// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { Grid, MenuItem, Select, Typography } from '@material-ui/core/';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSearchParams } from 'react-router-dom';
// Components
import { NotificationSettings } from './notification-settings.component';
import { Page, VerticalFormMenu } from 'features/ui';
import { ProfileForm } from './profile-form.component';
import { UserContext } from 'context';
import { CompanyProfile } from './company-profile.component';
// Constants
import { PERMISSIONS } from 'features/rbac';
import { SEARCH_PARAM_KEYS } from 'features/constants';
import { useMobile } from 'hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      marginBottom: theme.spacing(10),
    },
    tabSelect: {
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(5),
      },
    },
  })
);

enum ProfileTabValues {
  profile = 0,
  company = 1,
}

export const ProfilePage: React.FC = () => {
  const { isMobile } = useMobile();
  const classes = useStyles();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tab = searchParams.get(SEARCH_PARAM_KEYS.TAB);

    if (tab) {
      setCurrentTab(ProfileTabValues[tab as keyof typeof ProfileTabValues]);
    }
  }, [searchParams]);

  const { permissions } = useContext(UserContext)!;
  const isEmployee = permissions.includes(PERMISSIONS.VISIT_EMPLOYEE_PAGE);

  const [currentTab, setCurrentTab] = useState<ProfileTabValues>(
    ProfileTabValues.profile
  );

  const sections = [
    <ProfileForm />,
    <CompanyProfile />,
    <NotificationSettings />,
  ];

  const tabLabels = isEmployee
    ? ['Profile']
    : ['Profile', 'Company', 'Notification Settings'];

  return (
    <Page style={{ marginBottom: 150 }}>
      <Grid container item xs={12}>
        <Grid item xs={12} className={classes.pageTitle}>
          <Typography variant="h2">Settings</Typography>
        </Grid>
        <Grid container item xs={12} sm={3} className={classes.tabSelect}>
          {isMobile ? (
            <Select
              variant="outlined"
              fullWidth
              value={currentTab}
              onChange={(e) =>
                setCurrentTab(e.target.value as ProfileTabValues)
              }
            >
              {tabLabels.map((label, index) => (
                <MenuItem value={index}>{label}</MenuItem>
              ))}
            </Select>
          ) : (
            <VerticalFormMenu
              currentTab={currentTab}
              setIsAutoScrolling={() => {}}
              setDestinationSection={(tab) => setCurrentTab(tab)}
              tabLabels={tabLabels}
            />
          )}
        </Grid>
        <Grid container item xs={12} sm={9}>
          {sections[currentTab]}
        </Grid>
      </Grid>
    </Page>
  );
};
