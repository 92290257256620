// Libraries
import React from 'react';
// Utils
import { Task, TaskStatus } from 'features/home/customer-portal/types';

interface ThirdPartyTestingActionsProps {
  task: Task;
  openThirdPartyTestingModal: () => void;
}

export const ThirdPartyTestingActions: React.FC<ThirdPartyTestingActionsProps> = ({
  task,
  openThirdPartyTestingModal,
}) => {
  if (task.status === TaskStatus.pending) {
    return (
      <button
        onClick={openThirdPartyTestingModal}
        className="w-full sm:w-auto font-inter text-sm text-darkGray mr-2 border py-2 px-3 rounded-[4px]"
      >
        View & Sign Form
      </button>
    );
  }

  if (task.status === TaskStatus.done) {
    return (
      <button
        onClick={openThirdPartyTestingModal}
        className="w-full sm:w-auto font-inter text-sm text-darkGray mr-2 border py-2 px-3 rounded-[4px]"
      >
        View Form
      </button>
    );
  }

  return null;
};
