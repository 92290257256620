// Libraries
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  makeStyles,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik, useFormikContext } from 'formik';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as Yup from 'yup';
// Components
import { EditPricingModal } from './edit-pricing-modal.component';
import { FormSection } from 'features/ui';
import { PricingTable } from './pricing-table.component';
import { VendorSearch } from './vendor-search.component';
// Utils
import { MOQ_UNITS, PURCHASE_QUANTITY_UNITS } from 'features/types';
import { PricingFormType } from './types';
import { IRawMaterialFormValues } from './raw-material-form.component';
import { ITheme } from 'styles/mui/themeV2';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    autocomplete: {
      height: '54px',
    },
    button: {
      padding: '8px 12px',
      marginBottom: theme.spacing(8),
    },
    buttonContainer: {
      marginBottom: theme.spacing(5),
    },
    helperText: {
      whiteSpace: 'nowrap',
      marginLeft: 0,
    },
    row: {
      marginBottom: theme.spacing(2),
    },
    select: {
      height: '23px',
      marginTop: theme.spacing(1),
    },
  })
);

const INITIAL_VALUES: PricingFormType = {
  leadTimeNumber: '',
  leadTimeUnits: '',
  moq: '',
  moqUnit: '',
  unit: '',
  cost: '',
  quantityPurchased: '',
  receivedOn: null,
  selectedManufacturer: undefined,
  selectedSupplier: undefined,
};

const UNITS = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
];

export const PricingSection: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IRawMaterialFormValues>();
  const classes = useStyles();

  const [isEditPriceModalOpen, setIsEditPriceModalOpen] = useState<boolean>(
    false
  );
  const [priceIndexToEdit, setPriceIndexToEdit] = useState<Maybe<number>>(
    undefined
  );

  // Form submission for both creation (add) and edit
  const handleAdd = (
    submittedValues: {
      create: PricingFormType;
      edit: PricingFormType;
    },
    { resetForm }: { resetForm: any }
  ) => {
    const {
      cost,
      leadTimeNumber,
      leadTimeUnits,
      moq,
      moqUnit,
      quantityPurchased,
      receivedOn,
      unit,
      selectedManufacturer,
      selectedSupplier,
    } = submittedValues.create;

    setFieldValue('prices', [
      ...values.prices,
      {
        // If no ID is available use a generated one
        id: '',
        cost,
        unit,
        location: values.general.location,
        receivedAt: receivedOn
          ? new DateFnsUtils().format(receivedOn as Date, 'yyyy-MM-dd')
          : null,
        manufacturer: selectedManufacturer,
        supplier: selectedSupplier,
        moq,
        moqUnit,
        leadTimeNumber,
        leadTimeUnits,
        quantityPurchased,
      },
    ]);

    resetForm({
      create: { ...INITIAL_VALUES },
      edit: { ...INITIAL_VALUES },
    });
  };

  const handleEdit = (
    submittedValues: {
      create: PricingFormType;
      edit: PricingFormType;
    },
    { resetForm }: { resetForm: any }
  ) => {
    const {
      cost,
      leadTimeNumber,
      leadTimeUnits,
      moq,
      moqUnit,
      quantityPurchased,
      receivedOn,
      unit,
      selectedManufacturer,
      selectedSupplier,
    } = submittedValues.edit;

    const newPrices = [...values.prices];
    newPrices[priceIndexToEdit as number] = {
      cost: parseFloat(cost as string),
      id: values.prices[priceIndexToEdit as number].id,
      leadTimeNumber: leadTimeNumber,
      leadTimeUnits: leadTimeUnits,
      location: values.general.location,
      manufacturer: selectedManufacturer,
      moq: moq ? +moq : undefined,
      moqUnit: moqUnit,
      quantityPurchased: parseFloat(quantityPurchased as string),
      receivedAt: new DateFnsUtils().format(receivedOn as Date, 'yyyy-MM-dd'),
      supplier: selectedSupplier,
      unit,
    };

    setFieldValue('prices', newPrices);
    setIsEditPriceModalOpen(false);
    resetForm();
  };

  const validations = Yup.object().shape({
    cost: Yup.number()
      .positive('Cost must be positive')
      .required('Cost is required'),
    unit: Yup.string().required('Unit is required'),
    moq: Yup.number().positive('MOQ must be positive'),
    moqUnit: Yup.string().when('moq', {
      is: (val: string) => Boolean(val), // when moq is present we should require a moqUnit
      then: (schema) => schema.required('MOQ must have a unit'),
    }),
    quantityPurchased: Yup.number().positive(
      'Quantity purchased must be positive'
    ),
    selectedSupplier: Yup.object().required('Supplier is required'),
  });

  const formik = useFormik<{ create: PricingFormType; edit: PricingFormType }>({
    initialValues: {
      create: {
        ...INITIAL_VALUES,
      },
      edit: {
        ...INITIAL_VALUES,
      },
    },
    onSubmit: isEditPriceModalOpen ? handleEdit : handleAdd,
    validationSchema: isEditPriceModalOpen
      ? Yup.object().shape({
          create: Yup.object(),
          edit: validations,
        })
      : Yup.object().shape({
          create: validations,
          edit: Yup.object(),
        }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { getFieldProps, submitForm, errors } = formik;

  const handleDelete = () => {
    const newPrices = [...values.prices];
    const deletedPrice = newPrices.splice(priceIndexToEdit as number, 1);
    const newDeletedPrices = [...values.deletedPrices, deletedPrice[0].id];
    setFieldValue('prices', newPrices);
    setFieldValue('deletedPrices', newDeletedPrices);
    setIsEditPriceModalOpen(false);
  };

  const renderFormContent = (isEditModal: boolean) => {
    const formNameSpace = isEditModal ? 'edit' : 'create';
    return (
      <>
        <VendorSearch
          isEditModal={isEditModal}
          setFieldValue={formik.setFieldValue}
          values={formik.values}
          errors={formik.errors}
        />
        <Grid container item spacing={5} xs={12} className={classes.row}>
          <Grid item xs={isEditModal ? 6 : 2}>
            <TextField
              aria-label="price"
              {...getFieldProps(`${formNameSpace}.cost`)}
              error={Boolean(errors[formNameSpace]?.cost)}
              helperText={errors[formNameSpace]?.cost ?? ''}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              fullWidth
              variant="outlined"
              type="number"
              label="Price"
              required
              inputProps={{
                type: 'number',
                placeholder: '0.00',
                step: '.01',
                min: '0.00',
              }}
            />
          </Grid>
          <Grid item xs={isEditModal ? 3 : 2}>
            <TextField
              aria-label="unit"
              {...getFieldProps(`${formNameSpace}.unit`)}
              error={Boolean(errors[formNameSpace]?.unit)}
              helperText={errors[formNameSpace]?.unit ?? ''}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              fullWidth
              inputProps={{ className: classes.select }}
              label="Unit"
              required
              select
              variant="outlined"
            >
              {Object.values(PURCHASE_QUANTITY_UNITS).map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={isEditModal ? 6 : 4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  onChange={(date) =>
                    formik.setFieldValue(`${formNameSpace}.receivedOn`, date)
                  }
                  value={formik.values[formNameSpace].receivedOn}
                  fullWidth
                  autoOk={true}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  label="Price received on"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={isEditModal ? 6 : 4}>
            <TextField
              {...getFieldProps(`${formNameSpace}.quantityPurchased`)}
              fullWidth
              variant="outlined"
              label="Qty purchased"
              inputProps={{
                type: 'number',
                placeholder: '0.00',
                step: '.01',
                min: '0.00',
              }}
              InputProps={{
                endAdornment: formik.values[formNameSpace].unit,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={5} xs={12} className={classes.row}>
          <Grid item xs={isEditModal ? 8 : 4}>
            <TextField
              {...getFieldProps(`${formNameSpace}.moq`)}
              error={Boolean(errors[formNameSpace]?.moq)}
              helperText={errors[formNameSpace]?.moq ?? ''}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              fullWidth
              inputProps={{
                inputMode: 'numeric',
                min: '0',
                pattern: '[0-9]*.[0-9]*',
                step: '1',
                type: 'number',
              }}
              variant="outlined"
              label="MOQ"
              data-testid="moq"
            />
          </Grid>
          <Grid item xs={isEditModal ? 4 : 2}>
            <TextField
              aria-label="moqUnit"
              {...getFieldProps(`${formNameSpace}.moqUnit`)}
              error={Boolean(errors[formNameSpace]?.moqUnit)}
              helperText={errors[formNameSpace]?.moqUnit ?? ''}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              fullWidth
              inputProps={{ className: classes.select }}
              label="MOQ Unit"
              select
              variant="outlined"
            >
              {Object.values(MOQ_UNITS).map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={isEditModal ? 4 : 2}>
            <TextField
              aria-label="leadTimeNumber"
              {...getFieldProps(`${formNameSpace}.leadTimeNumber`)}
              fullWidth
              variant="outlined"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label="Lead Time"
            />
          </Grid>
          <Grid item xs={isEditModal ? 8 : 4}>
            <TextField
              aria-label="leadTimeUnits"
              {...getFieldProps(`${formNameSpace}.leadTimeUnits`)}
              fullWidth
              select
              variant="outlined"
              inputProps={{ className: classes.select }}
            >
              {UNITS.map((unit) => {
                return (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <FormSection
      sectionHeader="Pricing"
      isRevealed={Boolean(values.general.location)}
      setIsRevealed={() => null}
      helperText="Location must be added first"
    >
      {isEditPriceModalOpen && (
        <EditPricingModal
          open={true}
          setIsEditPricingModalOpen={setIsEditPriceModalOpen}
          priceIndexToEdit={priceIndexToEdit}
          handleEdit={submitForm}
          handleDelete={handleDelete}
        >
          {renderFormContent(true)}
        </EditPricingModal>
      )}
      {renderFormContent(false)}
      <Grid
        className={classes.buttonContainer}
        item
        xs={1}
        style={{ marginBottom: '10px' }}
      >
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={submitForm}
          className={classes.button}
        >
          Add
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {values.prices.length > 0 ? (
          <PricingTable
            setFieldValue={formik.setFieldValue}
            prices={values.prices}
            setIsEditPriceModalOpen={setIsEditPriceModalOpen}
            setPriceIndexToEdit={setPriceIndexToEdit}
          />
        ) : (
          <Typography variant="body2">None added</Typography>
        )}
      </Grid>
    </FormSection>
  );
};
