// Utils
import { IApiResponse } from './api.types';

export const showTransformer = (response: string) => {
  const {
    data,
    included,
    errors,
    errorMessage,
    meta,
  }: IApiResponse = JSON.parse(response);

  if (errors) {
    return {
      errors,
      errorMessage,
    };
  }

  const parsedIncluded = Object.keys(data?.relationships || {}).reduce(
    (acc, cv) => {
      const relationship = data?.relationships[cv];

      if (
        relationship &&
        Array.isArray(relationship.data) &&
        relationship.data.length > 0
      ) {
        const { type } = relationship?.data[0];
        acc[cv] = included?.filter((inc) => inc.type === type);
      } else {
        acc[cv] = included?.find(
          (inc) =>
            inc.type === relationship?.data?.type &&
            inc.id === relationship?.data.id
        );
      }

      return acc;
    },
    {} as { [x: string]: any }
  );

  if (data) {
    return {
      ...data,
      ...parsedIncluded,
      meta,
    };
  }
};

export const indexTransformer = (response: string) => {
  const {
    data,
    errors,
    errorMessage,
    included,
    meta,
  }: IApiResponse = JSON.parse(response);

  if (errors) {
    return {
      errors,
      errorMessage,
    };
  }
  const parseIncluded = (datum: any) => {
    return Object.keys(datum?.relationships || {}).reduce((acc, cv) => {
      const relationship = datum?.relationships[cv] as any;
      if (
        relationship &&
        Array.isArray(relationship.data) &&
        relationship.data.length > 0
      ) {
        const { type } = relationship?.data[0];

        acc[cv] = included?.filter(
          (inc) =>
            inc.type === type &&
            relationship.data.find((el: any) => el.id === inc.id)
        );
      } else {
        acc[cv] = included?.find(
          (inc) =>
            inc.type === relationship?.data?.type &&
            inc.id === relationship?.data?.id
        );
      }

      return acc;
    }, {} as { [x: string]: any });
  };

  if (data) {
    return {
      data: data.map((datum) => {
        return {
          id: datum.id,
          attributes: datum.attributes,
          relationships: datum.relationships,
          parsedIncluded: parseIncluded(datum),
        };
      }),
      meta,
    };
  }
};

export const notificationsTransformer = (response: string) => {
  const { data, errors, errorMessage }: IApiResponse = JSON.parse(response);

  if (errors) {
    return {
      errors,
      errorMessage,
    };
  }

  return { data };
};

export const noOpTransformer = (response: string) => {
  if (!response) {
    return {};
  }
  return JSON.parse(response);
};
