// Libraries
import React from 'react';
import {
  createStyles,
  makeStyles,
  Button,
  Grid,
  MenuItem,
  Typography,
  Select,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { SortOptions } from './types';
// Constants
import {
  CLEAR_SELECTIONS,
  FILTER_BY,
  FORMULAS_TITLE,
  FORMULA_SEARCH_RESULTS_FILTERS,
  NONE,
  PROJECT_SEARCH_RESULTS_FILTERS,
  SORT_BY,
} from './constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(5),
    },
    filterChip: {
      backgroundColor: theme.palette.gray.light,
      color: theme.palette.gray.dark,
      borderRadius: '100px',
      padding: '9px 12px',
      minHeight: theme.spacing(10),
      marginRight: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
      },
    },
    filterContainer: {
      display: 'flex',
      marginLeft: theme.spacing(1),
    },
    selectedFilterChip: {
      minHeight: theme.spacing(10),
      borderRadius: '100px',
      padding: '9px 12px',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    sortContainer: {
      borderLeft: `1px solid ${theme.palette.gray.main}`,
      borderRight: `1px solid ${theme.palette.gray.main}`,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    clearSelections: {
      cursor: 'pointer',
      color: theme.palette.gray.main,
      marginLeft: theme.spacing(5),
    },
  })
);

interface SecondaryFiltersAndSortProps {
  filters: any;
  selectedSort: string;
  setFilters: any;
  setSelectedSort: React.Dispatch<React.SetStateAction<string>>;
  sortOptions: SortOptions;
  title: string;
}

export const SecondaryFiltersAndSort: React.FC<SecondaryFiltersAndSortProps> = ({
  filters,
  selectedSort,
  setFilters,
  setSelectedSort,
  sortOptions,
  title,
}) => {
  const classes = useStyles();

  const clearSelections = () => {
    setSelectedSort(NONE);
    if (title === FORMULAS_TITLE) {
      setFilters(FORMULA_SEARCH_RESULTS_FILTERS);
    } else {
      setFilters(PROJECT_SEARCH_RESULTS_FILTERS);
    }
  };

  const handleSort = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedSort(e.target.value as string);
  };

  const handleFilter = (value: string) => {
    setFilters({
      ...filters,
      [value]: {
        ...filters[value],
        isFiltering: !filters[value]?.isFiltering,
      },
    });
  };

  const renderFilters = () => {
    return Object.keys(filters).map((filter, idx) => {
      return (
        <div key={`${filters[filter].name}-${idx}`}>
          <Button
            className={`${
              filters[filter].isFiltering
                ? classes.selectedFilterChip
                : classes.filterChip
            }`}
            onClick={() => handleFilter(filters[filter].value)}
            endIcon={
              filters[filter].isFiltering ? (
                <CloseIcon
                  onClick={() =>
                    setFilters({
                      ...filters,
                      [filter]: {
                        isFiltering: !filters[filter].isFiltering,
                      },
                    })
                  }
                />
              ) : null
            }
          >
            <Typography variant="h4">{filters[filter].name}</Typography>
          </Button>
        </div>
      );
    });
  };

  const renderSort = () => {
    return (
      <Grid container alignItems="center" className={classes.sortContainer}>
        <Typography style={{ marginRight: '1rem' }} variant="h4">
          {SORT_BY}
        </Typography>
        <Select value={selectedSort} onChange={handleSort}>
          {Object.keys(sortOptions).map((sortOption) => {
            return (
              <MenuItem
                value={sortOptions[sortOption].value}
                key={sortOptions[sortOption].value}
              >
                <Typography variant="h4">
                  {sortOptions[sortOption].name}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    );
  };

  return (
    <div className={classes.container}>
      <div>
        <Typography variant="h4">{FILTER_BY}</Typography>
      </div>
      <div className={classes.filterContainer}>{renderFilters()}</div>
      <div>{renderSort()}</div>
      <div className={classes.clearSelections}>
        <Typography variant="h4" onClick={clearSelections}>
          {CLEAR_SELECTIONS}
        </Typography>
      </div>
    </div>
  );
};
