// Libraries
import React, { useEffect, useRef, useState } from 'react';
// Components
import { Loader } from './loader.component';
import Chevron from 'features/formula/images/chevron.svg';

export type SelectOption = {
  name: string;
  value: string | any;
};
interface SelectProps {
  bottomOption?: string;
  label?: string;
  onBottomOptionClick?: () => void;
  onOptionClick: (option: SelectOption) => void;
  options: Array<SelectOption>;
  placeholder: string;
  displayValue: Maybe<string>;
  small?: boolean;
  required?: boolean;
  width?: string;
}

export const Select: React.FC<SelectProps> = ({
  bottomOption,
  label,
  onBottomOptionClick,
  onOptionClick,
  options,
  placeholder,
  displayValue,
  small,
  required,
  width,
}) => {
  const dropdown = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Maybe<string>>(
    displayValue
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedOption(displayValue);
    setIsLoading(false);
  }, [displayValue]);

  useEffect(() => {
    if (selectedOption) {
      setIsOpen(false);
    }
  }, [selectedOption]);

  const handleOptionClick = (option: SelectOption) => {
    setSelectedOption(option.name);
    onOptionClick(option);
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    isOpen && document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, dropdown]);

  return (
    <div
      className={`relative cursor-pointer ${width ? `w-${width}` : 'w-full'}`}
      tabIndex={0}
      ref={dropdown}
    >
      {label && (
        <label>
          {label} {required ? <span className="text-formulaRed"> *</span> : ''}
        </label>
      )}
      <button
        type="button"
        onClick={toggleOpen}
        className={`bg-white ${
          small ? 'p-2' : 'p-3'
        } border-2 rounded border-formulaLightGray w-full shadow-md text-left ${
          !selectedOption && 'text-formulaGray'
        }`}
      >
        {selectedOption ? selectedOption : placeholder}
      </button>
      <img
        src={Chevron}
        alt=""
        height="14"
        width="14"
        className={`absolute top-5 right-1 transform rotate-90 ${
          small && 'top-4'
        } ${label && 'top-11'}
        }`}
      />
      <div
        className={`${isOpen ? 'block' : 'hidden'} ${
          label ? 'top-companySearch' : 'top-12'
        } absolute border-2 rounded-b border-formulaLightGray border-t-0 z-10 bg-white w-full shadow-md cursor-pointer`}
      >
        <ul className="overflow-scroll max-h-72">
          {options.map((option) => {
            return (
              <li
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={`px-2 py-3 hover:bg-formulaLightBlue ${
                  option.name === selectedOption
                    ? 'bg-formulaLighterGray'
                    : 'bg-white'
                }`}
              >
                {option.name}
              </li>
            );
          })}
          {bottomOption && onBottomOptionClick && (
            <li
              onClick={() => {
                !isLoading && onBottomOptionClick();
                setIsLoading(true);
              }}
              className={`px-2 py-3 border-t-2 border-formulaLightGray text-blue-60 ${
                isLoading ? '' : 'hover:bg-formulaLightBlue'
              }}`}
            >
              <Loader isLoading={isLoading} size={{ width: 25, height: 25 }}>
                {bottomOption}
              </Loader>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
