// Libraries
import React, { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { CircularProgress } from '@material-ui/core';
import * as Yup from 'yup';
import _ from 'lodash';
// Components
import { AdditionalTestsSection } from './additional-tests-sections.component';
import { CostSummarySection } from './cost-summary-section.component';
import { TestSection } from './test-section.component';
// Utils
import { ThirdPartyTestFormValues } from './types';
import { IApiData, ThirdPartyTestAttributes, useApi } from 'api';
import { useSnackbar } from 'hooks';
// Constants
import { THIRD_PARTY_TESTS } from './third-party-test.constants';
import { RESOURCE_TYPES } from 'api/api.constants';

interface ThirdPartyTestSelectionFormProps {
  projectId: Maybe<string>;
  thirdPartyTest: Maybe<IApiData<ThirdPartyTestAttributes>>;
  setProject: (project: any) => void;
  isLocked?: boolean;
}

export const ThirdPartyTestSelectionForm: React.FC<ThirdPartyTestSelectionFormProps> = ({
  projectId,
  thirdPartyTest,
  setProject,
  isLocked,
}) => {
  const { showSnackbar } = useSnackbar();
  const { postProjectThirdPartyTest, patchProjectThirdPartyTest } = useApi();
  // dynamically updates the validation schema based on the user's selections
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({})
  );

  const isInEditState = Boolean(thirdPartyTest?.attributes);

  const handleSubmit = async (
    values: ThirdPartyTestFormValues,
    { setSubmitting }: FormikHelpers<ThirdPartyTestFormValues>
  ) => {
    const request = isInEditState
      ? patchProjectThirdPartyTest
      : postProjectThirdPartyTest;

    request({
      urlParams: `${projectId}/${RESOURCE_TYPES.THIRD_PARTY_TESTS}${
        thirdPartyTest ? `/${thirdPartyTest.id}` : ''
      }`,
      data: {
        thirdPartyTest: {
          ...values,
          additionalTests: values.additionalTests
            // Clear out any empty tests
            .filter((test) => test.name && test.cost >= 0)
            // Add initials to each test & trim whitespace
            .map((test) => ({ ...test, name: test.name.trim(), initials: '' })),
        },
      },
      handleSuccess: (data) => {
        setProject((prevProject: any) => ({
          ...prevProject,
          thirdPartyTest: data,
        }));

        showSnackbar(
          `Third party tests successfully ${
            isInEditState ? 'updated' : 'created'
          }.`,
          'success'
        );
      },
      handleFinally: () => {
        setSubmitting(false);
      },
    });
  };

  const initialFormValues = {
    ..._.omit(thirdPartyTest?.attributes, [
      'id',
      'projectId',
      'createdAt',
      'updatedAt',
      'acknowledged',
    ]),
    additionalTests: thirdPartyTest?.attributes.additionalTests || [
      {
        name: '',
        cost: 0,
      },
    ],
  } as ThirdPartyTestFormValues;

  return (
    <section className="px-10 pt-12 pb-10 overflow-x-auto sm:overflow-x-visible">
      <div className="min-w-[54rem] sm:min-w-full">
        {isLocked ? (
          <div className="w-8/12 mb-10 font-inter text-[16px] text-red-40">
            <p>
              Tests are currently in review by the customer and can no longer be
              edited.
            </p>
          </div>
        ) : (
          <details className="w-8/12 mb-10 font-inter cursor-pointer" open>
            <summary className="text-[16px] font-semibold mb-2">
              How to use this form:
            </summary>
            <ol className="text-[16px] list-decimal ml-6 mb-2">
              <li className="mb-2">
                Select tests required for this project. Adjust default costs
                where necessary.
              </li>
              <li className="mb-2">
                When test selection is finalized, change Monday column” D1. 3rd
                Party Testing Acknowledgement” to PENDING.
              </li>
              <li className="mb-2">
                The customer will receive a notification that Third Party
                Testing is ready for their signoff in their project tracker.
              </li>
            </ol>

            <p className="text-[16px] ml-2">
              <b>Note:</b> Once the Monday column is changed, this form is no
              longer editable.
            </p>
          </details>
        )}
        <Formik
          // starts empty (aside from additional tests) but will be updated with the user's selections
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleSubmit }) => {
            const isFormEmpty = Object.values(values).every((value) =>
              Array.isArray(value) ? value.every((test) => !test.name) : !value
            );

            return (
              <div className="relative">
                {THIRD_PARTY_TESTS.map((testGroup) => (
                  <TestSection
                    key={testGroup.sectionTitle}
                    testGroup={testGroup}
                    setValidationSchema={setValidationSchema}
                    isLocked={isLocked}
                  />
                ))}
                <AdditionalTestsSection isLocked={isLocked} />
                <section className="sticky bottom-0 z-[99] h-[160px] sm:h-[80px] bg-lightestGray border border-grey-90 rounded-sm rounded-t-none">
                  <div className="flex flex-col sm:flex-row border-grey-90 h-full">
                    <span className="w-full sm:w-6/12 flex h-1/2 sm:h-full items-center pl-6 border-r-grey-90 border-r">
                      <h6 className="font-mono text-xs font-medium">
                        TOTAL COST TO CUSTOMER
                      </h6>
                    </span>
                    <CostSummarySection />
                  </div>
                </section>
                {!isLocked && (
                  <div className="flex sm:justify-end">
                    <button
                      disabled={isSubmitting || isFormEmpty}
                      onClick={() => handleSubmit()}
                      className="flex items-center bg-blue-60 text-white font-inter text-[14px] px-8 py-[15px] rounded-sm mt-10 disabled:bg-gray"
                    >
                      {isSubmitting && (
                        <CircularProgress
                          size={16}
                          thickness={6}
                          color="secondary"
                          className="mr-2"
                        />
                      )}
                      <p>{isInEditState ? 'Save' : 'Submit'}</p>
                    </button>
                  </div>
                )}
              </div>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};
