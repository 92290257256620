import React from 'react';
// Components
import { SectionHeader } from '../../section-header.component';
import { FormulaPriceSummaryTable } from './formula-price-summary-table.component';
import { IngredientsTable } from './ingredients-table.component';
// Utils
import { SectionProps } from '../types';

export const FormulaSection: React.FC<SectionProps> = ({ costs, isActive }) => {
  return (
    <div
      className={`${isActive ? 'h-auto' : 'h-0 overflow-hidden'} flex flex-col`}
    >
      <SectionHeader
        title="Formula"
        checkboxLabel="Purchasing Review Complete"
      />
      <div className="px-6 py-9 bg-white">
        <IngredientsTable formulaCosts={costs} />
        <FormulaPriceSummaryTable formulaCosts={costs} />
      </div>
    </div>
  );
};
