// Libraries
import React from 'react';
import { Link } from '@material-ui/core';
// Utils
import { FormulaAttributes, IApiData, IngredientAttributes } from 'api';
import { WarningOrError } from './types';
// Constants
import { MIXED_UP_LOCATIONS_WARNING } from './constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
// Helpers

export const generateFormulaWarnings = (
  formula?: IApiData<FormulaAttributes>,
  ingredients?: IApiData<IngredientAttributes>[]
): WarningOrError[] => {
  const formulaLink = (
    <Link
      style={{ textDecoration: 'underline' }}
      href={ROUTES.SHOW_FORMULA.route.replace(
        UUID_SHOW_ROUTE_STRING,
        formula?.id || ''
      )}
    >
      Formula
    </Link>
  );

  // Case for a new formula that has no ingredients yet
  if (!ingredients) {
    return [];
  }

  const warnings = [];

  const ingredientsAtASecondLocation = ingredients.filter(
    (ingredient) =>
      ingredient.attributes.rawMaterialLocation !==
      ingredients.at(0)?.attributes.rawMaterialLocation
  );

  if (ingredientsAtASecondLocation.length > 0) {
    const numIngredientsAtFirstLocation =
      ingredients.length - ingredientsAtASecondLocation.length;
    const totalNumberOfIngredients = ingredients.length;
    const firstLocation = ingredients.at(0)?.attributes.rawMaterialLocation;
    const secondLocation = ingredientsAtASecondLocation.at(0)?.attributes
      .rawMaterialLocation;

    warnings.push({
      issue: `${MIXED_UP_LOCATIONS_WARNING.issue} ${numIngredientsAtFirstLocation} of ${totalNumberOfIngredients}
              are from ${firstLocation}. ${ingredientsAtASecondLocation.length} of
              ${totalNumberOfIngredients} are from ${secondLocation}.`,
      actionItem: (
        <>
          {MIXED_UP_LOCATIONS_WARNING.actionItem} {formulaLink}
        </>
      ),
      warningName: MIXED_UP_LOCATIONS_WARNING.warningName,
    });
  }

  return warnings;
};
