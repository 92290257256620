// Libraries
import React, { useContext } from 'react';
import { useFormik } from 'formik';
// Components
import { DialogModal } from 'features/ui';
import {
  createStyles,
  FormControl,
  makeStyles,
  TextField,
} from '@material-ui/core';
// Utils
import { handleError } from 'features/formula/utils';
import { IApiResponse } from 'features/types';
import { ITheme } from 'styles/mui/themeV2';
import { FormulaContext } from '../context';
import { useApi, useSnackbar } from 'hooks';
// Constants
import * as Constants from 'features/constants';
import { FormulaAttributes } from 'api';

const DIRECTIONS_FOR_USE = 'directionsForUse';
const FEATURES_BENEFITS = 'featuresBenefits';
const RECOMMENDED_FOR = 'recommendedFor';

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      '& .MuiSelect-root': {
        height: '54px',
      },
    },
    textField: {
      marginBottom: theme.spacing(5),
    },
  })
);

interface IMarketingInfoDialog {
  open: boolean;
  handleClose: () => void;
}

interface IFormValues {
  directionsForUse?: string;
  featuresBenefits?: string;
  recommendedFor?: string;
}

export const MarketingInfoDialog: React.FC<IMarketingInfoDialog> = ({
  open,
  handleClose,
}) => {
  const classes = useStyles();
  const request = useApi();
  const { formula, setFormula } = useContext(FormulaContext);
  const { showSnackbar } = useSnackbar();

  const isLocked = formula?.locked;

  const handleFormulaUpdate = async (values: IFormValues) => {
    try {
      const response: IApiResponse = await request({
        resource: `/api/v1/formulas/${formula?.id}`,
        options: {
          settings: Constants.PATCH,
          scope: 'update:formula',
          body: {
            formula: {
              directionsForUse: values.directionsForUse,
              featuresBenefits: values.featuresBenefits,
              recommendedFor: values.recommendedFor,
            } as FormulaAttributes,
          },
        },
      });

      if (response.data) {
        formula &&
          setFormula({
            ...formula,
            directionsForUse: response.data.attributes.directionsForUse,
            featuresBenefits: response.data.attributes.featuresBenefits,
            recommendedFor: response.data.attributes.recommendedFor,
          });
        showSnackbar('Formula details successfully updated', 'success');
        resetForm({
          values,
        });
      } else {
        handleError(response);
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error');
      console.error(error);
      Rollbar.error(error);
    } finally {
      handleClose();
    }
  };

  const formik = useFormik<IFormValues>({
    initialValues: {
      directionsForUse: formula?.directionsForUse || '',
      featuresBenefits: formula?.featuresBenefits || '',
      recommendedFor: formula?.recommendedFor || '',
    },
    onSubmit: handleFormulaUpdate,
  });

  // getFieldProps automatically gives us => value, onBlur, onChange and Name attributes :)
  const { getFieldProps, handleSubmit, isSubmitting, resetForm } = formik;

  const resetModal = () => {
    handleClose();
    resetForm();
  };

  return (
    <DialogModal
      onConfirm={handleSubmit}
      open={open}
      handleClose={resetModal}
      submitDisabled={isSubmitting || isLocked}
      title={'Formula details'}
    >
      <FormControl fullWidth>
        <TextField
          disabled={isLocked}
          {...getFieldProps(DIRECTIONS_FOR_USE)}
          className={classes.textField}
          fullWidth
          label="Directions for use"
          variant="outlined"
        />
        <TextField
          {...getFieldProps(RECOMMENDED_FOR)}
          className={classes.textField}
          disabled={isLocked}
          fullWidth
          label="Recommended for"
          variant="outlined"
        />
        <TextField
          {...getFieldProps(FEATURES_BENEFITS)}
          className={classes.textField}
          disabled={isLocked}
          fullWidth
          label="Features benefits"
          variant="outlined"
        />
      </FormControl>
    </DialogModal>
  );
};
