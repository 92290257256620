// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Card, Typography } from 'design-system';
import { RtlTip } from '../customer-brief-v2';
import { ScrollIntoView } from './scroll-into-view.component';
import * as amplitude from '@amplitude/analytics-browser';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { useRtlIconMap } from '../rtl-logic';
import { useConfirmation } from 'hooks';
// Constants
import {
  BRIEF_FORM_KEYS,
  RESTART_CONFIRM_STATE,
  RTL_CONFIRM_STATE,
} from '../brief.constants';
// Assets
import { ReactComponent as ReadyToLaunchIcon } from 'img/ready-to-launch.svg';
import { PRODUCT_OPTIONS } from './form-module.constants';

type ProductType = {
  label: string;
  value: string;
  image?: string;
  hasRocket?: boolean;
};

const RTL_TIP = [
  'Throughout this form, you’ll see this icon next to options available in our Ready to Launch, our collection of trusted and tested formulas.',
];

interface ProductTypeCardsProps {
  projectIsRtl?: boolean;
}

export const ProductTypeCards: React.FC<ProductTypeCardsProps> = ({
  projectIsRtl,
}) => {
  const { setFieldValue, values } = useFormikContext<InternalBriefFormValues>();

  const rtlIconMap = useRtlIconMap({
    values,
    moduleKey: BRIEF_FORM_KEYS.PRODUCT_TYPE,
  });

  const { confirm } = useConfirmation();

  const handleNonRtlClick = async (type: ProductType) => {
    const shouldProceed = await confirm(RTL_CONFIRM_STATE);
    if (shouldProceed) {
      amplitude.track('Confirmed Leaving RTL', {
        field: 'Product Type',
        value: type.value,
      });
      setFieldValue(BRIEF_FORM_KEYS.PRODUCT_TYPE, type);
    } else {
      amplitude.track('Cancelled Leaving RTL', {
        field: 'Product Type',
        value: type.value,
      });
      return;
    }
  };

  return (
    <ScrollIntoView>
      {({ ref }) => (
        <div
          ref={ref}
          className="flex flex-col sm:grid sm:grid-cols-9 sm:col-span-9 mt-9"
        >
          <div className="flex flex-col gap-6 sm:col-span-6">
            <p className="font-inter text-lg text-grey-20">
              {values.productCategory.value} Product Type
            </p>
            <Typography
              variant="p"
              font="inter"
              size="base"
              weight="normal"
              color="secondary"
            >
              Select the product type within your category:
            </Typography>
            <div className="flex flex-col sm:flex-row flex-wrap gap-6">
              {PRODUCT_OPTIONS.find(
                (option) => option.value === values.productCategory.value
              )!.productTypes.map((type: ProductType) => {
                const isRtlOption = rtlIconMap[BRIEF_FORM_KEYS.PRODUCT_TYPE]
                  ? rtlIconMap[BRIEF_FORM_KEYS.PRODUCT_TYPE][type.value]
                  : false;
                return (
                  <Card
                    data-testid={`product-type-${type.value}`}
                    action={
                      isRtlOption || !projectIsRtl
                        ? async () => {
                            // If we already have a project type then we should confirm before changing
                            if (values.productType.value && values.structure) {
                              const shouldProceed = await confirm(
                                RESTART_CONFIRM_STATE
                              );

                              if (!shouldProceed) return;
                            }

                            setFieldValue(BRIEF_FORM_KEYS.PRODUCT_TYPE, type);
                          }
                        : () => handleNonRtlClick(type)
                    }
                    key={type.value}
                    title={type.label}
                    orientation="horizontal"
                    image={type.image}
                    icon={
                      isRtlOption ? (
                        <ReadyToLaunchIcon className="h-4 w-4" />
                      ) : null
                    }
                    iconColor="text-peach-60"
                    selected={
                      values[
                        BRIEF_FORM_KEYS.PRODUCT_TYPE as keyof InternalBriefFormValues
                      ].value === type.value
                    }
                    className="w-full sm:w-[303px] h-[60px] sm:h-[60px]"
                  />
                );
              })}
            </div>
          </div>
          {projectIsRtl && (
            <div className="col-span-3">
              <RtlTip
                additionalClasses={'mt-[52px]'}
                contents={RTL_TIP}
                // Placeholder url
                url="https://www.primematterlabs.com/create-your-product"
              />
            </div>
          )}
        </div>
      )}
    </ScrollIntoView>
  );
};
