// Libraries
import React from 'react';
import { Avatar, Tooltip } from '@material-ui/core';

interface IEmployeeAvatarProps {
  givenName: string;
  familyName: string;
  avatarColor?: string;
}

export const EmployeeAvatar: React.FC<IEmployeeAvatarProps> = ({
  givenName,
  familyName,
  avatarColor,
}) => {
  const initials = givenName[0] + familyName[0];

  return (
    <Tooltip title={`${givenName} ${familyName}`} arrow>
      <Avatar
        alt={`${givenName} ${familyName}`}
        style={{ backgroundColor: avatarColor || '#5994ca', color: '#000' }}
      >
        {initials}
      </Avatar>
    </Tooltip>
  );
};
