// Libraries
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import { InviteTeamMemberForm, TeamMemberRow } from 'features/team-management';
import { UserContext } from 'context';
// Utils
import { Action, ActionType } from 'features/team-management/types';
import { ITheme } from 'styles/mui/themeV2';
import { useSnackbar } from 'hooks';
import { CompanyAttributes, CustomerAttributes, IApiData, useApi } from 'api';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    companyInput: {
      marginBottom: theme.spacing(8),
    },
    companyInputButton: {
      marginRight: theme.spacing(2),
    },
    teamManagementContainer: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      padding: `${theme.spacing(8)}px !important`,
    },
    teamManagementTitle: {
      marginBottom: theme.spacing(6),
    },
  })
);

export const CompanyProfile: React.FC = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { patchCompany, getCompany, deleteCustomer, postEmail } = useApi();
  const {
    userSession,
    userSession: {
      uuid,
      customerMetadata: { companyUuid, companyName },
    },
    setUserSession,
  } = useContext(UserContext)!;

  const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState<boolean>(
    false
  );
  const [isEditingCompanyName, setIsEditingCompanyName] = useState<boolean>(
    false
  );

  const [action, setAction] = useState<Action>({
    currentResourceId: undefined,
    isLoading: false,
    type: undefined,
  });

  const [teamMembers, setTeamMembers] = useState<
    IApiData<CustomerAttributes>[]
  >([]);
  useEffect(() => {
    if (!companyUuid) return;

    setIsLoadingTeamMembers(true);

    getCompany({
      urlParams: companyUuid,
      handleSuccess: (
        data: IApiData<CompanyAttributes> & {
          customers: Array<IApiData<CustomerAttributes>>;
        }
      ) => setTeamMembers(data.customers),
      handleFinally: () => setIsLoadingTeamMembers(false),
    });
  }, [companyUuid, getCompany]);

  const {
    handleSubmit,
    getFieldProps,
    errors,
    resetForm,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: {
      companyName,
    },
    validationSchema: Yup.object().shape({
      companyName: Yup.string().required('Company name is required'),
    }),
    onSubmit: (values): any => {
      setSubmitting(true);

      const { companyName } = values;
      patchCompany({
        urlParams: companyUuid,
        data: {
          company: {
            name: companyName,
          },
        },
        handleSuccess: (data: IApiData<CompanyAttributes>) => {
          showSnackbar('Company name updated', 'success');
          setUserSession({
            ...userSession,
            customerMetadata: {
              ...userSession.customerMetadata,
              companyName: data.attributes.name,
            },
          });
        },
        handleFinally: () => {
          setSubmitting(false);
          setIsEditingCompanyName(false);
        },
      });
    },
  });

  const handleRemoveTeamMember = (id: string) => {
    if (!window.confirm('Remove?')) return;

    setAction({
      currentResourceId: id,
      isLoading: true,
      type: ActionType.remove,
    });

    deleteCustomer({
      urlParams: id,
      handleSuccess: () => {
        showSnackbar('Team member removed!', 'success');
        setTeamMembers((prev) => prev.filter((member) => member.id !== id));
      },
      handleFinally: () =>
        setAction({
          currentResourceId: undefined,
          isLoading: false,
          type: undefined,
        }),
    });
  };

  const handleSendCustomerReinvite = (id: string) => {
    setAction({
      currentResourceId: id,
      isLoading: true,
      type: ActionType.reinvite,
    });

    postEmail({
      data: {
        email: {
          customerUuid: id,
          method: 'create-notification',
          itemType: 'customer_reinvitation',
        },
      },
      handleSuccess: (data) => {
        showSnackbar('Re-invite sent!', 'success');
        setTeamMembers((prev) =>
          prev.map((member) => {
            if (member.id === id) {
              return {
                ...member,
                attributes: {
                  ...member.attributes,
                  thirdPartyId: data.attributes.thirdPartyId,
                },
              };
            }
            return member;
          })
        );
      },
      handleError: (e) => {
        showSnackbar('Failed to send re-invite', 'error');
        Rollbar.error(e);
      },
      handleFinally: () =>
        setAction({
          currentResourceId: undefined,
          isLoading: false,
          type: undefined,
        }),
    });
  };

  return (
    <Grid container item xs={12} style={{ marginBottom: 50 }}>
      <Grid
        container
        xs={12}
        spacing={2}
        alignItems="center"
        className={classes.companyInput}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            {...getFieldProps('companyName')}
            fullWidth
            label="Company name"
            required
            variant="outlined"
            error={!!errors.companyName}
            helperText={errors.companyName}
            disabled={!isEditingCompanyName}
          />
        </Grid>
        <Grid container item xs={6} sm={6} md={3} lg={2}>
          <Grid item xs={5} sm={4} md={5} lg={6}>
            <Button
              className={classes.companyInputButton}
              variant={isEditingCompanyName ? 'contained' : 'outlined'}
              color={isEditingCompanyName ? 'primary' : 'secondary'}
              onClick={() =>
                isEditingCompanyName
                  ? handleSubmit()
                  : setIsEditingCompanyName(!isEditingCompanyName)
              }
              disabled={isSubmitting}
            >
              {isEditingCompanyName ? 'Save' : 'Edit'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={5} lg={6}>
            {isEditingCompanyName && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  resetForm({ values: { companyName } });
                  setIsEditingCompanyName(false);
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.teamManagementContainer}>
        <Grid item xs={12} className={classes.teamManagementTitle}>
          <Typography variant="h3">Team members</Typography>
        </Grid>
        <Grid item xs={12}>
          <InviteTeamMemberForm
            companyUuid={companyUuid!}
            setTeamMembers={setTeamMembers}
          />
        </Grid>
        <Grid container item xs={12}>
          {isLoadingTeamMembers ? (
            <Grid
              container
              item
              xs={12}
              spacing={5}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: '1rem' }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            teamMembers.map(
              (teamMember: IApiData<CustomerAttributes>, index: number) => (
                <Grid
                  container
                  item
                  xs={12}
                  key={teamMember.id}
                  style={{ marginTop: index >= 1 ? 20 : 24 }}
                >
                  <TeamMemberRow
                    customer={teamMember}
                    setCustomers={setTeamMembers}
                    action={action}
                    setAction={setAction}
                    handleRemoveTeamMember={handleRemoveTeamMember}
                    handleSendCustomerReinvite={handleSendCustomerReinvite}
                    isCurrentlyLoggedInCustomer={teamMember.id === uuid}
                  />
                </Grid>
              )
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
