// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
// Components
import { Button, DoubleButton } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';

interface FormNavigationProps {
  allRtlModulesHaveValues: boolean;
  currentPage: number;
  pages: { title: string; component: React.ReactNode }[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setIsErrorAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormNavigation: React.FC<FormNavigationProps> = ({
  allRtlModulesHaveValues,
  currentPage,
  pages,
  setCurrentPage,
  setIsErrorAlertOpen,
}) => {
  const { validateForm, submitForm } = useFormikContext<
    InternalBriefFormValues
  >();
  const page = pages[currentPage];
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === pages.length - 2;

  if (page.title === 'Formula' && !allRtlModulesHaveValues) {
    return null;
  }

  return (
    <div className="flex justify-end">
      <div>
        {isFirstPage ? (
          <Button
            data-testid="next-button"
            size="large"
            text="Next →"
            action={() => {
              validateForm().then((errors) => {
                if (isEmpty(errors)) {
                  setCurrentPage(currentPage + 1);
                  setIsErrorAlertOpen(false);
                } else {
                  setIsErrorAlertOpen(true);
                }
              });
            }}
          />
        ) : (
          <DoubleButton
            size="large"
            buttonOneText="← Prev"
            buttonTwoText={isLastPage ? 'Submit' : 'Next →'}
            buttonOneAction={() => {
              setCurrentPage(currentPage - 1);
              setIsErrorAlertOpen(false);
            }}
            buttonTwoAction={() => {
              validateForm().then((errors) => {
                if (!isEmpty(errors)) {
                  setIsErrorAlertOpen(true);
                  return;
                }

                if (isLastPage) {
                  submitForm();
                  return;
                }

                setCurrentPage(currentPage + 1);
                setIsErrorAlertOpen(false);
              });
            }}
          />
        )}
      </div>
    </div>
  );
};
