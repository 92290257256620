// Libraries
import { useContext } from 'react';
// Utils
import { ConfirmationContext, ConfirmState } from 'context';

let resolveCallback: (value: unknown) => void;

export const useConfirmation = () => {
  const { confirmState, setConfirmState } = useContext(ConfirmationContext);

  const { isOpen } = confirmState;

  const closeConfirmation = () => {
    setConfirmState({
      description: '',
      isOpen: false,
      prompt: '',
    });
  };

  const handleConfirmation = () => {
    closeConfirmation();
    resolveCallback(true);
  };

  const handleCancel = () => {
    closeConfirmation();
    resolveCallback(false);
  };

  const confirm = ({ prompt, description }: Omit<ConfirmState, 'isOpen'>) => {
    setConfirmState({
      description,
      isOpen: true,
      prompt,
    });

    return new Promise((resolve) => {
      resolveCallback = resolve;
    });
  };

  return { confirm, handleConfirmation, handleCancel, isOpen };
};
