// Libraries
import React from 'react';
// Components
import { Button, Modal, Typography } from 'design-system';
// Utils
import { useConfirmation } from 'hooks';
import { ConfirmState } from './types';

export const ConfirmModal: React.FC<ConfirmState> = ({
  prompt,
  description,
  isOpen,
}): JSX.Element | null => {
  const { handleConfirmation, handleCancel } = useConfirmation();
  if (!isOpen) return null;

  return (
    <Modal handleClose={() => null}>
      <div className="flex flex-col gap-3 p-6 border-b border-grey-90">
        <Typography variant="h4" font="inter" weight="bold">
          {prompt}
        </Typography>
        <Typography variant="h4" font="inter">
          {description}
        </Typography>
      </div>
      <div className="flex justify-end gap-3 p-4">
        <Button
          text="Nevermind"
          type="secondary"
          onClick={handleCancel}
          size="large"
        />
        <Button text="Yes, Select" onClick={handleConfirmation} size="large" />
      </div>
    </Modal>
  );
};
