import React, { useEffect } from 'react';

interface ScrollIntoViewProps {
  children: (props: {
    ref: React.RefObject<HTMLDivElement>;
  }) => React.ReactElement<any, any>;
}

export const ScrollIntoView: React.FC<ScrollIntoViewProps> = ({ children }) => {
  const element = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!element.current) return;

    element.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, [element]);

  return children({ ref: element });
};
