// Libraries
import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
// Components
import { Can } from 'features/rbac';
import { ArchiveDialog } from './archive-inci-modal.component';
import { FunctionsSection } from './functions-section.component';
import { GeneralSection } from './general-section.component';
import { InciForm } from './inci-form.component';
// Utils
import { PERMISSIONS } from 'features/rbac';
import {
  FixedSubmitButtonBar,
  Page,
  Scrollable,
  VerticalFormMenu,
} from 'features/ui';
import { RawMaterialsSection } from './raw-materials-section.component';
// Utils
import { useTitle } from 'hooks';
import { useApi, IApiData, InciAttributes } from 'api';
// Constants
const INITIAL_SELECTED_TAB = 0;
const DEFAULT_DESTINATION_SECTION = -1;
const TAB_LABELS = ['General', 'Functions', 'Raw Materials'];

export const InciPage: React.FC = () => {
  const { getInci } = useApi();
  const { uuid }: { uuid?: string } = useParams();
  const navigate = useNavigate();

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  // STATE FOR INCI
  const [inci, setInci] = useState<Maybe<IApiData<InciAttributes>>>(undefined);

  useTitle(inci?.attributes.usName);

  useEffect(() => {
    if (!uuid) return;

    getInci({
      urlParams: `/${uuid}`,
      handleSuccess: setInci,
    });
  }, [getInci, uuid]);

  // STATE FOR VERTICAL MENU
  const [destinationSection, setDestinationSection] = useState<number>(
    DEFAULT_DESTINATION_SECTION
  );
  const [destinationRef, setDestinationRef] = useState<any>();
  const [currentTab, setCurrentTab] = useState<number>(INITIAL_SELECTED_TAB);
  const [isAutoScrolling, setIsAutoScrolling] = useState<boolean>(false);

  useEffect(() => {
    destinationRef?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setTimeout(() => {
      setDestinationSection(DEFAULT_DESTINATION_SECTION);
      setIsAutoScrolling(false);
    }, 500);
  }, [destinationRef]);

  const FORM_SECTIONS = [
    <GeneralSection />,
    <FunctionsSection />,
    <RawMaterialsSection />,
  ];

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Page>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography variant="h2">{uuid ? 'Edit' : 'Add'} an INCI</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="column"
          alignItems="flex-start"
          style={{ paddingTop: '0', paddingBottom: '0', marginBottom: '30px' }}
        ></Grid>
      </Grid>
      <Grid container item xs={3}>
        <VerticalFormMenu
          currentTab={currentTab}
          setIsAutoScrolling={setIsAutoScrolling}
          setDestinationSection={setDestinationSection}
          tabLabels={TAB_LABELS}
        />
      </Grid>
      <Grid container item xs={9}>
        <InciForm inci={inci} setInci={setInci}>
          <>
            <Grid container item xs={11} style={{ marginBottom: '30rem' }}>
              {FORM_SECTIONS.map((Section, index) => (
                <Scrollable
                  key={index}
                  setDestinationRef={setDestinationRef}
                  isDestinationSection={index === destinationSection}
                  isAutoScrolling={isAutoScrolling}
                  setIsAutoScrolling={setIsAutoScrolling}
                  index={index}
                  destinationSection={destinationSection}
                  setDestinationSection={setDestinationSection}
                  setCurrentTab={setCurrentTab}
                >
                  {Section}
                </Scrollable>
              ))}
              <Can
                perform={PERMISSIONS.ARCHIVE_INCI}
                yes={() => (
                  <div>
                    <Button
                      size="small"
                      onClick={() => setIsArchiveModalOpen(true)}
                    >
                      Archive
                    </Button>
                    {isArchiveModalOpen && (
                      <ArchiveDialog
                        open={isArchiveModalOpen}
                        handleClose={() => setIsArchiveModalOpen(false)}
                        inci={inci}
                      />
                    )}
                  </div>
                )}
              />
            </Grid>
            <FixedSubmitButtonBar onCancel={handleCancel} />
          </>
        </InciForm>
      </Grid>
    </Page>
  );
};
