// Libraries
import React from 'react';
import { Link } from '@material-ui/core';
// Utils
import { IApiData, IngredientAttributes } from 'api';
import { WarningOrError } from './types';
import { checkIfRawMaterialIsStock } from './pricing-calculator.component';
// Constants
import {
  FORMULA_MISSING_MOQ_UNIT_WARNING,
  FORMULA_MISSING_MOQS_WARNING,
  MISSING_PRICE_WARNING,
} from './constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

export const generateExcessRawMaterialsWarnings = (
  ingredients?: IApiData<IngredientAttributes>[]
): WarningOrError[] => {
  if (!ingredients) return [];

  const warnings = [];

  const ingredientsWithMissingPrices = ingredients.filter(
    (ingredient) => !ingredient.attributes.highestPrice
  );

  ingredientsWithMissingPrices.length > 0 &&
    warnings.push({
      issue: MISSING_PRICE_WARNING.issue,
      actionItem: (
        <>
          {`${MISSING_PRICE_WARNING.actionItem} `}
          {ingredientsWithMissingPrices.map(
            (ingredient, index, ingredientsMissingPrices) => (
              <React.Fragment key={`${ingredient.id}-${index}`}>
                <Link
                  style={{ textDecoration: 'underline' }}
                  href={ROUTES.SHOW_RAW_MATERIAL.route.replace(
                    UUID_SHOW_ROUTE_STRING,
                    ingredient.attributes.rawMaterialUuid
                  )}
                >
                  {ingredient.attributes.rawMaterialRmId ||
                    `SAMP-${ingredient.attributes.rawMaterialSampleCode}`}
                </Link>
                {/* if there's an element after the current iteration then append a comma */}
                {ingredientsMissingPrices[index + 1] && ', '}
              </React.Fragment>
            )
          )}
        </>
      ),
      warningName: MISSING_PRICE_WARNING.warningName,
    });

  const ingredientsMissingMOQ = ingredients.filter((ingredient) => {
    // Check if the ingredient is a stock raw material
    const isStockRM = checkIfRawMaterialIsStock(ingredient);

    // Check if the ingredient has a price assigned to it
    const hasPrice = Boolean(ingredient.attributes.highestPrice);

    // Check if ingredient is missing a MOQ
    const isMissingMOQ = !ingredient.attributes.highestPrice!.moq;

    return hasPrice && !isStockRM && isMissingMOQ;
  });

  if (ingredientsMissingMOQ.length > 0) {
    warnings.push({
      issue: `${FORMULA_MISSING_MOQS_WARNING.issue}`,
      actionItem: (
        <>
          {FORMULA_MISSING_MOQS_WARNING.actionItem}
          <div className="flex flex-wrap space-x-1">
            {ingredientsMissingMOQ.map((ingredient, index, arr) => {
              return (
                <React.Fragment key={`${index}-${ingredient.id}`}>
                  <Link
                    style={{ textDecoration: 'underline' }}
                    href={ROUTES.SHOW_RAW_MATERIAL.route.replace(
                      UUID_SHOW_ROUTE_STRING,
                      ingredient.attributes.rawMaterialUuid
                    )}
                  >
                    {ingredient.attributes.rawMaterialRmId ||
                      `SAMP-${ingredient.attributes.rawMaterialSampleCode}`}
                  </Link>
                  {index !== arr.length - 1 && ','}
                </React.Fragment>
              );
            })}
          </div>
        </>
      ),
      warningName: FORMULA_MISSING_MOQS_WARNING.warningName,
    });
  }

  const ingredientsMissingMOQUnit = ingredients.filter((ingredient) => {
    // Check if the ingredient is a stock raw material
    const isStockRM = checkIfRawMaterialIsStock(ingredient);

    // Check if the ingredient has a price assigned to it
    const hasPrice = Boolean(ingredient.attributes.highestPrice);

    // Check if ingredient is missing a MOQ
    const hasMOQ = Boolean(ingredient.attributes.highestPrice!.moq);

    const isMissingMOQUnit = !ingredient.attributes.highestPrice?.moq_unit;
    return hasPrice && !isStockRM && hasMOQ && isMissingMOQUnit;
  });

  if (ingredientsMissingMOQUnit.length > 0) {
    warnings.push({
      issue: `${FORMULA_MISSING_MOQ_UNIT_WARNING.issue}`,
      actionItem: (
        <>
          {FORMULA_MISSING_MOQ_UNIT_WARNING.actionItem}
          <div className="flex flex-wrap space-x-1">
            {ingredientsMissingMOQUnit.map((ingredient, index, arr) => {
              return (
                <React.Fragment key={`${index}-${ingredient.id}`}>
                  <Link
                    style={{ textDecoration: 'underline' }}
                    href={ROUTES.SHOW_RAW_MATERIAL.route.replace(
                      UUID_SHOW_ROUTE_STRING,
                      ingredient.attributes.rawMaterialUuid || ''
                    )}
                  >
                    {ingredient.attributes.rawMaterialRmId ||
                      `SAMP-${ingredient.attributes.rawMaterialSampleCode}`}
                  </Link>
                  {index !== arr.length - 1 && ','}
                </React.Fragment>
              );
            })}
          </div>
        </>
      ),
      warningName: FORMULA_MISSING_MOQ_UNIT_WARNING.warningName,
    });
  }

  return warnings;
};
