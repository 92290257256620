// Libraries
import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';

// Components
import { FormSection } from 'features/ui';

// Utils
import { IRawMaterialFormValues } from './raw-material-form.component';

const NOTES = 'Notes';
const NOTES_SUBHEADER =
  'Additional information that has not been previously listed';

export const NotesSection: React.FC = () => {
  const {
    values: { notes },
    setFieldValue,
  } = useFormikContext<IRawMaterialFormValues>();

  const [isRevealed, setIsRevealed] = useState<boolean>(false);

  useEffect(() => {
    setIsRevealed(Boolean(notes));
  }, [notes]);

  return (
    <FormSection
      sectionHeader={NOTES}
      sectionSubheader={NOTES_SUBHEADER}
      isRevealed={isRevealed}
      setIsRevealed={setIsRevealed}
    >
      <Grid container item spacing={5} xs={12}>
        <Grid xs={12} item>
          <TextField
            variant="outlined"
            onChange={(event) => setFieldValue('notes', event.target.value)}
            value={notes}
            fullWidth
            multiline
            rows={5}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
