// Libraries
import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// Utils
import { THEME_PALETTE } from 'styles/mui/themeV2';

interface CalculationErrorAlertProps {
  errors: string[];
}

export const CalculationErrorAlert: React.FC<CalculationErrorAlertProps> = ({
  errors,
}) => {
  return (
    <Alert severity="error" className="mb-2">
      <Typography variant="body2" style={{ color: THEME_PALETTE.gray.dark }}>
        Project is missing information. Update the following to calculate costs:
      </Typography>
      <List
        dense
        style={{
          listStyleType: 'disc',
          marginLeft: '1rem',
          color: THEME_PALETTE.gray.dark,
        }}
      >
        {errors.map((errorText, index) => (
          <ListItem key={index} style={{ display: 'list-item' }} disableGutters>
            <ListItemText
              primary={errorText}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        ))}
      </List>
    </Alert>
  );
};
