// Libraries
import _ from 'lodash';
// Utils
import { UNITS } from 'features/types';
import { Tank } from './types';
// Constants
import { TANKS_BY_LOCATION } from './quote-calculator.constants';

export class QuoteCalculator {
  /**
   * ====== CONSTANTS ======
   *
   *
   *
   *
   *
   *
   *
   *
   *  */
  static BUY_FEE_PERCENTAGE = 0.2;
  static GRAMS_PER_POUND = 453.6;
  static HOURLY_PAY_RATE = 25;
  static MILLILITERS_PER_OUNCE = 29.57;
  static MINUTES_PER_HOUR = 60;
  static OUNCES_PER_GRAM = 0.035274;
  static OUNCES_PER_MILLILITER = 0.03381402;
  static OUNCES_PER_POUND = 16;
  static WORKING_HOURS = 7.83;

  /**
   *  ====== INITIALIZATION ======
   *
   *
   *
   *
   *
   *
   *
   *
   *  */
  formulaCost: Maybe<number>;
  laborCost: Maybe<number>;
  componentCost: Maybe<number>;
  orderQuantity: number;
  productionLocation: string;
  profit: number;
  size: number;
  unit: UNITS;

  constructor({
    orderQuantity,
    productionLocation,
    profit,
    size,
    unit,
  }: {
    orderQuantity: number;
    productionLocation: string;
    profit: number;
    size: number;
    unit: UNITS;
  }) {
    this.formulaCost = undefined;
    this.laborCost = undefined;
    this.componentCost = undefined;
    this.orderQuantity = orderQuantity;
    this.productionLocation = productionLocation;
    this.profit = profit;
    this.size = size;
    this.unit = unit;
  }

  /**
   *  ====== PUBLIC METHODS ======
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */

  public calculateLaborCost({
    efficiencyPercentage,
    runRate,
    compoundingHeadCount,
    preworkHeadCount,
    operatorHeadCount,
    cappingHeadCount,
    packingPalletizerHeadCount,
    unitCartonHeadCount,
  }: {
    efficiencyPercentage: number;
    runRate: number;
    compoundingHeadCount: number;
    preworkHeadCount: number;
    operatorHeadCount: number;
    cappingHeadCount: number;
    packingPalletizerHeadCount: number;
    unitCartonHeadCount: number;
  }) {
    // Calculate labor cost
    const laborEfficiency = this.calculateLaborEfficiency({
      efficiencyPercentage,
      runRate,
    });

    const tankSizeAndUnitOutput = this.calculateTankSizeAndUnitOutput();

    const { WORKING_HOURS, HOURLY_PAY_RATE } = QuoteCalculator;

    const laborCostCompounding =
      (compoundingHeadCount * HOURLY_PAY_RATE * WORKING_HOURS) /
      tankSizeAndUnitOutput.unitOutput!;

    const nonCostCompoundingHeadCount = _.sum([
      preworkHeadCount,
      operatorHeadCount,
      cappingHeadCount,
      unitCartonHeadCount,
      packingPalletizerHeadCount,
    ]);

    const laborCostNonCompounding = this.calculateCostFromHeadCount({
      headCount: nonCostCompoundingHeadCount,
      laborEfficiency,
    });

    this.laborCost = laborCostCompounding + laborCostNonCompounding;

    return {
      headCountCost: this.laborCost,
      tank: tankSizeAndUnitOutput,
    };
  }

  public calculateFormulaCost(ingredients: any) {
    // Get ingredients from formula
    // Calculate total ingredient cost / lb

    const totalCostPerPound = _.sum(
      ingredients.map((ingredient: any) =>
        Number(ingredient.pricePerLb * (ingredient.weight / 100))
      )
    );

    const { OUNCES_PER_POUND } = QuoteCalculator;

    // Calculate total ingredient cost / oz
    const totalCostPerOunce = totalCostPerPound / OUNCES_PER_POUND;

    // Calculate total ingredient cost / size & unit
    const totalCostPerSizeAndUnit = ((unit: UNITS) => {
      const { GRAMS_PER_POUND, MILLILITERS_PER_OUNCE } = QuoteCalculator;

      switch (unit) {
        case UNITS.oz:
          return totalCostPerOunce * this.size;
        case UNITS.g:
          return (totalCostPerPound / GRAMS_PER_POUND) * this.size;
        case UNITS.ml:
          return (totalCostPerOunce / MILLILITERS_PER_OUNCE) * this.size;
        default:
          return 0;
      }
    })(this.unit);

    this.formulaCost = totalCostPerSizeAndUnit;

    return {
      totalCostPerPound,
      totalCostPerOunce,
      totalCostPerSizeAndUnit,
    };
  }

  public calculateComponentsCost({
    masterBox,
    additionalComponentOne,
    additionalComponentTwo,
    additionalComponentThree,
  }: {
    masterBox: number;
    additionalComponentOne: number;
    additionalComponentTwo: number;
    additionalComponentThree: number;
  }): {
    componentSubtotal: number;
    buyFee: number;
    componentTotalCost: number;
  } {
    // Sum up the components subtotal cost
    const componentSubtotal = _.sum([
      masterBox,
      additionalComponentOne,
      additionalComponentTwo,
      additionalComponentThree,
    ]);

    // Calculate buy fee
    const buyFee = componentSubtotal * QuoteCalculator.BUY_FEE_PERCENTAGE; // 20%

    // Calculate component total cost
    const componentTotalCost = componentSubtotal + buyFee;

    this.componentCost = componentTotalCost;

    return {
      componentSubtotal,
      buyFee,
      componentTotalCost,
    };
  }

  public calculateMargin() {
    const totalCost = this.formulaCost! + this.laborCost! + this.componentCost!;
    const revenue = totalCost + this.profit;

    const margin = (this.profit / revenue) * 100;

    return {
      totalCost,
      margin,
    };
  }

  public getContributionPerRun() {
    return this.profit * this.orderQuantity;
  }

  /**
   *  ====== PRIVATE METHODS ======
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */
  private calculateLaborEfficiency({
    efficiencyPercentage,
    runRate,
  }: {
    efficiencyPercentage: number;
    runRate: number;
  }): number {
    // efficiencyPercentage comes in as a percentage, so we need to convert it to a decimal
    const convertedEfficiencyPercentage = efficiencyPercentage / 100.0;

    return convertedEfficiencyPercentage * runRate;
  }

  private calculateCostFromHeadCount({
    headCount,
    laborEfficiency,
  }: {
    headCount: number;
    laborEfficiency: number;
  }) {
    if (laborEfficiency === 0) return 0;

    const {
      WORKING_HOURS,
      HOURLY_PAY_RATE,
      MINUTES_PER_HOUR,
    } = QuoteCalculator;

    return (
      (headCount * HOURLY_PAY_RATE * WORKING_HOURS) /
      (laborEfficiency * MINUTES_PER_HOUR * WORKING_HOURS)
    );
  }

  private calculateTankSizeAndUnitOutput() {
    // create a duplicate so it can be modified in place without potentially
    // polluting the object
    const tanks = TANKS_BY_LOCATION[this.productionLocation].map((tank) => ({
      ...tank,
    }));

    const tankCombinationForOrderQuantityAndLocation = this.determineTankCombinationForOrderQuantityAndLocation(
      {
        tanksAtLocation: tanks,
        orderQuantity: this.orderQuantity,
        results: [],
      }
    );

    const tank = tankCombinationForOrderQuantityAndLocation?.reduce(
      (acc: Tank, cv: Tank) => {
        acc.gallons += cv.gallons;
        acc.unitOutput! += cv.unitOutput!;
        return acc;
      },
      {
        gallons: 0,
        unitOutput: 0,
      } as Tank
    );

    return {
      ...tank, // the cumulative value of all the tanks combined
      unitOutput: _.round(tank?.unitOutput || 0),
      tanks: tankCombinationForOrderQuantityAndLocation, // all the tanks in one list, helps determine compounding headcount (tanks.length * compoundingHeadCount)
    } as Tank & { tanks: Tank[] };
  }

  // NOTE: The most accurate calculation would involve converting the unit to fluid ounces.
  // but we cannot do this because we do not know the density of the product. So we are using
  // the standard ounce.
  private convertContainerSizeToOunce() {
    const { OUNCES_PER_MILLILITER, OUNCES_PER_GRAM } = QuoteCalculator;

    switch (this.unit) {
      case UNITS.oz:
        return this.size;
      case UNITS.ml:
        return this.size * OUNCES_PER_MILLILITER; // ounces per ml
      case UNITS.g:
        return this.size * OUNCES_PER_GRAM; // ounces per gram
      default:
        throw new Error(`Unit type not supported: ${this.unit}`);
    }
  }

  private determineTankCombinationForOrderQuantityAndLocation({
    tanksAtLocation,
    orderQuantity,
    results,
  }: {
    tanksAtLocation: Array<Tank>;
    orderQuantity: number;
    results: Array<Tank>;
  }): any {
    // stop condition is we ran out of tanks or there is no remaining MOQ
    if (tanksAtLocation.length <= 0 || orderQuantity <= 0) return results;

    for (let index = 0; index <= tanksAtLocation.length; index++) {
      const tank = tanksAtLocation[index];
      const { gallons } = tank;

      // We are using fluid ounces as the standard unit of measurement for conversion from oz, ml, and g
      const tankCapacityInFluidOunces = gallons * 128; // fluid ounces per gallon

      const tankUnitOutputInFluidOunces =
        tankCapacityInFluidOunces / this.convertContainerSizeToOunce();

      // max & min are used to ensure we are always subtracting the smaller number from the larger number
      const remainingOrderQuantity =
        Math.max(tankUnitOutputInFluidOunces, orderQuantity) -
        Math.min(tankUnitOutputInFluidOunces, orderQuantity);

      if (tankUnitOutputInFluidOunces >= orderQuantity) {
        // We have a tank that can fulfill the order quantity
        return [
          ...results,
          {
            gallons,
            unitOutput: tankUnitOutputInFluidOunces,
          },
        ];
      } else {
        const isNextTankAvailable = Boolean(tanksAtLocation.at(index + 1));

        // We are at the largest available tank we can utilize
        if (!isNextTankAvailable) {
          tank.quantity!--;

          // remove the tank so it no longer can be considered
          if (tank.quantity! <= 0) tanksAtLocation.splice(index, 1);

          // recursively call the function with the remaining order quantity and the updated tanks list
          return this.determineTankCombinationForOrderQuantityAndLocation({
            tanksAtLocation,
            orderQuantity: remainingOrderQuantity,
            results: [
              ...results,
              {
                gallons,
                unitOutput: tankUnitOutputInFluidOunces,
              },
            ],
          });
        }
      }
    }

    // stop condition is we ran out of tanks or there is no remaining order quantity to account for
    if (tanksAtLocation.length <= 0 || orderQuantity <= 0) return results;
  }
}
