// Libraries
import React from 'react';
import { Tabs, Tab, makeStyles, createStyles } from '@material-ui/core';
// Utils
import {
  ITheme,
  THEME_PALETTE,
  gTAmericaMonoRegular,
} from 'styles/mui/themeV2';
// Helpers
const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    iconWrapper: {
      display: 'block !important',
    },
    tabs: {
      borderRadius: '0.25rem',
    },
    tab: {
      fontFamily: gTAmericaMonoRegular.fontFamily,
      width: '156px',
    },
  })
);

type TabProps = {
  disabled?: boolean;
  label: string;
  icon?: Maybe<
    string | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  >;
};

interface TabbedMenuProps {
  backgroundColor?: string;
  color?: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  indicatorColor?: string;
  tabValue: number;
  tabs: TabProps[];
}

export const TabbedMenu: React.FC<TabbedMenuProps> = ({
  backgroundColor,
  color,
  handleTabChange,
  indicatorColor,
  tabValue,
  tabs,
}) => {
  const classes = useStyles({ backgroundColor, color });

  const renderTabs = () => {
    return tabs.map(({ disabled, label, icon }, index) => {
      return (
        <Tab
          key={`tab-${index}`}
          className={classes.tab}
          icon={icon}
          disabled={disabled}
          label={label}
          classes={{
            wrapper: classes.iconWrapper,
          }}
          {...a11yProps(index)}
        />
      );
    });
  };

  return (
    <Tabs
      style={{
        backgroundColor: backgroundColor || THEME_PALETTE.gray.dark,
        color: color || THEME_PALETTE.secondary.main,
      }}
      className={classes.tabs}
      variant={'scrollable'}
      value={tabValue}
      onChange={handleTabChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: indicatorColor,
        },
      }}
    >
      {renderTabs()}
    </Tabs>
  );
};
