// Libraries
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// Utils
import { useApi } from 'api';
import { useSnackbar } from 'hooks';
// Assets
import {
  LinkIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { DocumentIcon } from '@heroicons/react/24/outline';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

export const FileView: React.FC = () => {
  const { projectUuid, fileUuid } = useParams<{
    projectUuid: string;
    fileUuid: string;
  }>();
  const { getProjectFileUrl, getProjectFile, deleteProjectFile } = useApi();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // state
  const [file, setFile] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getProjectFile({
      urlParams: `${projectUuid}/documents/${fileUuid}`,
      handleSuccess: (data) => {
        setFile(data);
        setIsLoading(false);
      },
    });
  }, [getProjectFile, projectUuid, fileUuid]);

  const handleFileDownload = () => {
    getProjectFileUrl({
      urlParams: `${projectUuid}/documents/${fileUuid}?format=url`,
      handleSuccess: ({ data }) => {
        let a = document.createElement('a');
        a.href = data.presigned_url;
        a.download = data.name;
        a.click();
        a.remove();
      },
    });
  };

  const handleFileDelete = () => {
    if (isDeleting) return;
    if (!window.confirm(`Delete ${file?.attributes?.name}?`)) return;
    setIsDeleting(true);

    deleteProjectFile({
      urlParams: `${projectUuid}/documents/${fileUuid}`,
      handleSuccess: ({ data }) => {},
      handleFinally: () =>
        navigate(
          ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
            UUID_SHOW_ROUTE_STRING,
            projectUuid!
          ) + '?tab=1'
        ),
    });
  };

  const handleShareLink = () => {
    const shareUrl = `${window.location.origin}/projects/${projectUuid}/files/${fileUuid}`;
    navigator.clipboard.writeText(shareUrl);
    showSnackbar('Link copied to clipboard', 'success');
  };

  const handleClose = () => {
    navigate(
      ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
        UUID_SHOW_ROUTE_STRING,
        projectUuid!
      ) + '?tab=1'
    );
  };

  const uploaderName =
    file?.createdBy.attributes.givenName +
    ' ' +
    file?.createdBy.attributes.familyName.charAt(0);

  const uploadDate =
    typeof file?.attributes.createdAt === 'string'
      ? new Date(file?.attributes.createdAt)?.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : file?.attributes.createdAt?.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });

  return (
    <div className="h-screen flex justify-center items-center">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="sm:w-1/4">
          <div className="flex justify-end mb-3">
            <XMarkIcon
              className="h-6 w-6 cursor-pointer text-black"
              onClick={handleClose}
            />
          </div>

          <div className="bg-grey-95 h-80 sm:h-96 flex items-center mb-3 rounded">
            <DocumentIcon className="h-6 w-6 mx-auto text-[#6d7e93]" />
          </div>

          <div className="flex border border-grey-90 rounded items-center border-primary-100 justify-between">
            <div className="pt-4 pb-4 pl-4 w-3/5">
              <div className="text-left">
                <p
                  className="text-base font-inter text-black truncate text-ellipsis overflow-hidden"
                  title={file?.attributes?.name}
                >
                  {file?.attributes?.name}
                </p>
              </div>
              <div className="text-left text-wrap">
                <p className="text-sm font-inter text-grey-50">
                  Uploaded by {uploaderName} on {uploadDate}
                </p>
              </div>
            </div>

            <div className="flex justify-center space-x-4 w-2/5 text-grey-20">
              <LinkIcon
                className="h-5 w-5 cursor-pointer"
                onClick={handleShareLink}
              />
              <ArrowDownTrayIcon
                className="h-5 w-5 cursor-pointer"
                onClick={handleFileDownload}
              />
              <TrashIcon
                className="h-5 w-5 cursor-pointer"
                onClick={handleFileDelete}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
