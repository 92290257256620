// Libraries
import React from 'react';
import _ from 'lodash';

enum Direction {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

type DirectionType = `${Direction}`;

interface DividerProps {
  direction?: DirectionType;
  [key: string]: any;
}

export const Divider: React.FC<DividerProps> = ({
  direction = Direction.HORIZONTAL,
  ...rest
}: DividerProps) => {
  const getDirectionalStyle = () => {
    switch (direction) {
      case Direction.HORIZONTAL:
        return 'h-[1px]';
      case Direction.VERTICAL:
        return 'w-[1px] h-full bg-grey-90';
      default:
        return 'h-[1px]';
    }
  };

  return (
    <hr
      className={`${getDirectionalStyle()} text-grey-90 ${rest.className}`}
      {..._.omit(rest, 'className')}
    />
  );
};
