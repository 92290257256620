// Libraries
import React, { useState } from 'react';
// Utils
import { CustomerViewWarningContext } from './customer-warning.context';

interface ICustomerViewWarningProvider {
  children: React.ReactNode[] | React.ReactNode;
}

export const CustomerViewWarningProvider: React.FC<ICustomerViewWarningProvider> = ({
  children,
}) => {
  const [showCustomerViewWarning, setShowCustomerViewWarning] = useState(true);
  return (
    <CustomerViewWarningContext.Provider
      value={{ showCustomerViewWarning, setShowCustomerViewWarning }}
    >
      {children}
    </CustomerViewWarningContext.Provider>
  );
};
