// Libraries
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
// Utils
import { CustomerBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';
import { COMPLIANT_COUNTRIES } from '../form-modules/country-compliance.component';

interface RtlDetectorProps {
  filteredRtlFormulas: any[];
  setProjectIsRtl: (isRtl: boolean) => void;
}

export const RtlDetector: React.FC<RtlDetectorProps> = ({
  filteredRtlFormulas,
  setProjectIsRtl,
}) => {
  const { values } = useFormikContext<CustomerBriefFormValues>();

  useEffect(() => {
    // We are RTL if the product category hasn't been selected yet.
    // We are also in RTL as long as there's a product type selected
    // and there are RTL formulas available.
    let isRtl = true;

    // For now, we will do an additional check here to confirm that the only country selected is the US.
    // Otherwise, we are not in RTL. This is preferential to adding a property to every RTL formula,
    // but we will need to adjust this when we have formulas that are RTL for multiple countries.
    const countries = values[BRIEF_FORM_KEYS.COUNTRIES];
    const rtlCountryCompliance = Boolean(
      countries.length === 0 ||
        (countries.length === 1 && countries.includes(COMPLIANT_COUNTRIES[0]))
    );

    if (!values[BRIEF_FORM_KEYS.PRODUCT_CATEGORY].value) {
      isRtl = true;
    } else {
      isRtl = Boolean(filteredRtlFormulas.length) && rtlCountryCompliance;
    }

    setProjectIsRtl(isRtl);
  }, [values, setProjectIsRtl, filteredRtlFormulas]);

  return null;
};

export default RtlDetector;
