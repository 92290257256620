// Libraries
import React from 'react';
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
// Components
import { TeamMemberFormik } from '../team-member-formik.component';
// Constants
import { TEAM_MEMBER_FIELD_NAMES } from '../team-management.constants';
import { CustomerAttributes, IApiData, useApi } from 'api';
import { TeamMemberFormValues } from '../types';
import { FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'hooks';

interface EditTeamMemberFormProps {
  teamMember: IApiData<CustomerAttributes>;
  setTeamMembers: React.Dispatch<
    React.SetStateAction<IApiData<CustomerAttributes>[]>
  >;
  handleClose: () => void;
}

export const EditTeamMemberForm: React.FC<EditTeamMemberFormProps> = ({
  teamMember,
  setTeamMembers,
  handleClose,
}) => {
  const { showSnackbar } = useSnackbar();
  const { patchCustomer } = useApi();

  const initialValues: TeamMemberFormValues = {
    givenName: teamMember.attributes.givenName || '',
    familyName: teamMember.attributes.familyName || '',
    email: teamMember.attributes.email || '',
  };

  const handleUpdateTeamMemberAndInvite = (
    values: TeamMemberFormValues,
    { setSubmitting }: FormikHelpers<TeamMemberFormValues>
  ) => {
    setSubmitting(true);

    patchCustomer({
      urlParams: `${teamMember.id}?updateAndInvite=true`,
      data: {
        customer: {
          givenName: values.givenName,
          familyName: values.familyName,
          email: values.email,
        },
      },
      handleSuccess: (data: IApiData<CustomerAttributes>) => {
        showSnackbar('Team member updated & invited successfully', 'success');
        setTeamMembers((prev) =>
          prev.map((tm) => (tm.id === teamMember.id ? data : tm))
        );

        handleClose();
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  return (
    <TeamMemberFormik
      initialValues={initialValues}
      handleSubmit={handleUpdateTeamMemberAndInvite}
    >
      {({
        getFieldProps,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }: FormikProps<TeamMemberFormValues>) => (
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={3} style={{ marginBottom: 43 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.givenName)}
                label="First name"
                fullWidth
                error={!!(errors.givenName && touched.givenName)}
                helperText={
                  errors.givenName && touched.givenName ? errors.givenName : ''
                }
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.familyName)}
                error={touched.familyName && !!errors.familyName}
                fullWidth
                helperText={touched.familyName && errors.familyName}
                label="Last name"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.email)}
                error={touched.email && !!errors.email}
                fullWidth
                helperText={
                  // When a team member has a thirdPartyId,
                  //  it means they have an Auth0 account.
                  //  In this case, the email can't be changed.
                  teamMember.attributes.thirdPartyId
                    ? 'Email can’t be changed.'
                    : touched.email && errors.email
                }
                label="Work email"
                required
                variant="outlined"
                disabled={
                  // When a team member has a thirdPartyId,
                  //  it means they have an Auth0 account.
                  //  In this case, the email can't be changed.
                  //  so the  email field should be disabled.
                  Boolean(teamMember.attributes.thirdPartyId)
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}></Grid>
          <Grid container item xs={12} sm={4} spacing={2}>
            <Grid item xs={6}>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                fullWidth
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="large"
                variant="outlined"
                fullWidth
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={6}
                    />
                  )
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </TeamMemberFormik>
  );
};
