// Libraries
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import * as _ from 'lodash';
// Components
import { SectionHeader } from '../section-header.component';
import { TextField } from 'design-system';
// Utils
import { SectionProps } from './types';
// Constants
import { laborCostInputs, WORKSHEET_FORM_KEYS } from '../constants';

export const LaborSection: React.FC<SectionProps> = ({ isActive }) => {
  const { getFieldProps, values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    const calculateHeadCountTotal = (keysToSum: string[]) => {
      const total = _.sum(_.at(values, keysToSum));
      return total;
    };

    setFieldValue(
      WORKSHEET_FORM_KEYS.TOTAL_HEAD_COUNT_ONE,
      calculateHeadCountTotal([
        WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_ONE,
        WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_ONE,
        WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_ONE,
        WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_ONE,
        WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_ONE,
        WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_ONE,
      ])
    );

    setFieldValue(
      WORKSHEET_FORM_KEYS.TOTAL_HEAD_COUNT_TWO,
      calculateHeadCountTotal([
        WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_TWO,
        WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_TWO,
        WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_TWO,
        WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_TWO,
        WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_TWO,
        WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_TWO,
      ])
    );

    setFieldValue(
      WORKSHEET_FORM_KEYS.TOTAL_HEAD_COUNT_THREE,
      calculateHeadCountTotal([
        WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_THREE,
        WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_THREE,
        WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_THREE,
        WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_THREE,
        WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_THREE,
        WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_THREE,
      ])
    );
  }, [setFieldValue, values]);

  return (
    <div
      className={`${isActive ? 'h-auto' : 'h-0 overflow-hidden'} flex flex-col`}
    >
      <SectionHeader title="Labor" checkboxLabel="Operations Review Complete" />
      <div className="px-3 bg-white">
        {laborCostInputs.map((laborCost: any) => {
          return (
            <div className="grid grid-cols-10 py-[6px] items-center min-h-[44px]">
              <span className="col-span-2 text-grey-50">{laborCost.title}</span>
              {laborCost.formikKeys &&
                laborCost.formikKeys.map((formikKey: string) => {
                  return (
                    <div className="col-span-2 mr-6">
                      <TextField
                        type="number"
                        {...getFieldProps(formikKey)}
                        height="h-11"
                        trailingText={laborCost?.trailingText}
                      />
                    </div>
                  );
                })}
            </div>
          );
        })}
        <div className="grid grid-cols-10 py-[6px] items-center min-h-[44px]">
          <span className="col-span-2 text-grey-50">Total Head Count</span>
          <span className="col-span-2 mr-6">
            {values[WORKSHEET_FORM_KEYS.TOTAL_HEAD_COUNT_ONE]}
          </span>
          <span className="col-span-2 mr-6">
            {values[WORKSHEET_FORM_KEYS.TOTAL_HEAD_COUNT_TWO]}
          </span>
          <span className="col-span-2 mr-6">
            {values[WORKSHEET_FORM_KEYS.TOTAL_HEAD_COUNT_THREE]}
          </span>
        </div>
      </div>
    </div>
  );
};
