// Libraries
import React, { useEffect } from 'react';
// Utils
import { useApi } from 'api';
import { useSnackbar } from 'hooks';

interface IRtlOrderSuccessListenerProps {
  projectUuid: string;
}

export const RtlOrderSuccessListener: React.FC<IRtlOrderSuccessListenerProps> = ({
  projectUuid,
}) => {
  const { showSnackbar } = useSnackbar();
  const { patchProjectOrderSuccess } = useApi();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const success = query.get('success');
    const sessionId = query.get('session_id');
    const orderId = query.get('order_id');

    if (success) {
      patchProjectOrderSuccess({
        urlParams: `${projectUuid}/orders/${orderId}/success?session_id=${sessionId}`,
        handleSuccess: () => {
          showSnackbar('Order made successfully', 'success');
        },
        handleError: Rollbar.error,
        data: {},
      });
    }
  }, [patchProjectOrderSuccess, projectUuid, showSnackbar]);

  return null;
};
