//Libraries
import React from 'react';
import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
// Utils
import { TestGroup, ThirdPartyTestFormValues } from './types';
import { NAVBAR_HEIGHT } from 'features/navigation';

export const TestSection: React.FC<{
  testGroup: TestGroup;
  setValidationSchema: React.Dispatch<React.SetStateAction<any>>;
  isLocked?: boolean;
}> = ({
  testGroup: { sectionTitle, tests },
  setValidationSchema,
  isLocked,
}) => {
  const { setValues, values, getFieldProps, errors } = useFormikContext<
    ThirdPartyTestFormValues
  >();

  const handleWholeSectionCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (checked) {
      const newValues = tests.reduce((acc, { name, defaultCost }) => {
        acc[name] = defaultCost;
        acc[`${name}Initials`] = '';
        return acc;
      }, {} as any);
      setValues({ ...values, ...newValues });
      setValidationSchema((prev: any) => {
        return prev.shape(
          tests.reduce((acc, { name, label }) => {
            acc[name] = Yup.number().min(0).required().label(`${label} cost`);
            return acc;
          }, {} as any)
        );
      });
    } else {
      const newValues = tests.reduce((acc, { name }) => {
        acc[name] = null;
        acc[`${name}Initials`] = null;
        return acc;
      }, {} as any);
      setValues({ ...values, ...newValues });
      setValidationSchema((prev: any) => {
        return prev.shape(
          tests.reduce((acc, { name }) => {
            acc[name] = Yup.mixed().nullable().notRequired();
            return acc;
          }, {} as any)
        );
      });
    }
  };

  const testsToRender = isLocked
    ? tests.filter(({ name }) => values[name])
    : tests;

  return (
    <section className="relative">
      <div
        className="sticky z-50 h-9 flex border border-grey-90 rounded-sm bg-lightestGray"
        style={{ top: NAVBAR_HEIGHT }}
      >
        {!isLocked && (
          <span className="w-1/12 flex items-center justify-center">
            <input
              type="checkbox"
              className="w-[18px] h-[18px] accent-darkGray"
              checked={tests.every(({ name }) => Boolean(values[name]))}
              onChange={handleWholeSectionCheck}
            />
          </span>
        )}
        <span
          className={`flex w-11/12 items-center justify-start ${
            isLocked ? 'ml-6' : ''
          }`}
        >
          <h6 className="font-mono text-[12px] font-medium">{sectionTitle}</h6>
        </span>
      </div>
      {testsToRender.map(({ label, defaultCost, name }) => (
        <div
          key={name}
          className="flex border border-grey-90 border-t-0 min-h-[80px]"
        >
          {!isLocked && (
            <span className="w-1/12 flex items-center justify-center border-r border-grey-90">
              <input
                type="checkbox"
                className="w-[18px] h-[18px] accent-darkGray"
                name={name}
                checked={Boolean(values[name])}
                onChange={(e) => {
                  setValues({
                    ...(values as any),
                    [name]: e.target.checked ? defaultCost : null,
                    [`${name}Initials`]: e.target.checked ? '' : null,
                  });

                  // Turn validation on/off for this text field when checked
                  setValidationSchema((prev: any) => {
                    if (e.target.checked) {
                      return prev.shape({
                        [name]: Yup.number()
                          .min(0)
                          .required()
                          .label(`${label} cost`),
                      });
                    } else {
                      return prev.shape({
                        [name]: Yup.mixed().nullable().notRequired(),
                      });
                    }
                  });
                }}
              />
            </span>
          )}
          <span className="flex w-6/12 items-center justify-start border-r border-grey-90 p-6">
            <h6 className="font-inter text-[16px]">{label}</h6>
          </span>
          <span className="flex w-5/12 items-center justify-start p-6">
            {isLocked ? (
              <h6 className="font-inter text-[16px] text-lightGray">
                ${(values[name] || 0)?.toLocaleString('en-US')}
              </h6>
            ) : values[name] !== null && values[name] !== undefined ? (
              <TextField
                {...getFieldProps(name)}
                type="number"
                variant="outlined"
                label="Cost"
                error={Boolean(errors[name])}
                helperText={(errors[name] as string) || ''}
                className="border border-grey-90 rounded-sm w-full"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  startAdornment: (
                    <span className="text-[16px] mr-[1px]">$</span>
                  ),
                }}
                onChange={(e) => {
                  setValues({
                    ...(values as any),
                    [name]: e.target.value,
                  });
                }}
                required
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            ) : (
              <h6 className="font-inter text-[16px] text-lightGray">
                {defaultCost ? `$${defaultCost.toLocaleString('en-US')}` : 0}
              </h6>
            )}
          </span>
        </div>
      ))}
    </section>
  );
};
