// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
// Components
import StaticTable from './static-table.component';
// Utils
import { DataRow, IPricingData, PricingQuoteFormikValues, Tank } from './types';
import {
  BriefAttributes,
  FormulaAttributes,
  IApiData,
  IngredientAttributes,
  PricingQuoteAttributes,
} from 'api';
import {
  calculateComponentCostPerContainer,
  calculateExcessRawMaterials,
  calculateFormulaCostPerContainer,
  calculateLaborCostPerContainer,
  calculateTankUnitOutput,
  calculateTotalBatchSize,
  calculateTotalCostWithProfit,
  determineMarginTarget,
} from './pricing-calculator.component';
// Constants
import {
  EXCESS_RAWS_TABLE_HEADERS,
  NA,
  PRODUCTION_LOCATIONS,
  PRODUCTION_TANKS,
} from './constants';

interface IExcessRawMaterialChart {
  brief: BriefAttributes;
  formula: IApiData<FormulaAttributes>;
  ingredients: Maybe<IApiData<IngredientAttributes>[]>;
  pricingData: Maybe<IPricingData>;
  pricingQuote: Maybe<IApiData<PricingQuoteAttributes>>;
  productionLocation: Maybe<valueof<typeof PRODUCTION_LOCATIONS>>;
  productionTank: Maybe<
    Tank & {
      tanks: Tank[];
    }
  >;
}

export const ExcessRawMaterialChart: React.FC<IExcessRawMaterialChart> = ({
  brief,
  formula,
  ingredients,
  pricingData,
  pricingQuote,
  productionLocation,
  productionTank,
}) => {
  const { values } = useFormikContext<PricingQuoteFormikValues>();

  const getTankDataRows = (): Array<Maybe<DataRow>> => {
    if (
      !brief ||
      !formula ||
      !ingredients ||
      !pricingData ||
      !productionLocation
    )
      return [];

    return PRODUCTION_TANKS[productionLocation].map((tank) => {
      // This is the tank that is calculated in the parent
      const isCurrentTank = tank.gallons === productionTank?.gallons;

      if (!brief?.briefSupplement) return undefined;

      const tankUnitOutput = _.round(
        calculateTankUnitOutput(brief, tank.gallons),
        0
      );
      const componentCost = calculateComponentCostPerContainer(
        brief.briefSupplement.data.attributes,
        {
          unitOutput: tankUnitOutput,
          gallons: tank.gallons,
        }
      );
      const formulaCost = calculateFormulaCostPerContainer(brief, ingredients);
      const laborCost = calculateLaborCostPerContainer(
        brief,
        values,
        productionLocation,
        { unitOutput: tankUnitOutput, gallons: tank.gallons }
      );

      const totalCostPerContainer = componentCost + formulaCost + laborCost;
      const totalCostWithProfit = calculateTotalCostWithProfit(
        totalCostPerContainer,
        values.marginTargetPercentage
      );
      const profit = _.round(totalCostWithProfit - totalCostPerContainer, 2);
      const totalContributionPerRun = _.round(
        profit * tankUnitOutput,
        2
      ).toLocaleString('en-US');

      const totalBatchSize = calculateTotalBatchSize({
        ...brief,
        minimumOrderQuantity: tankUnitOutput,
      });
      const totalExcessRawMaterials = calculateExcessRawMaterials({
        ingredients,
        profit,
        totalBatchSize,
        productionTank: { unitOutput: tankUnitOutput, gallons: tank.gallons },
      });

      return {
        isHighlighted: isCurrentTank,
        values: [
          `${
            pricingQuote
              ? values.marginTargetPercentage
              : determineMarginTarget({
                  unitOutput: tankUnitOutput,
                  gallons: tank.gallons,
                })
          }%`,
          `${tank.gallons.toLocaleString('en-US')} Gallon`,
          parseInt(tankUnitOutput.toFixed(0)).toLocaleString('en-US'),
          `$${_.round(totalCostPerContainer, 2).toFixed(2)}`,
          `$${_.round(profit, 2).toFixed(2)}`,
          `$${_.round(totalCostWithProfit, 2).toFixed(2)}`,
          `$${totalContributionPerRun}`,
          totalExcessRawMaterials?.percentageOfExcessRawMaterials
            ? `${_.round(
                totalExcessRawMaterials?.percentageOfExcessRawMaterials,
                2
              ).toFixed(2)}%`
            : NA,
          totalExcessRawMaterials?.totalExcessValue
            ? `$${_.round(totalExcessRawMaterials?.totalExcessValue, 2)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            : NA,
        ],
      };
    });
  };

  return (
    <StaticTable
      headers={EXCESS_RAWS_TABLE_HEADERS}
      tableData={getTankDataRows()}
    />
  );
};
