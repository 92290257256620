// Libraries
import React from 'react';
import { createStyles, makeStyles, IconButton } from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';
// Assets
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    default: {
      color: theme.palette.secondary.dark,
    },
  })
);

export const DeleteButton: React.FC<any> = (props) => {
  const classes = useStyles();

  return (
    <IconButton {...props} className={classes.default}>
      <DeleteIcon />
    </IconButton>
  );
};
