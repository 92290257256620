// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import { CategoryCard } from './category-card.component';
import { FormLayout } from '../layout';
import { WizardContext } from '../wizard/wizard.context';
// Utils
import { useAnalytics, useMobile } from 'hooks';
import { ProductFormat } from './types';
// Assets
import bodyCareImage from '../../assets/bodyCare.png';
import bodyCareImageMobile from '../../assets/bodyCareMobile.png';
import makeupImage from '../../assets/makeup.png';
import makeupImageMobile from '../../assets/makeupMobile.png';
import fragranceImage from '../../assets/fragrance.png';
import fragranceImageMobile from '../../assets/fragranceMobile.png';
import hairCareImage from '../../assets/hairCare.png';
import hairCareImageMobile from '../../assets/hairCareMobile.png';
import skinCareImage from '../../assets/skinCare.png';
import skinCareImageMobile from '../../assets/skinCareMobile.png';
import sunCareImage from '../../assets/sunCare.png';
import sunCareImageMobile from '../../assets/sunCareMobile.png';
import swatch1 from '../../assets/swatch1.png';
import swatch2 from '../../assets/swatch2.png';
import swatch3 from '../../assets/swatch3.png';
import swatch4 from '../../assets/swatch4.png';
import swatch5 from '../../assets/swatch5.png';
import swatch6 from '../../assets/swatch6.png';
import swatch7 from '../../assets/swatch7.png';
import swatch8 from '../../assets/swatch8.png';
import swatch9 from '../../assets/swatch9.png';
import swatch10 from '../../assets/swatch10.png';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';
import * as AnalyticConstants from 'features/analytics/analytics.constants';

type Product = {
  name: string;
  formats: ProductFormat[];
  image: JSX.Element;
  mobileImage: JSX.Element;
};

type CategoriesAndAvailability = {
  productCategories: Product[];
  available: boolean;
};

const LABEL = 'NOT CURRENTLY SUPPORTED';

export const PRODUCT_CATEGORIES_AND_TYPES = {
  available: [
    {
      name: 'Skin Care',
      formats: [
        {
          name: 'Moisturizer',
          image: <img height="72" width="72" src={swatch1} alt="Moisturizer" />,
        },
        {
          name: 'Cleanser',
          image: <img height="72" width="72" src={swatch2} alt="Cleanser" />,
        },
        {
          name: 'Serum',
          image: <img height="72" width="72" src={swatch3} alt="Serum" />,
        },
        {
          name: 'Scrub',
          image: <img height="72" width="72" src={swatch4} alt="Scrub" />,
        },
        {
          name: 'Toner',
          image: <img height="72" width="72" src={swatch5} alt="Toner" />,
        },
        {
          name: 'Lip balm',
          image: <img height="72" width="72" src={swatch6} alt="Lip balm" />,
        },
      ],
      image: (
        <img
          className="h-full object-cover"
          height="188"
          width="188"
          src={skinCareImage}
          alt="Skin care"
        />
      ),
      mobileImage: (
        <img
          height="50"
          className="max-h-[50px] w-full object-cover"
          src={skinCareImageMobile}
          alt="Skin care"
        />
      ),
    },
    {
      name: 'Hair Care',
      formats: [
        {
          name: 'Shampoo',
          image: <img height="72" width="72" src={swatch9} alt="Shampoo" />,
        },
        {
          name: 'Conditioner',
          image: <img height="72" width="72" src={swatch7} alt="Conditioner" />,
        },
        {
          name: 'Serum',
          image: <img height="72" width="72" src={swatch3} alt="Serum" />,
        },
        {
          name: 'Styling cream',
          image: (
            <img height="72" width="72" src={swatch1} alt="Styling cream" />
          ),
        },
        {
          name: 'Spray',
          image: <img height="72" width="72" src={swatch10} alt="Spray" />,
        },
        {
          name: 'Pomade',
          image: <img height="72" width="72" src={swatch6} alt="Pomade" />,
        },
      ],
      image: (
        <img
          className="h-full object-cover"
          height="188"
          width="188"
          src={hairCareImage}
          alt="Hair care"
        />
      ),
      mobileImage: (
        <img
          height="50"
          className="max-h-[50px] w-full object-cover"
          src={hairCareImageMobile}
          alt="Hair care"
        />
      ),
    },
    {
      name: 'Body Care',
      formats: [
        {
          name: 'Moisturizer',
          image: <img height="72" width="72" src={swatch1} alt="Moisturizer" />,
        },
        {
          name: 'Body wash',
          image: <img height="72" width="72" src={swatch2} alt="Body wash" />,
        },
        {
          name: 'Body scrub',
          image: <img height="72" width="72" src={swatch4} alt="Body scrub" />,
        },
        {
          name: 'Hand cream',
          image: <img height="72" width="72" src={swatch7} alt="Hand cream" />,
        },
        {
          name: 'Foot cream',
          image: <img height="72" width="72" src={swatch7} alt="Foot cream" />,
        },
        {
          name: 'Deodorant',
          image: <img height="72" width="72" src={swatch6} alt="Deodorant" />,
        },
      ],
      image: (
        <img
          className="h-full object-cover"
          height="188"
          width="188"
          src={bodyCareImage}
          alt="Body care"
        />
      ),
      mobileImage: (
        <img
          height="50"
          className="max-h-[50px] w-full object-cover"
          src={bodyCareImageMobile}
          alt="Body care"
        />
      ),
    },
    {
      name: 'Sun Care',
      formats: [
        {
          name: 'Chemical Sunscreen',
          image: (
            <img
              height="72"
              width="72"
              src={swatch1}
              alt="Chemical Sunscreen"
            />
          ),
        },
        {
          name: 'Mineral Sunscreen',
          image: (
            <img height="72" width="72" src={swatch1} alt="Mineral Sunscreen" />
          ),
        },
        {
          name: 'After-sun',
          image: <img height="72" width="72" src={swatch7} alt="After-sun" />,
        },
        {
          name: 'Self-tanning',
          image: (
            <img height="72" width="72" src={swatch8} alt="Self-tanning" />
          ),
        },
      ],
      image: (
        <img
          className="h-full object-cover"
          height="188"
          width="188"
          src={sunCareImage}
          alt="Skin care"
        />
      ),
      mobileImage: (
        <img
          height="50"
          className="max-h-[50px] w-full object-cover"
          src={sunCareImageMobile}
          alt="Sun care"
        />
      ),
    },
  ],
  unavailable: [
    {
      name: 'Makeup',
      formats: [
        { name: 'Concealer' },
        { name: 'Eyeliner' },
        { name: 'Lip stick' },
        { name: 'Mascara' },
        { name: 'Nail polish' },
        { name: 'Blush' },
      ],
      image: (
        <img
          className="h-full object-cover"
          height="188"
          width="188"
          src={makeupImage}
          alt="Makeup"
        />
      ),
      mobileImage: (
        <img
          height="50"
          className="max-h-[50px] w-full object-cover"
          src={makeupImageMobile}
          alt="Makeup"
        />
      ),
    },
    {
      name: 'Fragrance',
      formats: [{ name: 'Perfume' }, { name: 'Cologne' }],
      image: (
        <img
          className="h-full object-cover"
          height="188"
          width="188"
          src={fragranceImage}
          alt="Fragrance"
        />
      ),
      mobileImage: (
        <img
          height="50"
          className="max-h-[50px] w-full object-cover"
          src={fragranceImageMobile}
          alt="Fragrance"
        />
      ),
    },
  ],
};

const SUB_TITLE = '';
const TITLE = 'Choose your product category';

export const ProductCategory: React.FC = () => {
  const { pageNumber, incrementPage } = useContext(WizardContext);
  const { isMobile } = useMobile();
  const { errors, setFieldValue } = useFormikContext();
  const analytics = useAnalytics()!;

  const setCategoryAndContinue = (categoryName: string) => {
    setFieldValue(BRIEF_FORM_INPUTS.productCategory, categoryName);
    setFieldValue(BRIEF_FORM_INPUTS.productType, '');

    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBQuestion,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbNext(
        `${String(pageNumber).padStart(2, '0')}. ${TITLE}`
      ),
    });

    incrementPage();
  };

  const renderCategories = (
    categoriesAndAvailability: CategoriesAndAvailability
  ) => {
    const { productCategories, available } = categoriesAndAvailability;
    return productCategories.map((category, idx) => {
      if (idx % 2 === 0) {
        return (
          <React.Fragment key={`category-pair-${idx}`}>
            <div
              key={`${category.name}-${idx}`}
              className="col-start-2 col-span-5"
              onClick={
                available
                  ? () => setCategoryAndContinue(category.name)
                  : () => null
              }
            >
              <CategoryCard
                available={available}
                formats={category.formats}
                image={category.image}
                name={category.name}
              />
            </div>

            {productCategories[idx + 1] ? (
              <div
                key={`${productCategories[idx + 1].name}-${idx + 1}`}
                className="col-span-5"
                onClick={() =>
                  available
                    ? setCategoryAndContinue(productCategories[idx + 1].name)
                    : () => null
                }
              >
                <CategoryCard
                  available={available}
                  formats={productCategories[idx + 1].formats}
                  image={productCategories[idx + 1].image}
                  name={productCategories[idx + 1].name}
                />
              </div>
            ) : null}
          </React.Fragment>
        );
      } else {
        return null;
      }
    });
  };

  const renderMobileCategories = (
    categoriesAndAvailability: CategoriesAndAvailability
  ) => {
    const { productCategories, available } = categoriesAndAvailability;
    return productCategories.map((category, idx) => {
      return (
        <div
          key={`${category.name}-${idx}`}
          className="col-span-2"
          onClick={
            available ? () => setCategoryAndContinue(category.name) : () => null
          }
        >
          <CategoryCard
            available={available}
            formats={category.formats}
            image={category.image}
            isMobile
            mobileImage={category.mobileImage}
            name={category.name}
          />
        </div>
      );
    });
  };

  return (
    <FormLayout
      isNextButtonDisabled={BRIEF_FORM_INPUTS.productCategory in errors}
      subTitle={SUB_TITLE}
      title={TITLE}
    >
      {isMobile ? (
        <div className="flex flex-col">
          <div className="mx-5 grid grid-cols-2 gap-x-6">
            {renderMobileCategories({
              productCategories: PRODUCT_CATEGORIES_AND_TYPES.available,
              available: true,
            })}
            <label className="col-span-2 font-mono text-xs font-semibold my-3">
              {LABEL}
            </label>
            {renderMobileCategories({
              productCategories: PRODUCT_CATEGORIES_AND_TYPES.unavailable,
              available: false,
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="grid grid-cols-12 gap-x-6 gap-y-6 mb-8">
            {renderCategories({
              productCategories: PRODUCT_CATEGORIES_AND_TYPES.available,
              available: true,
            })}
          </div>
          <div className="grid grid-cols-12 gap-x-6 gap-y-6">
            <label className="col-start-2 col-span-12 font-mono text-xs font-semibold mb-3">
              {LABEL}
            </label>
          </div>
          <div className="grid grid-cols-12 gap-x-6 gap-y-6">
            {renderCategories({
              productCategories: PRODUCT_CATEGORIES_AND_TYPES.unavailable,
              available: false,
            })}
          </div>
        </div>
      )}
    </FormLayout>
  );
};
