// Libraries
import React, { useEffect, useState } from 'react';
// Asset
import { ReactComponent as MagnifyingGlass } from 'features/formula/images/magnifyingGlass.svg';
// Util
import {
  CompanyAttributes,
  IApiResponse,
  RawMaterialAttributes,
} from 'features/types';
// Constants
import * as Constants from 'features/constants';
import { Button } from '@material-ui/core';
import { searchResourceFunctionType } from 'api';

type SearchResult = RawMaterialAttributes | CompanyAttributes;

interface IChildrenArguments {
  /** @param {boolean} isDropdownOpen used to determine the visibility of a dropdown. */
  isDropdownOpen: boolean;
  /** @param {boolean} isSearching used to determine the visibility of a loader. */
  isSearching: boolean;
  /** @param {Array<Maybe<SearchResult>>} searchResults used to hold the results of the search request. */
  searchResults: Array<Maybe<SearchResult>>;
  /** @param {React.Dispatch<React.SetStateAction<boolean>>} setIsDropdownOpen used to control the visibility of a dropdown. */
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISearch {
  searchFunction: searchResourceFunctionType;
  /** @prop {string} [additionalParams] - used to inject additional params into the request url */
  additionalParams?: string;
  /**
   * This callback is called `children`.
   * It receives search state params passed from the Search component.
   * @return {ReactElement} can be any React element, but typically will be a dropdown to display search results
   */
  children: (args: IChildrenArguments) => React.ReactNode | React.ReactNode[];
  /** @prop {string} [label] - used to display an input label */
  label?: string;
  /**
   * Receives an API response, and returns a parsed version of the response
   * @name parseSearchResults
   * @function
   * @param {IApiResponse} apiResponse a response object from the API
   */
  parseSearchResults: (apiResponse: IApiResponse) => any;
  /**
   * @prop {string} resource - the API resource you are trying to search against
   * @example "companies" resolves to `/api/v1/companies?query=amazon&page=1&per_page=25`;
   * */
  resource: string;
  /**
   * @prop {string} [selectedValue] - the initial display value that the input uses,
   * it is also used to set the value when a selection is made from a the results  */
  selectedValue?: Maybe<string>;
  /**
   * @prop {...rest} - allows for adding any of the built in HTML input tag attributes
   */
  [x: string]: any;
}

/**
 * A reusable search component
 * @returns {React.ReactElement} A search component.
 */
export const Search: React.FC<ISearch> = ({
  searchFunction,
  children,
  label,
  parseSearchResults,
  placeholder,
  required,
  resource,
  additionalParams,
  selectedValue,
  ...rest
}) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(selectedValue || '');

  useEffect(() => {
    setSearchValue(selectedValue || '');
  }, [selectedValue]);

  const [searchResults, setSearchResults] = useState<
    Array<Maybe<SearchResult>>
  >([]);

  useEffect(() => {
    if (additionalParams && isDropdownOpen) {
      performSearch(searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalParams, isDropdownOpen]);

  const performSearch = async (searchTerm: string) => {
    if (searchTerm.trim().length) {
      setIsDropdownOpen(true);
      setIsSearching(true);

      searchFunction({
        query: searchTerm,
        urlParams: `&page=1&per_page=25${additionalParams}`,
        handleSuccess: (response: IApiResponse) => {
          setSearchResults(parseSearchResults(response));
        },
        handleFinally: () => setIsSearching(false),
      });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>
  ) => {
    setSearchValue(e.target.value);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return (
    <div className="relative">
      <MagnifyingGlass
        className={`absolute left-2 ${label ? 'top-10' : 'top-4'}`}
      />
      {label && (
        <label>
          {label}
          {required ? <span className="text-formulaRed"> *</span> : ''}
        </label>
      )}
      <input
        name="name"
        autoComplete="off"
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={(e) =>
          Constants.handleKeyDown(e, () => performSearch(searchValue))
        }
        value={searchValue}
        className="transition-colors ease-in-out duration-700 w-full border rounded-b-none outline-none border-formulaLightGray focus:border-blue-60 focus:border-2 p-3 pl-8 rounded"
        type="text"
        {...rest}
      />
      <Button
        disabled={rest.disabled}
        style={{ position: 'absolute' }}
        className="left-auto top-2 right-2"
        onClick={() => performSearch(searchValue)}
      >
        Search
      </Button>
      {children({
        isDropdownOpen,
        isSearching,
        searchResults,
        setIsDropdownOpen,
      })}
    </div>
  );
};
