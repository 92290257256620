//Libraries
import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
// Utils
import { handleError } from 'features/formula/utils';
import { useApi, useSnackbar } from 'hooks';
import { IApiData } from 'features/types';
// Constants
import * as Constants from 'features/constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const CHANGE_REASON = {
  input: 'input',
  reset: 'reset',
};

const USAGE_OPTIONS = ['Leave on', 'Rinse off'];

const INITIAL_FORMULA_VALUES = {
  batchQuantity: '',
  description: '',
  directionsForUse: '',
  externalId: '',
  featuresBenefits: '',
  name: '',
  projectUuid: '',
  recommendedFor: '',
  usage: '',
  uuid: '',
};

// Styles

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    select: {
      textAlign: 'left',
    },
  })
);

// Helpers

const renderFormulaUsageOptions = (): JSX.Element[] => {
  return USAGE_OPTIONS.map((usage, index) => (
    <MenuItem key={`${usage}-${index}`} value={usage}>
      {usage}
    </MenuItem>
  ));
};

type LocationState = {
  projectId?: string;
};

export const CreateFormulaPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const request = useApi();
  const location = useLocation();
  const projectId = (location?.state as LocationState)?.projectId;

  const [isFormulaLoading, setIsFormulaLoading] = useState<boolean>(false);
  const [areProjectsLoading, setAreProjectsLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<
    Array<Omit<IApiData, 'relationships'>>
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<any>({
    id: '',
    attributes: { name: '' },
  });
  const [projects, setProjects] = useState<Array<IApiData>>([]);
  const [selectedProject, setSelectedProject] = useState<
    Maybe<Omit<IApiData, 'relationships'>>
  >(undefined);
  const [formula, setFormula] = useState<any>(INITIAL_FORMULA_VALUES);

  const readProjects = useCallback(
    async (uuid: string) => {
      try {
        setAreProjectsLoading(true);
        const apiResponse = await request({
          resource: `/api/v1/companies/${uuid}/projects`,
          options: {
            scope: 'read:projects',
            settings: Constants.GET,
          },
        });

        if (apiResponse?.status === 204) {
          return;
        }
        if (apiResponse?.data) {
          setProjects(apiResponse.data);
        } else {
          handleError(
            apiResponse,
            "There was an error loading that company's projects."
          );
        }
      } catch (error) {
        showSnackbar((error as Error).message, 'error');
      } finally {
        setAreProjectsLoading(false);
      }
    },
    [request, showSnackbar]
  );

  useEffect(() => {
    if (projectId) {
      (async function () {
        try {
          setIsFormulaLoading(true);
          const apiResponse = await request({
            resource: `/api/v1/projects/${projectId}`,
            options: {
              scope: 'read:projects',
              settings: Constants.GET,
            },
          });

          if (apiResponse.data) {
            const project = apiResponse.data;
            const company = project.attributes.company.data;

            setFormula((formula: any) => ({
              ...formula,
              projectUuid: project.id,
            }));
            setSelectedProject(project);
            setSelectedCompany(company);
            readProjects(company.id);
          } else {
            handleError(
              apiResponse,
              'There was a problem loading the project.'
            );
          }
        } catch (error) {
          showSnackbar((error as Error).message, 'error');
        } finally {
          setIsFormulaLoading(false);
        }
      })();
    }
  }, [request, projectId, readProjects, showSnackbar]);

  const handleCompanyChange = (_event: object, value: any) => {
    if (value?.id) {
      readProjects(value.id);
      setSelectedCompany(value);
    } else {
      setProjects([]);
      setSelectedCompany(value);
    }

    setSelectedProject({ id: '', attributes: { name: '' } });
    setFormula({
      ...formula,
      projectUuid: '',
    });
  };

  const handleCompanyInput = async (
    _event: React.ChangeEvent<{}>,
    query: string,
    reason: string
  ) => {
    // onInputChange is called any time the autocomplete is changed
    // we only want to make a request when the user is typing
    // see issue: https://github.com/mui-org/material-ui/issues/18784
    if (reason !== CHANGE_REASON.input) return;

    if (query.trim().length) {
      try {
        const apiResponse = await request({
          resource: `/api/v1/companies?query=${encodeURIComponent(query)}`,
          options: {
            scope: 'read:companies',
            settings: Constants.GET,
          },
        });

        if (apiResponse?.data) {
          setCompanies(apiResponse.data);
        } else {
          handleError(apiResponse, 'There was an error while searching.');
        }
      } catch (error) {
        showSnackbar((error as Error).message, 'error');
      }
    }
  };

  const handleProjectChange = (_event: object, value: any) => {
    setSelectedProject(value);
    setFormula({
      ...formula,
      projectUuid: value?.id,
    });
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormula({ ...formula, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createFormula();
  };

  const createFormula = async () => {
    try {
      setIsFormulaLoading(true);
      const apiResponse = await request({
        resource: '/api/v1/formulas/',
        options: {
          settings: Constants.POST,
          body: {
            formula,
          },
          scope: 'create:formulas',
        },
      });
      if (apiResponse.data) {
        navigate(
          ROUTES.SHOW_FORMULA.route.replace(
            UUID_SHOW_ROUTE_STRING,
            apiResponse.data.id
          )
        );
      } else {
        handleError(apiResponse, 'Formula failed to save');
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error');
      Rollbar.error(error);
    } finally {
      setIsFormulaLoading(false);
    }
  };

  return (
    <Container className={classes.root} maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h3" align="left">
              Formula
            </Typography>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            justify="flex-end"
            alignItems="flex-start"
            xs={6}
          >
            <Grid item>
              <Button
                disabled={isFormulaLoading}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {isFormulaLoading ? (
            <Grid item xs={12} className={classes.loading}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  autoFocus
                  id="name"
                  label="Name"
                  value={formula.name}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: 256 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="externalId"
                  variant="outlined"
                  required
                  fullWidth
                  id="externalId"
                  label="Formula ID"
                  placeholder="Format:  MM-DD-YYYY-Lab-Notebook-Page-Number"
                  value={formula.externalId}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: 256 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="description"
                  variant="outlined"
                  fullWidth
                  id="description"
                  label="Description"
                  value={formula.description}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: 256 }}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={selectedCompany}
                  options={companies}
                  getOptionLabel={(company) => company.attributes.name}
                  onChange={handleCompanyChange}
                  onInputChange={handleCompanyInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText="Select an existing company"
                      required
                      label="Company"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={!projects.length}
                  value={selectedProject}
                  options={projects}
                  getOptionLabel={(project) => `${project.attributes.name}`}
                  onChange={handleProjectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText="Select an existing project"
                      required
                      label="Project"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {areProjectsLoading ? (
                              <CircularProgress size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  name="usage"
                  id="usage"
                  className={classes.select}
                  select
                  fullWidth
                  variant="outlined"
                  label="Usage"
                  size="medium"
                  value={formula.usage}
                  onChange={handleTextChange}
                >
                  {!formula.usage && (
                    <MenuItem value="none" disabled>
                      Please select a usage
                    </MenuItem>
                  )}
                  {renderFormulaUsageOptions()}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="recommendedFor"
                  variant="outlined"
                  fullWidth
                  id="recommendedFor"
                  label="Recommended for"
                  placeholder="e.g. Normal skin"
                  value={formula.recommendedFor}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: 256 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="directionsForUse"
                  variant="outlined"
                  fullWidth
                  id="directionsForUse"
                  label="Directions for Use"
                  placeholder="e.g. Apply liberally to wet skin. Lather and rinse."
                  value={formula.directionsForUse}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: 256 }}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="featuresBenefits"
                  variant="outlined"
                  fullWidth
                  id="featuresBenefits"
                  label="Features Benefits"
                  placeholder="e.g. Promotes fuller, healthier hair"
                  value={formula.featuresBenefits}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: 256 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  name="batchQuantity"
                  variant="outlined"
                  fullWidth
                  id="batchQuantity"
                  label="Batch Quantity (grams)"
                  value={formula.batchQuantity}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: 256 }}
                />
              </Grid>
              <Grid xs={6}>
                {formula.dateCreated &&
                  `Created on: ${format(formula.dateCreated, 'MM/dd/yyyy')}`}
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Container>
  );
};
