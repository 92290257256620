// Libraries
import React from 'react';
// Utils
import { useMobile, useSnackbar } from 'hooks';
import { IApiData, ProjectAttributes, TaskAttributes, useApi } from 'api';
// Constants
import * as Constants from '../../../../constants';
import {
  FeedbackStatus,
  FeedbackType,
  TaskStatus,
} from 'features/home/customer-portal/types';

interface IIngredientListPendingActions {
  file: any;
  files: any[];
  handleModalClick: (file: any) => void;
  setFiles: (files: any) => void;
  setProject: React.Dispatch<React.SetStateAction<IApiData<ProjectAttributes>>>;
}

export const IngredientListPendingActions: React.FC<IIngredientListPendingActions> = ({
  file,
  files,
  handleModalClick,
  setFiles,
  setProject,
}) => {
  const { isMobile } = useMobile();
  const { postIngredientListFeedback } = useApi();
  const { showSnackbar } = useSnackbar();

  const rAndDMilestone = Constants.MILESTONES.find(
    (milestone) =>
      milestone.title === Constants.MILESTONE_TITLES.R_AND_D_FORMULATION
  );

  const approveIngredientListTask = rAndDMilestone?.tasks.find(
    (task) => task.title === Constants.TASK_TITLES.APPROVE_INGREDIENT_LIST
  );

  const handleSubmit = () => {
    postIngredientListFeedback({
      urlParams: `${file.id}/feedbacks?feedbackType=${FeedbackType.approveIl}`,
      data: {
        feedback: {
          status: FeedbackStatus.approved,
        },
      },
      handleSuccess: (data) => {
        setProject((project) => {
          return {
            ...project,
            parsedIncluded: {
              ...project.parsedIncluded,
              tasks: project.parsedIncluded.tasks.map(
                (parsedTask: IApiData<TaskAttributes>) => {
                  return parsedTask.attributes.mondayColumnId ===
                    approveIngredientListTask?.columnId
                    ? {
                        ...parsedTask,
                        attributes: {
                          ...parsedTask.attributes,
                          status: TaskStatus.done,
                        },
                      }
                    : parsedTask;
                }
              ),
            },
          };
        });

        setFiles(
          files.map((currentFile: any) => {
            if (currentFile.id === file.id) {
              return {
                ...currentFile,
                parsedIncluded: {
                  ...currentFile.parsedIncluded,
                  feedback: { ...data },
                },
              };
            }
            return currentFile;
          })
        );
      },
      handleFinally: () => {
        showSnackbar(
          `${file.attributes.name} IL approved. We will now begin formulation.`,
          'success'
        );
      },
    });
  };

  return (
    <div className="flex justify-between sm:mb-0 mb-5 items-start">
      <button
        className="flex items-center p-2 w-1/2 sm:w-auto cursor-pointer rounded-md font-inter text-sm border border-darkGray text-black mr-2 justify-center "
        onClick={() => handleModalClick(file)}
      >
        Submit IL {isMobile ? <br /> : null} Feedback
      </button>
      <button
        className="flex items-center p-2 sm:w-auto cursor-pointer rounded-md font-inter text-sm border bg-black text-white justify-center"
        onClick={handleSubmit}
      >
        Approve IL
      </button>
    </div>
  );
};
