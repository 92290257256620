export interface Milestone {
  title: string;
  tasks: Task[];
}

export enum FeedbackStatus {
  approved = 'approved',
  rejected = 'rejected',
}

export enum SampleFeedbackStatus {
  approved = 'Approved',
  notApproved = 'Not Approved',
  notApplicable = 'N/A',
}

export enum FeedbackType {
  rejectSample = 'reject_sample',
  approveSample = 'approve_sample',
  rejectIl = 'reject_ingredient_list',
  approveIl = 'approve_ingredient_list',
}

export enum TaskOwner {
  customer = 'customer',
  prime = 'prime',
}

export enum TaskStatus {
  notStarted = 'Not Started',
  inProgress = 'In Progress',
  workingOnIt = 'Working on it',
  pending = 'Pending',
  waived = 'Waived',
  done = 'Done',
}

// Completed is when all tasks in a given milestone are 'Done'
// In progress is when any task is 'Blocked', or 'Working on it' or 'Done' but not all
// Waiting is when all tasks are 'Not started'

export enum SectionStatus {
  completed = 'COMPLETED',
  inProgress = 'IN PROGRESS',
  notStarted = 'NOT STARTED',
}

export type Task = {
  actions?: any;
  canUploadFiles?: boolean;
  columnId: string;
  isActionable?: boolean;
  isLast?: boolean;
  owner: TaskOwner | string;
  status: Maybe<TaskStatus | string>;
  tag?: string;
  title: string;
  updatedAt: Maybe<string>;
  waivedTitle?: string;
};

export type MileStone = {
  title: string;
  tasks: Array<Task>;
  tagColor: string;
};

export enum ProjectType {
  rtl = 'RTL',
}
