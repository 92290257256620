//Libraries
import React from 'react';
import {
  Avatar,
  CssBaseline,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
//Assets
import brandmark from 'img/brandmark.svg';
//Components
import { Copyright } from './copyright.component';
import { LoginButton, ROUTES } from 'features/navigation';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://images.ctfassets.net/twbjtxiia91j/hnTX4yoNTryb89APNw0yh/07fd6fdf833f8edfa0e4880da98836a1/pm-casestudies-hero_2x.jpg?w=1600&h=1000&q=50)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  avatar: {
    margin: theme.spacing(4),
    backgroundColor: theme.palette.secondary.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  signUp: {
    marginTop: theme.spacing(4),
  },
  signUpLink: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const SignIn: React.FC = () => {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <>
      {isAuthenticated ? (
        <Navigate to={ROUTES.HOME.route} />
      ) : (
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              <img src={brandmark} width="300" height="200" alt="wordmark" />
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Grid item xs={6}>
                <LoginButton />
                <Grid className={classes.signUp}>
                  <Typography>
                    Don't have an account?{' '}
                    <Link
                      className={classes.signUpLink}
                      onClick={() =>
                        loginWithRedirect({
                          screen_hint: 'signup',
                        })
                      }
                    >
                      Sign up!
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};
