// Libraries
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
// Components
import { Select } from 'design-system';
// Utils
import { useApi } from 'api';
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const PointOfContact: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<InternalBriefFormValues>();
  const { getCompanyCustomers } = useApi();
  const [customerOptions, setCustomerOptions] = useState([]);

  useEffect(() => {
    if (!values.company.value) return;
    getCompanyCustomers({
      urlParams: `${values.company.value}/customers`,
      handleSuccess: (response) => {
        setCustomerOptions(
          response.data.map((option: any) => {
            return {
              label: `${option.attributes.givenName} ${option.attributes.familyName}`,
              value: option.id,
            };
          })
        );
      },
    });
  }, [values.company, getCompanyCustomers]);

  return (
    <div>
      <p id="point-of-contact" className="font-inter text-lg text-grey-20 mb-6">
        Point of Contact
      </p>
      <Select
        disabled={!values.company.value}
        helperText="Select a company to see contacts"
        options={customerOptions}
        onChange={(option: Option) =>
          setFieldValue(BRIEF_FORM_KEYS.CONTACT, option)
        }
        label="Contact Name"
        value={values.contact}
      />
    </div>
  );
};
