// Libraries
import React from 'react';
import { createPortal } from 'react-dom';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { ExclamationCircleIcon } from '@heroicons/react/16/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import { SunIcon } from '@heroicons/react/24/outline';

interface AlertProps {
  content: React.ReactNode | React.ReactNode[];
  headline?: string;
  onClose?: () => void;
  severity?: 'success' | 'error' | 'warning' | 'info' | 'tip';
}

const severityToColorAndIcon = {
  error: {
    bgColor: 'bg-red-95',
    icon: <ExclamationCircleIcon className="h-4 w-4 text-red-50" />,
    textColor: 'text-red-20',
    closeIconColor: 'text-red-50',
  },
  info: {
    bgColor: 'bg-royal-95',
    icon: <SunIcon className="h-4 w-4 text-royal-50" />,
    textColor: 'bg-royal-20',
    closeIconColor: 'text-royal-50',
  },
  success: {
    bgColor: 'bg-green-95',
    icon: <CheckCircleIcon className="h-4 w-4 text-green-50" />,
    textColor: 'text-green-20',
    closeIconColor: 'text-green-50',
  },
  tip: {
    bgColor: 'bg-teal-95',
    icon: <LightBulbIcon className="h-4 w-4 text-teal-50" />,
    textColor: 'text-teal-20',
    closeIconColor: 'text-teal-50',
  },
  warning: {
    bgColor: 'bg-yellow-95',
    icon: <ExclamationTriangleIcon className="h-4 w-4 text-peach-60" />,
    textColor: 'text-peach-30',
    closeIconColor: 'text-peach-50',
  },
};

export const Alert: React.FC<AlertProps> = ({
  content,
  headline,
  onClose,
  severity = 'info',
}) => {
  const { bgColor, closeIconColor, icon, textColor } = severityToColorAndIcon[
    severity
  ];
  if (headline) {
    return createPortal(
      <div
        className={`${bgColor} flex flex-col gap-3 rounded p-3 w-[640px] fixed bottom-10 left-0 right-0 mx-auto z-[9999]`}
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            {icon}
            <div className={`${textColor} font-inter text-sm font-semibold`}>
              {headline}
            </div>
          </div>
          {onClose && (
            <div className={`cursor-pointer p-2`} onClick={onClose}>
              {<XMarkIcon className={`h-4 w-4 ${closeIconColor}`} />}
            </div>
          )}
        </div>
        <div className={`${textColor} font-inter text-sm px-6`}>{content}</div>
      </div>,
      document.body
    );
  }
  return createPortal(
    <div
      className={`${bgColor} flex flex-col rounded p-3 w-[640px] fixed bottom-10 left-0 right-0 mx-auto z-[9999]`}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          {icon}
          <div className={`${textColor} font-inter text-sm`}>{content}</div>
        </div>
        {onClose && (
          <div className="cursor-pointer" onClick={onClose}>
            {<XMarkIcon className={`h-4 w-4 ${closeIconColor}`} />}
          </div>
        )}
      </div>
    </div>,
    document.body
  );
};
