// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, createStyles, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
// Utils
import { ITheme } from 'styles/mui/themeV2';
// Constants
import {
  DATE_FORMAT,
  NUM_RECENT_SEARCHES_TO_DISPLAY,
  RECENT_SEARCHES,
  RECENT_SEARCHES_SECTION_TITLE,
} from './constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    recentSearchRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(4),
    },
    recentSearchDate: {
      color: theme.palette.gray.main,
    },
    recentSearchTitle: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    sectionTitle: {
      marginBottom: theme.spacing(4),
    },
  })
);

interface RecentSearchesProps {
  handleSearch: () => void;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

export const RecentSearches: React.FC<RecentSearchesProps> = ({
  handleSearch,
  setQuery,
}) => {
  const classes = useStyles();

  const [
    querySetByRecentSearchClick,
    setQuerySetByRecentSearchClick,
  ] = useState<boolean>(false);

  const handleClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    const target = e.target as HTMLSpanElement;
    setQuery(target.innerText);
    setQuerySetByRecentSearchClick(true);
  };

  useEffect(() => {
    if (!querySetByRecentSearchClick) return;
    handleSearch();
    setQuerySetByRecentSearchClick(false);
  }, [
    querySetByRecentSearchClick,
    setQuerySetByRecentSearchClick,
    handleSearch,
  ]);

  const displayRecentSearches = () => {
    const existingSearches = JSON.parse(
      localStorage.getItem(RECENT_SEARCHES) || '[]'
    );
    return existingSearches.map(
      (es: { query: string; searchDate: Date }, idx: number) => {
        if (idx < NUM_RECENT_SEARCHES_TO_DISPLAY) {
          return (
            <div key={`${es.query}-${idx}`} className={classes.recentSearchRow}>
              <Typography
                className={classes.recentSearchTitle}
                onClick={handleClick}
                variant="body2"
              >
                {es.query}
              </Typography>
              <Typography className={classes.recentSearchDate} variant="body2">
                {format(new Date(es.searchDate), DATE_FORMAT)}
              </Typography>
            </div>
          );
        }
        return null;
      }
    );
  };
  return (
    <div className={classes.container}>
      <Typography className={classes.sectionTitle} variant="h4">
        {RECENT_SEARCHES_SECTION_TITLE}
      </Typography>
      {displayRecentSearches()}
    </div>
  );
};
