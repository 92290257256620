// Libraries
import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
// Components
import { Can, PERMISSIONS } from 'features/rbac';
// Assets
import PmLogo from 'img/pm-logo-blue.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexFlow: 'column',
      height: 'calc(86vh - 200px)',
    },
    logo: {
      width: '80%',
      maxWidth: '100px',
      minWidth: '0px',
    },
  })
);

export const Forbidden: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <img src={PmLogo} alt="Prime Matter Lockup" className={classes.logo} />
      <br />
      <Typography variant="h1">Forbidden</Typography>
      <Typography variant="h3">
        You do not have permission to view this page.
      </Typography>
      <br />
      <Can
        perform={PERMISSIONS.VISIT_EMPLOYEE_PAGE}
        yes={() => (
          <Typography variant="h4">
            Please contact your manager if you believe this is a mistake.
          </Typography>
        )}
      />
    </Grid>
  );
};

export default Forbidden;
