// Libraries
import React from 'react';
import { makeStyles, createStyles, IconButton } from '@material-ui/core';
// Utils
import { ITheme, THEME_PALETTE } from 'styles/mui/themeV2';
// Assets
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    default: {
      height: theme.spacing(8),
      width: theme.spacing(8),
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.main,
      },
    },
  })
);

interface MoreActionsIconButtonProps {
  disabled?: boolean;
  [x: string]: any;
}

export const MoreActionsIconButton: React.FC<MoreActionsIconButtonProps> = React.forwardRef(
  ({ disabled, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <IconButton
        {...rest}
        disabled={disabled}
        ref={ref as React.RefObject<HTMLButtonElement>}
        className={classes.default}
        style={{
          backgroundColor: disabled ? THEME_PALETTE.gray.light : '',
        }}
      >
        <AddIcon />
      </IconButton>
    );
  }
);
