export const BRIEF_FORM_INPUTS = {
  additionalInfo: 'additionalInfo',
  benchmarks: 'benchmarks',
  cogsRange: 'cogsRange',
  cogsSpecific: 'cogsSpecific',
  fragranceComment: 'fragranceComment',
  fragranceType: 'fragranceType',
  intendedMarkets: 'intendedMarkets',
  isOtc: 'isOtc',
  isOtcNote: 'isOtcNote',
  labels: 'labels',
  minimumOrderQuantity: 'minimumOrderQuantity',
  msrpPerUnitRange: 'msrpPerUnitRange',
  msrpPerUnitSpecific: 'msrpPerUnitSpecific',
  mustExcludeIngredients: 'mustExcludeIngredients',
  mustHaveIngredients: 'mustHaveIngredients',
  previouslyBroughtToMarket: 'previouslyBroughtToMarket',
  previouslyBroughtToMarketNote: 'previouslyBroughtToMarketNote',
  primaryPackaging: 'primaryPackaging',
  productCategory: 'productCategory',
  productClaims: 'productClaims',
  productType: 'productType',
  productTypeNote: 'productTypeNote',
  projectDescription: 'projectDescription',
  projectName: 'projectName',
  retailerStandards: 'retailerStandards',
  size: 'size',
  specificFragrances: 'specificFragrances',
  structure: 'structure',
  targetAudience: 'targetAudience',
  targetDate: 'targetDate',
  unit: 'unit',
};

export const COUNTRIES = [
  { title: 'United States' },
  { title: 'Canada' },
  { title: 'European Union' },
  { title: 'Afghanistan' },
  { title: 'Albania' },
  { title: 'Algeria' },
  { title: 'Alland Islands' },
  { title: 'American Samoa' },
  { title: 'Andorra' },
  { title: 'Angola' },
  { title: 'Anguilla' },
  { title: 'Antarctica' },
  { title: 'Antigua and Barbuda' },
  { title: 'Argentina' },
  { title: 'Armenia' },
  { title: 'Aruba' },
  { title: 'Australia' },
  { title: 'Austria' },
  { title: 'Azerbaijan' },
  { title: 'Bahamas' },
  { title: 'Bahrain' },
  { title: 'Bangladesh' },
  { title: 'Barbados' },
  { title: 'Belarus' },
  { title: 'Belgium' },
  { title: 'Belize' },
  { title: 'Benin' },
  { title: 'Bermuda' },
  { title: 'Bhutan' },
  { title: 'Bolivia' },
  { title: 'Botswana' },
  { title: 'Bosnia and Herzegovina' },
  { title: 'Bouvet Island' },
  { title: 'Brazil' },
  { title: 'British Indian Ocean Territory' },
  { title: 'British Virgin Islands' },
  { title: 'Brunei Darussalam' },
  { title: 'Bulgaria' },
  { title: 'Burkina Faso' },
  { title: 'Burundi' },
  { title: 'Cape Verde' },
  { title: 'Cayman Islands' },
  { title: 'Cambodia' },
  { title: 'Cameroon' },
  { title: 'Central African Republic' },
  { title: 'Chad' },
  { title: 'Chile' },
  { title: 'China' },
  { title: 'Christmas Island' },
  { title: 'Cocos (Keeling) Islands' },
  { title: 'Colombia' },
  { title: 'Comoros' },
  { title: 'Congo, Democratic Republic of the' },
  { title: 'Congo, Republic of the' },
  { title: 'Cook Islands' },
  { title: 'Costa Rica' },
  { title: "Cote d'Ivoire" },
  { title: 'Croatia' },
  { title: 'Cuba' },
  { title: 'Curacao' },
  { title: 'Cyprus' },
  { title: 'Czech Republic' },
  { title: 'Denmark' },
  { title: 'Dominica' },
  { title: 'Dominican Republic' },
  { title: 'Djibouti' },
  { title: 'Ecuador' },
  { title: 'Egypt' },
  { title: 'El Salvador' },
  { title: 'Equatorial Guinea' },
  { title: 'Eritrea' },
  { title: 'Estonia' },
  { title: 'Ethiopia' },
  { title: 'Falkland Islands (Malvinas)' },
  { title: 'Faroe Islands' },
  { title: 'Fiji' },
  { title: 'Finland' },
  { title: 'France' },
  { title: 'French Guiana' },
  { title: 'French Polynesia' },
  { title: 'French Southern Territories' },
  { title: 'Gabon' },
  { title: 'Gambia' },
  { title: 'Georgia' },
  { title: 'Germany' },
  { title: 'Ghana' },
  { title: 'Gibraltar' },
  { title: 'Greece' },
  { title: 'Greenland' },
  { title: 'Grenada' },
  { title: 'Guadeloupe' },
  { title: 'Guam' },
  { title: 'Guatemala' },
  { title: 'Guernsey' },
  { title: 'Guinea' },
  { title: 'Guinea-Bissau' },
  { title: 'Guyana' },
  { title: 'Haiti' },
  { title: 'Heard Island and McDonald Islands' },
  { title: 'Honduras' },
  { title: 'Hong Kong' },
  { title: 'Holy See (Vatican City State)' },
  { title: 'Hungary' },
  { title: 'Iceland' },
  { title: 'India' },
  { title: 'Indonesia' },
  { title: 'Iran, Islamic Republic of' },
  { title: 'Iraq' },
  { title: 'Ireland' },
  { title: 'Isle of Man' },
  { title: 'Israel' },
  { title: 'Italy' },
  { title: 'Jamaica' },
  { title: 'Japan' },
  { title: 'Jersey' },
  { title: 'Jordan' },
  { title: 'Kazakhstan' },
  { title: 'Kenya' },
  { title: 'Kiribati' },
  { title: "Korea, Democratic People's Republic of" },
  { title: 'Korea, Republic of' },
  { title: 'Kosovo' },
  { title: 'Kuwait' },
  { title: 'Kyrgyzstan' },
  { title: "Lao People's Democratic Republic" },
  { title: 'Latvia' },
  { title: 'Lebanon' },
  { title: 'Liberia' },
  { title: 'Libya' },
  { title: 'Liechtenstein' },
  { title: 'Lesotho' },
  { title: 'Lithuania' },
  { title: 'Luxembourg' },
  { title: 'Macao' },
  {
    title: 'Macedonia, the Former Yugoslav Republic of',
  },
  { title: 'Madagascar' },
  { title: 'Malawi' },
  { title: 'Malaysia' },
  { title: 'Maldives' },
  { title: 'Mali' },
  { title: 'Malta' },
  { title: 'Marshall Islands' },
  { title: 'Martinique' },
  { title: 'Mauritania' },
  { title: 'Mauritius' },
  { title: 'Mayotte' },
  { title: 'Mexico' },
  { title: 'Micronesia, Federated States of' },
  { title: 'Montenegro' },
  { title: 'Montserrat' },
  { title: 'Monaco' },
  { title: 'Moldova, Republic of' },
  { title: 'Mongolia' },
  { title: 'Morocco' },
  { title: 'Mozambique' },
  { title: 'Myanmar' },

  { title: 'Namibia' },
  { title: 'Nauru' },
  { title: 'Nepal' },
  { title: 'Netherlands' },
  { title: 'New Caledonia' },
  { title: 'New Zealand' },
  { title: 'Nicaragua' },
  { title: 'Niger' },
  { title: 'Nigeria' },
  { title: 'Niue' },
  { title: 'Norfolk Island' },
  { title: 'Northern Mariana Islands' },
  { title: 'Norway' },

  { title: 'Oman' },
  { title: 'Pakistan' },
  { title: 'Panama' },
  { title: 'Palau' },
  { title: 'Palestine, State of' },
  { title: 'Papua New Guinea' },
  { title: 'Paraguay' },
  { title: 'Peru' },
  { title: 'Philippines' },
  { title: 'Pitcairn' },
  { title: 'Poland' },
  { title: 'Portugal' },
  { title: 'Puerto Rico' },
  { title: 'Qatar' },
  { title: 'Reunion' },
  { title: 'Romania' },
  { title: 'Russian Federation' },
  { title: 'Rwanda' },
  { title: 'Saint Barthelemy' },
  { title: 'Saint Helena' },
  { title: 'Saint Kitts and Nevis' },
  { title: 'Saint Lucia' },
  { title: 'Saint Maarten (Dutch part)' },
  { title: 'Saint Martin (French part)' },
  { title: 'Saint Pierre and Miquelon' },
  { title: 'Saint Vincent and the Grenadines' },
  { title: 'Samoa' },
  { title: 'San Marino' },
  { title: 'Sao Tome and Principe' },
  { title: 'Saudi Arabia' },
  { title: 'Serbia' },
  { title: 'Senegal' },
  { title: 'Seychelles' },
  { title: 'Sierra Leone' },
  { title: 'Singapore' },
  { title: 'Slovakia' },
  { title: 'Slovenia' },
  { title: 'Somalia' },
  { title: 'Solomon Islands' },
  { title: 'South Africa' },
  {
    title: 'South Georgia and the South Sandwich Islands',
  },
  { title: 'South Sudan' },
  { title: 'Spain' },
  { title: 'Sri Lanka' },
  { title: 'Sudan' },
  { title: 'Suriname' },
  { title: 'Svalbard and Jan Mayen' },
  { title: 'Swaziland' },
  { title: 'Sweden' },
  { title: 'Switzerland' },
  { title: 'Syrian Arab Republic' },
  { title: 'Taiwan, Province of China' },
  { title: 'Tajikistan' },
  { title: 'Thailand' },
  { title: 'Timor-Leste' },
  { title: 'Turks and Caicos Islands' },
  { title: 'Togo' },
  { title: 'Tokelau' },
  { title: 'Tonga' },
  { title: 'Trinidad and Tobago' },
  { title: 'Tunisia' },
  { title: 'Turkey' },
  { title: 'Turkmenistan' },
  { title: 'Tuvalu' },
  { title: 'Uganda' },
  { title: 'Ukraine' },
  { title: 'United Arab Emirates' },
  { title: 'United Kingdom' },
  { title: 'United Republic of Tanzania' },
  { title: 'US Virgin Islands' },
  { title: 'Uruguay' },
  { title: 'Uzbekistan' },
  { title: 'Vanuatu' },
  { title: 'Venezuela' },
  { title: 'Vietnam' },
  { title: 'Wallis and Futuna' },
  { title: 'Western Sahara' },
  { title: 'Yemen' },
  { title: 'Zambia' },
  { title: 'Zimbabwe' },
];

export const CLAIMS = [
  {
    title: 'Vegan',
  },
  {
    title: 'Gluten-free',
  },
  {
    title: 'Cruelty-free',
  },
  {
    title: 'Paraben-free',
  },
  {
    title: 'Silicone-free',
  },
  {
    title: 'Sulfate-free',
  },
  {
    title: 'Non-Comedogenic',
  },
  {
    title: 'Organic',
  },
  {
    title: 'Hypoallergenic',
  },
  {
    title: 'Dermatologist tested',
  },
];

export const RETAILER_STANDARDS = [
  {
    title: 'Conscious Beauty at Ulta',
    value: '7772ff2e-a7aa-49a2-8b07-2a2b5f7eaad6',
  },
  {
    title: 'Clean at Sephora',
    value: '8a2046e9-182b-46af-9f09-d97c4b8f0db1',
  },
  {
    title: 'Credo Clean Standard',
    value: '3bff8d98-08dc-407e-886f-9f83a6c0a429',
  },
  {
    title: 'Target Clean',
    value: '9844590e-f5ef-43d4-978e-39a07e75f3fc',
  },
];

export const COST_PER_UNIT_SPECIFIC_LABEL = 'COST PER UNIT (SPECIFIC)';
export const COST_PER_UNIT_RANGE_LABEL = 'COST PER UNIT (RANGE)';

export const COST_PER_UNIT_RANGES = [
  '$0.01-$0.50',
  '$0.51-$1.00',
  '$1.01-$1.50',
  '$1.51-$2.00',
  '$2.01-$2.50',
  '$2.51-$3.00',
  '$3.00-$3.50',
  '$3.51-$4.00',
  '$4.01-$4.50',
  '$4.51-$5.00',
  '$5.01-$5.50',
  '$5.51-$6.00',
  '$6.01-$6.50',
  '$6.51-$7.00',
  '$7.01-$7.50',
  '$7.51-$8.00',
  '$8.01-$8.50',
  '$8.51-$9.00',
  '$9.01-$9.50',
  '$9.51-$10.00',
];

export const COST_PER_UNIT_RANGES_OPTIONS = COST_PER_UNIT_RANGES.map(
  (range) => {
    return {
      name: range,
      value: range,
    };
  }
);
