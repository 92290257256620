// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
import { PlusIcon } from '@heroicons/react/16/solid';
// Components
import { Chip, TextArea, Typography } from 'design-system';
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';
// Assets
import { ReactComponent as ReadyToLaunchIcon } from 'img/ready-to-launch.svg';

const CHIPS = [
  {
    label: 'Sephora Clean',
    value: '8a2046e9-182b-46af-9f09-d97c4b8f0db1',
    isRtl: false,
  },
  {
    label: 'Ulta (Conscious Beauty)',
    value: '7772ff2e-a7aa-49a2-8b07-2a2b5f7eaad6',
    isRtl: false,
  },
  {
    label: 'Target Clean',
    value: '9844590e-f5ef-43d4-978e-39a07e75f3fc',
    isRtl: false,
  },
  {
    label: 'Credo Clean',
    value: '3bff8d98-08dc-407e-886f-9f83a6c0a429',
    isRtl: false,
  },
];

export const IngredientNotes: React.FC = () => {
  const { getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();

  const handleChipClose = (chipToRemove: Option) => {
    const updatedStandards = values.retailerStandards.filter(
      (itemUuid: string) => itemUuid !== chipToRemove.value
    );
    setFieldValue(BRIEF_FORM_KEYS.RETAILER_STANDARDS, [...updatedStandards]);
  };

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-6">
        <Typography id="ingredient-notes" variant="h3" font="inter">
          Ingredient Notes
        </Typography>
        <TextArea
          {...getFieldProps(BRIEF_FORM_KEYS.MUST_HAVE_INGREDIENTS)}
          label="Must-have/Key ingredients"
        />
        <TextArea
          {...getFieldProps(BRIEF_FORM_KEYS.MUST_EXCLUDE_INGREDIENTS)}
          label="Restricted ingredients"
        />
        <div className="flex flex-col">
          <Typography variant="h6" font="inter">
            Exclude banned ingredients for:
          </Typography>
          <div className="flex flex-wrap gap-3 mt-1">
            {CHIPS.map((chip) => {
              const isSelected = values.retailerStandards.includes(chip.value);
              return (
                <Chip
                  key={`chip-${chip.value}`}
                  onClick={() => {
                    setFieldValue(BRIEF_FORM_KEYS.RETAILER_STANDARDS, [
                      // @ts-ignore
                      ...new Set([...values.retailerStandards, chip.value]),
                    ]);
                  }}
                  onCloseIconClick={(event) => {
                    event.stopPropagation();
                    handleChipClose(chip);
                  }}
                  label={chip.label}
                  leadingIcon={
                    chip.isRtl ? (
                      <ReadyToLaunchIcon className="h-4 w-4 text-peach-60" />
                    ) : null
                  }
                  selected={isSelected}
                  trailingIcon={
                    !isSelected ? (
                      <PlusIcon className="h-4 w-4 text-grey-50" />
                    ) : null
                  }
                  value={chip.value}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
