// Libraries
import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
// Utils
import { useApi } from 'api';
import { ITheme } from 'styles/mui/themeV2';
import { SavedSearchResult, SearchResult, SearchResultFilters } from './types';
// Components
import { RecentSearches } from './recent-searches.component';
import { SearchBar } from './search-bar.component';
import { PrimarySearchFilters } from './primary-search-filters.component';
import { SearchResultsTable } from './search-results-table.component';
// Constants
import {
  FORMULAS,
  FORMULA_SEARCH_RESULTS_FILTERS,
  FORMULA_SEARCH_RESULTS_SORT_OPTIONS,
  FORMULA_SEARCH_RESULTS_TABLE_COLUMNS,
  INITIAL_NUM_SEARCH_RESULTS_TO_DISPLAY,
  INITIAL_TABLES_TO_DISPLAY,
  NUM_RECENT_SEARCHES_TO_DISPLAY,
  PROJECTS,
  PROJECT_SEARCH_RESULTS_FILTERS,
  PROJECT_SEARCH_RESULTS_SORT_OPTIONS,
  PROJECT_SEARCH_RESULTS_TABLE_COLUMNS,
  RECENT_SEARCHES,
} from './constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.secondary.dark,
      marginLeft: theme.spacing(2),
      width: '98%',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    buttonContainer: {
      flexGrow: 1,
    },
    container: {
      marginTop: '24px',
      textAlign: 'left',
    },
    filterContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.gray.lighter,
      display: 'flex',
      padding: theme.spacing(8),
    },
    primaryFiltersContainer: {
      display: 'flex',
    },
    recentSearchesContainer: {
      backgroundColor: theme.palette.gray.lighter,
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      padding: theme.spacing(8),
      width: '100%',
    },
    seperator: {
      color: theme.palette.gray.light,
    },
  })
);

export const ProjectAndFormulaSearch = () => {
  const classes = useStyles();
  const { searchFormulas, searchProjects } = useApi();

  // Search State
  const [query, setQuery] = useState<string>('');
  const [isFormulaSearchLoading, setIsFormulaSearchLoading] = useState<boolean>(
    false
  );
  const [isProjectSearchLoading, setIsProjectSearchLoading] = useState<boolean>(
    false
  );
  const [shouldDisplayResultsTables, setShouldDisplayResultsTables] = useState<
    boolean
  >(false);
  const [formulaResults, setFormulaResults] = useState<SearchResult[]>([]);
  const [projectResults, setProjectResults] = useState<SearchResult[]>([]);

  // Primary Filtering State
  const [tablesToDisplay, setTablesToDisplay] = useState<string[]>(
    INITIAL_TABLES_TO_DISPLAY
  );

  // Secondary Filtering State
  const [formulaFilters, setFormulaFilters] = useState<SearchResultFilters>(
    FORMULA_SEARCH_RESULTS_FILTERS
  );
  const [projectFilters, setProjectFilters] = useState<SearchResultFilters>(
    PROJECT_SEARCH_RESULTS_FILTERS
  );

  // Sorting State
  const [selectedFormulaSortValue, setSelectedFormulaSortValue] = useState<
    string
  >(FORMULA_SEARCH_RESULTS_SORT_OPTIONS.none.value);
  const [selectedProjectSortValue, setSelectedProjectSortValue] = useState<
    string
  >(PROJECT_SEARCH_RESULTS_SORT_OPTIONS.none.value);

  // Pagination State
  const [formulaResultsPerPage, setFormulaResultsPerPage] = useState<number>(
    INITIAL_NUM_SEARCH_RESULTS_TO_DISPLAY
  );
  const [projectResultsPerPage, setProjectResultsPerPage] = useState<number>(
    INITIAL_NUM_SEARCH_RESULTS_TO_DISPLAY
  );
  const [currentFormulaResultsPage, setCurrentFormulaResultsPage] = useState<
    number
  >(0);
  const [currentProjectResultsPage, setCurrentProjectResultsPage] = useState<
    number
  >(0);
  const [totalFormulaResultsCount, setTotalFormulaResultsCount] = useState<
    number
  >(0);
  const [totalProjectResultsCount, setTotalProjectResultsCount] = useState<
    number
  >(0);

  const filteredFormulaString = Object.keys(formulaFilters)
    .filter((f: keyof SearchResultFilters) => {
      return formulaFilters[f].isFiltering;
    })
    .join(',');

  const filteredProjectString = Object.keys(projectFilters)
    .filter((f: keyof SearchResultFilters) => {
      return projectFilters[f].isFiltering;
    })
    .join(',');

  const addQueryToRecentItems = () => {
    const existingSearches = JSON.parse(
      localStorage.getItem(RECENT_SEARCHES) || '[]'
    );

    // Don't add to recent searches if this is a duplicate search query
    if (
      existingSearches.filter((es: SavedSearchResult) => es.query === query)
        .length > 0
    )
      return;

    existingSearches.unshift({ query, searchDate: new Date() });

    if (existingSearches.length > NUM_RECENT_SEARCHES_TO_DISPLAY) {
      existingSearches.splice(NUM_RECENT_SEARCHES_TO_DISPLAY);
    }

    localStorage.setItem(RECENT_SEARCHES, JSON.stringify(existingSearches));
  };

  const handleSearch = () => {
    if (!query) return;

    addQueryToRecentItems();
    setIsFormulaSearchLoading(true);
    setIsProjectSearchLoading(true);
    setShouldDisplayResultsTables(true);

    searchFormulas({
      query: encodeURIComponent(query),
      urlParams: `&page=${
        currentFormulaResultsPage + 1
      }&perPage=${formulaResultsPerPage}&filters=${filteredFormulaString}&sort=${encodeURI(
        selectedFormulaSortValue
      )}`,
      handleSuccess: (data) => {
        setFormulaResults(data.results);
        setTotalFormulaResultsCount(data.total.value);
      },
      handleFinally: () => setIsFormulaSearchLoading(false),
    });

    searchProjects({
      query: encodeURIComponent(query),
      urlParams: `&page=${
        currentProjectResultsPage + 1
      }&perPage=${projectResultsPerPage}&filters=${filteredProjectString}&sort=${encodeURI(
        selectedProjectSortValue
      )}`,
      handleSuccess: (data) => {
        setProjectResults(data.results);
        setTotalProjectResultsCount(data.total.value);
      },
      handleFinally: () => {
        setIsProjectSearchLoading(false);
      },
    });
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentFormulaResultsPage,
    currentProjectResultsPage,
    formulaResultsPerPage,
    projectResultsPerPage,
    projectFilters,
    formulaFilters,
    selectedFormulaSortValue,
    selectedProjectSortValue,
  ]);

  return (
    <Grid className={classes.container} container direction="column">
      <Grid item>
        <SearchBar
          currentFormulaResultsPage={currentFormulaResultsPage}
          currentProjectResultsPage={currentProjectResultsPage}
          handleSearch={handleSearch}
          query={query}
          setQuery={setQuery}
          setCurrentFormulaResultsPage={setCurrentFormulaResultsPage}
          setCurrentProjectResultsPage={setCurrentProjectResultsPage}
        />
      </Grid>
      {!shouldDisplayResultsTables ? (
        <>
          <div className={classes.recentSearchesContainer}>
            <RecentSearches handleSearch={handleSearch} setQuery={setQuery} />
          </div>
          <hr className={classes.seperator} />
          <div className={classes.filterContainer}>
            <div>
              <Typography variant="h4">Search in:</Typography>
            </div>

            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  setShouldDisplayResultsTables(true);
                  setTablesToDisplay([FORMULAS]);
                }}
                size="large"
                className={classes.button}
              >
                Formulas
              </Button>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  setShouldDisplayResultsTables(true);
                  setTablesToDisplay([PROJECTS]);
                }}
                size="large"
                className={classes.button}
              >
                Projects
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div style={{ marginTop: '20px' }}>
          <Grid item>
            <PrimarySearchFilters
              tablesToDisplay={tablesToDisplay}
              formulaResultsCount={totalFormulaResultsCount}
              projectResultsCount={totalProjectResultsCount}
              setTablesToDisplay={setTablesToDisplay}
            />
          </Grid>
          {tablesToDisplay.includes(FORMULAS) && (
            <Grid item>
              <SearchResultsTable
                currentPage={currentFormulaResultsPage}
                filters={formulaFilters}
                isLoading={isFormulaSearchLoading}
                results={formulaResults}
                rowsPerPage={formulaResultsPerPage}
                selectedSort={selectedFormulaSortValue}
                setFilters={setFormulaFilters}
                setPage={setCurrentFormulaResultsPage}
                setRowsPerPage={setFormulaResultsPerPage}
                setSelectedSort={setSelectedFormulaSortValue}
                sortOptions={FORMULA_SEARCH_RESULTS_SORT_OPTIONS}
                tableColumns={FORMULA_SEARCH_RESULTS_TABLE_COLUMNS}
                title="Formulas"
                totalResultsCount={totalFormulaResultsCount}
              />
            </Grid>
          )}
          {tablesToDisplay.includes(PROJECTS) && (
            <Grid item>
              <SearchResultsTable
                currentPage={currentProjectResultsPage}
                filters={projectFilters}
                isLoading={isProjectSearchLoading}
                results={projectResults}
                rowsPerPage={projectResultsPerPage}
                selectedSort={selectedProjectSortValue}
                setFilters={setProjectFilters}
                setPage={setCurrentProjectResultsPage}
                setRowsPerPage={setProjectResultsPerPage}
                setSelectedSort={setSelectedProjectSortValue}
                sortOptions={PROJECT_SEARCH_RESULTS_SORT_OPTIONS}
                tableColumns={PROJECT_SEARCH_RESULTS_TABLE_COLUMNS}
                title="Projects"
                totalResultsCount={totalProjectResultsCount}
              />
            </Grid>
          )}
        </div>
      )}
    </Grid>
  );
};
