// Libraries
import _ from 'lodash';
// Utils
import { UNITS } from 'features/formula/formula-page/constants';
import {
  IApiData,
  FormulaAttributes,
  IngredientAttributes,
  BriefSupplementAttributes,
  BriefAttributes,
  PriceAttributes,
  RawMaterialLocations,
} from 'api';
import { parseCostFromPrice } from 'features/formula/formula-page/utils';
import { PricingQuoteFormikValues, Tank } from './types';
import { PRODUCTION_TANKS, FLUID_OUNCES_PER_GALLON } from './constants';

// Constants
import { LABOR_HEAD_COUNT_AND_RUN_RATE_MAP } from './labor-head-count-and-run-rate-map.util';
import {
  CONTAINER_TYPE,
  ADDITIONAL_COMPONENT_COST_PER_UNIT,
  UNIT_OUTPUT_THRESHOLDS,
} from './constants';
import { MOQ_UNITS, PURCHASE_QUANTITY_UNITS } from 'features/types';
import {
  MARGIN_TARGET,
  STOCK_RAW_MATERIALS_EAST,
  STOCK_RAW_MATERIALS_WEST,
} from './constants';

const {
  BOTTLE,
  TOTTLE,
  JAR,
  TUBE,
  LIP_BALM_DEODORANT,
  FOIL_SACHET,
  AEROSOL,
  BAG_ON_VALVE,
} = CONTAINER_TYPE;
const BATCH_SIZE_DIVISOR = 453.59;
const BATCH_SIZE_UNIT_SIZE_MULTIPLIER = 29.57;
const BATCH_SIZE_WASTE_PERCENTAGE = 1.05;
// TODO: make waste percentage (.10) an attribute of final brief supplement
const DEFAULT_WASTE_PERCENTAGE = 0.1;
const EXCESS_WASTE = 1.2;
const GRAMS_PER_KILOGRAM = 1000;
const HOURLY_PAY_RATE = 25;
const IDENTITY_PROPERTY = 1;
const MIAMI_PRODUCTION_HOURS = 7.83;
const MINUTES_PER_HOUR = 60;
const OUNCE_PER_GRAM = 0.035274;
const OUNCE_PER_MILLILITER = 0.03381402;
const PERCENTAGE_BASE_DIVISOR = 100.0;
const POUNDS_PER_KILO = 2.2046;
const SIXTEEN_FLUID_OUNCE_DIVISOR = 16;
const TWENTY_PERCENT_BUY_FEE = 1.2;

const PLACEHOLDER_VALUE = 0;

enum Unit {
  oz = 'oz',
  ml = 'ml',
  g = 'g',
}

/* ========== HELPERS ========== */
export const checkIfRawMaterialIsStock = (
  ingredient: IApiData<IngredientAttributes>
) => {
  const {
    attributes: { rawMaterialRmId, rawMaterialLocation },
  } = ingredient;

  if (!rawMaterialRmId) return false;

  if (rawMaterialLocation === RawMaterialLocations.MIA)
    return STOCK_RAW_MATERIALS_EAST.includes(rawMaterialRmId);

  if (rawMaterialLocation === RawMaterialLocations.LA)
    return STOCK_RAW_MATERIALS_WEST.includes(rawMaterialRmId);

  return false;
};

const convertPercentageIntegerToFloat = (percentage: number) => {
  return percentage / PERCENTAGE_BASE_DIVISOR;
};

export const convertContainerSizeToOunce = (size?: number, unit?: Unit) => {
  if (!size || !unit) return;

  switch (unit) {
    case Unit.oz:
      return size;
    case Unit.ml:
      return size * OUNCE_PER_MILLILITER;
    case Unit.g:
      return size * OUNCE_PER_GRAM;
    default:
      throw new Error(`Unit type not supported: ${unit}`);
  }
};

const isMissingRequiredInformation = ({
  cappingHeadCount,
  compoundingHeadCount,
  efficiencyPercentage,
  operatorHeadCount,
  otherHeadCount,
  packingOrPalletizerHeadCount,
  preworkHeadCount,
  runRate,
  unitCartonHeadCount,
}: PricingQuoteFormikValues) => {
  return [
    cappingHeadCount,
    compoundingHeadCount,
    efficiencyPercentage,
    operatorHeadCount,
    otherHeadCount,
    packingOrPalletizerHeadCount,
    preworkHeadCount,
    runRate,
    unitCartonHeadCount,
  ].some((value) => typeof value === 'undefined' || value === null);
};

export const determineMarginTarget = (tank: Maybe<Tank>) => {
  if (!tank?.unitOutput) return 0;

  const { unitOutput } = tank;
  if (unitOutput < 25_000) {
    return MARGIN_TARGET.VERY_HIGH;
  } else if (unitOutput < 50_000) {
    return MARGIN_TARGET.HIGH;
  } else if (unitOutput < 250_000) {
    return MARGIN_TARGET.MEDIUM;
  } else {
    return MARGIN_TARGET.LOW;
  }
};

/* ========== BATCH SIZE CALCULATION =========== */
const calculateWasteFactor = (unitSize: number) => {
  if (unitSize <= 2) return 1;
  else if (unitSize <= 4) return 2;
  else if (unitSize <= 8) return 3;
  else return 4;
};

export const calculateTotalBatchSize = (brief: BriefAttributes) => {
  if (!brief.size || !brief.unit || !brief.minimumOrderQuantity) return;

  const unitSize = convertContainerSizeToOunce(
    brief.size,
    brief.unit as Unit
  ) as number;

  const wasteFactor = unitSize ? calculateWasteFactor(unitSize) : 0;

  return (
    ((wasteFactor + unitSize * BATCH_SIZE_UNIT_SIZE_MULTIPLIER) *
      BATCH_SIZE_WASTE_PERCENTAGE *
      brief.minimumOrderQuantity) /
    BATCH_SIZE_DIVISOR
  );
};

/* ========== COMPONENT COST CALCULATIONS ========== */
export const calculateComponentCostPerContainer = (
  {
    innerBox,
    masterBox,
    overCap,
    tamperSleeve,
  }: Pick<
    BriefSupplementAttributes,
    'innerBox' | 'masterBox' | 'overCap' | 'tamperSleeve'
  >,
  productionTank: Tank
) => {
  const { LOW, MEDIUM } = UNIT_OUTPUT_THRESHOLDS;
  const productionSize =
    productionTank.unitOutput < LOW
      ? 'LOW'
      : productionTank.unitOutput < MEDIUM
      ? 'MEDIUM'
      : 'HIGH';
  return (
    ((innerBox
      ? ADDITIONAL_COMPONENT_COST_PER_UNIT[productionSize].innerBox
      : 0) +
      (tamperSleeve
        ? ADDITIONAL_COMPONENT_COST_PER_UNIT[productionSize].tamperSleeve
        : 0) +
      (masterBox
        ? ADDITIONAL_COMPONENT_COST_PER_UNIT[productionSize].masterBox[
            masterBox
          ]
        : 0) +
      (overCap
        ? ADDITIONAL_COMPONENT_COST_PER_UNIT[productionSize].overCap
        : 0)) *
    TWENTY_PERCENT_BUY_FEE // this represents the 20% buy fee
  );
};

/* ========== FORMULA COST CALCULATIONS ========== */
export const calculatePricePerPoundTotalNetWeight = (
  ingredients: IApiData<IngredientAttributes>[]
) => {
  return ingredients.reduce(
    (acc: number, ingredient: IApiData<IngredientAttributes>) => {
      acc += ingredient.attributes.highestPrice
        ? parseCostFromPrice(ingredient.attributes.highestPrice!, UNITS.LB) *
          ((ingredient.attributes.amount as number) / 100)
        : PLACEHOLDER_VALUE;

      return acc;
    },
    0
  );
};

export const calculatePricePer16FlOz = (
  pricePerPoundTotalNetWeight: number
) => {
  return (pricePerPoundTotalNetWeight / 454) * 474;
};

export const calculatePriceWithWastePercentage16oz = (
  pricePer16FlOz: number
) => {
  return pricePer16FlOz * (IDENTITY_PROPERTY + DEFAULT_WASTE_PERCENTAGE);
};

export const calculatePricePerFluidOz = (
  priceWithWastePercentage16oz: number
) => {
  return priceWithWastePercentage16oz / SIXTEEN_FLUID_OUNCE_DIVISOR;
};

export const calculateFormulaCostPerContainer = (
  brief: BriefAttributes,
  ingredients: IApiData<IngredientAttributes>[]
) => {
  const pricePerPoundTotalNetWeight = calculatePricePerPoundTotalNetWeight(
    ingredients
  );

  const pricePer16FlOz = calculatePricePer16FlOz(pricePerPoundTotalNetWeight);
  const priceWithWastePercentage16oz = calculatePriceWithWastePercentage16oz(
    pricePer16FlOz
  );
  const pricePerFluidOz = calculatePricePerFluidOz(
    priceWithWastePercentage16oz
  );

  return (
    pricePerFluidOz *
    convertContainerSizeToOunce(brief.size, brief.unit as Unit)!
  );
};

const convertQuantityToPounds = (
  quantity: number,
  unit: keyof typeof MOQ_UNITS | keyof typeof PURCHASE_QUANTITY_UNITS,
  price: Maybe<PriceAttributes>
) => {
  if (unit === MOQ_UNITS.kg) {
    return quantity * POUNDS_PER_KILO;
  }

  if (unit === PURCHASE_QUANTITY_UNITS.g) {
    return quantity * GRAMS_PER_KILOGRAM * POUNDS_PER_KILO;
  }

  if (unit === MOQ_UNITS.dollars) {
    const costPerPound = parseCostFromPrice(price!, UNITS.LB);

    // quantity in dollars divided by the cost per 1 pound gives us the quantity in pounds
    return quantity / costPerPound;
  }

  // quantity is already in pounds
  return quantity;
};

export const calculateExcessValue = (
  ingredients: Maybe<IApiData<IngredientAttributes>[]>,
  totalBatchSize: Maybe<number>
) => {
  return ingredients?.map((ingredient) => {
    const {
      moq,
      moq_unit,
      quantity_purchased,
      unit,
    } = ingredient.attributes.highestPrice!;

    const isStockRM = checkIfRawMaterialIsStock(ingredient);

    if ((!moq && !quantity_purchased) || !totalBatchSize)
      return { excessValue: 0, estimatedUsage: 0 };

    const quantityForCalculation = moq ? moq : quantity_purchased;
    const unitForCalculation = moq_unit ? moq_unit : unit;

    const packSize = convertQuantityToPounds(
      quantityForCalculation as number,
      unitForCalculation as
        | keyof typeof MOQ_UNITS
        | keyof typeof PURCHASE_QUANTITY_UNITS,
      ingredient.attributes.highestPrice
    );

    const estimatedUsage =
      (totalBatchSize * (ingredient.attributes.amount as number)) /
      PERCENTAGE_BASE_DIVISOR;

    if (isStockRM) return { excessValue: 0, estimatedUsage };

    const excessAmount = packSize - estimatedUsage;

    const costPerPound = parseCostFromPrice(
      ingredient.attributes.highestPrice!,
      UNITS.LB
    );

    const excessValue =
      (excessAmount <= 0 ? 0 : excessAmount) * costPerPound * EXCESS_WASTE;

    return { estimatedUsage, excessValue };
  });
};

export const calculateTotalExcessValue = (excessValues: Maybe<number[]>) => {
  return excessValues?.reduce((sum: number, excessValue: number) => {
    sum += excessValue;
    return sum;
  }, 0);
};

export const calculateExcessRawMaterials = ({
  ingredients,
  totalBatchSize,
  productionTank,
  profit,
}: {
  ingredients: Maybe<IApiData<IngredientAttributes>[]>;
  totalBatchSize: Maybe<number>;
  productionTank?: Tank;
  profit: Maybe<number>;
}) => {
  const excessRawMaterials = calculateExcessValue(
    ingredients,
    totalBatchSize
  )?.map(({ excessValue }) => excessValue);

  const totalExcessValue = calculateTotalExcessValue(excessRawMaterials);

  const contributionPerRun =
    !profit || !productionTank
      ? undefined
      : _.round(productionTank?.unitOutput! * profit, 2);

  const percentageOfExcessRawMaterials =
    contributionPerRun && totalExcessValue
      ? _.round(
          (totalExcessValue / contributionPerRun) * PERCENTAGE_BASE_DIVISOR,
          2
        )
      : undefined;

  return {
    excessRawMaterials,
    percentageOfExcessRawMaterials,
    totalExcessValue,
  };
};

/* ========== LABOR COST CALCULATIONS ========== */
export const calculateTankUnitOutput = (brief: any, gallons: number) => {
  const tankCapacityInFluidOunces = gallons * FLUID_OUNCES_PER_GALLON;

  return (
    tankCapacityInFluidOunces /
    convertContainerSizeToOunce(brief.size, brief.unit as Unit)!
  );
};

export const calculateTankSizeAndOutput = ({
  productionLocation,
  brief,
}: {
  productionLocation: Maybe<string>;
  brief: Maybe<BriefAttributes>;
}) => {
  if (
    !productionLocation ||
    !brief?.size ||
    !brief?.unit ||
    !brief.minimumOrderQuantity
  )
    return;

  // create a duplicate so it can be modified in place without potentially
  // polluting the object
  const productionTanks = PRODUCTION_TANKS[productionLocation].map((tank) => ({
    ...tank,
  }));

  const generateMultipleTanks = (
    tanks: typeof productionTanks,
    MOQ: number,
    results: Array<Tank>
  ): Maybe<Array<Tank>> => {
    // stop condition is we ran out of tanks or there is no remaining MOQ
    if (tanks.length <= 0 || MOQ <= 0) return results;

    for (let index = 0; index <= tanks.length; index++) {
      const tank = tanks[index];
      const { gallons } = tank;

      const tankUnitOutput = calculateTankUnitOutput(brief, gallons);

      const remainingMOQ =
        Math.max(tankUnitOutput, MOQ) - Math.min(tankUnitOutput, MOQ);

      if (tankUnitOutput >= MOQ) {
        return [
          ...results,
          {
            gallons,
            unitOutput: tankUnitOutput,
          },
        ];
      } else {
        const isNextTankAvailable = Boolean(tanks.at(index + 1));

        // We are at the largest available tank we can utilize
        if (!isNextTankAvailable) {
          tank.quantity--;

          // remove the tank so it no longer can be considered
          if (tank.quantity <= 0) tanks.splice(index, 1);

          return generateMultipleTanks(tanks, remainingMOQ, [
            ...results,
            {
              gallons,
              unitOutput: tankUnitOutput,
            },
          ]);
        }
      }
    }

    if (tanks.length <= 0 || MOQ <= 0) return results;
  };

  const multipleTanks = generateMultipleTanks(
    productionTanks,
    brief.minimumOrderQuantity,
    []
  );

  const tank = multipleTanks?.reduce(
    (acc, cv) => {
      acc.gallons += cv.gallons;
      acc.unitOutput += cv.unitOutput;
      return acc;
    },
    {
      gallons: 0,
      unitOutput: 0,
    } as Tank
  );

  return {
    ...tank, // the cumulative value of all the tanks combined
    unitOutput: _.round(tank?.unitOutput || 0),
    tanks: multipleTanks, // all the tanks in one list, helps determine compounding headcount (tanks.length * compoundingHeadCount)
  } as Tank & { tanks: Tank[] };
};

export const calculateLaborEfficiency = (
  efficiencyPercentage: number,
  runRate: number
) => {
  return (
    convertPercentageIntegerToFloat(efficiencyPercentage || 0) * (runRate || 0)
  );
};

export const calculateLaborCostCompounding = (
  productionTank: Maybe<Tank>,
  compoundingHeadCount: number
) => {
  if (!productionTank) return;

  return (
    ((compoundingHeadCount || 0) * HOURLY_PAY_RATE * MIAMI_PRODUCTION_HOURS) /
    productionTank.unitOutput
  );
};

export const calculateLaborCost = (
  headCount: number,
  laborEfficiency: number
) => {
  if (laborEfficiency === 0) return 0;

  return (
    ((headCount || 0) * HOURLY_PAY_RATE * MIAMI_PRODUCTION_HOURS) /
    (laborEfficiency * MINUTES_PER_HOUR * MIAMI_PRODUCTION_HOURS)
  );
};

export const calculateLaborCostPerContainer = (
  brief: BriefAttributes,
  laborFields: PricingQuoteFormikValues,
  productionLocation: string,
  providedTank?: Tank
) => {
  const {
    cappingHeadCount,
    compoundingHeadCount,
    efficiencyPercentage,
    operatorHeadCount,
    otherHeadCount,
    packingOrPalletizerHeadCount,
    preworkHeadCount,
    runRate,
    unitCartonHeadCount,
  } = laborFields;

  const laborEfficiency = calculateLaborEfficiency(
    efficiencyPercentage,
    runRate
  )!;

  const tank = providedTank
    ? providedTank
    : calculateTankSizeAndOutput({
        productionLocation,
        brief,
      });

  const laborCostCompounding = calculateLaborCostCompounding(
    tank,
    compoundingHeadCount
  )!;

  const laborCostPreWork = calculateLaborCost(
    preworkHeadCount,
    laborEfficiency
  )!;

  const laborCostOperator = calculateLaborCost(
    operatorHeadCount,
    laborEfficiency
  )!;

  const laborCostCapping = calculateLaborCost(
    cappingHeadCount,
    laborEfficiency
  )!;

  const laborCostUnitCarton = calculateLaborCost(
    unitCartonHeadCount,
    laborEfficiency
  )!;

  const laborCostPackingOrPalletizer = calculateLaborCost(
    packingOrPalletizerHeadCount,
    laborEfficiency
  )!;

  const laborCostOther = calculateLaborCost(otherHeadCount, laborEfficiency)!;

  return [
    laborCostCompounding,
    laborCostPreWork,
    laborCostOperator,
    laborCostCapping,
    laborCostUnitCarton,
    laborCostPackingOrPalletizer,
    laborCostOther,
  ].reduce((acc: number, cv: number) => {
    acc += cv;
    return acc;
  }, 0);
};

/* ========== TOTAL COST CALCULATIONS ========== */
export const calculateTotalCostWithProfit = (
  totalCostPerContainer: number,
  marginTargetPercentage: number
) => {
  return (
    totalCostPerContainer /
    (IDENTITY_PROPERTY -
      convertPercentageIntegerToFloat(marginTargetPercentage || 0))
  );
};

interface ICalculateUnitPricing {
  formula?: IApiData<FormulaAttributes>;
  ingredients: IApiData<IngredientAttributes>[];
  brief: BriefAttributes;
  laborFields: PricingQuoteFormikValues;
  productionTank?: Tank;
  productionLocation: string;
}

export const calculateUnitPricing = ({
  ingredients,
  brief,
  laborFields,
  productionTank,
  productionLocation,
}: ICalculateUnitPricing) => {
  if (isMissingRequiredInformation(laborFields)) {
    throw new Error('Missing required information.');
  }
  // This is the cost for packaging per unit
  const componentCostPerContainer = productionTank
    ? calculateComponentCostPerContainer(
        brief as BriefSupplementAttributes,
        productionTank
      )
    : 0;
  // This is the cost of all ingredients per unit
  const formulaCostPerContainer = calculateFormulaCostPerContainer(
    brief,
    ingredients
  );
  // This is the cost of labor per unit
  const laborCostPerContainer = calculateLaborCostPerContainer(
    brief,
    laborFields,
    productionLocation
  );

  const totalCostPerContainer =
    formulaCostPerContainer + laborCostPerContainer + componentCostPerContainer;

  const totalCostWithProfit = calculateTotalCostWithProfit(
    totalCostPerContainer,
    laborFields.marginTargetPercentage
  );

  const profit = totalCostWithProfit - totalCostPerContainer;

  // Round to nearest 2 decimal places
  // Profit appears as "Contribution" on the chart
  const roundedTotalCostWithProfit =
    Math.round(totalCostWithProfit * 100) / 100;

  return {
    componentCostPerContainer,
    roundedTotalCostWithProfit,
    formulaCostPerContainer,
    laborCostPerContainer,
    // Appears as "Contribution" on the chart
    profit,
  };
};

export const determineHeadCountValues = (
  {
    container,
    material,
    closure,
    unitCarton,
  }: Pick<
    BriefSupplementAttributes,
    'container' | 'material' | 'closure' | 'unitCarton'
  >,
  unitOutput: number
) => {
  const capClosures = ['flipTop', 'discCap', 'snapCap'];
  const outputSize =
    unitOutput < 25000 ? 'LOW' : unitOutput < 50000 ? 'MEDIUM' : 'HIGH';
  const unitContainerKey = unitCarton ? 'withUnitCarton' : 'withoutUnitCarton';

  switch (container) {
    case BOTTLE:
      if (capClosures.includes(closure as string)) {
        return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.BOTTLE_WITH_CAP[outputSize][
          unitContainerKey
        ];
      } else {
        return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.BOTTLE_WITH_SPRAYER[
          outputSize
        ][unitContainerKey];
      }
    case TOTTLE:
      return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.TOTTLE[outputSize][
        unitContainerKey
      ];
    case JAR:
      return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.JAR[outputSize][
        unitContainerKey
      ];
    case TUBE:
      if (material === 'metal') {
        return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.TUBE_WITH_METAL[outputSize][
          unitContainerKey
        ];
      } else {
        return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.TUBE_WITH_PLASTIC[outputSize][
          unitContainerKey
        ];
      }
    case LIP_BALM_DEODORANT:
      return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.LIP_BALM_DEODORANT[outputSize][
        unitContainerKey
      ];
    case FOIL_SACHET:
      return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.FOIL_SACHET[outputSize];
    case AEROSOL:
      return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.AEROSOL[outputSize][
        unitContainerKey
      ];
    case BAG_ON_VALVE:
      return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.BAG_ON_VALVE[outputSize][
        unitContainerKey
      ];
    default:
      return LABOR_HEAD_COUNT_AND_RUN_RATE_MAP.DEFAULT;
  }
};
