// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { HorizontalCard, TextArea, Typography } from 'design-system';
import { ScrollIntoView } from './scroll-into-view.component';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { useIsEmployee, useConfirmation } from 'hooks';
import { getRtlKeysForProductCategory, useRtlIconMap } from '../rtl-logic';
// Assets
import aerosol from 'img/aerosol.png';
import butter from 'img/butter.png';
import cream from 'img/cream.png';
import foam from 'img/foam.png';
import gelCream from 'img/gel-cream.png';
import liquid from 'img/liquid.png';
import lotion from 'img/lotion.png';
import opaque from 'img/opaque.png';
import serum from 'img/serum.png';
import spray from 'img/spray.png';
import stick from 'img/form-stick.png';
import gel from 'img/form-gel.png';
import oil from 'img/form-oil.png';
import translucent from 'img/translucent.png';
import transparent from 'img/transparent.png';
import { ReactComponent as ReadyToLaunchIcon } from 'img/ready-to-launch.svg';
// Constants
import {
  BRIEF_FORM_KEYS,
  OPACITIES,
  RESTART_CONFIRM_STATE,
  RTL_CONFIRM_STATE,
  STRUCTURES,
} from '../brief.constants';

type Card = {
  title: string;
  image: string;
};

type CardRow = Card[];
type CardSection = { title: string; cardRows: CardRow };

const FORM_AND_STRUCTURE_CARDS: CardSection = {
  title: 'Form/Structure',
  cardRows: [
    {
      title: STRUCTURES.BUTTER,
      image: butter,
    },
    {
      title: STRUCTURES.SERUM,
      image: serum,
    },
    {
      title: STRUCTURES.STICK,
      image: stick,
    },
    {
      title: STRUCTURES.LOTION,
      image: lotion,
    },
    {
      title: STRUCTURES.OIL,
      image: oil,
    },
    {
      title: STRUCTURES.CONTINUOUS_SPRAY_BOV,
      image: spray,
    },
    {
      title: STRUCTURES.CREAM,
      image: cream,
    },
    {
      title: STRUCTURES.GEL_CREAM,
      image: gelCream,
    },
    {
      title: STRUCTURES.GEL,
      image: gel,
    },
    {
      title: STRUCTURES.LIQUID,
      image: liquid,
    },
    {
      title: STRUCTURES.MOUSSE_FOAM,
      image: foam,
    },
    {
      title: STRUCTURES.AEROSOL,
      image: aerosol,
    },
  ],
};

interface FormulaSpecificsProps {
  projectIsRtl?: boolean;
}

export const FormulaSpecifics: React.FC<FormulaSpecificsProps> = ({
  projectIsRtl,
}) => {
  // When this component mounts, we want to check the current list of RTL filtered formulas
  // and want to render icons for the options that are present w/in RTL filtered formulas
  // On future renders, we DO NOT want to recheck the list of RTL filtered formulas, unless
  // a module before this one is changed.

  const { getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();

  const rtlIconMap = useRtlIconMap({
    moduleKey: BRIEF_FORM_KEYS.STRUCTURE,
    values,
  });

  const { confirm } = useConfirmation();

  const isEmployee = useIsEmployee();

  const OPACITY_CARDS: CardSection = {
    title: 'Opacity',
    cardRows: [
      {
        title: OPACITIES.CLEAR_TRANSPARENT,
        image: transparent,
      },
      {
        title: OPACITIES.TRANSLUCENT,
        image: translucent,
      },
      {
        title: OPACITIES.OPAQUE,
        image: opaque,
      },
    ],
  };

  const handleNonRtlClick = async (value: string) => {
    const shouldProceed = await confirm(RTL_CONFIRM_STATE);

    if (shouldProceed) {
      setFieldValue(BRIEF_FORM_KEYS.STRUCTURE, value);
    } else {
      return;
    }
  };

  const renderCards = (cardSection: CardSection, formikKey: string) => {
    return (
      <>
        <Typography variant="h4" font="inter" weight="semibold">
          {cardSection.title}
        </Typography>
        <div className="flex flex-col md:flex-row flex-wrap sm:col-span-6 gap-6">
          {cardSection.cardRows.map((card: Card, index: number) => {
            const isRtlOption = rtlIconMap[BRIEF_FORM_KEYS.STRUCTURE]
              ? rtlIconMap[BRIEF_FORM_KEYS.STRUCTURE][card.title]
              : false;
            return (
              <HorizontalCard
                data-testid={`structure-${card.title}`}
                cardwidth="sm:w-[144px]"
                className="sm:w-[303px] h-[74px]"
                icon={
                  isRtlOption ? <ReadyToLaunchIcon className="h-4 w-4" /> : null
                }
                iconColor="text-peach-60"
                image={card.image}
                selected={
                  values[formikKey as keyof InternalBriefFormValues] ===
                  card.title
                }
                key={index}
                onClick={
                  isRtlOption || !projectIsRtl
                    ? async () => {
                        const hasValue =
                          values[formikKey as keyof InternalBriefFormValues];
                        const moduleList = getRtlKeysForProductCategory(
                          values.productCategory.value
                        );

                        const indexOfModule = moduleList.indexOf(
                          formikKey as any
                        );

                        const nextModules = moduleList.slice(indexOfModule + 1);

                        const nextFieldsHaveValues = nextModules.some(
                          // @ts-ignore
                          (module) => !!values[module]
                        );

                        // If we already have a project type then we should confirm before changing
                        if (
                          indexOfModule !== -1 &&
                          hasValue &&
                          nextFieldsHaveValues
                        ) {
                          const shouldProceed = await confirm(
                            RESTART_CONFIRM_STATE
                          );

                          if (!shouldProceed) return;
                        }

                        setFieldValue(formikKey, card.title);
                      }
                    : () => handleNonRtlClick(card.title)
                }
                title={card.title}
              />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <ScrollIntoView>
      {({ ref }) => (
        <div ref={ref} className="flex flex-col gap-12">
          <div className="flex flex-col gap-6">
            <Typography id="formula-specifics" variant="h3" font="inter">
              Formula Specifics
            </Typography>

            {renderCards(FORM_AND_STRUCTURE_CARDS, BRIEF_FORM_KEYS.STRUCTURE)}
            {isEmployee && (
              <>
                {renderCards(OPACITY_CARDS, BRIEF_FORM_KEYS.OPACITY)}
                <TextArea
                  {...getFieldProps(BRIEF_FORM_KEYS.COLOR_NOTE)}
                  label="Color"
                  placeholder="Color notes"
                />
              </>
            )}
          </div>
        </div>
      )}
    </ScrollIntoView>
  );
};
