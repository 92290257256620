// Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';
// Utils
import { useAnalytics, useMobile } from 'hooks';
// Assets
import successBackground from '../../assets/successBackground.jpeg';
import successBackgroundMobile from '../../assets/successBackgroundMobile.png';
// Constants
import { ROUTES } from 'features/navigation';
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { SEARCH_PARAM_KEYS } from 'features/constants';

export const SubmissionSuccess: React.FC = () => {
  const analytics = useAnalytics()!;
  const navigate = useNavigate();
  const { isMobile } = useMobile();

  const goToHome = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBSubmissionSuccess,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbSubmissionNo,
    });
    navigate(ROUTES.HOME.route);
  };

  const goToCreateBrief = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBSubmissionSuccess,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbSubmissionYes,
    });
    navigate(ROUTES.CREATE_BRIEF.route);
  };

  const goToTeamInvite = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBSubmissionSuccess,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbSubmissionYes,
    });

    navigate({
      pathname: ROUTES.PROFILE.route,
      search: `?${SEARCH_PARAM_KEYS.TAB}=company`,
    });
  };

  return (
    <div
      className="sm:flex h-fit-content sm:h-screen lg:h-[calc(100vh-4rem)] text-center w-full px-8 py-[66px] sm:py-0 sm:px-0"
      style={{
        backgroundSize: '100% 100%',
        backgroundImage: `url(${
          isMobile ? successBackgroundMobile : successBackground
        })`,
      }}
    >
      <section className="sm:flex sm:self-center px-6 py-[52px] sm:py-0 lg:self-auto sm:h-1/2 h-fit-content lg:h-full sm:items-center bg-white sm:w-3/5 mx-auto">
        <div className="sm:mx-14">
          <h1 className="text-xl leading-9 sm:leading-[48px] sm:text-[3rem] font-headline text-darkGray">
            Congrats, your brief is submitted!
          </h1>
          <p className="text-base sm:text-lg font-display text-gray mt-6 mb-10">
            Someone from the Prime Matter Labs team will be in touch soon to get
            your project started.
          </p>
          <div>
            <p className="text-base sm:text-lg font-display text-darkGray mt-6 mb-6">
              Would you like to create another project brief?
            </p>
            <div className="flex flex-col sm:flex-row items-center sm:justify-center">
              <button
                onClick={goToCreateBrief}
                className="px-5 py-4 bg-blue-60 text-white text-lg font-display mb-3 sm:mb-0"
              >
                Yes, start a new brief
              </button>
              <button
                onClick={goToTeamInvite}
                className="sm:ml-3 px-5 py-4 bg-white text-darkGray text-lg font-display border-2"
              >
                Invite team members
              </button>
            </div>
            <button
              onClick={goToHome}
              className="sm:ml-3 mt-4 underline text-lg font-display text-darkGray"
            >
              Go to my projects dashboard
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
