import React, { useState } from 'react';
import { SampleFeedbackForm } from './sample-feedback-form.component';
import { Box, Typography } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  round: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, round, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={round !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {round === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

interface LegacySampleFeedbackFormProps {
  sampleFeedbacks: any[];
}

export const LegacySampleFeedbackForm: React.FC<LegacySampleFeedbackFormProps> = ({
  sampleFeedbacks,
}) => {
  const [round, setRound] = useState<number>(0);

  return (
    <section className="font-mono pb-16 overflow-auto">
      <nav className="relative bg-darkGray text-white rounded-t min-w-[1000px]">
        <ul className="flex max-w-[100vw] overflow-auto sticky left-0">
          {sampleFeedbacks.map(({ id, attributes }, index) => (
            <li
              onClick={() => setRound(index)}
              key={id}
              className={`py-4 px-12 cursor-pointer text-center ${
                round === index ? 'border-b-2 border-legacyGreen' : ''
              }`}
            >
              <h6 className="text-xs">ROUND {attributes.round}</h6>
            </li>
          ))}
        </ul>
      </nav>
      <div className="min-w-[1000px]">
        <div className="bg-darkGray text-white text-xs py-4 font-bold">
          <div className="flex items-center ">
            <h6 className="px-5 w-2/12 text-left">CHARACTERISTIC</h6>
            <h6 className="px-5 w-1/12 text-center">APPROVED</h6>
            <div className="px-3 w-1/12 text-center">
              <h6 className="m-auto">NOT</h6>
              <h6>APPROVED</h6>
            </div>
            <h6 className="px-5 w-1/12 text-center">N/A</h6>
            <h6 className="px-5 w-7/12 text-left">NOTES</h6>
          </div>
        </div>
        {sampleFeedbacks.map((sampleFeedback, index) => (
          <TabPanel round={round} index={index}>
            <SampleFeedbackForm
              sampleFeedback={sampleFeedback}
              isReadOnly
              isLegacyView
            />
          </TabPanel>
        ))}
      </div>
    </section>
  );
};
