// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { TextArea } from 'design-system';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const ProductVision: React.FC = () => {
  const { getFieldProps, setFieldValue } = useFormikContext();

  return (
    <div className="flex flex-col gap-6">
      <p id="product-vision" className="font-inter text-lg text-grey-20 mb-6">
        Product Vision
      </p>
      <div>
        <TextArea
          label="Product Description"
          placeholder="Share a brief overview"
          {...getFieldProps(BRIEF_FORM_KEYS.PRODUCT_DESCRIPTION)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(BRIEF_FORM_KEYS.PRODUCT_DESCRIPTION, e.target.value)
          }
        />
      </div>
      <div>
        <TextArea
          label="Purpose"
          placeholder="Why do you need to develop this?"
          {...getFieldProps(BRIEF_FORM_KEYS.PRODUCT_PURPOSE)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(BRIEF_FORM_KEYS.PRODUCT_PURPOSE, e.target.value)
          }
        />
      </div>
      <div>
        <TextArea
          label="Function"
          {...getFieldProps(BRIEF_FORM_KEYS.PRODUCT_FUNCTION)}
          placeholder="What does it do?"
        />
      </div>
      <div>
        <TextArea
          label="Directions for use"
          placeholder="How should this product be used?"
          {...getFieldProps(BRIEF_FORM_KEYS.DIRECTIONS_FOR_USE)}
        />
      </div>
      <div>
        <TextArea
          label="Objective"
          placeholder="What is the desired outcome of using this product?"
          {...getFieldProps(BRIEF_FORM_KEYS.PRODUCT_OBJECTIVE)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(BRIEF_FORM_KEYS.PRODUCT_OBJECTIVE, e.target.value)
          }
        />
      </div>
      <div>
        <TextArea
          label="Target"
          placeholder="Audience/Demographic"
          {...getFieldProps(BRIEF_FORM_KEYS.TARGET_CUSTOMER)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue(BRIEF_FORM_KEYS.TARGET_CUSTOMER, e.target.value)
          }
        />
      </div>
    </div>
  );
};
