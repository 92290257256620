// Libraries
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
// Components
import { UserContextProvider } from 'context';
import { AuthWrapper } from 'features/rbac';
import App from './features/app';
// Utils
import { AnalyticsContextProvider, analytics } from 'features/analytics';
// Styles
import './index.scss';
import themeV2 from 'styles/mui/themeV2';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <AnalyticsContextProvider instance={analytics()}>
        <ThemeProvider theme={themeV2}>
          <UserContextProvider>
            <AuthWrapper>
              <App />
            </AuthWrapper>
          </UserContextProvider>
        </ThemeProvider>
      </AnalyticsContextProvider>
    </Router>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
