// Libraries
import React from 'react';
import { Typography, makeStyles, createStyles } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
// Utils
import { ITheme } from 'styles/mui/themeV2';
// Constants
import {
  CLOSURE_MENU_ITEMS,
  CONTAINER_MENU_ITEMS,
  INITIAL_ADDITIONAL_COMPONENTS_VALUES,
  MATERIAL_MENU_ITEMS,
  NA,
} from './constants';
import {
  BriefAttributes,
  BriefSupplementAttributes,
  IApiData,
  MASTER_BOX_LABEL,
} from 'api';
const MISSING = 'Missing';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    grayBackground: {
      backgroundColor: theme.palette.gray.lighter,
    },
    label: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(10),
      whiteSpace: 'nowrap',
    },
  })
);

interface BriefSummaryPanelProps {
  brief: IApiData<BriefAttributes>;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
}

export const BriefSummaryPanel: React.FC<BriefSummaryPanelProps> = ({
  brief,
  setTabValue,
}) => {
  const classes = useStyles();

  const renderCost = () => {
    if (!brief) return NA;

    const {
      attributes: { targetMaxCost, targetMinCost, targetCost },
    } = brief;

    return targetMinCost && targetMaxCost
      ? `$${targetMinCost.toFixed(2)}-${targetMaxCost.toFixed(2)}`
      : targetCost
      ? // @ts-ignore
        `$${targetCost.toFixed(2).toLocaleString('en-US')}`
      : NA;
  };

  const packagingComponents = {
    ...(brief.attributes.briefSupplement?.data
      .attributes as BriefSupplementAttributes),
  };

  const isMissingComponentValues =
    !packagingComponents.container ||
    !packagingComponents.material ||
    !packagingComponents.closure;

  return (
    <div className={`flex flex-col my-6 mt-0 p-5 ${classes.grayBackground}`}>
      <div className="flex justify-between mb-6">
        <div className="flex">
          <div className="flex flex-col">
            <Typography className={classes.label} variant="h4">
              Unit Size
            </Typography>
            <Typography
              aria-label="Unit Size"
              className={`${brief.attributes.size ? '' : 'text-red-40'}`}
            >
              {brief.attributes.size
                ? `${brief.attributes.size} ${brief.attributes.unit}`
                : MISSING}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className={classes.label} variant="h4">
              Order Quantity
            </Typography>
            <Typography
              aria-label="Order Quantity"
              className={`${
                brief.attributes.minimumOrderQuantity ? '' : 'text-red-40'
              }`}
            >
              {brief.attributes.minimumOrderQuantity
                ? brief.attributes.minimumOrderQuantity.toLocaleString('en-US')
                : MISSING}
            </Typography>
          </div>
          <div className="flex flex-col">
            <Typography className={classes.label} variant="h4">
              Target Cost
            </Typography>
            <Typography aria-label="Target Cost">
              {brief ? renderCost() : NA}
            </Typography>
          </div>
          <div className="flex items-start">
            <EditOutlined
              onClick={() => setTabValue(0)}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-col">
          <Typography className={classes.label} variant="h4">
            Primary Packaging
          </Typography>
          {isMissingComponentValues ? (
            <Typography className={'text-formulaRed'}>Missing</Typography>
          ) : (
            <>
              <Typography aria-label="Container">
                {CONTAINER_MENU_ITEMS[packagingComponents.container]?.label} (
                {MATERIAL_MENU_ITEMS[packagingComponents.material]?.label || ''}
                )
              </Typography>
              <Typography aria-label="Closure">
                {CLOSURE_MENU_ITEMS[packagingComponents.closure]?.label}
              </Typography>
            </>
          )}
        </div>
        <div className="flex flex-col">
          <Typography className={classes.label} variant="h4">
            Secondary Packaging
          </Typography>
          <>
            {packagingComponents.masterBox && (
              <Typography aria-label="Master Box">
                Master Box: {MASTER_BOX_LABEL[packagingComponents.masterBox]}
              </Typography>
            )}
            {packagingComponents.innerBox && (
              <Typography aria-label="Inner Box">
                {INITIAL_ADDITIONAL_COMPONENTS_VALUES.innerBox.label}
              </Typography>
            )}
            {packagingComponents.tamperSleeve && (
              <Typography aria-label="Tamper Sleeve">
                {INITIAL_ADDITIONAL_COMPONENTS_VALUES.tamperSleeve.label}
              </Typography>
            )}
            {packagingComponents.unitCarton && (
              <Typography aria-label="Unit Carton">
                {INITIAL_ADDITIONAL_COMPONENTS_VALUES.unitCarton.label}
              </Typography>
            )}
            {packagingComponents.overCap && (
              <Typography aria-label="Over Cap">
                {INITIAL_ADDITIONAL_COMPONENTS_VALUES.overCap.label}
              </Typography>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
