// Libraries
import React, { useContext, useState } from 'react';
import {
  IconButton,
  Grid,
  Tooltip,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { DescriptionOutlined, EditOutlined } from '@material-ui/icons';
// Components
import { FormulaContext } from '../context';
import { GenerateReportModal } from './generate-report-modal.component';
import { EditAndDuplicateFormulaDialog } from './edit-and-duplicate-formula-dialog.component';
import { NoteDialog } from './note-dialog.component';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { Dialog } from './types';
// Assets
import { ReactComponent as GenerateReport } from '../images/generateReport.svg';
// Constants
import { DIALOG_MODES } from './constants';
const { hidden, report, edit, note } = DIALOG_MODES;

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.gray.dark,
      '&:hover': {
        backgroundColor: theme.palette.gray.light,
        color: theme.palette.gray.dark,
      },
    },
  })
);

export const FormulaActions: React.FC = () => {
  const classes = useStyles();
  const { formula } = useContext(FormulaContext);

  const [dialogMode, setDialogMode] = useState<Dialog>(hidden);
  const closeDialog = () => setDialogMode(hidden);

  const isDialogOpen = (dialogModeType: Dialog) =>
    dialogMode === dialogModeType;
  const openReportDialog = () => setDialogMode(report);
  const openNoteDialog = () => setDialogMode(note);
  const openEditDialog = () => setDialogMode(edit);

  const hasNote = formula?.note;

  if (!formula) {
    return null;
  }

  return (
    <>
      <GenerateReportModal
        open={isDialogOpen(report)}
        handleClose={closeDialog}
        formulaUuid={formula.id}
        formulaName={formula.name}
      />
      <NoteDialog open={isDialogOpen(note)} handleClose={closeDialog} />
      <EditAndDuplicateFormulaDialog
        open={isDialogOpen(edit)}
        handleClose={closeDialog}
      />
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={2}
        spacing={4}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Tooltip title={'Edit details'}>
            <IconButton className={classes.button} onClick={openEditDialog}>
              <EditOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={hasNote ? 'Edit note' : 'Add note'}>
            <IconButton className={classes.button} onClick={openNoteDialog}>
              <DescriptionOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={'Generate report'}>
            <IconButton className={classes.button} onClick={openReportDialog}>
              <GenerateReport />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};
