//Libraries
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  TextField,
  Grid,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';

interface SearchBarProps {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus: boolean;
  placeholder: string;
  helperText: string;
  handleKeyDown: (event: React.KeyboardEvent) => void;
  children?: React.ReactElement;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  handleSearch,
  autoFocus,
  placeholder,
  helperText,
  handleKeyDown,
  children,
}) => {
  return (
    <div onKeyDown={handleKeyDown}>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            name="Search"
            fullWidth
            placeholder={placeholder}
            // We may want this to be a prop
            autoComplete={'off'}
            autoFocus={autoFocus}
            onChange={handleSearch}
          />
          <FormHelperText style={{ marginTop: 10 }}>
            {helperText}
          </FormHelperText>
        </Grid>
        <Grid container item xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};
