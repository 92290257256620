//Libraries
import React, { useContext, useEffect } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
//Assets
import briefIcon from 'img/brief-image-circle.svg';
import formulaIcon from 'img/dropper-circle.svg';
//Components
import { ProjectAndFormulaSearch } from './search';
import { Page } from 'features/ui';
import { UserContext } from 'context';
import { ErrorBoundary } from 'features/error';
import { useSnackbar } from 'hooks';
//Utils
import { ROUTES } from 'features/navigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      color: theme.palette.secondary.dark,
    },
    box: {
      cursor: 'pointer',
      backgroundColor: '#fff2e7',
      '&:hover': {
        backgroundColor: '#fcdcbe',
      },
      padding: '18px 20px',
    },
    h1: {
      fontFamily: 'Agipo Regular',
      fontWeight: 400,
      fontSize: '2.5rem',
      color: '#333',
    },
    h3: {
      fontFamily: 'Inter Regular',
      fontWeight: 400,
      fontSize: '1.25rem',
      color: '#333',
    },
    icon: {
      color: '#333',
    },
    link: {
      color: '#333',
      cursor: 'pointer',
    },
    accordion: {
      border: 'solid 1px #eaeaea',
    },
    accordionDetails: {
      flexDirection: 'column',
      padding: 0,
    },
    accordianSummary: {
      fontFamily: 'Inter Regular',
    },
  })
);

export const EmployeeDashboard: React.FC = () => {
  const navigate = useNavigate();
  const {
    state,
  }: {
    state: { formulaWasArchived?: boolean; formulaExternalId?: string };
  } = useLocation();
  const { formulaWasArchived, formulaExternalId } = state || {};

  const classes = useStyles();
  const { userSession } = useContext(UserContext)!;
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (formulaWasArchived) {
      showSnackbar(
        `Formula ${formulaExternalId} has been archived. Contact tech team to restore formula.`,
        'success'
      );
    }
  }, [formulaWasArchived, formulaExternalId, showSnackbar]);

  return (
    <Page>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography className={classes.h1} align="left">
            {userSession.givenName && `Welcome, ${userSession.givenName}`}
          </Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <Link
            className={classes.link}
            onClick={() => navigate(ROUTES.RAW_MATERIALS.route)}
          >
            <Grid container alignItems="center">
              <Grid item style={{ marginBottom: '8px' }}>
                <MenuBookOutlinedIcon className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography
                  style={{ marginLeft: '10px' }}
                  variant="body2"
                  align="right"
                >
                  Raw Materials Dictionary
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <Link
            className={classes.link}
            onClick={() => navigate(ROUTES.CREATE_RAW_MATERIAL.route)}
          >
            <Grid container alignItems="center">
              <Grid item style={{ marginBottom: '8px', marginLeft: '22px' }}>
                <AddCircleOutlineOutlinedIcon className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography
                  style={{ marginLeft: '10px' }}
                  variant="body2"
                  align="right"
                >
                  Add Raw Material
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Link
            style={{ textDecoration: 'none' }}
            onClick={() => navigate(ROUTES.CREATE_INTERNAL_BRIEF.route)}
          >
            <div style={{ marginRight: '10px' }} className={classes.box}>
              <Grid container alignItems="center">
                <Grid item>
                  <img src={briefIcon} alt="Icon" />
                </Grid>
                <Grid item>
                  <Typography
                    style={{ marginLeft: '1rem' }}
                    className={classes.h3}
                  >
                    Build a project
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link
            style={{ textDecoration: 'none' }}
            onClick={() => navigate(ROUTES.CREATE_FORMULA.route)}
          >
            <div style={{ marginLeft: '10px' }} className={classes.box}>
              <Grid container alignItems="center">
                <Grid item>
                  <img src={formulaIcon} alt="Icon" />
                </Grid>
                <Grid item>
                  <Typography
                    style={{ marginLeft: '1rem' }}
                    className={classes.h3}
                  >
                    Create a formula
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Link>
        </Grid>
      </Grid>
      <br />
      <ErrorBoundary>
        <ProjectAndFormulaSearch />
      </ErrorBoundary>
    </Page>
  );
};
