// Libraries
import React, { useState } from 'react';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
// Utils
import { interRegular, ITheme } from 'styles/mui/themeV2';
// Constants
const OVERFLOW_STRING_LENGTH = 124;

interface IStyleProps {
  note: Maybe<string>;
  isNoteExpanded: boolean;
}

interface FormulaNoteProps {
  note: string;
  noteCreatedBy: Maybe<string>;
  noteCreatedOn: Maybe<Date | string>;
  noteLastModifiedBy: Maybe<string>;
  noteLastModifiedOn: Maybe<Date | string>;
}

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    noteContainer: {
      fontFamily: interRegular.fontFamily,
      color: theme.palette.gray.dark,
      backgroundColor: theme.palette.gray.lighter,
      border: `1px solid ${theme.palette.gray.light}`,
      borderTop: 'none',
      borderBottomLeftRadius: '0.25rem',
      borderBottomRightRadius: '0.25rem',
    },
    note: {
      display: 'flex',
      alignItems: 'center',
      width: '75%',
      padding: '1rem 1.25rem',
    },
    noteSeeMore: {
      width: 'fit-content',
      whiteSpace: 'nowrap',
      textDecoration: 'underline',
      userSelect: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    noteChangeLog: {
      padding: '1rem 1.25rem 1rem 0',
      color: theme.palette.gray.main,
    },
    noteText: {
      whiteSpace: (props: IStyleProps) =>
        props.isNoteExpanded ? 'pre-line' : 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

export const FormulaNote: React.FC<FormulaNoteProps> = ({
  note,
  noteCreatedBy,
  noteCreatedOn,
  noteLastModifiedBy,
  noteLastModifiedOn,
}) => {
  const [isNoteExpanded, setIsNoteExpanded] = useState<boolean>(false);
  const classes = useStyles({
    isNoteExpanded,
    note: note,
  });

  return (
    <Grid className={classes.noteContainer} container justify="space-between">
      <Grid className={classes.note}>
        <Typography variant="body2" className={classes.noteText}>
          Note: {note}
          {isNoteExpanded && (
            <>
              {' '}
              <Typography
                variant="body2"
                component="span"
                className={classes.noteSeeMore}
                onClick={() => setIsNoteExpanded(false)}
              >
                See Less
              </Typography>
            </>
          )}
        </Typography>
        {note.length > OVERFLOW_STRING_LENGTH && !isNoteExpanded ? (
          <Typography
            variant="body2"
            className={classes.noteSeeMore}
            onClick={() => setIsNoteExpanded(true)}
          >
            See More
          </Typography>
        ) : (
          ''
        )}
      </Grid>
      <Grid className={classes.noteChangeLog} direction="column">
        <Grid item>
          <Typography variant="caption">
            Added by {noteCreatedBy} on{' '}
            {noteCreatedOn instanceof Date
              ? noteCreatedOn?.toLocaleDateString()
              : noteCreatedOn}
          </Typography>
        </Grid>
        <Grid>
          {noteLastModifiedBy && (
            <Typography variant="caption">
              Last modified by {noteLastModifiedBy} on{' '}
              {noteLastModifiedOn instanceof Date
                ? noteLastModifiedOn?.toLocaleDateString()
                : noteLastModifiedOn}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
