// Libraries
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
// Components
import { CollapsibleSection } from './collapsible-section.component';
import {
  SampleFeedbackActions,
  ThirdPartyTestingActions,
} from './customer-actions';
import { SampleFeedbackDialog } from './sample-feedback';
import { IngredientListFeedbackActions } from './customer-actions';
import { IngredientListFeedbackModal } from './ingredient-list-feedback-modal.component';
import { DuplicateFileDialogue } from '../file-manager/duplicate-file-dialogue.component';
import { UploadFileButton } from './customer-actions/upload-file-button.component';
import { TestingAcknowledgementModal } from 'features/third-party-test';
// Utils
import { TaskAttributes, useApi } from 'api';
import { SectionStatus, Task, TaskStatus, MileStone } from '../../types';
import {
  isFileADuplicate,
  isFileExtensionAccepted,
  isFileSizeTooBig,
} from '../file-manager';
import { IApiData, ProjectAttributes } from 'api';
import { useSnackbar } from 'hooks';
import { SampleFeedbackModalState } from './types';
// Constants
import * as Constants from '../../constants';
import { MILESTONES, TASK_TITLES } from '../../constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

interface IProjectTracker {
  project: IApiData<ProjectAttributes>;
  setProject: React.Dispatch<React.SetStateAction<IApiData<ProjectAttributes>>>;
}

export const ProjectTracker: React.FC<IProjectTracker> = ({
  project,
  setProject,
}) => {
  const [files, setFiles] = useState<any>([]);
  const [sampleFeedbackModalState, setSampleFeedbackModalState] = useState<
    SampleFeedbackModalState
  >({
    isOpen: false,
    isViewOnly: false,
  });

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(
    false
  );
  const [
    isThirdPartyTestingModalOpen,
    setIsThirdPartyTestingModalOpen,
  ] = useState<boolean>(false);
  const [viewedFile, setViewedFile] = useState<any>(null);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [fileCategory, setFileCategory] = useState<string>('');
  const [fileInputId, setFileInputId] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDuplicateFileModalOpen, setIsDuplicateFileModalOpen] = useState<
    boolean
  >(false);

  const { showSnackbar } = useSnackbar();

  const location = useLocation();
  const isFileUploadedSuccessfully = (location?.state as {
    fileUploadSuccess: any;
  })?.fileUploadSuccess;

  useEffect(() => {
    if (isFileUploadedSuccessfully) {
      showSnackbar(
        'Success! IL uploaded. Previous IL versions can be viewed in Files tab.',
        'success',
        <Link
          to={`${ROUTES.SHOW_PROJECT.route.replace(
            UUID_SHOW_ROUTE_STRING,
            project.id
          )}`}
          style={{ textDecoration: 'underline' }}
        >
          Return to employee brief view.
        </Link>
      );
    }
  }, [isFileUploadedSuccessfully, showSnackbar, project.id]);

  const {
    getProjectFileUrl,
    getProjectFiles,
    deleteProjectFile,
    postProjectFile,
  } = useApi();

  useEffect(() => {
    // files are called 'documents' in the API
    getProjectFiles({
      urlParams: `${project.id}/documents`,
      handleSuccess: ({ data }) => {
        setFiles(data);
      },
    });
  }, [getProjectFiles, project.id]);

  const handleFileDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    const fileName = e.currentTarget.getAttribute('data-file-name')!;

    if (!window.confirm(`Delete ${fileName}?`)) return;

    setIsDeleting(true);

    const uriEncodedUuid = e.currentTarget.getAttribute('data-file-id')!;

    deleteProjectFile({
      // files are called 'documents' in the API
      urlParams: `${project.id}/documents/${uriEncodedUuid}`,
      handleSuccess: ({ data }) => {
        setFiles(data);
      },
      handleFinally: () => setIsDeleting(false),
    });
  };

  const handleFileDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    const uriEncodedUuid = e.currentTarget.getAttribute('data-file-id')!;

    getProjectFileUrl({
      // files are called 'documents' in the API
      urlParams: `${project.id}/documents/${uriEncodedUuid}?format=url`,
      handleSuccess: ({ data }) => {
        // this initiates an auto download to the browser
        let a = document.createElement('a');
        a.href = data.presigned_url;
        a.download = data.name;
        a.click();
        a.remove();
      },
    });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files![0];
    if (isFileSizeTooBig(newFile)) {
      alert(Constants.UPLOAD_WARNINGS.fileSizeWarning.content);
      return;
    } else if (!isFileExtensionAccepted(newFile)) {
      alert(Constants.UPLOAD_WARNINGS.fileExtensionWarning.content);
      return;
    } else if (isFileADuplicate(newFile, files)) {
      /**
       * store the file and category in state for sending later
       * because the callback is invoked from the modal and it doesn't
       * have access to the event at that time
       */
      setUploadedFile(newFile);
      setFileCategory(e.currentTarget.getAttribute('data-upload-category')!);
      // store the id of the input that triggered the modal so we can reset it later if they cancel
      setFileInputId(e.target.id);
      setIsDuplicateFileModalOpen(true);
    } else {
      // set state is async so this invocation will not have access to the updated state so pass the event instead
      sendFileToServer(e);
    }
  };

  const sendFileToServer = async (e?: any, dupeResponse?: string) => {
    setIsUploading(true);

    /**
     * if the function is invoked from the duplicate confirmation modal
     * then use the category and file data from state because it no longer has the file upload event data
     * else it was invoked from the handler so use the data from the upload event
     */
    const category = e
      ? e.currentTarget.getAttribute('data-upload-category')!
      : fileCategory;

    const selectedFile = e ? e.target.files![0] : uploadedFile;
    // capture user response from duplicate confirmation modal, if any
    const dupeHandler = dupeResponse ? `&dupe=${dupeResponse}` : '';
    const formData = new FormData();
    formData.append('document', selectedFile);

    postProjectFile({
      urlParams: `${project.id}/documents?category=${category}${dupeHandler}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      handleSuccess: ({ data }) => {
        setFiles(data);
      },
      handleFinally: () => setIsUploading(false),
    });
  };

  const handleDuplicateFileModal = () => {
    const inputElement = document.getElementById(
      fileInputId
    ) as HTMLInputElement;

    // if the user the upload then all these states have to be reset
    inputElement.value = '';
    setFileInputId('');
    setUploadedFile(null);
    setFileCategory('');
    setIsDuplicateFileModalOpen(false);
  };

  const tasksMap = project.parsedIncluded.tasks?.reduce(
    (
      map: { [key: string]: IApiData<TaskAttributes> },
      task: IApiData<TaskAttributes>
    ) => {
      map[task.attributes.mondayColumnId] = task;
      return map;
    },
    {}
  );

  const determineSectionStatus = (milestone: MileStone) => {
    const foundTasks = milestone.tasks
      .map((task) => tasksMap[task.columnId])
      // filter out tasks that don't have a corresponding task in the API
      .filter(Boolean);

    if (
      foundTasks.every(
        (task) =>
          task.attributes.status === TaskStatus.done ||
          task.attributes.status === TaskStatus.waived
      )
    ) {
      return SectionStatus.completed;
    } else if (
      foundTasks.some(
        (task) =>
          task.attributes.status === TaskStatus.inProgress ||
          task.attributes.status === TaskStatus.pending ||
          task.attributes.status === TaskStatus.done
      )
    ) {
      return SectionStatus.inProgress;
    } else {
      return SectionStatus.notStarted;
    }
  };

  const generateActionsFromTask = (task: Task) => {
    if (!task.isActionable) return;

    switch (task.title) {
      case TASK_TITLES.APPROVE_INGREDIENT_LIST:
        return (
          <IngredientListFeedbackActions
            task={task}
            project={project}
            files={files}
          />
        );
      case TASK_TITLES.GIVE_FEEDBACK:
        return (
          <SampleFeedbackActions
            task={task}
            handleOpenSampleFeedbackModal={() =>
              setSampleFeedbackModalState({
                isOpen: true,
                isViewOnly: false,
              })
            }
            project={project as any}
            setProject={setProject}
          />
        );
      case TASK_TITLES.SEND_ARTWORK:
      case TASK_TITLES.SEND_BILL_OF_MATERIALS:
        return (
          <UploadFileButton
            category={{ title: task.title, tag: task.tag! }}
            isUploading={isUploading}
            handleUpload={handleFileUpload}
          />
        );
      case TASK_TITLES.COMPLETE_3RD_PARTY_TESTING_ACKNOWLEDGEMENT:
        return (
          <ThirdPartyTestingActions
            task={task}
            openThirdPartyTestingModal={() =>
              setIsThirdPartyTestingModalOpen(true)
            }
          />
        );

      default:
        return null;
    }
  };

  const handleFeedbackModalClick = (file?: any, modalType?: string) => {
    /**
     * handles toggle opening and closing the IL modal by the action buttons
     * also delegates the type of modal to open
     * isReadonly must always be set to false when the modal is opened this way so user can take action
     */
    setViewedFile(file ? file : null);
    setIsReadOnly(false);
    setIsFeedbackModalOpen(!isFeedbackModalOpen);
  };

  const handleViewILFeedback = (file: any) => {
    // specifically handle opening the IL modal for viewing feedback
    setViewedFile(file);
    setIsReadOnly(true);
    setIsFeedbackModalOpen(true);
  };

  const handleViewSampleFeedbackHistory = () => {
    setSampleFeedbackModalState({
      isOpen: true,
      isViewOnly: true,
    });
  };

  const renderMilestones = () => {
    return MILESTONES.map((milestone, index) => {
      return (
        <CollapsibleSection
          key={index}
          companyName={project.attributes.companyName}
          project={project}
          title={milestone.title}
          sampleTrackingNumber="12345"
          status={determineSectionStatus(milestone)}
          formulationRound={project.attributes.sampleSubmissionRound}
          files={files}
          handleDelete={handleFileDelete}
          handleDownload={handleFileDownload}
          isDeleting={isDeleting}
          handleModalClick={handleFeedbackModalClick}
          setFiles={setFiles}
          setProject={setProject}
          handleViewILFeedback={handleViewILFeedback}
          handleViewSampleFeedbackHistory={handleViewSampleFeedbackHistory}
          handleUpload={handleFileUpload}
          tasks={milestone.tasks
            // filter out tasks that don't have a corresponding task in the API
            .filter((task) => tasksMap[task.columnId])
            .map((task) => {
              const foundTask = tasksMap[task.columnId];
              const taskStatus = foundTask.attributes.status;
              const updatedAt = foundTask.attributes.updatedAt;

              return {
                ...task,
                actions: generateActionsFromTask({
                  ...task,
                  status: taskStatus,
                }),
                status: taskStatus,
                updatedAt: updatedAt,
              };
            })}
        />
      );
    });
  };

  return (
    <div className="flex flex-col">
      <SampleFeedbackDialog
        project={project as any}
        setProject={setProject}
        sampleFeedbackModalState={sampleFeedbackModalState}
        setSampleFeedbackModalState={setSampleFeedbackModalState}
      />
      <IngredientListFeedbackModal
        files={files}
        isOpen={isFeedbackModalOpen}
        handleClose={handleFeedbackModalClick}
        setFiles={setFiles}
        isReadOnly={isReadOnly}
        viewedFile={viewedFile}
      />
      <TestingAcknowledgementModal
        project={project as any}
        setProject={setProject}
        isOpen={isThirdPartyTestingModalOpen}
        closeModal={() => setIsThirdPartyTestingModalOpen(false)}
      />
      <DuplicateFileDialogue
        isOpen={isDuplicateFileModalOpen}
        handleClose={handleDuplicateFileModal}
        handleDupe={sendFileToServer}
      />
      {renderMilestones()}
    </div>
  );
};
