// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { TextField } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

interface TimelineProps {
  isInternalBrief?: boolean;
}

export const Timeline: React.FC<TimelineProps> = ({ isInternalBrief }) => {
  const { getFieldProps, errors } = useFormikContext<InternalBriefFormValues>();

  return (
    <div>
      <p id="timeline" className="font-inter text-lg text-grey-20 mb-6">
        Timeline
      </p>
      {isInternalBrief ? (
        <div className="grid grid-col-1 md:grid-cols-3 md:gap-x-6">
          <TextField
            {...getFieldProps(BRIEF_FORM_KEYS.INITIAL_DATE_OF_BRIEF)}
            label="Initial Date of Brief"
            placeholder="00/00/00"
            error={Boolean(errors.initialDateOfBrief)}
            helperText={errors.initialDateOfBrief}
          />
          <TextField
            {...getFieldProps(BRIEF_FORM_KEYS.APPROVAL_DATE)}
            label="Approval Date"
            placeholder="00/00/00"
            error={Boolean(errors.approvalDate)}
            helperText={errors.approvalDate}
          />
          <TextField
            {...getFieldProps(BRIEF_FORM_KEYS.TARGET_DATE)}
            label="Target Launch Date"
            placeholder="00/00/00"
            error={Boolean(errors.targetDate)}
            helperText={errors.targetDate}
          />
        </div>
      ) : (
        <div>
          <TextField
            {...getFieldProps(BRIEF_FORM_KEYS.TARGET_DATE)}
            label="Target Launch Date"
            placeholder="00/00/00"
            data-testid="target-date"
            error={Boolean(errors.targetDate)}
            helperText={errors.targetDate}
          />
        </div>
      )}
    </div>
  );
};
