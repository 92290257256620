// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { createStyles, makeStyles, Link, Typography } from '@material-ui/core';
// Components
import { DialogModal } from 'features/ui';
import { SelectPricingTable } from './select-pricing-table.component';
// Utils
import { ExtendedFormulaAttributes, Ingredient } from './types';
import { Price } from 'features/types';
import { useApi as useApiV1 } from 'hooks/use-api.hook';
import { useApi } from 'api';
import { getPricesFromRawMaterial } from 'features/raw-material';
import { useSnackbar } from 'hooks';
import { ITheme } from 'styles/mui/themeV2';
import { FormulaContext } from '../context';
// Constants
import * as Constants from 'features/constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.gray.dark,
    },
  })
);

interface SelectPricingDialogProps {
  isOpen: boolean;
  ingredient: Ingredient;
  handleClose: () => void;
}

export const SelectPricingDialog: React.FC<SelectPricingDialogProps> = ({
  ingredient,
  handleClose,
  isOpen,
}) => {
  const classes = useStyles();
  const request = useApiV1();
  const { getRawMaterial } = useApi();
  const { showSnackbar } = useSnackbar();
  const { formula, setFormula } = useContext(FormulaContext);

  const [prices, setPrices] = useState<Price[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const currentPriceId = (ingredient?.price as Price).uuid as string;

  const [selectedPriceId, setSelectedPriceId] = useState<string>(
    currentPriceId
  );

  const dialogCta = (
    <Typography variant="body2">
      Need to change or add new pricing?{' '}
      <Link
        target="_blank"
        href={ROUTES.SHOW_RAW_MATERIAL.route.replace(
          UUID_SHOW_ROUTE_STRING,
          ingredient.rmUuid
        )}
        className={classes.link}
      >
        Update original pricing
      </Link>
    </Typography>
  );

  useEffect(() => {
    getRawMaterial({
      urlParams: ingredient.rmUuid,
      handleSuccess: (data) => {
        setPrices(
          getPricesFromRawMaterial(
            data.prices,
            data.manufacturers,
            data.suppliers
          ) || []
        );
      },
    });
  }, [setPrices, request, ingredient.rmUuid, showSnackbar, getRawMaterial]);

  const handleSubmit = async () => {
    try {
      const response = await request({
        resource: `/api/v1/ingredients/${ingredient.id}`,
        options: {
          settings: Constants.PATCH,
          scope: 'update:ingredients',
          body: {
            ingredient: {
              priceUuid: selectedPriceId,
            },
          },
        },
      });
      if (response.data) {
        showSnackbar(`Pricing updated for ${ingredient.name}`, 'success');
        ingredient.price = response.data.attributes.highestPrice;
        setFormula({ ...formula } as ExtendedFormulaAttributes);
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error');
    }
    handleClose();
  };

  return (
    <DialogModal
      dialogCta={dialogCta}
      handleClose={handleClose}
      maxWidthSize="md"
      open={isOpen}
      onConfirm={handleSubmit}
      submitDisabled={submitDisabled}
      title={`Select pricing for ${ingredient.name}`}
    >
      <SelectPricingTable
        currentPriceId={currentPriceId}
        selectedPriceId={selectedPriceId}
        setSelectedPriceId={setSelectedPriceId}
        prices={prices}
        setSubmitDisabled={setSubmitDisabled}
      />
    </DialogModal>
  );
};
