// Libraries
import React from 'react';
// Styles
import { THEME_PALETTE } from 'styles/mui/themeV2';

interface IngredientListTrackerFileCardFeedbackProps {
  file: any;
  handleViewFeedback: (file: any) => void;
}

export const IngredientListTrackerFileCardFeedback: React.FC<IngredientListTrackerFileCardFeedbackProps> = ({
  file,
  handleViewFeedback,
}) => {
  const uploadDate = (file: any) => {
    return new Date(
      file.parsedIncluded.feedback.attributes.createdAt
    )?.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
    <div
      className="flex sm:items-center text-sm font-inter"
      style={{ color: THEME_PALETTE.gray.main }}
    >
      <div>
        <p>Feedback submitted {uploadDate(file)}</p>{' '}
      </div>
      &nbsp;
      <div className="flex items-start">
        <button
          onClick={() => handleViewFeedback(file)}
          className="underline border-none"
        >
          View
        </button>
      </div>
    </div>
  );
};
