// Libraries
import React from 'react';

interface NoteProps {
  additionalClasses?: string;
  header: string;
  contents: string[];
  noteColor?: string;
  headerColor?: string;
  textColor?: string;
  icon?: React.ReactComponentElement<any> | null;
  iconColor?: string;
  url?: string;
  urlColor?: string;
}

export const Note: React.FC<NoteProps> = ({
  additionalClasses,
  header,
  contents,
  noteColor,
  headerColor,
  textColor,
  icon: Icon,
  iconColor,
  url,
  urlColor,
}) => {
  return (
    <div className={`w-full p-3 rounded ${noteColor} ${additionalClasses}`}>
      <div className="flex flex-row items-center mb-3">
        {Icon &&
          React.cloneElement(Icon, {
            className: `${iconColor} mr-2`,
            height: 16,
            width: 16,
          })}
        <div>
          <p className={`font-inter text-sm font-semibold ${headerColor}`}>
            {header}
          </p>
        </div>
      </div>
      <div className="pl-6 pr-6">
        {contents.map((content, index) => (
          <p
            key={index}
            className={`font-inter text-sm font-normal ${textColor} ${
              index !== contents.length - 1 && 'mb-6'
            }`}
          >
            {content}
          </p>
        ))}
      </div>
      {url && (
        <div className="mt-2 pl-6 pr-6">
          <a
            target="_blank"
            rel="noreferrer"
            href={url}
            className={`font-inter text-sm font-semibold ${
              urlColor ? urlColor : 'text-blue-50'
            } `}
          >
            Learn more
          </a>
        </div>
      )}
    </div>
  );
};
