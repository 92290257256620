// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// Components
import { DialogModal } from 'features/ui';
import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar, useAnalytics } from 'hooks';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { FormulaContext } from '../context';
import { useApi } from 'api';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const EXTERNAL_ID = 'externalId';
const NAME = 'name';
const USAGE = 'usage';

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    helperText: {
      marginBottom: theme.spacing(4),
    },
    root: {
      '& .MuiSelect-root': {
        height: '54px',
      },
    },
    textField: {
      marginBottom: theme.spacing(5),
    },
    autocomplete: {
      height: '54px !important',
      marginBottom: theme.spacing(3.5),
      padding: '0',
    },
    select: {
      height: theme.spacing(5),
      marginTop: theme.spacing(1),
    },
  })
);

interface IEditFormulaDialog {
  open: boolean;
  handleClose: () => void;
  duplicateMode?: boolean;
}

interface IFormSelectField {
  id: string;
  attributes: { name: string };
}
interface IFormValues {
  company: IFormSelectField;
  externalId: string;
  name: string;
  project: IFormSelectField;
  projectUuid: string;
  usage?: string;
}

const USAGES = ['Leave on', 'Rinse off'];

export const EditAndDuplicateFormulaDialog: React.FC<IEditFormulaDialog> = ({
  open,
  handleClose,
  duplicateMode,
}) => {
  const classes = useStyles();
  const {
    getCompanyProjects,
    patchFormula,
    postFormula,
    searchCompanies,
  } = useApi();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { formula, setFormula } = useContext(FormulaContext);
  const { showSnackbar } = useSnackbar();

  const isLocked = formula?.locked;

  const handleFormulaUpdate = (values: IFormValues) => {
    patchFormula({
      urlParams: formula?.id,
      data: {
        formula: {
          externalId: values.externalId,
          name: values.name,
          projectUuid: values.project.id,
          usage: values.usage,
        },
      },
      handleSuccess: (data) => {
        formula &&
          setFormula({
            ...formula,
            externalId: data.attributes.externalId,
            name: data.attributes.name,
            projectUuid: values.project.id,
            usage: data.attributes.usage,
          });
        showSnackbar('Formula details successfully updated', 'success');
      },
      handleError: (error) => {
        showSnackbar((error as Error).message, 'error');
        Rollbar.warning(error);
      },
      handleFinally: () => {
        resetForm({
          values,
        });
        handleClose();
      },
    });
  };

  const handleDuplicateFormula = (values: IFormValues) => {
    postFormula({
      urlParams: `?source_uuid=${formula?.id}`,
      data: {
        formula: {
          ...formula,
          name: values.name,
          externalId: values.externalId,
          projectUuid: values.project.id,
          locked: false,
          usage: values.usage,
        },
      },
      handleSuccess: (data) => {
        navigate(
          ROUTES.SHOW_FORMULA.route.replace(UUID_SHOW_ROUTE_STRING, data.id),
          {
            state: { duplicated: true },
          }
        );
        analytics?.trackEvent({
          eventCategory: AnalyticConstants.EVENT_CATEGORIES.formulas,
          eventAction: AnalyticConstants.EVENT_ACTIONS.duplicate,
          eventLabel: `${formula?.id} ${values.name}`,
        });
      },
      handleError: (error) => {
        showSnackbar((error as Error).message, 'error');
        Rollbar.warning(error);
      },
      handleFinally: () => {
        handleClose();
      },
    });
  };

  const formik = useFormik<IFormValues>({
    initialValues: {
      company: {
        id: formula?.companyUuid || '',
        attributes: { name: formula?.companyName || '' },
      },
      externalId: duplicateMode ? '' : formula?.externalId || '',
      name: duplicateMode ? `Copy of ${formula?.name}` : formula?.name || '',
      projectUuid: formula?.projectUuid || '',
      project: {
        id: formula?.projectUuid || '',
        attributes: { name: formula?.projectName || '' },
      },
      usage: formula?.usage || '',
    },
    validationSchema: Yup.object({
      company: Yup.object({
        id: Yup.string().required('A company must be selected'),
      }),
      externalId: Yup.string()
        .required('A formula ID is required')
        .notOneOf(
          duplicateMode ? [formula?.externalId] : [],
          'A unique formula ID is required'
        ),
      name: Yup.string().required('A formula name is required'),
      project: Yup.object({
        id: Yup.string().required('A project must be selected'),
      }),
    }),
    onSubmit: duplicateMode ? handleDuplicateFormula : handleFormulaUpdate,
    enableReinitialize: true,
  });

  // getFieldProps automatically gives us => value, onBlur, onChange and Name attributes :)
  const {
    values,
    errors,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    resetForm,
    setFieldValue,
    handleBlur,
    touched,
  } = formik;

  const [companies, setCompanies] = useState<Array<IFormSelectField>>([]);
  const [projects, setProjects] = useState<Array<IFormSelectField>>([]);

  const CHANGE_REASON = {
    input: 'input',
    reset: 'reset',
  };

  const resetModal = () => {
    handleClose();
    resetForm();
  };

  const handleCompanyInputChange = (
    _event: React.ChangeEvent<{}>,
    query: string,
    reason: string
  ) => {
    if (reason !== CHANGE_REASON.input) return;
    if (query.trim().length) {
      searchCompanies({
        query: encodeURIComponent(query),
        urlParams: '',
        handleSuccess: (response) => {
          setCompanies(response.data);
        },
      });
    } else {
      // The field has been cleared; set the fields to blank
      setFieldValue('company', {
        id: '',
        attributes: { name: '' },
      });
      setFieldValue('project', {
        id: '',
        attributes: { name: '' },
      });
      setProjects([]);
    }
  };

  const handleCompanyChange = (_event: object, value: any) => {
    value?.id ? readProjects(value.id) : setProjects([]);
    setFieldValue('company', value);
    setFieldValue('project', { id: '', attributes: { name: '' } });
  };

  const handleProjectChange = (_event: object, value: any) => {
    setFieldValue('project', {
      id: value.props.value,
      attributes: { name: value.props.children },
    });
  };

  const readProjects = (uuid: string) => {
    getCompanyProjects({
      urlParams: `${uuid}/projects`,
      handleSuccess: (response) => {
        setProjects(response.data);
      },
    });
  };

  useEffect(() => {
    if (values.company?.id) {
      readProjects(values.company.id);
    }
    // eslint-disable-next-line
  }, [values.company.id]);

  return (
    <DialogModal
      onConfirm={handleSubmit}
      open={open}
      handleClose={resetModal}
      submitDisabled={isSubmitting || Object.values(errors).some(Boolean)}
      title={duplicateMode ? 'Duplicate Formula' : 'Formula details'}
    >
      <FormControl fullWidth>
        <Typography className={classes.helperText} variant="body2">
          Please enter the new duplicate formula id, and modify the formula name
          if needed.
        </Typography>
        <TextField
          disabled={isLocked}
          {...getFieldProps(NAME)}
          className={classes.textField}
          error={Boolean(errors.name && touched.name)}
          fullWidth
          helperText={touched.name ? errors.name : ''}
          label="Formula name"
          required
          variant="outlined"
        />
        <TextField
          {...getFieldProps(EXTERNAL_ID)}
          className={classes.textField}
          disabled={isLocked}
          error={Boolean(errors.externalId && touched.externalId)}
          fullWidth
          helperText={touched.externalId ? errors.externalId : ''}
          label="Formula #"
          required
          variant="outlined"
        />
        <Autocomplete
          className={classes.autocomplete}
          disabled={isLocked}
          disableClearable
          freeSolo
          getOptionLabel={(company) => company?.attributes?.name}
          onChange={handleCompanyChange}
          onInputChange={handleCompanyInputChange}
          options={companies}
          size="small"
          value={values.company}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.autocomplete}
              error={Boolean(errors.company?.id && touched.company)}
              fullWidth
              helperText={touched.company ? errors.company?.id : ''}
              label="Company (Existing)"
              name="company"
              onBlur={handleBlur}
              required
              variant="outlined"
            />
          )}
          style={{ marginBottom: Boolean(errors.company?.id) ? 28 : 14 }}
        />
        <TextField
          disabled={isLocked}
          select
          required
          label="Project (Existing)"
          error={Boolean(errors.project?.id && touched.project)}
          fullWidth
          helperText={touched.project ? errors.project?.id : ''}
          inputProps={{ className: classes.select }}
          name="project"
          onBlur={handleBlur}
          // @ts-ignore
          onChange={handleProjectChange}
          value={values.project.id}
          variant="outlined"
        >
          {projects.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {project.attributes.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.textField}
          disabled={isLocked}
          {...getFieldProps(USAGE)}
          inputProps={{ className: classes.select }}
          label="Usage"
          select
          style={{ marginTop: '20px' }}
          variant="outlined"
        >
          {USAGES.map((usage) => (
            <MenuItem key={usage} value={usage}>
              {usage}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </DialogModal>
  );
};
