// Libraries
import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@material-ui/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import jwtDecode from 'jwt-decode';
import { Navigate, useNavigate } from 'react-router-dom';
// Components
import { UserContext } from 'context';
// Utils
import { useApi, EmployeeAttributes, IApiData, IJWTPayload } from 'api';
// Constants
import { ROUTES } from 'features/navigation';

const LOCATIONS = [
  { value: 'Canada', name: 'Canada' },
  { value: 'Los Angeles', name: 'Los Angeles' },
  { value: 'Miami', name: 'Miami' },
  { value: 'New York City', name: 'New York City' },
];

const AVATAR_COLORS = [
  {
    name: 'Harp Green',
    value: '#dbebe7',
  },
  {
    name: 'Oasis Yellow',
    value: '#fde2ca',
  },
  {
    name: 'Periwinkle Gray',
    value: '#d6e0ef',
  },
];

enum InputNames {
  givenName = 'givenName',
  familyName = 'familyName',
  location = 'location',
  avatarColor = 'avatarColor',
}

const validationSchema = yup.object({
  [InputNames.givenName]: yup
    .string()
    .strict()
    .required('First name is required')
    .max(256, 'First name cannot be longer than 256 characters')
    .trim('First name must not include a leading or trailing space'),
  [InputNames.familyName]: yup
    .string()
    .strict()
    .required('Last name is required')
    .max(256, 'Last name cannot be longer than 256 characters')
    .trim('Last name must not include a leading or trailing space'),
  [InputNames.location]: yup
    .string()
    .matches(/(Canada|Los Angeles|Miami|New York City)/, {
      message: 'Location is required',
    })
    .required('Location is required'),
  [InputNames.avatarColor]: yup
    .string()
    .strict()
    .required('Avatar color is required'),
});

type EmployeeFormValues = yup.InferType<typeof validationSchema>;

const INITIAL_FORM_VALUES: EmployeeFormValues = {
  [InputNames.givenName]: '',
  [InputNames.familyName]: '',
  [InputNames.location]: '',
  [InputNames.avatarColor]: '',
};

export const EmployeeOnboarding = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { setUserSession, setPermissions, userSession } = useContext(
    UserContext
  )!;
  const { postEmployee } = useApi();

  if (userSession.uuid) {
    return <Navigate to={ROUTES.HOME.route} />;
  }

  const handleSubmit = (
    values: EmployeeFormValues,
    { setSubmitting }: FormikHelpers<EmployeeFormValues>
  ) => {
    postEmployee({
      data: {
        employee: {
          ...values,
          thirdPartyId: user?.sub,
          email: user?.email,
        },
      },
      handleSuccess: (
        { id, attributes }: IApiData<EmployeeAttributes>,
        token: string
      ) => {
        // Set the permissions first so that the Can component has the right permissions
        // before the customer gets set and triggers a change in authenticatedApp
        const decoded = jwtDecode<IJWTPayload>(token);
        if (decoded?.permissions?.length) {
          setPermissions(decoded.permissions);
        } else {
          // TODO: Handle this better
          throw new Error('No permissions found for user');
        }
        console.log({
          id,
        });
        setUserSession({
          ...userSession,
          uuid: id || '',
          givenName: attributes.givenName,
          familyName: attributes.familyName,
          avatarColor: attributes.avatarColor,
          employeeMetadata: {
            ...userSession.employeeMetadata,
            location: attributes.location,
          },
        });

        navigate(ROUTES.HOME.route);
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={{ ...INITIAL_FORM_VALUES }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ getFieldProps, errors, isSubmitting, values }) => (
        <Form>
          <section className="min-h-[12rem]">
            <div className="grid grid-cols-12 gap-x-6 mt-[3.25rem]">
              <div className="col-span-8 flex flex-col">
                <h1 className="font-headline font-extrabold text-5xl mb-2 text-black">
                  Lets get started!
                </h1>
                <h2 className="font-display text-xl text-gray">
                  We just need to collect some basic information first
                </h2>
              </div>
              <div className="col-start-11 col-span-1">
                <button
                  type="submit"
                  className="bg-blue-60 px-5 py-4 text-white text-xl font-display flex items-center"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CircularProgress
                      className="mr-4"
                      color="secondary"
                      size={20}
                      thickness={6}
                    />
                  )}
                  <p>Submit</p>
                </button>
              </div>
            </div>
          </section>
          <section className="bg-lightestBlue pb-40 pt-10 px-52">
            <span className="w-full flex justify-between mb-6">
              <div className="flex flex-col w-1/2 mr-6">
                <label
                  className="text-left font-mono text-xs text-black font-semibold mb-3"
                  htmlFor={'givenName'}
                >
                  YOUR FIRST NAME*
                </label>
                <input
                  {...getFieldProps(InputNames.givenName)}
                  className={`min-h-[5.5rem] border ${
                    errors.givenName ? 'border-red-40' : 'border-black'
                  } font-display text-xl px-6`}
                  disabled={isSubmitting}
                  id={'givenName'}
                />
                <p className="text-left text-sm text-red-40">
                  {errors.givenName}
                </p>
              </div>

              <div className="flex flex-col w-1/2">
                <label
                  className="text-left font-mono text-xs text-black font-semibold mb-3"
                  htmlFor={'familyName'}
                >
                  YOUR LAST NAME*
                </label>
                <input
                  {...getFieldProps(InputNames.familyName)}
                  className={`min-h-[5.5rem] border ${
                    errors.familyName ? 'border-red-40' : 'border-black'
                  } font-display text-xl px-6`}
                  disabled={isSubmitting}
                  id={'familyName'}
                />
                <p className="text-left text-sm text-red-40">
                  {errors.familyName}
                </p>
              </div>
            </span>

            <span className="w-full flex justify-between">
              <div className="flex flex-col w-1/2 mr-6">
                <label
                  className="text-left font-mono text-xs text-black font-semibold mb-3"
                  htmlFor="location"
                >
                  YOUR OFFICE LOCATION*
                </label>
                <select
                  {...getFieldProps(InputNames.location)}
                  className={`min-h-[5.5rem] border ${
                    errors.location ? 'border-red-40' : 'border-black'
                  } font-display text-xl px-6`}
                  disabled={isSubmitting}
                  id="location"
                >
                  <option selected> -- select an option -- </option>
                  {LOCATIONS.map(({ name, value }) => (
                    <option value={value}>{name}</option>
                  ))}
                </select>
                <p className="text-left text-sm text-red-40">
                  {errors.location}
                </p>
              </div>
              <div className="flex flex-col w-1/2">
                <label
                  className="text-left font-mono text-xs text-black font-semibold mb-3"
                  htmlFor="avatarColor"
                >
                  AVATAR COLOR*
                </label>
                <div className="flex">
                  <select
                    {...getFieldProps(InputNames.avatarColor)}
                    className={`w-4/5 min-h-[5.5rem] border rounded-none ${
                      errors.avatarColor ? 'border-red-40' : 'border-black'
                    } font-display text-xl px-6 mr-2`}
                    disabled={isSubmitting}
                    id="avatarColor"
                  >
                    <option selected value="">
                      {' '}
                      -- select an option --{' '}
                    </option>
                    {AVATAR_COLORS.map(({ name, value }) => (
                      <option value={value}>{name}</option>
                    ))}
                  </select>
                  <div
                    style={{
                      backgroundColor: values.avatarColor || 'gray',
                    }}
                    className="w-[5.5rem] h-[5.5rem] rounded-full drop-shadow-lg border border-black"
                  ></div>
                </div>
                <p className="text-left text-sm text-red-40">
                  {errors.avatarColor}
                </p>
              </div>
            </span>
          </section>
        </Form>
      )}
    </Formik>
  );
};
