// Libraries
import React from 'react';
// Components
import { LeftNavContext } from './left-nav-context';

interface ILeftNavContextProvider {
  children: React.ReactNode[] | React.ReactNode;
}

export const LeftNavContextProvider: React.FC<ILeftNavContextProvider> = ({
  children,
}) => {
  const [isLeftNavOpen, setIsLeftNavOpen] = React.useState<boolean>(false);

  return (
    <LeftNavContext.Provider value={{ isLeftNavOpen, setIsLeftNavOpen }}>
      {children}
    </LeftNavContext.Provider>
  );
};
