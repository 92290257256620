// Libraries
import React, { useState } from 'react';
// Components
import { ConfirmationContext } from './confirmation-context';
import { ConfirmModal } from './confirm-modal.component';
// Utils
import { ConfirmState } from './types';

interface IConfirmationContextProvider {
  children: React.ReactNode[] | React.ReactNode;
}

export const ConfirmationContextProvider: React.FC<IConfirmationContextProvider> = ({
  children,
}) => {
  const [confirmState, setConfirmState] = useState<ConfirmState>({
    description: '',
    isOpen: false,
    prompt: '',
  });

  const { isOpen, prompt, description } = confirmState;

  return (
    <ConfirmationContext.Provider value={{ confirmState, setConfirmState }}>
      <ConfirmModal prompt={prompt} description={description} isOpen={isOpen} />
      {children}
    </ConfirmationContext.Provider>
  );
};
