// Libraries
import React, { useState, useEffect, useRef } from 'react';
// Components
import { SearchBar } from 'features/ui';
import { RawMaterialsTable } from './raw-materials-table.component';
// Utils
import { useDebounce } from 'hooks';
import {
  IApiData,
  IApiResponse,
  InciAttributes,
  PriceAttributes,
  RawMaterialAttributes,
  VendorAttributes,
  useApi,
} from 'api';
import { Inci } from './types';
// Constants
import { API_TYPES } from 'api/api.constants';

export const RawMaterialSearch: React.FC = () => {
  const { searchRawMaterials } = useApi();
  const cancel = useRef(false);

  // Search and pagination
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState<boolean>(
    false
  );

  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState<number>(0);

  const debounceSearch = useDebounce(
    (searchTerm) => {
      searchForRawMaterials(searchTerm);
    },
    300,
    false,
    [page, rowsPerPage]
  );

  useEffect(() => {
    searchForRawMaterials(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!cancel.current) {
      debounceSearch(query);
    } else {
      cancel.current = false;
    }
    return () => {
      cancel.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const [rawMaterials, setRawMaterials] = useState<
    Array<
      IApiData<
        RawMaterialAttributes & {
          prices: Array<
            IApiData<PriceAttributes> & { supplier: IApiData<VendorAttributes> }
          >;
        }
      >
    >
  >([]);

  const [incis, setIncis] = useState<Array<Inci | IApiData<InciAttributes>>>(
    []
  );

  const searchForRawMaterials = async (searchTerm: string) => {
    if (searchTerm.trim().length) {
      setIsLoadingSearchResults(true);

      searchRawMaterials({
        query: searchTerm,
        urlParams: `&page=${page + 1}&per_page=${rowsPerPage}`,
        handleSuccess: (response: IApiResponse) => {
          setTotalNumberOfRows(response.meta.total);
          setRawMaterials(
            response.data?.map((rawMaterial) => ({
              ...rawMaterial,
              prices: response.included
                .filter(
                  (resource) =>
                    resource.type === API_TYPES.PRICE &&
                    resource.relationships?.rawMaterial?.data?.id ===
                      rawMaterial.id
                )
                .map((price) => ({
                  ...price,
                  supplier: response.included.find(
                    (resource) =>
                      resource.type === API_TYPES.SUPPLIER &&
                      resource.id === price.relationships?.supplier?.data?.id
                  ),
                })),
            })) as any
          );
          setIncis(response.included);
        },
        handleFinally: () => setIsLoadingSearchResults(false),
      });
    } else {
      setRawMaterials([]);
    }
  };

  const searchInput = useRef<undefined | HTMLInputElement>();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchInput.current = event.currentTarget;
    const { value } = event.currentTarget;
    setPage(0);
    setQuery(value);
    debounceSearch(value);
  };

  const tableWrapper = useRef<any>();
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const ARROW_DOWN = 'ArrowDown';

    if (event.key === ARROW_DOWN) {
      event.preventDefault();
      // Only focus on the table if we have raw materials
      rawMaterials?.length && tableWrapper.current.firstChild.focus();
    }
  };

  return (
    <>
      <SearchBar
        handleSearch={handleSearch}
        autoFocus={false}
        placeholder="Search for any raw material..."
        helperText="Search by name, raw material id, sample code or INCI name"
        handleKeyDown={handleKeyDown}
      />

      <div ref={tableWrapper} style={{ marginBottom: 15 }}>
        <RawMaterialsTable
          rawMaterials={rawMaterials as any}
          incis={incis as any}
          isLoadingSearchResults={isLoadingSearchResults}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalNumberOfRows={totalNumberOfRows}
        />
      </div>
    </>
  );
};
