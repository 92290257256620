// Libraries
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// Components
import { EditTeamMemberForm } from './edit-team-member-form.component';
// Utils
import { CustomerAttributes, IApiData } from 'api';

interface EditTeamMemberDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  teamMember: IApiData<CustomerAttributes>;
  setTeamMembers: React.Dispatch<
    React.SetStateAction<IApiData<CustomerAttributes>[]>
  >;
}

export const EditTeamMemberDialog: React.FC<EditTeamMemberDialogProps> = ({
  handleClose,
  isOpen,
  setTeamMembers,
  teamMember,
}) => {
  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={handleClose}>
      <DialogTitle>Team Members</DialogTitle>
      <IconButton
        aria-label="close"
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ padding: '8px 32px' }}>
        <EditTeamMemberForm
          handleClose={handleClose}
          setTeamMembers={setTeamMembers}
          teamMember={teamMember}
        />
      </DialogContent>
    </Dialog>
  );
};
