// Libraries
import React from 'react';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
// Utils
import { ITheme } from 'styles/mui/themeV2';

interface InfoBoxProps {
  content: string;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.blue.light,
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    infoIconContainer: {
      marginRight: theme.spacing(2),
      position: 'relative',
      top: theme.spacing(-0.5),
    },
  })
);

export const InfoBox: React.FC<InfoBoxProps> = ({ content }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.container}>
      <Grid item className={classes.infoIconContainer}>
        <InfoIcon color={'primary'} />
      </Grid>
      <Grid item>
        <Typography variant="body1">{content}</Typography>
      </Grid>
    </Grid>
  );
};
