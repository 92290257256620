// Libraries
import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/16/solid';
// Components
import { Chip } from 'design-system';

export const WorksheetInfo = () => {
  return (
    <div className="col-start-2 col-span-10 flex justify-between mt-[60px] ">
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <span className="text-royal-50">Formula #24A30-127009-SC004</span>
          <span className="text-grey-50">Formula Name</span>
        </div>
        <div className="text-lg">Pricing Worksheet V5.5</div>
        <Chip
          additionalClasses="w-fit-content"
          size="small"
          label="Status: Awaiting Sample Feedback"
          value=""
          bgColor="bg-peach-95"
          borderColor="border-peach-80"
          textColor="text-peach-40"
        />
      </div>
      <div className="flex gap-9 border border-grey-95 p-4">
        <div className="flex flex-col gap-1">
          <span className="font-mono text-sm uppercase text-grey-50">
            Location
          </span>
          <span>Miami</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-mono text-sm uppercase text-grey-50">
            Target Cogs
          </span>
          <span>$6.01 - $6.50</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-mono text-sm uppercase text-grey-50">
            Primary Component
          </span>
          <span>Lip Balm/Deodorant</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-mono text-sm uppercase text-grey-50">
            Master Box
          </span>
          <span>Greater than 6</span>
        </div>
        <div className="cursor-pointer">
          <PencilSquareIcon className="h-4 w-4 text-royal-50" />
        </div>
      </div>
    </div>
  );
};
