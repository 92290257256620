// Libraries
import React from 'react';
// Components
import { IngredientListTrackerFileCard } from './ingredient-list-tracker-file-card.component';
import { IngredientListTrackerFileCardFeedback } from './ingredient-list-tracker-file-card-feedback.component';
import { IApiData, ProjectAttributes } from 'api';
// Constants
import { FILE_CATEGORIES } from '../../constants';
import { IngredientListPendingActions } from './customer-actions/ingredient-list-feedback/ingredient-list-pending-actions.component';
import { FeedbackStatus } from '../../types';

interface IngredientListTrackerFileProps {
  files: any[];
  isDeleting: boolean;
  handleDelete: (e: any) => void;
  handleDownload: (e: any) => void;
  handleViewFeedback: (file: any) => void;
  handleModalClick: (file: any) => void;
  setFiles: (files: any) => void;
  setProject: React.Dispatch<React.SetStateAction<IApiData<ProjectAttributes>>>;
}

export const IngredientListTrackerFile: React.FC<IngredientListTrackerFileProps> = ({
  files,
  isDeleting,
  handleDelete,
  handleDownload,
  handleViewFeedback,
  handleModalClick,
  setFiles,
  setProject,
}) => {
  const sortedILFiles = files
    // sort from newest to oldest
    .sort((a: any, b: any) => {
      return (
        new Date(b.attributes.createdAt).getTime() -
        new Date(a.attributes.createdAt).getTime()
      );
    })
    // filter for only IL files
    .filter(
      (file: any) =>
        file.attributes.category === FILE_CATEGORIES.approveIngredientList
    );

  const latestFile = sortedILFiles[0];
  const olderFiles = sortedILFiles.slice(1);

  const hasFeedback = (file: any) => {
    return (
      file?.parsedIncluded?.feedback?.attributes?.status ===
      FeedbackStatus.rejected
    );
  };

  const hasApprovedIL = () => {
    return sortedILFiles.some((file: any) => {
      return (
        file?.parsedIncluded?.feedback?.attributes?.status ===
        FeedbackStatus.approved
      );
    });
  };

  const hasILFiles = sortedILFiles.length > 0;

  const renderFiles = () => {
    if (hasApprovedIL() || !hasILFiles) return null;

    return (
      <div>
        <div className="text-xs font-inter text-left text-gray mb-2">
          Most recent version:
        </div>
        <div className="sm:flex">
          <IngredientListTrackerFileCard
            file={latestFile}
            isDeleting={isDeleting}
            handleDelete={handleDelete}
            handleDownload={handleDownload}
          />
          {hasFeedback(latestFile) ? (
            <IngredientListTrackerFileCardFeedback
              file={latestFile}
              handleViewFeedback={handleViewFeedback}
            />
          ) : (
            <IngredientListPendingActions
              file={latestFile}
              files={files}
              handleModalClick={handleModalClick}
              setFiles={setFiles}
              setProject={setProject}
            />
          )}
        </div>
        {olderFiles.length > 0 && (
          <>
            <div className="text-xs font-inter text-left text-gray mb-2">
              Past Versions:
            </div>
            {olderFiles.map((file: any) => {
              return (
                <div className="sm:flex" key={file.id}>
                  <IngredientListTrackerFileCard
                    file={file}
                    isDeleting={isDeleting}
                    handleDelete={handleDelete}
                    handleDownload={handleDownload}
                    cardColor="bg-lighterGray"
                  />
                  {hasFeedback(file) && (
                    <IngredientListTrackerFileCardFeedback
                      file={file}
                      handleViewFeedback={handleViewFeedback}
                    />
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };

  return renderFiles();
};
