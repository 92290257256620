// Libraries
import * as Yup from 'yup';
// Utils
import { ITableColumnCollection } from 'features/types';

export const INITIAL_RAW_MATERIAL_FORM_VALUES = {
  id: '',
  name: '',
  rmId: '',
};

export const SEARCH_TABLE_HEADERS = {
  rawMaterialHeaders: [
    {
      text: 'Name',
      style: 'pl-5',
    },
    {
      text: 'RM/Sample Code',
      style: 'pl-4',
    },
    {
      text: 'Location',
      style: 'pl-4',
    },
    {
      text: 'Cost',
      style: 'pl-4',
    },
  ],
  pricingHeaders: [
    {
      text: 'Cost / oz.',
      style: 'px-5',
    },
    {
      text: 'Received Date',
      style: 'pl-7',
    },
    {
      text: 'Supplier',
      style: 'pl-11',
    },
    {
      text: 'Manufacturer',
      style: 'pl-11',
    },
  ],
};

export const UNPHASED_INGREDIENTS_PHASE_ID = 'unphasedIngredientsPhaseId';

export const DEFAULT_NUM_SEARCH_RESULTS = 5;
export const INCREMENT_NUM_SEARCH_RESULTS = 5;

export const WEIGHT_PERCENT_PRECISION = 4;
export const WEIGHT_GRAMS_PRECISION = 4;

export const INGREDIENTS_TABLE_COLUMNS: ITableColumnCollection = {
  dragIndicator: {
    align: 'left',
    columnWidth: '5%',
    headerText: '',
    padding: '1rem 0',
  },
  expandCollapseButton: {
    align: 'left',
    columnWidth: '1%',
    headerText: '',
    padding: '1rem 0',
  },
  keyIngredientButton: {
    align: 'left',
    columnWidth: '1%',
    headerText: '',
    padding: '1rem 0',
  },
  markOtcIngredientButton: {
    align: 'left',
    columnWidth: '1%',
    headerText: '',
    padding: '1rem 0',
  },
  lineNumber: {
    align: 'left',
    columnWidth: '1%',
    headerText: 'Line',
    padding: '16px 10px',
  },
  rmId: {
    align: 'left',
    columnWidth: '10%',
    headerText: 'Code',
    padding: '16px 10px',
  },
  tradeName: {
    align: 'left',
    columnWidth: '41%',
    headerText: 'Trade Name',
    padding: '16px 16px 16px 10px',
  },
  cost: {
    align: 'right',
    columnWidth: '16%',
    headerText: 'Cost (% of Tot.)',
  },
  percentage: {
    align: 'right',
    columnWidth: '12%',
    headerText: 'Percentage',
  },
  weight: {
    align: 'right',
    columnWidth: '10%',
    headerText: 'Weight (g)',
  },
  deleteButton: {
    align: 'left',
    columnWidth: '5%',
    headerText: '',
  },
};

export const LOCATION_FILTER_OPTIONS = [
  {
    name: 'Miami',
    value: 'Miami',
  },
  {
    name: 'New York',
    value: 'New York',
  },
  {
    name: 'Los Angeles',
    value: 'Los Angeles',
  },
];

export const UNITS = {
  LB: 'lb',
  OZ: 'oz',
};

export const EDIT_OR_DUPLICATE_FORMULA_FIELDS = {
  NAME: {
    label: 'Formula Name',
    name: 'name',
  },
  ID: {
    label: 'Formula ID',
    name: 'externalId',
  },
  PROJECT: {
    label: 'Project',
  },
};

export const DIALOG_MODES = {
  archive: 'archive',
  duplicate: 'duplicate',
  edit: 'edit',
  hidden: 'hidden',
  marketingInfo: 'marketingInfo',
  note: 'note',
  report: 'report',
  status: 'status',
} as const;

export const US_API_DRUG_PURPOSES = 'usApiDrugPurposes';

const getNumberError = (field: string) => `${field} must be zero or greater.`;

export const SPECIFICATIONS_VALIDATION_SCHEMA = Yup.object({
  lotNumber: Yup.string()
    .max(256, 'Can not exceed 256 characters')
    .required('Lot # is required.'),
  quantity: Yup.number()
    .positive('Batch Qty must be positive.')
    .required('Batch Qty is required.'),
  phRangeMin: Yup.number().min(0, getNumberError('pH')),
  phRangeMax: Yup.number().min(0, getNumberError('pH')),
  phOvernight: Yup.number().min(0, getNumberError('pH')),
  phInitial: Yup.number().min(0, getNumberError('pH')),
  solidsPercentageRangeMin: Yup.number().min(0, getNumberError('Percentage')),
  solidsPercentageRangeMax: Yup.number().min(0, getNumberError('Percentage')),
  solidsPercentageOvernight: Yup.number().min(0, getNumberError('Percentage')),
  solidsPercentageInitial: Yup.number().min(0, getNumberError('Percentage')),
  specificGravityRangeMin: Yup.number().min(
    0,
    getNumberError('Specific gravity')
  ),
  specificGravityRangeMax: Yup.number().min(
    0,
    getNumberError('Specific gravity')
  ),
  specificGravityOvernight: Yup.number().min(
    0,
    getNumberError('Specific gravity')
  ),
  specificGravityInitial: Yup.number().min(
    0,
    getNumberError('Specific gravity')
  ),
  viscosityPercentageOvernight: Yup.number().min(
    0,
    getNumberError('Percentage')
  ),
  viscosityReadingMinRange: Yup.number().min(0, getNumberError('Reading')),
  viscosityReadingMaxRange: Yup.number().min(0, getNumberError('Reading')),
  viscositySpeedRange: Yup.number().min(0, getNumberError('Speed')),
  viscosityPercentageInitial: Yup.number().min(0, getNumberError('Percentage')),
  viscosityReadingOvernight: Yup.number().min(0, getNumberError('Reading')),
  viscosityReadingInitial: Yup.number().min(0, getNumberError('Reading')),
  viscositySpeedOvernight: Yup.number().min(0, getNumberError('Speed')),
  viscositySpeedInitial: Yup.number().min(0, getNumberError('Speed')),
});

export const APPEARANCE_METHODS = ['Visual'];

export const COLOR_METHODS = ['Visual'];

export const ODOR_METHODS = ['Organoleptic'];

export const PH_METHODS = ['pH Meter'];

export const VISCOSITY_METHODS = ['Viscometer'];

export const SPECIFIC_GRAVITY_METHODS = ['Pycnometer'];

export const SOLIDS_PERCENTAGE_METHODS = ['Pycnometer'];
