// Libraries
import React from 'react';
// Assets
import verifyBackground from '../assets/verifyBackground.jpeg';

export const VerifyEmail: React.FC = (): JSX.Element => {
  // using calc function to properly get the height minus the navbar
  // and the footer
  return (
    <div
      className="flex h-screen lg:h-[calc(100vh-10rem)] bg-cover"
      style={{
        backgroundImage: `url(${verifyBackground})`,
      }}
    >
      <section className="flex self-center lg:self-auto h-1/2 lg:h-full items-center bg-white sm:w-2/5 w-5/6 mx-auto justify-normal">
        <div className="mx-14">
          <h1 className="sm:text-5xl text-4xl text-darkGray font-headline">
            Check your inbox
          </h1>
          <p className="sm:text-xl text-sm text-gray mt-6 font-display">
            Follow instructions in the verification email to continue.
          </p>
          <p className="sm:text-xl text-sm text-gray mt-6 font-display">
            If you have not received an email from <u>primematterlabs.com</u>,
            check your spam or junk mail folder.
          </p>
          <p className="sm:text-xl text-sm text-gray mt-6 font-display">
            Please contact{' '}
            <a
              href={`mailto:help@primematterlabs.com`}
              className="underline text-blue-60"
            >
              help@primematterlabs.com
            </a>{' '}
            for additional assistance.
          </p>
        </div>
      </section>
    </div>
  );
};
