// Libraries
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  createStyles,
  TableCell,
  Typography,
  TableBody,
  Tooltip,
  CircularProgress,
  TablePagination,
} from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';
// Components
import { FormSection } from 'features/ui';
// Utils
import { ITheme } from 'styles/mui/themeV2';
// Constants
import {
  useApi,
  IApiData,
  RawMaterialAttributes,
  RawMaterialInciAttributes,
} from 'api';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const RAW_MATERIALS = 'Raw Materials';
const DEFAULT_ROWS_PER_PAGE = 5;
const DEFAULT_PAGE = 0;

const TABLE_HEADERS = ['RM Code', 'Sample Code', 'Trade Name', 'Location', '%'];

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    headerRow: {
      top: '65px',
      zIndex: 100,
    },
    pagination: {
      position: 'sticky',
      bottom: 0,
      zIndex: 100,
      backgroundColor: theme.palette.gray.light,
    },
    tableContainer: {
      maxHeight: 400,
    },
    table: {
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.gray.light}`,
      borderRadius: '0.25rem',
    },
    tableCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '0',
    },
    percentTableCell: {
      textAlign: 'left',
      maxWidth: '0',
    },
    tableHeader: {
      color: theme.palette.gray.lighter,
      '&:first-child': {
        borderTopLeftRadius: '0.25rem',
      },
      '&:last-child': {
        borderTopRightRadius: '0.25rem',
      },
      backgroundColor: theme.palette.secondary.dark,
    },
  })
);

export const RawMaterialsSection: React.FC = () => {
  const classes = useStyles();
  const { getRawMaterials } = useApi();

  // Use uuid to check if we are on the new page or the edit page
  const { uuid }: { uuid?: string } = useParams();
  const isEditPage = Boolean(uuid);

  // Raw Material State
  const [rawMaterials, setRawMaterials] = useState<
    Array<
      IApiData<
        RawMaterialAttributes,
        { rawMaterialIncis: Maybe<Array<IApiData<RawMaterialInciAttributes>>> }
      >
    >
  >([]);
  const [isLoadingRawMaterials, setIsLoadingRawMaterials] = useState<boolean>(
    false
  );

  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [totalNumberRawMaterials, setTotalNumberRawMaterials] = useState<
    number
  >(0);

  const handleRowsPerPageChange = (e: any) => setRowsPerPage(e.target.value);

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => setPage(page);

  useEffect(() => {
    if (!uuid) return;

    setIsLoadingRawMaterials(true);
    getRawMaterials({
      urlParams: `?inci_id=${uuid}&page=${page + 1}&perPage=${rowsPerPage}`,
      handleSuccess: (resp) => {
        setTotalNumberRawMaterials(resp.meta.total);
        setRawMaterials(resp.data);
      },
      handleFinally: () => setIsLoadingRawMaterials(false),
    });
  }, [page, rowsPerPage, getRawMaterials, uuid]);

  const rmiTotal = (
    rmis: Maybe<Array<IApiData<RawMaterialInciAttributes>>>
  ) => {
    const foundRmi = rmis?.find(
      (rmi: IApiData<RawMaterialInciAttributes>) =>
        rmi.attributes.inciUuid === uuid
    );
    return parseFloat(foundRmi ? (foundRmi.attributes.amount as string) : '0');
  };

  return (
    <FormSection
      sectionHeader={RAW_MATERIALS}
      isRevealed={isEditPage}
      helperText={isEditPage ? undefined : 'N/A'}
      setIsRevealed={() => null}
    >
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader>
          <TableHead className={classes.headerRow}>
            <TableRow className={classes.headerRow}>
              {TABLE_HEADERS.map((header, index) => {
                return (
                  <TableCell
                    className={classes.tableHeader}
                    key={`${header}-${index}`}
                    align="left"
                  >
                    <Typography variant="h4">{header}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingRawMaterials ? (
              <TableRow style={{ height: 300 }}>
                <TableCell colSpan={4} align="center">
                  <CircularProgress role="alert" />
                </TableCell>
              </TableRow>
            ) : rawMaterials.length ? (
              rawMaterials.map(({ id, attributes, parsedIncluded }) => (
                <TableRow key={id}>
                  <Tooltip title={attributes.rmId || ''}>
                    <TableCell className={classes.tableCell}>
                      {attributes.rmId || 'N/A'}
                    </TableCell>
                  </Tooltip>
                  <Tooltip
                    title={
                      attributes?.sampleCode
                        ? `SAMP-${attributes.sampleCode}`
                        : ''
                    }
                  >
                    <TableCell className={classes.tableCell}>
                      {attributes?.sampleCode
                        ? `SAMP-${attributes.sampleCode}`
                        : 'N/A'}
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={attributes.name}>
                    <TableCell className={classes.tableCell}>
                      <Link
                        className="underline"
                        to={ROUTES.SHOW_RAW_MATERIAL.route.replace(
                          UUID_SHOW_ROUTE_STRING,
                          id
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {attributes.name}
                      </Link>
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={attributes.location}>
                    <TableCell className={classes.tableCell}>
                      {attributes.location}
                    </TableCell>
                  </Tooltip>
                  <TableCell className={classes.percentTableCell}>
                    {rmiTotal(parsedIncluded?.rawMaterialIncis)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body1">
                    INCI is not assigned to any Raw Material
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          className={classes.pagination}
          component="div"
          count={totalNumberRawMaterials}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
    </FormSection>
  );
};
