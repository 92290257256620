// Libraries
import React from 'react';
// Components
import { InterstitialLayout } from '../layout';
// Assets
import interstitialImageOne from '../../assets/interstitialImageOne.jpg';
import interstitialImageOneMobile from '../../assets/interstitialImageOneMobile.png';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const BUTTON_TEXT = 'Begin';

const STEPS = [
  {
    text: 'Project basics',
    isComplete: false,
  },
  {
    text: 'Market strategy',
    isComplete: false,
  },
  {
    text: 'Project requirements',
    isComplete: false,
  },
];

const IMAGE = {
  src: interstitialImageOne,
  alt: 'various creams and gels',
};

const MOBILE_IMAGE = {
  src: interstitialImageOneMobile,
  alt: 'various creams and gels',
};

const TITLE = 'Tell us about your project';

export const ProjectBasicsInterstitial: React.FC = () => {
  return (
    <div>
      <InterstitialLayout
        buttonText={BUTTON_TEXT}
        eventCategory={
          AnalyticConstants.EVENT_CATEGORIES.CBDividerProjectBasics
        }
        image={IMAGE}
        mobileImage={MOBILE_IMAGE}
        steps={STEPS}
        title={TITLE}
      />
    </div>
  );
};
