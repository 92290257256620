import React from 'react';
import { Checkbox } from 'design-system/checkbox.component';

interface SectionHeaderProps {
  title: string;
  checkboxLabel?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  checkboxLabel,
}) => {
  return (
    <div
      className={`flex justify-between items-center rounded-t mt-3 px-3 py-2 bg-grey-20 text-xs text-grey-90`}
    >
      <span className="font-mono uppercase">{title}</span>
      {checkboxLabel && <Checkbox primaryLabel={checkboxLabel} />}
    </div>
  );
};
