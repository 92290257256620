// Libraries
import React from 'react';
import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// Utils
import { ITheme } from 'styles/mui/themeV2';

interface AccordionButtonProps {
  expanded?: boolean;
  [x: string]: any;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    default: {
      height: theme.spacing(8),
      width: theme.spacing(8),
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.main,
      },
    },
  })
);

// TODO: Look into properly extending from IconButton interface
export const AccordionButton: React.FC<AccordionButtonProps> = ({
  expanded,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.default} {...rest}>
      {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  );
};
