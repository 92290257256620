// Libraries
import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// Utils
import { THEME_PALETTE } from 'styles/mui/themeV2';
import { WarningOrError } from './types';
// Constants
import { FORMULA_ISSUE_SUMMARY } from './constants';
const ZERO_WARNINGS = 0;

interface FormulaWarningsProps {
  warnings: WarningOrError[];
}

export const FormulaWarnings: React.FC<FormulaWarningsProps> = ({
  warnings,
}) => {
  if (warnings.length <= ZERO_WARNINGS) return null;

  return (
    <Alert severity="warning" className="mt-2">
      <Typography variant="body2" style={{ color: THEME_PALETTE.gray.dark }}>
        {FORMULA_ISSUE_SUMMARY}
      </Typography>
      <List
        dense
        style={{
          listStyleType: 'disc',
          marginLeft: '1rem',
          color: THEME_PALETTE.gray.dark,
        }}
      >
        {warnings.map((warning, index) => (
          <ListItem key={index} style={{ display: 'list-item' }} disableGutters>
            <ListItemText
              primary={warning.issue}
              secondary={warning.actionItem}
              primaryTypographyProps={{ variant: 'body2' }}
              secondaryTypographyProps={{ component: 'span' }}
            />
          </ListItem>
        ))}
      </List>
    </Alert>
  );
};
