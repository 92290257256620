// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Checkbox, TextArea } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { BRIEF_FORM_KEYS } from '../brief.constants';

const STATEMENTS = [
  {
    label: 'Vegan ingredients',
    formikKey: BRIEF_FORM_KEYS.VEGAN_INGREDIENTS_STATEMENT,
  },
  {
    label: 'Gluten free ingredients',
    formikKey: BRIEF_FORM_KEYS.GLUTEN_FREE_STATEMENT,
  },
  {
    label: 'No animal derived ingredients',
    formikKey: BRIEF_FORM_KEYS.NO_ANIMAL_DERIVED_INGREDIENTS_STATEMENT,
  },
  {
    label: 'No animal tested ingredients',
    formikKey: BRIEF_FORM_KEYS.NO_ANIMAL_TESTED_INGREDIENTS_STATEMENT,
  },
  {
    label: 'IFRA compliance',
    formikKey: BRIEF_FORM_KEYS.IFRA_COMPLIANCE_STATEMENT,
  },
];

export const StatementsNeeded: React.FC = () => {
  const { getFieldProps, values } = useFormikContext<InternalBriefFormValues>();

  const renderStatements = () => {
    return STATEMENTS.map((option) => (
      <div key={option.formikKey} className="mb-3">
        <Checkbox
          {...getFieldProps(option.formikKey)}
          primaryLabel={option.label}
          checked={values[option.formikKey]}
        />
      </div>
    ));
  };

  return (
    <div id="statements-needed">
      <p className="font-inter text-lg font-normal text-grey-20 mb-6">
        Statements Needed
      </p>
      <div>{renderStatements()}</div>
      <TextArea
        {...getFieldProps(BRIEF_FORM_KEYS.STATEMENTS_NOTE)}
        placeholder="Notes on other"
      />
    </div>
  );
};
