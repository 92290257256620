// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Checkbox, TextArea, Typography } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { CheckBoxOption } from './types';
import { BRIEF_FORM_KEYS } from '../brief.constants';

const R_AND_D_DOCUMENTS = [
  {
    label: 'Preliminary IL per submission (Standard)',
    description: '',
    formikKey: BRIEF_FORM_KEYS.PRELIMINARY_IL_PER_SUBMISSION_STANDARD,
    disabled: true,
  },
  {
    label: 'Estimated Quote (Standard)',
    description: '',
    formikKey: BRIEF_FORM_KEYS.ESTIMATED_QUOTE_STANDARD,
    disabled: true,
  },
  {
    label: 'COA',
    description: '',
    formikKey: BRIEF_FORM_KEYS.COA,
  },
];

export const RAndDDocuments: React.FC = () => {
  const { getFieldProps, values } = useFormikContext<InternalBriefFormValues>();

  const renderCheckboxes = () => {
    return R_AND_D_DOCUMENTS.map((option: CheckBoxOption) => (
      <Checkbox
        key={option.label}
        direction="col"
        primaryLabel={option.label}
        description={option.description}
        {...getFieldProps(option.formikKey)}
        checked={values[option.formikKey as keyof InternalBriefFormValues]}
        disabled={option.disabled}
      />
    ));
  };

  return (
    <div className="flex flex-col gap-6">
      <Typography id="rd" variant="h3" font="inter">
        R&D Documents
      </Typography>
      <div className="flex flex-col gap-3">
        <Typography variant="h4" font="inter" weight="semibold">
          What R&D Documents are needed?
        </Typography>

        {renderCheckboxes()}

        <TextArea
          placeholder="Other"
          {...getFieldProps(BRIEF_FORM_KEYS.OTHER_R_AND_D_DOCUMENTS)}
        />
      </div>
    </div>
  );
};
