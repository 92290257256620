// Libraries
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
// Utils
import { usePricingQuoteParams } from './hooks';
import { useSnackbar, useAnalytics } from 'hooks';
import { PricingQuoteFormikValues } from './types';
import { useApi, IApiData, PricingQuoteAttributes } from 'api';
// Constants
import {
  INITIAL_LABOR_HEAD_COUNT_VALUES,
  INITIAL_RUN_RATE,
  INITIAL_EFFICIENCY_PERCENTAGE,
  INITIAL_MARGIN_TARGET_PERCENTAGE,
} from './constants';
import { AnalyticsConstants } from 'features/analytics';

interface IPricingQuoteFormikWrapper {
  children: any;
}

export const PricingQuoteFormikWrapper: React.FC<IPricingQuoteFormikWrapper> = ({
  children,
}) => {
  const { showSnackbar } = useSnackbar();
  const {
    getFormulaPriceQuote,
    postFormulaPriceQuote,
    patchFormulaPriceQuote,
  } = useApi();
  const [pricingQuote, setPricingQuote] = useState<
    Maybe<IApiData<PricingQuoteAttributes>>
  >(undefined);

  const { pricingQuoteSearchParams } = usePricingQuoteParams();

  const analytics = useAnalytics();

  useEffect(() => {
    if (!pricingQuoteSearchParams.formulaId) return;

    getFormulaPriceQuote({
      urlParams: `${pricingQuoteSearchParams.formulaId}/pricing_quotes`,
      handleSuccess: setPricingQuote,
    });
  }, [getFormulaPriceQuote, pricingQuoteSearchParams.formulaId]);

  /**
   * We need to memoize the initial values in order to prevent our form
   * from resetting when props from above (e.g. context) triggers a
   * re-render of this component, this will prevent non-persisted pricing quotes
   * from zeroing out.
   * */
  const initialValues: PricingQuoteFormikValues = useMemo(
    () => ({
      cappingHeadCount:
        pricingQuote?.attributes.cappingHeadCount ||
        INITIAL_LABOR_HEAD_COUNT_VALUES.cappingHeadCount.value,
      compoundingHeadCount:
        pricingQuote?.attributes.compoundingHeadCount ||
        INITIAL_LABOR_HEAD_COUNT_VALUES.compoundingHeadCount.value,
      efficiencyPercentage: pricingQuote
        ? parseFloat(pricingQuote?.attributes.efficiencyPercentage)
        : (INITIAL_EFFICIENCY_PERCENTAGE.value as number),
      marginTargetPercentage: pricingQuote
        ? parseFloat(pricingQuote?.attributes.marginTargetPercentage)
        : (INITIAL_MARGIN_TARGET_PERCENTAGE.value as number),
      operatorHeadCount:
        pricingQuote?.attributes.operatorHeadCount ||
        INITIAL_LABOR_HEAD_COUNT_VALUES.operatorHeadCount.value,
      otherHeadCount:
        pricingQuote?.attributes.otherHeadCount ||
        INITIAL_LABOR_HEAD_COUNT_VALUES.otherHeadCount.value,
      packingOrPalletizerHeadCount:
        pricingQuote?.attributes.packingOrPalletizerHeadCount ||
        INITIAL_LABOR_HEAD_COUNT_VALUES.packingOrPalletizerHeadCount.value,
      preworkHeadCount:
        pricingQuote?.attributes.preworkHeadCount ||
        INITIAL_LABOR_HEAD_COUNT_VALUES.preworkHeadCount.value,
      runRate:
        pricingQuote?.attributes.runRate || (INITIAL_RUN_RATE.value as number),
      unitCartonHeadCount:
        pricingQuote?.attributes.unitCartonHeadCount ||
        INITIAL_LABOR_HEAD_COUNT_VALUES.unitCartonHeadCount.value,
    }),
    [pricingQuote]
  );

  const sendGATracking = (event: React.FormEvent<HTMLFormElement>) => {
    analytics?.trackEvent({
      eventCategory: AnalyticsConstants.EVENT_CATEGORIES.pricingCalculator,
      eventAction: AnalyticsConstants.EVENT_ACTIONS.inputChange,
      eventLabel: AnalyticsConstants.EVENT_LABEL.input(
        (event.target as any).name
      ),
    });
  };

  const handleSubmit = (
    values: PricingQuoteFormikValues,
    { setSubmitting }: FormikHelpers<PricingQuoteFormikValues>
  ) => {
    if (!pricingQuoteSearchParams.formulaId) return;

    const { formulaId } = pricingQuoteSearchParams;

    const isUpdate = Boolean(pricingQuote);

    const request = isUpdate ? patchFormulaPriceQuote : postFormulaPriceQuote;

    request({
      urlParams: `${formulaId}/pricing_quotes`,
      data: {
        pricingQuote: values,
      },
      handleSuccess: (data) => {
        setPricingQuote(data);
        showSnackbar(
          `Pricing quote successfully ${isUpdate ? 'updated' : 'saved'}.`,
          'success'
        );
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form onChange={(e) => sendGATracking(e)}>
        {children({ pricingQuote })}
      </Form>
    </Formik>
  );
};
