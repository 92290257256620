// Libraries
import React from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
  createStyles,
  Typography,
} from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
    radioGroupLabel: {
      marginBottom: theme.spacing(1),
      color: theme.palette.gray.dark,
      '& > h4': {
        fontWeight: 600,
      },
    },
    yesLabel: {
      marginRight: theme.spacing(8),
    },
  })
);

interface RadioButtonsWithExplanationProps {
  explanationInputName: string;
  explanationValue: Maybe<string>;
  label: string;
  inputName: string;
  inputValue: Maybe<boolean>;
  setFieldValue: (name: string, value: Maybe<string | boolean>) => void;
}

export const RadioButtonsWithExplanation: React.FC<RadioButtonsWithExplanationProps> = ({
  explanationInputName,
  explanationValue,
  label,
  inputName,
  inputValue,
  setFieldValue,
}) => {
  const classes = useStyles();

  const isInputValueSelected = inputValue === true || inputValue === false;
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // The DOM casts all radio button values to a string
    // We convert it back to a boolean here.
    // Options are 'false' or 'true'
    const booleanValue: boolean = event.target.value === 'true';
    // If the user wants to undo their click (not the standard pattern for radio buttons)
    // we compare the value from props against the change event value
    const value = booleanValue === inputValue ? undefined : booleanValue;

    setFieldValue(inputName, value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography variant="h4">{label}</Typography>
      </FormLabel>
      <RadioGroup name={inputName} className={classes.radioGroup}>
        <FormControlLabel
          checked={inputValue === true} // can also be null/undefined
          className={classes.yesLabel}
          control={<Radio onChange={handleRadioChange} />}
          label="Yes"
          value={true} // will be cast to a string by DOM
        />
        <FormControlLabel
          checked={inputValue === false} // can also be null/undefined
          control={<Radio onChange={handleRadioChange} />}
          label="No"
          value={false} // will be cast to a string by DOM
        />
      </RadioGroup>
      {(explanationValue || isInputValueSelected) && (
        <TextField
          id={`explanation-${inputName}`}
          label="Details"
          multiline
          name={explanationInputName}
          value={explanationValue}
          onChange={(e) => setFieldValue(explanationInputName, e.target.value)}
          variant="outlined"
        />
      )}
    </FormControl>
  );
};
