//Libraries
import React from 'react';
import { IconButton, TextField } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useFormikContext } from 'formik';
// Utils
import { ThirdPartyTestFormValues } from './types';
// Constants
import { NAVBAR_HEIGHT } from 'features/navigation';

export const AdditionalTestsSection: React.FC<{
  isLocked?: boolean;
}> = ({ isLocked }) => {
  const {
    values,
    getFieldProps,
    setFieldValue,
    errors,
    touched,
  } = useFormikContext<ThirdPartyTestFormValues>();
  const { additionalTests } = values;

  const addAdditionalTest = () => {
    setFieldValue('additionalTests', [
      ...additionalTests,
      {
        name: '',
        cost: 0,
      },
    ]);
  };

  return (
    <section className="relative">
      <div
        className="sticky z-50 h-9 flex border border-grey-90 rounded-sm bg-lightestGray px-6"
        style={{ top: NAVBAR_HEIGHT }}
      >
        <span className="flex w-full items-center justify-start">
          <h6 className="font-mono text-[12px] font-medium">
            ADDITIONAL TESTS
          </h6>
        </span>
      </div>
      {additionalTests.map((test, index) => (
        <div
          key={index}
          className="flex border border-grey-90 border-t-0 min-h-[80px]"
        >
          <span className="flex w-6/12 items-center justify-start border-r border-grey-90 p-6">
            {isLocked ? (
              <h6 className="font-inter text-[16px] text-lightGray">
                {test.name}
              </h6>
            ) : (
              <TextField
                {...getFieldProps(`additionalTests[${index}].name`)}
                error={Boolean(
                  touched.additionalTests?.[index]?.name &&
                    (errors.additionalTests?.[index] as any)?.name
                )}
                helperText={
                  touched.additionalTests?.[index]?.name &&
                  (errors.additionalTests?.[index] as any)?.name
                    ? (errors.additionalTests?.[index] as any)?.name
                    : undefined
                }
                type="text"
                variant="outlined"
                label="Test Name"
                className="border border-grey-90 rounded-sm w-full"
              />
            )}
          </span>
          <span className="flex w-6/12 items-center justify-start p-6">
            {isLocked ? (
              <h6 className="font-inter text-[16px] text-lightGray">
                ${test.cost.toLocaleString('en-US')}
              </h6>
            ) : (
              <TextField
                {...getFieldProps(`additionalTests[${index}].cost`)}
                error={Boolean(
                  touched.additionalTests?.[index]?.cost &&
                    (errors.additionalTests?.[index] as any)?.cost
                )}
                helperText={
                  touched.additionalTests?.[index]?.cost &&
                  (errors.additionalTests?.[index] as any)?.cost
                    ? (errors.additionalTests?.[index] as any)?.cost
                    : undefined
                }
                type="number"
                variant="outlined"
                label="Cost"
                className="border border-grey-90 rounded-sm w-full"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  startAdornment: (
                    <span className="text-[16px] mr-[1px]">$</span>
                  ),
                }}
              />
            )}
          </span>
        </div>
      ))}
      {!isLocked && (
        <div className="h-9 flex border border-grey-90 border-t-0 border-b-0 p-6">
          <span className="flex w-full items-center justify-start">
            <IconButton
              disableRipple
              color="primary"
              onClick={addAdditionalTest}
            >
              <AddCircleIcon />
            </IconButton>
            <p className="font-inter text-[14px]">
              Add another non-standard test
            </p>
          </span>
        </div>
      )}
    </section>
  );
};
