// Libraries
import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/16/solid';
// Components
import { Typography } from './typography.component';

interface BaseInputProps {
  backgroundColor?: string;
  additionalClasses?: string;
  height?: string;
  helperText?: string;
  label?: string;
  leadingIcon?: React.ReactNode;
  leadingText?: string;
  required?: boolean;
  trailingIcon?: React.ReactNode;
  trailingText?: string;
  width?: string;
  [key: string]: any;
}

export const BaseInput: React.FC<BaseInputProps> = ({
  backgroundColor = 'bg-white',
  additionalClasses = '',
  height = 'h-24',
  helperText,
  label,
  leadingIcon,
  leadingText,
  required,
  trailingIcon,
  trailingText,
  width = 'w-full',
  ...rest
}) => {
  const { disabled, error, type } = rest;

  const bgColor = disabled ? 'bg-grey-95' : backgroundColor;
  const borderColor = error ? 'border-red-50' : 'border-grey-90';
  const baseClasses = `${bgColor} ${borderColor} h-1 py-5 focus:outline-none focus:border-royal-50`;

  const leadingContentClasses = `${baseClasses} flex items-center pl-3 rounded-l-[4px] text-grey-50`;
  const trailingContentClasses = `${baseClasses} text-black flex items-center pr-3 rounded-r-[4px] whitespace-nowrap`;

  const renderBorderRadius = () => {
    if (leadingText || leadingIcon) return 'rounded-r-[4px]';
    if (trailingText || trailingIcon || error) return 'rounded-l-[4px]';
    return 'border rounded-[4px]';
  };

  const inputClasses = `px-3 ${width} ${baseClasses} ${renderBorderRadius()}`;

  const labelClasses = `${
    disabled ? 'text-grey-60' : 'text-grey-20'
  } text-xs font-semibold ${required && "after:content-['*'] after:ml-0.5"}`;

  const containerClasses = `${width} flex items-center ${
    leadingText || trailingText || leadingIcon || trailingIcon || error
      ? `border ${borderColor} focus-within:border-royal-50 rounded-[4px]`
      : ''
  }`;

  return (
    <div className={`${height} w-full flex flex-col font-inter"`}>
      {label && <label className={`${labelClasses} mb-2`}>{label}</label>}
      <div className={containerClasses}>
        {leadingText && (
          <span className={leadingContentClasses}>{leadingText}</span>
        )}
        {leadingIcon && (
          <div className={leadingContentClasses}>{leadingIcon}</div>
        )}
        <input
          className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${inputClasses} ${additionalClasses} grow`}
          {...(type === 'number'
            ? {
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }
            : {})}
          {...rest}
        />
        {trailingText && (
          <span className={trailingContentClasses}>{trailingText}</span>
        )}
        {(trailingIcon || error) && (
          <div className={trailingContentClasses}>
            {error ? (
              <ExclamationCircleIcon
                height="16"
                width="16"
                className="text-red-50"
              />
            ) : (
              trailingIcon
            )}
          </div>
        )}
      </div>
      {helperText && (
        <Typography
          variant="h6"
          font="inter"
          color={error ? 'error' : 'secondary'}
          additionalStyles="mt-2"
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
};
