import React from 'react';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import { Create as CreateIcon } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      '&:hover': {
        cursor: 'pointer',
        color: 'black',
      },
    },
    gridItemIcon: {
      marginLeft: '8px',
      height: '20px',
      width: '20px',
    },
  })
);

interface IEditableFieldProps {
  children: JSX.Element;
  emptyFieldText: string;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditableField: React.FC<IEditableFieldProps> = ({
  children,
  emptyFieldText,
  isEditing,
  setIsEditing,
}) => {
  const classes = useStyles();

  return isEditing ? (
    children
  ) : (
    <>
      {children.props.value ? children.props.value : emptyFieldText}
      <Tooltip title="Edit">
        <CreateIcon
          className={classes.gridItemIcon + ' ' + classes.icon}
          onClick={() => setIsEditing(true)}
        />
      </Tooltip>
    </>
  );
};
