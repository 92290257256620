// Libraries
import React from 'react';
import { Formik, FormikHelpers } from 'formik';
// Utils
import { TeamMemberFormValues } from './types';
// Constants
import { teamMemberValidationSchema } from './team-management.constants';

interface TeamMemberFormikProps {
  initialValues: TeamMemberFormValues;
  handleSubmit: (
    values: TeamMemberFormValues,
    helpers: FormikHelpers<TeamMemberFormValues>
  ) => void;
  children: any;
}

export const TeamMemberFormik: React.FC<TeamMemberFormikProps> = ({
  initialValues,
  handleSubmit,
  children,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={teamMemberValidationSchema}
    >
      {children}
    </Formik>
  );
};
