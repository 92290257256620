//Libraries
import React, { createContext } from 'react';

export const BatchQuantityContext = createContext<{
  batchQuantity: number;
  setBatchQuantity: React.Dispatch<React.SetStateAction<number>> | (() => void);
}>({
  batchQuantity: 0.0,
  setBatchQuantity: () => {},
});
