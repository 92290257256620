// Libraries
import React from 'react';
import _ from 'lodash';
// Components
import { Typography, TypographyProps } from './typography.component';
// Utils
import { DirectionType, Direction } from './types';

interface RadioButtonProps {
  primaryLabel: string;
  direction?: DirectionType;
  description?: string;
  disabled?: boolean;
  checked?: boolean;
  primaryLabelProps?: Omit<TypographyProps, 'children'>;
  descriptionProps?: Omit<TypographyProps, 'children'>;
  leadingIcon?: React.ReactComponentElement<any>;
  iconSize?: string;
  [key: string]: any;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  primaryLabel,
  direction = Direction.ROW,
  description,
  disabled,
  checked,
  primaryLabelProps,
  descriptionProps,
  leadingIcon,
  iconSize = '14px',
  ...rest
}) => {
  return (
    <label className="flex items-center cursor-pointer">
      <input
        type="radio"
        className={`peer appearance-none mr-3 w-4 h-4 border-2 border-grey-90 rounded-lg  ${
          disabled ? 'checked:bg-grey-90' : 'checked:bg-royal-50'
        } checked:border-0 ${rest?.className || ''}`}
        {..._.omit(rest, ['className'])}
        checked={checked}
        disabled={disabled}
      />

      <svg
        className="absolute hidden peer-checked:block"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Box/DefaultPrimitive">
          <circle
            id="Circle"
            cx="8"
            cy="8"
            r="2"
            transform="rotate(180 8 8)"
            fill="white"
          />
        </g>
      </svg>
      <div
        className={`flex flex-${direction} ${
          direction === Direction.ROW ? 'items-center' : 'flex-start'
        } ${rest.disabled ? 'opacity-50' : ''}`}
      >
        <div className="flex flex-row items-center">
          {leadingIcon &&
            React.cloneElement(leadingIcon, {
              className: 'mr-1',
              height: iconSize,
              width: iconSize,
            })}{' '}
          <Typography
            variant="h4"
            font="inter"
            additionalStyles={`mr-2 ${primaryLabelProps?.className || ''}`}
            {..._.omit(primaryLabelProps, 'className')}
          >
            {primaryLabel}
          </Typography>
        </div>
        {description && (
          <Typography
            variant="h4"
            color="secondary"
            font="inter"
            additionalStyles={`pointer-events-none ${
              descriptionProps?.className || ''
            }`}
            {..._.omit(descriptionProps, 'className')}
          >
            {description}
          </Typography>
        )}
      </div>
    </label>
  );
};
