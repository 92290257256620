// Libraries
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
// Utils
import { useAnalytics } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

interface UploadFileButtonProps {
  category: { title: string; tag: string };
  isUploading: boolean;
  handleUpload: (e: any) => void;
}

export const UploadFileButton: React.FC<UploadFileButtonProps> = ({
  category,
  isUploading,
  handleUpload,
}) => {
  const analytics = useAnalytics()!;

  const isEmployee = window.location.pathname.includes(
    'employee-view-project-tracker'
  );

  const handleUploadBtnClick = (e: any) => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.fileUploadBtn,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: isEmployee
        ? AnalyticConstants.EVENT_LABEL.employeeUploadFile
        : AnalyticConstants.EVENT_LABEL.customerUploadFile,
    });

    handleUpload(e);
  };

  return isUploading ? (
    <div>Uploading</div>
  ) : (
    <Button
      component="label"
      endIcon={<PublishIcon />}
      color="default"
      variant="contained"
    >
      <input
        hidden
        type="file"
        onChange={handleUploadBtnClick}
        data-upload-category={category.tag}
        id={`upload-${category.title.toLowerCase().replace(' ', '')}`}
      />
      <Typography variant="body2">Upload file</Typography>
    </Button>
  );
};
