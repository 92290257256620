// Libraries
import React, { useState, useEffect } from 'react';
import {
  createStyles,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Search from '@material-ui/icons/Search';
import { FormikErrors } from 'formik';
// Utils
import { useDebounce } from 'hooks';
import { ITheme } from 'styles/mui/themeV2';
import { Vendor } from 'features/types';
import { useApi } from 'api';
import { PricingFormType } from './types';
// Constants

const INPUT_REASONS = {
  CLEAR: 'clear',
  INPUT: 'input',
  RESET: 'reset',
};

const MANUFACTURER = 'manufacturer';
const SUPPLIER = 'supplier';

type VendorType = typeof MANUFACTURER | typeof SUPPLIER;

// Helpers

const generateVendorLabel = (vendorType: string) =>
  vendorType.charAt(0).toUpperCase() + vendorType.slice(1);

export interface IVendorSearch {
  vendorType: VendorType;
  vendorValue: string;
  vendorInputValue: string;
  isSearchingForVendor: boolean;
  vendorSearchResults: Array<Maybe<Vendor & { isNew?: boolean }>>;
  selectedVendor: Maybe<Vendor>;
  isRequired: boolean;
}

export const INITIAL_MANUFACTURER_SEARCH: IVendorSearch = {
  vendorType: MANUFACTURER,
  vendorValue: '',
  vendorInputValue: '',
  isSearchingForVendor: false,
  vendorSearchResults: [],
  selectedVendor: undefined,
  isRequired: false,
};

export const INITIAL_SUPPLIER_SEARCH: IVendorSearch = {
  vendorType: SUPPLIER,
  vendorValue: '',
  vendorInputValue: '',
  isSearchingForVendor: false,
  vendorSearchResults: [],
  selectedVendor: undefined,
  isRequired: true,
};

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    autocomplete: {
      height: '54px',
    },
    button: {
      padding: '8px 12px',
    },
    buttonContainer: {
      marginBottom: theme.spacing(5),
    },
    row: {
      marginBottom: theme.spacing(5),
    },
    select: {
      height: '23px',
      marginTop: theme.spacing(1),
    },
  })
);

interface VendorSearchProps {
  isEditModal: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          create: PricingFormType;
          edit: PricingFormType;
        }>
      >;
  values: { create: PricingFormType; edit: PricingFormType };
  errors: FormikErrors<{
    create: PricingFormType;
    edit: PricingFormType;
  }>;
}

export const VendorSearch: React.FC<VendorSearchProps> = ({
  isEditModal,
  setFieldValue,
  values,
  errors,
}) => {
  const formNameSpace = isEditModal ? 'edit' : 'create';
  const {
    getManufacturers,
    getSuppliers,
    postManufacturers,
    postSuppliers,
  } = useApi();
  const classes = useStyles();
  const filter = createFilterOptions<Maybe<Vendor & { isNew?: boolean }>>();

  /* CREATE PRICE VENDOR SEARCH STATE */
  const [manufacturerSearch, setManufacturerSearch] = useState<IVendorSearch>({
    ...INITIAL_MANUFACTURER_SEARCH,
  });

  const [supplierSearch, setSupplierSearch] = useState<IVendorSearch>({
    ...INITIAL_SUPPLIER_SEARCH,
  });

  // When the add button is clicked in the pricing form, it clears the selectedVendor
  // from formik's state. We want to check for that and clear the inputs if they are not present anymore.
  useEffect(() => {
    const selectedManufacturer = values.create.selectedManufacturer;
    const selectedSupplier = values.create.selectedSupplier;

    !selectedManufacturer &&
      setManufacturerSearch({
        ...INITIAL_MANUFACTURER_SEARCH,
      });

    !selectedSupplier &&
      setSupplierSearch({
        ...INITIAL_SUPPLIER_SEARCH,
      });
  }, [values.create.selectedManufacturer, values.create.selectedSupplier]);

  /* EDIT PRICE VENDOR SEARCH STATE */
  const [
    editModalManufacturerSearch,
    setEditModalManufacturerSearch,
  ] = useState<IVendorSearch>({
    ...INITIAL_MANUFACTURER_SEARCH,
  });

  const [editModalSupplierSearch, setEditModalSupplierSearch] = useState<
    IVendorSearch
  >({
    ...INITIAL_SUPPLIER_SEARCH,
  });

  // When the pencil icon is clicked in the Pricing table, we want to display the vendors for
  // that price here
  useEffect(() => {
    const selectedManufacturer = values.edit.selectedManufacturer;
    const selectedSupplier = values.edit.selectedSupplier;

    setEditModalManufacturerSearch({
      ...INITIAL_MANUFACTURER_SEARCH,
      selectedVendor: selectedManufacturer,
      vendorValue: selectedManufacturer?.name || '',
      vendorInputValue: selectedManufacturer?.name || '',
    });
    setEditModalSupplierSearch({
      ...INITIAL_SUPPLIER_SEARCH,
      selectedVendor: selectedSupplier,
      vendorValue: selectedSupplier?.name || '',
      vendorInputValue: selectedSupplier?.name || '',
    });
  }, [values.edit.selectedManufacturer, values.edit.selectedSupplier]);

  // vendors is a function that returns the correct state hooks, depending on whether we are rendering the
  // new pricing form fields, or the pricing edit modal
  const vendors = (isEditModal: boolean) => {
    return [
      {
        searchState: isEditModal ? editModalSupplierSearch : supplierSearch,
        setSearchState: isEditModal
          ? setEditModalSupplierSearch
          : setSupplierSearch,
      },
      {
        searchState: isEditModal
          ? editModalManufacturerSearch
          : manufacturerSearch,
        setSearchState: isEditModal
          ? setEditModalManufacturerSearch
          : setManufacturerSearch,
      },
    ];
  };

  const handleVendorSearch = async (
    searchTerm: string,
    vendor: {
      searchState: IVendorSearch;
      setSearchState: React.Dispatch<React.SetStateAction<IVendorSearch>>;
    }
  ) => {
    if (searchTerm.trim().length) {
      const { searchState, setSearchState } = vendor;

      setSearchState((state: IVendorSearch) => ({
        ...state,
        isSearchingForVendor: true,
      }));

      const requestFunction =
        searchState.vendorType === MANUFACTURER
          ? getManufacturers
          : getSuppliers;

      requestFunction({
        urlParams: `?query=${encodeURIComponent(searchTerm)}`,
        handleSuccess: ({ data }) => {
          if (data.length) {
            setSearchState((state: IVendorSearch) => ({
              ...state,
              vendorSearchResults: data.map(
                ({ id, attributes }: { id: string; attributes: any }) => ({
                  id,
                  ...attributes,
                })
              ),
            }));
          } else {
            setSearchState((state: IVendorSearch) => ({
              ...state,
              vendorSearchResults: [],
            }));
          }
        },
        handleFinally: () => {
          setSearchState((state: IVendorSearch) => ({
            ...state,
            isSearchingForVendor: false,
          }));
        },
      });
    }
  };

  const debouncedVendorSearch = useDebounce(
    (searchTerm: string, vendor: any) => handleVendorSearch(searchTerm, vendor),
    275,
    false,
    []
  );

  const handleAddNewVendor = async (vendor: any, name: string) => {
    const { searchState, setSearchState } = vendor;
    const { vendorType } = searchState;

    const requestFunction =
      vendorType === MANUFACTURER ? postManufacturers : postSuppliers;

    setSearchState((state: IVendorSearch) => ({
      ...state,
      isSearchingForVendor: true,
    }));

    requestFunction({
      data: {
        [vendorType]: {
          name,
        },
      },
      handleSuccess: (data) => {
        const selectedVendor = {
          id: data.id,
          ...data.attributes,
        };

        setFieldValue(
          `${formNameSpace}.selected${generateVendorLabel(vendorType)}`,
          selectedVendor
        );
        setSearchState((state: IVendorSearch) => ({
          ...state,
          selectedVendor,
        }));
      },
      handleFinally: () => {
        setSearchState((state: IVendorSearch) => ({
          ...state,
          isSearchingForVendor: false,
        }));
      },
    });
  };

  const resetVendorFields = () => {
    setManufacturerSearch({
      ...INITIAL_MANUFACTURER_SEARCH,
    });
    setEditModalManufacturerSearch({
      ...INITIAL_MANUFACTURER_SEARCH,
    });
    setSupplierSearch({
      ...INITIAL_SUPPLIER_SEARCH,
    });
    setEditModalSupplierSearch({
      ...INITIAL_SUPPLIER_SEARCH,
    });
  };

  return (
    <Grid container item spacing={5} xs={12} className={classes.row}>
      {vendors(isEditModal).map((vendor) => {
        const { searchState, setSearchState } = vendor;
        const { vendorType } = searchState;
        const label = generateVendorLabel(vendorType);

        return (
          <Grid item xs={isEditModal ? 12 : 6} key={vendorType}>
            <Autocomplete
              className={classes.autocomplete}
              id={vendorType}
              options={searchState.vendorSearchResults}
              loading={searchState.isSearchingForVendor}
              selectOnFocus
              filterOptions={(vendor, params) => {
                const filtered = filter(vendor, params);
                // Suggest the creation of a new value
                if (params.inputValue) {
                  filtered.push({
                    id: params.inputValue,
                    name: params.inputValue,
                    isNew: true,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(vendor) => {
                if (vendor?.isNew) {
                  return `Add "${vendor?.name}"`;
                } else {
                  return vendor?.name || '';
                }
              }}
              value={searchState.vendorValue as any}
              onChange={(e: any, newValue: any) => {
                setSearchState((search) => ({
                  ...search,
                  vendorValue: newValue,
                  selectedVendor: newValue,
                }));
              }}
              inputValue={searchState.vendorInputValue}
              onInputChange={(e: any, value, reason) => {
                switch (reason) {
                  case INPUT_REASONS.INPUT:
                    setSearchState({
                      ...searchState,
                      vendorInputValue: value,
                    });
                    debouncedVendorSearch(value, vendor);
                    break;
                  case INPUT_REASONS.CLEAR: // when the X icon is clicked
                    resetVendorFields();
                    break;
                  case INPUT_REASONS.RESET: // reset is equivalent to selecting an option
                    if (!value) return;
                    const foundVendor = searchState.vendorSearchResults.find(
                      (vendor) => vendor?.name === value
                    );
                    // A new vendor has been selected
                    // and we also don't already have a vendor selected
                    if (!foundVendor && !searchState.selectedVendor) {
                      handleAddNewVendor(vendor, searchState.vendorInputValue);
                    } else {
                      if (!foundVendor) return;

                      const selectedVendor = {
                        id: foundVendor?.id || '',
                        name: foundVendor?.name || searchState.vendorInputValue,
                      };

                      setFieldValue(
                        `${formNameSpace}.selected${label}`,
                        selectedVendor
                      );

                      setSearchState({
                        ...searchState,
                        vendorValue:
                          foundVendor?.name || searchState.vendorValue,
                        vendorInputValue:
                          foundVendor?.name || searchState.vendorInputValue,
                        selectedVendor,
                      });
                    }
                    break;
                  default:
                    break;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {searchState.isSearchingForVendor ? (
                          <CircularProgress size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    className: classes.autocomplete,
                    startAdornment: <Search />,
                  }}
                  variant="outlined"
                  fullWidth
                  label={label}
                  required={searchState.isRequired}
                  error={Boolean(
                    errors[formNameSpace] &&
                      // @ts-ignore
                      errors[formNameSpace][`selected${label}`]
                  )}
                  helperText={
                    // @ts-ignore
                    errors[formNameSpace] &&
                    // @ts-ignore
                    errors[formNameSpace][`selected${label}`]
                  }
                />
              )}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
