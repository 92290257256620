// Libraries
import React, { ReactNode, useContext, useState } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import {
  createStyles,
  makeStyles,
  Collapse,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';

// Components
import { DeleteButton } from '../ui';

// Utils
import { PhaseWithIngredients } from './types';
import { useApi } from 'hooks';
import { handleError } from 'features/formula/utils';
import { ITheme } from 'styles/mui/themeV2';
// Constants
import * as Constants from 'features/constants';
import { FormulaContext } from '../context';

interface PhaseRowProps {
  children: React.ReactNode[] | React.ReactNode;
  handleDeletePhase: (phaseToDelete: PhaseWithIngredients) => void;
  isDeleting: boolean;
  isLocked: boolean;
  isUnphasedRow: boolean;
  phase: PhaseWithIngredients;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    deleteCell: {
      borderBottom: 'none',
      verticalAlign: 'baseline',
      paddingLeft: 0,
    },
    row: {
      backgroundColor: theme.palette.blue.main,
    },
    linkContainer: {
      textDecoration: 'underline',
      alignSelf: 'flex-end',
      marginLeft: '0.5rem',
      cursor: 'pointer',
    },
    input: {
      backgroundColor: theme.palette.secondary.main,
      border: `solid 1px ${theme.palette.gray.main}`,
      borderRadius: '0.25rem',
      marginLeft: '5.25rem',
      width: '93%',
    },
    tableCell: {
      borderBottom: 'none',
    },
    textFieldContainer: {
      marginTop: '0.5rem',
    },
    textField: {
      width: '93%',
    },
  })
);

export const PhaseRow: React.FC<PhaseRowProps> = ({
  children,
  handleDeletePhase,
  isDeleting,
  isLocked,
  isUnphasedRow,
  phase,
}) => {
  const { formula, setFormula } = useContext(FormulaContext);
  const request = useApi();
  const [isPhaseRowExpanded, setIsPhaseRowExpanded] = useState<boolean>(false);
  const [procedure, setProcedure] = useState<Maybe<string>>(
    phase.procedure || ''
  );

  const classes = useStyles();

  if (!formula) return null;

  const handleProcedureUpdate = async (value?: string) => {
    try {
      if (value === phase.procedure) return;

      const apiResponse = await request({
        resource: `/api/v1/phases/${phase.id}`,
        options: {
          settings: Constants.PATCH,
          body: {
            phase: {
              procedure: value,
            },
          },
          scope: 'update:phase',
        },
      });
      if (apiResponse.data) {
        phase.procedure = value;
        setFormula({ ...formula });
      } else {
        handleError(apiResponse);
      }
    } catch (error) {
      Rollbar.error(error);
    }
  };

  return (
    <>
      <TableHead>
        <TableRow className={classes.row}>
          <TableCell className={classes.tableCell} colSpan={10}>
            <Grid container direction="column">
              <Grid container>
                <Grid item>
                  <Typography variant="h3">
                    {!isUnphasedRow ? `Phase ` : ''}
                    {phase.name}
                  </Typography>
                </Grid>
                {!isUnphasedRow && (
                  <Grid className={classes.linkContainer} item>
                    <Typography
                      variant="caption"
                      onClick={() => setIsPhaseRowExpanded(!isPhaseRowExpanded)}
                    >
                      {isPhaseRowExpanded ? 'Hide' : 'Show'} procedure{' '}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Collapse in={isPhaseRowExpanded}>
                <Grid className={classes.textFieldContainer} item>
                  {isLocked ? (
                    <Typography variant="body1">{procedure}</Typography>
                  ) : (
                    <TextField
                      className={classes.input}
                      multiline
                      onBlur={() => handleProcedureUpdate(procedure)}
                      onChange={(e) => setProcedure(e.target.value)}
                      minRows={3}
                      value={procedure}
                      variant="outlined"
                    />
                  )}
                </Grid>
              </Collapse>
            </Grid>
          </TableCell>
          <TableCell colSpan={1} className={classes.deleteCell}>
            {!(children as ReactNode[])?.length &&
              (isDeleting ? (
                <CircularProgress size={30} thickness={6} />
              ) : (
                <DeleteButton onClick={() => handleDeletePhase(phase)} />
              ))}
          </TableCell>
        </TableRow>
      </TableHead>
      <Droppable droppableId={phase.id} type="ingredient">
        {(provided: any) => (
          <TableBody
            colSpan={10}
            style={{ padding: 0 }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </>
  );
};
