// Libraries
import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CreateOutlined from '@material-ui/icons/CreateOutlined';
import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
// Assets
import Done from '@material-ui/icons/Done';

// Utils
import { IRawMaterialFormValues } from './raw-material-form.component';
import { ITheme, THEME_PALETTE } from 'styles/mui/themeV2';
import { ITableColumn } from 'features/types';
import { Inci } from '../types';
// Components
import { Can, PERMISSIONS } from 'features/rbac';

// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
const SHOULD_DISABLE_US_INCIDENTAL_INPUT = true;

const GreenCheckIcon = () => (
  <Done style={{ color: THEME_PALETTE.green.dark, marginRight: '0.25rem' }} />
);

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    editCell: {
      borderLeft: `1px solid ${theme.palette.gray.light}`,
    },
    editLink: {
      color: theme.palette.gray.main,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    headerRow: {
      top: '65px',
      zIndex: 100,
    },
    incidentalsRow: {
      backgroundColor: theme.palette.gray.light,
      fontFamily: 'GT America Regular Mono',
      padding: theme.spacing(4),
    },
    row: {
      marginBottom: theme.spacing(8),
    },
    table: {
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.gray.light}`,
      borderRadius: '0.25rem',
      marginTop: theme.spacing(8),
    },
    tableHeader: {
      borderRight: `1px solid ${theme.palette.gray.light}`,
      '&:first-child': {
        borderTopLeftRadius: '0.25rem',
      },
      '&:last-child': {
        borderTopRightRadius: '0.25rem',
        borderRight: 'none',
      },
      backgroundColor: theme.palette.secondary.dark,
    },
    totalAlert: {
      width: '100%',
    },
    totalRow: {
      backgroundColor: theme.palette.tan.light,
    },
    totalRowCell: {
      borderRight: `1px solid ${theme.palette.gray.light}`,
      borderBottom: 'none',
    },
    verifiedText: {
      display: 'flex',
      alignItems: 'center',
      height: 42,
    },
  })
);

interface CompositionalBreakdownTableProps {
  handleEditSelectedInci: (inci: Maybe<Inci>) => void;
  setNameOfInciToEdit: React.Dispatch<React.SetStateAction<string>>;
  setIsEditCompBreakdownModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  clearRegionVerifications: (region: string) => void;
}

const TABLE_HEADERS: ITableColumn[] = [
  {
    headerText: 'US Name',
    columnWidth: '48%',
    align: 'left',
  },
  {
    headerText: 'EU Name',
    columnWidth: '48%',
    align: 'left',
  },
  {
    headerText: '',
    columnWidth: '4%',
    align: 'left',
  },
];

const EU = 'EU';
const US = 'US';

export const CompositionalBreakdownTable: React.FC<CompositionalBreakdownTableProps> = ({
  handleEditSelectedInci,
  setIsEditCompBreakdownModalOpen,
  setNameOfInciToEdit,
  clearRegionVerifications,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<IRawMaterialFormValues>();

  const handleEditAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    region: string
  ) => {
    const newIncis = values.incis.map((inci) => {
      if (inci.usName === e.target.name || inci.euName === e.target.name) {
        return {
          ...inci,
          ...(region === US
            ? { usAmount: parseFloat(e.target.value) }
            : { euAmount: parseFloat(e.target.value) }),
        };
      } else {
        return inci;
      }
    });

    setFieldValue('incis', newIncis);
    clearRegionVerifications(region);
  };

  const allergenIncidentalIncis = values?.incis.filter(
    (inci) => inci.allergen || inci.incidental || inci.preservative
  );

  const nonAllergenIncidentalIncis = values.incis.filter(
    (inci) => !inci.allergen && !inci.incidental && !inci.preservative
  );

  const calculateInciAmountTotal = (isUs?: boolean) => {
    const usTotal = values?.incis.reduce(
      (acc, inci) => +(inci.usAmount + acc).toFixed(5),
      0
    );
    const euTotal = values?.incis.reduce(
      (acc, inci) => +(inci.euAmount + acc).toFixed(5),
      0
    );
    return { usTotal, euTotal };
  };

  const totalsNotEqualToOneHundred = () => {
    const inciAmountTotal = calculateInciAmountTotal();
    return {
      isUsNotEqual: inciAmountTotal.usTotal !== 100,
      isEuNotEqual: inciAmountTotal.euTotal !== 100,
    };
  };

  const amountOutOfRange = (amount: number, min: number, max: number) => {
    return amount > max || amount < min;
  };

  const inciIsUsIncidentalOrAllergen = (inci: Inci, region: string) => {
    return region === 'US' && (inci.allergen || inci.incidental);
  };

  const renderTableCell = (
    id: string,
    name: string,
    amount: number,
    min: number,
    max: number,
    region: string,
    inci: Inci,
    isInputDisabled?: boolean,
    concentrationLevel?: Maybe<number>
  ) => {
    return (
      <TableCell style={{ maxWidth: '350px' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item direction="column" xs={8}>
            <Grid item xs={11}>
              <Tooltip title={name}>
                <Link
                  className="underline"
                  to={ROUTES.SHOW_INCI.route.replace(
                    UUID_SHOW_ROUTE_STRING,
                    id
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    variant="body2"
                  >
                    {name}{' '}
                    <span className="text-gray">
                      {concentrationLevel && `(${concentrationLevel}x)`}
                    </span>
                  </Typography>
                </Link>
              </Tooltip>
            </Grid>
            <Grid item style={{ marginTop: '6px' }}>
              <Typography
                className={classes.editLink}
                variant="caption"
                onClick={() => handleEditSelectedInci(inci)}
              >
                Edit
              </Typography>
            </Grid>
            <Grid
              style={{
                paddingTop: '8px',
                paddingRight: '3px',
                color: 'red',
              }}
              direction="row"
              container
              item
              justifyContent="flex-end"
            >
              {amountOutOfRange(amount, min, max) &&
                !inciIsUsIncidentalOrAllergen(inci, region) && (
                  <Typography variant="caption">{`Update to between ${' '}`}</Typography>
                )}
            </Grid>
          </Grid>
          <Grid container item direction="column" xs={4}>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                value={amount}
                name={name}
                disabled={isInputDisabled}
                onChange={(e) => handleEditAmount(e, region)}
              />
            </Grid>
            <Grid item>
              {!inciIsUsIncidentalOrAllergen(inci, region) && (
                <Typography
                  style={
                    amountOutOfRange(amount, min, max) ? { color: 'red' } : {}
                  }
                  variant="caption"
                >
                  {min}%-{max}%
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const renderTableRows = (
    incis: Inci[],
    shouldDisableAmountInput?: boolean
  ) => {
    return incis.map((inci: Inci, index: number) => {
      const {
        id,
        usName,
        euName,
        euAmount,
        usAmount,
        minPercentage,
        maxPercentage,
        concentrationLevel,
      } = inci;
      return (
        <TableRow key={`inci-${id}`}>
          {renderTableCell(
            id,
            usName as string,
            usAmount,
            minPercentage,
            maxPercentage,
            US,
            inci,
            shouldDisableAmountInput,
            concentrationLevel
          )}
          {renderTableCell(
            id,
            euName as string,
            euAmount,
            minPercentage,
            maxPercentage,
            EU,
            inci,
            false,
            concentrationLevel
          )}
          <TableCell className={classes.editCell}>
            {' '}
            <Tooltip title="Edit comp breakdown">
              <CreateOutlined
                onClick={() => {
                  setIsEditCompBreakdownModalOpen(true);
                  setNameOfInciToEdit(usName as string);
                }}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const isNotEqualToOneHundred = totalsNotEqualToOneHundred();
  const inciTotal = calculateInciAmountTotal();

  const renderVerificationRows = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <FormGroup>
            {values.verification.usIncisVerified ? (
              <FormHelperText className={classes.verifiedText}>
                <GreenCheckIcon />
                <Typography variant="caption">
                  {`US Incis verified by ${
                    values.verification.usIncisVerifiedBy
                  } on ${new Date(
                    values.verification.usIncisVerified
                  )?.toLocaleDateString()}`}
                </Typography>
              </FormHelperText>
            ) : (
              <Can
                perform={PERMISSIONS.VERIFY_INCIS}
                yes={() => (
                  <FormControlLabel
                    label="Verify US INCIs"
                    control={
                      <Checkbox
                        color="default"
                        name="verifyUsIncis"
                        onChange={handleVerificationChange}
                      />
                    }
                  />
                )}
              />
            )}
          </FormGroup>
          <FormGroup>
            {values.verification.usInciFactorsVerified ? (
              <FormHelperText className={classes.verifiedText}>
                <GreenCheckIcon />
                <Typography variant="caption">
                  {`US factors verified by ${
                    values.verification.usInciFactorsVerifiedBy
                  } on ${new Date(
                    values.verification.usInciFactorsVerified
                  )?.toLocaleDateString()}`}
                </Typography>
              </FormHelperText>
            ) : (
              <Can
                perform={PERMISSIONS.VERIFY_INCIS}
                yes={() => (
                  <FormControlLabel
                    label="Verify US Factors"
                    control={
                      <Checkbox
                        color="default"
                        name="verifyUsInciFactors"
                        onChange={handleVerificationChange}
                      />
                    }
                  />
                )}
              />
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            {values.verification.euIncisVerified ? (
              <FormHelperText className={classes.verifiedText}>
                <GreenCheckIcon />
                <Typography variant="caption">
                  {`EU Incis verified by ${
                    values.verification.euIncisVerifiedBy
                  } on ${new Date(
                    values.verification.euIncisVerified
                  )?.toLocaleDateString()}`}
                </Typography>
              </FormHelperText>
            ) : (
              <Can
                perform={PERMISSIONS.VERIFY_INCIS}
                yes={() => (
                  <FormControlLabel
                    label="Verify EU INCIs"
                    control={
                      <Checkbox
                        color="default"
                        name="verifyEuIncis"
                        onChange={handleVerificationChange}
                      />
                    }
                  />
                )}
              />
            )}
          </FormGroup>
          <FormGroup>
            {values.verification.euInciFactorsVerified ? (
              <FormHelperText className={classes.verifiedText}>
                <GreenCheckIcon />
                <Typography variant="caption">
                  {`EU factors verified by ${
                    values.verification.euInciFactorsVerifiedBy
                  } on ${new Date(
                    values.verification.euInciFactorsVerified
                  )?.toLocaleDateString()}`}
                </Typography>
              </FormHelperText>
            ) : (
              <Can
                perform={PERMISSIONS.VERIFY_INCIS}
                yes={() => (
                  <FormControlLabel
                    label="Verify EU Factors"
                    control={
                      <Checkbox
                        color="default"
                        name="verifyEuInciFactors"
                        onChange={handleVerificationChange}
                      />
                    }
                  />
                )}
              />
            )}
          </FormGroup>
        </Grid>
      </Grid>
    );
  };

  const handleVerificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(`verification.${event.target.name}`, event.target.checked);
  };

  return (
    <>
      {renderVerificationRows()}
      {values.incis.length > 0 && isNotEqualToOneHundred.isUsNotEqual && (
        <Alert color="error" severity="error" className={classes.totalAlert}>
          US total must equal 100%
        </Alert>
      )}
      {values.incis.length > 0 && isNotEqualToOneHundred.isEuNotEqual && (
        <Alert color="error" severity="error" className={classes.totalAlert}>
          EU total must equal 100%
        </Alert>
      )}
      <Table className={classes.table}>
        <TableHead className={classes.headerRow}>
          <TableRow className={classes.headerRow}>
            {TABLE_HEADERS.map((header, index) => {
              return (
                <TableCell
                  className={classes.tableHeader}
                  key={`${header}-${index}`}
                  align={header.align ? header.align : 'left'}
                  style={{
                    width: header.columnWidth,
                    color: '#ffffff',
                  }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h4">{header.headerText}</Typography>
                    </Grid>
                    {index < TABLE_HEADERS.length - 1 && (
                      <Grid item>
                        <Typography variant="h4">%</Typography>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.totalRow}>
            <TableCell className={classes.totalRowCell}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4">Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {isNaN(inciTotal.usTotal) ? 'N/A' : inciTotal.usTotal}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell className={classes.totalRowCell}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4">Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {isNaN(inciTotal.euTotal) ? 'N/A' : inciTotal.euTotal}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell className={classes.totalRowCell}></TableCell>
          </TableRow>
          {nonAllergenIncidentalIncis.length > 0 &&
            renderTableRows(nonAllergenIncidentalIncis)}
          {allergenIncidentalIncis.length > 0 && (
            <>
              <TableRow>
                <TableCell className={classes.incidentalsRow} colSpan={3}>
                  INCIDENTALS, ALLERGENS & PRESERVATIVES
                </TableCell>
              </TableRow>

              {renderTableRows(
                allergenIncidentalIncis,
                SHOULD_DISABLE_US_INCIDENTAL_INPUT
              )}
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
};
