// Libraries
import React from 'react';
import { format, parseISO } from 'date-fns';
import { createStyles, makeStyles, Typography, Grid } from '@material-ui/core';
// Utils
import { BriefAttributes, IApiData, ProjectAttributes } from 'api';
import { ITheme } from 'styles/mui/themeV2';
// Constants
import { RETAILER_STANDARDS } from 'features/brief/customer-brief/constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      color: theme.palette.gray.dark,
      display: 'flex',
      flexDirection: 'column',
    },
    divider: {
      borderTop: `1px solid ${theme.palette.gray.light}`,
      marginBottom: '2rem',
    },
    projectDetailsContainer: {
      border: `1px solid ${theme.palette.gray.light}`,
      borderRadius: '0.25rem',
      marginTop: '0.75rem',
      marginBottom: '6rem',
      padding: '2rem',
    },
    sectionItem: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '2rem',
      paddingRight: '1rem',
      textAlign: 'left',
    },
    sectionItemLabel: {
      marginBottom: '0.375rem',
    },
    sectionTitleRow: {
      marginBottom: '2rem',
    },
  })
);

type BriefViewItem = {
  label: string;
  value: any;
};

type BriefViewSection = {
  title: string;
  items: BriefViewItem[];
};

type Fragrance = keyof typeof FRAGRANCE_MAP;

const COGS_PREFIX = 'target';
const MSRP_PREFIX = 'msrpTarget';
const NOT_APPLICABLE = <span className="text-gray">N/A</span>;
const FRAGRANCE_MAP = {
  free: 'Fragrance-free',
  own: 'I have my own',
  bmark: 'I have a benchmark fragrance',
  custom: 'I want to develope a frangrance',
};

interface ICustomerBriefView {
  project: IApiData<ProjectAttributes>;
}

export const CustomerBriefView: React.FC<ICustomerBriefView> = ({
  project,
}) => {
  const classes = useStyles();
  const brief = project.parsedIncluded.brief;

  if (!project || !brief) {
    return null;
  }

  const determinePricingScheme = (prefix: string) => {
    const cost = prefix + 'Cost';
    const minCost = prefix + 'MinCost';
    const maxCost = prefix + 'MaxCost';

    if (brief.attributes[cost as keyof BriefAttributes]) {
      return `$${brief.attributes[cost as keyof BriefAttributes].toFixed(2)}`;
    } else if (
      brief.attributes[minCost as keyof BriefAttributes] &&
      brief.attributes[maxCost as keyof BriefAttributes]
    ) {
      return `$${brief.attributes[minCost as keyof BriefAttributes].toFixed(
        2
      )}-$${brief.attributes[maxCost as keyof BriefAttributes].toFixed(2)}`;
    } else {
      return NOT_APPLICABLE;
    }
  };

  const renderContentOrNotApplicable = (
    briefValue: string | number | undefined
  ) => {
    return briefValue || NOT_APPLICABLE;
  };

  const renderDate = (date: any) => {
    return format(parseISO(date), 'MM/dd/yyyy') || NOT_APPLICABLE;
  };

  const renderItems = (items: BriefViewItem[]) => {
    return items.map((item: BriefViewItem, idx: number) => {
      return (
        <Grid
          xs={12}
          sm={6}
          item
          key={`${item.label}-${idx}`}
          className={classes.sectionItem}
        >
          <Typography className={classes.sectionItemLabel} variant="h4">
            {item.label}
          </Typography>
          <Typography variant="h3">{item.value}</Typography>
        </Grid>
      );
    });
  };

  const renderSection = (section: BriefViewSection) => {
    return (
      <>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-start"
          className={classes.sectionTitleRow}
        >
          <Typography variant="h3">{section.title}</Typography>
        </Grid>
        <Grid container>{renderItems(section.items)}</Grid>
        <Grid xs={12} sm={12} className={classes.divider}></Grid>
      </>
    );
  };

  const renderStandards = () => {
    if (
      !brief?.relationships?.standards?.data ||
      brief.relationships.standards.data.length < 1
    ) {
      return NOT_APPLICABLE;
    }
    return brief.relationships.standards.data
      .map((standard: any) => {
        return RETAILER_STANDARDS.find((rs) => standard.id === rs.value)!.title;
      })
      .join(', ');
  };

  const renderUnit = (unitAbbreviation: string) => {
    switch (unitAbbreviation) {
      case 'ml':
        return 'Milliliters';
      case 'oz':
        return 'Ounces';
      case 'g':
        return 'Grams';
      default:
        return 'N/A';
    }
  };

  const SECTIONS = {
    PROJECT_BASICS: {
      title: 'Project Basics',
      items: [
        {
          label: 'Project Name',
          value: project.attributes.name,
        },
        {
          label: 'Project Description',
          value: renderContentOrNotApplicable(project.attributes.description),
        },
        {
          label: 'Target Audience',
          value: renderContentOrNotApplicable(brief.attributes.targetCustomer),
        },
        {
          label: 'Intended Markets',
          value:
            brief.attributes.countries && brief.attributes.countries.length > 0
              ? brief.attributes.countries.join(', ')
              : NOT_APPLICABLE,
        },
      ],
    },
    MARKET_STRATEGY: {
      title: 'Market Strategy',
      items: [
        {
          label: 'Project Category',
          value: renderContentOrNotApplicable(project.attributes.category),
        },
        {
          label: 'Product Type',
          value: renderContentOrNotApplicable(project.attributes.productType),
        },
        {
          label: 'Product Created Before?',
          value: renderContentOrNotApplicable(
            project.attributes.previouslyBroughtToMarket
          ),
        },
        {
          label: 'Additional Notes',
          value: renderContentOrNotApplicable(
            project.attributes.previouslyBroughtToMarketNote
          ),
        },
        {
          label: 'OTC?',
          value: renderContentOrNotApplicable(project.attributes.otc),
        },
        {
          label: 'Additional Notes',
          value: renderContentOrNotApplicable(project.attributes.otcNote),
        },
        {
          label: 'Form/Structure',
          value: renderContentOrNotApplicable(brief.attributes.structure),
        },
        {
          label: 'Fill Size',
          value: renderContentOrNotApplicable(brief.attributes.size),
        },
        {
          label: 'Unit',
          value: renderUnit(brief.attributes.unit),
        },
        {
          label: 'Order Quantity',
          value: renderContentOrNotApplicable(
            brief.attributes.minimumOrderQuantity
          ),
        },
        {
          label: 'Delivery Date',
          value: brief.attributes.targetDate
            ? renderDate(brief.attributes.targetDate)
            : NOT_APPLICABLE,
        },
        {
          label: 'Target COGS',
          value: determinePricingScheme(COGS_PREFIX),
        },
        {
          label: 'Target Retail Price',
          value: determinePricingScheme(MSRP_PREFIX),
        },
      ],
    },
    PRODUCT_REQUIREMENTS: {
      title: 'Product Requirements',
      items: [
        {
          label: 'Primary Packaging',
          value: renderContentOrNotApplicable(
            brief.attributes.primaryPackaging
          ),
        },
        {
          label: 'Labels',
          value:
            brief.attributes.labels && brief.attributes.labels.length > 0
              ? brief.attributes.labels.join(', ')
              : NOT_APPLICABLE,
        },
        {
          label: 'Must Have Ingredients',
          value: renderContentOrNotApplicable(
            brief.attributes.mustHaveIngredients
          ),
        },
        {
          label: 'Must Exclude Ingredients',
          value: renderContentOrNotApplicable(
            brief.attributes.mustExcludeIngredients
          ),
        },
        {
          label: 'Claims',
          value:
            project.attributes.productClaims &&
            project.attributes.productClaims.length > 0
              ? project.attributes.productClaims.join(', ')
              : NOT_APPLICABLE,
        },
        {
          label: 'Standards',
          value: renderStandards(),
        },
        {
          label: 'Fragrance',
          value: brief.attributes?.fragranceType
            ? FRAGRANCE_MAP[brief.attributes.fragranceType as Fragrance]
            : 'N/A',
        },
        {
          label: 'Benchmarks',
          value:
            brief.attributes.bMarks && brief.attributes.bMarks.length > 0
              ? 'Yes'
              : 'No',
        },
        {
          label: 'Additional Product Notes',
          value: renderContentOrNotApplicable(
            brief.attributes.additionalComments
          ),
        },
      ],
    },
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.projectDetailsContainer}>
        {renderSection(SECTIONS.PROJECT_BASICS)}
        {renderSection(SECTIONS.MARKET_STRATEGY)}
        {renderSection(SECTIONS.PRODUCT_REQUIREMENTS)}
      </Grid>
    </div>
  );
};
