// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton, TextArea, Typography } from 'design-system';
// Utils
import { CustomerBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const HaveYouBroughtThisProductToMarketBefore: React.FC = () => {
  const { getFieldProps, values } = useFormikContext<CustomerBriefFormValues>();
  return (
    <div className="flex flex-col gap-6">
      <Typography variant="h3" font="inter">
        Have you brought this product to market before?
      </Typography>
      <div className="flex flex-col gap-3">
        <div>
          <RadioButton
            {...getFieldProps(BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET)}
            value="Yes"
            primaryLabel="Yes"
            name="previouslyBroughtToMarket"
            checked={values.previouslyBroughtToMarket === 'Yes'}
          />
        </div>
        <div>
          <RadioButton
            {...getFieldProps(BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET)}
            value="No"
            primaryLabel="No"
            name="previouslyBroughtToMarket"
            checked={values.previouslyBroughtToMarket === 'No'}
          />
        </div>
        <TextArea
          {...getFieldProps(BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET_NOTE)}
          placeholder="Additional comments"
        />
      </div>
    </div>
  );
};
