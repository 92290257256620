// Libraries
import React from 'react';
// Components
import { IngredientListTrackerFileCard } from './ingredient-list-tracker-file-card.component';

interface TrackerFilesProps {
  files: any;
  isDeleting: boolean;
  tag: string;
  handleDelete: (e: any) => void;
  handleDownload: (e: any) => void;
}

export const TrackerFiles: React.FC<TrackerFilesProps> = ({
  files,
  isDeleting,
  tag,
  handleDelete,
  handleDownload,
}) => {
  const filteredFiles = files
    // sort from newest to oldest
    .sort((a: any, b: any) => {
      return (
        new Date(b.attributes.createdAt).getTime() -
        new Date(a.attributes.createdAt).getTime()
      );
    })
    // filter for files that belong to this task
    .filter((file: any) => file.attributes.category === tag);

  const hasCategoryFiles = filteredFiles.length > 0;

  const renderFiles = () => {
    if (!hasCategoryFiles) return null;

    return (
      <div className={`sm:flex flex-wrap justify-start w-full`}>
        {filteredFiles.map((file: any) => {
          return (
            <IngredientListTrackerFileCard
              file={file}
              isDeleting={isDeleting}
              handleDelete={handleDelete}
              handleDownload={handleDownload}
            />
          );
        })}
      </div>
    );
  };

  return renderFiles();
};
