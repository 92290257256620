// Libraries
import React from 'react';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';

interface IFormSection {
  callToAction?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  helperText?: string;
  isRevealed: boolean;
  sectionHeader: string;
  sectionSubheader?: string;
  setIsRevealed: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    button: {
      padding: '12px 16px',
    },
    container: {
      border: `1px solid ${theme.palette.gray.light}`,
      borderRadius: '4px',
      padding: theme.spacing(8),
      marginBottom: theme.spacing(5),
      minHeight: '7.125rem',
    },
    helperText: {
      color: theme.palette.gray.main,
      fontStyle: 'italic',
    },
    sectionSubheader: {
      paddingTop: theme.spacing(3),
      color: theme.palette.gray.main,
    },
  })
);

export const FormSection: React.FC<IFormSection> = ({
  children,
  callToAction,
  isRevealed,
  helperText,
  sectionHeader,
  sectionSubheader,
  setIsRevealed,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      className={classes.container}
      style={isRevealed ? { paddingRight: '16px' } : { paddingRight: '32px' }}
    >
      <Grid container item xs={6} direction="column" justifyContent="center">
        <Typography
          variant="h3"
          noWrap
          style={isRevealed ? { marginBottom: '32px' } : {}}
        >
          {sectionHeader}
        </Typography>
        {isRevealed || !sectionSubheader ? (
          ''
        ) : (
          <Typography
            className={classes.sectionSubheader}
            variant="body2"
            noWrap
          >
            {sectionSubheader}
          </Typography>
        )}
      </Grid>
      {isRevealed ? (
        <>
          {callToAction}
          {children}
        </>
      ) : (
        <Grid
          container
          item
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          {!helperText ? (
            <Button
              className={classes.button}
              color="secondary"
              variant="outlined"
              onClick={() => setIsRevealed(true)}
            >
              Add
            </Button>
          ) : (
            <Typography className={classes.helperText} variant="body2">
              {helperText}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
