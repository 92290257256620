//Utils
import * as Constants from 'features/constants';

/**
 * NOTE:
 * API V1 is being slowly deprecated. We should no longer import from this file.
 * Instead we should import any network requests within LOUIS from api/useApi.tsx
 */

function getHeaders(token: Object) {
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return headers;
}

export function getGetSettings(token: string) {
  const settings = {
    method: Constants.GET,
    headers: getHeaders(token),
  };

  return settings;
}

export function getPostSettings(token: string, body: Object) {
  const settings = {
    method: Constants.POST,
    body: JSON.stringify(body),
    headers: getHeaders(token),
  };

  return settings;
}

export function getPatchSettings(token: string, body: Object) {
  const settings = {
    method: Constants.PATCH,
    body: JSON.stringify(body),
    headers: getHeaders(token),
  };

  return settings;
}

export function getPutSettings(token: string, body: Object) {
  const settings = {
    method: Constants.PUT,
    body: JSON.stringify(body),
    headers: getHeaders(token),
  };

  return settings;
}

export function getDeleteSettings(token: string) {
  const settings = {
    method: Constants.DELETE,
    headers: getHeaders(token),
  };

  return settings;
}
