// Libraries
import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
// Components
import { DialogModal } from 'features/ui';
import { FormulaContext } from '../context';
import { GeneralSectionInputs } from 'features/raw-material';
// Utils
import { PHASE_ALPHABET } from './add-or-edit-ingredient-modal.component';
import { ITheme } from 'styles/mui/themeV2';
import { useApi, IApiData, IngredientAttributes } from 'api';
import { useSnackbar } from 'hooks';
import { ExtendedFormulaAttributes, Ingredient } from './types';

interface QuickAddRawMaterialModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface FormValues {
  general: {
    name: string;
    location: string;
    rmId: Maybe<string>;
    sampleCode: Maybe<string>;
  };
  ingredient: {
    amount: number;
    phase: string;
  };
}

const validationSchema = Yup.object().shape({
  general: Yup.object().shape({
    name: Yup.string().required(),
    location: Yup.string().required(),
    rmId: Yup.string(),
    sampleCode: Yup.string().when('rmId', {
      is: (val: string) => Boolean(val) === false,
      then: (schema: any) => schema.required(),
    }),
  }),
  ingredient: Yup.object().shape({
    amount: Yup.number()
      .required('Weight is required')
      .positive('Weight must be a positive number'),
    phase: Yup.string().required(),
  }),
});

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    row: {
      marginTop: theme.spacing(2),
    },
    select: {
      padding: theme.spacing(1),
    },
  })
);

export const QuickAddRawMaterialModal: React.FC<QuickAddRawMaterialModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const classes = useStyles();
  const { formula, setFormula } = useContext(FormulaContext);
  const { showSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { postFormulaIngredients } = useApi();

  const INITIAL_FORM_VALUES: FormValues = {
    general: {
      name: '',
      location: '',
      rmId: undefined,
      sampleCode: undefined,
    },
    ingredient: {
      amount: 0,
      phase: formula?.phases.at(-1)?.name || 'A',
    },
  };

  const handleSubmit = ({ general, ingredient }: FormValues) => {
    if (!formula) return;

    setIsSubmitting(true);
    postFormulaIngredients({
      urlParams: `${formula?.id}/ingredients`,
      data: {
        ingredient: {
          ...ingredient,
          raw_material: general,
        },
      },
      handleSuccess: (data: IApiData<IngredientAttributes>) => {
        const {
          active,
          amount,
          highestPrice,
          lotNumber,
          manufacturer,
          position,
          rawMaterialEuIncis,
          rawMaterialName,
          rawMaterialRmId,
          rawMaterialUsIncis,
          rawMaterialUuid,
          rawMaterialSampleCode,
          supplier,
          usApiDrugPurposes,
        } = data.attributes;

        // Grab phase ID from response
        const phaseId = data.relationships.phase.data.id;

        // Build the new ingredient
        const newIngredient: Ingredient = {
          active,
          euIncis: rawMaterialEuIncis,
          id: data.id,
          key: undefined,
          lotNumber,
          manufacturer,
          name: rawMaterialName,
          amount: parseFloat(amount as string),
          phaseUuid: phaseId,
          position: position,
          price: highestPrice,
          rmId: rawMaterialRmId,
          rmUuid: rawMaterialUuid,
          sampleCode: rawMaterialSampleCode,
          supplier,
          usApiDrugPurposes,
          usIncis: rawMaterialUsIncis,
        } as Ingredient;

        // from the existing phases in state try to find the phase
        const foundPhase = formula.phases.find((phase) => phase.id === phaseId);

        if (foundPhase) {
          // Just add a new ingredient to the existing phase
          foundPhase.ingredients.push(newIngredient);
        } else {
          // Add both the new phase and the new ingredient in that phase's ingredients
          formula.phases.push({
            id: phaseId,
            name: ingredient.phase,
            ingredients: [newIngredient],
          });
        }

        // reassign positions on the frontend
        formula.phases
          .sort(({ name: aName }, { name: bName }) => {
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
          })
          .flatMap((ph) => ph.ingredients)
          .forEach((ing, idx) => (ing.position = idx + 1));

        // Finally add the new ing/phase to the formula state
        setFormula({
          ...formula,
          phases: [...formula.phases],
        } as ExtendedFormulaAttributes);
        showSnackbar(`${newIngredient.name} added to formula`, 'success');
        handleClose();
      },
      handleFinally: () => setIsSubmitting(false),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_FORM_VALUES}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, getFieldProps, errors }) => {
        return (
          <DialogModal
            title="Quick Add Raw Material"
            submitDisabled={isSubmitting}
            onConfirm={handleSubmit}
            handleClose={handleClose}
            open={isOpen}
            maxWidthSize="md"
          >
            <GeneralSectionInputs />
            {/* ---- INGREDIENT INPUTS ---- */}
            <Grid className={classes.row} container item spacing={5} xs={12}>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" size="medium">
                  <InputLabel id="phase-select-label">Phase</InputLabel>
                  <Select
                    className={classes.select}
                    id="phase-select"
                    label="Phase"
                    labelId="phase-select-label"
                    variant="outlined"
                    {...getFieldProps('ingredient.phase')}
                  >
                    {PHASE_ALPHABET.map((phaseLetter, index) => (
                      <MenuItem key={phaseLetter + index} value={phaseLetter}>
                        Phase {phaseLetter}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    error={Boolean(errors.ingredient?.amount)}
                    inputProps={{
                      min: 0.0,
                      max: 100.0,
                    }}
                    label="Weight %"
                    required
                    type="number"
                    variant="outlined"
                    helperText={errors.ingredient?.amount}
                    {...getFieldProps('ingredient.amount')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogModal>
        );
      }}
    </Formik>
  );
};
