// Libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import { Apartment } from '@material-ui/icons';
// Components
import { useMobile } from 'hooks';
// Utils
import { useApi, BriefAttributes, IApiData, ProjectAttributes } from 'api';
import { interRegular, ITheme } from 'styles/mui/themeV2';
// Constants
import { RETAILER_STANDARDS } from '../constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    companyContainer: {
      alignItems: 'center',
      borderRight: `1px solid ${theme.palette.gray.light}`,
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      padding: '2rem 1.5rem',
    },
    container: {
      color: theme.palette.gray.dark,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '1064px',
      margin: '4rem auto',
    },
    divider: {
      borderTop: `1px solid ${theme.palette.gray.light}`,
      marginBottom: '2rem',
    },
    projectDetailsContainer: {
      border: `1px solid ${theme.palette.gray.light}`,
      borderRadius: '0.25rem',
      marginTop: '0.75rem',
      marginBottom: '6rem',
      padding: '2rem',
    },
    sectionItem: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '2rem',
      paddingRight: '1rem',
      textAlign: 'left',
    },
    sectionItemLabel: {
      marginBottom: '0.375rem',
    },
    sectionTitleRow: {
      marginBottom: '2rem',
    },
    tabbedMenu: {
      backgroundColor: theme.palette.gray.dark,
      display: 'flex',
      marginTop: '4.375rem',
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
    },
    tabbedMenuItem: {
      borderBottom: `2px solid ${theme.palette.green.dark}`,

      color: theme.palette.secondary.main,
      padding: '1.125rem 3rem',
    },
    topLevelValueContainer: {
      alignItems: 'flex-start',
      borderRight: `1px solid ${theme.palette.gray.light}`,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'space-between',
      padding: '1.25rem 1.5rem',
      '&:last-child': {
        borderRight: 'none',
      },
    },
    topLevelValuesContainer: {
      border: `1px solid ${theme.palette.gray.light}`,
      display: 'flex',
      marginTop: '2rem',
    },
    topLevelValueText: {
      fontFamily: interRegular.fontFamily,
      fontSize: '16px',
    },
  })
);

type BriefViewItem = {
  label: string;
  value: any;
};

type BriefViewSection = {
  title: string;
  items: BriefViewItem[];
};

type ProjectWithBrief = IApiData<ProjectAttributes> & {
  brief: IApiData<BriefAttributes>;
};

const COGS_PREFIX = 'target';
const MSRP_PREFIX = 'msrpTarget';

const NOT_APPLICABLE = <span className="text-gray">N/A</span>;

const FRAGRANCE_MAP = {
  free: 'Fragrance-free',
  own: 'I have my own',
  bmark: 'I have a benchmark fragrance',
  custom: 'I want to develope a frangrance',
};

type Fragrance = keyof typeof FRAGRANCE_MAP;

export const BriefView = () => {
  const { uuid } = useParams();
  const { getProject } = useApi();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [project, setProject] = useState<ProjectAttributes | undefined>(
    undefined
  );
  const [brief, setBrief] = useState<BriefAttributes | undefined>(undefined);
  const { isMobile } = useMobile();

  useEffect(() => {
    if (uuid) {
      getProject({
        urlParams: uuid,
        handleSuccess: (data: ProjectWithBrief) => {
          const {
            attributes: { brief },
            attributes,
          } = data;
          setProject(attributes);
          setBrief({
            ...brief.data.attributes,
            // @ts-ignore
            standards: brief.data.relationships.standards.data,
          });
        },
        handleFinally: () => {
          setIsLoading(false);
        },
      });
    }
  }, [getProject, uuid]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!project || !brief) {
    return null;
  }

  const determinePricingScheme = (prefix: string) => {
    const cost = prefix + 'Cost';
    const minCost = prefix + 'MinCost';
    const maxCost = prefix + 'MaxCost';

    if (brief[cost as keyof BriefAttributes]) {
      return `$${brief[cost as keyof BriefAttributes].toFixed(2)}`;
    } else if (
      brief[minCost as keyof BriefAttributes] &&
      brief[maxCost as keyof BriefAttributes]
    ) {
      return `$${brief[minCost as keyof BriefAttributes].toFixed(2)}-$${brief[
        maxCost as keyof BriefAttributes
      ].toFixed(2)}`;
    } else {
      return NOT_APPLICABLE;
    }
  };

  const renderContentOrNotApplicable = (
    briefValue: string | number | undefined
  ) => {
    return briefValue || NOT_APPLICABLE;
  };

  const renderDate = (date: any) => {
    return format(parseISO(date), 'MM/dd/yyyy') || NOT_APPLICABLE;
  };

  const renderItems = (items: BriefViewItem[]) => {
    return items.map((item: BriefViewItem, idx: number) => {
      return (
        <Grid
          xs={12}
          sm={6}
          item
          key={`${item.label}-${idx}`}
          className={classes.sectionItem}
        >
          <Typography className={classes.sectionItemLabel} variant="h4">
            {item.label}
          </Typography>
          <Typography variant="h3">{item.value}</Typography>
        </Grid>
      );
    });
  };

  const renderSection = (section: BriefViewSection) => {
    return (
      <>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-start"
          className={classes.sectionTitleRow}
        >
          <Typography variant="h3">{section.title}</Typography>
        </Grid>
        <Grid container>{renderItems(section.items)}</Grid>
        <Grid xs={12} sm={12} className={classes.divider}></Grid>
      </>
    );
  };

  const renderStandards = () => {
    if (!brief?.standards || brief.standards.length < 1) {
      return NOT_APPLICABLE;
    }
    return brief.standards
      .map((standard: any) => {
        return RETAILER_STANDARDS.find((rs) => standard.id === rs.value)!.title;
      })
      .join(', ');
  };

  const renderUnit = (unitAbbreviation: string) => {
    switch (unitAbbreviation) {
      case 'ml':
        return 'Milliliters';
      case 'oz':
        return 'Ounces';
      case 'g':
        return 'Grams';
      default:
        return 'N/A';
    }
  };

  const SECTIONS = {
    PROJECT_BASICS: {
      title: 'Project Basics',
      items: [
        {
          label: 'Project Name',
          value: project.name,
        },
        {
          label: 'Project Description',
          value: renderContentOrNotApplicable(project.description),
        },
        {
          label: 'Target Audience',
          value: renderContentOrNotApplicable(brief.targetCustomer),
        },
        {
          label: 'Intended Markets',
          value:
            brief.countries && brief.countries.length > 0
              ? brief.countries.join(', ')
              : NOT_APPLICABLE,
        },
      ],
    },
    MARKET_STRATEGY: {
      title: 'Market Strategy',
      items: [
        {
          label: 'Project Category',
          value: renderContentOrNotApplicable(project.category),
        },
        {
          label: 'Product Type',
          value: renderContentOrNotApplicable(project.productType),
        },
        {
          label: 'Product Created Before?',
          value: renderContentOrNotApplicable(
            project.previouslyBroughtToMarket
          ),
        },
        {
          label: 'Additional Notes',
          value: renderContentOrNotApplicable(
            project.previouslyBroughtToMarketNote
          ),
        },
        {
          label: 'OTC?',
          value: renderContentOrNotApplicable(project.otc),
        },
        {
          label: 'Additional Notes',
          value: renderContentOrNotApplicable(project.otcNote),
        },
        {
          label: 'Form/Structure',
          value: renderContentOrNotApplicable(brief.structure),
        },
        {
          label: 'Fill Size',
          value: renderContentOrNotApplicable(brief.size),
        },
        {
          label: 'Unit',
          value: renderUnit(brief.unit),
        },
        {
          label: 'Order Quantity',
          value: renderContentOrNotApplicable(brief.minimumOrderQuantity),
        },
        {
          label: 'Delivery Date',
          value: brief.targetDate
            ? renderDate(brief.targetDate)
            : NOT_APPLICABLE,
        },
        {
          label: 'Target COGS',
          value: determinePricingScheme(COGS_PREFIX),
        },
        {
          label: 'Target Retail Price',
          value: determinePricingScheme(MSRP_PREFIX),
        },
      ],
    },
    PRODUCT_REQUIREMENTS: {
      title: 'Product Requirements',
      items: [
        {
          label: 'Primary Packaging',
          value: renderContentOrNotApplicable(brief.primaryPackaging),
        },
        {
          label: 'Labels',
          value:
            brief.labels && brief.labels.length > 0
              ? brief.labels.join(', ')
              : NOT_APPLICABLE,
        },
        {
          label: 'Must Have Ingredients',
          value: renderContentOrNotApplicable(brief.mustHaveIngredients),
        },
        {
          label: 'Must Exclude Ingredients',
          value: renderContentOrNotApplicable(brief.mustExcludeIngredients),
        },
        {
          label: 'Claims',
          value:
            project.productClaims && project.productClaims.length > 0
              ? project.productClaims.join(', ')
              : NOT_APPLICABLE,
        },
        {
          label: 'Standards',
          value: renderStandards(),
        },
        {
          label: 'Fragrance',
          value: brief?.fragranceType
            ? FRAGRANCE_MAP[brief.fragranceType as Fragrance]
            : 'N/A',
        },
        {
          label: 'Benchmarks',
          value: brief.bMarks && brief.bMarks.length > 0 ? 'Yes' : 'No',
        },
        {
          label: 'Additional Product Notes',
          value: renderContentOrNotApplicable(brief.additionalComments),
        },
      ],
    },
  };

  return (
    <div className={classes.container}>
      <Typography variant="h1">{project.name}</Typography>
      {!isMobile && (
        <div className={classes.topLevelValuesContainer}>
          <div className={classes.companyContainer}>
            <p className={classes.topLevelValueText}>{project.companyName}</p>
            <Apartment />
          </div>
          <div className={classes.topLevelValueContainer}>
            <Typography variant="h4">Form/Structure</Typography>
            <Typography variant="body1">{brief.structure}</Typography>
          </div>
          <div className={classes.topLevelValueContainer}>
            <Typography variant="h4">Quantity</Typography>
            <Typography variant="body1">
              {brief.minimumOrderQuantity}
            </Typography>
          </div>
          <div className={classes.topLevelValueContainer}>
            <Typography variant="h4">Target Delivery</Typography>
            <Typography variant="body1">
              {brief.targetDate ? renderDate(brief.targetDate) : NOT_APPLICABLE}
            </Typography>
          </div>
          <div className={classes.topLevelValueContainer}>
            <Typography variant="h4">Target COGS</Typography>
            <Typography variant="body1">
              {determinePricingScheme(COGS_PREFIX)}
            </Typography>
          </div>
        </div>
      )}
      <div className={classes.tabbedMenu}>
        <div className={classes.tabbedMenuItem}>
          <Typography variant="h4">Project Brief</Typography>
        </div>
      </div>
      <Grid container className={classes.projectDetailsContainer}>
        {renderSection(SECTIONS.PROJECT_BASICS)}
        {renderSection(SECTIONS.MARKET_STRATEGY)}
        {renderSection(SECTIONS.PRODUCT_REQUIREMENTS)}
      </Grid>
    </div>
  );
};
