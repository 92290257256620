//Libraries
import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
//Assets
import PmLogo from 'img/pm-logo-blue.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexFlow: 'column',
      height: 'calc(86vh - 200px)',
    },
    logo: {
      width: '80%',
      maxWidth: '100px',
      minWidth: '0px',
    },
  })
);

export const NotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <img src={PmLogo} alt="Prime Matter Lockup" className={classes.logo} />
      <br />
      <Typography variant="h1">Page not found</Typography>
    </Grid>
  );
};

export default NotFound;
