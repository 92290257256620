// Libraries
import React from 'react';
// Components
import { DialogModal } from 'features/ui';

interface EditPricingModalProps {
  children: React.ReactNode | React.ReactNode[];
  handleDelete: () => void;
  handleEdit: () => void;
  open: boolean;
  priceIndexToEdit: Maybe<number>;
  setIsEditPricingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitDisabled?: boolean;
}

export const EditPricingModal: React.FC<EditPricingModalProps> = ({
  children,
  open,
  handleDelete,
  handleEdit,
  setIsEditPricingModalOpen,
  submitDisabled,
}) => {
  return (
    <DialogModal
      deleteButtonText={'Delete Price'}
      open={open}
      onConfirm={handleEdit}
      overflow="scroll"
      title="Edit Price"
      handleClose={() => setIsEditPricingModalOpen(false)}
      onDelete={handleDelete}
      submitDisabled={submitDisabled}
    >
      {children}
    </DialogModal>
  );
};
