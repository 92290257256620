// Libraries
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, createStyles, Box, Tabs, Tab } from '@material-ui/core';
// Utils
import { interRegular, ITheme } from 'styles/mui/themeV2';
// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    tabContainer: {
      position: 'sticky',
      top: '4.5rem',
      height: 'fit-content',
      width: '100%',
    },
    tab: {
      fontFamily: interRegular.fontFamily,
      textTransform: 'capitalize',
      borderRadius: 24,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: theme.palette.gray.lighter,
      },
      height: 50,
      marginBottom: 2,
      color: '#333333',
    },
    selectedTab: {
      backgroundColor: theme.palette.tan.light,
      '&:hover': {
        backgroundColor: theme.palette.tan.main,
      },
    },
    tabTextWrapper: {
      alignItems: 'baseline',
      marginLeft: theme.spacing(2),
    },
    tabIndicator: {
      display: 'none',
    },
  })
);

// Types
interface IVerticalFormMenu {
  currentTab: number;
  setDestinationSection: React.Dispatch<React.SetStateAction<number>>;
  setIsAutoScrolling: React.Dispatch<React.SetStateAction<boolean>>;
  tabLabels: string[];
}

export const VerticalFormMenu: React.FC<IVerticalFormMenu> = ({
  currentTab,
  setDestinationSection,
  setIsAutoScrolling,
  tabLabels,
}) => {
  const classes = useStyles();
  const menu = useRef<any>();
  const [isMenuSticky, setIsMenuSticky] = useState<boolean>(false);

  const stickyMenuViewPortPosition = 72;
  const stickyMenuViewPortPositionAndMargin = 92;

  const updateStyles = () => {
    // get the position of the top of the menu relative to the viewport
    const menuTop = menu.current?.getBoundingClientRect()?.top;
    /**
     * When scroll makes menu sticky to the window (in this case 72px)
     * set the sticky menu state to true so the Tabs margin styles render.
     * There's a logic paradox where once the margin is added the relative position becomes 92px
     * this sets state back to false and changes margin back to 0.
     * We want state to stay true as long as position is the base relative position or with margin
     */
    menuTop &&
      setIsMenuSticky(
        menuTop === stickyMenuViewPortPosition ||
          menuTop === stickyMenuViewPortPositionAndMargin
      );
  };

  // when the window detects scrolling we want to check if the styling needs to be updated
  useEffect(() => {
    window.addEventListener('scroll', updateStyles);

    return () => window.removeEventListener('scroll', updateStyles);
  }, []);

  const renderTabs = () => {
    return tabLabels.map((label) => (
      <Tab
        key={label}
        label={label}
        className={classes.tab}
        classes={{
          selected: classes.selectedTab,
          wrapper: classes.tabTextWrapper,
        }}
      />
    ));
  };

  const handleChange = (_event: React.ChangeEvent<{}>, tab: number) => {
    setIsAutoScrolling(true);
    setDestinationSection(tab);
  };

  return (
    <Box className={classes.tabContainer}>
      <Tabs
        orientation="vertical"
        value={currentTab}
        onChange={handleChange}
        TabIndicatorProps={{ className: classes.tabIndicator }}
        ref={menu}
        style={{ marginTop: isMenuSticky ? 20 : 0 }}
      >
        {renderTabs()}
      </Tabs>
    </Box>
  );
};
