// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Utils
import { ThirdPartyTestFormValues } from './types';
import { calculateTotalCost } from './utils';

export const CostSummarySection: React.FC = () => {
  const { values } = useFormikContext<ThirdPartyTestFormValues>();

  return (
    <span className="w-full sm:w-6/12 h-1/2 sm:h-full flex items-center pl-6">
      <h6 className="font-inter text-[16px] font-bold">
        {calculateTotalCost(
          Object.keys(values)
            .filter(
              (key) =>
                /* Remove all keys with initials or additional tests */
                !key.includes('Initials') && key !== 'additionalTests'
            )
            .map((key) =>
              typeof values[key] === 'string'
                ? parseFloat(values[key] as string)
                : (values[key] as number) || 0
            )
            // Add additional tests costs
            .concat(values.additionalTests.map((test) => test.cost || 0))
        )}
      </h6>
    </span>
  );
};
