// Libraries
import { useState, useEffect, useMemo } from 'react';

export const useOnScreen = (ref: React.MutableRefObject<any>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    // Intersection observer tracks whether an element as intersected
    // with an element set in the config
    () =>
      new IntersectionObserver(
        // entry represents the actual dom element that is being tracked
        ([entry]) => {
          const { isIntersecting } = entry;

          // isIntersecting means that the element is now visible
          // on the screen based on the intersection between the element
          // and the root, while considering the rootMargin
          setIntersecting(isIntersecting);
        },
        // when root is null it will use the entire window object
        // threshold is a number between 0 and 1
        // threshold tells the intersection observer how often to fire
        // the provided callback.
        // 1 means only fire when the entire element is visible
        // 0 means fire as often as any part of the element becomes visible
        // rootMargin: tells us where on the document to count as an intersection
        // see: https://github.com/pomber/intersection-observer-debugger for visual debugger
        {
          root: null,
          //equates to:  top,right,bottom,left
          rootMargin: '-25% 0px -75% 0px',
          threshold: [0.00001],
        }
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
};
