//Libraries
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export const Loader: React.FC = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      role="alert"
      aria-label="loading content"
      aria-live="polite"
    >
      <CircularProgress />
    </div>
  );
};
