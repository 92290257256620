//Libraries
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
// Utils
import { ExtendedFormulaAttributes } from './types';
import { useApi } from 'api';
// Constants
import { ROUTES } from 'features/navigation';

type ArchiveDialogProps = {
  formula: ExtendedFormulaAttributes;
  handleClose: () => void;
  open: boolean;
};

export const ArchiveDialog: React.FC<ArchiveDialogProps> = ({
  formula,
  handleClose,
  open,
}) => {
  const navigate = useNavigate();
  const { deleteFormula } = useApi();

  const [isArchivingFormula, setIsArchivingFormula] = useState<boolean>(false);

  const handleArchive = () => {
    setIsArchivingFormula(true);

    deleteFormula({
      urlParams: formula.id,
      handleSuccess: () =>
        navigate(ROUTES.HOME.route, {
          state: {
            formulaWasArchived: true,
            formulaExternalId: formula.externalId,
          },
        }),
      handleFinally: () => setIsArchivingFormula(false),
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Archive Formula</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <DialogContentText>
          Are you sure you want to archive {formula.externalId}?
        </DialogContentText>
        <Grid
          container
          item
          justifyContent="flex-end"
          xs={12}
          spacing={2}
          style={{ marginRight: 0, marginTop: 16 }}
        >
          <Grid item>
            <Button
              disabled={isArchivingFormula}
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              size="large"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isArchivingFormula}
              variant="outlined"
              size="large"
              onClick={handleArchive}
            >
              Archive
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
