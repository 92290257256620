// Libraries
import React from 'react';
import _ from 'lodash';
// Components
import { Typography, TypographyProps } from './typography.component';
// Utils
import { DirectionType, Direction } from './types';

interface CheckboxProps {
  primaryLabel: string;
  direction?: DirectionType;
  description?: string;
  disabled?: boolean;
  checked?: boolean;
  primaryLabelProps?: Omit<TypographyProps, 'children'>;
  descriptionProps?: Omit<TypographyProps, 'children'>;
  leadingIcon?: React.ReactComponentElement<any>;
  iconSize?: string;
  [key: string]: any;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  direction = Direction.ROW,
  primaryLabel,
  primaryLabelProps,
  description,
  descriptionProps,
  leadingIcon,
  iconSize = '14px',
  ...rest
}) => {
  return (
    <label
      data-testid={`checkbox-${primaryLabel}`}
      className="flex items-center cursor-pointer"
    >
      <input
        type="checkbox"
        className={`peer appearance-none flex-shrink-0  mr-3 w-4 h-4 border-2 border-grey-90 rounded-[4px] bg-white checked:accent-royal-50 checked:bg-royal-50 checked:border-0 ${
          rest?.className || ''
        }`}
        {..._.omit(rest, ['className'])}
      />
      <svg
        className="absolute hidden peer-checked:block flex-1"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Box/DefaultPrimitive">
          <path
            id="Vector"
            d="M5.69471 7.28071C5.50611 7.09855 5.25351 6.99776 4.99131 7.00004C4.72911 7.00232 4.4783 7.10749 4.29289 7.29289C4.10749 7.4783 4.00232 7.72911 4.00004 7.99131C3.99776 8.25351 4.09855 8.50611 4.28071 8.69471L6.28071 10.6947C6.46824 10.8822 6.72255 10.9875 6.98771 10.9875C7.25288 10.9875 7.50718 10.8822 7.69471 10.6947L11.6947 6.69471C11.8769 6.50611 11.9777 6.25351 11.9754 5.99131C11.9731 5.72911 11.8679 5.4783 11.6825 5.29289C11.4971 5.10749 11.2463 5.00232 10.9841 5.00004C10.7219 4.99776 10.4693 5.09855 10.2807 5.28071L6.98771 8.57371L5.69471 7.28071Z"
            fill="white"
          />
        </g>
      </svg>
      <div
        className={`flex flex-${direction} ${
          direction === Direction.ROW ? 'items-center' : 'flex-start'
        } ${rest.disabled ? 'opacity-50' : ''}`}
      >
        <div className="flex flex-row items-center">
          {leadingIcon &&
            React.cloneElement(leadingIcon, {
              className: 'mr-1',
              height: iconSize,
              width: iconSize,
            })}{' '}
          <Typography
            variant="h4"
            font="inter"
            additionalStyles={`mr-2 ${primaryLabelProps?.className || ''}`}
            {..._.omit(primaryLabelProps, 'className')}
          >
            {primaryLabel}
          </Typography>
        </div>
        {description && (
          <Typography
            variant="h4"
            color="secondary"
            font="inter"
            additionalStyles={`pointer-events-none ${
              descriptionProps?.className || ''
            }`}
            {..._.omit(descriptionProps, 'className')}
          >
            {description}
          </Typography>
        )}
      </div>
    </label>
  );
};
