// Libraries
import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// Utils
import { THEME_PALETTE } from 'styles/mui/themeV2';
import { WarningOrError } from './types';
// Constants
import { FORMULA_ISSUE_SUMMARY } from './constants';
const ZERO_ERRORS = 0;

interface FormulaErrorsProps {
  errors: WarningOrError[];
}

export const FormulaErrors: React.FC<FormulaErrorsProps> = ({ errors }) => {
  if (errors.length <= ZERO_ERRORS) return null;

  return (
    <Alert severity="error" className="mb-2">
      <Typography variant="body2" style={{ color: THEME_PALETTE.gray.dark }}>
        {FORMULA_ISSUE_SUMMARY}
      </Typography>
      <List
        dense
        style={{
          listStyleType: 'disc',
          marginLeft: '1rem',
          color: THEME_PALETTE.gray.dark,
        }}
      >
        {errors.map((error, index) => (
          <ListItem key={index} style={{ display: 'list-item' }} disableGutters>
            <ListItemText
              primary={error.issue}
              secondary={error.actionItem}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        ))}
      </List>
    </Alert>
  );
};
