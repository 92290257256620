// Libraries
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

// Utils
import { ITheme } from 'styles/mui/themeV2';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    auditContainer: {
      color: 'red',
      marginTop: 10,
    },
  })
);

interface IPricingLaborAudit {
  setChangedInputUser: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const PricingLaborAudit: React.FC<IPricingLaborAudit> = ({
  setChangedInputUser,
}) => {
  const classes = useStyles();

  // Audit state
  const [auditUser, setAuditUser] = useState<string>();
  const [auditDate, setAuditDate] = useState<string>();

  // Search params
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const urlParams = Object.fromEntries(searchParams.entries());
    if (!urlParams.userName) return;

    setAuditUser(urlParams.userName);
    setAuditDate(urlParams.date);
    /**
     * We preset the changedInputUser with whoever user is in the param
     * so that if the current user shares the link again
     * without having made any changes, the user that
     * originally made changes to the inputs will still displayed in the audit
     */
    setChangedInputUser(encodeURIComponent(urlParams.userName));
  }, [searchParams, setChangedInputUser]);

  return (
    <div className={classes.auditContainer}>
      {auditUser && (
        <Typography variant="h3">
          Last modified by: {auditUser} on {auditDate}
        </Typography>
      )}
    </div>
  );
};
