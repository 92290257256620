// Library
import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  createStyles,
  TableCell,
} from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { DataRow } from './types';

interface StaticTableProps {
  children?: any;
  headers: string[];
  tableData: Array<Maybe<DataRow>>;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      maxHeight: 500,
    },
    disabledRow: {
      color: theme.palette.gray.main,
    },
    headerRow: {
      backgroundColor: theme.palette.secondary.dark,
    },
    headerCell: {
      whiteSpace: 'nowrap',
      color: theme.palette.primary.light,
    },
    dataCell: {
      borderLeft: `1px solid ${theme.palette.gray.light}`,
      borderRight: `1px solid ${theme.palette.gray.light}`,
    },
    currentTankRow: {
      backgroundColor: theme.palette.tan.main,
    },
  })
);

export const StaticTable: React.FC<StaticTableProps> = ({
  children,
  headers,
  tableData,
}) => {
  const classes = useStyles();

  const renderTableHeaders = () =>
    headers.map((header) => (
      <TableCell
        className={classes.headerCell}
        classes={{ stickyHeader: classes.headerRow }}
      >
        {header}
      </TableCell>
    ));

  const renderTableRows = () =>
    tableData.map((data) => {
      const { isDisabled, isHighlighted, values } = data as DataRow;
      return (
        <TableRow
          className={isHighlighted ? classes.currentTankRow : undefined}
        >
          {values.map((value) => (
            <TableCell
              className={classes.dataCell}
              style={{
                color: isDisabled ? '#909090' : '',
              }}
            >
              {value}
            </TableCell>
          ))}
        </TableRow>
      );
    });

  return (
    <Paper>
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.headerRow}>
              {renderTableHeaders()}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
          {children}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StaticTable;
