// Libraries
import React from 'react';
import { Link } from '@material-ui/core';
// Utils
import { FormulaAttributes, IApiData, IngredientAttributes } from 'api';
import { ExtendedFormulaAttributes } from 'features/formula/formula-page/types';
import { WarningOrError } from './types';
// Constants
import {
  FORMULA_MISSING_INGREDIENTS_WARNING,
  INCORRECT_FORMULA_AMOUNT_WARNING,
  MISSING_PRICE_WARNING,
} from './constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

export const generateFormulaErrors = (
  formula?: IApiData<FormulaAttributes | ExtendedFormulaAttributes>,
  ingredients?: IApiData<IngredientAttributes>[]
): WarningOrError[] => {
  const formulaLink = (
    <Link
      style={{ textDecoration: 'underline' }}
      href={ROUTES.SHOW_FORMULA.route.replace(
        UUID_SHOW_ROUTE_STRING,
        formula?.id || ''
      )}
    >
      Formula
    </Link>
  );

  // Case for a new formula that has no ingredients yet
  if (!ingredients) {
    return [
      {
        issue: FORMULA_MISSING_INGREDIENTS_WARNING.issue,
        actionItem: (
          <>
            {FORMULA_MISSING_INGREDIENTS_WARNING.actionItem} {formulaLink}
          </>
        ),
        warningName: FORMULA_MISSING_INGREDIENTS_WARNING.warningName,
      },
    ];
  }

  const warnings = [];
  const ingredientsWithMissingPrices = ingredients
    ? ingredients
        .filter((ingredient) => !ingredient.attributes.highestPrice)
        .map((ingredient, index, ingredientsMissingPrices) => (
          <React.Fragment key={`${ingredient.id}-${index}`}>
            <Link
              style={{ textDecoration: 'underline' }}
              href={ROUTES.SHOW_RAW_MATERIAL.route.replace(
                UUID_SHOW_ROUTE_STRING,
                ingredient.attributes.rawMaterialUuid
              )}
            >
              {ingredient.attributes.rawMaterialRmId
                ? ingredient.attributes.rawMaterialRmId
                : `SAMP-${ingredient.attributes.rawMaterialSampleCode}`}
            </Link>
            {/* if there's an element after the current iteration then append a comma */}
            {ingredientsMissingPrices[index + 1] && ', '}
          </React.Fragment>
        ))
    : [];

  ingredientsWithMissingPrices.length > 0 &&
    warnings.push({
      issue: MISSING_PRICE_WARNING.issue,
      actionItem: (
        <>
          {`${MISSING_PRICE_WARNING.actionItem} `}
          {ingredientsWithMissingPrices}
        </>
      ),
      warningName: MISSING_PRICE_WARNING.warningName,
    });

  const totalAmountOfIngredients = ingredients
    .reduce((sum, ingredient) => {
      return (
        sum +
        (typeof ingredient.attributes.amount === 'string'
          ? parseFloat(ingredient.attributes.amount)
          : ingredient.attributes.amount)
      );
    }, 0)
    .toFixed(2);

  if (
    parseFloat(totalAmountOfIngredients) < 100 ||
    parseFloat(totalAmountOfIngredients) > 100
  ) {
    warnings.push({
      issue: (
        <>
          {INCORRECT_FORMULA_AMOUNT_WARNING.issue}{' '}
          {`current total is: ${totalAmountOfIngredients}%`}
        </>
      ),
      actionItem: (
        <>
          {INCORRECT_FORMULA_AMOUNT_WARNING.actionItem} {formulaLink}
        </>
      ),
      warningName: INCORRECT_FORMULA_AMOUNT_WARNING.warningName,
    });
  }

  return warnings;
};
