// Libraries
import React from 'react';

enum TypographyVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
}

type TypographyVariantType = `${TypographyVariant}`;

enum TypographyColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  HYPERLINK = 'hyperlink',
  DISABLED = 'disabled',
  ERROR = 'error',
  ON_DARK = 'onDark',
  WARNING = 'warning',
}

type TypographyColorType = `${TypographyColor}`;

export interface TypographyProps {
  additionalStyles?: string;
  children: React.ReactNode;
  color?: TypographyColorType;
  font?: 'agipo' | 'inter' | 'mono';
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl';
  variant: TypographyVariantType;
  weight?: 'thin' | 'extra-light' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extra-bold' | 'black';
  [key: string]: any;
}

const getDefaultFontSize = (variant: TypographyVariantType) => {
  switch (variant) {
    case TypographyVariant.H1:
      return 'text-2xl';
    case TypographyVariant.H2:
      return 'text-xl';
    case TypographyVariant.H3:
      return 'text-lg';
    case TypographyVariant.H4:
      return 'text-base';
    case TypographyVariant.H5:
      return 'text-sm';
    case TypographyVariant.H6:
      return 'text-xs';
    case TypographyVariant.P:
      return 'text-base';
    default:
      return 'text-base';
  }
};

const getTextColorStyle = (color: TypographyColorType) => {
  switch (color) {
    case TypographyColor.PRIMARY:
      return 'text-grey-20';
    case TypographyColor.SECONDARY:
      return 'text-grey-50';
    case TypographyColor.HYPERLINK:
      return 'text-royal-50';
    case TypographyColor.DISABLED:
      return 'text-grey-60';
    case TypographyColor.ERROR:
      return 'text-red-50';
    case TypographyColor.ON_DARK:
      return 'text-grey-90';
    case TypographyColor.WARNING:
      return 'text-peach-30'
    default:
      return 'text-grey-20';
  }
}

export const Typography: React.FC<TypographyProps> = ({
  additionalStyles,
  children,
  color,
  font,
  size,
  variant,
  weight,
  ...rest
}) => {
  const Tag = variant as keyof JSX.IntrinsicElements;
  const fontClass = font ? `font-${font}` : `font-agipo` ;
  const fontSize = size ? `text-${size}` : getDefaultFontSize(variant);
  const textColor = color ? getTextColorStyle(color) : getTextColorStyle(TypographyColor.PRIMARY);
  const fontWeight = weight ? `font-${weight}` : '';

  return (
    <Tag
      className={`${fontSize} ${fontClass} ${textColor} ${fontWeight} ${additionalStyles || ''}`}
      {...rest}>
        {children}
    </Tag>
  );
};
