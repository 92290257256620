// Libraries
import { useCallback } from 'react';

function debounce(
  func: (...rest: any[]) => unknown,
  wait: number,
  immediate: boolean
) {
  let timeout: any;

  return function (...rest: any[]) {
    const later = function () {
      timeout = null;
      if (!immediate) func(...rest);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func(...rest);
  };
}
export const useDebounce = (
  callback: (...rest: any[]) => unknown,
  wait: number,
  immediate: boolean,
  deps: Array<any>
) => {
  // eslint-disable-next-line
  const debouncedCallback = useCallback(
    debounce(callback, wait, immediate),
    deps
  );

  return debouncedCallback;
};
