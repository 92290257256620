// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Assets
import radioFilled from 'img/radioFilled.svg';

interface RadioButtonProps {
  inputName: string;
  title: string;
  // Sometimes the title is enough to be a value
  // when we need more than just the title (i.e. display vs actual value)
  // we can use value
  value?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  inputName,
  title,
  value,
}) => {
  const { values } = useFormikContext<any>();
  const isSelected =
    values[inputName].includes(value) || values[inputName].includes(title);

  return (
    <div
      className={`h-8 w-8 min-w-[2rem] rounded-full flex justify-items-center align-center ${
        !isSelected ? 'border border-gray' : ''
      }`}
    >
      {isSelected && <img src={radioFilled} alt="radio button" />}
    </div>
  );
};
