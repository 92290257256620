// Libraries
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
// Components
import { PageNav } from 'design-system';
// Utils
import { InternalBriefFormValues } from './form.config';

const NAV_ITEMS = [
  {
    href: '#/project-overview',
    subItems: [
      {
        label: 'Basics',
        href: '#basics',
        value: 'basics',
      },
      {
        label: 'Point of Contact',
        href: '#point-of-contact',
        value: 'point-of-contact',
      },
      {
        label: 'Product Basics',
        href: '#product-basics',
        value: 'product-basics',
      },
      {
        label: 'Fill Size',
        href: '#fill-size',
        value: 'fill-size',
      },
      {
        label: 'Initial Order Quantity',
        href: '#initial-order-quantity',
        value: 'initial-order-quantity',
      },
      {
        label: 'Target COGS',
        href: '#target-cogs',
        value: 'target-cogs',
      },
      {
        label: 'Timeline',
        href: '#timeline',
        value: 'timeline',
      },
      {
        label: 'Production Location',
        href: '#production-location',
        value: 'production-location',
      },
    ],
    label: '1. Project Overview',
    value: 'project-overview',
    //TODO: Have formik completion of section cause trailingIcon to appear
    // trailingIcon: (
    //   <span className="text-green-50">
    //     <CheckIcon />
    //   </span>
    // ),
  },
  {
    label: '2. Concept & Product',
    href: '#concept-product',
    value: 'concept-product',
    subItems: [
      {
        label: 'Product Vision',
        href: '#product-vision',
        value: 'product-vision',
      },
      {
        label: 'Benchmarks',
        href: '#benchmarks',
        value: 'benchmarks',
      },
    ],
  },
  {
    label: '3. Ingredients & Formula',
    href: '#ingredients-formula',
    value: 'ingredients-formula',
    subItems: [
      {
        label: 'Formula Specifics',
        href: '#formula-specifics',
        value: 'formula-specifics',
      },
      {
        label: 'Ingredient Notes',
        href: '#ingredient-notes',
        value: 'ingredient-notes',
      },
      {
        label: 'Fragrance',
        href: '#fragrance',
        value: 'fragrance',
      },
    ],
  },
  {
    label: '4. Claims',
    href: '#claims',
    value: 'claims',
    subItems: [
      {
        label: 'Benefits',
        href: '#benefits',
        value: 'benefits',
      },
      {
        label: 'OTC Claims',
        href: '#otc-claims',
        value: 'otc-claims',
      },
      {
        label: 'Clinical/Data Claims',
        href: '#clinical-data-claims',
        value: 'clinical-data-claims',
      },
    ],
  },
  {
    label: '5. Packaging',
    href: '#packaging',
    value: 'packaging',
    subItems: [
      {
        label: 'Components',
        href: '#components',
        value: 'components',
      },
    ],
  },
  {
    label: '6. Testing',
    href: '#testing',
    value: 'testing',
    subItems: [
      {
        label: 'Tests',
        href: '#tests',
        value: 'tests',
      },
      {
        label: 'Test Handling',
        href: '#test-handling',
        value: 'test-handling',
      },
      {
        label: 'R&D Documents',
        href: '#rd',
        value: 'rd',
      },
    ],
  },
  {
    label: '7. Regulatory',
    href: '#regulatory',
    value: 'regulatory',
    subItems: [
      {
        label: 'Statements Needed',
        href: '#statements-needed',
        value: 'statements-needed',
      },
      {
        label: 'Compliant Countries',
        href: '#compliant-countries',
        value: 'compliant-countries',
      },
    ],
  },
  {
    label: '8. Formula Samples',
    href: '#formula-samples',
    value: 'formula-samples',
    subItems: [
      {
        label: 'Sample Quantity',
        href: '#sample-quantity',
        value: 'sample-quantity',
      },
      {
        label: 'Sample Recipients',
        href: '#sample-recipients',
        value: 'sample-recipients',
      },
    ],
  },
];

interface InternalBriefNavProps {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
}

export const InternalBriefNav: React.FC<InternalBriefNavProps> = ({
  currentPage,
  setCurrentPage,
}) => {
  const [activeItem, setActiveItem] = useState(NAV_ITEMS[currentPage]);
  const { validateForm } = useFormikContext<InternalBriefFormValues>();

  useEffect(() => {
    setActiveItem(NAV_ITEMS[currentPage]);
  }, [currentPage]);

  const handleNavItemClick = (item: any) => {
    const indexToNavigateTo = NAV_ITEMS.findIndex(
      (navItem) => navItem.value === item.value
    );

    validateForm().then((errors) => {
      if (
        (errors.projectName ||
          errors.company?.value ||
          errors.approvalDate ||
          errors.cogsType ||
          errors.cogsRange ||
          errors.targetCost ||
          errors.size ||
          errors.initialDateOfBrief ||
          errors.minimumOrderQuantity ||
          errors.targetDate) &&
        indexToNavigateTo !== 0
      ) {
        alert('Please correct all errors before proceeding');
        return;
      }
      setCurrentPage(indexToNavigateTo);
      setActiveItem(item);
    });
  };

  return (
    <div className="md:h-full md:border md:border-grey-90 md:border-r-0 md:rounded-tl md:bg-grey-99">
      <PageNav
        activeItem={activeItem}
        currentPage={currentPage}
        navItems={NAV_ITEMS}
        onChange={handleNavItemClick}
        onClick={handleNavItemClick}
      />
    </div>
  );
};
