// Libraries
import React from 'react';
// Components
import { Divider } from 'design-system';
import { CountryCompliance, StatementsNeeded } from '../form-modules';

export const Regulatory: React.FC = () => (
  <>
    <StatementsNeeded />

    <Divider />

    <CountryCompliance />
  </>
);
