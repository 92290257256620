// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Select, TextField } from 'design-system';
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS, FILL_SIZE_UNITS } from '../brief.constants';

export const FillSize: React.FC = () => {
  const { errors, getFieldProps, setFieldValue } = useFormikContext<
    InternalBriefFormValues
  >();

  return (
    <div>
      <p id="fill-size" className="font-inter text-lg text-grey-20 mb-6">
        Fill Size
      </p>
      <div className="grid grid-cols-2 gap-x-6">
        <TextField
          {...getFieldProps(BRIEF_FORM_KEYS.SIZE)}
          data-testid="fill-size"
          label="Quantity"
          placeholder="Enter a number"
          error={Boolean(errors.size)}
          helperText={errors.size}
          type="number"
        />
        <Select
          {...getFieldProps(BRIEF_FORM_KEYS.UNIT)}
          onChange={(option: Option) => {
            setFieldValue(BRIEF_FORM_KEYS.UNIT, option.value);
          }}
          data-testid="fill-size-unit"
          label="Unit"
          options={[
            { label: 'g', value: FILL_SIZE_UNITS.G },
            { label: 'ml', value: FILL_SIZE_UNITS.ML },
            { label: 'oz', value: FILL_SIZE_UNITS.OZ },
          ]}
          placeholder="Select"
          error={Boolean(errors.unit)}
          helperText={errors.unit}
        />
      </div>
    </div>
  );
};
