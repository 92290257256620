import { IApiData, IngredientAttributes, PhaseAttributes } from './api.types';
import {
  Ingredient,
  PhaseWithIngredients,
} from 'features/formula/formula-page/types';
import { UNPHASED_INGREDIENTS_PHASE_ID } from 'features/formula/formula-page/constants';

const getIngredientsByPhase = (
  phaseId: string,
  ingredients?: IApiData<IngredientAttributes>[]
) => {
  if (!ingredients) return [];

  return ingredients
    .filter((inc: IApiData<IngredientAttributes>) =>
      // find ingredients based on whether there is a phase id,
      // and if not, find the ones where there is no phase id
      phaseId === UNPHASED_INGREDIENTS_PHASE_ID
        ? !inc.relationships?.phase?.data?.id
        : inc.relationships?.phase?.data?.id === phaseId
    )
    .map(
      ({
        id,
        attributes,
        attributes: {
          amount,
          highestPrice,
          rawMaterialEuIncis,
          rawMaterialName,
          rawMaterialRmId,
          rawMaterialSampleCode,
          rawMaterialUsIncis,
          rawMaterialUuid,
        },
      }: IApiData<IngredientAttributes>) => {
        return {
          ...attributes,
          id,
          euIncis: rawMaterialEuIncis,
          name: rawMaterialName,
          phaseUuid: phaseId,
          rmId: rawMaterialRmId,
          sampleCode: rawMaterialSampleCode,
          rmUuid: rawMaterialUuid,
          amount: parseFloat(amount as string),
          price: highestPrice,
          usIncis: rawMaterialUsIncis,
        } as Ingredient;
      }
    );
};

export const getIncludedPhases = (
  phases?: IApiData<PhaseAttributes>[],
  ingredients?: IApiData<IngredientAttributes>[]
) => {
  // some formulas were created before phases were added to the data model
  // this check allows them to still appear in the formulator table
  if (!phases && ingredients) {
    return [
      {
        id: UNPHASED_INGREDIENTS_PHASE_ID,
        name: 'No Phase',
        procedure: undefined,
        ingredients: getIngredientsByPhase(
          UNPHASED_INGREDIENTS_PHASE_ID,
          ingredients
        ),
      } as PhaseWithIngredients,
    ];
  }

  const deserializedPhases = phases
    ? phases?.map(
        ({ id, attributes: { name, procedure } }: IApiData<PhaseAttributes>) =>
          ({
            id,
            name,
            procedure,
            ingredients: getIngredientsByPhase(id, ingredients),
          } as PhaseWithIngredients)
      )
    : [];

  // Check to see whether there are any ingredients without phase data
  if (ingredients?.find((ing) => !ing.relationships?.phase?.data?.id)) {
    deserializedPhases.unshift({
      id: UNPHASED_INGREDIENTS_PHASE_ID,
      name: 'No Phase',
      procedure: undefined,
      ingredients: getIngredientsByPhase(
        UNPHASED_INGREDIENTS_PHASE_ID,
        ingredients
      ),
    } as PhaseWithIngredients);
  }
  return deserializedPhases;
};
