// Libraries
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
// Components
import { ClassificationSection } from './classification-section.component';
import { CompositionalBreakdownSection } from './compositional-breakdown-section.component';
import { FixedSubmitButtonBar } from 'features/ui';
import { GeneralSection } from './general-section.component';
import { MarketingInfoSection } from './marketing-info-section.component';
import { NotesSection } from './notes-section.component';
import { Page } from 'features/ui';
import { PricingSection } from './pricing-section.component';
import { RawMaterialForm } from './raw-material-form.component';
import { Scrollable } from 'features/ui';
import { VerticalFormMenu } from 'features/ui';
import { UserHistory } from 'features/ui';
// Utils
import {
  useApi,
  IApiData,
  InciAttributes,
  PriceAttributes,
  RawMaterialAttributes,
  RawMaterialInciAttributes,
  VendorAttributes,
} from 'api';
import { IRawMaterial } from '../types';
import { useSnackbar, useTitle } from 'hooks';
import { Price } from 'features/types';
// Constants
import { INCI_TYPES } from 'features/constants';

const INITIAL_SELECTED_TAB = 0;
const DEFAULT_DESTINATION_SECTION = -1;
const TAB_LABELS = [
  'General',
  'Pricing',
  'Comp Breakdown',
  'Marketing Info',
  'Classifications',
  'Notes',
];

export const getPricesFromRawMaterial = (
  prices: Maybe<IApiData<PriceAttributes>[]>,
  manufacturers: Maybe<IApiData<VendorAttributes>[]>,
  suppliers: Maybe<IApiData<VendorAttributes>[]>
): Maybe<Price[]> => {
  return prices?.map((price) => {
    const manufacturer = manufacturers?.find(
      (manufacturer) =>
        price.relationships?.manufacturer?.data?.id === manufacturer.id
    );
    const supplier = suppliers?.find(
      (supplier) => price.relationships?.supplier?.data?.id === supplier.id
    );

    return {
      ...price.attributes,
      id: price.id,
      manufacturer: manufacturer
        ? {
            id: manufacturer.id,
            ...manufacturer.attributes,
          }
        : undefined,
      supplier: supplier
        ? {
            id: supplier.id,
            ...supplier.attributes,
          }
        : undefined,
    };
  });
};

const getIncisFromRawMaterial = (
  incis: Maybe<IApiData<InciAttributes>[]>,
  rawMaterialIncis: Maybe<IApiData<RawMaterialInciAttributes>[]>
) => {
  return incis?.map((inci) => {
    const usRawMaterialInci = rawMaterialIncis?.find(
      (rmi) =>
        rmi.relationships?.inci?.data?.id === inci.id &&
        rmi.attributes.region === INCI_TYPES.US
    );

    const euRawMaterialInci = rawMaterialIncis?.find(
      (rmi) =>
        rmi.relationships?.inci?.data?.id === inci.id &&
        rmi.attributes.region === INCI_TYPES.EU
    );

    return {
      ...inci.attributes,
      id: inci.id,
      maxPercentage: euRawMaterialInci?.attributes.maxPercentage,
      minPercentage: euRawMaterialInci?.attributes.minPercentage,
      concentrationLevel: euRawMaterialInci?.attributes.concentrationLevel,
      usAmount: parseFloat(
        (usRawMaterialInci?.attributes.amount as string) || '0'
      ),
      euAmount: parseFloat(
        (euRawMaterialInci?.attributes.amount as string) || '0'
      ),
      incidental: euRawMaterialInci?.attributes.incidental,
      allergen: euRawMaterialInci?.attributes.allergen,
      preservative: euRawMaterialInci?.attributes.preservative,
      usApiDrugName: usRawMaterialInci?.attributes.usApiDrugName,
      usApiMin: usRawMaterialInci?.attributes.usApiMin,
      usApiMax: usRawMaterialInci?.attributes.usApiMax,
      usApiPurityFactor: usRawMaterialInci?.attributes.usApiPurityFactor,
    };
  });
};

const getUsApiFromRawMaterial = (
  rawMaterialIncis: Maybe<IApiData<RawMaterialInciAttributes>[]>
) => {
  const foundRMI = rawMaterialIncis?.find(
    (rmi) => rmi.attributes.usApiDrugName
  );

  if (!foundRMI) return {};

  const {
    usApiDrugName,
    usApiMax,
    usApiMin,
    usApiPurityFactor,
  } = foundRMI?.attributes;

  return {
    activeInciId: foundRMI?.relationships.inci.data.id || '',
    usApiDrugName,
    usApiMax,
    usApiMin,
    usApiPurityFactor,
  };
};

export const RawMaterialPage: React.FC = () => {
  const { getRawMaterial } = useApi();
  const navigate = useNavigate();
  const { uuid }: { uuid?: string } = useParams();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const { success }: { success: boolean } = location.state || {
    success: false,
  };

  // STATE FOR RAW MATERIAL
  const [rawMaterial, setRawMaterial] = useState<Maybe<IRawMaterial>>(
    undefined
  );

  useTitle(rawMaterial?.name);

  useEffect(() => {
    if (uuid) {
      getRawMaterial({
        urlParams: uuid,
        handleSuccess: (data: any) => {
          const attributes: RawMaterialAttributes = data.attributes;
          const usApiAttributes = getUsApiFromRawMaterial(
            data.rawMaterialIncis
          );

          setRawMaterial({
            ...attributes,
            ...data.classification?.attributes,
            prices: getPricesFromRawMaterial(
              data.prices,
              data.manufacturers,
              data.suppliers
            ),
            incis: getIncisFromRawMaterial(data.incis, data.rawMaterialIncis),
            ...(usApiAttributes || {}),
            createdBy: data.createdBy?.attributes?.fullName,
            updatedBy: data.updatedBy?.attributes?.fullName,
          });
          success &&
            showSnackbar('Raw Material created successfully!', 'success');
        },
      });
    } else {
      setRawMaterial(undefined);
    }
  }, [uuid, showSnackbar, success, getRawMaterial]);

  // STATE FOR VERTICAL MENU
  const [destinationSection, setDestinationSection] = useState<number>(
    DEFAULT_DESTINATION_SECTION
  );
  const [destinationRef, setDestinationRef] = useState<any>();
  const [currentTab, setCurrentTab] = useState<number>(INITIAL_SELECTED_TAB);
  const [isAutoScrolling, setIsAutoScrolling] = useState<boolean>(false);

  useEffect(() => {
    destinationRef?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setTimeout(() => {
      setDestinationSection(DEFAULT_DESTINATION_SECTION);
      setIsAutoScrolling(false);
    }, 500);
  }, [destinationRef]);

  const FORM_SECTIONS = [
    <GeneralSection />,
    <PricingSection />,
    <CompositionalBreakdownSection rawMaterialUuid={uuid} />,
    <MarketingInfoSection />,
    <ClassificationSection />,
    <NotesSection />,
  ];

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Page>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {uuid ? 'Edit' : 'Add'} a Raw Material
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="column"
          alignItems="flex-start"
          style={{ paddingTop: '0', paddingBottom: '0', marginBottom: '30px' }}
        >
          <UserHistory
            createdBy={rawMaterial?.createdBy}
            createdAt={rawMaterial?.createdAt}
            updatedBy={rawMaterial?.updatedBy}
            updatedAt={rawMaterial?.updatedAt}
          />
        </Grid>
      </Grid>
      <Grid container item xs={3}>
        <VerticalFormMenu
          currentTab={currentTab}
          setIsAutoScrolling={setIsAutoScrolling}
          setDestinationSection={setDestinationSection}
          tabLabels={TAB_LABELS}
        />
      </Grid>
      <Grid container item xs={9}>
        <RawMaterialForm
          rawMaterial={rawMaterial}
          setRawMaterial={setRawMaterial}
        >
          <>
            <Grid container item xs={11} style={{ marginBottom: '30rem' }}>
              {FORM_SECTIONS.map((Section, index) => (
                <Scrollable
                  key={index}
                  setDestinationRef={setDestinationRef}
                  isDestinationSection={index === destinationSection}
                  isAutoScrolling={isAutoScrolling}
                  setIsAutoScrolling={setIsAutoScrolling}
                  index={index}
                  destinationSection={destinationSection}
                  setDestinationSection={setDestinationSection}
                  setCurrentTab={setCurrentTab}
                >
                  {Section}
                </Scrollable>
              ))}
            </Grid>
            <FixedSubmitButtonBar onCancel={onCancel} />
          </>
        </RawMaterialForm>
      </Grid>
    </Page>
  );
};
