// Libraries
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
// Components
import { Button, Modal, RadioButton, Select } from 'design-system';
// Utils
import { useApi, IApiData, FormulaAttributes } from 'api';

interface NewPriceQuoteModalProps {
  handleClose: () => void;
  projectUuid: string;
}

const QUOTE_TYPES = {
  PRELIMINARY: 'preliminary',
  FINAL: 'final',
};

const FORM_VALUES = {
  FORMULA: 'formula',
  QUOTE_TYPE: 'quoteType',
};

const initialValues = {
  formula: '',
  quoteType: QUOTE_TYPES.PRELIMINARY,
};

export const NewPriceQuoteModal: React.FC<NewPriceQuoteModalProps> = ({
  handleClose,
  projectUuid,
}) => {
  const { getProjectFormulas, postProjectWorksheet } = useApi();
  const [formulas, setFormulas] = useState<any>([]);

  useEffect(() => {
    getProjectFormulas({
      urlParams: `${projectUuid}/formulas`,
      handleSuccess: ({ data }: { data: IApiData<FormulaAttributes>[] }) => {
        setFormulas(data);
      },
    });
  }, [getProjectFormulas, projectUuid]);

  const renderOptions = formulas.map(
    (formula: IApiData<FormulaAttributes>) => ({
      value: formula.id,
      label: formula.attributes.name,
    })
  );

  return (
    <Modal handleClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) =>
          postProjectWorksheet({
            urlParams: `${projectUuid}/worksheets`,
            data: {
              worksheet: {
                formulaUuid: values.formula,
                quoteType: values.quoteType,
              },
            },
          })
        }
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="flex flex-col font-inter">
              <div className="flex flex-col gap-3 p-6 bg-white ">
                <span className="font-bold">New Quote</span>
                <div className="flex gap-6">
                  <RadioButton
                    value={QUOTE_TYPES.PRELIMINARY}
                    name={FORM_VALUES.QUOTE_TYPE}
                    primaryLabel="Preliminary Quote"
                    checked={props.values.quoteType === QUOTE_TYPES.PRELIMINARY}
                    onChange={() =>
                      props.setFieldValue(
                        FORM_VALUES.QUOTE_TYPE,
                        QUOTE_TYPES.PRELIMINARY
                      )
                    }
                  />
                  <RadioButton
                    value={QUOTE_TYPES.FINAL}
                    name={FORM_VALUES.QUOTE_TYPE}
                    primaryLabel="Final Quote"
                    checked={props.values.quoteType === QUOTE_TYPES.FINAL}
                    onChange={() =>
                      props.setFieldValue(
                        FORM_VALUES.QUOTE_TYPE,
                        QUOTE_TYPES.FINAL
                      )
                    }
                  />
                </div>
                <Select
                  label="Formula"
                  options={renderOptions}
                  onChange={(option: any) =>
                    props.setFieldValue(FORM_VALUES.FORMULA, option.value)
                  }
                  value={props.values.formula}
                />
              </div>
              <div className="px-6 py-3 border-t border-grey-90">
                <div className="flex gap-2 justify-end">
                  <Button
                    type="secondary"
                    onClick={handleClose}
                    text="Cancel"
                  />
                  <Button
                    onClick={props.submitForm}
                    type="primary"
                    text="Create"
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
