// Libraries
import React, { useContext, useEffect, useState } from 'react';
// Components
import { AddBatchDialog } from './add-batch-dialog.component';
import { Loader } from 'features/pricing-quote';
import { SpecificationsForm } from './specifications-form.component';
import { TabPanel, TabbedMenu } from 'features/ui';
import { FormulaContext } from '../context';
// Assets
import AddIcon from '@material-ui/icons/Add';
// Utils
import { THEME_PALETTE } from 'styles/mui/themeV2';
import { useApi, BatchAttributes, IApiData } from 'api';

const ADD_BATCH = 'Add Batch';

export const Specs: React.FC = () => {
  const { getFormulaBatches } = useApi();
  const { formula } = useContext(FormulaContext);
  const [batches, setBatches] = useState<IApiData<BatchAttributes>[]>([]);
  const [isLoadingBatches, setIsLoadingBatches] = useState<boolean>(true);

  useEffect(() => {
    getFormulaBatches({
      urlParams: `/${formula!.id}/batches`,
      handleSuccess: (resp) => {
        setBatches(
          resp.data.sort(
            (a: IApiData<BatchAttributes>, b: IApiData<BatchAttributes>) => {
              const dateA: any = new Date(a.attributes.createdAt);
              const dateB: any = new Date(b.attributes.createdAt);

              return dateA - dateB;
            }
          )
        );
      },
      handleFinally: () => setIsLoadingBatches(false),
    });
  }, [getFormulaBatches, formula]);

  const getBatchTabArray = () => {
    return batches.map((_batch, index) => {
      return {
        label: `Batch ${index + 1}`,
      };
    });
  };

  const tabs = [
    ...getBatchTabArray(),
    {
      label: ADD_BATCH,
      icon: <AddIcon />,
    },
  ];

  const [isAddBatchDialogOpen, setIsAddBatchDialogOpen] = useState<boolean>(
    false
  );

  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    setSelectedTab(batches.length - 1);
  }, [batches.length]);

  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    selectedTab: number
  ) => {
    if (tabs[selectedTab].label === ADD_BATCH) {
      setIsAddBatchDialogOpen(true);
    } else {
      setSelectedTab(selectedTab);
    }
  };

  const renderBatchTabPanels = () => {
    return batches.map((batch, index) => {
      return (
        <TabPanel key={index} index={index} value={selectedTab}>
          <SpecificationsForm batch={batch} setBatches={setBatches} />
        </TabPanel>
      );
    });
  };

  return (
    <Loader isLoading={isLoadingBatches}>
      <>
        <AddBatchDialog
          batches={batches}
          formulaId={formula!.id}
          isOpen={isAddBatchDialogOpen}
          handleClose={() => setIsAddBatchDialogOpen(false)}
          setBatches={setBatches}
        />
        <TabbedMenu
          backgroundColor={THEME_PALETTE.blue.main}
          color={THEME_PALETTE.gray.dark}
          tabValue={selectedTab}
          handleTabChange={handleTabChange}
          indicatorColor={THEME_PALETTE.gray.dark}
          tabs={tabs}
        />
        {renderBatchTabPanels()}
      </>
    </Loader>
  );
};
