import React from 'react';
import { Formik } from 'formik';
import { dummyIngredients, WORKSHEET_FORM_KEYS } from './constants';

interface WorksheetFormProps {
  children: React.ReactNode | React.ReactNode[];
  worksheetData: any;
}

export const WorksheetForm: React.FC<WorksheetFormProps> = ({ children }) => {
  const initialValues = {
    [WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_ONE]: 0,
    [WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_TWO]: 0,
    [WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_THREE]: 0,
    [WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_ONE]: 0,
    [WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_TWO]: 0,
    [WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_THREE]: 0,
    [WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_ONE]: 0,
    [WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_TWO]: 0,
    [WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_THREE]: 0,
    [WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_ONE]: 0,
    [WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_TWO]: 0,
    [WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_THREE]: 0,
    [WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_ONE]: 0,
    [WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_TWO]: 0,
    [WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_THREE]: 0,
    [WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_ONE]: 0,
    [WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_TWO]: 0,
    [WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_THREE]: 0,
    [WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_ONE]: 0,
    [WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_TWO]: 0,
    [WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_THREE]: 0,
    [WORKSHEET_FORM_KEYS.RUN_RATE_ONE]: 0,
    [WORKSHEET_FORM_KEYS.RUN_RATE_TWO]: 0,
    [WORKSHEET_FORM_KEYS.RUN_RATE_THREE]: 0,
    [WORKSHEET_FORM_KEYS.PROFIT_ONE]: 0,
    [WORKSHEET_FORM_KEYS.PROFIT_TWO]: 0,
    [WORKSHEET_FORM_KEYS.PROFIT_THREE]: 0,
    [WORKSHEET_FORM_KEYS.MASTER_BOX_ONE]: 0,
    [WORKSHEET_FORM_KEYS.MASTER_BOX_TWO]: 0,
    [WORKSHEET_FORM_KEYS.MASTER_BOX_THREE]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_A]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_A]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_A]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_B]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_B]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_B]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_C]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_C]: 0,
    [WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_C]: 0,
    [WORKSHEET_FORM_KEYS.ORDER_QUANTITY_ONE]: 10000,
    [WORKSHEET_FORM_KEYS.ORDER_QUANTITY_TWO]: 20000,
    [WORKSHEET_FORM_KEYS.ORDER_QUANTITY_THREE]: 30000,
    [WORKSHEET_FORM_KEYS.INGREDIENTS]: dummyIngredients,
  };
  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {children}
    </Formik>
  );
};
