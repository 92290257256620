// Libraries
import React, { useContext, useState } from 'react';
import { DropResult, DragDropContext } from '@hello-pangea/dnd';
import { makeStyles, createStyles, Table } from '@material-ui/core';
// Components
import { AddPhaseRow } from './add-phase-row.component';
import { PhaseRow } from './phase-row.component';
import { IngredientsTableHeaderRow } from './ingredients-table-header-row.component';
import { IngredientsTableSubHeaderRow } from './ingredients-table-sub-header-row.component';
import { IngredientsTableRow } from './ingredients-table-row.component';
import { useSnackbar } from 'hooks';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { Ingredient, PhaseWithIngredients } from './types';
import { FormulaContext } from '../context';
import { BatchQuantityContext } from '../context';
import { calculatePricePercentageOfTotal, parseCostFromPrice } from './utils';
import { useApi, IApiData, PhaseAttributes } from 'api';
// Constants
import {
  INGREDIENTS_TABLE_COLUMNS,
  UNPHASED_INGREDIENTS_PHASE_ID,
  UNITS,
} from './constants';

const UNPHASED_IS_INCLUDED = true;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    table: {
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.gray.light}`,
      marginBottom: theme.spacing(16),
    },
  })
);

export const IngredientsTable: React.FC = () => {
  const { formula, setFormula } = useContext(FormulaContext);
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const { deletePhase, patchIngredient } = useApi();

  const [deletedPhaseId, setDeletedPhaseId] = useState<Maybe<string>>(
    undefined
  );
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const [batchQuantity, setBatchQuantity] = useState<number>(0.0);
  const [selectedPricingUnit, setSelectedPricingUnit] = useState<string>(
    UNITS.LB
  );

  if (!formula) return null;

  // A helper for return all ingredients as a flat array rather than being nested inside
  // of their phases
  const flattenIngredientsWithinPhases = (isUnphasedIncluded?: boolean) => {
    return formula?.phases
      ?.filter(
        (phase) =>
          isUnphasedIncluded || phase.id !== UNPHASED_INGREDIENTS_PHASE_ID
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .flatMap((phase) => phase.ingredients);
  };

  // A helper that makes sure the position of ingredients are always
  // displayed in proper order on the DOM
  const renderIngredientsInOrder = (ingredients: Ingredient[]) => {
    ingredients.forEach((ing, idx) => {
      ing.position = idx + 1;
    });

    if (ingredients.length) {
      setFormula({
        ...formula,
      });
    }
  };

  const unphasedIngredientsPhase = formula.phases.find(
    (phase) => phase.id === UNPHASED_INGREDIENTS_PHASE_ID
  );

  const handleDeletePhase = (phaseToDelete: PhaseWithIngredients) => {
    setDeletedPhaseId(phaseToDelete.id);
    deletePhase({
      urlParams: phaseToDelete.id,
      handleSuccess: ({ data }: { data: IApiData<PhaseAttributes>[] }) => {
        const phaseToDeleteIndex = formula.phases.findIndex(
          (phase) => phase.id === phaseToDelete.id
        );
        formula.phases.splice(phaseToDeleteIndex, 1);
        formula.phases.forEach((phase) => {
          const foundPhase = data.find(
            (phaseDatum: IApiData<PhaseAttributes>) =>
              phaseDatum.id === phase.id
          );
          phase.name = foundPhase!.attributes.name;
        });
        setFormula({
          ...formula,
        });
        showSnackbar('Phase successfully deleted', 'success');
      },
      handleFinally: () => setDeletedPhaseId(undefined),
    });
  };

  const handleIngredientUpdate = (
    ingredientIndex: number,
    phaseIndex: number
  ) => {
    return async (row: any) => {
      const phaseToUpdate = formula.phases[phaseIndex];
      const ingredientToUpdate = phaseToUpdate.ingredients[ingredientIndex];
      const { active, phase, lot, amount, usApiDrugPurposes } = row;

      patchIngredient({
        urlParams: ingredientToUpdate.id,
        data: {
          ingredient: {
            active,
            amount,
            phase,
            lotNumber: lot,
            usApiDrugPurposes,
          },
        },
        handleSuccess: () => {
          const phaseCopy = [...formula.phases];
          const ingredientsCopy = [...phaseToUpdate.ingredients];
          ingredientsCopy.splice(ingredientIndex, 1, {
            ...ingredientToUpdate,
            active,
            amount,
            phase,
            lot,
            usApiDrugPurposes,
          });
          phaseCopy.splice(phaseIndex, 1, {
            ...phaseToUpdate,
            ingredients: ingredientsCopy,
          });

          setFormula({
            ...formula,
            phases: phaseCopy,
          });
          showSnackbar(
            `${ingredientToUpdate.name} successfully updated`,
            'success'
          );
        },
      });
    };
  };

  const handleDeleteIngredient = (
    ingredientIndex: number,
    phase?: PhaseWithIngredients
  ) => {
    // This is pessimistically rendered as the network deletion already has come
    // back with a success response in the component below
    phase?.ingredients.splice(ingredientIndex, 1);
    const ingredients = flattenIngredientsWithinPhases(UNPHASED_IS_INCLUDED);
    renderIngredientsInOrder(ingredients);
  };

  const updateIngredientPosition = async (ingredient: Ingredient) => {
    return patchIngredient({
      urlParams: ingredient.id,
      data: {
        ingredient: {
          phaseUuid: ingredient.phaseUuid,
          position: ingredient.position,
        },
      },
      handleSuccess: () => setFormula({ ...formula }),
    });
  };

  // physically changes where the ing object appears in the ingredients array
  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    // Don't allow users to move ingredients into nameless phase
    if (result.destination.droppableId === UNPHASED_INGREDIENTS_PHASE_ID)
      return;

    const { source, destination } = result;

    // If phase is the same, but position within phase is different
    if (source.droppableId === destination.droppableId) {
      const foundPhase = formula.phases.find(
        (phase) => phase.id === destination.droppableId
      )!;

      // Remove the ingredient from that phases array of ingredients
      const [ingredient] = foundPhase.ingredients.splice(source.index, 1);

      // Move it to the new position in the array, so that when it renders the list
      // its in the right physical order
      foundPhase.ingredients.splice(destination.index, 0, ingredient);

      // Optimistically render the new positions
      const ingredients = flattenIngredientsWithinPhases(UNPHASED_IS_INCLUDED);
      renderIngredientsInOrder(ingredients);

      // Update the position of the ingredient in the database
      updateIngredientPosition(ingredient);
    } else {
      const foundDestinationPhase = formula.phases.find(
        (phase) => phase.id === destination.droppableId
      )!;
      const foundSourcePhase = formula.phases.find(
        (phase) => phase.id === source.droppableId
      )!;

      // Remove the ingredient from its old phase
      const [ingredient] = foundSourcePhase.ingredients.splice(source.index, 1);
      // Add it to the destination phase and update its phaseUuid
      foundDestinationPhase.ingredients.splice(
        destination.index,
        0,
        ingredient
      );
      ingredient.phaseUuid = foundDestinationPhase.id;

      // Optimistically render ingredients with new positions
      const ingredients = flattenIngredientsWithinPhases(UNPHASED_IS_INCLUDED);
      renderIngredientsInOrder(ingredients);

      // Update its position in the database
      updateIngredientPosition(ingredient).then(() => {
        const isSourcePhaseEmpty = foundSourcePhase.ingredients.length <= 0;
        const isSourcePhaseUnPhased =
          foundSourcePhase.id === UNPHASED_INGREDIENTS_PHASE_ID;

        // ask the user if they want to delete the phase when its empty
        if (
          isSourcePhaseEmpty &&
          !isSourcePhaseUnPhased &&
          window.confirm(`Delete Phase ${foundSourcePhase.name}?`)
        ) {
          handleDeletePhase(foundSourcePhase);
        }

        // When the nameless phase is empty, remove it
        if (isSourcePhaseEmpty && isSourcePhaseUnPhased) {
          formula.phases.splice(formula.phases.indexOf(foundSourcePhase), 1);
          setFormula({ ...formula });
        }
      });
    }
  };

  const getTotalCostOfFormula = () => {
    return flattenIngredientsWithinPhases(UNPHASED_IS_INCLUDED)?.reduce(
      (acc, ingredient) => {
        acc += ingredient?.price
          ? parseCostFromPrice(ingredient.price, selectedPricingUnit) *
            (ingredient.amount / 100)
          : 0;
        return acc;
      },
      0
    );
  };

  const totalCost = getTotalCostOfFormula();

  const renderIngredients = (
    ingredients: Ingredient[],
    phase: PhaseWithIngredients,
    phaseIndex: number
  ) => {
    return ingredients.map((ingredient, ingredientIndex) => {
      return (
        <IngredientsTableRow
          batchQuantity={batchQuantity}
          key={`${ingredient.name}-${ingredientIndex}`}
          removeIngredientFromTable={() =>
            handleDeleteIngredient(ingredientIndex, phase)
          }
          updateIngredient={handleIngredientUpdate(ingredientIndex, phaseIndex)}
          ingredient={ingredient}
          ingredientIndex={ingredientIndex}
          isLocked={formula.locked}
          isQs={false}
          percentageOfTotal={calculatePricePercentageOfTotal(
            ingredient,
            selectedPricingUnit,
            totalCost
          )}
          phaseIndex={phaseIndex}
          position={ingredient.position}
          selectedPricingUnit={selectedPricingUnit}
          index={ingredientIndex}
          isAllExpanded={isAllExpanded}
        />
      );
    });
  };

  const renderPhase = (
    phase: PhaseWithIngredients,
    isUnphasedRow: boolean,
    phaseIndex: number
  ) => {
    return (
      <PhaseRow
        isLocked={formula.locked}
        key={phase.name}
        isDeleting={phase.id === deletedPhaseId}
        phase={phase}
        handleDeletePhase={handleDeletePhase}
        isUnphasedRow={isUnphasedRow}
      >
        {renderIngredients(phase.ingredients, phase, phaseIndex)}
      </PhaseRow>
    );
  };

  const renderTableItems = () => {
    return (
      <>
        {unphasedIngredientsPhase &&
          unphasedIngredientsPhase?.ingredients.length > 0 &&
          renderPhase(
            unphasedIngredientsPhase,
            true,
            formula.phases.indexOf(unphasedIngredientsPhase)
          )}
        {formula?.phases
          .filter((phase) => phase.id !== UNPHASED_INGREDIENTS_PHASE_ID)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((phase, phaseIndex) => {
            return renderPhase(phase, false, phaseIndex);
          })}
      </>
    );
  };

  // for handling the expand all accordion btn in the sub header
  const handleExpandAll = () => {
    setIsAllExpanded(!isAllExpanded);
  };

  return (
    <Table className={classes.table}>
      <IngredientsTableHeaderRow tableColumns={INGREDIENTS_TABLE_COLUMNS} />
      <BatchQuantityContext.Provider
        value={{ batchQuantity, setBatchQuantity }}
      >
        <IngredientsTableSubHeaderRow
          ingredients={flattenIngredientsWithinPhases().concat(
            unphasedIngredientsPhase ? unphasedIngredientsPhase.ingredients : []
          )}
          selectedPricingUnit={selectedPricingUnit}
          setSelectedPricingUnit={setSelectedPricingUnit}
          handleExpandAll={handleExpandAll}
          isAllExpanded={isAllExpanded}
          isLocked={formula.locked}
        />
        <DragDropContext onDragEnd={handleOnDragEnd}>
          {renderTableItems()}
        </DragDropContext>
      </BatchQuantityContext.Provider>
      <AddPhaseRow />
    </Table>
  );
};
