// Libraries
import React, { useContext } from 'react';
// Components
import { LeftNavContext } from 'context/left-nav-context';

interface IPage {
  children: React.ReactNode[] | React.ReactNode;
}

export const Page: React.FC<IPage> = ({ children }) => {
  const { isLeftNavOpen } = useContext(LeftNavContext);
  // This offset is used to adjust the left margin of the page content, depending on the left-nav's state when we are at a large, or bigger breakpoint
  const leftNavOffset = isLeftNavOpen ? 'lg:ml-[304px]' : 'lg:ml-[112px]';

  return (
    <div
      className={`h-[auto] relative grid lg:grid-cols-12 md:grid-cols-6 grid-cols-2 lg:mr-16 md:ml-20 md:mr-8 mx-6 ${leftNavOffset}`}
    >
      {children}
    </div>
  );
};
