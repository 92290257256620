//Libraries
import React from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
//Utils
import { RawMaterialSearch } from './raw-material-search.component';
import { ROUTES } from 'features/navigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  })
);

export const RawMaterialsDictionary: React.FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const sendToCreateRawMaterialPage = () =>
    navigate(ROUTES.CREATE_RAW_MATERIAL.route);

  const sendToCreateInciPage = () => navigate(ROUTES.CREATE_INCI.route);

  return (
    <Container className={classes.root} maxWidth="md">
      <Grid container alignItems="center">
        <Grid item xs={12} sm={7}>
          <Typography variant="h1" align="left">
            Raw Material Dictionary
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={5} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={sendToCreateRawMaterialPage}
            style={{ marginRight: 12 }}
          >
            Add raw material
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={sendToCreateInciPage}
          >
            Add INCI
          </Button>
        </Grid>
      </Grid>
      <br />
      <RawMaterialSearch />
    </Container>
  );
};
