// Libraries
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
// Components
import { Autocomplete, Button, Chip, Modal } from 'design-system';
// Utils
import { useApi } from 'api';
import { ArrowRightIcon } from '@heroicons/react/16/solid';

interface CreateNewCatalogueEntryModalProps {
  filterChips: Option[];
  handleClose: () => void;
}

interface FormValues {
  formulaNumber: Option;
  formulaType: string;
}

const FORM_FIELD_NAMES = {
  FORMULA_NUMBER: 'formulaNumber',
  FORMULA_TYPE: 'formulaType',
};

export const CreateNewCatalogueEntryModal: React.FC<CreateNewCatalogueEntryModalProps> = ({
  filterChips,
  handleClose,
}) => {
  const [selectedFormula, setSelectedFormula] = useState<any>(null);

  const { getFormula, searchFormulas } = useApi();

  const formik = useFormik<FormValues>({
    initialValues: {
      formulaNumber: { label: '', value: '' },
      formulaType: '',
    },
    validationSchema: Yup.object({
      formulaNumber: Yup.object().shape({
        label: Yup.string().min(1).required('Please select a formula'),
        value: Yup.string().min(1).required('Please select a formula'),
      }),
      formulaType: Yup.string().required('Please select a formula type'),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  console.log(formik.values);

  useEffect(() => {
    if (formik.values.formulaNumber.value) {
      getFormula({
        urlParams: formik.values.formulaNumber.value,
        handleSuccess: (data) => {
          setSelectedFormula(data);
        },
      });
    }
  }, [getFormula, formik.values.formulaNumber]);

  const formulaAlreadyHasRtlCatalogueEntry =
    selectedFormula?.relationships.rtlCatalogueEntry;

  return (
    <Modal handleClose={handleClose}>
      <form>
        <div className="flex flex-col gap-6 font-inter p-6 pb-9">
          <span className="font-semibold">New RTL Formula</span>
          <div className="flex flex-col">
            <Autocomplete
              clearField={() =>
                formik.setFieldValue(FORM_FIELD_NAMES.FORMULA_NUMBER, '')
              }
              label="Formula Number"
              onChange={(option: Option) => {
                formik.setFieldValue(FORM_FIELD_NAMES.FORMULA_NUMBER, option);
              }}
              placeholder="Select a base formula #"
              responseParser={(response) =>
                response?.results.map((result: any) => ({
                  label: result._source.external_id,
                  value: result._source.uuid,
                }))
              }
              search={searchFormulas}
              urlParams={'&filters=externalId'}
              value={formik.values.formulaNumber}
            />
            <span className="text-xs text-red-50 -mt-3">
              {formik.errors.formulaNumber?.value}
            </span>
          </div>
          {formik.values.formulaNumber.value &&
            selectedFormula &&
            formulaAlreadyHasRtlCatalogueEntry && (
              <div className="flex flex-col gap-3 p-3 text-peach-30 bg-yellow-95">
                <div className="flex items-center gap-2">
                  <ExclamationTriangleIcon className="h-4 w-4 text-peach-60" />
                  <span className="font-semibold text-sm">
                    Base formula already in use
                  </span>
                </div>
                <span className="px-6">
                  The same base formula can be attached to more than 1 RTL
                  Formula entry, but be sure that's your intention.
                </span>
              </div>
            )}
          <div className="flex flex-col gap-3">
            <span className="font-semibold text-xs">This formula is:</span>
            <div className="flex gap-3">
              {filterChips.map((chip: any) => {
                return (
                  <Chip
                    key={chip.value}
                    bgColor="bg-grey-95"
                    label={chip.label}
                    onClick={() =>
                      formik.setFieldValue(
                        FORM_FIELD_NAMES.FORMULA_TYPE,
                        chip.value
                      )
                    }
                    selected={chip.value === formik.values.formulaType}
                    selectedBgColor="bg-royal-95"
                    selectedTextColor="text-royal-40"
                    textColor="text-grey-40"
                    value={formik.values.formulaType}
                  />
                );
              })}
            </div>
            <span className="text-xs text-red-50">
              {formik.errors.formulaType}
            </span>
          </div>
        </div>
        <div className="flex justify-end gap-3 p-4 border-t border-grey-90">
          <Button onClick={handleClose} text="Cancel" type="secondary" />
          <Button
            onClick={formik.handleSubmit}
            type="primary"
            text="Next"
            trailingIcon={<ArrowRightIcon />}
          />
        </div>
      </form>
    </Modal>
  );
};
