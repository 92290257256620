// Libraries
import React, { useEffect } from 'react';
import { Formik, FormikHelpers } from 'formik';
// Components
import { BottomBar, Button, Divider, Typography } from 'design-system';
import {
  BasicInfo,
  MaterialResponsibility,
  Overview,
  Price,
  Terms,
} from './form-modules';
// Utils
import { PriceQuoteFormValues } from './form.config';
import { useSnackbar } from 'hooks';
import { priceQuoteValidationSchema } from '../validations';
// Assets
import { ReactComponent as PmLogo } from '../../../img/pm-logo-black.svg';
import {
  CompanyAttributes,
  IApiData,
  PriceQuoteAttributes,
  PriceQuoteType,
  TaskAttributes,
  useApi,
} from 'api';
import { DocumentIcon } from '@heroicons/react/24/outline';
// Constants
import {
  INITIAL_PRICE_QUOTE_FORM_VALUES,
  PRICE_QUOTE_FORM_KEYS,
  PRICE_QUOTE_TASK_STATUSES,
  PRICE_QUOTE_TYPE,
  PRICE_SIZE_UNITS,
} from '../price-quote.constants';
import { TASK_COLUMN_IDS } from 'features/home/customer-portal/constants';

interface PriceQuoteModalProps {
  quoteType: PriceQuoteType;
  company?: Maybe<IApiData<CompanyAttributes>>;
  projectId?: string;
  handleClose: () => void;
  priceQuote?: Maybe<IApiData<PriceQuoteAttributes>>;
  setProject: (project: any) => void;
  tasks?: Maybe<Array<IApiData<TaskAttributes>>>;
}

const generateQuoteVersionNumber = (
  company: Maybe<IApiData<CompanyAttributes>>,
  quoteType: PriceQuoteType
) => {
  if (!company) {
    throw new Error('Company is required to generate quote version number');
  }

  const typeIdentifier = quoteType === PriceQuoteType.preliminary ? 'P' : 'F';
  const companyInitials = company.attributes.name
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');
  const date = new Date().toLocaleDateString().replace(/\//g, '');
  const uuidFragment = crypto.randomUUID().split('-')[0];
  return `${typeIdentifier}PQ-${companyInitials}-${date}-${uuidFragment}`;
};

export const PriceQuoteModal: React.FC<PriceQuoteModalProps> = ({
  quoteType,
  company,
  projectId,
  handleClose,
  priceQuote,
  setProject,
  tasks,
}) => {
  const {
    postProjectWorksheet,
    postProjectWorksheetPriceQuote,
    patchProjectWorksheetPriceQuote,
    shareProjectWorksheetPriceQuoteWithCustomer,
  } = useApi();
  // imports
  const { showSnackbar } = useSnackbar();

  // states
  const [quoteIsEditable, setQuoteIsEditable] = React.useState<boolean>(true);

  // useEffects
  useEffect(() => {
    // if this is a new form then it is editable
    if (!priceQuote) return;

    // if this is a final price quote and the final quote task has any status (meaning it was shared) then it is not editable
    if (priceQuote?.attributes.quoteType === PriceQuoteType.final) {
      const finalQuoteTaskStatus = tasks?.find(
        (task) =>
          task.attributes.mondayColumnId ===
          TASK_COLUMN_IDS.FINAL_QUOTE_APPROVED
      )?.attributes.status;
      if (!finalQuoteTaskStatus) {
        setQuoteIsEditable(false);
      } else {
        setQuoteIsEditable(true);
      }
    }

    if (priceQuote?.attributes.quoteType === PriceQuoteType.preliminary) {
      const preliminaryQuoteTaskStatus = tasks?.find(
        (task) =>
          task.attributes.mondayColumnId ===
          TASK_COLUMN_IDS.PRELIM_QUOTE_APPROVED
      )?.attributes.status;
      if (
        preliminaryQuoteTaskStatus === PRICE_QUOTE_TASK_STATUSES.pending ||
        preliminaryQuoteTaskStatus === PRICE_QUOTE_TASK_STATUSES.done ||
        preliminaryQuoteTaskStatus === PRICE_QUOTE_TASK_STATUSES.waived
      ) {
        setQuoteIsEditable(false);
      } else {
        setQuoteIsEditable(true);
      }
    }
  }, [priceQuote, tasks]);

  // functions
  const parsedNum = (num: Maybe<number | string>) => {
    if (!num) return num;
    return typeof num === 'number' ? num : parseInt(num.replace(/,/g, ''));
  };

  const handleSubmit = (
    values: PriceQuoteFormValues,
    { setSubmitting }: FormikHelpers<PriceQuoteFormValues>
  ) => {
    const {
      personalNote,
      quoteVersionNumber,
      quoteDate,
      validFor,
      paymentTerms,
      incoterms,
      deliveryTerms,
      formulaDescription,
      moqOne,
      moqTwo,
      moqThree,
      sizeOneQuantity,
      sizeOneUnit,
      sizeOneDescription,
      sizeOneMoqOneTotalCostPerUnit,
      sizeOneMoqTwoTotalCostPerUnit,
      sizeOneMoqThreeTotalCostPerUnit,
      sizeTwoQuantity,
      sizeTwoUnit,
      sizeTwoDescription,
      sizeTwoMoqOneTotalCostPerUnit,
      sizeTwoMoqTwoTotalCostPerUnit,
      sizeTwoMoqThreeTotalCostPerUnit,
      sizeThreeQuantity,
      sizeThreeUnit,
      sizeThreeDescription,
      sizeThreeMoqOneTotalCostPerUnit,
      sizeThreeMoqTwoTotalCostPerUnit,
      sizeThreeMoqThreeTotalCostPerUnit,
      pricingNotes,
      materialsNote,
      rawMaterialResponsibility,
      containersResponsibility,
      decorationResponsibility,
      closureResponsibility,
      masterResponsibility,
      formulaUuid,
    } = values;
    setSubmitting(true);

    if (priceQuote) {
      // Update price quote
      patchProjectWorksheetPriceQuote({
        urlParams: `${projectId}/worksheets/${priceQuote.relationships.worksheet.data.id}/price_quotes/${priceQuote.id}`,
        data: {
          priceQuote: {
            quoteType: quoteType,
            personalNote,
            quoteVersionNumber,
            quoteDate: new Date(quoteDate),
            validFor: parsedNum(validFor),
            paymentTerms,
            incoterms,
            deliveryTerms,
            formulaDescription,
            moqOne: parsedNum(moqOne),
            moqTwo: parsedNum(moqTwo),
            moqThree: parsedNum(moqThree),
            sizeOneQuantity: parsedNum(sizeOneQuantity),
            sizeOneUnit: sizeOneUnit.value,
            sizeOneDescription,
            sizeOneMoqOneTotalCostPerUnit: parsedNum(
              sizeOneMoqOneTotalCostPerUnit
            ),
            sizeOneMoqTwoTotalCostPerUnit: parsedNum(
              sizeOneMoqTwoTotalCostPerUnit
            ),
            sizeOneMoqThreeTotalCostPerUnit: parsedNum(
              sizeOneMoqThreeTotalCostPerUnit
            ),
            sizeTwoQuantity: parsedNum(sizeTwoQuantity),
            sizeTwoUnit: sizeTwoUnit.value,
            sizeTwoDescription,
            sizeTwoMoqOneTotalCostPerUnit: parsedNum(
              sizeTwoMoqOneTotalCostPerUnit
            ),
            sizeTwoMoqTwoTotalCostPerUnit: parsedNum(
              sizeTwoMoqTwoTotalCostPerUnit
            ),
            sizeTwoMoqThreeTotalCostPerUnit: parsedNum(
              sizeTwoMoqThreeTotalCostPerUnit
            ),
            sizeThreeQuantity: parsedNum(sizeThreeQuantity),
            sizeThreeUnit: sizeThreeUnit.value,
            sizeThreeDescription,
            sizeThreeMoqOneTotalCostPerUnit: parsedNum(
              sizeThreeMoqOneTotalCostPerUnit
            ),
            sizeThreeMoqTwoTotalCostPerUnit: parsedNum(
              sizeThreeMoqTwoTotalCostPerUnit
            ),
            sizeThreeMoqThreeTotalCostPerUnit: parsedNum(
              sizeThreeMoqThreeTotalCostPerUnit
            ),
            pricingNotes,
            materialsNote,
            rawMaterialResponsibility,
            containersResponsibility,
            decorationResponsibility,
            closureResponsibility,
            masterResponsibility,
            formulaUuid,
          },
        },
        handleSuccess: () => {
          handleClose();
          showSnackbar('Draft updated', 'success');
          setProject((prevProject: any) => ({
            ...prevProject,
            priceQuotes: prevProject.priceQuotes.map(
              (pq: PriceQuoteAttributes) =>
                pq.id === priceQuote.id
                  ? { ...priceQuote, attributes: values }
                  : pq
            ),
          }));
        },
        handleFinally: () => {
          setSubmitting(false);
        },
        handleError: () => {
          setSubmitting(false);
          showSnackbar('Error updating draft', 'error');
        },
      });
    } else {
      // Create new price quote
      postProjectWorksheet({
        urlParams: `${projectId}/worksheets`,
        data: {
          worksheet: {},
        },
        handleSuccess: (data: any) => {
          const worksheetId = data.id;

          postProjectWorksheetPriceQuote({
            urlParams: `${projectId}/worksheets/${worksheetId}/price_quotes`,
            data: {
              priceQuote: {
                quoteType: quoteType,
                personalNote,
                quoteVersionNumber,
                quoteDate: new Date(quoteDate),
                validFor: parsedNum(validFor),
                paymentTerms,
                incoterms,
                deliveryTerms,
                formulaDescription,
                moqOne: parsedNum(moqOne),
                moqTwo: parsedNum(moqTwo),
                moqThree: parsedNum(moqThree),
                sizeOneQuantity: parsedNum(sizeOneQuantity),
                sizeOneUnit: sizeOneUnit.value,
                sizeOneDescription,
                sizeOneMoqOneTotalCostPerUnit: parsedNum(
                  sizeOneMoqOneTotalCostPerUnit
                ),
                sizeOneMoqTwoTotalCostPerUnit: parsedNum(
                  sizeOneMoqTwoTotalCostPerUnit
                ),
                sizeOneMoqThreeTotalCostPerUnit: parsedNum(
                  sizeOneMoqThreeTotalCostPerUnit
                ),
                sizeTwoQuantity: parsedNum(sizeTwoQuantity),
                sizeTwoUnit: sizeTwoUnit.value,
                sizeTwoDescription,
                sizeTwoMoqOneTotalCostPerUnit: parsedNum(
                  sizeTwoMoqOneTotalCostPerUnit
                ),
                sizeTwoMoqTwoTotalCostPerUnit: parsedNum(
                  sizeTwoMoqTwoTotalCostPerUnit
                ),
                sizeTwoMoqThreeTotalCostPerUnit: parsedNum(
                  sizeTwoMoqThreeTotalCostPerUnit
                ),
                sizeThreeQuantity: parsedNum(sizeThreeQuantity),
                sizeThreeUnit: sizeThreeUnit.value,
                sizeThreeDescription,
                sizeThreeMoqOneTotalCostPerUnit: parsedNum(
                  sizeThreeMoqOneTotalCostPerUnit
                ),
                sizeThreeMoqTwoTotalCostPerUnit: parsedNum(
                  sizeThreeMoqTwoTotalCostPerUnit
                ),
                sizeThreeMoqThreeTotalCostPerUnit: parsedNum(
                  sizeThreeMoqThreeTotalCostPerUnit
                ),
                pricingNotes,
                materialsNote,
                rawMaterialResponsibility,
                containersResponsibility,
                decorationResponsibility,
                closureResponsibility,
                masterResponsibility,
                formulaUuid,
              },
            },
            handleSuccess: () => {
              handleClose();
              showSnackbar('Draft saved', 'success');
            },
            handleFinally: () => {
              setSubmitting(false);
            },
          });
        },
        handleError: () => {
          setSubmitting(false);
          showSnackbar('Error saving draft', 'error');
        },
      });
    }
  };

  const initialValues: PriceQuoteFormValues = priceQuote
    ? {
        ...(priceQuote.attributes as any),
        [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT]:
          PRICE_SIZE_UNITS.find(
            (unit) => unit.value === priceQuote.attributes.sizeOneUnit
          ) ||
          INITIAL_PRICE_QUOTE_FORM_VALUES[PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT],
        [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT]:
          PRICE_SIZE_UNITS.find(
            (unit) => unit.value === priceQuote.attributes.sizeTwoUnit
          ) ||
          INITIAL_PRICE_QUOTE_FORM_VALUES[PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT],
        [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT]:
          PRICE_SIZE_UNITS.find(
            (unit) => unit.value === priceQuote.attributes.sizeThreeUnit
          ) ||
          INITIAL_PRICE_QUOTE_FORM_VALUES[
            PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT
          ],
        [PRICE_QUOTE_FORM_KEYS.FORMULA_UUID]:
          priceQuote.relationships.formula.data.id,
        [PRICE_QUOTE_FORM_KEYS.QUOTE_DATE]: priceQuote.attributes.quoteDate
          ? new Date(priceQuote.attributes.quoteDate!).toLocaleDateString(
              'en-US'
            )
          : '',
      }
    : {
        ...INITIAL_PRICE_QUOTE_FORM_VALUES,
        [PRICE_QUOTE_FORM_KEYS.QUOTE_VERSION_NUMBER]: generateQuoteVersionNumber(
          company,
          quoteType
        ),
      };

  const priceQuoteExists = !!priceQuote;

  const handleShareWithCustomer = () => {
    if (!priceQuote) return;

    shareProjectWorksheetPriceQuoteWithCustomer({
      urlParams: `${projectId}/worksheets/${priceQuote.relationships.worksheet.data.id}/price_quotes/${priceQuote.id}/share_price_quote`,
      data: {},
      handleSuccess: (data: any) => {
        setProject((prevProject: any) => ({
          ...prevProject,
          tasks: data.data,
        }));
        showSnackbar('Price quote shared with customer', 'success');
      },
      handleError: () => {
        showSnackbar('Error sharing price quote with customer', 'error');
      },
    });
  };

  return (
    <div
      className={`relative h-auto w-[1110px] flex flex-col rounded shadow-md bg-white mb-24`}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={priceQuoteValidationSchema}
        enableReinitialize
      >
        {({ submitForm, isSubmitting, validateForm }) => {
          return (
            <>
              <div className="p-6 flex flex-col gap-6 overflow-visible overflow-y-scroll">
                <div className="flex flex-row">
                  <div className="mr-6">
                    <PmLogo />
                  </div>
                  <div>
                    <Typography variant="p" font="agipo" size="xl">
                      {PRICE_QUOTE_TYPE[quoteType]?.title}
                    </Typography>
                  </div>
                </div>

                <BasicInfo />
                <div className="flex flex-col border rounded border-grey-90 p-6">
                  <div className="flex flex-row justify-between">
                    <Overview
                      companyName={company?.attributes.name}
                      quoteIsEditable={quoteIsEditable}
                    />
                    <Terms quoteIsEditable={quoteIsEditable} />
                  </div>

                  <Divider className="my-12" />

                  <Price
                    projectId={projectId}
                    quoteIsEditable={quoteIsEditable}
                  />

                  <Divider className="my-12" />

                  <MaterialResponsibility quoteIsEditable={quoteIsEditable} />
                </div>
              </div>
              <BottomBar
                buttons={[
                  <Button
                    size="large"
                    text={quoteIsEditable ? 'Cancel' : 'Close'}
                    type="secondary"
                    disabled={isSubmitting}
                    action={handleClose}
                  />,
                  quoteIsEditable ? (
                    <Button
                      size="large"
                      text={priceQuote ? 'Update Draft' : 'Save Draft'}
                      type="secondary"
                      leadingIcon={<DocumentIcon />}
                      action={submitForm}
                      disabled={isSubmitting}
                    />
                  ) : undefined,
                  quoteIsEditable ? (
                    <Button
                      size="large"
                      text="Share with Customer"
                      type="primary"
                      disabled={isSubmitting || !priceQuoteExists}
                      onClick={() => {
                        validateForm().then((errors) => {
                          if (Object.keys(errors).length !== 0) {
                            showSnackbar('Please correct form errors', 'error');
                            return;
                          }
                          handleShareWithCustomer();
                        });
                      }}
                    />
                  ) : undefined,
                ]}
                additionalStyles="left-0"
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};
