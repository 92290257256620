import { PRODUCT_CATEGORIES, PRODUCT_TYPES } from '../brief.constants';
import skinCare from 'img/skin-care.png';
import hairCare from 'img/hair-care.png';
import bodyCare from 'img/body-care.png';
import sunCare from 'img/sun-care.png';
import chemicalSunscreen from 'img/chemical-sunscreen.png';
import mineralSunscreen from 'img/mineral-sunscreen.png';
import hybridSunscreen from 'img/hybrid-sunscreen.png';
import selfTanning from 'img/self-tanning.png';
import afterSun from 'img/after-sun.png';
import cleanser from 'img/cleanser.png';
import lipBalm from 'img/lip-balm.png';
import moisturizer from 'img/moisturizer.png';
import scrub from 'img/scrub.png';
import skincareSerum from 'img/skincare-serum.png';
import toner from 'img/toner.png';
import conditioner from 'img/conditioner.png';
import pomade from 'img/pomade.png';
import haircareSpray from 'img/haircare-spray.png';
import haircareSerum from 'img/haircare-serum.png';
import shampoo from 'img/shampoo.png';
import stylingCream from 'img/styling-cream.png';
import bodyWash from 'img/body-wash.png';
import deodorant from 'img/deodorant.png';
import handCream from 'img/hand-cream.png';
import bodyScrub from 'img/body-scrub.png';
import footCream from 'img/foot-cream.png';
import bodyCareMoisturizer from 'img/body-care-moisturizer.png';

export const PRODUCT_OPTIONS = [
  {
    label: PRODUCT_CATEGORIES.SKIN_CARE,
    value: PRODUCT_CATEGORIES.SKIN_CARE,
    productTypes: [
      { label: 'Cleanser', value: PRODUCT_TYPES.CLEANSER, image: cleanser },
      { label: 'Lip Balm', value: PRODUCT_TYPES.LIP_BALM, image: lipBalm },
      {
        label: 'Moisturizer',
        value: PRODUCT_TYPES.MOISTURIZER,
        image: moisturizer,
      },
      { label: 'Scrub', value: PRODUCT_TYPES.SCRUB, image: scrub },
      { label: 'Serum', value: PRODUCT_TYPES.SERUM, image: skincareSerum },
      { label: 'Toner', value: PRODUCT_TYPES.TONER, image: toner },
    ],
    image: skinCare,
  },
  {
    label: PRODUCT_CATEGORIES.HAIR_CARE,
    value: PRODUCT_CATEGORIES.HAIR_CARE,
    productTypes: [
      {
        label: 'Conditioner',
        value: PRODUCT_TYPES.CONDITIONER,
        image: conditioner,
      },
      { label: 'Pomade', value: PRODUCT_TYPES.POMADE, image: pomade },
      { label: 'Serum', value: PRODUCT_TYPES.SERUM, image: haircareSerum },
      { label: 'Shampoo', value: PRODUCT_TYPES.SHAMPOO, image: shampoo },
      { label: 'Spray', value: PRODUCT_TYPES.SPRAY, image: haircareSpray },
      {
        label: 'Styling Cream',
        value: PRODUCT_TYPES.STYLING_CREAM,
        image: stylingCream,
      },
    ],
    image: hairCare,
  },
  {
    label: PRODUCT_CATEGORIES.BODY_CARE,
    value: PRODUCT_CATEGORIES.BODY_CARE,
    productTypes: [
      {
        label: 'Body Scrub',
        value: PRODUCT_TYPES.BODY_SCRUB,
        image: bodyScrub,
      },
      { label: 'Body Wash', value: PRODUCT_TYPES.BODY_WASH, image: bodyWash },
      { label: 'Deodorant', value: PRODUCT_TYPES.DEODORANT, image: deodorant },
      {
        label: 'Foot Cream',
        value: PRODUCT_TYPES.FOOT_CREAM,
        image: footCream,
      },
      {
        label: 'Hand Cream',
        value: PRODUCT_TYPES.HAND_CREAM,
        image: handCream,
      },
      {
        label: 'Moisturizer',
        value: PRODUCT_TYPES.MOISTURIZER,
        image: bodyCareMoisturizer,
      },
    ],
    image: bodyCare,
  },
  {
    label: PRODUCT_CATEGORIES.SUN_CARE,
    value: PRODUCT_CATEGORIES.SUN_CARE,
    productTypes: [
      {
        label: 'After-sun',
        value: PRODUCT_TYPES.AFTER_SUN,
        image: afterSun,
        hasRocket: true,
      },
      {
        label: 'Mineral Sunscreen',
        value: PRODUCT_TYPES.MINERAL_SUNSCREEN,
        image: mineralSunscreen,
        hasRocket: true,
      },
      {
        label: 'Hybrid Sunscreen',
        value: PRODUCT_TYPES.HYBRID_SUNSCREEN,
        image: hybridSunscreen,
        hasRocket: true,
      },
      {
        label: 'Self-tanning',
        value: PRODUCT_TYPES.SELF_TANNING,
        image: selfTanning,
        hasRocket: false,
      },
      {
        label: 'Chemical Sunscreen',
        value: PRODUCT_TYPES.CHEMICAL_SUNSCREEN,
        image: chemicalSunscreen,
        hasRocket: false,
      },
    ],
    image: sunCare,
  },
];
