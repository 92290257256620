// Libraries
import React from 'react';
// Utils
import { useMobile } from 'hooks';
import { useAnalytics } from 'hooks';
// Assets
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { ReactComponent as DownloadIcon } from '../../images/download_icon.svg';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

interface IngredientListTrackerFileCardProps {
  file: any;
  isDeleting: boolean;
  handleDelete: (e: any) => void;
  handleDownload: (e: any) => void;
  cardColor?: string;
}

export const IngredientListTrackerFileCard: React.FC<IngredientListTrackerFileCardProps> = ({
  file,
  isDeleting,
  handleDelete,
  handleDownload,
  cardColor = 'bg-legacyGreen',
}) => {
  const { isMobile } = useMobile();
  const analytics = useAnalytics()!;

  const isEmployee = window.location.pathname.includes(
    'employee-view-project-tracker'
  );

  const handleDeleteClick = (e: any) => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.fileDeleteBtn,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.deleteFile,
    });

    handleDelete(e);
  };

  const handleDownloadClick = (e: any) => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.fileDownloadBtn,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: isEmployee
        ? AnalyticConstants.EVENT_LABEL.employeeFileDownload
        : AnalyticConstants.EVENT_LABEL.customerFileDownload,
    });

    handleDownload(e);
  };

  return (
    <div
      className={`flex items-center p-2 select-none rounded-md sm:mr-2 justify-around mb-2 ${cardColor}`}
    >
      <div className="cursor-pointer">
        <DownloadIcon data-file-id={file.id} onClick={handleDownloadClick} />
      </div>
      <div
        className="pl-2 text-sm font-inter text-wrap truncate sm:justify-start text-left"
        data-file-id={file.id}
      >
        Attachment: {isMobile ? <br /> : null} {file.attributes.name}
      </div>
      {!isDeleting && (
        <div
          className="ml-2 cursor-pointer"
          data-file-id={file.id}
          data-file-name={file.attributes.name}
          onClick={handleDeleteClick}
        >
          <CloseIcon />
        </div>
      )}
    </div>
  );
};
