// Libraries
import React, { useEffect, useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { User, useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
// Components
import { Loader } from 'features/ui';
import { OnboardingWrapper } from './onboarding';
import { UserContext } from 'context';
// Utils
import { useAnalytics } from 'hooks';
import { useError } from 'hooks';
import {
  apiConstants,
  useApi,
  CustomerAttributes,
  IApiData,
  IJWTPayload,
} from 'api';
// Constants
import { ROLES } from 'features/rbac';
import { ROUTES } from './navigation';
const { NOT_FOUND_STATUS } = apiConstants;

interface CustomerAppProps {
  children: any;
}

const CustomerApp: React.FC<CustomerAppProps> = ({ children }) => {
  const analytics = useAnalytics()!;
  const throwError = useError();
  const { isAuthenticated, isLoading, user } = useAuth0();
  const { getCustomer, patchCustomer } = useApi();
  const navigate = useNavigate();

  const { setPermissions, setUserSession, userSession } = useContext(
    UserContext
  )!;
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(true);
  const [isUpdatingCustomer, setIsUpdatingCustomer] = useState<boolean>(false);

  const updateCustomerSession = (
    { attributes, id }: IApiData<CustomerAttributes>,
    token: string,
    isNewCustomer?: boolean
  ) => {
    const decoded = jwtDecode<IJWTPayload>(token);

    if (decoded?.permissions?.length) {
      setPermissions(decoded.permissions);
    } else {
      throw new Error('No permissions found for user');
    }

    setUserSession({
      ...userSession,
      uuid: id,
      email: attributes?.email,
      thirdPartyId: attributes?.thirdPartyId,
      givenName: attributes?.givenName,
      familyName: attributes?.familyName,
      customerMetadata: {
        ...userSession.customerMetadata,
        companyName: attributes?.companyName,
        companyUuid: attributes?.companyUuid,
        notificationPreference: attributes?.notificationPreference,
        isNewCustomer: Boolean(isNewCustomer),
      },
    });
  };

  const handleUpdateCustomerSession = (
    data: IApiData<CustomerAttributes>,
    token: string
  ) => {
    // In some cases the customer can be orphaned from the company
    // in that case we don't want to redirect them.
    // So we check if the customer has a company and projects
    // if they don't have projects we redirect them to the create new customer brief page
    let isNewCustomer = false;

    if (
      data.relationships.company?.data &&
      !(data?.relationships?.projects?.data as any)?.length
    ) {
      isNewCustomer = true;

      navigate(ROUTES.CREATE_BRIEF.route);
    }

    // This will update the customer's session and
    // the missing company issue gets handled in the projects-table component
    updateCustomerSession(data, token, isNewCustomer);
  };

  const updateCustomerWithThirdPartyId = (
    user: Maybe<User>,
    email: Maybe<string>
  ) => {
    setIsUpdatingCustomer(true);

    patchCustomer({
      // Since we are updating the customer with the thirdPartyId we need to use the email as a urlParam
      // to find the customer in the database
      urlParams: `${user?.sub}?email=${encodeURIComponent(email || '')}`,
      data: {
        customer: {
          thirdPartyId: user?.sub,
        },
      },
      handleSuccess: (patchedData, token) =>
        handleUpdateCustomerSession(patchedData, token),
      handleError: (error) => {
        Rollbar.error(error, {
          sub: user?.sub,
        });
        throwError(error as AxiosError);
      },
      handleFinally: () => setIsUpdatingCustomer(false),
    });
  };

  const findOrCreateCustomer = async () => {
    // Sometimes the user object stores the email under the name property
    const email = user?.email || user?.name;

    getCustomer({
      urlParams: user?.sub
        ? `${user.sub}?email=${encodeURIComponent(email || '')}`
        : '',
      handleSuccess: async (data: IApiData<CustomerAttributes>, token) => {
        // Set user properties for analytics, to identify the user as an employee
        analytics.set('user_properties', {
          user_role: ROLES.CUSTOMER,
          user_company_name: data.attributes.companyName,
        });
        // Customer has been found by email but is missing thirdPartyId in the database
        // we need to update the customer with the thirdPartyId
        if (!data.attributes.thirdPartyId) {
          updateCustomerWithThirdPartyId(user, email);
        } else {
          handleUpdateCustomerSession(data, token);
        }
      },
      handleError: (error) => {
        // in most cases the error will be an expected 404 error
        // when its not we send the unexpected error to Rollbar
        if ((error as AxiosError)?.response?.status !== NOT_FOUND_STATUS) {
          Rollbar.error(error, {
            sub: user?.sub,
          });
          throwError(error as AxiosError);
        }
      },
      handleFinally: () => setIsCustomerLoading(false),
    });
  };

  useEffect(() => {
    if (user) {
      // Configure customer for Rollbar
      Rollbar.configure({
        payload: {
          person: {
            id: user?.sub,
            email: user?.email,
          },
        },
      });

      findOrCreateCustomer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  // This shows when a newly signed up user already has a customer in the database
  // but they didn't have a thirdPartyId. While this is showing we are adding the thirdPartyId
  // to the customer in the database
  if (isUpdatingCustomer) {
    return (
      <div className="flex items-center justify-center flex-col h-screen">
        <div className="w-full">
          <CircularProgress />
        </div>
        <div className="p-4">
          <p>It looks like you already have some projects with PML.</p>
          <p>Please wait while we add those to your account…</p>
        </div>
      </div>
    );
  }

  if (isLoading || (isAuthenticated && isCustomerLoading)) {
    return <Loader />;
  }

  // This means the user has signed up for the app through auth0
  // but doesn't exist in the the database yet
  if (isAuthenticated && !userSession.uuid) {
    return <OnboardingWrapper />;
  }

  return children;
};

export default CustomerApp;
