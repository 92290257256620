// Libraries
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Utils
import { Inci, IRawMaterial } from '../types';
import { Price } from 'features/types';
import { useApi, IApiData } from 'api';
import { useSnackbar } from 'hooks';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

interface RawMaterialFormProps {
  rawMaterial: Maybe<IRawMaterial>;
  setRawMaterial: React.Dispatch<React.SetStateAction<Maybe<IRawMaterial>>>;
  children: React.ReactNode | React.ReactNode[];
}

export interface IRawMaterialFormValues {
  general: {
    name: string;
    location: string;
    rmId: string;
    sampleCode: string;
  };
  // This represents the submission of a price before it is submitted to the backend.
  prices: Array<Price>;
  deletedPrices: Array<Price>;
  incis: Array<Inci>;
  classification: {
    vegan: Maybe<boolean>;
    veganExplanation: Maybe<string>;
    crueltyFree: Maybe<boolean>;
    crueltyFreeExplanation: Maybe<string>;
    glutenFree: Maybe<boolean>;
    glutenFreeExplanation: Maybe<string>;
    p65Restricted: Maybe<boolean>;
    p65RestrictedExplanation: Maybe<string>;
    canadaRestricted: Maybe<boolean>;
    canadaRestrictedExplanation: Maybe<string>;
    euRestricted: Maybe<boolean>;
    euRestrictedExplanation: Maybe<string>;
    reachRestricted: Maybe<boolean>;
    reachRestrictedExplanation: Maybe<string>;
    reachRegistered: Maybe<boolean>;
    reachRegisteredExplanation: Maybe<string>;
  };
  marketingInfo: {
    marketingName: string;
    origin: string[];
    synonyms: Array<string>;
    marketingDescription: string;
  };
  notes: string;
  usApi: {
    activeInciId: Maybe<string>;
    usApiDrugName: Maybe<string>;
    usApiMin: Maybe<number>;
    usApiMax: Maybe<number>;
    usApiPurityFactor: Maybe<number>;
  };
  verification: {
    verifyEuInciFactors?: boolean;
    verifyEuIncis?: boolean;
    verifyUsInciFactors?: boolean;
    verifyUsIncis?: boolean;
    clearVerificationsUsIncis?: boolean;
    clearVerificationsEuIncis?: boolean;
    usIncisVerified: Maybe<Date>;
    usInciFactorsVerified: Maybe<Date>;
    euIncisVerified: Maybe<Date>;
    euInciFactorsVerified: Maybe<Date>;
    usIncisVerifiedBy: Maybe<string>;
    usInciFactorsVerifiedBy: Maybe<string>;
    euIncisVerifiedBy: Maybe<string>;
    euInciFactorsVerifiedBy: Maybe<string>;
  };
}

const validationSchema = Yup.object().shape({
  general: Yup.object().shape({
    name: Yup.string().required(),
    location: Yup.string().required(),
    rmId: Yup.string(),
    sampleCode: Yup.string().when('rmId', {
      is: (val: string) => Boolean(val) === false,
      then: (schema: any) => schema.required(),
    }),
  }),
});

export const RawMaterialForm: React.FC<RawMaterialFormProps> = ({
  rawMaterial,
  setRawMaterial,
  children,
}) => {
  const navigate = useNavigate();
  const { postRawMaterial, patchRawMaterial } = useApi();
  const { uuid }: { uuid?: string } = useParams();
  const { showSnackbar } = useSnackbar();

  const INITIAL_FORM_VALUES: IRawMaterialFormValues = {
    general: {
      name: rawMaterial?.name || '',
      location: rawMaterial?.location || '',
      rmId: rawMaterial?.rmId || '',
      sampleCode: rawMaterial?.sampleCode || '',
    },
    prices: rawMaterial?.prices || [],
    deletedPrices: [],
    incis: rawMaterial?.incis || [],
    classification: {
      vegan: rawMaterial?.vegan ?? undefined,
      veganExplanation: rawMaterial?.veganExplanation ?? undefined,
      crueltyFree: rawMaterial?.crueltyFree ?? undefined,
      crueltyFreeExplanation: rawMaterial?.crueltyFreeExplanation ?? undefined,
      glutenFree: rawMaterial?.glutenFree ?? undefined,
      glutenFreeExplanation: rawMaterial?.glutenFreeExplanation ?? undefined,
      p65Restricted: rawMaterial?.p65Restricted ?? undefined,
      p65RestrictedExplanation:
        rawMaterial?.p65RestrictedExplanation ?? undefined,
      canadaRestricted: rawMaterial?.canadaRestricted ?? undefined,
      canadaRestrictedExplanation:
        rawMaterial?.canadaRestrictedExplanation ?? undefined,
      euRestricted: rawMaterial?.euRestricted ?? undefined,
      euRestrictedExplanation:
        rawMaterial?.euRestrictedExplanation ?? undefined,
      reachRestricted: rawMaterial?.reachRestricted ?? undefined,
      reachRestrictedExplanation:
        rawMaterial?.reachRestrictedExplanation ?? undefined,
      reachRegistered: rawMaterial?.reachRegistered ?? undefined,
      reachRegisteredExplanation:
        rawMaterial?.reachRegisteredExplanation ?? undefined,
    },
    marketingInfo: {
      marketingName: rawMaterial?.marketingName || '',
      origin: rawMaterial?.origin || [],
      synonyms: rawMaterial?.synonyms || [],
      marketingDescription: rawMaterial?.marketingDescription || '',
    },
    notes: rawMaterial?.notes || '',
    usApi: {
      activeInciId: rawMaterial?.activeInciId || undefined,
      usApiDrugName: rawMaterial?.usApiDrugName || undefined,
      usApiMin: rawMaterial?.usApiMin || undefined,
      usApiMax: rawMaterial?.usApiMax || undefined,
      usApiPurityFactor: rawMaterial?.usApiPurityFactor || undefined,
    },
    verification: {
      verifyEuInciFactors: false,
      verifyEuIncis: false,
      verifyUsInciFactors: false,
      verifyUsIncis: false,
      clearVerificationsEuIncis: false,
      clearVerificationsUsIncis: false,
      usIncisVerified: rawMaterial?.usIncisVerified || undefined,
      usInciFactorsVerified: rawMaterial?.usInciFactorsVerified || undefined,
      euIncisVerified: rawMaterial?.euIncisVerified || undefined,
      euInciFactorsVerified: rawMaterial?.euInciFactorsVerified || undefined,
      usIncisVerifiedBy: rawMaterial?.usIncisVerifiedBy || undefined,
      usInciFactorsVerifiedBy:
        rawMaterial?.usInciFactorsVerifiedBy || undefined,
      euIncisVerifiedBy: rawMaterial?.euIncisVerifiedBy || undefined,
      euInciFactorsVerifiedBy:
        rawMaterial?.euInciFactorsVerifiedBy || undefined,
    },
  };

  const handleSubmit = (values: IRawMaterialFormValues) => {
    const isEditMode = Boolean(uuid);
    const requestFunction = isEditMode ? patchRawMaterial : postRawMaterial;

    requestFunction({
      urlParams: uuid, // if post request, we can still pass url data, but this will be a blank string
      data: {
        rawMaterial: {
          ...values.general,
          rmId: values.general.rmId || null,
          sampleCode: values.general.sampleCode || null,
          ...values.marketingInfo,
          ...values.usApi,
          notes: values.notes,
          prices: values.prices,
          deletedPrices: values.deletedPrices,
          incis: values.incis,
          classification: values.classification,
          verifyUsIncis: values.verification.verifyUsIncis,
          verifyUsInciFactors: values.verification.verifyUsInciFactors,
          verifyEuIncis: values.verification.verifyEuIncis,
          verifyEuInciFactors: values.verification.verifyEuInciFactors,
          clearVerificationsUsIncis:
            values.verification.clearVerificationsUsIncis,
          clearVerificationsEuIncis:
            values.verification.clearVerificationsEuIncis,
        },
      },
      handleSuccess: (data: IApiData<any>) => {
        if (isEditMode) {
          setRawMaterial({
            ...data.attributes,
            incis: [...values.incis],
            notes: values.notes,
            prices: [...values.prices],
            ...values.general,
            ...values.classification,
            ...values.marketingInfo,
            ...values.usApi,
            functions: [],
            deletedPrices: [],
          });
          showSnackbar('Raw Material successfully updated!', 'success');
        } else {
          navigate(
            ROUTES.SHOW_RAW_MATERIAL.route.replace(
              UUID_SHOW_ROUTE_STRING,
              data.id
            ),
            { state: { success: true } }
          );
        }
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_FORM_VALUES}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
