// Libraries
import React from 'react';
import {
  createStyles,
  makeStyles,
  TableRow,
  TableCell,
  Typography,
  TableHead,
} from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { ITableColumnCollection } from 'features/types';

interface IIngredientsTableHeaderRow {
  tableColumns: ITableColumnCollection;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    headerRow: {
      backgroundColor: theme.palette.secondary.dark,
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
      position: 'sticky',
      top: 0,
      zIndex: 100,
    },
    headerCell: {
      color: '#ffffff',
      borderBottom: 'none',
      '&:first-child': {
        borderTopLeftRadius: '0.25rem',
      },
      '&:last-child': {
        borderTopRightRadius: '0.25rem',
      },
    },
  })
);

export const IngredientsTableHeaderRow: React.FC<IIngredientsTableHeaderRow> = ({
  tableColumns,
}) => {
  const classes = useStyles();

  return (
    <TableHead className={classes.headerRow}>
      <TableRow className={classes.headerRow}>
        {Object.keys(tableColumns).map((tableColumnKey: string) => {
          const tableColumn = tableColumns[tableColumnKey];
          return (
            <TableCell
              key={`${tableColumnKey}`}
              align={tableColumn.align ? tableColumn.align : 'left'}
              className={classes.headerCell}
              style={{
                width: tableColumn.columnWidth,
                padding: tableColumn?.padding,
              }}
            >
              <Typography variant="h4">{tableColumn.headerText}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
