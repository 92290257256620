//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { Warning as WarningIcon } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Toolbar, Tooltip, AppBar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { BellIcon } from '@heroicons/react/16/solid';
//Assets
import PMWordMark from 'img/pm-wordmark-blue.svg';
import PmLogo from 'img/Element-Logo.svg';
import { ReactComponent as Arrow } from 'img/arrow.svg';
import redIndicator from '../notifications/assets/redIndicator.svg';
//Components
import { LoginButton, NAVBAR_HEIGHT, ROUTES } from 'features/navigation';
import { Can, PERMISSIONS, ROLES } from 'features/rbac';
import { NewCustomerAppBar } from './new-customer-app-bar.component';
import {
  UserContext,
  NotificationsContext,
  ConfirmationContextProvider,
} from 'context';
//Utils
import { THEME_PALETTE } from 'styles/mui/themeV2';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: 'white',
      borderBottom: `1px solid ${THEME_PALETTE.gray.light}`,
      height: NAVBAR_HEIGHT,
    },
    button: {
      color: THEME_PALETTE.gray.dark,
    },
    toolbarButtons: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    wordMark: {
      padding: theme.spacing(2),
    },
  })
);

const excludeRoutes = [ROUTES.SIGNIN.route];

export const NavBar: React.FC = () => {
  const { pathname } = useLocation();
  const { logout } = useAuth0();
  const classes = useStyles();
  const {
    roles,
    userSession: {
      givenName,
      familyName,
      customerMetadata: { companyName, isNewCustomer },
    },
  } = useContext(UserContext)!;

  const { hasNewNotifications } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const menuRef = React.useRef<HTMLDivElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isEmployee = roles && roles.includes(ROLES.EMPLOYEE);
  const isCustomer = !isEmployee;

  const initials = `${givenName?.charAt(0) || ''}${
    familyName?.charAt(0) || ''
  }`;

  useEffect(() => {
    // Add event listener to handle clicks outside the navbar
    const handleClickOutside = (event: MouseEvent) => {
      const navbar = document.getElementById('navbar');
      if (
        navbar &&
        !navbar.contains(event.target as Node) &&
        !(menuRef.current && menuRef.current.contains(event.target as Node))
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (
    excludeRoutes.includes(pathname) ||
    pathname.includes('ready-to-launch')
  ) {
    return null;
  }

  const handleSettingsClick = () => {
    navigate(ROUTES.PROFILE.route);
  };

  return (
    <>
      <AppBar position="sticky" className={classes.appBar} elevation={0}>
        <Toolbar id="navbar">
          <Can
            perform={PERMISSIONS.VISIT_AUTHORIZED_APPLICATION}
            yes={() =>
              isCustomer && isNewCustomer ? (
                <ConfirmationContextProvider>
                  <NewCustomerAppBar />
                </ConfirmationContextProvider>
              ) : (
                <>
                  <>
                    <img
                      className="cursor-pointer"
                      onClick={() => navigate(ROUTES.HOME.route)}
                      src={PmLogo}
                      style={{ height: '30px' }}
                      alt="PM Logo"
                    />{' '}
                    &nbsp;
                    <sup style={{ fontSize: '8px' }}>BETA</sup>
                  </>
                  <div className="flex items-center ml-auto mr-3">
                    {['development', 'staging'].includes(
                      process.env.REACT_APP_ENV as string
                    ) && (
                      <Tooltip
                        title={`This environment is ${process.env.REACT_APP_ENV}`}
                      >
                        <WarningIcon className={classes.button} />
                      </Tooltip>
                    )}
                    {!isEmployee && (
                      <div className="relative">
                        <BellIcon
                          onClick={() =>
                            navigate(
                              ROUTES.CUSTOMER_NOTIFICATIONS_DASHBOARD.route
                            )
                          }
                          color="F0F2F4"
                          className="text-grey-50 h-5 w-5 mx-4 cursor-pointer"
                        />
                        {hasNewNotifications && (
                          <img
                            alt="notification indicator"
                            src={redIndicator}
                            className="absolute top-0 right-[18px]"
                          />
                        )}
                      </div>
                    )}
                    <div
                      className="ml-3 flex items-center cursor-pointer select-none"
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                      <div>
                        <h6 className="text-sm text-black">
                          {isEmployee ? '' : companyName}
                        </h6>
                      </div>
                      <div className="ml-3 flex items-center">
                        <div className=" bg-lightBlue w-8 h-8 flex items-center justify-center rounded-full border border-black p-4">
                          <h6 className="text-sm text-black">{initials}</h6>
                        </div>
                        <div>
                          <Arrow />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
            no={() => (
              <>
                <div className={classes.wordMark}>
                  <a
                    href="http://www.primematterlabs.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={PMWordMark} width="150" alt="" />
                  </a>
                </div>
                <div className={classes.toolbarButtons}>
                  <LoginButton />
                </div>
              </>
            )}
          />
        </Toolbar>
        {/* TODO: Move to separate component */}
        {isMenuOpen && (
          <nav
            ref={menuRef}
            className="absolute select-none text-left text-black shadow-md bg-white top-[65px] right-0 p-6 w-56"
          >
            <ul>
              <li
                className="text-sm mb-6 cursor-pointer"
                onClick={handleSettingsClick}
              >
                <p className="inline">Settings</p>
              </li>
              <li className="text-sm cursor-pointer" onClick={() => logout()}>
                <p className="inline">Log out</p>
              </li>
            </ul>
          </nav>
        )}
      </AppBar>
    </>
  );
};
