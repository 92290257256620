//Libraries
import { useContext } from 'react';
//Components
import { UserContext } from 'context';

const check = (staticPermissions: Array<string>, action: string) => {
  if (!staticPermissions || staticPermissions.length === 0) {
    return false;
  }

  if (staticPermissions.includes(action)) {
    return true;
  }

  return false;
};

export const Can = (props: any) => {
  const { permissions } = useContext(UserContext)!;

  return check(permissions, props.perform) ? props.yes() : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};
