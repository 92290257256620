// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { createStyles, makeStyles, Grid, Typography } from '@material-ui/core';
// Utils
import { FormulaContext } from '../context';
import { ITheme } from 'styles/mui/themeV2';
import { Ingredient } from './types';
import { parseCostFromPrice } from './utils';
import {
  useApi,
  BriefAttributes,
  FormulaAttributes,
  IApiData,
  IngredientAttributes,
  PricingQuoteAttributes,
} from 'api';
import {
  PricingQuoteConstants,
  calculateTankSizeAndOutput,
  calculateUnitPricing,
  determineHeadCountValues,
  determineMarginTarget,
  generateProjectErrors,
  generateFormulaErrors,
} from 'features/pricing-quote';

// Constants
import { UNITS } from './constants';
const { INITIAL_EFFICIENCY_PERCENTAGE } = PricingQuoteConstants;

interface TotalCostSummaryProps {
  ingredients: Ingredient[];
  pricingQuote: Maybe<IApiData<PricingQuoteAttributes>>;
  selectedPricingUnit: string;
  setSelectedPricingUnit: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    active: {
      backgroundColor: theme.palette.gray.dark,
      color: theme.palette.secondary.main,
    },
    inactive: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.gray.dark,
    },
    rightButton: {
      borderTopRightRadius: '0.25rem',
      borderBottomRightRadius: '0.25rem',
    },
    leftButton: {
      borderTopLeftRadius: '0.25rem',
      borderBottomLeftRadius: '0.25rem',
    },
    toggleButton: {
      padding: '0.375rem',
    },
    buttonGroup: {
      border: `1px solid ${theme.palette.gray.dark}`,
      borderRadius: '0.25rem',
      width: 'auto',
      cursor: 'pointer',
      marginRight: '0.5rem',
    },
    container: {
      alignItems: 'center',
      paddingRight: '1rem',
    },
    currentCostContainer: {
      marginRight: '2rem',
    },
  })
);

const NOT_APPLICABLE = 'N/A';
const MULTIPLE_TANKS_MULTIPLIER = 2;

export const TotalCostSummary: React.FC<TotalCostSummaryProps> = ({
  ingredients,
  pricingQuote,
  selectedPricingUnit,
  setSelectedPricingUnit,
}) => {
  const classes = useStyles();
  const { formula } = useContext(FormulaContext);
  const { getProject } = useApi();

  const [brief, setBrief] = useState<Maybe<IApiData<BriefAttributes>>>(
    undefined
  );

  const pricingQuoteOverrideExists = Boolean(pricingQuote?.attributes);

  useEffect(() => {
    if (formula?.projectUuid) {
      getProject({
        urlParams: formula?.projectUuid,
        handleSuccess: (response) => {
          setBrief(response.attributes.brief.data);
        },
      });
    }
  }, [formula?.projectUuid, getProject]);

  const getTotalCostOfFormula = () => {
    return ingredients?.reduce((acc, ingredient) => {
      acc += ingredient?.price
        ? parseCostFromPrice(ingredient.price, selectedPricingUnit) *
          (ingredient.amount / 100)
        : 0;
      return acc;
    }, 0);
  };

  const determineTargetCost = () => {
    if (!brief) return;

    const {
      targetCost,
      targetMinCost,
      targetMaxCost,
    } = brief.attributes as BriefAttributes;

    let targetCostToDisplay = NOT_APPLICABLE;

    if (targetCost) {
      targetCostToDisplay = `$${targetCost.toFixed(2)}`;
    } else if (targetMinCost && targetMaxCost) {
      targetCostToDisplay = `$${targetMaxCost.toFixed(2)}`;
    }

    return (
      <div>
        <Typography className="text-left" variant="body1">
          {targetCostToDisplay}
        </Typography>
      </div>
    );
  };

  const determineCurrentCost = () => {
    if (!brief || !formula || !brief.attributes?.briefSupplement) return;

    const {
      productionLocation,
    } = brief.attributes.briefSupplement.data.attributes;

    const formattedIngredients = ingredients.map((ingredient) => {
      return ({
        id: ingredient.id,
        attributes: ingredient,
        relationships: [],
        // We should be able to remove this conversion from Ingredient -> unknown -> IngredientAttributes when
        // Ingredient is converted to IngredientAttributes globally
      } as unknown) as IApiData<IngredientAttributes>;
    });

    const projectErrors = generateProjectErrors({
      brief: brief,
      formulas: undefined,
      productionLocation,
    });

    const formattedFormula = ({
      id: formula.id,
      attributes: { ...formula },
      relationships: [],
    } as unknown) as IApiData<FormulaAttributes>;
    const formulaErrors = generateFormulaErrors(
      formattedFormula,
      formattedIngredients
    );

    const tankSizeAndOutput = calculateTankSizeAndOutput({
      productionLocation,
      brief: {
        ...brief.attributes,
        ...brief.attributes.briefSupplement.data.attributes,
      },
    });

    const laborFields = pricingQuoteOverrideExists
      ? {
          cappingHeadCount: pricingQuote!.attributes.cappingHeadCount as number,
          compoundingHeadCount: pricingQuote!.attributes
            .compoundingHeadCount as number,
          operatorHeadCount: pricingQuote!.attributes
            .operatorHeadCount as number,
          otherHeadCount: pricingQuote!.attributes.otherHeadCount as number,
          packingOrPalletizerHeadCount: pricingQuote!.attributes
            .packingOrPalletizerHeadCount as number,
          preworkHeadCount: pricingQuote!.attributes.preworkHeadCount as number,
          runRate: pricingQuote!.attributes.runRate as number,
          unitCartonHeadCount: pricingQuote!.attributes
            .unitCartonHeadCount as number,
        }
      : determineHeadCountValues(
          brief.attributes.briefSupplement.data.attributes,
          tankSizeAndOutput?.unitOutput || 0
        );

    // Multiple tanks increases the number of compounding headcount
    if (tankSizeAndOutput && tankSizeAndOutput?.tanks.length > 1) {
      laborFields.compoundingHeadCount =
        tankSizeAndOutput.tanks.length * MULTIPLE_TANKS_MULTIPLIER;
    }

    const unitPricing = calculateUnitPricing({
      ingredients: formattedIngredients,
      brief: {
        ...brief.attributes,
        ...brief.attributes.briefSupplement.data.attributes,
      },
      laborFields: {
        ...laborFields,
        efficiencyPercentage: pricingQuoteOverrideExists
          ? parseFloat(pricingQuote!.attributes.efficiencyPercentage)
          : (INITIAL_EFFICIENCY_PERCENTAGE.value as number),
        marginTargetPercentage: pricingQuoteOverrideExists
          ? parseFloat(pricingQuote!.attributes.marginTargetPercentage)
          : determineMarginTarget(tankSizeAndOutput),
      },
      productionTank: tankSizeAndOutput,
      productionLocation: productionLocation as string,
    });

    const currentCostToDisplay =
      // projectErrors will always have one error b/c no formula is being provided to it.
      projectErrors.length > 1 || formulaErrors.length > 0
        ? NOT_APPLICABLE
        : `$${unitPricing.roundedTotalCostWithProfit.toFixed(2)}`;

    const isOverTarget =
      (brief?.attributes?.targetCost &&
        unitPricing.roundedTotalCostWithProfit >
          brief.attributes?.targetCost) ||
      (brief.attributes?.targetMaxCost &&
        unitPricing.roundedTotalCostWithProfit >
          brief.attributes.targetMaxCost);

    return (
      <div>
        <Typography className="text-left" variant="body1">
          <span
            className={`${
              isOverTarget && currentCostToDisplay !== NOT_APPLICABLE
                ? 'text-formulaRed'
                : ''
            }`}
          >
            {currentCostToDisplay}
          </span>
        </Typography>
      </div>
    );
  };

  const NO_PRICE = '0.00';

  const UNIT_BUTTONS = [
    {
      name: UNITS.OZ,
      handleClick: () => setSelectedPricingUnit(UNITS.OZ),
      active: selectedPricingUnit === UNITS.OZ,
    },
    {
      name: UNITS.LB,
      handleClick: () => setSelectedPricingUnit(UNITS.LB),
      active: selectedPricingUnit === UNITS.LB,
    },
  ];

  const renderButtons = () => {
    return (
      <Grid
        container
        item
        justifyContent="flex-end"
        className={classes.buttonGroup}
      >
        {UNIT_BUTTONS.map((button, idx) => {
          return (
            <div
              key={idx}
              onClick={button.handleClick}
              className={`
              ${button.active ? classes.active : classes.inactive} ${
                idx === 0 ? classes.leftButton : classes.rightButton
              } ${classes.toggleButton}
            `}
            >
              <Typography variant="h4">{button.name}</Typography>
            </div>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="flex-end" className={classes.container}>
      <Grid
        container
        direction="column"
        xs={8}
        className={classes.currentCostContainer}
      >
        <Grid spacing={2} container direction="row" justifyContent="flex-end">
          <Grid item>
            <Typography>
              TARGET COST ({brief?.attributes.size} {brief?.attributes.unit}):
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{determineTargetCost()}</Typography>
          </Grid>
        </Grid>
        <Grid spacing={2} container direction="row" justifyContent="flex-end">
          <Grid item>
            <Typography>CURRENT COST:</Typography>
          </Grid>
          <Grid item>
            <Typography>{determineCurrentCost()}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {renderButtons()}
      <Typography variant="body1">
        ${getTotalCostOfFormula().toFixed(2) || NO_PRICE}
      </Typography>
    </Grid>
  );
};
