// Libraries
import React from 'react';
import { Link, Typography } from '@material-ui/core';

export const Copyright: React.FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link
        color="inherit"
        href="https://www.primematterlabs.com/"
        target="_blank"
      >
        Prime Matter Labs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
