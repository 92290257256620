// Libraries
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import Close from '@material-ui/icons/Close';
// Constants
import { ROUTES } from 'features/navigation';
import { SEARCH_PARAM_KEYS } from 'features/constants';
import { HIDE_TEAM_INVITATION_BANNER_KEY } from '../constants';

export const TeamInvitationBanner: React.FC = () => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const isHidden = localStorage.getItem(HIDE_TEAM_INVITATION_BANNER_KEY);
    setIsHidden(!!isHidden);
  }, []);

  // Controls the visibility of the banner based on the local storage value and state
  if (isHidden || localStorage.getItem(HIDE_TEAM_INVITATION_BANNER_KEY)) {
    return null;
  }

  const handleClose = () => {
    localStorage.setItem(HIDE_TEAM_INVITATION_BANNER_KEY, 'true');
    setIsHidden(true);
  };

  return (
    <div className="w-full bg-lightestBlue text-darkGray py-4 px-3 mb-3 flex items-center justify-start">
      <PersonAddOutlinedIcon className="text-blue-60 mr-3" />
      <p className="text-sm text-left">
        Invite team members to your company to see projects and perform actions.{' '}
        <Link
          className="underline"
          to={`${ROUTES.PROFILE.route}?${SEARCH_PARAM_KEYS.TAB}=company`}
        >
          Invite here
        </Link>
      </p>
      <Close className="ml-auto cursor-pointer" onClick={handleClose} />
    </div>
  );
};
