// Libraries
import React from 'react';

export const RegulatoryInfo: React.FC = () => {
  const addresses = [
    {
      name: 'Miami',
      line1: '16363 NW 49th Avenue',
      line2: 'Miami Gardens, FL 33014',
    },
    {
      name: 'Los Angeles',
      line1: '19780 Pacific Gateway Drive',
      line2: 'Torrance, CA 90502',
    },
    {
      name: 'New York',
      line1: '20 Jay Street',
      line2: 'Brooklyn, NY 11201',
    },
  ];

  const regulatoryInfo = [
    {
      title: 'FEI Number',
      locations: [
        {
          name: 'Miami',
          value: '1036626',
        },
        {
          name: 'Torrance',
          value: '3021784212',
        },
        {
          name: 'Medley',
          value: '3005051865',
        },
      ],
    },
    {
      title: 'EPA Registration ID',
      locations: [
        {
          name: 'Miami',
          value: '66804-FL-1',
        },
        {
          name: 'Torrance',
          value: '66804-FL-3',
        },
        {
          name: 'Medley',
          value: '66804-FL-3',
        },
      ],
    },
    {
      title: 'State Cosmetic Manufacturer License Number',
      locations: [
        {
          name: 'Miami',
          value: '#06562 (Florida DBPR)',
        },
        {
          name: 'Torrance',
          value: '#15692 (California DPH)',
        },
        {
          name: 'Medley',
          value: '#06561 (Florida DBPR)',
        },
      ],
    },
    {
      title: 'State OTC Drug Manufacturer License Number',
      locations: [
        {
          name: 'Miami',
          value: '#21845 (Florida DBPR)',
        },
        {
          name: 'Torrance',
          value: '#115692 (California DPH)',
        },
        {
          name: 'Medley',
          value: '#21846 (Florida DBPR)',
        },
      ],
    },
    {
      title: 'Health Canada DEL',
      locations: [
        {
          name: 'Miami and Medley',
          value: '101376-A',
        },
        {
          name: 'Torrance',
          value: 'N/A',
        },
      ],
    },
  ];

  const renderAddresses = () => {
    return addresses.map((address) => {
      return (
        <div key={address.name} className="flex flex-col mb-9">
          <span className="font-semibold">{address.name}</span>
          <span>{address.line1}</span>
          <span>{address.line2}</span>
        </div>
      );
    });
  };

  const renderRegulatoryInfo = () => {
    return regulatoryInfo.map((info) => {
      return (
        <div key={info.title} className="flex flex-col mb-12">
          <span className="font-inter text-xl text-grey-50">{info.title}</span>
          <div className="h-[1px] w-full border-t border-grey-90 mt-[6px] mb-6" />
          {info.locations.map((location) => {
            return (
              <div className="font-inter mb-3">
                {location.name}: {location.value}
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div className="text-left text-base mx-3 sm:mx-[80px] mt-[60px] pb-[160px] sm:pb-[130px] sm:grid sm:grid-cols-12 text-grey-20">
      <div className="sm:col-start-4 sm:col-span-6">
        <div className="font-mono uppercase text-xs text-grey-50 mb-3">
          PRIME MATTER LABS
        </div>
        <div className="font-headline text-[32px] font-[900] mb-12">
          Regulatory Information
        </div>
        <div className="font-inter text-xl text-grey-50">Site Addresses</div>
        <div className="h-[1px] w-full border-t border-grey-90 mt-[6px] mb-6" />
        {renderAddresses()}
        {renderRegulatoryInfo()}
      </div>
    </div>
  );
};
