//Libraries
import React, { useState, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Grid,
} from '@material-ui/core';
import { useNavigate, Link } from 'react-router-dom';
// Components
import { InciVerification } from './inci-verification.component';
// Utils
import { Region } from './types';
import { IApiData, PriceAttributes, RawMaterialAttributes } from 'api';
// Constants
import * as Constants from 'features/constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableBody: {
      overflow: 'scroll',
    },
    table: {
      minWidth: 650,
      minHeight: 400,
      '&:focus': {
        outline: `3px solid ${theme.palette.primary.main}`,
      },
    },
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    icon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    rawMaterialLinkCell: {
      minWidth: '10rem',
      position: 'relative',
      color: theme.palette.primary.main,
      transition: 'all 0.18s ease-in',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      '&:active': {
        backgroundColor: '#416d94',
      },
    },
    linkText: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selectedIndex: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '& th': {
        color: theme.palette.secondary.main,
      },
      '& td': {
        color: theme.palette.secondary.main,
      },
    },
  })
);

interface RawMaterialsTableProps {
  rawMaterials: Array<IApiData<RawMaterialAttributes>>;
  incis: Array<IApiData<any>>;
  isLoadingSearchResults: boolean;
  page: number;
  setPage: (newPage: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (newRowsPerPage: number) => void;
  totalNumberOfRows: number;
}

export const RawMaterialsTable: React.FC<RawMaterialsTableProps> = ({
  rawMaterials,
  incis,
  isLoadingSearchResults,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  totalNumberOfRows,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Table Key Navigation
  const table = useRef<any>();
  /* Starts at -1 so that none of the rows are selected before
      the table is in focus and able to be navigated
  */
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const ARROW_DOWN = 'ArrowDown';
    const ARROW_UP = 'ArrowUp';
    const ENTER = 'Enter';

    if ([ARROW_DOWN, ARROW_UP, ENTER].includes(event.key)) {
      event.preventDefault();
    }

    switch (event.key) {
      case ARROW_DOWN:
        setSelectedIndex((index: number) => {
          if (index >= rawMaterials.length - 1) {
            if (rawMaterials.length === rowsPerPage) {
              setPage(page + 1);
              return 0;
            }
            setPage(0);
            return 0;
          } else {
            return index + 1;
          }
        });
        break;
      case ARROW_UP:
        setSelectedIndex((index: number) => {
          if (index > 0) {
            return index - 1;
          } else {
            if (page > 0) {
              setPage(page - 1);
              return rowsPerPage - 1;
            }
            return 0;
          }
        });
        break;
      case ENTER:
        if (selectedIndex < 0) return;
        navigate(
          ROUTES.SHOW_RAW_MATERIAL.route.replace(
            UUID_SHOW_ROUTE_STRING,
            rawMaterials[selectedIndex].id
          )
        );
        break;
      default:
        break;
    }
  };

  const renderRawMaterialIncis = (
    rawMaterial: IApiData<RawMaterialAttributes>,
    region: Region
  ) => {
    const rawMaterialIncis = incis.filter(
      (data) =>
        data.type === Constants.API_TYPES.RAW_MATERIAL_INCI &&
        data.attributes.rawMaterialUuid === rawMaterial.id &&
        data.attributes.region === region
    );

    return rawMaterialIncis
      .map((rmi) => {
        return incis.find((inci) => inci.id === rmi.attributes.inciUuid)
          ?.attributes[
          region === Constants.INCI_TYPES.US
            ? Constants.INCI_REGION_TYPES.US_NAME
            : Constants.INCI_REGION_TYPES.EU_NAME
        ];
      })
      .join(', ');
  };

  return (
    <div
      className={classes.table}
      tabIndex={0}
      ref={table}
      onKeyDown={handleKeyDown}
    >
      <TableContainer component={Paper} className={classes.root}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">US Incis</TableCell>
              <TableCell align="center">EU Incis</TableCell>
              <TableCell align="center">RM ID</TableCell>
              <TableCell align="center">Sample Code</TableCell>
              <TableCell align="center">Location</TableCell>
              <TableCell align="left" colSpan={3}>
                Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {isLoadingSearchResults ? (
              <TableRow>
                <TableCell colSpan={100} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : rawMaterials.length ? (
              rawMaterials.map((rawMaterial, index) => {
                const highestPrice = ((rawMaterial as any).prices as Array<
                  IApiData<PriceAttributes>
                >).sort((a, b) => {
                  return a.attributes.cost - b.attributes.cost;
                })[0] as Maybe<IApiData<PriceAttributes>>;

                return (
                  <TableRow
                    key={rawMaterial.id}
                    classes={
                      index === selectedIndex
                        ? {
                            root: classes.selectedIndex,
                          }
                        : undefined
                    }
                  >
                    <TableCell
                      align="center"
                      component="td"
                      scope="row"
                      className={classes.rawMaterialLinkCell}
                    >
                      <Link
                        to={ROUTES.SHOW_RAW_MATERIAL.route.replace(
                          UUID_SHOW_ROUTE_STRING,
                          rawMaterial.id
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography className={classes.linkText}>
                          {rawMaterial.attributes.name}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {renderRawMaterialIncis(
                        rawMaterial,
                        Constants.INCI_TYPES.US as Region
                      )}
                      <InciVerification
                        ingredientOrRawMaterial={rawMaterial as any}
                        inciType="us"
                      />
                    </TableCell>
                    <TableCell align="center">
                      {renderRawMaterialIncis(
                        rawMaterial,
                        Constants.INCI_TYPES.EU as Region
                      )}
                      <InciVerification
                        ingredientOrRawMaterial={rawMaterial as any}
                        inciType="eu"
                      />
                    </TableCell>
                    <TableCell align="center">
                      {rawMaterial.attributes.rmId}
                    </TableCell>
                    <TableCell align="center">
                      {rawMaterial.attributes.sampleCode &&
                        `SAMP-${rawMaterial.attributes.sampleCode}`}
                    </TableCell>
                    <TableCell align="center">
                      {rawMaterial.attributes.location}
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            ${(highestPrice?.attributes.cost || 0).toFixed(2)}/
                            {highestPrice?.attributes.unit || 'unit'}
                          </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <Typography
                            style={{ color: '#909090', marginRight: 4 }}
                            variant="body2"
                          >
                            from
                          </Typography>
                          <Typography variant="body2">
                            {(highestPrice as any)?.supplier?.attributes.name ||
                              'N/A'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={100} align="center">
                  No Search Results
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalNumberOfRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};
