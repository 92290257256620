import React from 'react';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
//Utils
import { ITheme } from 'styles/mui/themeV2';
// Constants
import * as Constants from '../../constants';

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    footer: {
      color: theme.palette.gray.main,
      marginTop: theme.spacing(10),
      textAlign: 'left',
    },
  })
);

export const FileFooter: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid xs={12} className={classes.footer}>
      <Grid xs={6}>
        <Typography variant="body2">
          {Constants.FILE_UPLOAD_FOOTER.title}
        </Typography>
      </Grid>
      <Grid container xs={6}>
        <Typography variant="body2">
          {Constants.FILE_UPLOAD_FOOTER.content}
        </Typography>
      </Grid>
    </Grid>
  );
};
