// Libraries
import React, { useContext } from 'react';
// Components
import { UserContext } from 'context';
import { EmployeeDashboard } from './employee-dashboard';
import { CustomerPortal } from './customer-portal';
// Utils
import { ROLES } from 'features/rbac';

export const Home: React.FC = () => {
  const { roles } = useContext(UserContext)!;

  function renderDashboardBasedOnRole() {
    if (roles.includes(ROLES.EMPLOYEE)) {
      return <EmployeeDashboard />;
    } else {
      return <CustomerPortal />;
    }
  }

  return <>{renderDashboardBasedOnRole()}</>;
};
