// Libraries
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
// Components
import { Page } from 'features/ui';
// Utils
import { useApi, IApiData, InciAttributes } from 'api';
// Constants
import { BASE_URL } from 'features/navigation';

const LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '#',
];

const inciLength = {
  minLength: 0,
  maxLength: 45,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    letters: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 24,
      paddingBottom: 24,
    },
    newInciBtn: {
      textAlign: 'right',
    },
  })
);

export const IncisDictionary: React.FC = () => {
  const [selectedLetter, setSelectedLetter] = useState<string>('A');
  const [selectedLetterIncis, setSelectedLetterIncis] = useState<
    IApiData<InciAttributes>[]
  >([]);
  const { getIncis } = useApi();
  const classes = useStyles();

  useEffect(() => {
    const paramLetter =
      selectedLetter === '#'
        ? encodeURIComponent(selectedLetter)
        : selectedLetter;

    getIncis({
      urlParams: `?letter=${paramLetter}`,
      handleSuccess: (data: any) => {
        setSelectedLetterIncis(data.data);
      },
    });
  }, [selectedLetter, getIncis]);

  const handleLetterClick = (event: React.ChangeEvent<any>) => {
    setSelectedLetter(event.target.innerText);
  };

  const truncateInciName = (name: string) => {
    if (name.length <= inciLength.maxLength) return name;
    return `${name.slice(inciLength.minLength, inciLength.maxLength)}...`;
  };

  return (
    <Page>
      <Container maxWidth="lg" className={classes.header}>
        <Grid container item alignItems="center" xs={12}>
          <Grid item xs={9}>
            <Typography variant="h1" align="left">
              INCI Glossary
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'right' }}>
            <Link target="_blank" href={`${BASE_URL}/incis/new`}>
              <Button>Enter New INCI</Button>
            </Link>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <List className={classes.letters}>
            {LETTERS.map((letter, index) => {
              return (
                <ListItem
                  button
                  key={`${index}-${letter}`}
                  onClick={handleLetterClick}
                >
                  <ListItemText>{letter}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid container justifyContent="center">
          <Grid
            container
            spacing={5}
            alignItems="flex-start"
            style={{ maxWidth: '90%', textAlign: 'left' }}
          >
            {selectedLetterIncis?.map((inci) => {
              return (
                <Grid
                  key={inci.id}
                  container
                  item
                  xs={12}
                  sm={6}
                  alignContent="flex-start"
                >
                  <Link target="_blank" href={`${BASE_URL}/incis/${inci.id}`}>
                    <Tooltip title={inci.attributes.usName}>
                      <Typography noWrap variant="h3">
                        {truncateInciName(inci.attributes.usName)}
                      </Typography>
                    </Tooltip>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
