// Libraries
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
// Components
import { FormSection } from 'features/ui';
import { RadioButtonsWithExplanation } from './radio-buttons-with-explanation.component';
// Utils
import { IRawMaterialFormValues } from './raw-material-form.component';
// Constants
const CLASSIFICATION_HEADER = 'Classification';
const CLASSIFICATION_SUBHEADER =
  'Specifying raw material claims and restrictions';

type ClassificationRadioGroup = {
  explanationInputName: string;
  explanationValue: Maybe<string>;
  label: string;
  inputName: string;
  inputValue: Maybe<boolean>;
};

export const ClassificationSection: React.FC = () => {
  const {
    values: { classification },
    setFieldValue,
  } = useFormikContext<IRawMaterialFormValues>();
  const [isRevealed, setIsRevealed] = useState<boolean>(false);

  useEffect(() => {
    // Makes sure the section is revealed if there
    // is data already present (e.g. show page)
    setIsRevealed(
      Object.values(classification).some((cl) => typeof cl === 'boolean' || cl)
    );
  }, [classification]);

  const {
    vegan,
    veganExplanation,
    crueltyFree,
    crueltyFreeExplanation,
    glutenFree,
    glutenFreeExplanation,
    p65Restricted,
    p65RestrictedExplanation,
    canadaRestricted,
    canadaRestrictedExplanation,
    euRestricted,
    euRestrictedExplanation,
    reachRestricted,
    reachRestrictedExplanation,
    reachRegistered,
    reachRegisteredExplanation,
  } = classification;

  const CLASSIFICATIONS: ClassificationRadioGroup[] = [
    {
      label: 'Vegan',
      inputName: 'vegan',
      inputValue: vegan,
      explanationValue: veganExplanation,
      explanationInputName: 'veganExplanation',
    },
    {
      label: 'Cruelty Free',
      inputValue: crueltyFree,
      inputName: 'crueltyFree',
      explanationValue: crueltyFreeExplanation,
      explanationInputName: 'crueltyFreeExplanation',
    },
    {
      label: 'Gluten Free',
      inputValue: glutenFree,
      inputName: 'glutenFree',
      explanationValue: glutenFreeExplanation,
      explanationInputName: 'glutenFreeExplanation',
    },
    {
      label: 'p65 Restricted',
      inputValue: p65Restricted,
      inputName: 'p65Restricted',
      explanationValue: p65RestrictedExplanation,
      explanationInputName: 'p65RestrictedExplanation',
    },
    {
      label: 'Canada Restricted',
      inputValue: canadaRestricted,
      inputName: 'canadaRestricted',
      explanationValue: canadaRestrictedExplanation,
      explanationInputName: 'canadaRestrictedExplanation',
    },
    {
      label: 'EU Restricted',
      inputValue: euRestricted,
      inputName: 'euRestricted',
      explanationValue: euRestrictedExplanation,
      explanationInputName: 'euRestrictedExplanation',
    },
    {
      label: 'Reach Restricted',
      inputValue: reachRestricted,
      inputName: 'reachRestricted',
      explanationValue: reachRestrictedExplanation,
      explanationInputName: 'reachRestrictedExplanation',
    },
    {
      label: 'Reach Registered',
      inputValue: reachRegistered,
      inputName: 'reachRegistered',
      explanationValue: reachRegisteredExplanation,
      explanationInputName: 'reachRegisteredExplanation',
    },
  ];

  return (
    <FormSection
      isRevealed={isRevealed}
      sectionHeader={CLASSIFICATION_HEADER}
      sectionSubheader={CLASSIFICATION_SUBHEADER}
      setIsRevealed={setIsRevealed}
    >
      <Grid container item spacing={5} xs={12}>
        {CLASSIFICATIONS.map(
          ({
            explanationInputName,
            explanationValue,
            inputName,
            inputValue,
            label,
          }) => (
            <Grid key={explanationInputName} item xs={3}>
              <RadioButtonsWithExplanation
                explanationValue={explanationValue}
                inputName={inputName}
                explanationInputName={explanationInputName}
                inputValue={inputValue}
                label={label}
                setFieldValue={(name, value) =>
                  setFieldValue(`classification.${name}`, value)
                }
              />
            </Grid>
          )
        )}
      </Grid>
    </FormSection>
  );
};
