// Libraries
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Close, ErrorOutlineOutlined } from '@material-ui/icons';
// Utils
import { CustomerViewWarningContext } from '../context/customer-warning.context';

interface ICustomerViewWarning {
  companyName: string;
}

export const CustomerViewWarning: React.FC<ICustomerViewWarning> = ({
  companyName,
}) => {
  const { showCustomerViewWarning, setShowCustomerViewWarning } = useContext(
    CustomerViewWarningContext
  );
  return showCustomerViewWarning ? (
    <div className="fixed bottom-6 rounded-[4px] right-6 flex max-w-[475px] p-6 font-inter text-left text-darkGray text-base bg-yellow-95 shadow-md border border-grey-90">
      <ErrorOutlineOutlined />
      <div className="flex flex-col gap-4 ml-2 mr-4">
        <p>
          You are currently in Customer View. Any
          <br />
          <span>changes will be visible to {companyName}.</span>
        </p>
        <div className="flex gap-6">
          <Link className="font-semibold text-royal-50" to="/">
            Back to PML Employee Area
          </Link>
          <button
            className="font-semibold text-grey-50"
            onClick={() => setShowCustomerViewWarning(false)}
          >
            Dismiss
          </button>
        </div>
      </div>
      <Close
        className="cursor-pointer text-grey-50"
        onClick={() => setShowCustomerViewWarning(false)}
      />
    </div>
  ) : null;
};

export default CustomerViewWarning;
