// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const ProductionLocation: React.FC = () => {
  const { getFieldProps, values } = useFormikContext<InternalBriefFormValues>();

  return (
    <div>
      <p
        id="production-location"
        className="font-inter text-lg text-grey-20 mb-6"
      >
        Production Location
      </p>
      <div
        {...getFieldProps(BRIEF_FORM_KEYS.PRODUCTION_LOCATION)}
        className="md:flex md:flex-row lg:flex lg:flex-row"
      >
        <div className="mb-2 md:mb-0 lg:mb-0 md:mr-9 lg:mr-9">
          <RadioButton
            {...getFieldProps(BRIEF_FORM_KEYS.PRODUCTION_LOCATION)}
            label="location"
            primaryLabel="Miami"
            value="Miami"
            checked={values.productionLocation === 'Miami'}
          />
        </div>
        <div>
          <RadioButton
            {...getFieldProps(BRIEF_FORM_KEYS.PRODUCTION_LOCATION)}
            label="location"
            primaryLabel="Los Angeles"
            value="Los Angeles"
            checked={values.productionLocation === 'Los Angeles'}
          />
        </div>
      </div>
    </div>
  );
};
