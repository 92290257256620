// Libraries
import React from 'react';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
import {
  ArrowDownTrayIcon,
  LinkIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
//Utils
import { ITheme } from 'styles/mui/themeV2';
import { useMobile, useSnackbar } from 'hooks';
import { IApiData, ProjectAttributes } from 'api';

interface IFileCard {
  file: any;
  isDeleting: boolean;
  handleDownload: (e: any) => void;
  handleDelete: (e: any) => void;
  project: IApiData<ProjectAttributes>;
}

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    filesContainer: {
      border: 'solid',
      borderWidth: 'thin',
      borderColor: theme.palette.gray.light,
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(5),
    },
    fileIconsContainer: {
      alignItems: 'center',
      justifyContent: 'end',
      gap: theme.spacing(4),
      paddingRight: theme.spacing(5),
    },
    fileInfoContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      alignItems: 'start',
      flexDirection: 'column',
      color: theme.palette.gray.dark,
    },
    fileDateText: {
      color: theme.palette.gray.main,
      marginTop: theme.spacing(1),
      textAlign: 'left',
    },
  })
);

export const FileCard: React.FC<IFileCard> = ({
  file,
  isDeleting,
  handleDownload,
  handleDelete,
  project,
}) => {
  const classes = useStyles();
  const { isMobile } = useMobile();
  const { showSnackbar } = useSnackbar();

  const uploaderName =
    file.parsedIncluded.createdBy.attributes.givenName +
    ' ' +
    file.parsedIncluded.createdBy.attributes.familyName.charAt(0);

  const uploadDate =
    typeof file.attributes.createdAt === 'string'
      ? new Date(file.attributes.createdAt)?.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : file.attributes.createdAt?.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });

  const handleShareLink = () => {
    const shareUrl = `${window.location.origin}/projects/${project.id}/files/${file.id}`;
    navigator.clipboard.writeText(shareUrl);
    showSnackbar('Link copied to clipboard', 'success');
  };

  return (
    <Grid container xs={12} className={classes.filesContainer}>
      <Grid item container xs={7} md={6} className={classes.fileInfoContainer}>
        <Typography variant={isMobile ? 'body2' : 'body1'}>
          {file.attributes.name}
        </Typography>
        <Typography
          variant={isMobile ? 'caption' : 'body2'}
          className={classes.fileDateText}
        >
          Uploaded by {uploaderName} on {uploadDate}
        </Typography>
      </Grid>
      <Grid container item xs={5} md={6} className={classes.fileIconsContainer}>
        <button onClick={handleShareLink}>
          <LinkIcon className="h-5 w-5 text-grey-20" />
        </button>
        <button data-file-id={file.id} onClick={handleDownload}>
          <ArrowDownTrayIcon className="h-5 w-5 text-grey-20" />
        </button>
        <button
          data-file-id={file.id}
          data-file-name={file.attributes.name}
          onClick={handleDelete}
          disabled={isDeleting}
        >
          <TrashIcon className="h-5 w-5 text-grey-20" />
        </button>
      </Grid>
    </Grid>
  );
};
