// Libraries
import React, { useContext, useState } from 'react';
import {
  Grid,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  createStyles,
  CircularProgress,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
// Utils
import { FormulaContext } from '../context';
import { ExtendedFormulaAttributes } from './types';
import { useApi } from 'api';
import { ITheme } from 'styles/mui/themeV2';
import { useSnackbar } from 'hooks';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    row: {
      backgroundColor: theme.palette.blue.light,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    addPhaseText: {
      marginLeft: theme.spacing(1),
    },
  })
);

export const AddPhaseRow: React.FC = () => {
  const { formula, setFormula } = useContext(FormulaContext)!;
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { postPhase } = useApi();

  const handleAddPhase = () => {
    if (isLoading) return;

    setIsLoading(true);
    postPhase({
      data: { phase: { formulaUuid: formula?.id } },
      handleSuccess: (data: any) => {
        showSnackbar(`Phase ${data.attributes.name} added`, 'success');
        setFormula({
          ...formula,
          phases: [
            ...(formula?.phases || []),
            {
              id: data.id,
              ...data.attributes,
              ingredients: [],
            },
          ],
        } as ExtendedFormulaAttributes);
      },
      handleFinally: () => setIsLoading(false),
    });
  };

  return (
    <TableRow onClick={handleAddPhase} className={classes.row}>
      <TableCell colSpan={12}>
        <Grid container>
          {isLoading ? (
            <CircularProgress size={20} thickness={6} />
          ) : (
            <AddIcon />
          )}
          <Typography className={classes.addPhaseText}>Add phase</Typography>
        </Grid>
      </TableCell>
    </TableRow>
  );
};
