// SERVER STATUS CODES
export const SERVER_STATUS_CODES = {
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

// HTTP METHODS:
export const GET = 'GET';
export const POST = 'POST';
export const PATCH = 'PATCH';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

// LOCATONS
export const LOCATIONS = ['Miami', 'Los Angeles', 'New York'];

// DOMAIN SPECIFIC ITEMS

export const INCI_TYPES = {
  US: 'US',
  EU: 'EU',
};

export const API_TYPES = {
  CLASSIFICATION: 'classification',
  COMPANY: 'company',
  EMPLOYEE: 'employee',
  FORMULA: 'formula',
  INCI: 'inci',
  INGREDIENT: 'ingredient',
  PHASE: 'phase',
  PRICE: 'price',
  MANUFACTURER: 'manufacturer',
  RAW_MATERIAL_INCI: 'rawMaterialInci',
  SAMPLE: 'sample',
  SAMPLE_SUBMISSION: 'sampleSubmission',
  SUPPLIER: 'supplier',
};

export const INCI_REGION_TYPES = {
  US_NAME: 'usName',
  EU_NAME: 'euName',
};

export const BRIEF_TYPES = {
  initial: 'initial',
  final: 'final',
};

export const FORMULA = {
  COLUMNS: [
    {
      columnName: 'external_id',
      labelName: 'Formula #',
    },
    {
      columnName: 'name',
      labelName: 'Formula Name',
    },
    {
      columnName: 'company_name',
      labelName: 'Company Name',
    },
    {
      columnName: 'raw_material_name',
      labelName: 'Raw Material',
    },
    {
      columnName: 'production_recipe_id',
      labelName: 'Recipe #',
    },
  ],
  resource: 'formulas',
};

export const PROJECT = {
  COLUMNS: [
    {
      columnName: 'project',
      labelName: 'Project',
    },
    {
      columnName: 'company',
      labelName: 'Company',
    },
    {
      columnName: 'monday_pulse_id',
      labelName: 'Monday ID',
    },
  ],
  resource: 'projects',
};

export const PROJECT_CATEGORIES: Array<string> = [
  'Sun Care',
  'Skin Care',
  'Hair Care',
  'Body Care',
  'Perfume',
  'Color Cosmetics',
  'Oral Care',
];

// HELPER TEXT
export const INCI_AMOUNT_HELPER_TEXT =
  "Please enter a value. Use '0' if unknown";

export const handleKeyDown = (e: any, callback: any) => {
  e.key === KEY_PRESSES.ENTER && !e.shiftKey && callback();
};

export const KEY_PRESSES = {
  ENTER: 'Enter',
};

// Final brief titles
export const BRIEF_TITLE = {
  basics: 'Project Basics',
  fillSize: 'Fill Size',
  orderQuantity: 'Order Quantity',
  cogs: 'Target Cost of Goods Sold (COGS)*',
  msrp: 'Target Retail Price',
  components: 'Components',
  packaging: 'Packaging',
  ingredients: 'Ingredients',
  fragrance: 'Fragrance',
  benchmarks: 'Benchmarks',
  comments: 'Additional Comments',
};

export const BRIEF_SUBTITLE = {
  benchmarks: 'Identify current products in the market to benchmark against.',
  customerCogs: 'Cost includes chemistry and fill only.',
  employeeCogs:
    'One of these options must be filled out to calculate a pricing quote',
  ingredients: 'List ingredients for your product claims or benefits',
  orderQuantity:
    ' How many units do you expect to produce in the next 12 months?',
};
// Final brief helper text
export const requiredForCalcHelperText = 'Needed to calculate a pricing quote';

export const SEARCH_PARAM_KEYS = {
  TAB: 'tab',
  SHARE_FILE: 'shareFile',
};

export const SORT_OPTIONS = {
  newest: {
    label: 'Newest First (Default)',
    value: 'newest',
  },
  alphabetical: {
    label: 'A - Z',
    value: 'alphabetical',
  },
  fileType: {
    label: 'File Type',
    value: 'fileType',
  },
  oldest: {
    label: 'Oldest First',
    value: 'oldest',
  },
};

export const FORMULA_STATUSES = {
  APPROVED_BY_CUSTOMER: 'Approved by the customer',
  AWAITING_CUSTOMER: 'Awaiting customer',
  IN_FORMULATION: 'In formulation',
  IN_PRODUCTION: 'In production',
  IN_QC: 'In QC',
  NOT_STARTED: 'Not started',
  PENDING_RAW_MATERIAL: 'Pending raw material',
  INTERNAL_ONLY: 'Internal Only',
};
