// Libraries
import React from 'react';

interface FormulaTableHeaderRowProps {
  tableHeaders: Array<{ text: string; style?: string }>;
}

export const TableHeaderRow: React.FC<FormulaTableHeaderRowProps> = ({
  tableHeaders,
}) => {
  return (
    <>
      {tableHeaders.map((header, index) => {
        return (
          <div
            key={`header-${index}`}
            className={`h-full py-6 font-mono font-bold text-xs ${header.style}`}
          >
            {header.text.toUpperCase()}
          </div>
        );
      })}
    </>
  );
};
