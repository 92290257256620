// Libraries
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
// Utils
import { InciAttributes } from 'features/types';
import { useApi, IApiData } from 'api';
import { InciFormValues } from './types';
import { useSnackbar } from 'hooks';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

interface InciFormProps {
  inci: Maybe<IApiData<InciAttributes>>;
  setInci: React.Dispatch<
    React.SetStateAction<Maybe<IApiData<InciAttributes>>>
  >;
  children: React.ReactNode | React.ReactNode[];
}

const validationSchema = Yup.object().shape({
  general: Yup.object().shape({
    usName: Yup.string().required(),
    euName: Yup.string().required(),
  }),
});

export const InciForm: React.FC<InciFormProps> = ({
  children,
  inci,
  setInci,
}) => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { uuid }: { uuid?: string } = useParams();
  const { postInci, patchInci } = useApi();

  const INITIAL_FORM_VALUES: InciFormValues = {
    general: {
      casNumber: inci?.attributes.casNumber || '',
      euName: inci?.attributes.euName || '',
      usName: inci?.attributes.usName || '',
    },
    functions: inci?.attributes.functions || [],
  };

  const handleSubmit = (values: InciFormValues) => {
    const isEditMode = Boolean(uuid);
    const requestFunction = isEditMode ? patchInci : postInci;

    requestFunction({
      urlParams: uuid, // if post request, we can still pass url data, but this will be a blank string,
      data: {
        inci: {
          ...values.general,
          functions: values.functions,
        },
      },
      handleSuccess: (data: IApiData<InciAttributes>) => {
        if (isEditMode) {
          setInci(data);
          showSnackbar('Inci successfully updated!', 'success');
        } else {
          navigate(
            ROUTES.SHOW_INCI.route.replace(UUID_SHOW_ROUTE_STRING, data.id),
            { state: { success: true } }
          );
        }
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_FORM_VALUES}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};
