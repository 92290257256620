// Libraries
import React, { useState } from 'react';
import { IconButton, Modal, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormikContext } from 'formik';
import { CheckIcon } from '@heroicons/react/20/solid';
// Utils
import { TestingAcknowledgementFormValues } from './types';
import { calculateTotalCost } from './utils';
import { IApiData, ThirdPartyTestAttributes } from 'api';
// Constants
import { THIRD_PARTY_TESTS } from './third-party-test.constants';

const AreYouSureModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  close: () => void;
}> = ({ isOpen, handleClose, close }) => {
  return (
    <Modal open={isOpen}>
      <div className="absolute z-[999] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-grey-90 round-[4px]">
        <section className="p-9">
          <h6>
            Are you sure want to close the form? Your changes will not be saved.
          </h6>
        </section>
        <section className="flex border-t border-t-grey-90">
          <button
            className="w-1/2 py-6 border-r border-r-grey-90 text-grey-50 font-inter text-[16px]"
            onClick={close}
          >
            Cancel
          </button>
          <button
            className="w-1/2 py-6 font-inter text-[16px] text-royal-50"
            onClick={handleClose}
          >
            Yes
          </button>
        </section>
      </div>
    </Modal>
  );
};

interface TestingAcknowledgmentFormProps {
  closeModal: () => void;
  acknowledged: Maybe<boolean>;
  thirdPartyTest: Maybe<IApiData<ThirdPartyTestAttributes>>;
}

export const TestingAcknowledgmentForm: React.FC<TestingAcknowledgmentFormProps> = ({
  closeModal,
  acknowledged,
  thirdPartyTest,
}) => {
  const { values, errors, getFieldProps, isSubmitting } = useFormikContext<
    TestingAcknowledgementFormValues
  >();
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState<boolean>(
    false
  );

  return (
    <main className="py-16 mx-auto max-w-5xl min-w-[1024px] w-full">
      <AreYouSureModal
        isOpen={isAreYouSureModalOpen}
        handleClose={closeModal}
        close={() => setIsAreYouSureModalOpen(false)}
      />
      <div className="w-full">
        <section className="flex justify-between mb-6">
          <span>
            <h1 className="text-[28px] font-inter mb-2">
              Testing Acknowledgment
            </h1>
            <p className="font-inter text-sm">Please initial each row.</p>
          </span>
          <span>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </span>
        </section>

        <section>
          <div className="flex sticky top-0 z-[99] h-[82px] bg-darkGray text-white font-mono font-semibold text-[12px] leading-4">
            <h6 className="w-6/12 px-6 py-[33px]">TEST</h6>
            <h6 className="w-3/12 px-6 py-[33px] text-center">COST</h6>
            <div className="w-2/12"></div>
          </div>

          <section className="relative">
            {THIRD_PARTY_TESTS.filter(({ tests }) =>
              tests.some((test) => values[test.name])
            ).map(({ sectionTitle, tests }) => (
              <section>
                <div
                  key={sectionTitle}
                  className="sticky z-50 h-9 top-[82px] flex border border-grey-90 rounded-sm bg-lightestGray"
                >
                  <span className="flex w-11/12 items-center justify-start ml-6">
                    <h6 className="font-mono text-[12px] font-medium">
                      {sectionTitle}
                    </h6>
                  </span>
                </div>
                {tests
                  .filter(({ name }) => values[name])
                  .map(({ label, name }) => (
                    <div
                      key={name}
                      className="flex border border-grey-90 border-t-0 min-h-[80px]"
                    >
                      <span className="w-6/12 flex items-center border-r border-grey-90 px-6">
                        {label}
                      </span>
                      <span className="w-3/12 flex items-center justify-center border-r border-grey-90 px-6">
                        {parseFloat(values[name] as string).toLocaleString(
                          'en-US',
                          {
                            style: 'currency',
                            currency: 'USD',
                          }
                        )}
                      </span>
                      <span className="w-3/12 flex items-center justify-center border-r border-grey-90 px-6">
                        <span className="w-1/2">INITIAL:</span>
                        <span className="w-1/2">
                          <TextField
                            {...getFieldProps(`${name}Initials`)}
                            variant="outlined"
                            disabled={acknowledged}
                            fullWidth
                            type="text"
                            InputProps={{
                              endAdornment: acknowledged && (
                                <CheckIcon
                                  color="#6EC4AD"
                                  width={24}
                                  height={24}
                                />
                              ),
                              style: {
                                backgroundColor: values[`${name}Initials`]
                                  ? '#FFF'
                                  : '#FCF9CF',
                              },
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  ))}
              </section>
            ))}

            <section>
              {values.additionalTests.length > 0 && (
                <>
                  <div className="sticky z-50 h-9 top-[82px] flex border border-grey-90 rounded-sm bg-lightestGray">
                    <span className="flex w-11/12 items-center justify-start ml-6">
                      <h6 className="font-mono text-[12px] font-medium">
                        ADDITIONAL TESTS
                      </h6>
                    </span>
                  </div>
                  {values.additionalTests.map(
                    ({ name, cost, initials }, index) => (
                      <div
                        key={name + index}
                        className="flex border border-grey-90 border-t-0 min-h-[80px]"
                      >
                        <span className="w-6/12 flex items-center border-r border-grey-90 px-6">
                          {name}
                        </span>
                        <span className="w-3/12 flex items-center justify-center border-r border-grey-90 px-6">
                          {cost.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </span>
                        <span className="w-3/12 flex items-center justify-center border-r border-grey-90 px-6">
                          <span className="w-1/2">INITIAL:</span>
                          <span className="w-1/2">
                            <TextField
                              {...getFieldProps(
                                `additionalTests.${index}.initials`
                              )}
                              disabled={acknowledged}
                              variant="outlined"
                              fullWidth
                              type="text"
                              InputProps={{
                                endAdornment: acknowledged && (
                                  <CheckIcon
                                    color="#6EC4AD"
                                    width={24}
                                    height={24}
                                  />
                                ),
                                style: {
                                  backgroundColor: initials
                                    ? '#FFF'
                                    : '#FCF9CF',
                                },
                              }}
                            />
                          </span>
                        </span>
                      </div>
                    )
                  )}
                </>
              )}
            </section>
          </section>

          <div className="flex sticky bottom-0 z-[99] h-[82px]  text-darkGray border border-t-0 border-grey-90 font-inter text-sm leading-4">
            <h6 className="w-6/12 px-6 py-[33px] border-r border-grey-90 bg-lightestGray font-mono text-[12px] font-medium">
              TOTAL COST
            </h6>
            <h6 className="w-3/12 px-6 py-[33px] text-center border-r border-grey-90 font-bold bg-lightestGray">
              {calculateTotalCost(
                Object.keys(values)
                  .filter(
                    (key) =>
                      /* Remove all keys with initials or additional tests or acknowledged */
                      !key.includes('Initials') &&
                      key !== 'additionalTests' &&
                      key !== 'acknowledged'
                  )
                  .map((key) => {
                    return typeof values[key] === 'string'
                      ? parseFloat(values[key] as string)
                      : (values[key] as number) || 0;
                  })
                  // Add additional tests costs
                  .concat(values.additionalTests.map((test) => test.cost || 0))
              )}
            </h6>
            <div className="w-3/12"></div>
          </div>
          <div className="sticky md:static left-0 flex items-center py-8 px-6  text-darkGray border border-t-0 border-grey-90 font-inter text-sm leading-4 w-screen md:w-auto">
            <input
              {...getFieldProps('acknowledged')}
              checked={values.acknowledged}
              disabled={acknowledged}
              type="checkbox"
              className="w-[18px] h-[18px] accent-darkGray mr-4 cursor-pointer"
            />
            <h6 className="w-10/12 md:w-8/12 text-sm">
              By checking this box, client hereby acknowledges that they are
              responsible for all the above costs. 50% deposit to purchase
              material is non-refundable should any testing fail.
            </h6>
          </div>
        </section>
      </div>
      {acknowledged && (
        <section className="sticky md:static left-0 p-3 bg-lighterGray flex flex-col md:flex-row md:justify-end md:items-center text-darkGray border border-t-0 border-grey-90 font-inter text-sm leading-4 w-screen md:w-auto">
          <p className="font-mono text-[12px] text-lightGray font-semibold mt-3 md:mt-0">
            SUBMITTED{' '}
            {new Date(
              thirdPartyTest?.attributes?.updatedAt || ''
            ).toLocaleDateString()}
          </p>
        </section>
      )}

      {Object.keys(errors).length === 0 ? null : (
        <div className="sticky md:static left-0 mt-6 w-screen sm:w-auto ml-2 md:ml-0">
          <p className="text-red-40">
            Please initial for each test and check box before submitting.
          </p>
        </div>
      )}

      <div className="sticky md:static left-0 flex justify-end mt-6 w-screen md:w-auto">
        <button
          className="px-5 py-4 border rounded-[4px]"
          type="button"
          onClick={() =>
            acknowledged ? closeModal() : setIsAreYouSureModalOpen(true)
          }
        >
          Close
        </button>
        {!acknowledged && (
          <button
            className="bg-blue-60 ml-2 px-5 py-4 text-white rounded-[4px] disabled:bg-gray"
            disabled={isSubmitting}
            type="submit"
          >
            Submit
          </button>
        )}
      </div>
    </main>
  );
};
