// Libraries
import React, { useEffect, useState } from 'react';
import {
  Table,
  TablePagination,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
// Components
import { useError, useApi } from 'hooks';
import { FormulasTableBody } from 'features/formula';
// Utils
import * as Constants from 'features/constants';

interface Data {
  formula: string;
  id: string;
  createdAt: number;
  status: string;
}

interface Alignment {
  left: string;
  right: string;
  center: string;
}

interface HeadCell {
  id: keyof Data;
  label: string;
  alignment: keyof Alignment;
}

const headCells: Array<HeadCell> = [
  { id: 'formula', label: 'Formula Name', alignment: 'left' },
  { id: 'id', label: 'Formula #', alignment: 'left' },
  { id: 'createdAt', label: 'Created', alignment: 'left' },
  { id: 'status', label: 'Status', alignment: 'center' },
];

interface IProjectFormulasTableProps {
  projectId: string;
}

export default function ProjectFormulasTable({
  projectId,
}: IProjectFormulasTableProps) {
  const request = useApi();
  const throwError = useError();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formulas, setFormulas] = useState<Array<any>>([]);

  // Pagination
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState<number>(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const parseFormulas = (apiFormulas: Array<any>) => {
    return apiFormulas.map((apiFormula) => ({
      id: apiFormula.id,
      name: apiFormula.attributes.name,
      attributes: {
        id: apiFormula.attributes.externalId,
        createdAt: apiFormula.attributes.createdAt
          ? format(new Date(apiFormula.attributes.createdAt), 'MM/dd/yyyy')
          : '',
        status: apiFormula.attributes.status,
      },
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const apiResponse = await request({
          resource: `/api/v1/projects/${projectId}/formulas?page=${
            page + 1
          }&per_page=${rowsPerPage}`,
          options: {
            settings: Constants.GET,
            scope: 'read:formulas',
          },
        });
        if (apiResponse.data) {
          setFormulas(parseFormulas(apiResponse.data));
          setTotalNumberOfRows(apiResponse.meta.total);
          setIsLoading(false);
        } else {
          throw new Error('Error loading formulas');
        }
      } catch (error) {
        Rollbar.error(error);
        throwError(error as Error);
      }
    })();
  }, [request, rowsPerPage, page, projectId, throwError]);

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="right"></TableCell>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} align={headCell.alignment}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <FormulasTableBody
          sortByCreatedAt
          formulas={formulas}
          isLoading={isLoading}
        />
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalNumberOfRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
