// Libraries
import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import jwtDecode from 'jwt-decode';
import { Navigate, useNavigate } from 'react-router-dom';
import { FormLayout } from 'features/brief/customer-brief/customer-brief-form/layout';
import * as amplitude from '@amplitude/analytics-browser';
// Utils
import { useApi, CustomerAttributes, IApiData, IJWTPayload } from 'api';
import { UserContext } from 'context';
import { useAnalytics } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { ROUTES } from 'features/navigation';

enum InputNames {
  givenName = 'givenName',
  familyName = 'familyName',
  companyName = 'companyName',
}

const validationSchema = yup.object({
  [InputNames.givenName]: yup
    .string()
    .strict()
    .required('First name is required')
    .max(256, 'First name cannot be longer than 256 characters')
    .trim('First name must not include a leading or trailing space'),
  [InputNames.familyName]: yup
    .string()
    .strict()
    .required('Last name is required')
    .max(256, 'Last name cannot be longer than 256 characters')
    .trim('Last name must not include a leading or trailing space'),
  [InputNames.companyName]: yup
    .string()
    .strict()
    .required('Company name is required')
    .max(256, 'Company name cannot be longer than 256 characters')
    .trim('Company name must not include a leading or trailing space'),
});

type CustomerFormValues = yup.InferType<typeof validationSchema>;

const INITIAL_FORM_VALUES: CustomerFormValues = {
  [InputNames.givenName]: '',
  [InputNames.familyName]: '',
  [InputNames.companyName]: '',
};

export const CustomerOnboarding: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { setUserSession, setPermissions, userSession } = useContext(
    UserContext
  )!;
  const analytics = useAnalytics();
  const { postCustomer } = useApi();

  const SUB_TITLE = 'We just need to collect some basic information first';
  const TITLE = 'Lets get started!';

  if (userSession.uuid) {
    return <Navigate to={ROUTES.HOME.route} />;
  }

  const handleSubmit = (
    values: CustomerFormValues,
    { setSubmitting }: FormikHelpers<CustomerFormValues>
  ) => {
    postCustomer({
      data: {
        customer: {
          thirdPartyId: user?.sub,
          email: user?.email,
          ...values,
        },
      },
      handleSuccess: (
        { attributes, id }: IApiData<CustomerAttributes>,
        token: string
      ) => {
        analytics?.trackEvent({
          eventCategory: AnalyticConstants.EVENT_CATEGORIES.customerSignup,
          eventAction: AnalyticConstants.EVENT_ACTIONS.signup,
        });

        // Set the permissions first so that the Can component has the right permissions
        // before the customer gets set and triggers a change in authenticatedApp
        const decoded = jwtDecode<IJWTPayload>(token);
        if (decoded?.permissions?.length) {
          setPermissions(decoded.permissions);
        } else {
          // TODO: Handle this better
          throw new Error('No permissions found for user');
        }

        setUserSession({
          ...userSession,
          uuid: id || '',
          givenName: attributes.givenName,
          familyName: attributes.familyName,
          avatarColor: attributes.avatarColor,
          customerMetadata: {
            ...userSession.customerMetadata,
            companyName: attributes.companyName,
            companyUuid: attributes.companyUuid || '',
          },
        });
        amplitude.track('Customer Brief Started');
        navigate(ROUTES.CREATE_BRIEF.route);
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={{ ...INITIAL_FORM_VALUES }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ getFieldProps, errors, isSubmitting, submitForm }) => (
        <Form>
          <div className="text-left text-darkGray">
            <FormLayout
              buttonText="Submit"
              isBackButtonHidden={true}
              isNextButtonDisabled={isSubmitting}
              title={TITLE}
              subTitle={SUB_TITLE}
              onClick={submitForm}
            >
              <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6 sm:gap-y-6">
                <div className="flex flex-col col-span-2 sm:col-start-2 sm:col-span-6 sm:mb-0 mb-6">
                  <label
                    className="text-left font-mono text-xs text-black font-semibold mb-3"
                    htmlFor={'givenName'}
                  >
                    YOUR FIRST NAME*
                  </label>
                  <input
                    {...getFieldProps(InputNames.givenName)}
                    className={`min-h-[5.5rem] border ${
                      errors.givenName ? 'border-red-40' : 'border-black'
                    } font-display text-xl px-6`}
                    disabled={isSubmitting}
                    id={'givenName'}
                  />
                </div>
                <div className="flex flex-col col-span-2 sm:col-start-2 sm:col-span-6 mb-6">
                  <label
                    className="text-left font-mono text-xs text-black font-semibold mb-3"
                    htmlFor={'familyName'}
                  >
                    YOUR LAST NAME*
                  </label>
                  <input
                    {...getFieldProps(InputNames.familyName)}
                    className={`min-h-[5.5rem] border ${
                      errors.familyName ? 'border-red-40' : 'border-black'
                    } font-display text-xl px-6`}
                    disabled={isSubmitting}
                    id={'familyName'}
                  />
                  <p className="text-left text-sm text-red-40">
                    {errors.familyName}
                  </p>
                </div>
                <div className="flex flex-col col-span-2 sm:col-start-2 sm:col-span-6">
                  <label
                    className="text-left font-mono text-xs text-black font-semibold mb-3"
                    htmlFor={'companyName'}
                  >
                    YOUR COMPANY NAME*
                  </label>
                  <input
                    {...getFieldProps(InputNames.companyName)}
                    className={`min-h-[5.5rem] border ${
                      errors.companyName ? 'border-red-40' : 'border-black'
                    } font-display text-xl px-6`}
                    disabled={isSubmitting}
                    id={'companyName'}
                  />
                  <p className="text-left text-sm text-red-40">
                    {errors.companyName}
                  </p>
                </div>
              </div>
            </FormLayout>
          </div>
        </Form>
      )}
    </Formik>
  );
};
