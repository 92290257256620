// Libraries
import React, { createContext } from 'react';
// Utils
import { NotificationAttributes, IApiData } from 'api';

export const NotificationsContext = createContext<{
  notifications: IApiData<NotificationAttributes>[];
  setNotifications: React.Dispatch<React.SetStateAction<any> | (() => void)>;
  hasNewNotifications: boolean;
  setHasNewNotifications:
    | React.Dispatch<React.SetStateAction<any>>
    | (() => void);
  isNotificationsLoading: boolean;
}>({
  notifications: [],
  setNotifications: () => {},
  hasNewNotifications: false,
  setHasNewNotifications: () => {},
  isNotificationsLoading: false,
});
