import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
// Utils
import { useOnScreen } from './hooks';

interface IBlock {
  index: number;
  isAutoScrolling: boolean;
  isDestinationSection: boolean;
  setDestinationRef: React.Dispatch<React.SetStateAction<any>>;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  [x: string]: any;
}

export const Scrollable: React.FC<IBlock> = ({
  index,
  isAutoScrolling,
  setDestinationRef,
  isDestinationSection,
  setCurrentTab,
  children,
}) => {
  const grid = useRef<any>();
  const isIntersecting = useOnScreen(grid);

  useEffect(() => {
    if (isDestinationSection) setDestinationRef(grid.current);
  }, [isDestinationSection, setDestinationRef]);

  useEffect(() => {
    if ((isIntersecting && !isAutoScrolling) || isDestinationSection) {
      setCurrentTab(index);
    }
  }, [
    index,
    isAutoScrolling,
    isIntersecting,
    setCurrentTab,
    isDestinationSection,
  ]);

  return (
    <Grid ref={grid} container item style={{ scrollMargin: -40 }}>
      {children}
    </Grid>
  );
};
