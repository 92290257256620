// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import { Autocomplete, TextField } from 'design-system';
// Utils
import { useApi } from 'api';
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { UserContext } from 'context';
// Constants
import { ROLES } from 'features/rbac';
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const Basics: React.FC = () => {
  const { errors, getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();
  const { searchCompanies } = useApi();
  const { roles } = useContext(UserContext)!;

  const isEmployee = roles.includes(ROLES.EMPLOYEE);

  return (
    <div>
      <p id="basics" className="font-inter text-lg text-grey-20 mb-6">
        Basics
      </p>
      <div className="flex flex-col gap-y-6">
        <TextField
          required
          label="Name of Project"
          {...getFieldProps(BRIEF_FORM_KEYS.PROJECT_NAME)}
          error={Boolean(errors.projectName)}
          data-testid="project-name"
        />
        {isEmployee ? (
          <div>
            <Autocomplete
              clearField={() => {
                setFieldValue(BRIEF_FORM_KEYS.COMPANY, {
                  label: '',
                  value: '',
                });
                setFieldValue(BRIEF_FORM_KEYS.CONTACT, {
                  label: '',
                  value: '',
                });
              }}
              error={errors.company && errors.company.value}
              helperText={
                'Be careful! Changing the company will move this project and make it visible to the new client'
              }
              label="Company Name"
              onChange={(option: Option) => {
                setFieldValue(BRIEF_FORM_KEYS.COMPANY, option);
              }}
              required
              responseParser={(response) =>
                response?.data.map((option: any) => ({
                  label: option.attributes.name,
                  value: option.id,
                }))
              }
              placeholder="Search"
              search={searchCompanies}
              value={values.company}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
