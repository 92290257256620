import { SUN_CARE } from './sun-care.constants';
import { BRIEF_FORM_KEYS } from '../../brief.constants';

export const SUN_CARE_FORMULAS = [
  {
    // SPF 30 Continuous Spray
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]:
        SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].CONTINUOUS_SPRAY_BOV,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '82625521-29c3-4fae-85c8-696246342423',
  },
  {
    // SPF 30 Lotion
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '9fa61d2b-7d7e-4348-95af-3aa7c1ec0b46',
  },
  {
    // SPF 50 Continuous Spray
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]:
        SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].CONTINUOUS_SPRAY_BOV,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_50,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '8ee25dce-405b-4c4e-bc06-28cf748689a3',
  },
  {
    // SPF 50 Lotion
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_50,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: 'bd23b733-00af-49ec-9798-2dcfa64bb4f9',
  },
  {
    // SPF 30 Mousse
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]:
        SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].MOUSSE_FOAM,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '64ac1b9b-ba6a-4e9d-b24a-dbf924fc3aa3',
  },
  {
    // SPF 50 Sheer Lotion (tinted)
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].HYBRID_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_50,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].YES,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '5d9e48e5-1dcd-4b4a-8f5c-81a82f9b573f',
  },
  {
    // SPF 50 Stick
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].STICK,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_50,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '6857090f-f00d-4aa4-b906-e318d9cbef79',
  },
  {
    // SPF 60 Lotion
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_60,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: 'cb94d783-bbc4-4f0a-9f9d-0673fcb9647f',
  },
  {
    // SPF 85 Lotion
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_85,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: 'c3945b76-1363-491a-ba03-04783b6aa0f3',
  },
  {
    // SPF 50 Mineral Lotion (silicone free)
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].MINERAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_50,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].YES,
    },
    formulaUuid: '982260ac-55d8-4230-8ccd-ecc64ad34587',
  },
  {
    // SPF 30 Mineral Lotion
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].MINERAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: 'dbec25cb-c88e-49fe-915a-9d2794b8dea4',
  },
  {
    // SPF 30 Body Oil
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].OIL,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '6334c328-b43b-4ab6-bec5-e0b1af2f211c',
  },
  {
    // SPF 30 Mineral Lotion (silicone free)
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].MINERAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].YES,
    },
    formulaUuid: 'e9582868-0d71-4205-8121-be25fc76e7a1',
  },
  {
    // SPF 45 Gel
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].GEL,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_45,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '5d951367-abe9-440f-8d1b-ef922614daea',
  },
  {
    // SPF 45 Hybrid Lotion
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].HYBRID_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_45,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: 'a9bb3d35-6d20-483a-9b6f-ffb39b1e2281',
  },
  {
    // SPF 50 Mineral Lotion
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].MINERAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_50,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: 'a0387d96-7811-44dd-882b-85923ae8a9e5',
  },
  {
    // SPF 50 Mineral Tinted Lotion - Silicone free
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].MINERAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].LOTION,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_50,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].YES,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].YES,
    },
    formulaUuid: '4d3638d8-b174-461f-a634-645fc130a565',
  },
  {
    // SPF 30 Mineral c/s
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].MINERAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]:
        SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].CONTINUOUS_SPRAY_BOV,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '8dd51174-d997-4888-91ee-94884c1e606c',
  },
  {
    // SPF 30 Mineral Stick
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].MINERAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].STICK,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: 'a8516425-296d-46f4-9206-4e001fb2e457',
  },
  {
    // SPF 30 Stick (Clear)
    productProperties: {
      [BRIEF_FORM_KEYS.PRODUCT_TYPE]:
        SUN_CARE[BRIEF_FORM_KEYS.PRODUCT_TYPE].CHEMICAL_SUNSCREEN,
      [BRIEF_FORM_KEYS.STRUCTURE]: SUN_CARE[BRIEF_FORM_KEYS.STRUCTURE].STICK,
      [BRIEF_FORM_KEYS.SPF]: SUN_CARE[BRIEF_FORM_KEYS.SPF].SPF_30,
      [BRIEF_FORM_KEYS.TINT]: SUN_CARE[BRIEF_FORM_KEYS.TINT].NO,
      [BRIEF_FORM_KEYS.SILICONE_FREE]:
        SUN_CARE[BRIEF_FORM_KEYS.SILICONE_FREE].NO,
    },
    formulaUuid: '95cca28a-d5b4-4b5d-9b61-f75aa13a394f',
  },
];
