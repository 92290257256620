// Libraries
import React, { useContext } from 'react';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
// Components
import { BatchInfoContainer } from './batch-info-container.component';
import { FormulaContext } from '../context';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import {
  APPEARANCE_METHODS,
  COLOR_METHODS,
  ODOR_METHODS,
  PH_METHODS,
  SOLIDS_PERCENTAGE_METHODS,
  SPECIFIC_GRAVITY_METHODS,
  SPECIFICATIONS_VALIDATION_SCHEMA,
  VISCOSITY_METHODS,
} from './constants';
import { useApi, BatchAttributes, IApiData } from 'api';
// Constants
const STANDARD_TABLE_ROWS = [
  {
    title: 'Appearance',
    name: 'appearance',
    methods: APPEARANCE_METHODS,
  },
  {
    title: 'Color',
    name: 'color',
    methods: COLOR_METHODS,
  },
  {
    title: 'Odor',
    name: 'odor',
    methods: ODOR_METHODS,
  },

  {
    title: 'PH',
    name: 'ph',
    inputType: 'number',
    methods: PH_METHODS,
    inputRange: {
      min: 'Min',
      max: 'Max',
    },
  },
  {
    title: 'Specific Gravity',
    name: 'specificGravity',
    inputType: 'number',
    methods: SPECIFIC_GRAVITY_METHODS,
    inputRange: {
      min: 'Min',
      max: 'Max',
    },
  },
  {
    title: '% Solids',
    name: 'solidsPercentage',
    inputType: 'number',
    methods: SOLIDS_PERCENTAGE_METHODS,
    inputRange: {
      min: 'Min',
      max: 'Max',
    },
  },
];

const VISCOSITY_ROW = {
  title: 'Viscosity',
  name: 'viscosity',
  percentage: 'Percentage',
  reading: 'Reading',
  speed: 'Speed',
  spindle: 'Spindle',
  range: {
    min: 'Min',
    max: 'Max',
  },
};

const NOTES_ROW = {
  title: 'Notes',
  name: 'notes',
};

// used for generating fieldHelpers with formik
enum FIELDS {
  range = 'Range',
  initial = 'Initial',
  overnight = 'Overnight',
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    headerRow: {
      backgroundColor: theme.palette.secondary.dark,
    },
    headerRowText: {
      color: theme.palette.secondary.main,
      fontWeight: 600,
    },
    titleColumn: {
      backgroundColor: theme.palette.gray.light,
      width: '12%',
      '& > h4': {
        fontWeight: 600,
      },
    },
    rangeColumn: {
      width: '30%',
    },
    initialReadColumn: {
      width: '30%',
    },
    overnightReadColumn: {
      width: '30%',
    },
    labelRoot: {
      fontSize: 14,
    },
    tableRowRangeSeparator: {
      position: 'relative',
      marginLeft: 3,
      marginRight: 3,
      top: 18,
    },
    viscosityRangeSeparator: {
      position: 'relative',
      marginLeft: 25,
      marginRight: 3,
      top: 18,
    },
  })
);

interface SpecificationsFormProps {
  batch: IApiData<BatchAttributes>;
  setBatches: React.Dispatch<
    React.SetStateAction<Array<IApiData<BatchAttributes>>>
  >;
}

export const SpecificationsForm: React.FC<SpecificationsFormProps> = ({
  batch,
  setBatches,
}) => {
  const { formula } = useContext(FormulaContext);
  const { patchFormulaBatch } = useApi();
  const { attributes } = batch;
  const classes = useStyles();

  const handleUpdate = (
    newValues: BatchAttributes & {
      [x: string]: Maybe<string | number>;
    }
  ) => {
    // if we haven't changed the inputs contents but we
    // remove focus triggering this function
    if (_.isEqual(newValues, batch.attributes)) return;

    patchFormulaBatch({
      urlParams: `/${formula!.id}/batches/${batch.id}`,
      data: {
        batch: newValues,
      },
      handleSuccess: () => {
        setBatches((batches) => {
          batch.attributes = { ...attributes, ...newValues };
          return [...batches];
        });
      },
    });
  };

  const { getFieldProps, submitForm, errors } = useFormik<
    BatchAttributes & { [x: string]: Maybe<string | number> }
  >({
    initialValues: {
      ...attributes,
      appearanceOvernight: attributes.appearanceOvernight || '',
      appearanceInitial: attributes.appearanceInitial || '',
      appearanceRange: attributes.appearanceRange || '',
      colorOvernight: attributes.colorOvernight || '',
      colorInitial: attributes.colorInitial || '',
      lotNumber: attributes.lotNumber,
      notesOvernight: attributes.notesOvernight || '',
      notesInitial: attributes.notesInitial || '',
      odorOvernight: attributes.odorOvernight || '',
      odorInitial: attributes.odorInitial || '',
      phOvernight: attributes.phOvernight || '',
      phInitial: attributes.phInitial || '',
      phRangeMin: attributes.phRangeMin || '',
      phRangeMax: attributes.phRangeMax || '',
      quantity: attributes.quantity,
      solidsPercentageOvernight: attributes.solidsPercentageOvernight || '',
      solidsPercentageInitial: attributes.solidsPercentageInitial || '',
      solidsPercentageRangeMin: attributes.solidsPercentageRangeMin || '',
      solidsPercentageRangeMax: attributes.solidsPercentageRangeMax || '',
      specificGravityOvernight: attributes.specificGravityOvernight || '',
      specificGravityInitial: attributes.specificGravityInitial || '',
      specificGravityRangeMin: attributes.specificGravityRangeMin || '',
      specificGravityRangeMax: attributes.specificGravityRangeMax || '',
      viscosityReadingMinRange: attributes.viscosityReadingMinRange || '',
      viscosityReadingMaxRange: attributes.viscosityReadingMaxRange || '',
      viscositySpindleRange: attributes.viscositySpindleRange || '',
      viscositySpeedRange: attributes.viscositySpeedRange || '',
      viscosityPercentageOvernight:
        attributes.viscosityPercentageOvernight || '',
      viscosityPercentageInitial: attributes.viscosityPercentageInitial || '',
      viscosityReadingOvernight: attributes.viscosityReadingOvernight || '',
      viscosityReadingInitial: attributes.viscosityReadingInitial || '',
      viscositySpeedOvernight: attributes.viscositySpeedOvernight || '',
      viscositySpeedInitial: attributes.viscositySpeedInitial || '',
      viscositySpindleOvernight: attributes.viscositySpindleOvernight || '',
      viscositySpindleInitial: attributes.viscositySpindleInitial || '',
    },
    validationSchema: SPECIFICATIONS_VALIDATION_SCHEMA,
    onSubmit: handleUpdate,
  });

  const renderTableRows = () => {
    return STANDARD_TABLE_ROWS.map(
      ({ title, name, inputType, methods, inputRange }) => {
        const rangeFieldName = `${name}${FIELDS.range}`;
        const rangeFieldNameMin = `${name}${FIELDS.range}${inputRange?.min}`;
        const rangeFieldNameMax = `${name}${FIELDS.range}${inputRange?.max}`;
        const initialFieldName = `${name}${FIELDS.initial}`;
        const overnightFieldName = `${name}${FIELDS.overnight}`;

        return (
          <TableRow key={title}>
            <TableCell className={classes.titleColumn}>
              <Typography variant="h4">
                {title}&nbsp;
                <Tooltip title={methods.map((method) => method)}>
                  <InfoIcon color="inherit" />
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell className={classes.rangeColumn}>
              {inputType ? (
                <>
                  <TextField
                    {...getFieldProps(`${rangeFieldNameMin}`)}
                    error={rangeFieldNameMin in errors}
                    helperText={errors[rangeFieldNameMin]}
                    InputProps={{
                      type: inputType,
                    }}
                    onBlur={submitForm}
                    variant="outlined"
                    label="Min"
                    style={{ width: '48%' }}
                  />
                  <span className={classes.tableRowRangeSeparator}>-</span>
                  <TextField
                    {...getFieldProps(`${rangeFieldNameMax}`)}
                    error={rangeFieldNameMax in errors}
                    helperText={errors[rangeFieldNameMax]}
                    InputProps={{
                      type: inputType,
                    }}
                    onBlur={submitForm}
                    variant="outlined"
                    label="Max"
                    style={{ width: '48%' }}
                  />
                </>
              ) : (
                <TextField
                  {...getFieldProps(rangeFieldName)}
                  error={rangeFieldName in errors}
                  fullWidth
                  helperText={errors[rangeFieldName]}
                  InputProps={{
                    type: inputType,
                  }}
                  onBlur={submitForm}
                  variant="outlined"
                />
              )}
            </TableCell>
            <TableCell className={classes.initialReadColumn}>
              <TextField
                {...getFieldProps(initialFieldName)}
                error={initialFieldName in errors}
                fullWidth
                helperText={errors[initialFieldName]}
                InputProps={{
                  type: inputType,
                }}
                onBlur={submitForm}
                variant="outlined"
              />
            </TableCell>
            <TableCell className={classes.overnightReadColumn}>
              <TextField
                {...getFieldProps(overnightFieldName)}
                error={overnightFieldName in errors}
                fullWidth
                helperText={errors[overnightFieldName]}
                InputProps={{
                  type: inputType,
                }}
                onBlur={submitForm}
                variant="outlined"
              />
            </TableCell>
          </TableRow>
        );
      }
    );
  };

  const renderViscosityRow = () => {
    const {
      name,
      title,
      speed,
      spindle,
      reading,
      percentage,
      range,
    } = VISCOSITY_ROW;

    const rangeReadingMin = `${name}${reading}${range.min}${FIELDS.range}`;
    const rangeReadingMax = `${name}${reading}${range.max}${FIELDS.range}`;
    const rangeSpindle = `${name}${spindle}${FIELDS.range}`;
    const rangeSpeed = `${name}${speed}${FIELDS.range}`;
    const initialSpindleFieldName = `${name}${spindle}${FIELDS.initial}`;
    const initialSpeedFieldName = `${name}${speed}${FIELDS.initial}`;
    const initialReadingFieldName = `${name}${reading}${FIELDS.initial}`;
    const initialPercentageFieldName = `${name}${percentage}${FIELDS.initial}`;
    const overnightSpindleFieldName = `${name}${spindle}${FIELDS.overnight}`;
    const overnightSpeedFieldName = `${name}${speed}${FIELDS.overnight}`;
    const overnightReadingFieldName = `${name}${reading}${FIELDS.overnight}`;
    const overnightPercentageFieldName = `${name}${percentage}${FIELDS.overnight}`;

    return (
      <TableRow>
        <TableCell
          className={classes.titleColumn}
          style={{ verticalAlign: 'baseline' }}
        >
          <Typography variant="h4">
            {title}&nbsp;
            <Tooltip title={VISCOSITY_METHODS.map((value) => value)}>
              <InfoIcon color="inherit" />
            </Tooltip>
          </Typography>
        </TableCell>

        <TableCell style={{ verticalAlign: 'baseline' }}>
          <Grid container spacing={2}>
            <Grid container style={{ paddingBottom: 7 }}>
              <Grid item xs={12} sm={5}>
                <TextField
                  {...getFieldProps(rangeReadingMin)}
                  label="Min Reading (CPS)"
                  error={rangeReadingMin in errors}
                  helperText={errors[rangeReadingMin]}
                  onBlur={submitForm}
                  variant="outlined"
                  inputProps={{
                    min: 0,
                  }}
                  InputProps={{
                    type: 'number',
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  style={{ width: '160px' }}
                />
              </Grid>
              <span className={classes.viscosityRangeSeparator}>-</span>
              <Grid item xs={12} sm={5}>
                <TextField
                  {...getFieldProps(rangeReadingMax)}
                  label="Max Reading (CPS)"
                  error={rangeReadingMax in errors}
                  helperText={errors[rangeReadingMax]}
                  onBlur={submitForm}
                  variant="outlined"
                  inputProps={{
                    min: 0,
                  }}
                  InputProps={{
                    type: 'number',
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  style={{ width: 154 }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(rangeSpindle)}
                fullWidth
                label="Spindle"
                onBlur={submitForm}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(rangeSpeed)}
                fullWidth
                label="Speed (RPM)"
                error={rangeSpeed in errors}
                helperText={errors[rangeSpeed]}
                onBlur={submitForm}
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  type: 'number',
                }}
              />
            </Grid>
          </Grid>
        </TableCell>

        <TableCell>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(initialSpindleFieldName)}
                fullWidth
                label="Spindle"
                onBlur={submitForm}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(initialSpeedFieldName)}
                fullWidth
                label="Speed (RPM)"
                error={initialSpeedFieldName in errors}
                helperText={errors[initialSpeedFieldName]}
                onBlur={submitForm}
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  type: 'number',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(initialReadingFieldName)}
                fullWidth
                error={initialReadingFieldName in errors}
                helperText={errors[initialReadingFieldName]}
                label="Reading (CPS)"
                onBlur={submitForm}
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  type: 'number',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(initialPercentageFieldName)}
                error={initialPercentageFieldName in errors}
                helperText={errors[initialPercentageFieldName]}
                fullWidth
                label="Percentage (%)"
                onBlur={submitForm}
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  type: 'number',
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(overnightSpindleFieldName)}
                fullWidth
                label="Spindle"
                onBlur={submitForm}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(overnightSpeedFieldName)}
                error={overnightSpeedFieldName in errors}
                helperText={errors[overnightSpeedFieldName]}
                fullWidth
                label="Speed (RPM)"
                onBlur={submitForm}
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  type: 'number',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(overnightReadingFieldName)}
                error={overnightReadingFieldName in errors}
                helperText={errors[overnightReadingFieldName]}
                fullWidth
                label="Reading (CPS)"
                onBlur={submitForm}
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  type: 'number',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...getFieldProps(overnightPercentageFieldName)}
                error={overnightPercentageFieldName in errors}
                helperText={errors[overnightPercentageFieldName]}
                fullWidth
                label="Percentage (%)"
                onBlur={submitForm}
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                InputProps={{
                  type: 'number',
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const renderNotesRow = () => {
    const { title, name } = NOTES_ROW;

    return (
      <TableRow>
        <TableCell
          style={{ verticalAlign: 'baseline' }}
          className={classes.titleColumn}
          colSpan={1}
        >
          <Typography variant="h4">{title}</Typography>
        </TableCell>
        <TableCell>
          <TextField
            {...getFieldProps(`${name}${FIELDS.range}`)}
            fullWidth
            multiline
            minRows={5}
            onBlur={submitForm}
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          <TextField
            {...getFieldProps(`${name}${FIELDS.initial}`)}
            fullWidth
            multiline
            minRows={5}
            onBlur={submitForm}
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          <TextField
            {...getFieldProps(`${name}${FIELDS.overnight}`)}
            fullWidth
            multiline
            minRows={5}
            onBlur={submitForm}
            variant="outlined"
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <BatchInfoContainer
        batch={batch}
        errors={errors}
        getFieldProps={getFieldProps}
        submitForm={submitForm}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell></TableCell>
              <TableCell align="left">
                <Typography variant="h4" className={classes.headerRowText}>
                  Range
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h4" className={classes.headerRowText}>
                  Initial
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h4" className={classes.headerRowText}>
                  24 Hours
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableRows()}
            {renderViscosityRow()}
            {renderNotesRow()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
