// Utils
import { Tank } from './types';

export enum PRODUCTION_LOCATIONS {
  MIAMI = 'Miami',
  LOS_ANGELES = 'Los Angeles',
}

export const TANKS_BY_LOCATION: {
  [x: string]: Tank[];
} = {
  [PRODUCTION_LOCATIONS.MIAMI]: [
    {
      gallons: 100,
      quantity: 1,
    },
    {
      gallons: 200,
      quantity: 1,
    },
    {
      gallons: 500,
      quantity: 2,
    },
    {
      gallons: 1000,
      quantity: 5,
    },
    {
      gallons: 1500,
      quantity: 4,
    },
    {
      gallons: 2000,
      quantity: 2,
    },
    {
      gallons: 2600,
      quantity: 1,
    },
    {
      gallons: 3000,
      quantity: 2,
    },
  ],
  [PRODUCTION_LOCATIONS.LOS_ANGELES]: [
    {
      gallons: 40,
      quantity: 1,
    },
    {
      gallons: 100,
      quantity: 3,
    },
    {
      gallons: 125,
      quantity: 2,
    },
    {
      gallons: 305,
      quantity: 1,
    },
    {
      gallons: 350,
      quantity: 1,
    },
    {
      gallons: 500,
      quantity: 2,
    },
    {
      gallons: 1000,
      quantity: 1,
    },
    {
      gallons: 3200,
      quantity: 1,
    },
  ],
};
