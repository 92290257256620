// Libraries
import React from 'react';
import {
  makeStyles,
  createStyles,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
// Components
import StaticTable from './static-table.component';
// Utils
import { BriefAttributes, IApiData, IngredientAttributes } from 'api';
import {
  calculateExcessValue,
  calculateTotalBatchSize,
  calculateTotalExcessValue,
  checkIfRawMaterialIsStock,
} from './pricing-calculator.component';
import { DataRow } from './types';
import { parseCostFromPrice } from 'features/formula/formula-page/utils';
import { ITheme } from 'styles/mui/themeV2';
// Constants
import { NA, RAW_MATERIALS_BREAKDOWN_TABLE_HEADERS } from './constants';
import { UNITS } from 'features/formula/formula-page/constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

interface ExcessRawMaterialsBreakdownProps {
  brief: BriefAttributes;
  ingredients: Maybe<IApiData<IngredientAttributes>[]>;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    footer: {
      position: 'sticky',
      left: 0,
      bottom: 0,
      top: 0,
      zIndex: 2,
      background: theme.palette.blue.light,
    },
  })
);

const MissingData = ({ children }: { children: any }) => (
  <Typography component={'p'} className="text-red-40">
    {children}
  </Typography>
);

export const ExcessRawMaterialsBreakdown: React.FC<ExcessRawMaterialsBreakdownProps> = ({
  brief,
  ingredients,
}) => {
  const classes = useStyles();
  const excess = calculateExcessValue(
    ingredients,
    calculateTotalBatchSize(brief)
  );

  const getTableDataRow = (): Array<Maybe<DataRow>> => {
    if (!ingredients) return [];

    return ingredients.map((ingredient, idx) => {
      const { attributes } = ingredient;
      const displayCode =
        attributes.rawMaterialRmId ||
        `SAMP-${attributes.rawMaterialSampleCode}`;
      const isStockRM = checkIfRawMaterialIsStock(ingredient);

      return {
        isDisabled: isStockRM,
        values: [
          <Link
            className="underline"
            rel="noopener noreferrer"
            target="_blank"
            to={ROUTES.SHOW_RAW_MATERIAL.route.replace(
              UUID_SHOW_ROUTE_STRING,
              attributes.rawMaterialUuid
            )}
          >
            {displayCode}
          </Link>,
          attributes.rawMaterialName || attributes.rawMaterialTradeName || NA,
          attributes.amount,
          `$${parseCostFromPrice(attributes.highestPrice!, UNITS.LB)?.toFixed(
            2
          )}`,
          attributes.supplier?.name || NA,
          attributes.highestPrice?.moq?.toLocaleString('en-US') ||
            attributes.highestPrice?.quantity_purchased?.toLocaleString(
              'en-US'
            ) || <MissingData>Missing</MissingData>,
          attributes.highestPrice?.moq_unit ||
            attributes.highestPrice?.unit || <MissingData>Missing</MissingData>,
          !excess || !excess[idx].estimatedUsage ? (
            <MissingData>Missing</MissingData>
          ) : (
            excess[idx].estimatedUsage.toFixed(2)
          ),
          isStockRM ? (
            `$0*`
          ) : !excess || !excess[idx].excessValue ? (
            <MissingData>$0</MissingData>
          ) : (
            `$${parseFloat(excess[idx].excessValue.toFixed(2)).toLocaleString(
              'en-US'
            )}${attributes.highestPrice?.moq ? '' : '**'}`
          ),
        ],
      };
    });
  };

  const getTotalExcessValue = () => {
    return parseFloat(
      (
        calculateTotalExcessValue(
          excess?.map(({ excessValue }) => excessValue)
        ) || 0
      ).toFixed(2)
    ).toLocaleString('en-US');
  };

  return (
    <StaticTable
      headers={RAW_MATERIALS_BREAKDOWN_TABLE_HEADERS}
      tableData={getTableDataRow()}
    >
      <TableFooter className={classes.footer}>
        <TableRow>
          <TableCell colSpan={5}>
            <div>
              * Stock raw materials are not added to excess raw materials
            </div>
            <div>** Based on Purchase History. Check MOQ with Supply Chain</div>
          </TableCell>
          <TableCell colSpan={1} />
          <TableCell colSpan={2} align="right">
            Total Excess Raw Cost
          </TableCell>
          <TableCell align="left">${getTotalExcessValue()}</TableCell>
        </TableRow>
      </TableFooter>
    </StaticTable>
  );
};
