//Libraries
import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    marginBottom: 200,
  },
  paper: {
    padding: theme.spacing(8),
  },
}));

interface LegalProps {
  children: React.ReactNode;
  title: string;
}

export const Legal: React.FC<LegalProps> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="md">
      <Grid
        item
        xs={12}
        className={classes.paper}
        elevation={3}
        component={Paper}
      >
        <Typography variant="h1" paragraph align="center" color="primary">
          {title}
        </Typography>
        <br />
        <Typography variant="body1" paragraph align="left">
          {children}
        </Typography>
      </Grid>
    </Container>
  );
};
