// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Checkbox, Typography } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { CheckBoxOption } from './types';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

const TESTS = [
  {
    label: 'In Vitro FDA Critical Wavelength (Broad Spectrum)',
    description: '',
    formikKey: BRIEF_FORM_KEYS.BROAD_SPECTRUM_TEST,
  },
  {
    label: 'Compatibility (Final Component)',
    description: 'See reference chart for # of components needed',
    formikKey: BRIEF_FORM_KEYS.COMPATIBILITY_TEST,
  },
  {
    label: 'Human Repeat Insult Patch Test (HRIPT)',
    description: '',
    formikKey: BRIEF_FORM_KEYS.HRIPT_TEST,
  },
  {
    label:
      'Human Repeat Insult Patch Test with Dermatologist Signature (HRIPT)',
    description: '',
    formikKey: BRIEF_FORM_KEYS.HRIPT_WITH_DERMATOLOGIST_TEST,
  },
  {
    label: 'Human Repeat Insult Patch Test with Hypoallergenic Claim (HRIPT)',
    description: '',
    formikKey: BRIEF_FORM_KEYS.HRIPT_FOR_HYPOALLERGENIC_TEST,
  },
  {
    label: 'Ocular Irritation (In Vitro)',
    description: '',
    formikKey: BRIEF_FORM_KEYS.OCCULAR_TEST,
  },
  {
    label: 'Preservative Efficacy Test (PET)',
    description: 'USP 51',
    formikKey: BRIEF_FORM_KEYS.PET_TEST,
  },
  {
    label: 'SPF Determination: FDA',
    description: '',
    formikKey: BRIEF_FORM_KEYS.SPF_DETERMINATION_FDA_TEST,
  },
  {
    label: 'SPF Determination: ISO',
    description: '',
    formikKey: BRIEF_FORM_KEYS.SPF_DETERMINATION_ISO_TEST,
  },
  {
    label: 'Stability',
    description: 'FT, 4C, 40C',
    formikKey: BRIEF_FORM_KEYS.STABILITY_TEST,
  },
  {
    label: 'Water Activity (Anhydrous)',
    description: '',
    formikKey: BRIEF_FORM_KEYS.WATER_ACTIVITY_TEST,
  },
];

export const Tests: React.FC = () => {
  const { getFieldProps, values } = useFormikContext<InternalBriefFormValues>();

  const renderCheckboxes = () => {
    return TESTS.map((option: CheckBoxOption) => (
      <Checkbox
        key={option.label}
        direction="col"
        primaryLabel={option.label}
        description={option.description}
        {...getFieldProps(option.formikKey)}
        checked={values[option.formikKey as keyof InternalBriefFormValues]}
        disabled={option.disabled}
      />
    ));
  };

  return (
    <div className="flex flex-col gap-6">
      <Typography id="tests" variant="h3" font="inter">
        Tests
      </Typography>

      {renderCheckboxes()}
    </div>
  );
};
