// Libraries
import React from 'react';
import PmLogo from 'img/pm-wordmark-black.svg';
import createYourProduct from 'img/create-your-product.svg';
import { useConfirmation } from 'hooks';

export const NewCustomerAppBar: React.FC = () => {
  const { confirm } = useConfirmation();

  return (
    <div className="w-full px-6 py-[26px] flex justify-between">
      <img
        className="cursor-pointer"
        onClick={async () => {
          const shouldProceed = await confirm({
            prompt: 'Are you sure you want to leave?',
            description: 'Your selections will not be saved.',
          });

          if (!shouldProceed) return;

          window.location.assign('http://www.primematterlabs.com');
        }}
        src={PmLogo}
        style={{ height: '20px' }}
        alt="Prime Matter logo"
      />
      <img src={createYourProduct} alt="Create your product" />
    </div>
  );
};
