// Libraries
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
// Components
import { AddOrEditIngredientModal } from './add-or-edit-ingredient-modal.component';
import { FormulaContext } from '../context';
import { FormulaDetails } from './formula-details.component';
import { IngredientsTable } from './ingredients-table.component';
import { Page } from 'features/ui';
import { UserHistory } from 'features/ui';
import { TabPanel, TabbedMenu } from 'features/ui';
import { Specs } from './specs.component';
import { Loader } from 'features/pricing-quote';
// Utils
import { getIncludedPhases } from 'api/api.utils';
import { ExtendedFormulaAttributes } from './types';
import { useApi } from 'api';
import { useTitle } from 'hooks';
import { ITheme, gTAmericaMonoRegular } from 'styles/mui/themeV2';

enum FORMULA_TAB_VALUES {
  formula = 0,
  specs = 1,
}

interface FormulaRouteParams {
  uuid?: string;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    tabs: {
      backgroundColor: theme.palette.gray.dark,
      color: theme.palette.secondary.main,
      borderRadius: '0.25rem',
    },
    tab: {
      fontFamily: gTAmericaMonoRegular.fontFamily,
    },
    tabContainer: {
      marginTop: theme.spacing(8),
    },
  })
);

export const FormulaPage = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState<valueof<typeof FORMULA_TAB_VALUES>>(
    FORMULA_TAB_VALUES.formula
  );

  const [showAddIngredientModal, setShowAddIngredientModal] = useState<boolean>(
    false
  );
  const openAddIngredientModal = () => setShowAddIngredientModal(true);

  const [formula, setFormula] = useState<Maybe<ExtendedFormulaAttributes>>(
    undefined
  );
  const { uuid }: FormulaRouteParams = useParams();
  const { getFormula } = useApi();

  useTitle(formula?.name);

  useEffect(() => {
    if (uuid) {
      getFormula({
        urlParams: uuid,
        handleSuccess: (data) => {
          const { id, attributes } = data;

          setFormula({
            id,
            ...attributes,
            company: { ...data.company.attributes, id: data.company.id },
            phases: getIncludedPhases(data.phases, data.ingredients),
            noteCreatedOn: attributes.noteCreatedOn
              ? new Date(attributes.noteCreatedOn)
              : undefined,
            noteCreatedBy: data.noteCreatedBy?.attributes?.fullName,
            noteLastModifiedOn: attributes.noteLastModifiedOn
              ? new Date(attributes.noteLastModifiedOn)
              : undefined,
            noteLastModifiedBy: data.noteLastModifiedBy?.attributes?.fullName,
            createdBy: data.createdBy?.attributes?.fullName,
            updatedBy: data.updatedBy?.attributes?.fullName,
            createdAt: attributes.createdAt
              ? new Date(attributes.createdAt)
              : undefined,
            updatedAt: attributes.updatedAt
              ? new Date(attributes.updatedAt)
              : undefined,
          });
        },
      });
    }
  }, [uuid, getFormula]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Page>
      <FormulaContext.Provider
        value={{ formula, setFormula, openAddIngredientModal }}
      >
        <AddOrEditIngredientModal
          isOpen={showAddIngredientModal}
          closeModal={() => setShowAddIngredientModal(false)}
        />
        <FormulaDetails />

        <Grid container className={classes.tabContainer}>
          <Grid style={{ padding: 0 }} item xs={12}>
            <TabbedMenu
              tabValue={tabValue}
              handleTabChange={handleTabChange}
              tabs={[{ label: 'Formula' }, { label: 'Specs' }]}
            />
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={FORMULA_TAB_VALUES.formula}>
              <Loader isLoading={!formula}>
                <IngredientsTable />
              </Loader>
            </TabPanel>
            <TabPanel value={tabValue} index={FORMULA_TAB_VALUES.specs}>
              <Specs />
            </TabPanel>
          </Grid>
        </Grid>
      </FormulaContext.Provider>

      <UserHistory
        createdBy={formula?.createdBy}
        createdAt={formula?.createdAt}
        updatedBy={formula?.updatedBy}
        updatedAt={formula?.updatedAt}
      />
    </Page>
  );
};
