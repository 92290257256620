//Libraries
import { useContext } from 'react';
//Components
import { UserContext } from 'context';

type UsePermissionCheckProps = {
  actions: string[];
};

export const usePermissionCheck = ({
  actions,
}: UsePermissionCheckProps): boolean => {
  const { permissions } = useContext(UserContext)!;

  return actions.every((action) => permissions.includes(action));
};
