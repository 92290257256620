// Libraries
import React, { useEffect, useState } from 'react';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/16/solid';
import { FieldArray, useFormikContext } from 'formik';
// Components
import {
  Button,
  Checkbox,
  TextArea,
  TextField,
  Typography,
} from 'design-system';
// Utils
import { InternalBriefFormValues } from './form.config';

export const Benchmarks = () => {
  const { getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();

  //TODO: Replace with actual data from Formik or other state management
  const [benchmarks, setBenchmarks] = useState([1]);

  useEffect(() => {
    setBenchmarks(
      values.benchmarks.length > 0
        ? values.benchmarks.map((_bmark, index) => index)
        : [1]
    );
  }, [values.benchmarks]);

  const renderBenchmarkFields = () => {
    return benchmarks.map((benchmark, index) => {
      return (
        <div
          key={index}
          className="flex flex-col w-full p-6 rounded-[4px] border border-grey-90"
        >
          <div className="flex items-center justify-between mb-6">
            <Typography variant="h5" font="inter" size="sm" weight="semibold">
              Benchmark {index + 1}
            </Typography>
            <FieldArray
              name="benchmarks"
              render={(arrayHelpers) => {
                if (benchmarks.length <= 1) return null;
                return (
                  <TrashIcon
                    className="h-4 w-4 text-royal-50 cursor-pointer"
                    onClick={() => {
                      benchmarks.splice(index, 1);
                      setBenchmarks([...benchmarks]);
                      arrayHelpers.remove(index);
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="flex gap-9 justify-between">
            <TextField
              label="Brand Name"
              {...getFieldProps(`benchmarks[${index}].brandName`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(`benchmarks[${index}].brandName`, e.target.value)
              }
            />
            <TextField
              label="Product Name"
              {...getFieldProps(`benchmarks[${index}].productName`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(
                  `benchmarks[${index}].productName`,
                  e.target.value
                )
              }
            />
          </div>
          <div>
            <TextField
              label="Product Link"
              placeholder="http://"
              {...getFieldProps(`benchmarks[${index}].url`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(`benchmarks[${index}].url`, e.target.value)
              }
            />
          </div>
          <div className="flex flex-col gap-3">
            <Typography variant="h6" font="inter" weight="bold">
              Likes
            </Typography>
            <Checkbox
              primaryLabel="Appearance"
              {...getFieldProps(`benchmarks[${index}].appearance`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  `benchmarks[${index}].appearance`,
                  e.target.checked
                );
              }}
              checked={values.benchmarks[index]?.appearance}
            />
            <Checkbox
              primaryLabel="Color"
              {...getFieldProps(`benchmarks[${index}].color`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(`benchmarks[${index}].color`, e.target.checked);
              }}
              checked={values.benchmarks[index]?.color}
            />
            <Checkbox
              primaryLabel="Fragrance"
              {...getFieldProps(`benchmarks[${index}].fragrance`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(
                  `benchmarks[${index}].fragrance`,
                  e.target.checked
                )
              }
              checked={values.benchmarks[index]?.fragrance}
            />
            <Checkbox
              primaryLabel="Texture"
              {...getFieldProps(`benchmarks[${index}].texture`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(`benchmarks[${index}].texture`, e.target.checked)
              }
              checked={values.benchmarks[index]?.texture}
            />
            <Checkbox
              primaryLabel="Viscosity"
              {...getFieldProps(`benchmarks[${index}].viscosity`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(
                  `benchmarks[${index}].viscosity`,
                  e.target.checked
                )
              }
              checked={values.benchmarks[index]?.viscosity}
            />
            <TextArea
              placeholder="Tell us more about this benchmark"
              label="Additional Comments"
              {...getFieldProps(`benchmarks[${index}].comment`)}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {renderBenchmarkFields()}
      <FieldArray
        name="benchmarks"
        render={(arrayHelpers) => (
          <Button
            onClick={() => {
              setBenchmarks([...benchmarks, benchmarks.length]);
              arrayHelpers.push({
                brandName: '',
                productName: '',
                url: '',
                appearance: false,
                color: false,
                fragrance: false,
                texture: false,
                viscosity: false,
              });
            }}
            leadingIcon={<PlusCircleIcon />}
            text="Add Benchmark"
            type="secondary"
          />
        )}
      />
    </div>
  );
};
