import React from 'react';
import { CheckIcon } from '@heroicons/react/16/solid';

interface ISwitch {
  isChecked: boolean;
  handleChange: () => void;
}

export const Switch: React.FC<ISwitch> = ({ isChecked, handleChange }) => {
  return (
    <label className="flex cursor-pointer select-none items-center">
      <div className="relative">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleChange()}
          className="sr-only"
        />
        <div
          className={`block h-5 w-[36px] rounded-full transition duration-500 ${
            isChecked ? 'bg-royal-50' : 'bg-grey-90'
          }`}
        ></div>
        <div
          className={`absolute top-[2px] h-4 w-4 rounded-full bg-white transition duration-500 ${
            isChecked ? 'translate-x-[18px]' : 'translate-x-[2px]'
          }`}
        >
          <CheckIcon
            className={` text-royal-50 transition duration-500 ${
              isChecked ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </div>
      </div>
    </label>
  );
};

export default Switch;
