// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
// Components
import { CustomerProjectPage } from './customer-project-page';
import { CustomerDashboardV3 } from './customer-dashboard-v3';
// Utils
import { useApi, IApiData, IApiResponse, ProjectAttributes } from 'api';
import { UserContext } from 'context';
import { useError } from 'hooks';
//Constants
import { NOT_FOUND_STATUS } from 'api/api.constants';
import { ROUTES } from 'features/navigation';
import { ROLES } from 'features/rbac';
import ProtectedComponent from 'features/rbac/protected.component';

export const CustomerPortal: React.FC = () => {
  const {
    roles,
    userSession: { customerMetadata },
  } = useContext(UserContext)!;
  const params = useParams();
  const { getCompanyProjects } = useApi();
  const throwError = useError();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<Array<IApiData<ProjectAttributes>>>(
    []
  );

  const isEmployee = roles.includes(ROLES.EMPLOYEE);

  const companyName =
    customerMetadata.companyName || projects[0]?.attributes?.companyName;

  useEffect(() => {
    getCompanyProjects({
      // Handling for a PML employee accessing a customer's dashboard
      urlParams: `${
        isEmployee ? params.uuid : customerMetadata.companyUuid
      }/projects`,
      handleSuccess: ({
        data,
      }: IApiResponse & {
        data: IApiData<ProjectAttributes>;
      }) => setProjects(data),
      handleError: (error) => {
        if ((error as AxiosError).response?.status === NOT_FOUND_STATUS) {
          throwError(
            new Error(
              'The page you are looking for is temporarily unavailable. Please contact us at ' // email already in error boundary
            )
          );
          Rollbar.warning('Customer has been disassociated from company.');
        } else {
          throwError(error as Error);
        }
      },
      handleFinally: () => setIsLoading(false),
    });
  }, [
    customerMetadata.companyUuid,
    params.uuid,
    roles,
    getCompanyProjects,
    throwError,
    isEmployee,
  ]);

  return (
    <Routes>
      <Route
        index
        element={
          <CustomerDashboardV3
            companyName={companyName}
            projects={projects}
            isLoading={isLoading}
            isEmployee={isEmployee}
          />
        }
      />
      <Route
        path={ROUTES.SHOW_CUSTOMER_PROJECT_V2.route}
        element={
          <ProtectedComponent
            path={ROUTES.SHOW_CUSTOMER_PROJECT_V2.route}
            permission={ROUTES.SHOW_CUSTOMER_PROJECT_V2.visitPermission}
          >
            <CustomerProjectPage projects={projects} />
          </ProtectedComponent>
        }
      />
    </Routes>
  );
};
