// Libraries
import React from 'react';
// Components
import { GiveSampleFeedback } from './give-sample-feedback.component';
import { ApproveSample } from './approve-sample.component';
// Utils
import {
  ProjectType,
  Task,
  TaskStatus,
} from 'features/home/customer-portal/types';
import { useAnalytics } from 'hooks';
import { IApiData, ProjectAttributes } from 'api';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

interface SampleFeedbackActionsProps {
  task: Task;
  handleOpenSampleFeedbackModal: () => void;
  project: IApiData<ProjectAttributes>;
  setProject: (project: any) => void;
}

export const SampleFeedbackActions: React.FC<SampleFeedbackActionsProps> = ({
  task,
  handleOpenSampleFeedbackModal,
  project,
  setProject,
}) => {
  const analytics = useAnalytics()!;
  const [isApproved, setIsApproved] = React.useState(false);
  const isProjectRtl = project.attributes?.projectType === ProjectType.rtl;

  const handleShowModalClick = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.sampleFeedbackModel,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.openFeedbackModal,
    });

    handleOpenSampleFeedbackModal();
  };

  if (task.status === TaskStatus.pending || task.status === TaskStatus.done) {
    if (isProjectRtl) {
      return (
        <ApproveSample
          isApproved={isApproved}
          setIsApproved={setIsApproved}
          project={project as any}
          setProject={setProject}
          taskStatus={task.status}
        />
      );
    }
    return (
      <GiveSampleFeedback
        isSampleFeedbackTaskCompleted={task.status === TaskStatus.done}
        handleOpenSampleFeedbackModal={handleShowModalClick}
      />
    );
  }

  return null;
};
