// Libraries
import { createTheme, Theme } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';
// Assets
import GTAmericaMonoRegularWoff2 from '../../fonts/GTAmericaMono-Regular.woff2';
import InterRegularTtf from '../../fonts/Inter-Regular.ttf';
import AgipoRegExtWoff2 from '../../fonts/AgipoRegExt.woff2';

export const THEME_PALETTE = {
  gray: {
    dark: '#333333',
    main: '#909090',
    light: '#eaeaea',
    lighter: '#f7f7f7',
  },
  tan: {
    main: '#fcdcbe',
    light: '#fff2e7',
  },
  green: {
    dark: '#6ec4ad',
    main: '#c5e7de',
    light: '#eefbf8',
  },
  blue: {
    dark: '#4981c0',
    main: '#cdd9ec',
    light: '#f2f8ff',
  },
  primary: {
    dark: '#cdd9ec',
    main: '#4981c0',
    light: '#f2f8ff',
  },
  secondary: {
    main: '#ffffff',
    dark: '#333333',
  },
};

export const agipoRegularExtended: any = {
  fontFamily: 'Agipo Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Agipo Regular'),\
      url(${AgipoRegExtWoff2}) format('woff2')
    `,
};

export const gTAmericaMonoRegular = {
  fontFamily: 'GT America Regular Mono',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('GT America Regular Mono'),\
      url(${GTAmericaMonoRegularWoff2}) format('woff2')
  `,
};

export const interRegular = {
  fontFamily: 'Inter Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Inter Regular'),\
      url(${InterRegularTtf}) format('ttf')
  `,
};

interface IPalette extends Palette {
  gray: {
    dark: string;
    main: string;
    light: string;
    lighter: string;
  };
  tan: {
    main: string;
    light: string;
  };
  green: {
    dark: string;
    main: string;
    light: string;
  };
  blue: {
    dark: string;
    main: string;
    light: string;
  };
}

export interface ITheme extends Theme {
  palette: IPalette;
  custom: any;
}

declare module '@material-ui/core/styles/createPalette' {
  export interface PaletteOptions {
    gray: {
      dark: string;
      main: string;
      light: string;
      lighter: string;
    };
    tan: {
      main: string;
      light: string;
    };
    green: {
      dark: string;
      main: string;
      light: string;
    };
    blue: {
      dark: string;
      main: string;
      light: string;
    };
  }
}

const themeV2 = createTheme(({
  palette: THEME_PALETTE,
  typography: {
    fontFamily: [
      'Agipo Regular',
      'GT America Regular Mono',
      'Inter Regular',
    ].join(', '),
    h1: {
      fontFamily: 'Agipo Regular',
      fontWeight: 400,
      fontSize: '2.5rem',
    },
    h2: {
      fontFamily: 'Inter Regular',
      fontWeight: 400,
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'Inter Regular',
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    h4: {
      fontFamily: 'GT America Regular Mono',
      fontWeight: 500,
      fontSize: '0.75rem',
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: 'Inter Regular',
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: 'Inter Regular',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.29,
    },
    caption: {
      fontFamily: 'Inter Regular',
      fontWeight: 400,
      fontSize: '0.75rem',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          agipoRegularExtended,
          gTAmericaMonoRegular,
          interRegular,
        ],
      },
    },
    MuiButton: {
      root: {
        backgroundColor: THEME_PALETTE.primary.main,
        '&:hover': {
          backgroundColor: THEME_PALETTE.gray.dark,
          '&:disabled': {
            backgroundColor: THEME_PALETTE.gray.main,
          },
        },
        '&:disabled': {
          backgroundColor: THEME_PALETTE.gray.main,
          color: THEME_PALETTE.secondary.main,
          '&:hover': { backgroundColor: THEME_PALETTE.gray.main },
        },
        color: THEME_PALETTE.secondary.main,
        fontFamily: 'Inter Regular',
        textTransform: 'none',
      },
      containedPrimary: {
        '&:hover': { backgroundColor: THEME_PALETTE.gray.dark },
      },
      containedSecondary: {
        backgroundColor: THEME_PALETTE.secondary.dark,
        color: THEME_PALETTE.secondary.main,
        '&:hover': {
          backgroundColor: THEME_PALETTE.gray.main,
          color: THEME_PALETTE.secondary.dark,
        },
      },
      outlinedSecondary: {
        backgroundColor: THEME_PALETTE.secondary.main,
        border: `1px solid ${THEME_PALETTE.secondary.dark}`,
        color: THEME_PALETTE.secondary.dark,
        '&:hover': {
          backgroundColor: THEME_PALETTE.gray.dark,
          color: THEME_PALETTE.secondary.main,
        },
        '&:disabled': {
          border: 'none',
        },
      },
      sizeLarge: {
        height: '3rem',
        padding: '1rem 1.25rem',
      },
      sizeSmall: {
        height: '2.625rem',
        padding: '0.75rem 1rem',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&.Mui-checked': {
          color: THEME_PALETTE.gray.dark,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&.Mui-checked': {
          color: THEME_PALETTE.gray.dark,
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        backgroundColor: THEME_PALETTE.gray.lighter,
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
          backgroundColor: THEME_PALETTE.gray.lighter,
          boxShadow: '0px 1px 5px black',
        },
        '&.Mui-checked': {
          backgroundColor: THEME_PALETTE.primary.main,
          '&:hover': {
            backgroundColor: `${THEME_PALETTE.primary.main} !important`,
            boxShadow: '0px 1px 5px black',
          },
        },
      },

      switchBase: {
        top: 10,
        left: 10,
        width: 0,
        height: 0,
      },
      track: {
        backgroundColor: THEME_PALETTE.gray.dark,
        '&:hover': {
          backgroundColor: THEME_PALETTE.gray.dark,
        },
      },
    },
  },
  spacing: 4,
  custom: {
    drawer: {
      width: 240,
    },
  },
} as unknown) as ITheme);

export default themeV2;
