// Libraries
import React, { useContext, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';
// Components
import { Button } from './button.component';
// Utils
import { MobileContext } from 'context';

interface PaginationProps {
  numResultsStart: string;
  numResultsEnd: string;
  totalResults: string;
  totalPages: number;
}

const MAX_PAGES_BEFORE_ELLIPSIS = 6;
const LEFT_EDGE_PAGES_DELIMETER = 3;
const RIGHT_EDGE_PAGES_DELIMETER = -3;

export const Pagination: React.FC<PaginationProps> = ({
  numResultsStart,
  numResultsEnd,
  totalResults,
  totalPages,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { isMobile } = useContext(MobileContext);

  const decrementPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const incrementPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onFirstPage = currentPage === 1;
  const onLastPage = currentPage === totalPages;

  const renderPaginationButton = (numberToRender: number) => {
    return (
      <button
        key={numberToRender}
        onClick={() => setCurrentPage(numberToRender)}
      >
        <div
          className={`${
            currentPage === numberToRender
              ? 'bg-royal-90 hover:bg-royal-90'
              : 'bg-white hover:bg-royal-95'
          } flex items-center justify-center border border-grey-90 rounded font-inter text-xs w-[26px] h-[26px]`}
        >
          {numberToRender}
        </div>
      </button>
    );
  };

  const renderPageNumbers = () => {
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    // If there are less than 6 pages, render all page numbers
    if (totalPages <= MAX_PAGES_BEFORE_ELLIPSIS) {
      return (
        <div className="flex gap-1">
          {pageNumbers.map((number) => renderPaginationButton(number))}
        </div>
      );
    }

    // If the current page is within the first 3 pages, render the first 3 pages and the last 3 pages
    const firstThreePages = pageNumbers.slice(0, LEFT_EDGE_PAGES_DELIMETER);
    const lastThreePages = pageNumbers.slice(RIGHT_EDGE_PAGES_DELIMETER);

    if (
      firstThreePages.includes(currentPage) ||
      lastThreePages.includes(currentPage)
    ) {
      return (
        <div className="flex gap-1">
          {firstThreePages.map((number) => renderPaginationButton(number))}
          <div className="flex items-center justify-center w-[26px] h-[26px]">
            ...
          </div>
          {lastThreePages.map((number) => renderPaginationButton(number))}
        </div>
      );
    }

    // If the current page is within the middle pages, render the current page, the two pages on either side of it, then
    // ellipses, then the first and last pages
    const middlePages = pageNumbers.slice(currentPage - 2, currentPage + 1);
    return (
      <div className="flex gap-1">
        {renderPaginationButton(1)}
        <div className="flex items-center justify-center w-[26px] h-[26px]">
          ...
        </div>
        {middlePages.map((number) => renderPaginationButton(number))}
        <div className="flex items-center justify-center w-[26px] h-[26px]">
          ...
        </div>
        {renderPaginationButton(totalPages)}
      </div>
    );
  };

  return (
    <div
      className={`flex ${
        isMobile
          ? 'flex-col gap-3 py-6 items-center'
          : 'flex-row border-t border-grey-90 p-3 justify-between'
      } w-full bg-white`}
    >
      <div>
        Showing <span className="font-semibold">{numResultsStart}</span> to{' '}
        <span className="font-semibold">{numResultsEnd}</span> of{' '}
        <span className="font-semibold">{totalResults}</span>
      </div>
      {isMobile ? (
        <Button text="Load more" type="primary" width="w-full" />
      ) : (
        <div className="flex">
          <button
            disabled={onFirstPage}
            onClick={decrementPage}
            className="mr-6"
          >
            <div className="bg-white hover:bg-royal-95 p-1 border border-grey-90 rounded">
              <ChevronLeftIcon
                className={`h-4 w-4 ${
                  onFirstPage ? 'text-grey-70' : 'text-royal-50'
                }`}
              />
            </div>
          </button>
          {renderPageNumbers()}
          <button
            disabled={onLastPage}
            onClick={incrementPage}
            className="ml-6"
          >
            <div className="bg-white hover:bg-royal-95 p-1 border border-grey-90 rounded">
              <ChevronRightIcon
                className={`h-4 w-4 ${
                  onLastPage ? 'text-grey-70' : 'text-royal-50'
                }`}
              />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};
