//Libraries
import React, { Component } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
// Components
import { Page } from '../ui';
//Assets
import PmLogo from 'img/pm-logo-blue.svg';
// Constants
import { ROUTES } from 'features/navigation';

interface IErrorBoundaryProps {
  children: React.ReactElement | React.ReactElement[];
}
interface IErrorBoundaryState {}

export class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  state = {
    hasError: false,
    userText: '',
  };

  static getDerivedStateFromError(error: any) {
    /*
      check if the error is an instance of a generic Error
      rather than SyntaxError, or EvalError, etc.
      When a generic Error is shown we can show the text to the
      user to be more informative
    */
    if (error.name === 'Error') {
      // Update state so the next render will show the fallback UI.
      return {
        hasError: true,
        userText: error.message,
      };
    } else {
      return {
        hasError: true,
      };
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // We can report errors to the console and to Rollbar here
    console.log({ error, stackTrace: info.componentStack });
    Rollbar.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="bg-lightestBlue h-screen pt-4">
          <Page>
            <Grid container justifyContent="center">
              <Paper
                elevation={4}
                style={{ padding: '40px', maxWidth: '70%', height: '40rem' }}
              >
                <Grid
                  container
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  style={{ height: '100%' }}
                >
                  <img
                    src={PmLogo}
                    alt="Prime Matter Lockup"
                    style={{
                      width: '100%',
                      maxWidth: '200px',
                      minWidth: '0px',
                    }}
                  />
                  <br />
                  <Typography variant="h1">There was an error</Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ padding: '0 50px' }}
                  >
                    {this.state.userText ||
                      'Please try again later or if the error persists contact support at '}
                    <a
                      className="underline"
                      href={`mailto:support@primematterlabs.com`}
                    >
                      support@primematterlabs.com
                    </a>
                  </Typography>
                  <Grid item style={{ marginTop: '2rem' }}>
                    <Button
                      onClick={() => (window.location.href = ROUTES.HOME.route)}
                    >
                      Go Home
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Page>
        </div>
      );
    }

    return this.props.children;
  }
}
