// Libraries
import React, { useEffect, useState } from 'react';
import { Grid, createStyles, makeStyles } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
// Components
import { Loader } from 'features/pricing-quote';
import { FileFooter } from './file-footer.component';
import { NoFiles } from './no-files.component';
import { FileCard } from './file-card.component';
//Utils
import { IApiData, ProjectAttributes, useApi } from 'api';
import { ITheme } from 'styles/mui/themeV2';
// Constants
import { SEARCH_PARAM_KEYS, SORT_OPTIONS } from 'features/constants';
import { FileSort } from './file-sort.component';

interface IFileManager {
  project: IApiData<ProjectAttributes>;
}
// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
    },
  })
);

export const FileManager: React.FC<IFileManager> = ({ project }) => {
  const { getProjectFileUrl, getProjectFiles, deleteProjectFile } = useApi();

  const classes = useStyles();
  const { id } = project;

  const [searchParams] = useSearchParams();

  // State variables
  const [files, setFiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [fileShareId, setFileShareId] = useState<string>('');
  const [sortedBy, setSortedBy] = useState<string>(SORT_OPTIONS.newest.value);

  // Initial fetch of files
  useEffect(() => {
    getProjectFiles({
      // files are called 'documents' in the API
      urlParams: `${id}/documents`,
      handleSuccess: ({ data }) => {
        setFiles(data);
      },
      handleFinally: () => setIsLoading(false),
    });
  }, [getProjectFiles, id]);

  // Check for file share query param
  useEffect(() => {
    const fileId = searchParams.get(SEARCH_PARAM_KEYS.SHARE_FILE);

    if (fileId) {
      setFileShareId(fileId);
    }
  }, [searchParams]);

  const handleFileDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    const fileName = e.currentTarget.getAttribute('data-file-name')!;

    if (!window.confirm(`Delete ${fileName}?`)) return;

    setIsDeleting(true);

    const uriEncodedUuid = e.currentTarget.getAttribute('data-file-id')!;

    deleteProjectFile({
      // files are called 'documents' in the API
      urlParams: `${id}/documents/${uriEncodedUuid}`,
      handleSuccess: ({ data }) => {
        setFiles(data);
      },
      handleFinally: () => setIsDeleting(false),
    });
  };

  const handleFileDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    const uriEncodedUuid = e.currentTarget.getAttribute('data-file-id')!;

    getProjectFileUrl({
      // files are called 'documents' in the API
      urlParams: `${id}/documents/${uriEncodedUuid}?format=url`,
      handleSuccess: ({ data }) => {
        // this initiates an auto download to the browser
        let a = document.createElement('a');
        a.href = data.presigned_url;
        a.download = data.name;
        a.click();
        a.remove();
      },
    });
  };

  const sortedFiles = () => {
    switch (sortedBy) {
      case SORT_OPTIONS.alphabetical.value:
        return files.sort((a: any, b: any) => {
          return a.attributes.name.localeCompare(b.attributes.name);
        });
      case SORT_OPTIONS.fileType.value:
        return files.sort((a: any, b: any) => {
          const extensionA = a.attributes.name.split('.').pop();
          const extensionB = b.attributes.name.split('.').pop();
          return extensionA.localeCompare(extensionB);
        });
      case SORT_OPTIONS.oldest.value:
        return files.sort((a: any, b: any) => {
          return (
            new Date(a.attributes.createdAt).getTime() -
            new Date(b.attributes.createdAt).getTime()
          );
        });
      default:
        // newest to oldest
        return files.sort((a: any, b: any) => {
          return (
            new Date(b.attributes.createdAt).getTime() -
            new Date(a.attributes.createdAt).getTime()
          );
        });
    }
  };

  const filteredFiles = fileShareId
    ? files.filter((file: any) => file.id === fileShareId)
    : sortedFiles();

  const handleSortSelection = (e: React.ChangeEvent<any>) => {
    setSortedBy(e.target.value as string);
  };

  return (
    <Loader isLoading={isLoading}>
      <Grid className={classes.root}>
        <FileSort sortedBy={sortedBy} handleSort={handleSortSelection} />
        {filteredFiles.length > 0 ? (
          filteredFiles.map((file: any) => {
            return (
              <FileCard
                file={file}
                isDeleting={isDeleting}
                handleDownload={handleFileDownload}
                handleDelete={handleFileDelete}
                key={file.id}
                project={project}
              />
            );
          })
        ) : (
          <NoFiles />
        )}
        <FileFooter />
      </Grid>
    </Loader>
  );
};
