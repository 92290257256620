// Libraries
import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  FormControlLabel,
  Grid,
  TextField,
  Checkbox,
  Switch,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
// Components
import { DialogModal } from 'features/ui';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { IRawMaterialFormValues } from './raw-material-form.component';
import { Inci } from '../types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    row: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

interface EditCompBreakdownModalProps {
  open: boolean;
  nameOfInciToEdit: string;
  setIsEditCompBreakdownModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  clearVerifications: () => void;
}

const ALLERGEN = 'allergen';
const INCIDENTAL = 'incidental';

export const EditCompBreakdownModal: React.FC<EditCompBreakdownModalProps> = ({
  nameOfInciToEdit,
  open,
  setIsEditCompBreakdownModalOpen,
  clearVerifications,
}) => {
  const { values, setFieldValue } = useFormikContext<IRawMaterialFormValues>();
  const [editModalValues, setEditModalValues] = useState({
    ...(values.incis.find((inci) => inci.usName === nameOfInciToEdit) as Inci),
  });
  const [showUsApiFields, setShowUsApiFields] = useState<boolean>(
    Boolean(editModalValues.usApiDrugName)
  );

  const handleSwitchChange = () => {
    // Auto-populate the US API Drug Name field if there is an Inci name to use
    !showUsApiFields &&
      !editModalValues.usApiDrugName &&
      setEditModalValues({
        ...editModalValues,
        usApiDrugName: nameOfInciToEdit,
      });

    // Clear US API values if user closes the US API fields
    showUsApiFields &&
      setEditModalValues({
        ...editModalValues,
        usApiDrugName: undefined,
        usApiMin: undefined,
        usApiMax: undefined,
        usApiPurityFactor: undefined,
      });

    setShowUsApiFields(!showUsApiFields);
  };

  const classes = useStyles();
  const calculatedAmount =
    (editModalValues.minPercentage + editModalValues.maxPercentage) / 2;

  const submitButtonDisabled =
    showUsApiFields &&
    (!editModalValues.usApiDrugName ||
      editModalValues.usApiMin === null ||
      editModalValues.usApiMax === null ||
      editModalValues.usApiPurityFactor === null);

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditModalValues({
      ...editModalValues,
      [e.target.name]: Number(e.target.value),
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === INCIDENTAL && !event.target.checked) {
      setEditModalValues({
        ...editModalValues,
        incidental: false,
        allergen: false,
      });
    } else if (event.target.name === ALLERGEN) {
      setEditModalValues({
        ...editModalValues,
        incidental: true,
        allergen: event.target.checked,
      });
    } else {
      setEditModalValues({
        ...editModalValues,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const handleUsApiDrugNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditModalValues({
      ...editModalValues,
      usApiDrugName: event.target.value,
    });
  };

  const handleEditSubmission = () => {
    const newIncis = values.incis.map((inci) => {
      if (inci.usName === nameOfInciToEdit) {
        return {
          ...inci,
          ...editModalValues,
          usAmount: inci.incidental ? 0 : calculatedAmount,
          euAmount: calculatedAmount,
        };
      } else {
        return inci;
      }
    });

    setFieldValue('incis', newIncis);
    setIsEditCompBreakdownModalOpen(false);
  };

  const handleDelete = () => {
    const newIncis = values.incis.filter(
      (inci) => inci.usName !== nameOfInciToEdit
    );
    setFieldValue('incis', newIncis);
    setIsEditCompBreakdownModalOpen(false);
    clearVerifications();
  };

  const renderModalContent = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            className={classes.row}
            value={editModalValues.usName || ''}
            disabled
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid container item spacing={3} xs={12}>
          <Grid className={classes.row} item xs={3}>
            <TextField
              name="minPercentage"
              label="Range Min (%)"
              value={editModalValues.minPercentage || 0}
              inputProps={{ min: 0 }}
              type="number"
              variant="outlined"
              onChange={handleNumberChange}
              required
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>
          <div style={{ verticalAlign: 'middle', alignSelf: 'center' }}>-</div>
          <Grid className={classes.row} item xs={3}>
            <TextField
              name="maxPercentage"
              value={editModalValues.maxPercentage || 0}
              type="number"
              inputProps={{ min: 0 }}
              variant="outlined"
              onChange={handleNumberChange}
              label="Range Max (%)"
              required
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>
          <Grid
            style={{ marginLeft: 'auto' }}
            className={classes.row}
            item
            xs={5}
          >
            <TextField
              name="concentrationLevel"
              value={editModalValues.concentrationLevel}
              type="number"
              inputProps={{ min: 0 }}
              InputProps={{
                startAdornment: <div className="text-gray mr-2">X</div>,
              }}
              variant="outlined"
              onChange={handleNumberChange}
              label="Concentration Level"
              InputLabelProps={{ style: { fontSize: 13 } }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3} xs={12}>
          <FormControlLabel
            name="incidental"
            label="Incidental"
            control={
              <Checkbox
                checked={editModalValues.incidental}
                onChange={handleCheckboxChange}
              />
            }
          />
          <FormControlLabel
            name="allergen"
            label="Allergen"
            control={
              <Checkbox
                checked={editModalValues.allergen}
                onChange={handleCheckboxChange}
              />
            }
          />
          <FormControlLabel
            name="preservative"
            label="Preservative"
            control={
              <Checkbox
                checked={editModalValues.preservative}
                onChange={handleCheckboxChange}
              />
            }
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <Grid item>
            <FormControlLabel
              label={'US API'}
              control={
                <Switch
                  checked={showUsApiFields}
                  color="primary"
                  onChange={handleSwitchChange}
                />
              }
            />
          </Grid>
        </Grid>
        {showUsApiFields && (
          <>
            <Grid xs={12} item>
              <TextField
                label="US API Drug Name"
                name="usApiDrugName"
                fullWidth
                variant="outlined"
                value={editModalValues.usApiDrugName}
                onChange={handleUsApiDrugNameChange}
              />
            </Grid>
            <Grid xs={12} container item spacing={4}>
              <Grid item xs={3}>
                <TextField
                  label="US API Min"
                  type="number"
                  name="usApiMin"
                  variant="outlined"
                  value={editModalValues.usApiMin}
                  onChange={handleNumberChange}
                  inputProps={{
                    step: '0.1',
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="US API Max"
                  name="usApiMax"
                  variant="outlined"
                  value={editModalValues.usApiMax}
                  onChange={handleNumberChange}
                  type="number"
                  inputProps={{
                    step: '0.1',
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="US API Purity Factor"
                  name="usApiPurityFactor"
                  variant="outlined"
                  value={editModalValues.usApiPurityFactor}
                  onChange={handleNumberChange}
                  type="number"
                  inputProps={{
                    step: '0.1',
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <DialogModal
      submitDisabled={submitButtonDisabled}
      deleteButtonText="Remove"
      open={open}
      onConfirm={handleEditSubmission}
      onDelete={handleDelete}
      title="Edit Compositional Breakdown"
      handleClose={() => setIsEditCompBreakdownModalOpen(false)}
    >
      {renderModalContent()}
    </DialogModal>
  );
};
