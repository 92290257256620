// Libraries
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
// Assets
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
// Components
import { TabbedMenu } from 'features/ui';
import { ProjectTitleAndTags } from '../ui';
import { ProjectTracker } from './project-tracker';
import { FileManager } from './file-manager';
import { CustomerViewWarning } from '../ui/customer-view-warning.component';
// Utils
import { IApiData, ProjectAttributes, useApi } from 'api';
import { renderTargetCost } from '../utils';
import { CustomerBriefView } from './customer-brief-view';
import { useAnalytics, useError } from 'hooks';
//Constants
import { NOT_FOUND_STATUS } from 'api/api.constants';
import { NOT_APPLICABLE } from '../constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { AnalyticsConstants } from 'features/analytics';

interface ICustomerProjectPage {
  isEmployee?: boolean;
  projects?: IApiData<ProjectAttributes>[];
}

export const CustomerProjectPage: React.FC<ICustomerProjectPage> = ({
  isEmployee,
  projects,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const analytics = useAnalytics()!;
  const location = useLocation();
  const { getProject } = useApi();
  const { uuid } = useParams<{ uuid: string }>();
  const throwError = useError();

  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  const [tabValue, setTabValue] = useState<number>(tab ? parseInt(tab) : 0);
  const [project, setProject] = useState<IApiData<ProjectAttributes>>();
  const hasTasks = project?.parsedIncluded?.tasks?.length > 0;

  const handleLinkClick = (e: any) => {
    if (!project) return;

    e.preventDefault();
    const url = isEmployee
      ? ROUTES.EMPLOYEE_VIEW_CUSTOMER_DASHBOARD.route.replace(
          UUID_SHOW_ROUTE_STRING,
          project.attributes.company.data.id
        )
      : ROUTES.HOME.route;
    window.location.href = url;
  };

  useEffect(() => {
    const fetchProject = async () => {
      if (!uuid) {
        throwError(new Error('Project not found.'));
        return;
      }

      await getProject({
        urlParams: uuid,
        handleSuccess: (response) => {
          setProject({
            ...response,
            parsedIncluded: {
              brief: response.brief,
              formulas: response.formulas,
              tasks: response.tasks,
            },
          });
        },
        handleError: (error) => {
          if ((error as AxiosError).response?.status === NOT_FOUND_STATUS) {
            throwError(
              new Error(
                'The page you are looking for is temporarily unavailable. Please contact us at ' // email already in error boundary
              )
            );
            Rollbar.warning('Customer has been disassociated from company.');
          } else {
            throwError(error as Error);
          }
        },
        handleFinally: () => null,
      });
    };

    fetchProject();
  }, [getProject, throwError, uuid, projects, setProject]);

  const menuTabs = [
    ...(hasTasks ? [{ label: 'Timeline' }] : []),
    { label: 'Files' },
    { label: 'Project Brief' },
  ];

  let Component;
  switch (tabValue) {
    case 0:
      Component = hasTasks ? ProjectTracker : FileManager;
      break;
    case 1:
      Component = hasTasks ? FileManager : CustomerBriefView;
      break;
    case 2:
      Component = CustomerBriefView;
      break;
    default:
      Component = hasTasks ? ProjectTracker : FileManager;
  }

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    analytics.trackEvent({
      eventCategory: AnalyticsConstants.EVENT_CATEGORIES.CBDashboard,
      eventAction: AnalyticsConstants.EVENT_ACTIONS.click,
      eventLabel: `${menuTabs[newValue].label} Tab`,
    });
    setTabValue(newValue);
  };

  // Placeholder function to render dummy CTAs
  // const renderCtas = (project: IApiData<ProjectAttributes>) => {
  //   return (
  //     <div className="cursor-default flex flex-col gap-1 col-span-12">
  //       <Cta
  //         title="sample info"
  //         type="cta"
  //         text="some info"
  //         actions={[
  //           { name: 'action 1', clickHandler: () => null },
  //           { name: 'action 2', clickHandler: () => null },
  //         ]}
  //       />
  //       <Cta
  //         type="error"
  //         text="some error"
  //         title="sample error"
  //         actions={[
  //           { name: 'action 1', clickHandler: () => null },
  //           { name: 'action 2', clickHandler: () => null },
  //         ]}
  //       />
  //     </div>
  //   );
  // };

  return (
    <>
      {project && (
        <main className="grid grid-cols-12 max-w-7xl mx-5 sm:mx-auto pb-[130px]">
          <div className="col-span-12 sm:col-start-2 sm:col-span-10 flex flex-col">
            <div className="flex text-sm font-inter text-lightGray mt-12 items-center">
              <div className="cursor-pointer" onClick={handleLinkClick}>
                <div className="flex items-center">
                  <ChevronLeftIcon className="h-5 w-5" />
                  {`Back to Your Projects`}
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div className="flex gap-5 sm:gap-0 sm:flex-row flex-col items-center justify-between mt-6 mb-7">
              <div className="w-full overflow-hidden">
                <ProjectTitleAndTags project={project} large />
              </div>
              <div className="flex p-5 border border-lighterGray rounded">
                <div className="flex text-left min-w-[300px]">
                  <div className="w-full flex flex-col">
                    <div className="whitespace-nowrap uppercase font-mono font-medium text-xs text-lightGray">
                      UNITS
                    </div>
                    <div className="font-inter text-lg text-darkGray">
                      {project.parsedIncluded.brief.attributes.minimumOrderQuantity?.toLocaleString() ||
                        NOT_APPLICABLE}
                    </div>
                  </div>
                  <div className=" w-full flex flex-col">
                    <div className="whitespace-nowrap uppercase font-mono font-medium text-xs text-lightGray">
                      TARGET COST
                    </div>
                    <div className="font-inter text-lg text-darkGray">
                      {renderTargetCost(
                        project.parsedIncluded.brief.attributes
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* //TODO: reinstate when we have info and error logic */}
            {/* {renderCtas(project)} */}
            <div className="mt-5 mb-3">
              <TabbedMenu
                tabValue={tabValue}
                tabs={menuTabs}
                handleTabChange={handleTabChange}
                indicatorColor="#6ec4ad"
              />
            </div>
            {Component && (
              <Component project={project} setProject={setProject as any} />
            )}
          </div>
          {isEmployee && (
            <CustomerViewWarning
              companyName={project.attributes.company.data.attributes.name}
            />
          )}
        </main>
      )}
    </>
  );
};
