// Libraries
import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// Components
import { CustomerOnboarding } from './customer-onboarding.component';
import { EmployeeOnboarding } from './employee-onboarding.component';
import { UserContext } from 'context';
// Constants
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'features/navigation';
import { ROLES } from 'features/rbac';

export const OnboardingWrapper: React.FC = () => {
  const { roles } = useContext(UserContext)!;
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.SIGNIN.route} />;
  }

  const isEmployee = roles && roles.includes(ROLES.EMPLOYEE);

  return isEmployee ? <EmployeeOnboarding /> : <CustomerOnboarding />;
};
