// Libraries
import React, { useEffect, useState } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// Components
import { TestingAcknowledgmentForm } from './testing-acknowledgment-form.component';
// Utils
import {
  IApiData,
  ProjectAttributes,
  TaskAttributes,
  ThirdPartyTestAttributes,
  useApi,
} from 'api';
import { TestingAcknowledgementFormValues } from './types';
import { useSnackbar } from 'hooks';
import { RESOURCE_TYPES } from 'api/api.constants';
import { TaskStatus } from 'features/home/customer-portal/types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TestingAcknowledgementModalProps {
  isOpen: boolean;
  closeModal: () => void;
  project: IApiData<ProjectAttributes> & {
    thirdPartyTest: Maybe<IApiData<ThirdPartyTestAttributes>>;
  };
  setProject: (project: IApiData<ProjectAttributes>) => void;
}

export const TestingAcknowledgementModal: React.FC<TestingAcknowledgementModalProps> = ({
  isOpen,
  closeModal,
  project,
  setProject,
}) => {
  const { showSnackbar } = useSnackbar();
  const { patchProjectThirdPartyTest } = useApi();
  // When the project comes from the dashboard the third party test is in the parsedIncluded object
  // Otherwise if its fetched from the show action the third party test is in the project object directly
  const thirdPartyTest =
    (project as any)?.parsedIncluded?.thirdPartyTest || project.thirdPartyTest;

  // This gets set after we patch the third party test
  const acknowledged = thirdPartyTest?.attributes?.acknowledged;

  // Initial validation schema, will be updated dynamically
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      acknowledged: Yup.boolean().oneOf([true]).required(),
      additionalTests: Yup.array().of(
        Yup.object().shape({
          initials: Yup.string().required(
            'Initials are required for this test.'
          ),
        })
      ),
    })
  );

  useEffect(() => {
    if (!thirdPartyTest) return;

    setValidationSchema((prev: any) => {
      // Get all required initials from the third party test
      const requiredInitials = Object.keys(
        _.pickBy(
          _.omit(thirdPartyTest?.attributes || {}, [
            'createdAt',
            'updatedAt',
            'acknowledged',
            'additionalTests',
          ]),
          (value: Maybe<string | null>, key: string) =>
            key.includes('Initials') && value !== null && value !== undefined
        )
      );

      return prev.shape({
        // Add required initials to the validation schema
        ...requiredInitials.reduce((acc: any, key: string) => {
          acc[key] = Yup.string().required(
            'Initials are required for this test.'
          );
          return acc;
        }, {} as any),
      });
    });
  }, [thirdPartyTest]);

  const initialFormValues: TestingAcknowledgementFormValues = {
    ..._.pickBy(
      _.omit(thirdPartyTest?.attributes || {}, [
        'createdAt',
        'updatedAt',
        'acknowledged',
        'additionalTests',
      ]),
      (value) => value !== null || value === undefined
    ),
    additionalTests: thirdPartyTest?.attributes?.additionalTests ?? [],
    acknowledged: thirdPartyTest?.attributes?.acknowledged ?? false,
  };

  const handleTestingAcknowledgementFormSubmit = (
    values: TestingAcknowledgementFormValues
  ) => {
    patchProjectThirdPartyTest({
      urlParams: `/${project.id}/${RESOURCE_TYPES.THIRD_PARTY_TESTS}/${thirdPartyTest?.id}`,
      data: {
        thirdPartyTest: values,
      },
      handleSuccess: (data) => {
        setProject({
          ...project,
          thirdPartyTest: data,
          parsedIncluded: {
            ...project.parsedIncluded,
            tasks: project.parsedIncluded.tasks.map(
              (parsedTask: IApiData<TaskAttributes>) => {
                // TODO: use constant
                return parsedTask.attributes.mondayColumnId === 'status__1'
                  ? {
                      ...parsedTask,
                      attributes: {
                        ...parsedTask.attributes,
                        status: TaskStatus.done,
                      },
                    }
                  : parsedTask;
              }
            ),
          },
        } as any);

        showSnackbar(
          'Thank you for signing and submitting the Third Party Testing Acknowledgement form.',
          'success'
        );

        closeModal();
      },
    });
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      open={isOpen}
      onClose={closeModal}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleTestingAcknowledgementFormSubmit}
      >
        <Form>
          <TestingAcknowledgmentForm
            closeModal={closeModal}
            acknowledged={acknowledged}
            thirdPartyTest={thirdPartyTest}
          />
        </Form>
      </Formik>
    </Dialog>
  );
};
