// Libraries
import React, { useState, useEffect } from 'react';
import { Dialog, TextField } from '@material-ui/core';
// Utils
import { useApi } from 'api';
import { useSnackbar } from 'hooks';
// Styles
import { THEME_PALETTE } from 'styles/mui/themeV2';
import { FeedbackStatus, FeedbackType } from '../../types';

interface IngredientListFeedbackModalProps {
  files: any[];
  isOpen: boolean;
  handleClose: () => void;
  setFiles: (files: any) => void;
  isReadOnly?: boolean;
  viewedFile?: any;
}

const MODAL_CONTENT = {
  submitFeedback: {
    content: 'Type your feedback here:',
    disabledContent: 'Your feedback:',
  },
};

export const IngredientListFeedbackModal: React.FC<IngredientListFeedbackModalProps> = ({
  files,
  isOpen,
  handleClose,
  setFiles,
  isReadOnly,
  viewedFile,
}) => {
  const { showSnackbar } = useSnackbar();
  const { postIngredientListFeedback } = useApi();

  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    // prefill feedback for view only mode
    if (!isReadOnly) return;
    setFeedback(viewedFile?.parsedIncluded.feedback?.attributes.content);
  }, [isReadOnly, viewedFile]);

  const handleSubmit = () => {
    postIngredientListFeedback({
      urlParams: `${viewedFile.id}/feedbacks?feedbackType=${FeedbackType.rejectIl}`,
      data: {
        feedback: {
          status: FeedbackStatus.rejected,
          content: feedback,
        },
      },
      handleSuccess: (data) => {
        setFiles(
          files.map((file: any) => {
            if (file.id === viewedFile.id) {
              return {
                ...file,
                parsedIncluded: {
                  ...file.parsedIncluded,
                  feedback: { ...data },
                },
              };
            }
            return file;
          })
        );
      },
      handleFinally: () => {
        handleClose();
        setFeedback('');
        showSnackbar(
          `${viewedFile.attributes.name} feedback submitted. Prime Matter Labs will review your notes and upload an adjusted IL soon.`,
          'success'
        );
      },
    });
  };

  const handleCloseModal = () => {
    handleClose();
    setFeedback('');
  };

  const uploadDate = new Date(
    viewedFile?.parsedIncluded?.feedback?.attributes.createdAt
  )?.toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <Dialog open={isOpen} onClose={handleCloseModal} fullWidth>
      <div className="mt-6 ml-6 mr-6">
        <div className="mb-6">
          <div className="mb-6">
            <h3>
              {isReadOnly
                ? MODAL_CONTENT.submitFeedback.disabledContent
                : MODAL_CONTENT.submitFeedback.content}
            </h3>
          </div>
          <TextField
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            disabled={isReadOnly}
            multiline
            variant="outlined"
            fullWidth
            minRows={5}
            InputProps={{ style: { color: '#333' } }}
          />
        </div>

        <div className="flex items-center mb-6">
          <div className="basis-1/2 font-inter text-sm">
            <div>File: {viewedFile?.attributes.name}</div>
            {isReadOnly && <div>Feedback submitted: {uploadDate}</div>}
          </div>

          <div className="basis-1/2 flex justify-end items-center">
            <div>
              <button
                onClick={handleCloseModal}
                className="flex items-center p-2 cursor-pointer rounded-md font-inter text-sm border border-darkGray text-black mr-2"
              >
                {isReadOnly ? 'Close' : 'Cancel'}
              </button>
            </div>
            {!isReadOnly && (
              <div>
                <button
                  onClick={handleSubmit}
                  className="flex items-center p-2 cursor-pointer rounded-md font-inter text-sm border"
                  style={{
                    backgroundColor:
                      isReadOnly || !feedback
                        ? THEME_PALETTE.gray.light
                        : THEME_PALETTE.blue.dark,
                    color: 'white',
                  }}
                  disabled={isReadOnly || !feedback}
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
