import React from 'react';
import { BaseInput } from './base-input.component';

interface TextFieldProps {
  label?: string;
  [x: string]: any;
}

export const TextField: React.FC<TextFieldProps> = ({ label, ...rest }) => {
  return <BaseInput label={label} {...rest} />;
};
