// Libraries
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  InfoOutlined,
  ErrorOutlineOutlined,
  SubdirectoryArrowRight,
  WarningOutlined,
} from '@material-ui/icons';
import { IPricingData, Tank } from './types';
import _ from 'lodash';
// Components
import { DialogModal } from 'features/ui';
import { ExcessRawMaterialsTabbedContainer } from './excess-raw-materials-tabbed-container.component';
import { RawMaterialCostChart } from './raw-material-cost-chart.component';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import {
  calculateExcessRawMaterials,
  calculateTotalBatchSize,
} from './pricing-calculator.component';
import { generateExcessRawMaterialsWarnings } from './excess-raw-material-warnings-generator.component';
// Constants
import { NA, PRODUCTION_LOCATIONS } from './constants';
import {
  BriefAttributes,
  FormulaAttributes,
  IApiData,
  IngredientAttributes,
  PricingQuoteAttributes,
} from 'api';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    chartContainer: {
      position: 'sticky',
      top: 72,
      backgroundColor: theme.palette.gray.lighter,
    },
    modalLink: {
      cursor: 'pointer',
      margin: `${theme.spacing(1)}px 0`,
      marginRight: theme.spacing(1),
      textDecoration: 'underline',
    },
  })
);

interface DonutChartContainerProps {
  brief: BriefAttributes;
  children: React.ReactElement;
  ingredients: Maybe<IApiData<IngredientAttributes>[]>;
  isLoading: boolean;
  isMissingRequiredInformation: boolean;
  productionLocation: Maybe<valueof<typeof PRODUCTION_LOCATIONS>>;
  productionTank: Maybe<Tank & { tanks: Tank[] }>;
  pricingData: Maybe<IPricingData>;
  pricingQuote: Maybe<IApiData<PricingQuoteAttributes>>;
  selectedFormula: Maybe<IApiData<FormulaAttributes>>;
}

/**
 * When there are more than one production tank involved
 * we want to find and return the largest tank size for calculation
 *  */
const getLargestTank = (
  productionTank: Maybe<
    Tank & {
      tanks: Tank[];
    }
  >
) => {
  return productionTank?.tanks.reduce((prev, current) => {
    return prev.gallons > current.gallons ? prev : current;
  });
};

export const DonutChartContainer: React.FC<DonutChartContainerProps> = ({
  brief,
  children,
  ingredients,
  isLoading,
  isMissingRequiredInformation,
  productionLocation,
  productionTank,
  pricingData,
  pricingQuote,
  selectedFormula,
}) => {
  const classes = useStyles();
  const [showExcessRawsModal, setShowExcessRawsModal] = useState<boolean>(
    false
  );
  const [showRawMaterialCostModal, setShowRawMaterialCostModal] = useState<
    boolean
  >(false);

  if (isLoading) {
    return (
      <div className="flex flex-col px-10 ml-auto w-1/2">
        <div className={classes.chartContainer}>
          <Box className="flex justify-center items-center py-52">
            <Box className="flex flex-col justify-center items-center">
              <CircularProgress size={60} thickness={3} />
              <Typography variant="h4" style={{ marginTop: 12 }}>
                Loading Cost...
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
    );
  }

  if (isMissingRequiredInformation) {
    return (
      <div className="flex flex-col pl-10 ml-auto w-1/2">
        <div className={classes.chartContainer}>
          <Box className="flex justify-center items-center py-52">
            <Box className="flex flex-col justify-center items-center">
              <ErrorOutlineOutlined color="error" fontSize="large" />
              <Typography variant="h4" style={{ marginTop: '1rem' }}>
                Cannot calculate cost
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
    );
  }

  const determineContributionPerRun = () => {
    if (productionTank?.unitOutput && pricingData?.profit) {
      const contributionPerRun = _.round(
        productionTank?.unitOutput * pricingData?.profit,
        2
      );
      return contributionPerRun.toLocaleString('en-US');
    } else {
      return NA;
    }
  };

  const {
    percentageOfExcessRawMaterials,
    totalExcessValue,
  } = calculateExcessRawMaterials({
    ingredients,
    totalBatchSize: calculateTotalBatchSize(brief),
    profit: pricingData?.profit,
    productionTank:
      productionTank?.tanks.length! > 1
        ? getLargestTank(productionTank)
        : productionTank,
  });

  const renderLocationBasedTankSizeLabel = () => {
    const TANK_SIZE = 'Tank Size';

    if (!productionLocation) return TANK_SIZE;

    if (productionLocation === PRODUCTION_LOCATIONS.LOS_ANGELES)
      return `LA ${TANK_SIZE}`;

    if (productionLocation === PRODUCTION_LOCATIONS.MIAMI)
      return `${productionLocation} ${TANK_SIZE}`;
  };

  const renderProductionTankGallons = () => {
    if (!productionTank) return NA;

    if (productionTank.tanks.length > 1) return 'Multiple';

    return productionTank.gallons + ' Gallon';
  };

  const excessRawMaterialWarnings = generateExcessRawMaterialsWarnings(
    ingredients
  );
  const hasExcessRawMaterialWarnings = Boolean(
    excessRawMaterialWarnings.length
  );

  return (
    <div className="flex flex-col px-10 ml-auto w-1/2">
      <div className={classes.chartContainer}>
        <div>
          <div className="mt-6">{children}</div>
          <Grid
            container
            direction="column"
            alignItems="center"
            className="mb-6"
          >
            <Grid>
              <Typography
                onClick={() =>
                  setShowRawMaterialCostModal(!showRawMaterialCostModal)
                }
                className={classes.modalLink}
                variant="body2"
              >
                View Raw Material Costs
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              onClick={() => setShowExcessRawsModal(!showExcessRawsModal)}
              className="cursor-pointer"
            >
              <Typography className={classes.modalLink} variant="body2">
                View Excess Raw Materials
              </Typography>
              {hasExcessRawMaterialWarnings && (
                <Tooltip title="Missing information. Click to see more details.">
                  <WarningOutlined style={{ color: '#ff9800' }} />
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <div className="flex flex-col mx-10 mb-6">
            <div className="flex justify-between py-3 border-t border-formulaLighterGray">
              <div className="flex items-center">
                <Typography variant="h4">
                  {renderLocationBasedTankSizeLabel()}
                </Typography>
                <Tooltip title="Location and Order Quantity determine Tank Size">
                  <InfoOutlined className="ml-2" />
                </Tooltip>
              </div>
              <Typography>{renderProductionTankGallons()}</Typography>
            </div>
            <div className="flex justify-between py-3 border-t border-formulaLighterGray">
              <div className="flex items-center">
                <Typography variant="h4">Unit Output</Typography>
                <Tooltip title="Tank size determines Unit Output">
                  <InfoOutlined className="ml-2" />
                </Tooltip>
              </div>
              <Typography aria-label="Unit Output">
                {productionTank
                  ? productionTank.unitOutput.toLocaleString('en-US') + ' units'
                  : NA}
              </Typography>
            </div>
            <div className="flex justify-between py-3 border-t border-formulaLighterGray">
              <div className="flex items-center">
                <Typography variant="h4">Contribution Per Run </Typography>
              </div>
              <Typography aria-label="Contribution Per Run">
                ${determineContributionPerRun()}
              </Typography>
            </div>
            <div className="flex justify-between py-3 border-t border-formulaLighterGray">
              <div className="flex items-center">
                <Typography variant="h4">Total Excess Raws </Typography>
              </div>
              <Typography aria-label="Total Excess Raws">
                {_.round(totalExcessValue || 0, 2).toLocaleString('en-US')}
              </Typography>
            </div>
            <div className="flex justify-between py-3 border-t border-formulaLighterGray">
              <div className="flex items-center">
                <Typography variant="h4">% Excess Raws </Typography>
              </div>
              <Typography aria-label="% Excess Raws">
                {percentageOfExcessRawMaterials
                  ? `${percentageOfExcessRawMaterials}%`
                  : NA}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      {selectedFormula && ingredients && (
        <DialogModal
          dialogCta={
            <div className="flex items-center cursor-pointer">
              <Link
                to={ROUTES.SHOW_FORMULA.route.replace(
                  UUID_SHOW_ROUTE_STRING,
                  selectedFormula.id
                )}
                target="_blank"
                rel="noopener noreferrer"
                className="flex"
              >
                <Typography className="underline">
                  See all raw material costs
                </Typography>
                <div className="ml-1">
                  <SubdirectoryArrowRight />
                </div>
              </Link>
            </div>
          }
          handleClose={() => setShowRawMaterialCostModal(false)}
          maxWidthSize="md"
          closeOnly
          open={showRawMaterialCostModal}
          title="Top 5 Formula Costs"
        >
          <RawMaterialCostChart ingredients={ingredients} />
        </DialogModal>
      )}
      <DialogModal
        handleClose={() => setShowExcessRawsModal(false)}
        maxWidthSize="lg"
        closeOnly
        open={showExcessRawsModal}
        overflow="scroll"
        title="Excess Raw Materials"
      >
        <ExcessRawMaterialsTabbedContainer
          brief={brief}
          formula={selectedFormula}
          ingredients={ingredients}
          pricingData={pricingData}
          pricingQuote={pricingQuote}
          productionLocation={productionLocation}
          productionTank={productionTank}
        />
      </DialogModal>
    </div>
  );
};
