// Libraries
import React, { createContext } from 'react';
import { Color } from '@material-ui/lab';

export const SnackbarContext = createContext<{
  setSnackbar: React.Dispatch<React.SetStateAction<any>> | (() => void);
  showSnackbar: (
    message: string,
    severity: Color,
    action?: Maybe<React.ReactNode>
  ) => void;
}>({
  setSnackbar: () => {},
  showSnackbar: () => {},
});
