import { BRIEF_FORM_KEYS } from '../../brief.constants';

// The order of keys is CRITICAL for determining the order of the modules in the form. DO NOT CHANGE.
export const SUN_CARE_MODULE_LIST = [
  BRIEF_FORM_KEYS.PRODUCT_TYPE,
  BRIEF_FORM_KEYS.STRUCTURE,
  BRIEF_FORM_KEYS.SPF,
  BRIEF_FORM_KEYS.TINT,
  BRIEF_FORM_KEYS.SILICONE_FREE,
];
