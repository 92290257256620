// Libraries
import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
// Components
import { Button } from 'design-system';
//Utils
import { USDollar } from 'features/utils';
// Constants
import { Tank } from '../quote-calculator/types';

interface CostSummaryProps {
  closeWorksheet: () => void;
  contributionsPerRun: number[];
  laborCosts: any;
}

export const CostSummary: React.FC<CostSummaryProps> = ({
  closeWorksheet,
  contributionsPerRun,
  laborCosts,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="fixed bottom-0 w-full">
      <div className="bg-grey-20 ">
        <div className="w-full max-w-[1440px] mx-auto">
          <div className="grid grid-cols-12 flex-col font-inter ml-16 mr-16 gap-x-6">
            <div className="col-start-2 col-span-10">
              <div className="grid grid-cols-10 items-center px-3 py-4 text-white">
                <div className="flex items-center col-span-2">
                  <span className="text-grey-70">Customer Cost per Unit</span>
                  {isExpanded ? (
                    <ChevronUpIcon
                      onClick={() => setIsExpanded(!isExpanded)}
                      className="w-5 h-5 ml-2 text-royal-70 cursor-pointer"
                    />
                  ) : (
                    <ChevronDownIcon
                      onClick={() => setIsExpanded(!isExpanded)}
                      className="w-5 h-5 ml-2 text-royal-70 cursor-pointer"
                    />
                  )}
                </div>
                <span className="col-span-2">$2.16</span>
                <span className="col-span-2">$2.13</span>
                <span className="col-span-2">$2.11</span>
              </div>

              {isExpanded && (
                <>
                  <div className="grid grid-cols-10 items-center px-3 py-4 text-white">
                    <span className="col-span-2 text-grey-70">
                      Tank Size (Gallons)
                    </span>
                    {laborCosts.map(({ tank }: { tank: Tank }) => {
                      return (
                        <span className="col-span-2">
                          {tank.gallons!.toLocaleString()}
                        </span>
                      );
                    })}
                  </div>
                  <div className="grid grid-cols-10 items-center px-3 py-4 text-white">
                    <span className="col-span-2 text-grey-70">Unit Output</span>
                    {laborCosts.map(({ tank }: { tank: Tank }) => {
                      return (
                        <span className="col-span-2">
                          {tank.unitOutput!.toLocaleString()}
                        </span>
                      );
                    })}
                  </div>
                  <div className="grid grid-cols-10 items-center px-3 py-4 text-white">
                    <span className="col-span-2 text-grey-70">
                      Contribution per Run
                    </span>
                    {contributionsPerRun.map((contributionPerRun) => {
                      return (
                        <span className="col-span-2">
                          {USDollar.format(contributionPerRun)}
                        </span>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-10 p-2 pb-10 flex justify-end bg-white gap-3 font-inter">
        <Button type="secondary" text="Cancel" onClick={closeWorksheet} />
        <Button type="primary" text="Save" />
      </div>
    </div>
  );
};
