// Libraries
import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
// Components
import { DialogModal } from 'features/ui';
// Utils
import { useApi, BatchAttributes, IApiData } from 'api';
// Constants
import { SPECIFICATIONS_VALIDATION_SCHEMA } from './constants';

enum FORM_KEYS {
  lotNumber = 'lotNumber',
  quantity = 'quantity',
}

const INITIAL_FORM_VALUES = {
  lotNumber: '',
  quantity: '',
} as const;

interface AddBatchDialogProps {
  batches: Array<IApiData<BatchAttributes>>;
  formulaId: string;
  handleClose: () => void;
  isOpen: boolean;
  setBatches: React.Dispatch<
    React.SetStateAction<Array<IApiData<BatchAttributes>>>
  >;
}

export const AddBatchDialog: React.FC<AddBatchDialogProps> = ({
  batches,
  formulaId,
  handleClose,
  isOpen,
  setBatches,
}) => {
  const { postFormulaBatch } = useApi();

  const onSubmit = (
    values: typeof INITIAL_FORM_VALUES,
    { resetForm }: FormikHelpers<typeof INITIAL_FORM_VALUES>
  ) => {
    postFormulaBatch({
      urlParams: `/${formulaId}/batches`,
      data: {
        batch: values,
      },
      handleSuccess: (data: IApiData<BatchAttributes>) => {
        setBatches([...batches, data]);
        resetForm();
        handleClose();
      },
    });
  };

  const { getFieldProps, errors, handleSubmit, resetForm } = useFormik<
    typeof INITIAL_FORM_VALUES
  >({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: SPECIFICATIONS_VALIDATION_SCHEMA,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: onSubmit,
  });

  const onClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <DialogModal
      maxWidthSize="sm"
      open={isOpen}
      title={`Add Batch ${batches.length + 1} Specs`}
      onConfirm={handleSubmit}
      handleClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...getFieldProps(FORM_KEYS.lotNumber)}
            error={FORM_KEYS.lotNumber in errors}
            fullWidth
            helperText={errors[FORM_KEYS.lotNumber]}
            label="Lot #"
            required
            variant="outlined"
            inputProps={{
              'aria-label': 'lot number',
              maxLength: 256,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...getFieldProps(FORM_KEYS.quantity)}
            error={FORM_KEYS.quantity in errors}
            fullWidth
            helperText={errors[FORM_KEYS.quantity]}
            label="Batch Qty (G)"
            required
            variant="outlined"
            inputProps={{
              'aria-label': 'batch quantity',
              type: 'number',
              min: 0,
            }}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
};
