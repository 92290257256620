// Libraries
import React from 'react';
import { TextField, Grid } from '@material-ui/core';
// Components
import { FormSection } from 'features/ui';
import { useFormikContext } from 'formik';
// Constants

const GENERAL = 'General';

export const GeneralSection: React.FC = () => {
  const { getFieldProps } = useFormikContext();

  return (
    <FormSection
      sectionHeader={GENERAL}
      isRevealed={true}
      setIsRevealed={() => null}
    >
      <Grid container item spacing={5} xs={12}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...getFieldProps('general.usName')}
            fullWidth
            inputProps={{
              'aria-label': 'US Name',
            }}
            label="US Name"
            required
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...getFieldProps('general.euName')}
            fullWidth
            inputProps={{
              'aria-label': 'EU Name',
            }}
            label="EU Name"
            required
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...getFieldProps('general.casNumber')}
            fullWidth
            inputProps={{
              'aria-label': 'CAS #',
            }}
            label="CAS #"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
