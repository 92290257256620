//Libraries
import React from 'react';
//Utils
import { AnalyticsContext, AnalyticsContextType } from './analytics.context';

interface AnalyticsProviderProps {
  children: React.ReactNode;
  instance: AnalyticsContextType;
}

export const AnalyticsContextProvider = ({
  children,
  instance,
}: AnalyticsProviderProps) => {
  return (
    <AnalyticsContext.Provider value={instance}>
      {children}
    </AnalyticsContext.Provider>
  );
};
