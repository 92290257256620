// Libraries
import React from 'react';

interface ModalProps {
  children: JSX.Element | JSX.Element[];
  handleClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({ children, handleClose }) => {
  const handleOutsideModalClick = (event: any) => {
    event.stopPropagation();
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  return (
    <div
      onMouseDown={handleOutsideModalClick}
      className="overscroll-none flex items-center justify-center fixed z-40 w-full h-full top-0 left-0 bg-black bg-opacity-25"
    >
      <div className="z-50 relative h-auto w-[400px] flex flex-col rounded shadow-md bg-white">
        {children}
      </div>
    </div>
  );
};
