// Libraries
import React from 'react';
import _ from 'lodash';

interface TextAreaProps {
  value?: string;
  label?: string;
  errors?: string;
  placeholder?: string;
  rows?: number;
  isDisabled?: boolean;
  isStatic?: boolean;
  [prop: string]: any;
}

export const TextArea: React.FC<TextAreaProps> = ({
  value,
  label,
  errors,
  placeholder,
  rows = 4,
  isDisabled = false,
  isStatic = false,
  ...rest
}) => {
  let styles = '';
  if (isDisabled) styles = 'text-grey-60';
  if (isStatic) styles = 'border-none bg-white pl-0';
  if (errors) styles = 'border-red-50 focus:border-red-50 focus:ring-red-50';

  return (
    <div>
      {label && (
        <div className="mb-2">
          <label className="text-xs font-inter font-semibold">{label}</label>
        </div>
      )}

      <textarea
        className={`${styles} border border-grey-90 rounded-[4px] w-full text-grey-20 pt-3 pr-3 pl-3 font-inter
        resize-none focus:border-royal-50 focus:outline-none focus:ring-0 `}
        rows={rows}
        placeholder={placeholder ? placeholder : ''}
        value={value}
        disabled={isDisabled || isStatic}
        {..._.omit(rest, ['className'])}
      />

      {errors && <p className=" text-xs font-inter text-red-60">{errors}</p>}
    </div>
  );
};
