// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton, Typography } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { UserContext } from 'context';
// Constants
import { PACKAGING_OPTION_TYPES } from '../brief.constants';
import { ROLES } from 'features/rbac';

export const PrimaryComponent: React.FC = () => {
  const { roles } = useContext(UserContext)!;

  const isEmployee = roles.includes(ROLES.EMPLOYEE);

  const { getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();

  const renderOptions = (type: string) => {
    const optionName =
      Object.values(PACKAGING_OPTION_TYPES).find(
        (option) => option.name === type
      )?.name || '';

    const options =
      Object.values(PACKAGING_OPTION_TYPES).find(
        (option) => option.name === type
      )?.options || [];

    return options.map((option) => (
      <div key={option.value}>
        <RadioButton
          primaryLabel={option.label}
          {...getFieldProps(optionName)}
          onChange={() => setFieldValue(optionName, option.value)}
          checked={
            values[optionName as keyof InternalBriefFormValues] === option.value
          }
        />
      </div>
    ));
  };

  return (
    <div className="flex flex-col gap-3 mb-6">
      <Typography
        variant="h3"
        font="inter"
        weight="normal"
        additionalStyles="mb-3"
      >
        Components
      </Typography>
      <Typography variant="h4" font="inter" weight="bold">
        Primary Component
      </Typography>
      {renderOptions(PACKAGING_OPTION_TYPES.PRIMARY_COMPONENT.name)}
      {isEmployee && (
        <Typography
          variant="h6"
          font="inter"
          color="secondary"
          additionalStyles="-mt-1"
        >
          Required for Pricing Quote
        </Typography>
      )}
    </div>
  );
};
