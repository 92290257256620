export enum ActionType {
  remove = 'remove',
  reinvite = 'reinvite',
  edit = 'edit',
}

export interface Action {
  currentResourceId: Maybe<string>;
  isLoading: boolean;
  type: Maybe<ActionType>;
}

export interface TeamMemberFormValues {
  email: string;
  givenName: string;
  familyName: string;
}
