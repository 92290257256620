// Libraries
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  createStyles,
  makeStyles,
} from '@material-ui/core';
//Utils
import { ITheme } from 'styles/mui/themeV2';

// Constants
import * as Constants from '../../constants';

interface IDuplicateFileDialogue {
  isOpen: boolean;
  handleClose: () => void;
  handleDupe: (e?: boolean, dupeResponse?: string) => void;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    actionButtons: {
      justifyContent: 'space-evenly',
    },
  })
);

export const DuplicateFileDialogue: React.FC<IDuplicateFileDialogue> = ({
  isOpen,
  handleClose,
  handleDupe,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {Constants.UPLOAD_WARNINGS.duplicateFileWarning.title}
      </DialogTitle>
      <DialogContent>
        {Constants.UPLOAD_WARNINGS.duplicateFileWarning.content}
      </DialogContent>
      <DialogActions>
        <Grid container xs={12}>
          <Grid item xs={8}>
            <Button onClick={handleClose} color="secondary" variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item container xs={4} className={classes.actionButtons}>
            <Button
              onClick={() => {
                handleDupe(false, Constants.DUPE_VALUES.replace);
                handleClose();
              }}
            >
              Replace
            </Button>
            <Button
              onClick={() => {
                handleDupe(false, Constants.DUPE_VALUES.duplicate);
                handleClose();
              }}
            >
              Keep Both
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
