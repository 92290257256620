// Libraries
import React, { useRef, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
// Utils
import { ActionsMenuItem } from './types';

interface MoreActionsMenuProps {
  menuItems: ActionsMenuItem[];
  children: ({
    anchor,
    toggleMenu,
  }: {
    anchor: React.MutableRefObject<null>;
    toggleMenu: () => void;
  }) => React.ReactElement | React.ReactElement[] | JSX.Element | JSX.Element[];
}

export const MoreActionsMenu: React.FC<MoreActionsMenuProps> = ({
  menuItems,
  children,
}) => {
  const anchor = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const renderMenuItems = () =>
    menuItems.map(({ canAccess, label, onClick }, index) =>
      // If canAccess is undefined it means it is not locked down by a permission
      canAccess === undefined || canAccess ? (
        <MenuItem
          key={`${label}-${index}`}
          onClick={() => {
            onClick();
            setIsOpen(false);
          }}
        >
          {label}
        </MenuItem>
      ) : null
    );

  return (
    <>
      {children({ anchor, toggleMenu })}
      <Menu
        anchorEl={anchor.current}
        open={isOpen}
        title="More actions"
        onClose={toggleMenu}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
};
