// Libraries
import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Collapse,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core';
// Assets
import { Done, DoneAll } from '@material-ui/icons';

// Utils
import { Ingredient } from './types';
import { ITheme, THEME_PALETTE } from 'styles/mui/themeV2';

interface IIngredientsTableExpandedRow {
  isExpanded: boolean;
  isLocked: boolean;
  ingredient: Ingredient;
  handleAttributeUpdate: (
    inputName: Maybe<string>,
    value: Maybe<string>
  ) => void;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    expandedCell: {
      padding: 0,
      borderBottom: 'none',
    },
    expandedCellTitle: {
      color: theme.palette.gray.main,
    },
    expandedRow: {
      backgroundColor: theme.palette.gray.lighter,
      padding: theme.spacing(0, 5),
    },
    gridItem: {
      marginRight: '0.75rem',
    },
    tableCell: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      verticalAlign: 'top',
    },
    tableHeadCell: {
      border: 0,
      paddingBottom: 0,
      paddingTop: theme.spacing(5),
    },
    textField: {
      backgroundColor: theme.palette.secondary.main,
    },
    headerWithIcon: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export const IngredientsTableExpandedRow: React.FC<IIngredientsTableExpandedRow> = ({
  isExpanded,
  isLocked,
  ingredient,
  ingredient: {
    areEuInciFactorsVerified,
    areEuIncisVerified,
    areUsInciFactorsVerified,
    areUsIncisVerified,
    euIncis,
    manufacturer,
    supplier,
    usApiDrugPurposes,
    usIncis,
  },
  handleAttributeUpdate,
}) => {
  const [lot, setLot] = useState<string>(ingredient.lotNumber || '');
  const classes = useStyles();

  const getVerificationIcon = (
    incisVerified: Maybe<boolean>,
    factorsVerified: Maybe<boolean>
  ): Maybe<JSX.Element> => {
    if (incisVerified && factorsVerified) {
      return (
        <Tooltip arrow title="INCIS verified">
          <DoneAll style={{ color: THEME_PALETTE.green.dark }} />
        </Tooltip>
      );
    }
    if (factorsVerified || incisVerified) {
      return (
        <Tooltip
          arrow
          title={
            factorsVerified
              ? 'INCI factors verified'
              : incisVerified
              ? 'INCI names verified'
              : ''
          }
        >
          <Done style={{ color: THEME_PALETTE.green.dark }} />
        </Tooltip>
      );
    }
  };

  const EXPANDED_ROW_DATA = [
    {
      title: 'US INCIS',
      value: usIncis.map((inci) => inci.us_name).join(', '),
      Icon: getVerificationIcon(areUsIncisVerified, areUsInciFactorsVerified),
    },
    {
      title: 'EU INCIS',
      value: euIncis.map((inci) => inci.eu_name).join(', '),
      Icon: getVerificationIcon(areEuIncisVerified, areEuInciFactorsVerified),
    },
    {
      title: 'LOT #',
      value: lot,
      isEditable: !isLocked,
      colWidth: '15%',
      handleChange: setLot,
      inputName: 'lot',
      handleBlur: handleAttributeUpdate,
    },
    { title: 'SUPPLIER', value: supplier?.name, colWidth: '15%' },
    { title: 'MANUFACTURER', value: manufacturer?.name, colWidth: '15%' },
    {
      title: 'MARKED AS',
      value: usApiDrugPurposes.join(', '),
      colWidth: '15%',
    },
  ];

  const renderTableHeaders = () => {
    return EXPANDED_ROW_DATA.map(({ title, colWidth, Icon }) => (
      <TableCell
        style={{ width: colWidth }}
        className={classes.tableHeadCell}
        key={title}
      >
        <Box className={Icon ? classes.headerWithIcon : undefined}>
          <Typography
            className={classes.expandedCellTitle}
            variant="h4"
            style={Icon ? { marginRight: '0.25rem' } : {}}
          >
            {title}
          </Typography>
          {Icon}
        </Box>
      </TableCell>
    ));
  };

  const renderTableData = () => {
    return EXPANDED_ROW_DATA.map(
      (
        {
          title,
          inputName,
          value,
          isEditable,
          colWidth,
          handleChange,
          handleBlur,
        },
        index
      ) => (
        <TableCell
          style={
            title === 'LOT #'
              ? { width: colWidth, paddingTop: '0' }
              : { width: colWidth }
          }
          className={classes.tableCell}
          key={`${title} ${index}`}
        >
          {isEditable ? (
            <TextField
              className={classes.textField}
              variant="outlined"
              size="small"
              value={value}
              onChange={(e) => handleChange && handleChange(e.target.value)}
              onBlur={() => handleBlur && handleBlur(inputName, value)}
            >
              {value}
            </TextField>
          ) : (
            <Typography variant="body2">{value}</Typography>
          )}
        </TableCell>
      )
    );
  };

  return (
    <TableRow className={classes.expandedRow}>
      <TableCell className={classes.expandedCell} colSpan={11}>
        <Collapse in={isExpanded}>
          <Table>
            <TableHead>
              <TableRow>{renderTableHeaders()}</TableRow>
            </TableHead>
            <TableBody>
              <TableRow>{renderTableData()}</TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
