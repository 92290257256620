import React from 'react';

export enum ChipStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  InProgress = 'in progress',
  InReview = 'in review',
  Expired = 'expired',
}

export interface StatusChipProps {
  status: ChipStatus;
}

export const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const determineChipProperties = (status: ChipStatus) => {
    switch (status) {
      case ChipStatus.InProgress:
      case ChipStatus.InReview:
        return {
          bgColor: 'bg-yellow-95',
          borderColor: 'border-yellow-80',
          label: status === ChipStatus.InProgress ? 'In Progress' : 'In Review',
          textColor: 'text-yellow-30',
        };
      case ChipStatus.Approved:
        return {
          bgColor: 'bg-green-95',
          borderColor: 'border-green-80',
          label: 'Approved',
          textColor: 'text-green-40',
        };
      case ChipStatus.Rejected:
        return {
          bgColor: 'bg-red-95',
          borderColor: 'border-red-80',
          label: 'Rejected',
          textColor: 'text-red-40',
        };
      case ChipStatus.Expired:
        return {
          bgColor: 'bg-white',
          borderColor: 'border-red-80',
          label: 'Expired',
          textColor: 'text-grey-20',
        };
      default:
        return {
          bgColor: 'bg-white',
          borderColor: 'border-grey-80',
          label: 'Unknown',
          textColor: 'text-grey-20',
        };
    }
  };

  const chipProperties = determineChipProperties(status);

  return (
    <div
      className={`py-[2px] px-2 uppercase rounded-full border text-xs font-[500] ${chipProperties.bgColor} ${chipProperties.borderColor} ${chipProperties.textColor}`}
    >
      {chipProperties.label}
    </div>
  );
};
