// Libraries
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from '@hello-pangea/dnd';
import {
  createStyles,
  makeStyles,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
// Components
import { AccordionButton } from 'features/ui';
import { AddOrEditIngredientModal } from './add-or-edit-ingredient-modal.component';
import { APIIngredientModal } from './api-ingredient-modal.component';
import { DeleteButton, MarkAPIButton } from '../ui';
import { IngredientsTableExpandedRow } from './ingredients-table-expanded-row.component';
import { SelectPricingDialog } from './select-pricing-dialog.component';
// Assets
import dragIndicator from '../images/dragIndicator.svg';
// Utils
import { Ingredient } from './types';
import { calculatePricePerOunceAtCurrentWeight } from './utils';
import { ITheme } from 'styles/mui/themeV2';
import { useApi } from 'api';
import { useSnackbar } from 'hooks';
// Constants
import {
  US_API_DRUG_PURPOSES,
  WEIGHT_GRAMS_PRECISION,
  WEIGHT_PERCENT_PRECISION,
} from './constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
const PRICE_NOT_AVAILABLE = ' N/A';
const PERCENTAGE_INPUT_NAME = 'amount';

interface IIngredientsTableRow {
  batchQuantity: number;
  removeIngredientFromTable: () => void;
  index: number;
  ingredient: Ingredient;
  ingredientIndex: number;
  isLocked: boolean;
  isQs: boolean;
  percentageOfTotal: number;
  phaseIndex: number;
  position: number;
  selectedPricingUnit: string;
  updateIngredient: (values: any) => Maybe<Promise<any>> | void;
  isAllExpanded: boolean;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    expandedCell: {
      borderBottom: 'none',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
    link: {
      textDecoration: 'underline',
      maxWidth: 'fit-content',
      display: 'block',
    },
    lastUpdated: {
      color: theme.palette.gray.main,
      whiteSpace: 'nowrap',
    },
    isDragging: {
      border: `1px solid ${theme.palette.blue.main}`,
      filter:
        'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
    },
    notApplicable: {
      color: theme.palette.gray.main,
    },
    row: {
      backgroundColor: theme.palette.secondary.main,
    },
    tableCell: {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
    keyIcon: {
      color: theme.palette.secondary.dark,
      borderRadius: '50%',
      width: theme.typography.pxToRem(32),
      height: theme.typography.pxToRem(32),
      padding: 4,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.gray.light,
      },
    },
  })
);

export const IngredientsTableRow: React.FC<IIngredientsTableRow> = ({
  batchQuantity,
  removeIngredientFromTable,
  ingredient,
  ingredient: { active, id, name, rmId, sampleCode, rmUuid },
  ingredientIndex,
  percentageOfTotal,
  phaseIndex,
  position,
  selectedPricingUnit,
  index,
  updateIngredient,
  isAllExpanded,
  isLocked,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(ingredient.amount);
  const [isIngredientKey, setIsIngredientKey] = useState<boolean>(
    ingredient.key ?? false
  );
  const [percentageFieldFocused, setPercentageFieldFocused] = useState<boolean>(
    false
  );
  const [isAddIngredientModalOpen, setIsAddIngredientModalOpen] = useState<
    boolean
  >(false);
  const [isAPIIngredientModalOpen, setIsAPIIngredientModalOpen] = useState<
    boolean
  >(false);
  const [isSelectPricingModalOpen, setIsSelectPricingModalOpen] = useState<
    boolean
  >(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const classes = useStyles();
  const weightGrams = (percentage / 100) * batchQuantity;
  const defaultWeightGrams = '0.0000';
  const { deleteIngredient, patchIngredient } = useApi();
  const { showSnackbar } = useSnackbar();

  // hard set the status of each material's expand btn whenever the expand all btn is clicked
  useEffect(() => {
    if (isAllExpanded) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isAllExpanded]);

  useEffect(() => {
    setPercentage(ingredient.amount);
  }, [id, ingredient.amount]);

  const generateLastUpdatedString = () => {
    return ingredient.price?.received_at
      ? new Date(ingredient.price?.received_at)?.toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      : 'N/A';
  };

  const handleUpdate = (inputName: Maybe<string>, value: any) => {
    if (!inputName) return;

    setPercentageFieldFocused(false);

    // If the value hasn't changed
    if (ingredient[inputName] === value) {
      return;
    }

    let updatedIngredient;

    if (inputName === US_API_DRUG_PURPOSES) {
      const activeValue = value.length > 0;
      updatedIngredient = {
        ...ingredient,
        usApiDrugPurposes: value,
        active: activeValue,
      };
    } else {
      updatedIngredient = {
        ...ingredient,
        ...(inputName && { [inputName]: value }),
      };
    }
    updateIngredient(updatedIngredient);
  };

  const renderRmLink = (linkName: string) => {
    return (
      <Tooltip
        title={
          <span
            style={{
              fontSize: '12px',
            }}
          >
            {linkName}
          </span>
        }
        arrow
      >
        <Link
          to={ROUTES.SHOW_RAW_MATERIAL.route.replace(
            UUID_SHOW_ROUTE_STRING,
            rmUuid
          )}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <Typography>{linkName}</Typography>
        </Link>
      </Tooltip>
    );
  };

  const handleKeyIngredient = async (key: boolean, ingredientUuid: string) => {
    setIsIngredientKey(key);

    patchIngredient({
      urlParams: ingredientUuid,
      data: { ingredient: { key } },
      handleError: (error) => {
        Rollbar.error(error);
        setIsIngredientKey(false);
        showSnackbar('Error setting ingredient as key ingredient', 'error');
      },
    });
  };

  const handleDeleteIngredient = () => {
    if (!window.confirm(`Remove ${name}?`)) return;
    setIsDeleting(true);

    deleteIngredient({
      urlParams: id,
      handleSuccess: () => {
        showSnackbar(`${ingredient.name} successfully deleted`, 'success');
        removeIngredientFromTable();
      },
      handleFinally: () => setIsDeleting(false),
    });
  };

  return (
    <>
      <APIIngredientModal
        handleClose={() => setIsAPIIngredientModalOpen(false)}
        handleUpdate={handleUpdate}
        isOpen={isAPIIngredientModalOpen}
        usApiDrugPurposes={ingredient.usApiDrugPurposes}
        isLocked={isLocked}
      />
      <AddOrEditIngredientModal
        closeModal={() => setIsAddIngredientModalOpen(false)}
        ingredient={ingredient}
        ingredientIndex={ingredientIndex}
        isOpen={isAddIngredientModalOpen}
        phaseIndex={phaseIndex}
      />
      {isSelectPricingModalOpen && (
        <SelectPricingDialog
          isOpen={isSelectPricingModalOpen}
          handleClose={() => setIsSelectPricingModalOpen(false)}
          ingredient={ingredient}
        />
      )}
      <Draggable
        isDragDisabled={isLocked}
        key={id}
        draggableId={id}
        index={index}
      >
        {(
          provided: DraggableProvided,
          { isDragging }: DraggableStateSnapshot
        ) => (
          <>
            <TableRow
              ref={provided.innerRef}
              {...provided.draggableProps}
              className={`${classes.row} ${
                isDragging ? classes.isDragging : ''
              }`}
            >
              <TableCell
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                style={{
                  paddingRight: '0',
                }}
              >
                <img
                  src={dragIndicator}
                  alt="drag indicator"
                  {...provided.dragHandleProps}
                  style={{
                    filter: isLocked ? 'opacity(0.5)' : '',
                  }}
                />
              </TableCell>
              <TableCell
                colSpan={3}
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <AccordionButton
                    onClick={() => setIsExpanded(!isExpanded)}
                    expanded={isExpanded}
                  />
                  <MarkAPIButton
                    disabled={isLocked && !active}
                    onClick={() => setIsAPIIngredientModalOpen(true)}
                    selected={active}
                  />
                  {isIngredientKey ? (
                    <IconButton disabled={isLocked} className={classes.keyIcon}>
                      <Tooltip title="Unmark key ingredient">
                        <StarRoundedIcon
                          onClick={() =>
                            handleKeyIngredient(false, ingredient.id)
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <IconButton disabled={isLocked} className={classes.keyIcon}>
                      <Tooltip title="Mark key ingredient">
                        <StarBorderRoundedIcon
                          onClick={() =>
                            handleKeyIngredient(true, ingredient.id)
                          }
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                </Grid>
              </TableCell>
              <TableCell
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                align="right"
                style={{
                  paddingRight: 10,
                  paddingLeft: 0,
                }}
              >
                {position}
              </TableCell>
              <TableCell
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                style={{
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                {renderRmLink(rmId || `SAMP-${sampleCode}`)}
              </TableCell>
              <TableCell
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                style={{
                  paddingLeft: 10,
                }}
              >
                <div className="flex">
                  {renderRmLink(name)}
                  <Tooltip title="Change ingredient">
                    <SwapHorizIcon
                      className="cursor-pointer ml-1"
                      onClick={() => setIsAddIngredientModalOpen(true)}
                    />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                align="right"
              >
                <div style={{ display: 'flex' }}>
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography
                        className={
                          !ingredient?.price?.cost ? classes.notApplicable : ''
                        }
                        variant="body1"
                      >
                        {ingredient.price?.cost
                          ? `$${calculatePricePerOunceAtCurrentWeight(
                              ingredient,
                              selectedPricingUnit
                            ).toFixed(2)}
                    (${percentageOfTotal.toFixed(2)} %)`
                          : PRICE_NOT_AVAILABLE}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        className={classes.lastUpdated}
                        variant="caption"
                      >
                        Last updated {generateLastUpdatedString()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      alignSelf: 'center',
                      marginLeft: '4px',
                      cursor: 'pointer',
                    }}
                  >
                    {ingredient.price?.cost ? (
                      <div onClick={() => setIsSelectPricingModalOpen(true)}>
                        <Tooltip title="Select price">
                          <SwapHorizIcon />
                        </Tooltip>
                      </div>
                    ) : (
                      <Tooltip title="Add pricing">
                        <Link
                          to={ROUTES.SHOW_RAW_MATERIAL.route.replace(
                            UUID_SHOW_ROUTE_STRING,
                            rmUuid
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AddIcon />
                        </Link>
                      </Tooltip>
                    )}
                  </Grid>
                </div>
              </TableCell>
              <TableCell
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                align="right"
              >
                {isLocked ? (
                  <Typography variant="body1">
                    {percentageFieldFocused
                      ? percentage
                      : percentage.toFixed(WEIGHT_PERCENT_PRECISION)}
                  </Typography>
                ) : (
                  <TextField
                    onBlur={() =>
                      handleUpdate(PERCENTAGE_INPUT_NAME, percentage)
                    }
                    inputProps={{
                      maxLength: 6,
                      step: '0.0001',
                    }}
                    onFocus={() => setPercentageFieldFocused(true)}
                    type="number"
                    onChange={(e) =>
                      setPercentage(
                        parseFloat(e.target.value) < 0
                          ? // Zero out the field if the user tries to enter a negative number
                            0
                          : parseFloat(e.target.value)
                      )
                    }
                    value={
                      percentageFieldFocused
                        ? percentage
                        : percentage.toFixed(WEIGHT_PERCENT_PRECISION)
                    }
                    variant="outlined"
                  />
                )}
              </TableCell>
              <TableCell
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                align="right"
              >
                <Typography variant="body1">
                  {isNaN(weightGrams)
                    ? defaultWeightGrams
                    : weightGrams.toFixed(WEIGHT_GRAMS_PRECISION)}
                </Typography>
              </TableCell>
              <TableCell
                className={
                  isExpanded ? classes.expandedCell : classes.tableCell
                }
                style={{
                  paddingLeft: '0',
                }}
              >
                <DeleteButton
                  disabled={isLocked || isDeleting}
                  onClick={handleDeleteIngredient}
                />
              </TableCell>
            </TableRow>
            <IngredientsTableExpandedRow
              isLocked={isLocked}
              handleAttributeUpdate={handleUpdate}
              ingredient={ingredient}
              isExpanded={!isDragging && isExpanded}
            />
          </>
        )}
      </Draggable>
    </>
  );
};
