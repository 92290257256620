// Libraries
import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  MenuProps,
  Tooltip,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import {
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
// Components
import {
  isFileExtensionAccepted,
  isFileSizeTooBig,
} from 'features/home/customer-portal/customer-project-page/file-manager';
// Assets
import { ReactComponent as Upload } from '../../img/upload.svg';
// Utils
import { Can, PERMISSIONS } from 'features/rbac';
import { CompanyAttributes, IApiData, useApi } from 'api';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import * as Constants from 'features/home/customer-portal/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalIcon: {
      color: theme.palette.secondary.dark,
      fontSize: '2.2rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

interface IProjectOptionsMenu {
  projectId: string | undefined;
  openAssignmentDialog: () => void;
  openPreliminaryQuoteModal: () => void;
  openFinalQuoteModal: () => void;
  company?: Maybe<IApiData<CompanyAttributes>>;
}

export default function ProjectActionsMenu({
  projectId,
  openAssignmentDialog,
  openPreliminaryQuoteModal,
  openFinalQuoteModal,
}: IProjectOptionsMenu) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { postProjectFile } = useApi();
  const uploadRef = React.createRef<HTMLInputElement>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const toggleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendToFormulaPageWithProject = () => {
    navigate(ROUTES.CREATE_FORMULA.route, { state: { projectId } });
  };

  const handleILUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files![0];

    if (isFileSizeTooBig(selectedFile)) {
      alert(Constants.UPLOAD_WARNINGS.fileSizeWarning.content);
      return;
    } else if (!isFileExtensionAccepted(selectedFile)) {
      alert(Constants.UPLOAD_WARNINGS.fileExtensionWarning.content);
      return;
    } else {
      const formData = new FormData();
      formData.append('document', selectedFile);

      setIsUploading(true);

      postProjectFile({
        urlParams: `${projectId}/documents?category=${Constants.FILE_CATEGORIES.approveIngredientList}&dupe=duplicate`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        handleSuccess: () => {
          navigate(
            ROUTES.EMPLOYEE_VIEW_CUSTOMER_PROJECT.route.replace(
              UUID_SHOW_ROUTE_STRING,
              projectId as string
            ),
            {
              // Used in the project tracker to show a success snackbar
              state: { fileUploadSuccess: true },
            }
          );
        },
        handleFinally: () => {
          e.target.value = '';
          setIsUploading(false);
        },
      });
    }
  };

  return (
    <>
      <Tooltip title="Menu">
        <MoreVertIcon
          className={classes.verticalIcon}
          fontSize="large"
          onClick={toggleMenuOpen}
        />
      </Tooltip>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        id="customized-menu"
      >
        <StyledMenuItem onClick={sendToFormulaPageWithProject}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Create formula from project" />
        </StyledMenuItem>
        <Can
          perform={PERMISSIONS.ASSIGN_BRIEFS}
          yes={() => (
            <StyledMenuItem onClick={openAssignmentDialog}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Assign and Unassign project" />
            </StyledMenuItem>
          )}
        />
        <StyledMenuItem
          onClick={() => uploadRef.current?.click()}
          disabled={isUploading}
        >
          <ListItemIcon>
            <Upload />
          </ListItemIcon>
          <ListItemText
            primary="Upload IL to project tracker"
            secondary={isUploading ? 'Uploading...' : 'Max File Size: 25MB'}
          />
        </StyledMenuItem>
        <input
          ref={uploadRef}
          type="file"
          style={{ position: 'fixed', top: '-100%' }}
          onChange={handleILUpload}
        />
        <Can
          perform={PERMISSIONS.VISIT_PRICE_QUOTE}
          yes={() => (
            <StyledMenuItem
              onClick={() => {
                openPreliminaryQuoteModal();
                handleClose();
              }}
            >
              <ListItemIcon>
                <ClipboardDocumentListIcon className="h-5 w-5" />
              </ListItemIcon>
              <ListItemText primary="Prelim Quote" />
            </StyledMenuItem>
          )}
        />
        <Can
          perform={PERMISSIONS.VISIT_PRICE_QUOTE}
          yes={() => (
            <StyledMenuItem
              onClick={() => {
                openFinalQuoteModal();
                handleClose();
              }}
            >
              <ListItemIcon>
                <ClipboardDocumentCheckIcon className="h-5 w-5" />
              </ListItemIcon>
              <ListItemText primary="Final Quote" />
            </StyledMenuItem>
          )}
        />
      </StyledMenu>
    </>
  );
}
