//Libraries
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
//Utils
import {
  getGetSettings,
  getPostSettings,
  getPatchSettings,
  getPutSettings,
  getDeleteSettings,
} from 'features/api-v1';
// Constants
import { GET, POST, PATCH, PUT, DELETE } from 'features/constants';

const NO_CONTENT_STATUS = 204;
const CONTENT_TYPE = 'Content-type';
const APPLICATION_JSON = 'application/json';
const APPLICATION_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const APPLICATION_PDF = 'application/pdf';
const LOGIN_REQUIRED = 'login_required';

interface IHttpMethodMap {
  [key: string]: any;
}

const httpMethodMap: IHttpMethodMap = {
  [GET]: getGetSettings,
  [POST]: getPostSettings,
  [PATCH]: getPatchSettings,
  [PUT]: getPutSettings,
  [DELETE]: getDeleteSettings,
};

export interface IRequestOptions {
  scope?: string;
  body?: object;
  settings: string;
}

export interface IRequestParams {
  resource: string;
  options?: IRequestOptions;
}

/**
 * NOTE:
 * API V1 is being slowly deprecated. We should no longer import from this file.
 * Instead we should import any network requests within LOUIS from api/useApi.tsx
 */

const baseUrl: string = `${process.env.REACT_APP_R_AND_D_API_BASE_URL}`;

export const useApi = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const getToken = useCallback(
    function (scope: string) {
      return getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
        scope: scope,
      });
    },
    [getAccessTokenSilently]
  );

  const request = useCallback(
    async function ({ resource, options }: IRequestParams) {
      try {
        const token = await getToken(options?.scope as string);
        const getFetchOptions = httpMethodMap[options?.settings as string];

        const fetchResponse = await fetch(
          `${baseUrl}${resource}`,
          getFetchOptions(token, options?.body!)
        );
        const contentType = fetchResponse.headers.get(CONTENT_TYPE);
        if (fetchResponse.ok) {
          // Check for No Content status
          if (fetchResponse.status === NO_CONTENT_STATUS) {
            return fetchResponse;
          }
          if (contentType?.includes(APPLICATION_JSON)) {
            const parsedResponse = await fetchResponse.json();
            parsedResponse.token = token;
            return parsedResponse;
          } else if (
            contentType?.includes(APPLICATION_PDF) ||
            contentType?.includes(APPLICATION_XLSX)
          ) {
            return await fetchResponse.blob();
          } else {
            throw new Error(`Unhandled content type: ${contentType}`);
          }
        } else {
          // If response is not 'ok', server will explicity send a json response w/ 'errors' array
          // We return this, so that the component can decide what to do w/ the errors
          return await fetchResponse.json();
        }
      } catch (e: any) {
        if (e.error === LOGIN_REQUIRED) {
          Rollbar.warning(e); // send as a warning to Rollbar since the redirect will handle the issue

          loginWithRedirect();
          return e;
        } else {
          Rollbar.error(e);
          return e;
        }
      }
    },
    [getToken, loginWithRedirect]
  );

  return request;
};
