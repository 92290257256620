// Libraries
import React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface UserHistoryProps {
  createdBy: Maybe<string>;
  createdAt: Maybe<Date | string>;
  updatedBy: Maybe<string>;
  updatedAt: Maybe<Date | string>;
}

export const UserHistory: React.FC<UserHistoryProps> = ({
  createdBy,
  createdAt,
  updatedBy,
  updatedAt,
}) => {
  return (
    <Grid container xs={12} direction="column">
      {createdBy && (
        <Grid item xs={6}>
          <Typography>
            Created by {createdBy} at{' '}
            {typeof createdAt === 'string'
              ? new Date(createdAt)?.toLocaleDateString()
              : createdAt?.toLocaleDateString()}
          </Typography>
        </Grid>
      )}
      {updatedBy && (
        <Grid item xs={6}>
          <Typography>
            Updated by {updatedBy} at{' '}
            {typeof updatedAt === 'string'
              ? new Date(updatedAt)?.toLocaleDateString()
              : updatedAt?.toLocaleDateString()}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
