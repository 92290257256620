// Libraries
import React from 'react';
// Components
import { RawMaterialSearchDropdown } from './raw-material-search-dropdown.component';
import { Search } from './search.component';
// Utils
import { IApiResponse } from 'features/types';
// Assets
import { ReactComponent as Cancel } from '../images/cancel.svg';
// Utils
import { useApi } from 'api';
import { IRawMaterialFormValues } from 'features/formula/formula-page/types';
// Constants
import { INITIAL_RAW_MATERIAL_FORM_VALUES } from 'features/formula/formula-page/constants';
import { API_TYPES } from 'api/api.constants';

interface IRawMaterialSearch {
  rawMaterialFormValues: IRawMaterialFormValues;
  isSearchDisabled: boolean;
  setRawMaterialFormValues: React.Dispatch<
    React.SetStateAction<IRawMaterialFormValues>
  >;
  setIsSearchDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RawMaterialSearch: React.FC<IRawMaterialSearch> = ({
  rawMaterialFormValues,
  isSearchDisabled,
  setRawMaterialFormValues,
  setIsSearchDisabled,
}) => {
  const { searchRawMaterials } = useApi();

  const parseSearchResults = (response: IApiResponse) => {
    return response.data?.map((data) => ({
      id: data.id,
      ...data.attributes,
      prices: response.included
        .filter(
          (resource) =>
            resource.type === API_TYPES.PRICE &&
            resource.relationships?.rawMaterial?.data?.id === data.id
        )
        .map((price) => ({
          ...price,
          supplier: response.included.find(
            (resource) =>
              resource.type === API_TYPES.SUPPLIER &&
              resource.id === price.relationships.supplier?.data?.id
          ),
        })),
    }));
  };

  const resetSearchInput = () => {
    setRawMaterialFormValues({
      ...rawMaterialFormValues,
      ...INITIAL_RAW_MATERIAL_FORM_VALUES,
    });
    setIsSearchDisabled(false);
  };

  const handleSearchResultsSelection = (item: any) => {
    setRawMaterialFormValues({
      ...rawMaterialFormValues,
      id: item.id,
      name: item.name,
      rmId: item.rmId,
      sampleCode: item.sampleCode,
    } as IRawMaterialFormValues);
    // resetting search results && disabling input modification
    setIsSearchDisabled(true);
  };

  return (
    <div>
      <Search
        searchFunction={searchRawMaterials}
        disabled={isSearchDisabled}
        parseSearchResults={parseSearchResults}
        placeholder="Find a Raw Material... *"
        required
        resource="raw_materials"
        selectedValue={rawMaterialFormValues.name}
      >
        {({
          isSearching,
          isDropdownOpen,
          searchResults,
          setIsDropdownOpen,
        }) => (
          <RawMaterialSearchDropdown
            handleSearchResultsSelection={handleSearchResultsSelection}
            closeDropdown={() => {
              setRawMaterialFormValues({
                ...rawMaterialFormValues,
                ...INITIAL_RAW_MATERIAL_FORM_VALUES,
              });
              setIsSearchDisabled(false);
              setIsDropdownOpen(false);
            }}
            isSearching={isSearching}
            isOpen={isDropdownOpen}
            searchResults={searchResults}
            setIsOpen={setIsDropdownOpen}
          />
        )}
      </Search>
      <Cancel
        fill={'#3f3f46'}
        width="20"
        height="20"
        className={`${
          isSearchDisabled ? '' : 'hidden'
        } absolute top-4 right-20 cursor-pointer`}
        onClick={resetSearchInput}
      />
    </div>
  );
};
