// Libraries
import React from 'react';
// Components
import { Divider } from 'design-system';
import { RAndDDocuments, TestHandling, Tests } from '../form-modules';

export const Testing: React.FC = () => (
  <>
    <Tests />

    <Divider />

    <TestHandling />

    <Divider />

    <RAndDDocuments />
  </>
);

export default Testing;
