// Libraries
import React, { useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/16/solid';
import * as amplitude from '@amplitude/analytics-browser';
// Components
import { Button, Typography } from 'design-system';
// Assets
import ChemistryEquipment from 'img/chemistry-equipment.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

interface BriefSubmissionConfirmationProps {
  projectUuid: string | null;
}

export const BriefSubmissionConfirmation: React.FC<BriefSubmissionConfirmationProps> = ({
  projectUuid,
}) => {
  const navigate = useNavigate();
  window.history.pushState(
    null,
    '',
    '/custom-brief/#custom-development-confirmation'
  );

  useEffect(() => {
    amplitude.track('Custom Development Brief Submitted');
  });

  return (
    <div className="w-full flex flex-col mt-[60px]">
      <div>
        <div className="relative flex mb-[100px] ml-[140px]">
          <div className="flex flex-col w-[70%] gap-6 bg-white">
            <Typography variant="h2">
              Congrats, your brief is submitted!
            </Typography>
            <Typography variant="h4" font="inter">
              Someone from Prime Matter Labs will be in touch soon to get your
              project started.
            </Typography>
            <Button
              text="Go to my Project Dashboard"
              trailingIcon={<ArrowRightIcon />}
              type="primary"
              action={() =>
                navigate(
                  ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
                    UUID_SHOW_ROUTE_STRING,
                    projectUuid!
                  )
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="relative flex flex-col bg-blue-95 px-[140px] pb-[250px] min-h-[800px] h-full">
        <div className="absolute right-0 -top-[50px]">
          <img src={ChemistryEquipment} alt="chemistry equipment" />
        </div>
      </div>
    </div>
  );
};
