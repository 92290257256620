// Libraries
import React from 'react';
// Components
import { IngredientListDoneActions } from './ingredient-list-done-actions.component';
// Constants
import { Task, TaskStatus } from 'features/home/customer-portal/types';

interface IngredientListFeedbackActionsProps {
  task: Task;
  project: any;
  files: any;
}

export const IngredientListFeedbackActions: React.FC<IngredientListFeedbackActionsProps> = ({
  task,
  project,
  files,
}) => {
  if (task.status === TaskStatus.done) {
    return <IngredientListDoneActions project={project} files={files} />;
  }

  return null;
};
