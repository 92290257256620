// Library
import React, { useState } from 'react';
// Components
import { ExcessRawMaterialsBreakdown } from './excess-raw-materials-breakdown.component';
import { ExcessRawMaterialChart } from './excess-raw-material-chart.component';
import { ExcessRawMaterialWarnings } from './excess-raw-material-warnings.component';
import { TabPanel, TabbedMenu } from 'features/ui';
// Utils
import {
  BriefAttributes,
  FormulaAttributes,
  IApiData,
  IngredientAttributes,
  PricingQuoteAttributes,
} from 'api';
import { IPricingData, Tank } from './types';
import { THEME_PALETTE } from 'styles/mui/themeV2';
import { generateExcessRawMaterialsWarnings } from './excess-raw-material-warnings-generator.component';
// Constants
import { PRODUCTION_LOCATIONS } from './constants';

interface IExcessRawMaterialsTabbedContainerProps {
  brief: BriefAttributes;
  formula: Maybe<IApiData<FormulaAttributes>>;
  ingredients: Maybe<IApiData<IngredientAttributes>[]>;
  pricingData: Maybe<IPricingData>;
  pricingQuote: Maybe<IApiData<PricingQuoteAttributes>>;
  productionLocation: Maybe<valueof<typeof PRODUCTION_LOCATIONS>>;
  productionTank: Maybe<
    Tank & {
      tanks: Tank[];
    }
  >;
}

export const ExcessRawMaterialsTabbedContainer: React.FC<IExcessRawMaterialsTabbedContainerProps> = ({
  brief,
  formula,
  ingredients,
  pricingData,
  pricingQuote,
  productionLocation,
  productionTank,
}) => {
  const [tab, setTab] = useState<number>(0);
  const excessRawMaterialWarnings = generateExcessRawMaterialsWarnings(
    ingredients
  );

  if (!formula) return null;

  return (
    <>
      <ExcessRawMaterialWarnings warnings={excessRawMaterialWarnings} />
      <TabbedMenu
        backgroundColor={THEME_PALETTE.blue.dark}
        handleTabChange={(_, newTab) => setTab(newTab)}
        tabValue={tab}
        tabs={[
          {
            label: 'RM Breakdown',
          },
          {
            label: '% Excess Raws',
          },
        ]}
      />
      <TabPanel value={tab} index={0}>
        <ExcessRawMaterialsBreakdown brief={brief} ingredients={ingredients} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ExcessRawMaterialChart
          brief={brief}
          formula={formula}
          ingredients={ingredients}
          pricingData={pricingData}
          pricingQuote={pricingQuote}
          productionLocation={productionLocation}
          productionTank={productionTank}
        />
      </TabPanel>
    </>
  );
};
