// Libraries
import React from 'react';
import { Box, Paper, makeStyles, createStyles } from '@material-ui/core';
// Utils
import { ITheme, gTAmericaMonoRegular } from 'styles/mui/themeV2';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  [propName: string]: any;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    tab: {
      fontFamily: gTAmericaMonoRegular.fontFamily,
    },
  })
);

export const TabPanel = ({
  children,
  value,
  index,
  ...rest
}: TabPanelProps) => {
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      <Paper className={classes.tab}>
        {value === index && <Box>{children}</Box>}
      </Paper>
    </div>
  );
};
