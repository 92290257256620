// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import {
  TableCell,
  TableRow,
  TableBody,
  CircularProgress,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
// Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      '& td': {
        padding: '6.5px 12px 6.8px 12px',
        height: 40,
      },
    },
    formulaCell: {
      position: 'relative',
      color: theme.palette.primary.main,
      fontFamily: 'Inter Regular',
      transition: 'all 0.18s ease-in',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      '&:active': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    formulaLink: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      paddingLeft: 12,
    },
  })
);
interface FormulasTableProps {
  formulas: Array<any>;
  isLoading: boolean;
  searchHasBeenPerformed?: boolean;
  sortByCreatedAt?: boolean;
}

export const FormulasTableBody: React.FC<FormulasTableProps> = ({
  formulas,
  isLoading,
  searchHasBeenPerformed,
  sortByCreatedAt,
}) => {
  const classes = useStyles();

  function navigateToFormula(uuid: string) {
    return ROUTES.SHOW_FORMULA.route.replace(UUID_SHOW_ROUTE_STRING, uuid);
  }

  const sortedFormulas = sortByCreatedAt
    ? formulas.sort((a, b) =>
        new Date(a.attributes.createdAt) > new Date(b.attributes.createdAt)
          ? -1
          : 1
      )
    : formulas;

  return (
    <>
      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell colSpan={100} align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        ) : formulas.length ? (
          sortedFormulas.map((formula) => {
            return (
              <TableRow
                key={`formula-${formula.id}`}
                classes={{ root: classes.row }}
              >
                <TableCell></TableCell>
                <TableCell
                  align="left"
                  className={classes.formulaCell}
                  style={{ padding: 0, height: 1 }}
                >
                  <Link
                    className={classes.formulaLink}
                    to={navigateToFormula(formula.id)}
                  >
                    {formula.name}
                  </Link>
                </TableCell>
                {Object.values(formula.attributes).map(
                  (attribute: any, index) => {
                    return (
                      <TableCell key={`${index}-${attribute}`} align="left">
                        {attribute}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={100} align="center">
              {searchHasBeenPerformed
                ? 'No results found'
                : 'Search for a formula'}
            </TableCell>
          </TableRow>
        )}
        <TableRow></TableRow>
      </TableBody>
    </>
  );
};
