import React, { useState } from 'react';
import { RtlIconMapContext } from './rtl-icon-map.context';

interface RtlIconMapContextProviderProps {
  children: any;
}

export const RtlIconMapContextProvider: React.FC<RtlIconMapContextProviderProps> = ({
  children,
}) => {
  const [rtlIconMap, setRtlIconMap] = useState({});
  return (
    <RtlIconMapContext.Provider value={{ rtlIconMap, setRtlIconMap }}>
      {children}
    </RtlIconMapContext.Provider>
  );
};
