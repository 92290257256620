//Libraries
import React, { useState, ReactNode } from 'react';
//Components
import { UserContext, UserSession } from './user.context';

interface UserContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [roles, setRoles] = useState<Array<string>>([]);
  const [permissions, setPermissions] = useState<Array<string>>([]);
  const [userSession, setUserSession] = useState<UserSession>({
    uuid: '',
    email: '',
    thirdPartyId: '',
    givenName: '',
    familyName: '',
    avatarColor: '',
    customerMetadata: {
      companyName: '',
      companyUuid: '',
      notificationPreference: '',
      isNewCustomer: false,
    },
    employeeMetadata: {
      location: '',
      searchLocation: '',
    },
  });

  return (
    <UserContext.Provider
      value={{
        roles,
        setRoles,
        permissions,
        setPermissions,
        userSession,
        setUserSession,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
