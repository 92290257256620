// Libraries
import React from 'react';
import { XMarkIcon } from '@heroicons/react/16/solid';

interface FullscreenOverlayProps {
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
}

export const FullscreenOverlay: React.FC<FullscreenOverlayProps> = ({
  children,
  onClose,
}) => {
  return (
    // Left-nav is z-index 1250
    <div className="absolute z-[1251] w-full h-full top-0 left-0 bg-white">
      <div className="fixed top-3 z-[1251] right-6 cursor-pointer">
        <XMarkIcon onClick={onClose} className="h-6 w-6 text-grey-50" />
      </div>
      {children}
    </div>
  );
};
