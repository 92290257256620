//Libraries
import React from 'react';
import Link from '@material-ui/core/Link';
//Utils
import { ROUTES } from 'features/navigation';

export const PrivacyPolicy: React.FC = () => {
  return (
    <div>
      <p>Last Modified: February 1, 2021</p>

      <p>
        <b>
          <u>Introduction</u>
        </b>
      </p>

      <p>
        Prime Enterprises LLC d/b/a Prime Matter Labs ("
        <b>Company</b>," "<b>we</b>,")” respect your privacy and are committed
        to protecting it through our compliance with this policy. This policy
        describes the types of information we may collect from you or that you
        may provide when you visit the website www.primematterlabs.com and
        related websites (the "<b>Website</b>") and our practices for
        collecting, using, maintaining, protecting, and disclosing that
        information.
      </p>

      <p>This policy applies to information we collect:</p>

      <p>
        <ul>
          <li>On this Website.</li>
          <li>
            In email, text, and other electronic messages between you and this
            Website.
          </li>
          <li>
            Through mobile and desktop applications you download from this
            Website, which provide dedicated non-browser-based interaction
            between you and this Website.
          </li>
          <li>
            When you interact with our advertising and applications on
            third-party websites and services, if those applications or
            advertising include links to this policy.
          </li>
        </ul>
      </p>

      <p>It does not apply to information collected by:</p>

      <p>
        <ul>
          <li>On this Website.</li>
          <li>
            Us offline or through any other means, including on any other
            website operated by Company or any third party (including our
            affiliates and subsidiaries); or
          </li>
          <li>
            Any third party, including through any application or content
            (including advertising) that may link to or be accessible from or on
            the Website
          </li>
        </ul>
      </p>

      <p>
        Please read this policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, your choice is not to use our
        Website. By accessing or using this Website, you agree to this privacy
        policy. This policy may change from time to time (see Changes to Our
        Privacy Policy). Your continued use of this Website after we make
        changes is deemed to be acceptance of those changes, so please check the
        policy periodically for updates.
      </p>

      <p>
        <b>
          <u>Children Under the Age of 18</u>
        </b>
      </p>

      <p>
        Our Website is not intended for children under 18 years of age. No one
        under age 18 may provide any information to or on the Website. We do not
        knowingly collect personal information from children under 18. If you
        are under 18, do not use or provide any information on this Website or
        through any of its features, register on the Website, use any of the
        interactive or submission features of this Website, or provide any
        information about yourself to us, including your name, address,
        telephone number, email address, or any screen name or user name you may
        use. If we learn we have collected or received personal information from
        a child under 18 without verification of parental consent, we will
        delete that information. If you believe we might have any information
        from or about a child under 18, please contact us at
        support@primematterlabs.com
      </p>

      <p>
        California residents may have additional rights regarding the collection
        and sale of their personal information. Please see Your California
        Privacy Rights for more information.
      </p>

      <p>
        <b>
          <u>Information We Collect About You and How We Collect It</u>
        </b>
      </p>

      <p>
        We collect several types of information from and about users of our
        Website, including information:
      </p>

      <p>
        <ul>
          <li>
            By which you may be personally identified, such as name, postal
            address, e-mail address, or telephone number (
            <b>“personal information”</b>);
          </li>

          <li>
            About your internet connection, the equipment you use to access our
            Website, and usage details.
          </li>
        </ul>
      </p>

      <p>We collect this information:</p>

      <p>
        <ul>
          <li>Directly from you when you provide it to us.</li>

          <li>
            Automatically as you navigate through the site. Information
            collected automatically may include usage details, IP addresses, and
            information collected through cookies and other tracking
            technologies.
          </li>

          <li>From third parties, for example, our business partners.</li>
        </ul>
      </p>

      <p>
        <i>Information You Provide to Us </i>
      </p>

      <p>The information we collect on or through our Website may include:</p>

      <ul>
        <li>
          Information that you provide by filling in forms on our Website. This
          includes information provided at the time of registering to use our
          Website, subscribing to our service, submitting material, or
          requesting further services. We may also ask you for information when
          you report a problem with our Website or make other inquiries.
        </li>

        <li>
          Records and copies of your correspondence (including email addresses),
          if you contact us.
        </li>

        <li>
          Details of transactions you carry out through our Website and of the
          fulfillment of your orders. You may be required to provide financial
          information before placing an order through our Website.
        </li>
      </ul>

      <p>
        You also may provide information to be analyzed, reviewed, used, or
        otherwise transmitted to the Company or third parties (collectively,
        <b>“User Contributions”</b>).{' '}
      </p>

      <p>
        <i>
          Information We Collect Through Automatic Data Collection Technologies{' '}
        </i>
      </p>

      <p>
        As you navigate through and interact with our Website, we may use
        automatic data collection technologies to collect certain information
        about your equipment, browsing actions, and patterns, including:
      </p>

      <p>
        <ul>
          <li>
            Details of your visits to our Website, including traffic data, logs,
            and other communication data and the resources that you access and
            use on the Website.
          </li>

          <li>
            Information about your computer and internet connection, including
            your IP address, operating system, and browser type.
          </li>
        </ul>
      </p>

      <p>
        We also may use these technologies to collect information about your
        online activities over time and across third-party websites or other
        online services (behavioral tracking). See below for information on how
        you can opt out of behavioral tracking on this website.
      </p>

      <p>
        The information we collect automatically does not include personal
        information, but we may maintain it or associate it with personal
        information we collect in other ways or receive from third parties. It
        helps us to improve our Website and to deliver a better and more
        personalized service, including by enabling us to:
      </p>

      <p>
        <ul>
          <li>Estimate our audience size and usage patterns.</li>

          <li>
            Store information about your preferences, allowing us to customize
            our Website according to your individual interests.
          </li>

          <li>Speed up your searches.</li>

          <li>Recognize you when you return to our Website.</li>
        </ul>
      </p>

      <p>
        The technologies we use for this automatic data collection may include:
      </p>

      <p>
        <ul>
          <li>
            <b>Cookies (or browser cookies).</b> A cookie is a small file placed
            on the hard drive of your computer. You may refuse to accept browser
            cookies by activating the appropriate setting on your browser.
            However, if you select this setting you may be unable to access
            certain parts of our Website. Unless you have adjusted your browser
            setting so that it will refuse cookies, our system will issue
            cookies when you direct your browser to our Website.{' '}
          </li>

          <li>
            <b>Flash Cookies.</b> Certain features of our Website may use local
            stored objects (or Flash cookies) to collect and store information
            about your preferences and navigation to, from, and on our Website.
            Flash cookies are not managed by the same browser settings as are
            used for browser cookies. For information about managing your
            privacy and security settings for Flash cookies, see Choices About
            How We Use and Disclose Your Information.
          </li>
        </ul>
      </p>

      <p>
        <b>
          <u>How We Use Your Information</u>
        </b>
      </p>

      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information:
      </p>

      <p>
        <ul>
          <li>To present our Website and its contents to you.</li>
          <li>
            To provide you with information, products, or services that you
            request from us.
          </li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>To provide you with notices about your account.</li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li>
            To notify you about changes to our Website or any products or
            services we offer or provide though it.
          </li>
          <li>
            To allow you to participate in interactive features on our Website.
          </li>
          <li>
            In any other way we may describe when you provide the information.
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>
      </p>

      <p>
        We may also use your information to contact you about our own and
        third-parties’ goods and services that may be of interest to you. If you
        do not want us to use your information in this way, please check the
        relevant box located on the form on which we collect your data or adjust
        your user preferences in your account profile. For more information, see
        Choices About How We Use and Disclose Your Information.
      </p>

      <p>
        We may use the information we have collected from you to enable us to
        display advertisements to our advertisers’ target audiences. Even though
        we do not disclose your personal information for these purposes without
        your consent, if you click on or otherwise interact with an
        advertisement, the advertiser may assume that you meet its target
        criteria.
      </p>

      <p>
        <b>
          <u>Disclosure of Your Information</u>
        </b>
      </p>

      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction.{' '}
      </p>

      <p>
        We may disclose personal information that we collect or you provide as
        described in this privacy policy:
      </p>
      <p>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support our business and who are bound by contractual obligations to
            keep personal information confidential and use it only for the
            purposes for which we disclose it to them.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of the Company’s assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by the Company about
            our Website users is among the assets transferred.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li>With your consent.</li>
        </ul>
      </p>

      <p>We may also disclose your personal information:</p>

      <p>
        <ul>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            To enforce or apply our{' '}
            <Link href={ROUTES.TERMS_OF_USE.route}>Terms of Use</Link> and other
            agreements, including for billing and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of the Company, our customers, or
            others.
          </li>
        </ul>
      </p>

      <p>
        <b>
          <u>Choices About How We Use and Disclose Your Information</u>
        </b>
      </p>

      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:{' '}
      </p>

      <p>
        <ul>
          <li>
            Tracking Technologies and Advertising. You can set your browser to
            refuse all or some browser cookies, or to alert you when cookies are
            being sent. To learn how you can manage your Flash cookie settings,
            visit the Flash player settings page on Adobe’s website. If you
            disable or refuse cookies, please note that some parts of this site
            may then be inaccessible or not function properly.
          </li>
          <li>
            Promotional Offers from the Company. If you do not wish to have your
            contact information used by the Company to promote our own or third
            parties’ products or services, you can opt-out by sending us an
            email stating your request to{' '}
            <Link href="mailto:support@primematterlabs.com">
              support@primematterlabs.com
            </Link>
          </li>
        </ul>
      </p>

      <p>
        We do not control third parties’ collection or use of your information
        to serve interest-based advertising. However these third parties may
        provide you with ways to choose not to have your information collected
        or used in this way. You can opt out of receiving targeted ads from
        members of the Network Advertising Initiative <b>(“NAI”)</b> on the
        NAI’s website.
      </p>

      <p>
        California residents may have additional personal information rights and
        choices. Please see Your California Privacy Rights for more information.
      </p>

      <p>
        Nevada residents who wish to exercise their sale opt-out rights under
        Nevada Revised Statutes Chapter 603A may submit a request to this
        designated address:{' '}
        <Link href="mailto:support@primematterlabs.com">
          support@primematterlabs.com
        </Link>{' '}
        However, please know we do not currently sell data triggering that
        statute’s opt-out requirements.
      </p>

      <p>
        <b>
          <u>Accessing and Correcting Your Information</u>
        </b>
      </p>

      <p>
        You may send us an email at{' '}
        <Link href="mailto:support@primematterlabs.com">
          support@primematterlabs.com
        </Link>{' '}
        to request access to, correct or delete any personal information that
        you have provided to us. If we delete your personal information we may
        need to delete your user account. We may not accommodate a request to
        change information if we believe the change would violate any law or
        legal requirement or cause the information to be incorrect.
      </p>

      <p>
        <b>
          <u>Your California Privacy Rights</u>
        </b>
      </p>

      <p>
        If you are a California resident, California law may provide you with
        additional rights regarding our use of your personal information. To
        learn more about your California privacy rights, you may send us an
        email at{' '}
        <Link href="mailto:support@primematterlabs.com">
          support@primematterlabs.com
        </Link>
      </p>

      <p>
        <b>
          <u>Data Security</u>
        </b>
      </p>

      <p>
        The safety and security of your information also depends on you. Where
        we have given you (or where you have chosen) a password for access to
        certain parts of our Website, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone. Unfortunately, the transmission of information via the internet
        is not completely secure. Although we do our best to protect your
        personal information, we cannot guarantee the security of your personal
        information transmitted to our Website. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Website.
      </p>

      <p>
        <b>
          <u>Changes to Our Privacy Policy</u>
        </b>
      </p>

      <p>
        It is our policy to post any changes we make to our privacy policy on
        this page with a notice that the privacy policy has been updated on the
        Website home page. If we make material changes to how we treat our
        users’ personal information, we will notify you through a notice on the
        Website home page. The date the privacy policy was last revised is
        identified at the top of the page. You are responsible for ensuring we
        have an up-to-date active and deliverable email address for you, and for
        periodically visiting our Website and this privacy policy to check for
        any changes.
      </p>

      <p>
        <b>
          <u>Contact Information</u>
        </b>
      </p>

      <p>
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us at:{' '}
      </p>

      <p>
        <Link href="mailto:support@primematterlabs.com">
          support@primematterlabs.com
        </Link>
      </p>
    </div>
  );
};
