// Libraries
import React, { useEffect, useState } from 'react';
import { FullScreenModal, Typography } from 'design-system';
// Utils
import {
  IApiData,
  CompanyAttributes,
  PriceQuoteType,
  PriceQuoteAttributes,
  TaskAttributes,
} from 'api';
// Components
import { PriceQuoteModal } from 'features/price-quote';
// Assets
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
// Constants
import { TASK_COLUMN_IDS } from 'features/home/customer-portal/constants';
import { PRICE_QUOTE_TASK_STATUSES } from 'features/price-quote/price-quote.constants';

interface GeneratePriceQuoteModalProps {
  quoteType: PriceQuoteType;
  company: Maybe<IApiData<CompanyAttributes>>;
  projectId: Maybe<string>;
  toggleQuoteModal: () => void;
  priceQuote?: Maybe<IApiData<PriceQuoteAttributes>>;
  setProject: (project: any) => void;
  tasks?: Maybe<Array<IApiData<TaskAttributes>>>;
}

export const GeneratePriceQuoteModal: React.FC<GeneratePriceQuoteModalProps> = ({
  quoteType,
  company,
  projectId,
  toggleQuoteModal,
  priceQuote,
  setProject,
  tasks,
}) => {
  const [quoteIsEditable, setQuoteIsEditable] = useState(true);
  const isPreliminaryQuote = quoteType === PriceQuoteType.preliminary;

  useEffect(() => {
    // if this is a new form then it is editable
    if (!priceQuote) return;

    // if this is a final price quote and the final quote task has any status (meaning it was shared) then it is not editable
    if (priceQuote?.attributes.quoteType === PriceQuoteType.final) {
      const finalQuoteTaskStatus = tasks?.find(
        (task) =>
          task.attributes.mondayColumnId ===
          TASK_COLUMN_IDS.FINAL_QUOTE_APPROVED
      )?.attributes.status;
      if (!finalQuoteTaskStatus) {
        setQuoteIsEditable(false);
      } else {
        setQuoteIsEditable(true);
      }
    }

    if (priceQuote?.attributes.quoteType === PriceQuoteType.preliminary) {
      const preliminaryQuoteTaskStatus = tasks?.find(
        (task) =>
          task.attributes.mondayColumnId ===
          TASK_COLUMN_IDS.PRELIM_QUOTE_APPROVED
      )?.attributes.status;
      if (
        preliminaryQuoteTaskStatus === PRICE_QUOTE_TASK_STATUSES.pending ||
        preliminaryQuoteTaskStatus === PRICE_QUOTE_TASK_STATUSES.done ||
        preliminaryQuoteTaskStatus === PRICE_QUOTE_TASK_STATUSES.waived
      ) {
        setQuoteIsEditable(false);
      } else {
        setQuoteIsEditable(true);
      }
    }
  }, [priceQuote, tasks]);

  return (
    <FullScreenModal
      handleClose={toggleQuoteModal}
      header={`${isPreliminaryQuote ? 'Preliminary' : 'Final'} Quote Generator`}
      subHeader={`Use the below form to create a ${
        isPreliminaryQuote ? 'preliminary' : 'final'
      } pricing quote.`}
    >
      <>
        {!quoteIsEditable && (
          <div className="mb-3 px-3 py-3 bg-yellow-95 text-peach-30 flex flex-row items-center gap-x-3">
            <div>
              <ExclamationTriangleIcon className="h-4 w-4 text-yellow-50" />
            </div>
            <div>
              <Typography font="inter" variant="h5" color="warning">
                This form is currently under review by the customer and
                therefore cannot be edited.
              </Typography>
            </div>
          </div>
        )}
      </>
      <PriceQuoteModal
        quoteType={quoteType}
        company={company}
        projectId={projectId}
        handleClose={toggleQuoteModal}
        priceQuote={priceQuote}
        setProject={setProject}
        tasks={tasks}
      />
    </FullScreenModal>
  );
};
