import * as Yup from 'yup';

export const TEAM_MEMBER_FIELD_NAMES = {
  givenName: 'givenName',
  familyName: 'familyName',
  email: 'email',
};

export const teamMemberValidationSchema = Yup.object().shape({
  [TEAM_MEMBER_FIELD_NAMES.givenName]: Yup.string().required(
    'First name is required'
  ),
  [TEAM_MEMBER_FIELD_NAMES.familyName]: Yup.string().required(
    'Last name is required'
  ),
  [TEAM_MEMBER_FIELD_NAMES.email]: Yup.string()
    .required('Email is required')
    .email('Invalid email'),
});
