// Libraries
import React from 'react';
import { LockClosedIcon, PencilSquareIcon } from '@heroicons/react/16/solid';
import { useFormikContext } from 'formik';
// Components
import { Button, TextField } from 'design-system';
// Utils
import { SectionProps } from './types';
// Constants
import { SECTIONS } from '../constants';

interface OrderQuantitySectionProps extends SectionProps {
  data: any;
  setActiveSection: React.Dispatch<React.SetStateAction<string | null>>;
}

export const OrderQuantitySection: React.FC<OrderQuantitySectionProps> = ({
  data,
  isActive,
  setActiveSection,
}) => {
  const { getFieldProps, values } = useFormikContext<any>();

  return (
    <div
      className={`grid grid-cols-10 items-center px-3 ${
        isActive ? 'py-2' : 'py-4'
      } border border-grey-90 bg-white $
         border-b-black`}
    >
      <span className="col-span-2 text-grey-50">{data.title}</span>
      {data.formikKeys.map((formikKey: string, index: number) => {
        return isActive ? (
          <div className="col-span-2 mr-6">
            <TextField
              {...getFieldProps(formikKey)}
              disabled={index === 0}
              height="h-11"
              type="number"
              trailingIcon={
                index === 0 && (
                  <LockClosedIcon className="h-5 w-5 text-grey-50" />
                )
              }
            />
          </div>
        ) : (
          <span
            className={`${data.isTopSection && 'font-semibold'} col-span-2`}
          >
            {values[formikKey]}
          </span>
        );
      })}
      <div className="col-start-10 flex items-center justify-end cursor-pointer">
        {isActive ? (
          <div className="flex gap-[5px]">
            <Button
              onClick={() => setActiveSection(null)}
              type="secondary"
              text="Cancel"
            />
            <Button type="primary" text="Save" />
          </div>
        ) : (
          <PencilSquareIcon
            onClick={() => setActiveSection(SECTIONS.ORDER_QTY)}
            className=" h-4 w-4 text-royal-50"
          />
        )}
      </div>
    </div>
  );
};
