// Libraries
import React, { useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Grid,
  MenuItem,
  TextField,
  Select,
  Chip,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { useFormikContext } from 'formik';

// Components
import { FormSection } from 'features/ui';

// Utils
import { ITheme } from 'styles/mui/themeV2';
import { IRawMaterialFormValues } from './raw-material-form.component';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(2),
    },
    select: {
      display: 'flex',
      alignItems: 'center',
      height: theme.typography.pxToRem(23),
      marginTop: theme.spacing(1),
    },
  })
);

const MARKETING_INFO = 'Marketing Information';
const MARKETING_INFO_SUBHEADER =
  'Marketing name, description, synonyms and origin';

const ORIGINS = [
  { label: 'Plant', value: 'Plant' },
  { label: 'Animal', value: 'Animal' },
  { label: 'Mineral', value: 'Mineral' },
  { label: 'Synthetic', value: 'Synthetic' },
];

export const MarketingInfoSection: React.FC = () => {
  const classes = useStyles();

  const {
    values: { marketingInfo },
    setFieldValue,
  } = useFormikContext<IRawMaterialFormValues>();

  const [isRevealed, setIsRevealed] = useState<boolean>(false);

  useEffect(() => {
    const {
      marketingName,
      marketingDescription,
      origin,
      synonyms,
    } = marketingInfo;

    const isMarketingSectionDirty =
      Boolean(marketingName) ||
      Boolean(marketingDescription) ||
      origin.length > 0 ||
      synonyms.length > 0;

    setIsRevealed(isMarketingSectionDirty);
  }, [marketingInfo]);

  const handleAddChip = (newItem: any) => {
    setFieldValue('marketingInfo.synonyms', [
      ...marketingInfo.synonyms,
      newItem,
    ]);
  };

  const handleDeleteChip = (_item: any, index: number) => {
    marketingInfo.synonyms.splice(index, 1);
    setFieldValue('marketingInfo.synonyms', [...marketingInfo.synonyms]);
  };

  const handleOriginChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target as HTMLSelectElement;

    setFieldValue('marketingInfo.origin', [
      ...((value as unknown) as string[]),
    ]);
  };

  return (
    <FormSection
      sectionHeader={MARKETING_INFO}
      sectionSubheader={MARKETING_INFO_SUBHEADER}
      isRevealed={isRevealed}
      setIsRevealed={setIsRevealed}
    >
      <Grid container item spacing={5} xs={12} className={classes.row}>
        <Grid xs={6} item>
          <TextField
            variant="outlined"
            label="Marketing name"
            onChange={(event) =>
              setFieldValue('marketingInfo.marketingName', event.target.value)
            }
            value={marketingInfo.marketingName}
            fullWidth
          />
        </Grid>
        <Grid xs={6} item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="origin-label">Origin</InputLabel>
            <Select
              labelId="origin-label"
              label="Origin"
              multiple
              fullWidth
              value={marketingInfo.origin}
              onChange={handleOriginChange}
              inputProps={{ className: classes.select }}
              renderValue={(selected) => (
                <div>
                  {(selected as string[]).map((origin: string) => (
                    <Chip key={origin} label={origin} />
                  ))}
                </div>
              )}
            >
              {ORIGINS.map((origin) => {
                return (
                  <MenuItem key={origin.value} value={origin.value}>
                    {origin.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} item style={{ marginBottom: 15 }}>
          <ChipInput
            fullWidth
            variant="outlined"
            helperText="Type a value and press enter to add it"
            label="Synonyms"
            value={marketingInfo.synonyms}
            onAdd={handleAddChip}
            onDelete={handleDeleteChip}
          />
        </Grid>
        <Grid xs={12} item>
          <TextField
            variant="outlined"
            label="Description"
            onChange={(event) =>
              setFieldValue(
                'marketingInfo.marketingDescription',
                event.target.value
              )
            }
            value={marketingInfo.marketingDescription}
            fullWidth
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
