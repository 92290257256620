// Libraries
import React, { useState } from 'react';
import {
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
// Assets
import LocalPharmacy from '@material-ui/icons/LocalPharmacy';
import LocalPharmacyOutlined from '@material-ui/icons/LocalPharmacyOutlined';
// Utils
import { ITheme } from 'styles/mui/themeV2';

interface MarkAPIButtonProps {
  selected?: boolean;
  [x: string]: any;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    default: {
      height: theme.spacing(8),
      width: theme.spacing(8),
      color: theme.palette.secondary.main,
      backgroundColor: (props: MarkAPIButtonProps) =>
        props.selected ? theme.palette.gray.dark : theme.palette.gray.light,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.main,
      },
    },
  })
);

// API === Active Pharmaceutical Ingredient
export const MarkAPIButton: React.FC<MarkAPIButtonProps> = (props) => {
  const { selected, ...rest } = props;
  const classes = useStyles(props);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const startHover = () => setIsHovering(true);
  const endHover = () => setIsHovering(false);

  return (
    <Tooltip
      title="Mark Active Pharmaceutical Ingredient"
      placement="bottom-start"
    >
      <IconButton
        className={classes.default}
        {...rest}
        onMouseEnter={startHover}
        onMouseLeave={endHover}
        style={
          props.selected
            ? { backgroundColor: '#333333' }
            : { backgroundColor: '#eaeaea' }
        }
      >
        {isHovering || selected ? (
          <LocalPharmacy style={{ width: '20', height: '20' }} />
        ) : (
          <LocalPharmacyOutlined
            style={{ color: '#333', width: '20', height: '20' }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};
