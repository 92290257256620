// Libraries
import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
// Components
import { DialogModal } from 'features/ui';
// Utils
import { getNoteCreatedOrModifiedBy } from './utils';
import { handleError } from 'features/formula/utils';
import { FormulaContext } from '../context';
import { useApi, useSnackbar } from 'hooks';
import { IApiResponse } from 'features/types';
// Constants
import * as Constants from 'features/constants';

interface NoteDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const NoteDialog: React.FC<NoteDialogProps> = ({
  open,
  handleClose,
}) => {
  const request = useApi();
  const { formula, setFormula } = useContext(FormulaContext);
  const { showSnackbar } = useSnackbar();

  const [noteInput, setNoteInput] = useState<string>(formula?.note || '');

  const handleNoteInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNoteInput(event.target.value);

  const handleSubmit = async () => {
    try {
      const response: IApiResponse = await request({
        resource: `/api/v1/formulas/${formula?.id}`,
        options: {
          settings: Constants.PATCH,
          scope: 'update:formula',
          body: {
            formula: {
              note: noteInput,
            },
          },
        },
      });

      if (response.data) {
        const { included } = response;
        const { relationships } = response.data;
        const {
          noteCreatedOn,
          noteLastModifiedOn,
          note,
        } = response.data.attributes;

        formula &&
          setFormula({
            ...formula,
            note,
            noteCreatedOn: noteCreatedOn ? new Date(noteCreatedOn) : undefined,
            noteCreatedBy: getNoteCreatedOrModifiedBy(
              relationships?.noteCreatedBy?.data?.id,
              included
            ),
            noteLastModifiedOn: noteLastModifiedOn
              ? new Date(noteLastModifiedOn)
              : undefined,
            noteLastModifiedBy: getNoteCreatedOrModifiedBy(
              relationships?.noteLastModifiedBy?.data?.id,
              included
            ),
          });
        showSnackbar('Formula note added successfully', 'success');
      } else {
        handleError(response);
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error');
      console.error(error);
      Rollbar.error(error);
    } finally {
      handleClose();
    }
  };

  return (
    <DialogModal
      open={open}
      title="Formula notes"
      onConfirm={handleSubmit}
      handleClose={handleClose}
    >
      <TextField
        multiline
        fullWidth
        variant="outlined"
        onChange={handleNoteInputChange}
        rows={5}
        value={noteInput}
        placeholder="Enter your notes here..."
      />
    </DialogModal>
  );
};
