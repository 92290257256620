// Libraries
import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
// Components
import { FormSection } from 'features/ui';
import { useFormikContext } from 'formik';
import { InciFormValues } from './types';

// Constants
const FUNCTIONS_TITLE = 'Functions';
const FUNCTION_OPTIONS = [
  'Abrasive',
  'Absorbent',
  'Adhesive',
  'Anti-Seborrheic',
  'Anti-Sebum',
  'Anticaking',
  'Anticorrosive',
  'Antifoaming',
  'Antimicrobial',
  'Antioxidant',
  'Antiperspirant',
  'Antiplaque',
  'Antistatic',
  'Astringent',
  'Binding',
  'Bleaching',
  'Buffering',
  'Bulking',
  'Chelating',
  'Cleansing',
  'Colorant',
  'Denaturant',
  'Deodorant',
  'Depilatory',
  'Detangling',
  'Dispersing Non-Surfactant',
  'Emulsion Stabilising',
  'Epilating',
  'Exfoliating',
  'Eyelash Conditioning',
  'Film Forming',
  'Flavouring',
  'Foaming',
  'Fragrance',
  'Gel Forming',
  'Hair Conditioning',
  'Hair Dyeing',
  'Hair Fixing',
  'Hair Waving or Straightening',
  'Humectant',
  'Keratolytic',
  'Light Stabilizer',
  'Lytic',
  'Moisturising',
  'Nail Conditioning',
  'Nail Sculpting',
  'Not Reported',
  'Occlusive',
  'Opacifying',
  'Oral Care',
  'Oxidising',
  'Pearlescent',
  'Perfuming',
  'Ph Adjusters',
  'Plasticiser',
  'Preservative',
  'Propellant',
  'Reducing',
  'Refatting',
  'Refreshing',
  'Skin Conditioning',
  'Skin Conditioning - Emollient',
  'Skin Conditioning - Humectant',
  'Skin Conditioning - Miscellaneous',
  'Skin Conditioning - Occlusive',
  'Skin Protecting',
  'Slip Modifier',
  'Smoothing',
  'Solvent',
  'Soothing',
  'Surface Modifier',
  'Surfactant',
  'Surfactant - Cleansing',
  'Surfactant - Dispersing',
  'Surfactant - Emulsifying',
  'Surfactant - Foam boosting',
  'Surfactant - Hydrotrope',
  'Surfactant - Solubilizing',
  'Tanning',
  'Tonic',
  'UV Absorber',
  'UV Filter',
  'Viscosity Controlling',
];

export const FunctionsSection: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<InciFormValues>();

  return (
    <FormSection
      sectionHeader={FUNCTIONS_TITLE}
      isRevealed={true}
      setIsRevealed={() => null}
    >
      <Grid container item spacing={5} xs={12}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="functions-multi-select"
            options={FUNCTION_OPTIONS}
            disableCloseOnSelect
            disablePortal
            style={{ width: '100%' }}
            value={values.functions}
            onChange={(_event, value) => setFieldValue('functions', value)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Functions" />
            )}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
