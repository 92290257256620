// Libraries
import React from 'react';
import {
  Button,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
// Utils
import { ITheme } from 'styles/mui/themeV2';
// Constants
import {
  FORMULAS,
  INITIAL_TABLES_TO_DISPLAY,
  PROJECTS,
  TABLES_FILTER_DELIMITER,
} from './constants';

interface ISearchFilters {
  formulaResultsCount: number;
  projectResultsCount: number;
  tablesToDisplay: string[];
  setTablesToDisplay: React.Dispatch<React.SetStateAction<string[]>>;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    filterButton: {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.secondary.dark,
      '&:hover': {
        color: theme.palette.secondary.main,
      },
      padding: '16px 20px',
    },
    selectedButton: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.dark,
      padding: '16px 20px',
    },
  })
);

export const PrimarySearchFilters: React.FC<ISearchFilters> = ({
  formulaResultsCount,
  projectResultsCount,
  tablesToDisplay,
  setTablesToDisplay,
}) => {
  const classes = useStyles();

  const isButtonSelected = (tableName: string) => {
    return (
      tablesToDisplay.includes(tableName) &&
      tablesToDisplay.length <= TABLES_FILTER_DELIMITER
    );
  };

  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item>
        <Button
          className={`${classes.filterButton} ${
            isButtonSelected(FORMULAS) && classes.selectedButton
          }`}
          onClick={() => setTablesToDisplay([FORMULAS])}
          endIcon={
            isButtonSelected(FORMULAS) ? (
              <ClearIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setTablesToDisplay(INITIAL_TABLES_TO_DISPLAY);
                }}
              />
            ) : null
          }
        >
          <Typography variant="body1">{`Formulas (${formulaResultsCount})`}</Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={`${classes.filterButton} ${
            isButtonSelected(PROJECTS) && classes.selectedButton
          }`}
          onClick={() => setTablesToDisplay([PROJECTS])}
          endIcon={
            isButtonSelected(PROJECTS) ? (
              <ClearIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setTablesToDisplay(INITIAL_TABLES_TO_DISPLAY);
                }}
              />
            ) : null
          }
        >
          <Typography variant="body1">{`Projects (${projectResultsCount})`}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
