// Libraries
import React, { useState, useEffect } from 'react';
// Components
import { MobileContext } from './mobile.context';

interface MobileContextProviderProps {
  children:
    | React.ReactNode[]
    | React.ReactNode
    | (({
        isMobile,
      }: {
        isMobile: boolean;
      }) => React.ReactNode[] | React.ReactNode);
}

export const MobileContextProvider: React.FC<MobileContextProviderProps> = ({
  children,
}) => {
  const [isMobile, setIsMobile] = useState(
    document.documentElement.clientWidth < 640
  );

  const handleResize = () => {
    setIsMobile(document.documentElement.clientWidth < 640);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <MobileContext.Provider value={{ isMobile }}>
      {typeof children === 'function' ? children({ isMobile }) : children}
    </MobileContext.Provider>
  );
};
