// Libraries
import { useEffect, useContext } from 'react';
import _ from 'lodash';
// Utils
import { RtlIconMapContext } from 'context';
import { BRIEF_FORM_KEYS } from '../brief.constants';
import { determineRtlFormulasAndModulesFromProductCategory } from './rtl-logic-engine.component';

interface UseRtlIconMapProps {
  values: any;
  moduleKey: string;
}

export const useRtlIconMap = ({ values, moduleKey }: UseRtlIconMapProps) => {
  const { rtlIconMap, setRtlIconMap } = useContext(RtlIconMapContext);

  useEffect(() => {
    const {
      rtlFormulas,
      rtlModules,
    } = determineRtlFormulasAndModulesFromProductCategory(values);

    const currentModuleKeyIndex = rtlModules.indexOf(moduleKey as any);
    const prevModuleKeys = rtlModules.slice(0, currentModuleKeyIndex);

    const availableOptions = rtlFormulas
      .filter((rtlFormula) => {
        if (moduleKey === BRIEF_FORM_KEYS.PRODUCT_TYPE) return true;

        return prevModuleKeys.every((prevModuleKey) => {
          // @ts-ignore
          return typeof values[prevModuleKey] === 'object'
            ? // @ts-ignore
              rtlFormula.productProperties[prevModuleKey] ===
                values[prevModuleKey].value
            : // @ts-ignore
              rtlFormula.productProperties[prevModuleKey] ===
                values[prevModuleKey];
        });
      })
      .map((rtlFormula) => {
        // @ts-ignore
        return rtlFormula.productProperties[moduleKey];
      });

    const availableOptionsMap = _.keyBy(availableOptions);
    setRtlIconMap((prev: any) => ({
      ...prev,
      [moduleKey]: availableOptionsMap,
    }));
  }, [values, moduleKey, setRtlIconMap]);
  return rtlIconMap;
};
