// Libraries
import React from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const PLACEHOLDER_TEXT = 'Search projects and formulas';

interface SearchBarProps {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void;
  currentFormulaResultsPage: number;
  currentProjectResultsPage: number;
  setCurrentFormulaResultsPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentProjectResultsPage: React.Dispatch<React.SetStateAction<number>>;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  setCurrentProjectResultsPage,
  setCurrentFormulaResultsPage,
  currentFormulaResultsPage,
  currentProjectResultsPage,
  handleSearch,
  query,
  setQuery,
}) => {
  const shouldSearchResetPageNumberToZero =
    currentFormulaResultsPage !== 0 || currentProjectResultsPage !== 0;

  const resetSearchResultPageNumbers = () => {
    setCurrentFormulaResultsPage(0);
    setCurrentProjectResultsPage(0);
  };

  const determineResultsPageNumberAndPerformSearch = () => {
    if (shouldSearchResetPageNumberToZero) {
      // A change of page number in the results table will cause a search to be performed as a side effect
      resetSearchResultPageNumbers();
    } else {
      handleSearch();
    }
  };

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <Button onClick={determineResultsPageNumberAndPerformSearch}>
            Search
          </Button>
        ),
      }}
      fullWidth
      onChange={(e) => setQuery(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          determineResultsPageNumberAndPerformSearch();
        }
      }}
      placeholder={PLACEHOLDER_TEXT}
      value={query}
      variant="outlined"
    />
  );
};
