// Libraries
import React from 'react';
// Components
import { Divider } from 'design-system';
import { FormulaSpecifics, IngredientNotes, Fragrance } from '../form-modules';
// Utils

export const IngredientsAndFormula: React.FC = () => {
  return (
    <>
      <FormulaSpecifics />
      <Divider />
      <IngredientNotes />
      <Divider />
      <Fragrance />
    </>
  );
};
