//Libraries
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  FormControl,
  InputLabel,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  TextField,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// Components
import { Warnings } from 'features/ui';
// Utils
import { useApi as useApiV1 } from 'hooks';
import { useApi, BatchAttributes, IApiData } from 'api';
import { useSnackbar, useAnalytics } from 'hooks';
import { ITheme } from 'styles/mui/themeV2';
// Constants
import * as Constants from 'features/constants';
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(2, 8, 8, 8),
    },
    dialogTitle: {
      padding: theme.spacing(8, 8, 0, 8),
      marginBottom: theme.spacing(2),
    },
    extraInputsContainer: { marginBottom: theme.spacing(4) },
    selectContainer: { marginBottom: theme.spacing(8) },
    root: {
      minHeight: '18rem',
    },
    radioButtonContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    radioButtonLabel: {
      marginRight: theme.spacing(8),
      color: theme.palette.secondary.dark,
    },
    form: { height: '100%' },
    inputsContainer: { height: '100%' },
    inputField: {
      marginBottom: theme.spacing(1),
    },
  })
);

type Input = 'Switch' | 'TextField' | 'Select';

type SelectOption = {
  displayValue: string;
  value: string;
};

interface BatchOption extends SelectOption {
  batchQuantity: string;
}

const INPUT_TYPES: { [key: string]: Input } = {
  switch: 'Switch',
  select: 'Select',
  textField: 'TextField',
};

const FORMAT_TYPES = {
  pdf: 'pdf',
  xlsx: 'xlsx',
};

const REPORTS = [
  { value: 'USCosmetics', label: 'US Cosmetics' },
  { value: 'USDrugs', label: 'US Drugs' },
  { value: 'PrintableFormula', label: 'Printable Formula' },
  { value: 'FormulaPricing', label: 'Formula Pricing' },
  { value: 'USCOIDrugs', label: 'US COI Drugs' },
  { value: 'USCOICosmetics', label: 'US COI Cosmetics' },
  { value: 'TradeNameCOI', label: 'Trade Name COI' },
  { value: 'EUCosmetics', label: 'EU Cosmetics' },
  { value: 'EUCOICosmetics', label: 'EU COI Cosmetics' },
  { value: 'Compliance', label: 'Compliance Report' },
];

const PRINTABLE_FORMULA = 'PrintableFormula';
const BATCH = 'batch';

const WARNING_RESOURCE = 'formula';
const getWarningsEndPoint = (formulaUuid: string) => `${formulaUuid}/alerts`;

interface GenerateReportModalProps {
  open: boolean;
  handleClose: () => void;
  formulaUuid: string;
  formulaName: string;
}

interface IReport {
  reportType: Maybe<string>;
  formatType: Maybe<string>;
}

interface ReportInput {
  params?: string;
  inputLabel?: string;
  inputType?: Input;
  inputOptions?: Array<SelectOption> | Array<BatchOption>;
  value: boolean | string;
}
interface ReportFormats {
  pdf: boolean;
  xlsx: boolean;
}

interface ReportTypes {
  USCosmetics: ReportFormats & { inputs: { [key: string]: ReportInput } };
  USDrugs: ReportFormats & { inputs: { [key: string]: ReportInput } };
  PrintableFormula: ReportFormats & { inputs: { [key: string]: ReportInput } };
  USCOIDrugs: ReportFormats & { inputs: { [key: string]: ReportInput } };
  USCOICosmetics: ReportFormats;
  TradeNameCOI: ReportFormats;
  EUCosmetics: ReportFormats;
  EUCOICosmetics: ReportFormats;
  Compliance: ReportFormats;
  [X: string]: any;
}

const ROUNDING_OPTIONS = [
  // values here correspond to values that are used in the
  // ruby round method
  {
    displayValue: 'No Rounding - 1.12345%',
    value: '5',
  },
  {
    displayValue: 'Ones - 1%',
    value: '0',
  },
  {
    displayValue: 'Tenths - 1.1%',
    value: '1',
  },
  {
    displayValue: 'Hundredths - 1.12%',
    value: '2',
  },
  {
    displayValue: 'Thousandths - 1.123%',
    value: '3',
  },
  {
    displayValue: 'Ten Thousandths - 1.1234%',
    value: '4',
  },
];

export const GenerateReportModal = ({
  open,
  handleClose,
  formulaUuid,
  formulaName,
}: GenerateReportModalProps) => {
  const request = useApiV1();
  const { getFormulaBatches, getFormulaWarnings } = useApi();
  const analytics = useAnalytics();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false);

  const [isPdfEnabled, setIsPdfEnabled] = useState<boolean>(false);
  const [isXlsxEnabled, setIsXlsxEnabled] = useState<boolean>(false);

  const [report, setReport] = useState<
    IReport & Maybe<{ inputs: { [key: string]: ReportInput } }>
  >({
    reportType: '',
    formatType: '',
    inputs: {},
  });

  useEffect(() => {
    return () => {
      // Reset the report modal back to its initial state
      setReport({
        reportType: '',
        formatType: '',
        inputs: {},
      });
    };
  }, [open]);

  const [isLoadingBatches, setIsLoadingBatches] = useState<boolean>(false);
  const [batchOptions, setBatchOptions] = useState<BatchOption[]>([]);

  const FORMULA_HAS_BATCHES = batchOptions.length > 0;

  useEffect(() => {
    if (report.reportType === PRINTABLE_FORMULA) {
      setIsLoadingBatches(true);
      getFormulaBatches({
        urlParams: `/${formulaUuid}/batches`,
        handleSuccess: (resp: any) => {
          const results = resp.data.map(
            (batch: IApiData<BatchAttributes>, idx: number) => {
              return {
                displayValue: `Batch ${idx + 1} - ${
                  batch.attributes.lotNumber
                }`,
                value: batch.id,
                batchQuantity: batch.attributes.quantity,
              };
            }
          );
          setBatchOptions(results);
        },
        handleFinally: () => setIsLoadingBatches(false),
      });
    }
  }, [report.reportType, formulaUuid, getFormulaBatches]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.reportGeneration,
      eventAction: `${AnalyticConstants.EVENT_ACTIONS.generate} ${report.reportType} ${report.formatType}`,
      eventLabel: `${formulaUuid} ${formulaName}`,
    });

    event.preventDefault();
    generateReport();
  };

  const reports: ReportTypes = {
    USCosmetics: {
      pdf: true,
      xlsx: true,
      inputs: {
        OnePercentDelineator: {
          inputLabel: 'Display < 1% delineator',
          inputType: INPUT_TYPES.switch,
          params: 'displayLessThanOnePercentLine',
          value: false,
        },
      },
    },
    USDrugs: {
      pdf: true,
      xlsx: true,
      inputs: {
        activeIngredients: {
          inputLabel: 'Active Ingredients',
          inputType: INPUT_TYPES.select,
          inputOptions: ROUNDING_OPTIONS,
          params: 'roundBy',
          value: '',
        },
      },
    },
    PrintableFormula: {
      pdf: false,
      xlsx: true,
      inputs: {
        batch: {
          inputLabel: 'Specs to Print',
          inputType: INPUT_TYPES.select,
          inputOptions: batchOptions,
          params: `batchUuid`,
          value: '',
        },
        batchQuantity: {
          inputLabel: 'Batch Qty.',
          inputType: INPUT_TYPES.textField,
          params: `batchQty`,
          value: '',
        },
      },
    },
    FormulaPricing: {
      pdf: false,
      xlsx: true,
      inputs: {
        showPhases: {
          inputLabel: 'Show Phases',
          inputType: INPUT_TYPES.switch,
          params: 'showPhases',
          value: true,
        },
      },
    },
    USCOIDrugs: {
      pdf: true,
      xlsx: true,
      inputs: {
        activeIngredients: {
          inputLabel: 'Active Ingredients',
          inputType: INPUT_TYPES.select,
          inputOptions: ROUNDING_OPTIONS,
          params: 'roundBy',
          value: '',
        },
      },
    },
    USCOICosmetics: {
      pdf: true,
      xlsx: true,
    },
    TradeNameCOI: {
      pdf: false,
      xlsx: true,
    },
    EUCosmetics: {
      pdf: true,
      xlsx: true,
    },
    EUCOICosmetics: {
      pdf: true,
      xlsx: true,
    },
    Compliance: {
      pdf: false,
      xlsx: true,
    },
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    event.persist();

    if (event.target.name === 'reportType') {
      const currentReport = reports[event.target.value];
      setIsPdfEnabled(currentReport.pdf);
      setIsXlsxEnabled(currentReport.xlsx);
      setReport({
        ...currentReport,
        formatType: currentReport.pdf ? FORMAT_TYPES.pdf : FORMAT_TYPES.xlsx,
      });
    }
    setReport((report) => {
      return {
        ...report,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<any>, k: string) => {
    if (k === BATCH) {
      setReport({
        ...report,
        inputs: {
          ...report.inputs,
          [k]: {
            ...report.inputs[k],
            value: e.target.value,
          },
          batchQuantity: {
            ...report.inputs.batchQuantity,
            value: batchOptions.filter(
              (batchOption) => batchOption.value === e.target.value
            )[0].batchQuantity,
          },
        },
      });
    } else {
      setReport({
        ...report,
        inputs: {
          ...report.inputs,
          [k]: {
            ...report.inputs[k],
            value: e.target.value as string,
          },
        },
      });
    }
  };

  const buildUrlParameters = () => {
    return Object.values(report.inputs || {})
      .map((v) => {
        return v.value && v.params ? `&${v.params}=${v.value}` : '';
      })
      .join('');
  };

  const generateReport = async () => {
    try {
      setIsReportGenerating(true);
      const blob = await request({
        resource: `/api/v1/formulas/${formulaUuid}.${
          report.formatType
        }?reportType=${report.reportType}${buildUrlParameters()}`,
        options: {
          settings: Constants.GET,
        },
      });

      if (blob.size) {
        const blobData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobData;
        link.setAttribute('target', '_blank');
        link.download = `${formulaName} ${
          report.reportType
        } ${new Date().toISOString().replace(/T.*/, '')}.${report.formatType}`;
        link.click();

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobData);
        }, 100);
        showSnackbar('Report generated successfully', 'success');
      } else {
        throw new Error('The report failed to generate.');
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error');
      Rollbar.error(error);
    } finally {
      setIsReportGenerating(false);
    }
  };

  console.log({
    result: buildUrlParameters(),
  });

  const renderOptionalInputFromReportType = () => {
    return Object.entries(report.inputs || {}).map(([k, v]) => {
      switch (v.inputType) {
        case INPUT_TYPES.switch:
          return (
            <FormControlLabel
              control={
                <Switch
                  className={classes.inputField}
                  color="primary"
                  name={v.inputLabel}
                  checked={Boolean(v.value)}
                  onChange={() =>
                    setReport({
                      ...report,
                      inputs: {
                        ...report.inputs,
                        [k]: {
                          ...report.inputs[k],
                          value: !report.inputs[k].value,
                        },
                      },
                    })
                  }
                />
              }
              label={v.inputLabel}
              labelPlacement="end"
            />
          );
        case INPUT_TYPES.textField:
          return (
            <TextField
              className={classes.inputField}
              name={v.inputLabel}
              variant="outlined"
              fullWidth
              label={v.inputLabel}
              id="reportInput"
              value={v.value || ''}
              onChange={(e) =>
                setReport({
                  ...report,
                  inputs: {
                    ...report.inputs,
                    [k]: {
                      ...report.inputs[k],
                      value: e.target.value,
                    },
                  },
                })
              }
            />
          );
        case INPUT_TYPES.select:
          if (report.reportType === PRINTABLE_FORMULA && isLoadingBatches) {
            return <CircularProgress />;
          } else if (
            report.reportType === PRINTABLE_FORMULA &&
            !FORMULA_HAS_BATCHES
          ) {
            return (
              <Alert severity="warning">
                'This formula has no batches assigned to it.'
              </Alert>
            );
          } else {
            return (
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{v.inputLabel}</InputLabel>
                <Select
                  className={classes.inputField}
                  name={v.inputLabel}
                  variant="outlined"
                  fullWidth
                  label={v.inputLabel}
                  id="reportInput"
                  value={v?.value || (v.inputOptions as any)?.[0]?.value || ''}
                  onChange={(e) => handleSelectChange(e, k)}
                >
                  {report.reportType !== PRINTABLE_FORMULA
                    ? v.inputOptions?.map(({ displayValue, value }, idx) => (
                        <MenuItem
                          key={idx}
                          value={value as string}
                          selected={idx === 0}
                        >
                          {displayValue}
                        </MenuItem>
                      ))
                    : batchOptions?.map(({ displayValue, value }, idx) => (
                        <MenuItem key={idx} value={value} selected={idx === 0}>
                          {displayValue}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            );
          }
        default:
          return null;
      }
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        classes: { root: classes.root },
      }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h3">Generate report</Typography>
      </DialogTitle>
      <Warnings
        getWarnings={getFormulaWarnings}
        resource={WARNING_RESOURCE}
        warningsEndPoint={getWarningsEndPoint(formulaUuid)}
      />
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container className={classes.inputsContainer}>
            <Grid item xs={12} className={classes.selectContainer}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Report Type</InputLabel>
                <Select
                  name="reportType"
                  variant="outlined"
                  fullWidth
                  required
                  label="Report Type"
                  onChange={handleChange}
                  value={report.reportType}
                >
                  {REPORTS.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {report.reportType && (
              <Grid
                container
                item
                xs={12}
                className={classes.extraInputsContainer}
              >
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    className={classes.radioButtonContainer}
                  >
                    <FormLabel className={classes.radioButtonLabel}>
                      <Typography variant="body1">
                        Select report format:
                      </Typography>
                    </FormLabel>
                    <RadioGroup row name="formatType" onChange={handleChange}>
                      <FormControlLabel
                        control={
                          <Radio
                            value="pdf"
                            checked={report.formatType === FORMAT_TYPES.pdf}
                            disabled={!isPdfEnabled}
                          />
                        }
                        label="PDF"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            value="xlsx"
                            checked={report.formatType === FORMAT_TYPES.xlsx}
                            disabled={!isXlsxEnabled}
                          />
                        }
                        label="XLSX"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {renderOptionalInputFromReportType()}
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              spacing={2}
              justifyContent="flex-end"
              alignItems="flex-end"
              xs={12}
            >
              {isReportGenerating && <CircularProgress />}
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!report.reportType || isReportGenerating}
                  type="submit"
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  Generate
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
