//Libraries
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
//Assets
import PmLogo from 'img/pm-logo-blue.svg';
//Utils
import { ROUTES } from 'features/navigation';
import { useMobile } from 'hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#e1eaf8',
    },
    rootContainer: {
      paddingTop: 17,
      paddingBottom: 17,
      borderTop: '1px solid #c4c4c4',
      alignItems: 'center',
      justifyContent: 'right',
    },
    infoContainer: {
      marginRight: 12,
    },
    links: {
      textDecoration: 'underline',
    },
    fonts: {
      fontFamily: 'Inter Regular',
      fontSize: 12,
    },
    logoContainer: {
      marginLeft: 12,
      marginRight: 24,
    },
    logo: {
      height: 28,
    },
    mobileRootContainer: {
      paddingTop: 12,
      paddingBottom: 12,
      borderTop: '1px solid #c4c4c4',
      alignItems: 'center',
      justifyContent: 'right',
    },
    mobileTextContainer: {
      textAlign: 'right',
      marginBottom: 12,
    },
    mobileLogoContainer: {
      marginLeft: 24,
      marginRight: 24,
    },
  })
);

// Any routes here will not display the footer
const excludeRoutes = [
  ROUTES.SIGNIN.route,
  ROUTES.CREATE_BRIEF.route,
  ROUTES.BRIEF_SUBMISSION_SUCCESS.route,
  ROUTES.ONBOARDING.route,
];

export const Footer = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { isMobile } = useMobile();

  if (excludeRoutes.includes(pathname)) {
    return null;
  }

  const displayDesktop = () => {
    return (
      <Grid container className={classes.rootContainer}>
        <Grid item className={classes.infoContainer}>
          <Typography className={classes.fonts}>
            Help, bugs, & feedback:{' '}
            {
              <a
                href="mailto:support@primematterlabs.com"
                className={classes.links}
              >
                support@primematterlabs.com
              </a>
            }
          </Typography>
        </Grid>
        <Grid item className={classes.infoContainer}>
          <Typography className={classes.fonts}>
            © PML 2024 |{' '}
            {
              <a href={ROUTES.TERMS_OF_USE.route} className={classes.links}>
                Terms of Use
              </a>
            }{' '}
            |{' '}
            <a href={ROUTES.PRIVACY_POLICY.route} className={classes.links}>
              Privacy Policy
            </a>{' '}
            |{' '}
            <a href={ROUTES.REGULATORY_INFO.route} className={classes.links}>
              Regulatory
            </a>
          </Typography>
        </Grid>
        <Grid item className={classes.logoContainer}>
          <img
            src={PmLogo}
            alt="Prime Matter Lockup"
            className={classes.logo}
          />
        </Grid>
      </Grid>
    );
  };

  const displayMobile = () => {
    return (
      <Grid container className={classes.mobileRootContainer}>
        <Grid item>
          <Grid className={classes.mobileTextContainer}>
            <Typography className={classes.fonts}>
              Help, bugs, & feedback:
            </Typography>
          </Grid>
          <Grid className={classes.mobileTextContainer}>
            <Typography className={classes.fonts}>
              <a
                href="mailto:support@primematterlabs.com"
                className={classes.links}
              >
                support@primematterlabs.com
              </a>
            </Typography>
          </Grid>
          <Grid className={classes.mobileTextContainer}>
            <Typography className={classes.fonts}>
              © PML 2024 |{' '}
              <a href={ROUTES.TERMS_OF_USE.route} className={classes.links}>
                Terms of Use
              </a>
              |{' '}
              <a href={ROUTES.PRIVACY_POLICY.route} className={classes.links}>
                Privacy Policy
              </a>
              |{' '}
              <a href={ROUTES.REGULATORY_INFO.route} className={classes.links}>
                Regulatory
              </a>
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container className={classes.mobileLogoContainer}>
            <img
              src={PmLogo}
              alt="Prime Matter Lockup"
              className={classes.logo}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return isMobile ? displayMobile() : displayDesktop();
};
