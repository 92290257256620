// Libraries
import React from 'react';
// Components
import { Typography } from 'design-system';

interface InternalBriefPageProps {
  page: { component: React.ReactNode | React.ReactNode[]; title: string };
}

export const InternalBriefPage: React.FC<InternalBriefPageProps> = ({
  page,
}) => {
  return (
    <div className="w-full flex flex-col gap-12 p-3 pl-6 pr-6 md:p-9 md:pb-[200px] lg:p-12 lg:pb-[200px] border border-b-0 border-grey-90 rounded-tr ">
      <Typography
        variant="h6"
        font="mono"
        className="text-grey-50 font-mono text-xs uppercase"
      >
        {page.title}
      </Typography>
      {page.component}
    </div>
  );
};
