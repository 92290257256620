// Libraries
import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  createStyles,
  Chip,
  FormControlLabel,
  Grid,
  Radio,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
// Utils
import { ITableColumn } from 'features/types';
import { Price } from 'features/types';
import { ITheme } from 'styles/mui/themeV2';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    headerRow: {
      top: '65px',
      zIndex: 100,
    },
    firstRow: {
      '& td': {
        borderBottom: '2px solid black',
        '&:first-child': {
          borderLeft: `2px solid black`,
        },
        '&:last-child': {
          borderRight: `2px solid black`,
        },
      },
    },
    newChip: {
      backgroundColor: theme.palette.green.dark,
      color: theme.palette.secondary.main,
    },
    newerPriceRow: {
      backgroundColor: theme.palette.green.light,
      '& td': {
        borderColor: theme.palette.green.dark,
        borderBottomWidth: '2px',
        '&:first-child': {
          borderLeft: `2px solid ${theme.palette.green.dark}`,
        },
        '&:last-child': {
          borderRight: `2px solid ${theme.palette.green.dark}`,
        },
      },
    },
    receivedOn: {
      color: theme.palette.gray.main,
    },
    table: {
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.gray.light}`,
      borderRadius: '0.25rem',
      marginTop: theme.spacing(8),
    },
    tableCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '0',
    },
    tableHeader: {
      '&:first-child': {
        borderTopLeftRadius: '0.25rem',
      },
      '&:last-child': {
        borderTopRightRadius: '0.25rem',
      },
      backgroundColor: theme.palette.secondary.dark,
    },
  })
);

const TABLE_HEADERS: ITableColumn[] = [
  {
    headerText: '',
    columnWidth: '5%',
    align: 'left',
  },
  {
    headerText: 'Supplier',
    columnWidth: '19%',
    align: 'left',
  },
  {
    headerText: 'Manufacturer',
    columnWidth: '20%',
    align: 'left',
  },
  {
    headerText: 'MOQ',
    columnWidth: '9%',
    align: 'left',
  },
  {
    headerText: 'Lead Time',
    columnWidth: '12%',
    align: 'left',
  },
  {
    headerText: 'Price',
    columnWidth: '21%',
    align: 'right',
  },
  {
    headerText: 'Unit',
    columnWidth: '5%',
    align: 'left',
  },
  {
    headerText: '',
    columnWidth: '9%',
    align: 'left',
  },
];

interface ISelectPricingTable {
  currentPriceId: string;
  selectedPriceId: string;
  prices: Price[];
  setSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPriceId: React.Dispatch<React.SetStateAction<string>>;
}

export const SelectPricingTable: React.FC<ISelectPricingTable> = ({
  currentPriceId,
  prices,
  setSubmitDisabled,
  selectedPriceId,
  setSelectedPriceId,
}) => {
  const classes = useStyles();

  const currentPrice = prices.find((price) => currentPriceId === price.id);

  const pricesSortedAndFiltered = () => {
    return (
      prices
        .sort((a, b) => {
          // Move the currently selected price to the top of the list
          if (a.id === currentPrice?.id) return -1;
          if (b.id === currentPrice?.id) return 1;

          // Sort the rest of the prices by the receivedAt date
          return (a.receivedAt &&
            b.receivedAt &&
            new Date(a?.receivedAt) > new Date(b?.receivedAt)) ||
            !b.receivedAt
            ? -1
            : 1;
        })
        // Filter the sorted prices to remove any duplicates, except the currently selected price
        .filter((price, index, self) => {
          return (
            price.id === currentPrice?.id ||
            index ===
              self.findIndex(
                (t) =>
                  t?.supplier?.name === price?.supplier?.name &&
                  t?.manufacturer?.name === price?.manufacturer?.name &&
                  t.moq === price.moq &&
                  t.unit === price.unit &&
                  t.cost.toFixed(2) === price.cost.toFixed(2)
              )
          );
        })
    );
  };

  const priceIsNewerThanCurrentPrice = (price: Price) => {
    if (!currentPrice?.receivedAt && price?.receivedAt) {
      return true;
    }
    return (
      price?.receivedAt &&
      currentPrice?.receivedAt &&
      price?.receivedAt > currentPrice?.receivedAt
    );
  };

  const handleChange = (priceId: string) => {
    setSelectedPriceId(priceId);
    if (priceId !== currentPriceId) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  return (
    <TableContainer style={{ maxHeight: 300 }}>
      <Table className={classes.table} stickyHeader>
        <TableHead className={classes.headerRow}>
          <TableRow className={classes.headerRow}>
            {TABLE_HEADERS.map((header, index) => {
              return (
                <TableCell
                  className={classes.tableHeader}
                  key={`${header}-${index}`}
                  align={header.align ? header.align : 'left'}
                  style={{
                    width: header.columnWidth,
                    color: '#ffffff',
                  }}
                >
                  <Typography variant="h4">{header.headerText}</Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {pricesSortedAndFiltered().map((price, index) => {
            const newerPrice = priceIsNewerThanCurrentPrice(price);
            return (
              <TableRow
                key={index}
                className={clsx({
                  [classes.newerPriceRow]: newerPrice,
                  [classes.firstRow]: index === 0,
                })}
              >
                <Tooltip title={price?.supplier?.name || ''}>
                  <TableCell className={classes.tableCell}>
                    <FormControlLabel
                      value={price.id}
                      control={
                        <Radio
                          checked={selectedPriceId === price.id}
                          onChange={() => handleChange(price.id)}
                        />
                      }
                      label=""
                    />
                  </TableCell>
                </Tooltip>
                <Tooltip title={price?.supplier?.name || ''}>
                  <TableCell className={classes.tableCell}>
                    {price?.supplier?.name}
                  </TableCell>
                </Tooltip>
                <Tooltip title={price?.manufacturer?.name || ''}>
                  <TableCell className={classes.tableCell}>
                    {price?.manufacturer?.name}
                  </TableCell>
                </Tooltip>
                <Tooltip title={price?.moq || ''}>
                  <TableCell className={classes.tableCell}>
                    {price?.moq}
                  </TableCell>
                </Tooltip>
                <Tooltip
                  title={
                    price?.leadTimeNumber
                      ? `${price?.leadTimeNumber} ${price?.leadTimeUnits}`
                      : ''
                  }
                >
                  <TableCell className={classes.tableCell}>
                    {price?.leadTimeNumber} {price?.leadTimeUnits}
                  </TableCell>
                </Tooltip>
                <TableCell className={classes.tableCell}>
                  <Grid container alignItems="flex-end" direction="column">
                    <Grid item>
                      {price.cost
                        ? `$${price.cost.toFixed(2)}`
                        : 'Price not available'}
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.receivedOn}
                        variant="caption"
                      >
                        Received on: {price?.receivedAt}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className={`${classes.tableCell}`}>
                  <Grid container>
                    <Grid item>{price.unit}</Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  {newerPrice && (
                    <Chip
                      className={classes.newChip}
                      label={<Typography variant="body2">New</Typography>}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
