// Libraries
import React from 'react';
import { ArrowsRightLeftIcon } from '@heroicons/react/16/solid';
import { useFormikContext } from 'formik';
// Components
import { TextField } from 'design-system';
// Utils
import { USDollar } from 'features/utils';
// Constants
import { WORKSHEET_FORM_KEYS } from '../../constants';

interface IngredientsTableProps {
  formulaCosts: any;
}

export const IngredientsTable: React.FC<IngredientsTableProps> = ({
  formulaCosts,
}) => {
  const { getFieldProps, values } = useFormikContext<any>();
  return (
    <>
      <div className="grid grid-cols-[14%_22%_14%_16%_18%_16%] bg-grey-95 font-mono text-xs uppercase">
        <div className="px-3 py-[18px] border border-grey-90 ">RM Code</div>
        <div className="px-3 py-[18px] border border-grey-90">Trade Name</div>
        <div className="px-3 py-[18px] border border-grey-90">Weight</div>
        <div className="px-3 py-[18px] border border-grey-90">Price / Lb</div>
        <div className="px-3 py-[18px] border border-grey-90">
          Price / Lb of Formula
        </div>
        <div className="px-3 py-[18px] border border-grey-90">Supplier</div>
      </div>
      <div className="grid grid-cols-[14%_22%_14%_16%_18%_16%] white font-inter max-h-[375px] [&::-webkit-scrollbar]:hidden overflow-auto">
        {values[WORKSHEET_FORM_KEYS.INGREDIENTS].map(
          (ingredient: any, index: number) => {
            return (
              <>
                <div className="flex justify-between items-center px-3 py-[18px] bg-white border-l border-b border-grey-90">
                  <span className="underline cursor-pointer">
                    {ingredient.rmCode}
                  </span>
                  <ArrowsRightLeftIcon className="h-4 w-4 text-royal-50 cursor-pointer" />
                </div>
                <div className="px-3 py-[18px] bg-white border-l border-b border-grey-90 text-ellipsis overflow-hidden">
                  <span className="whitespace-nowrap">
                    {ingredient.tradeName}
                  </span>
                </div>
                <div className="px-3 py-[18px] bg-white border-l border-b border-grey-90">
                  <span>{ingredient.weight}</span>
                </div>
                <div className="px-3 py-2 bg-white border-l border-b border-grey-90">
                  <TextField
                    {...getFieldProps(
                      `${WORKSHEET_FORM_KEYS.INGREDIENTS}.${index}.pricePerLb`
                    )}
                    leadingText="$"
                    height="h-11"
                    type="number"
                  />
                </div>
                <div className="px-3 py-[18px] bg-white border-l border-b border-grey-90">
                  <span>
                    {USDollar.format(
                      ingredient.pricePerLb * (ingredient.weight / 100)
                    )}
                  </span>
                </div>
                <div className="px-3 py-2 bg-white border-l border-b border-r border-grey-90 text-ellipsis overflow-hidden">
                  <TextField height="h-11" value={ingredient.supplier} />
                </div>
              </>
            );
          }
        )}
      </div>
    </>
  );
};
