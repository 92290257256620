// Utils
import { BriefAttributes } from 'api';
import { NOT_APPLICABLE } from './constants';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const dateFormatter = (dateString: string) => {
  const date = new Date(dateString);

  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
};

export const renderTargetCost = (attributes: Maybe<BriefAttributes>) => {
  if (
    !attributes ||
    (!attributes.targetCost &&
      !attributes.targetMinCost &&
      !attributes.targetMaxCost)
  )
    return NOT_APPLICABLE;
  const { targetCost, targetMinCost, targetMaxCost } = attributes;

  if (targetCost) {
    return currencyFormatter.format(targetCost);
  }

  return `${currencyFormatter.format(
    targetMinCost!
  )}-${currencyFormatter.format(targetMaxCost!)}`;
};
