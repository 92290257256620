// Libraries
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { MenuItem, TextField } from '@material-ui/core';
// Components
import { DialogModal } from 'features/ui';
import { UserContext } from 'context';
// Utils
import { handleError } from 'features/formula/utils';
import { FormulaContext } from '../context';
import { useApi, useSnackbar } from 'hooks';
import { IApiResponse } from 'features/types';
// Constants
import * as Constants from 'features/constants';
import { PERMISSIONS } from 'features/rbac';

interface StatusDialogProps {
  open: boolean;
  handleClose: () => void;
}

const {
  NOT_STARTED,
  IN_FORMULATION,
  PENDING_RAW_MATERIAL,
  IN_QC,
  AWAITING_CUSTOMER,
  APPROVED_BY_CUSTOMER,
  IN_PRODUCTION,
  INTERNAL_ONLY,
} = Constants.FORMULA_STATUSES;

const menuItems = [
  {
    value: NOT_STARTED,
  },
  {
    value: IN_FORMULATION,
  },
  {
    value: PENDING_RAW_MATERIAL,
  },
  {
    value: IN_QC,
  },
  {
    value: AWAITING_CUSTOMER,
  },
  {
    value: APPROVED_BY_CUSTOMER,
  },
  {
    value: IN_PRODUCTION,
    restricted: true,
  },
  {
    value: INTERNAL_ONLY,
    helperText: 'Internal formulas are not visible to customers',
  },
];

export const StatusDialog: React.FC<StatusDialogProps> = ({
  open,
  handleClose,
}) => {
  const request = useApi();
  const { formula, setFormula } = useContext(FormulaContext);
  const { showSnackbar } = useSnackbar();
  const { permissions } = useContext(UserContext)!;
  const canSetFormulaToInProduction = permissions.includes(
    PERMISSIONS.SET_TO_IN_PRODUCTION
  );

  const handleSubmit = async () => {
    if (
      formulaIsBeingSetToInProduction &&
      !window.confirm('Lock this formula and set to in production?')
    )
      return null;

    if (
      formulaIsBeingChangedFromInProduction &&
      formula?.locked &&
      !window.confirm(
        "Warning: Changing this formula's status will unlock it. Please confirm."
      )
    )
      return null;

    try {
      const response: IApiResponse = await request({
        resource: `/api/v1/formulas/${formula?.id}`,
        options: {
          settings: Constants.PATCH,
          scope: 'update:formula',
          body: {
            formula: {
              status: formik.values.status,
              productionRecipeId: formik.values.productionRecipeId,
              locked: shouldLockFormula(),
            },
          },
        },
      });

      if (response.data) {
        formula &&
          setFormula({
            ...formula,
            status: response.data.attributes.status,
            productionRecipeId: response.data.attributes.productionRecipeId,
            locked: response.data.attributes.locked,
          });
        showSnackbar('Formula successfully updated', 'success');
      } else {
        handleError(response);
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error');
      console.error(error);
      Rollbar.error(error);
    } finally {
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      status: formula?.status || '',
      productionRecipeId: formula?.productionRecipeId || '',
      locked: formula?.locked,
    },
    onSubmit: handleSubmit,
  });

  const areFieldsDisabled =
    !canSetFormulaToInProduction && formik.values.locked;

  const shouldLockFormula = () => {
    if (formulaIsBeingSetToInProduction) {
      return true;
    } else if (formulaIsBeingChangedFromInProduction) {
      return false;
    } else {
      return formula?.locked;
    }
  };

  const showRecipeNumberField = formik?.values.status === IN_PRODUCTION;
  const formulaIsBeingSetToInProduction =
    formula?.status !== IN_PRODUCTION &&
    formik?.values.status === IN_PRODUCTION;
  const formulaIsBeingChangedFromInProduction =
    formula?.status === IN_PRODUCTION &&
    formik?.values.status !== IN_PRODUCTION;

  const { getFieldProps } = formik;

  const renderMenuItems = () => {
    return menuItems.map(({ value, restricted, helperText }, idx) => {
      if (restricted) {
        return (
          canSetFormulaToInProduction && (
            <MenuItem key={`${idx}-${value}`} value={value}>
              {value}
            </MenuItem>
          )
        );
      } else {
        return (
          <MenuItem key={`${idx}-${value}`} value={value}>
            {value}
            {helperText && (
              <span
                style={{
                  color: 'rgba(0, 0, 0, 0.5)',
                  fontSize: '0.8rem',
                  marginLeft: '10px',
                }}
              >
                {helperText}
              </span>
            )}
          </MenuItem>
        );
      }
    });
  };

  return (
    <DialogModal
      open={open}
      title="Formula status"
      onConfirm={formik.handleSubmit}
      handleClose={handleClose}
    >
      <TextField
        fullWidth
        label="Status"
        type="select"
        name="status"
        onChange={formik.handleChange}
        value={areFieldsDisabled ? IN_PRODUCTION : formik.values.status}
        required
        select={
          canSetFormulaToInProduction || formula?.status !== IN_PRODUCTION
        }
        variant="outlined"
        disabled={areFieldsDisabled}
      >
        {renderMenuItems()}
      </TextField>
      {showRecipeNumberField && (
        <TextField
          style={{ marginTop: '20px' }}
          label="Recipe No."
          variant="outlined"
          {...getFieldProps('productionRecipeId')}
          required
          disabled={!canSetFormulaToInProduction}
        />
      )}
    </DialogModal>
  );
};
