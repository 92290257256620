//Libraries
import React from 'react';

export interface ITrackEventParams {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: number;
  nonInteraction?: boolean;
}

export type AnalyticsContextType = {
  pageView: (page: string) => void;
  set: (key: string, value: any) => void;
  trackEvent: ({
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    nonInteraction,
  }: ITrackEventParams) => void;
};

export const AnalyticsContext = React.createContext<
  AnalyticsContextType | undefined
>(undefined);
