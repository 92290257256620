// Libraries
import React from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
// Utils
import { interRegular, ITheme } from 'styles/mui/themeV2';

interface DialogModalProps {
  children: React.ReactNode | React.ReactNode[] | string;
  closeOnly?: boolean;
  dialogCta?: React.ReactNode | React.ReactNode[] | string;
  deleteButtonText?: string;
  handleClose: () => void;
  maxWidthSize?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  onConfirm?: () => void;
  onDelete?: () => void;
  open: boolean;
  overflow?: string;
  submitButtonText?: string;
  submitDisabled?: boolean;
  title: string;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      fontFamily: interRegular.fontFamily,
    },
    actions: {
      padding: '0',
    },
    fullWidthWrapper: {
      width: '100%',
    },
    box: {
      padding: theme.spacing(8),
    },
    content: {
      overflow: 'visible',
      padding: '0',
      margin: theme.spacing(6, 0, 6, 0),
    },
    title: {
      padding: '0',
      fontSize: '1.25rem',
    },
    deleteButton: {
      textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 'auto',
    },
    deleteAction: {
      textDecoration: 'underline',
      cursor: 'pointer',
      alignSelf: 'center',
    },
  })
);

export const DialogModal: React.FC<DialogModalProps> = ({
  children,
  closeOnly,
  deleteButtonText,
  dialogCta,
  handleClose,
  maxWidthSize,
  onConfirm,
  onDelete,
  open,
  overflow,
  submitButtonText,
  submitDisabled,
  title,
}) => {
  const classes = useStyles({ overflow });

  return (
    <Dialog
      className={classes.root}
      maxWidth={maxWidthSize || 'sm'}
      PaperProps={{
        style: {
          overflow: overflow ?? 'visible',
        },
      }}
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <Box className={classes.box}>
        <DialogTitle disableTypography className={classes.title}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>{children}</DialogContent>
        <Grid
          container
          item
          xs={12}
          justifyContent={dialogCta ? 'space-between' : 'flex-end'}
        >
          {dialogCta && (
            <Grid style={{ alignSelf: 'center' }} item>
              {dialogCta}
            </Grid>
          )}
          <Grid
            container
            item
            justifyContent="flex-end"
            className={classes.fullWidthWrapper}
          >
            <DialogActions className={classes.actions}>
              {deleteButtonText && (
                <Typography className={classes.deleteButton} onClick={onDelete}>
                  {deleteButtonText}
                </Typography>
              )}
              {closeOnly ? (
                <Button
                  size="large"
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                >
                  Close
                </Button>
              ) : (
                <>
                  <Button
                    size="large"
                    onClick={handleClose}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={submitDisabled}
                    size="large"
                    onClick={onConfirm}
                  >
                    {submitButtonText || 'Submit'}
                  </Button>
                </>
              )}
            </DialogActions>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
