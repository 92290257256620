// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Select } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const SampleQuantity: React.FC = () => {
  const { getFieldProps, setFieldValue } = useFormikContext<
    InternalBriefFormValues
  >();

  return (
    <div id="sample-quantity">
      <p className="font-inter text-lg font-normal text-grey-20 mb-6">
        Sample Quantity
      </p>
      <Select
        {...getFieldProps(BRIEF_FORM_KEYS.SAMPLE_QUANTITY)}
        onChange={(option: Option) =>
          setFieldValue(BRIEF_FORM_KEYS.SAMPLE_QUANTITY, option)
        }
        options={[
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' },
        ]}
        label="Desired number of samples"
        width="w-1/2"
      />
      <p className="font-inter text-xs font-normal text-grey-50 -mt-6">
        per sample recipient
      </p>
    </div>
  );
};
