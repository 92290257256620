// Libraries
import React from 'react';
// Components
import { Divider } from 'design-system';
import { SampleRecipients } from './sample-recipients.component';
import { SampleQuantity } from '../form-modules';

export const FormulaSamples: React.FC = () => (
  <>
    <SampleQuantity />

    <Divider />

    <div id="sample-recipients" className="flex flex-col gap-6">
      <p className="font-inter text-lg font-normal text-grey-20">
        Sample Recipients
      </p>
      <p className="font-inter text-base font-normal text-grey-50">
        Shipping information for sample recipients other than the point of
        contact.
      </p>
      <SampleRecipients />
    </div>
  </>
);
