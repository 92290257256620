//Libraries
import React from 'react';
import Link from '@material-ui/core/Link';
//Utils
import { ROUTES } from 'features/navigation';

export const TermsOfUse: React.FC = () => {
  return (
    <div>
      <p>Last Modified: February 1, 2021</p>

      <p>
        <b>
          <u>Acceptance of the Terms of Use</u>
        </b>
      </p>

      <p>
        These terms of use are entered into by and between You and Prime
        Enterprises LLC d/b/a Prime Matter Labs ("
        <b>Company</b>," "<b>we</b>," or "<b>us</b>"). The following terms and
        conditions ("<b>Terms of Use</b>") govern your access to and use of
        primematterlabs.com and its related websites, including any content,
        functionality, and services offered on or through primematterlabs.com
        and its related websites (the "<b>Website</b>"), whether as a guest or a
        registered user?.
      </p>

      <p>
        Please read the Terms of Use carefully before you start to use the
        Website.{' '}
        <b>
          By using the Website, you accept and agree to be bound and abide by
          these Terms of Use and our{' '}
          <Link href={ROUTES.PRIVACY_POLICY.route}>Privacy Policy</Link>,
          incorporated herein by reference.
        </b>{' '}
        If you do not want to agree to these Terms of Use or the{' '}
        <Link href={ROUTES.PRIVACY_POLICY.route}>Privacy Policy</Link>, you must
        not access or use the Website.
      </p>

      <p>
        This Website is offered and available to users who are 18 years of age
        or older. By using this Website, you represent and warrant that you are
        of legal age to form a binding contract with the Company and meet the
        foregoing eligibility requirements. If you do not meet all of these
        requirements, you must not access or use the Website.
      </p>

      <p>
        <b>
          <u>Changes to the Terms of Use</u>
        </b>
      </p>

      <p>
        We may revise and update these Terms of Use from time to time in our
        sole discretion. All changes are effective immediately when we post
        them, and apply to all access to and use of the Website thereafter.
      </p>

      <p>
        Your continued use of the Website following the posting of revised Terms
        of Use means that you accept and agree to the changes. You are expected
        to check this page from time to time so you are aware of any changes, as
        they are binding on you.
      </p>

      <p>
        <b>
          <u>Accessing the Website and Account Security</u>
        </b>
      </p>

      <p>
        We reserve the right to withdraw or amend this Website, and any service
        or material we provide on the Website, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of the
        Website is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of the Website, or the entire
        Website, to users, including registered users.
      </p>

      <p>You are responsible for both:</p>

      <ul>
        <li>
          Making all arrangements necessary for you to have access to the
          Website.
        </li>

        <li>
          Ensuring that all persons who access the Website through your internet
          connection are aware of these Terms of Use and comply with them.
        </li>
      </ul>

      <p>
        To access the Website or some of the resources it offers, you may be
        asked to provide certain registration details or other information. It
        is a condition of your use of the Website that all the information you
        provide on the Website is correct, current, and complete. You agree that
        all information you provide to register with this Website or otherwise,
        including, but not limited to, through the use of any interactive
        features on the Website, is governed by our{' '}
        <Link href={ROUTES.PRIVACY_POLICY.route}>Privacy Policy</Link>, and you
        consent to all actions we take with respect to your information
        consistent with our{' '}
        <Link href={ROUTES.PRIVACY_POLICY.route}>Privacy Policy</Link>.
      </p>

      <p>
        If you choose, or are provided with, a user , password, or any other
        piece of information as part of our security procedures, you must treat
        such information as confidential, and you must not disclose it to any
        other person or entity. You also acknowledge that your account is
        personal to you and agree not to provide any other person with access to
        this Website or portions of it using your <span>user </span>, password,
        or other security information. You agree to notify us immediately of any
        unauthorized access to or use of your <span>user </span> or password or
        any other breach of security. You also agree to ensure that you exit
        from your account at the end of each session. You should use particular
        caution when accessing your account from a public or shared computer so
        that others are not able to view or record your password or other
        personal information.
      </p>

      <p>
        We have the right to disable any
        <span>
          <span>user</span>
        </span>
        <span>
          , password, or other identifier, whether chosen by you or provided by
          us, at any time in our sole discretion for any or no reason, including
          if, in our opinion, you have violated any provision of these Terms of
          Use.
        </span>
      </p>

      <p>
        <b>
          <u>Intellectual Property Rights</u>
        </b>
      </p>

      <p>
        The Website and its entire contents, features, and functionality are
        owned by the Company, its licensors, or other providers of such material
        and are protected by United States and international copyright,
        trademark, patent, trade secret, and other intellectual property or
        proprietary rights laws. These Terms of Use permit you to use the
        Website for your personal, non-commercial use only. You must not
        reproduce, distribute, modify, create derivative works of, publicly
        display, publicly perform, republish, download, store, or transmit any
        of the material on our Website, except as follows:
      </p>
      <ul>
        <li>
          Your computer may temporarily store copies of such materials in RAM
          incidental to your accessing and viewing those materials.
        </li>

        <li>
          You may store files that are automatically cached by your Web browser
          for display enhancement purposes.
        </li>

        <li>
          You may print or download one copy of a reasonable number of pages of
          the Website for your own personal, non-commercial use and not for
          further reproduction, publication, or distribution.
        </li>

        <li>
          If we provide desktop, mobile, or other applications for download, you
          may download a single copy to your computer or mobile device solely
          for your own personal, non-commercial use, provided you agree to be
          bound by our end user license agreement for such applications.
        </li>
      </ul>
      <p>You must not:</p>

      <ul>
        <li>Modify copies of any materials from this site.</li>

        <li>
          Use any illustrations, photographs, video or audio sequences, or any
          graphics separately from the accompanying text.
        </li>

        <li>
          Delete or alter any copyright, trademark, or other proprietary rights
          notices from copies of materials from this site.
        </li>
      </ul>

      <p>
        You must not access or use for any commercial purposes any part of the
        Website or any services or materials available through the Website.
      </p>

      <p>
        If you wish to make any use of material on the Website other than that
        set out in this section, please address your request to:{' '}
        <Link href="mailto:support@primematterlabs.com">
          support@primematterlabs.com
        </Link>
        .
      </p>

      <p>
        If you print, copy, modify, download, or otherwise use or provide any
        other person with access to any part of the Website in breach of the
        Terms of Use, your right to use the Website will stop immediately and
        you must, at our option, return or destroy any copies of the materials
        you have made. No right, title, or interest in or to the Website or any
        content on the Website is transferred to you, and all rights not
        expressly granted are reserved by the Company. Any use of the Website
        not expressly permitted by these Terms of Use is a breach of these Terms
        of Use and may violate copyright, trademark, and other laws.
      </p>

      <p>
        <b>
          <u>Trademarks</u>
        </b>
      </p>

      <p>
        The Company's name, trademarks, and logos, and all related , logos,
        product and service , designs, and slogans are trademarks of the Company
        or its affiliates or licensors. You must not use such marks without the
        prior written permission of the Company. All other , logos, product and
        service , designs, and slogans on this Website are the trademarks of
        their respective owners.
      </p>

      <p>
        <b>
          <u>Prohibited Uses</u>
        </b>
      </p>

      <p>
        You may use the Website only for lawful purposes and in accordance with
        these Terms of Use. You agree not to use the Website:
      </p>

      <ul>
        <li>
          In any way that violates any applicable federal, state, local, or
          international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the US or
          other countries).{' '}
        </li>

        <li>
          For the purpose of exploiting, harming, or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information, or otherwise.
        </li>

        <li>
          To impersonate or attempt to impersonate the Company, a Company
          employee, another user, or any other person or entity (including,
          without limitation, by using email addresses associated with any of
          the foregoing).
        </li>

        <li>
          To engage in any other conduct that restricts or inhibits anyone‚Äôs
          use or enjoyment of the Website, or which, as determined by us, may
          harm the Company or users of the Website, or expose them to liability.
        </li>
      </ul>

      <p>Additionally, you agree not to:</p>

      <ul>
        <li>
          Use the Website in any manner that could disable, overburden, damage,
          or impair the site or interfere with any other party‚Äôs use of the
          Website, including their ability to engage in real time activities
          through the Website.
        </li>

        <li>
          Use any robot, spider, or other automatic device, process, or means to
          access the Website for any purpose, including monitoring or copying
          any of the material on the Website.
        </li>

        <li>
          Use any manual process to monitor or copy any of the material on the
          Website, or for any other purpose not expressly authorized in these
          Terms of Use, without our prior written consent.
        </li>

        <li>
          Use any device, software, or routine that interferes with the proper
          working of the Website.
        </li>

        <li>
          Introduce any viruses, Trojan horses, worms, logic bombs, or other
          material that is malicious or technologically harmful.
        </li>

        <li>
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any parts of the Website, the server on which the Website is
          stored, or any server, computer, or database connected to the Website.{' '}
        </li>

        <li>
          Attack the Website via a denial-of-service attack or a distributed
          denial-of-service attack.
        </li>

        <li>
          Otherwise attempt to interfere with the proper working of the Website.
        </li>
      </ul>

      <p>
        <b>
          <u>User Contributions</u>
        </b>
      </p>

      <p>
        The Website may contain portals, questionnaires, submission
        capabilities, and other interactive features (collectively, "
        <b>Interactive Services</b>") that allow users to submit, publish, or
        transmit to the Company content or materials (collectively, "
        <b>User Contributions</b>") on or through the Website. User
        Contributions must not infringe any patent, trademark, trade secret,
        copyright, or other intellectual property or other rights of any other
        person, or violate the legal rights (including the rights of publicity
        and privacy) of others or contain any material that could give rise to
        any civil or criminal liability under applicable laws or regulations or
        that otherwise may be in conflict with these Terms of Use and our{' '}
        <Link href={ROUTES.PRIVACY_POLICY.route}>Privacy Policy</Link>.
      </p>

      <p>
        Any User Contribution you submit or transmit will be considered
        non-confidential and non-proprietary. By providing any User
        Contribution, you grant us and our affiliates and service providers, and
        each of their and our respective licensees, successors, and assigns the
        right to use, reproduce, modify, perform, display, distribute, and
        otherwise disclose to third parties any such material for any purpose.
      </p>

      <p>You represent and warrant that:</p>

      <ul>
        <li>
          You own or control all rights in and to the User Contributions and
          have the right to grant the license granted above to us and our
          affiliates and service providers, and each of their and our respective
          licensees, successors, and assigns.
        </li>

        <li>
          All of your User Contributions do and will comply with these Terms of
          Use.
        </li>
      </ul>

      <p>
        You understand and acknowledge that you are responsible for any User
        Contributions you submit or transmit, and you, not the Company, have
        full responsibility for such content, including its legality,
        reliability, accuracy, and appropriateness.
      </p>

      <p>
        We are not responsible or liable to any third party for the content or
        accuracy of any User Contributions submitted or transmitted by you or
        any other user of the Website.
      </p>

      <p>
        <b>
          <u>Monitoring and Enforcement; Termination</u>
        </b>
      </p>

      <p>We have the right to:</p>

      <ul>
        <li>
          Remove or refuse to accept any User Contributions for any or no reason
          in our sole discretion.
        </li>

        <li>
          Take any action with respect to any User Contribution that we deem
          necessary or appropriate in our sole discretion, including if we
          believe that such User Contribution violates the Terms of Use,
          infringes any law intellectual property right or other right of any
          person or entity, or could create liability for the Company.
        </li>

        <li>
          Terminate or suspend your access to all or part of the Website for any
          or no reason, including without limitation, any violation of these
          Terms of Use.
        </li>
      </ul>

      <p>
        Without limiting the foregoing, we have the right to cooperate fully
        with any law enforcement authorities or court order requesting or
        directing us to disclose the identity or other information of anyone
        posting any materials on or through the Website. YOU WAIVE AND HOLD
        HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE
        PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE
        FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS
        BY EITHER SUCH PARTIES.
      </p>

      <p>
        We have no liability or responsibility to anyone for performance or
        nonperformance of the activities described in this section.
      </p>

      <p>
        <b>
          <u>Reliance on Information Posted</u>
        </b>
      </p>

      <p>
        The information presented on or through the Website is made available
        solely for general information purposes. We do not warrant the accuracy,
        completeness, or usefulness of this information. Any reliance you place
        on such information is strictly at your own risk. We disclaim all
        liability and responsibility arising from any reliance placed on such
        materials by you or any other visitor to the Website, or by anyone who
        may be informed of any of its contents.
      </p>

      <p>
        This Website may include content provided by third parties. All
        statements and/or opinions expressed in these materials, and all
        articles and responses to questions and other content, other than the
        content provided by the Company, are solely the opinions and the
        responsibility of the person or entity providing those materials. These
        materials do not necessarily reflect the opinion of the Company. We are
        not responsible, or liable to you or any third party, for the content or
        accuracy of any materials provided by any third parties.
      </p>

      <p>
        <b>
          <u>Changes to the Website</u>
        </b>
      </p>

      <p>
        We may update the content on this Website from time to time, but its
        content is not necessarily complete or up-to-date. Any of the material
        on the Website may be out of date at any given time, and we are under no
        obligation to update such material.
      </p>

      <p>
        <b>
          <u>Information About You and Your Visits to the Website</u>
        </b>
      </p>

      <p>
        All information we collect on this Website is subject to our{' '}
        <Link href={ROUTES.PRIVACY_POLICY.route}>Privacy Policy</Link>. By using
        the Website, you consent to all actions taken by us with respect to your
        information in compliance with the{' '}
        <Link href={ROUTES.PRIVACY_POLICY.route}>Privacy Policy</Link>.
      </p>

      <p>
        <b>
          <u>Online Purchases and Other Terms and Conditions</u>
        </b>
      </p>

      <p>
        All purchases through our site or other transactions for the sale of
        goods, services, or other items through the Website, or resulting from
        visits made by you, are governed by our Terms of Sale or any applicable
        written agreement between you and the Company, which are hereby
        incorporated into these Terms of Use.
      </p>

      <p>
        <b>
          <u>Links from the Website</u>
        </b>
      </p>

      <p>
        If the Website contains links to other sites and resources provided by
        third parties, these links are provided for your convenience only. This
        includes links contained in advertisements, including banner
        advertisements and sponsored links. We have no control over the contents
        of those sites or resources, and accept no responsibility for them or
        for any loss or damage that may arise from your use of them. If you
        decide to access any of the third-party websites linked to this Website,
        you do so entirely at your own risk and subject to the terms and
        conditions of use for such websites.
      </p>

      <p>
        <b>
          <u>Geographic Restrictions</u>
        </b>
      </p>

      <p>
        The owner of the Website is based in the State of Florida in the United
        States. We make no claims that the Website or any of its content is
        accessible or appropriate outside of the United States. Access to the
        Website may not be legal by certain persons or in certain countries. If
        you access the Website from outside the United States, you do so on your
        own initiative and are responsible for compliance with local laws.
      </p>

      <p>
        <b>
          <u>Disclaimer of Warranties</u>
        </b>
      </p>

      <p>
        You understand that we cannot and do not guarantee or warrant that files
        available for downloading from the internet or the Website will be free
        of viruses or other destructive code. You are responsible for
        implementing sufficient procedures and checkpoints to satisfy your
        particular requirements for anti-virus protection and accuracy of data
        input and output, and for maintaining a means external to our site for
        any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
        LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
        DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY
        HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
        PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
        WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
        DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO
        IT.
      </p>

      <p>
        YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
        THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND
        ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN
        "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
        EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED
        WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO
        THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
        AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE
        COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS
        THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
        THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
        THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES
        IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT
        THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
        OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
      </p>

      <p>
        TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
        OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
      </p>

      <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>

      <p>
        <b>
          <u>Limitation on Liability</u>
        </b>
      </p>

      <p>
        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS
        AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
        OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
        LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
        INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON
        THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT,
        SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT
        NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
        LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
        SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED
        BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN
        IF FORESEEABLE.
      </p>

      <p>
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>

      <p>
        <b>
          <u>Indemnification</u>
        </b>
      </p>

      <p>
        You agree to defend, indemnify, and hold harmless the Company, its
        affiliates, licensors, and service providers, and its and their
        respective officers, directors, employees, contractors, agents,
        licensors, suppliers, successors, and assigns from and against any
        claims, liabilities, damages, judgments, awards, losses, costs,
        expenses, or fees (including reasonable attorneys' fees) arising out of
        or relating to your violation of these Terms of Use or your use of the
        Website, including, but not limited to, your User Contributions, any use
        of the Website‚Äôs content, services, and products other than as
        expressly authorized in these Terms of Use, or your use of any
        information obtained from the Website.
      </p>

      <p>
        <b>
          <u>Governing Law and Jurisdiction</u>
        </b>
      </p>

      <p>
        All matters relating to the Website and these Terms of Use, and any
        dispute or claim arising therefrom or related thereto (in each case,
        including non-contractual disputes or claims), shall be governed by and
        construed in accordance with the internal laws of the State of Florida
        without giving effect to any choice or conflict of law provision or rule
        (whether of the State of Florida or any other jurisdiction).
      </p>

      <p>
        Any legal suit, action, or proceeding arising out of, or related to,
        these Terms of Use or the Website shall be instituted exclusively in the
        federal courts of the United States or the courts of the State of
        Florida, in each case located in the City of Fort Lauderdale and County
        of Broward, although we retain the right to bring any suit, action, or
        proceeding against you for breach of these Terms of Use in your country
        of residence or any other relevant country. You waive any and all
        objections to the exercise of jurisdiction over you by such courts and
        to venue in such courts.
      </p>

      <p>
        <b>
          <u>Waiver and Severability</u>
        </b>
      </p>

      <p>
        No waiver by the Company of any term or condition set out in these Terms
        of Use shall be deemed a further or continuing waiver of such term or
        condition or a waiver of any other term or condition, and any failure of
        the Company to assert a right or provision under these Terms of Use
        shall not constitute a waiver of such right or provision.
      </p>

      <p>
        If any provision of these Terms of Use is held by a court or other
        tribunal of competent jurisdiction to be invalid, illegal, or
        unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of the
        Terms of Use will continue in full force and effect.
      </p>

      <p>
        <b>
          <u>Entire Agreement</u>
        </b>
      </p>

      <p>
        The Terms of Use and our{' '}
        <Link href={ROUTES.PRIVACY_POLICY.route}>Privacy Policy</Link>{' '}
        constitute the sole and entire agreement between you and the Company
        regarding the Website and supersede all prior and contemporaneous
        understandings, agreements, representations, and warranties, both
        written and oral, regarding the Website.
      </p>

      <p>
        <b>
          <u>Your Comments and Concerns</u>
        </b>
      </p>

      <p>
        This website is operated by Prime Enterprises, LLC, 16363 NW 49TH AVE,
        MIAMI, FL 33014
      </p>

      <p>
        All feedback, comments, requests for technical support, and other
        communications relating to the Website should be directed to:{' '}
        <Link href="mailto:support@primematterlabs.com">
          support@primematterlabs.com
        </Link>
      </p>
    </div>
  );
};
