// Libraries
import React, { useState } from 'react';
import { Chip, TextField } from '@material-ui/core';
// Components
import { DialogModal } from 'features/ui';
// Constants
import { Autocomplete } from '@material-ui/lab';

interface SharePricingQuoteModalProps {
  sendPricingQuoteEmail: (emails: string[]) => void;
  isOpen: boolean;
  closeModal: () => void;
}

export const SharePricingQuoteModal: React.FC<SharePricingQuoteModalProps> = ({
  sendPricingQuoteEmail,
  isOpen,
  closeModal,
}) => {
  const [emails, setEmails] = useState<string[]>([]);

  return (
    <DialogModal
      onConfirm={() => sendPricingQuoteEmail(emails)}
      open={isOpen}
      handleClose={closeModal}
      submitButtonText="Send"
      title={'Share pricing quote'}
      submitDisabled={emails.length < 1}
    >
      <Autocomplete
        multiple
        options={emails.map((email) => email)}
        freeSolo
        onChange={(_e, values) => setEmails(values)}
        renderTags={(values: string[], getTagProps) =>
          values.map((option: string, index: number) => (
            <Chip
              variant="default"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Emails"
            placeholder='Press "Enter" to add email'
            helperText="Press 'Enter' after each email you enter."
          />
        )}
      />
    </DialogModal>
  );
};
