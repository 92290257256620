// Libraries
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentTextIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
  PlusCircleIcon,
  PlusIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/16/solid';
// Components
import { Button, FullscreenOverlay, StatusChip } from 'design-system';
import { NewPriceQuoteModal } from './new-price-quote-modal.component';
import { Worksheet } from './worksheet/worksheet.component';
import { WorksheetForm } from './worksheet/worksheet-form.component';
// Utils
import { StatusChipProps } from 'design-system/status-chip.component';
import { ChipStatus } from 'design-system/status-chip.component';
import { BriefAttributes, IApiData, useApi, WorksheetAttributes } from 'api';

enum PriceQuoteType {
  Final = 'final',
  Preliminary = 'preliminary',
}

interface QuoteListProps {
  projectUuid: string;
  brief?: Maybe<IApiData<BriefAttributes, any>>;
}

export const QuoteList: React.FC<QuoteListProps> = ({ brief, projectUuid }) => {
  const { getProjectWorksheets } = useApi();
  const [quotes, setQuotes] = useState<IApiData<WorksheetAttributes>[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [isWorksheetOpen, setIsWorksheetOpen] = useState(false);
  const [worksheetIndex, setWorksheetIndex] = useState<number>(0);
  const [isNewQuoteModalOpen, setIsNewQuoteModalOpen] = useState(false);

  let prelimQuotesCount =
    quotes.length > 0
      ? quotes.filter(
          (quote) => quote.attributes.quoteType === PriceQuoteType.Preliminary
        ).length + 1
      : 0;
  let finalQuotesCount =
    quotes.length > 0
      ? quotes.filter(
          (quote) => quote.attributes.quoteType === PriceQuoteType.Final
        ).length + 1
      : 0;

  useEffect(() => {
    getProjectWorksheets({
      urlParams: `${projectUuid}/worksheets`,
      handleSuccess: ({ data }) => {
        setQuotes(data);
      },
    });
  }, [getProjectWorksheets, projectUuid]);

  const renderQuotes = (quotes: IApiData<WorksheetAttributes>[]) => {
    if (quotes.length < 1) return;
    const sortedPriceQuotes = quotes.sort((a, b) => {
      return (
        new Date(b.attributes.createdAt).getTime() -
        new Date(a.attributes.createdAt).getTime()
      );
    });

    return sortedPriceQuotes.map((quote: any, index) => {
      if (quote.type === PriceQuoteType.Final) {
        finalQuotesCount -= 1;
      } else {
        prelimQuotesCount -= 1;
      }

      const isFinalQuote = quote.attributes.quoteType === PriceQuoteType.Final;
      const isExpired = quote.status === ChipStatus.Expired;

      return (
        <div className="flex flex-col bg-white rounded">
          <div className="flex flex-col gap-3 sm:gap-0 sm:flex-row sm:justify-between p-4 border-b border-grey-90">
            <div className="flex items-center gap-3">
              <div className="text-lg">
                {isFinalQuote ? 'Final' : 'Prelim'} v
                {isFinalQuote ? finalQuotesCount : prelimQuotesCount}
              </div>
              <StatusChip status={quote.status as StatusChipProps['status']} />
            </div>
            <div className="flex items-center gap-3 text-xs">
              <div className="flex flex-col sm:items-end">
                <span className="text-royal-50 cursor-pointer">
                  Formula #:{quote.parsedIncluded.formula.attributes.externalId}
                </span>
                <span className="text-grey-50">
                  {quote.parsedIncluded.formula.attributes.name}
                </span>
              </div>
              <EllipsisVerticalIcon className="h-5 w-5 text-royal-50 cursor-pointer" />
            </div>
          </div>
          <div className="flex flex-col p-4 gap-9">
            <div>
              <div className="text-xs font-mono text-grey-50 uppercase font-[500] mb-3">
                Worksheet
              </div>
              <div className="flex flex-col sm:flex-row gap-3 sm:items-center">
                <div
                  onClick={() => {
                    setWorksheetIndex(index);
                    setIsWorksheetOpen(true);
                  }}
                  className={`${
                    isExpired
                      ? 'text-grey-50 cursor-default'
                      : 'text-royal-50 cursor-pointer'
                  } flex gap-2 px-3 py-4 rounded-[4px] border border-grey-90 `}
                >
                  <PresentationChartLineIcon className="h-5 w-5 " />
                  <span className="">Pricing Worksheet</span>
                </div>
                <div className="flex flex-col text-xs text-grey-50">
                  <span className="">
                    Last edited{' '}
                    {format(new Date(quote.attributes.updatedAt), 'MM/dd/yy')}{' '}
                    at {format(new Date(quote.attributes.updatedAt), 'p')}
                  </span>
                  <span className="cursor-pointer underline">
                    View changelog
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="flex gap-3 items-center text-xs font-mono text-grey-50 uppercase font-[500] mb-3">
                <span>Worksheet Approvals</span>
                <InformationCircleIcon className="h-4 w-4 cursor-pointer" />
              </div>
              <div>Placeholder</div>
            </div>
            <div
              className={`${
                quote.documentStatus === ChipStatus.Approved
                  ? 'bg-green-95'
                  : ''
              } flex flex-col gap-3 p-3 rounded border border-grey-90`}
            >
              <span className="text-xs font-mono text-grey-50 uppercase font-[500]">
                Quote
              </span>
              {quote.hasDocument ? (
                <div className="flex flex-col gap-3 sm:gap-0 sm:flex-row justify-between sm:items-center">
                  <div className="flex gap-2 max-w-max bg-blue-99 border border-grey-90 rounded px-3 py-4 text-royal-50">
                    <DocumentTextIcon className="h-5 w-5" />
                    <span className="cursor-pointer">
                      {quote.type === PriceQuoteType.Final ? 'Final' : 'Prelim'}{' '}
                      Quote Doc
                    </span>
                  </div>
                  <div className="flex gap-3 text-xs font-inter items-center">
                    {quote.documentDate && (
                      <span className="text-grey-50">
                        {quote.documentStatus === ChipStatus.InReview
                          ? 'Shared'
                          : null}{' '}
                        {format(quote.documentDate, 'MM/dd/yy')}
                      </span>
                    )}
                    {quote.documentStatus === ChipStatus.Rejected ? (
                      <span className="underline cursor-pointer text-royal-50">
                        View Feedback
                      </span>
                    ) : null}
                    <StatusChip
                      status={quote.documentStatus as StatusChipProps['status']}
                    />
                  </div>
                </div>
              ) : (
                <Button
                  leadingIcon={<PlusIcon className="h-5 w-5" />}
                  type="primary"
                  disabled
                  text="Customer Quote Doc"
                />
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return quotes.length > 0 ? (
    <>
      {isWorksheetOpen && (
        <FullscreenOverlay onClose={() => setIsWorksheetOpen(false)}>
          <WorksheetForm worksheetData={quotes[worksheetIndex]}>
            <Worksheet closeWorksheet={() => setIsWorksheetOpen(false)} />
          </WorksheetForm>
        </FullscreenOverlay>
      )}
      {isNewQuoteModalOpen && (
        <NewPriceQuoteModal
          projectUuid={projectUuid}
          handleClose={() => setIsNewQuoteModalOpen(false)}
        />
      )}
      <div className="bg-grey-95 p-6 flex flex-col gap-9 font-inter">
        <div className="flex sm:justify-end">
          <Button
            action={() => setIsNewQuoteModalOpen(true)}
            leadingIcon={<PlusCircleIcon />}
            size="large"
            text="New Quote"
          />
        </div>
        {/* {renderQuotes(quotes.filter((quote) => !quote.isArchived))} */}
        {renderQuotes(quotes)}
        <div
          onClick={() => setShowArchived(!showArchived)}
          className="flex gap-1 items-center text-grey-50 cursor-pointer"
        >
          <span className="text-sm">
            {showArchived ? 'Hide' : 'Show'} archived
          </span>
          {showArchived ? (
            <ChevronUpIcon className="h-4 w-4" />
          ) : (
            <ChevronDownIcon className="h-4 w-4" />
          )}
        </div>
        {/* {showArchived &&
          renderQuotes(priceQuotes.filter((quote) => quote.isArchived))} */}
      </div>
    </>
  ) : (
    <div className="bg-grey-95 p-6 h-screen">
      <div className="flex justify-end">
        <Button
          leadingIcon={<PlusCircleIcon />}
          size="large"
          text="New Quote"
        />
      </div>
      <div className="flex justify-center mt-[155px] font-inter">
        <div>
          This project has no quotes yet.{' '}
          <span className="underline cursor-pointer">Create a new one</span>
        </div>
      </div>
    </div>
  );
};
