// Libraries
import React from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { FormikHelpers, FormikProps } from 'formik';
// Utils
import { useSnackbar } from 'hooks';
import { CustomerAttributes, IApiData, useApi } from 'api';
import { ITheme } from 'styles/mui/themeV2';
import { TeamMemberFormik } from './team-member-formik.component';
import { TEAM_MEMBER_FIELD_NAMES } from './team-management.constants';
import { TeamMemberFormValues } from './types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    helperText: {
      position: 'absolute',
      bottom: '-1.5rem',
      textWrap: 'nowrap',
      [theme.breakpoints.down('md')]: {
        position: 'initial',
      },
    },
  })
);

const initialValues: TeamMemberFormValues = {
  givenName: '',
  familyName: '',
  email: '',
};

interface TeamManagementFormProps {
  companyUuid: string;
  setTeamMembers: React.Dispatch<
    React.SetStateAction<IApiData<CustomerAttributes>[]>
  >;
}

export const InviteTeamMemberForm: React.FC<TeamManagementFormProps> = ({
  companyUuid,
  setTeamMembers,
}) => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { postEmail } = useApi();

  const handleSendCustomerInvite = (
    values: TeamMemberFormValues,
    { resetForm, setSubmitting }: FormikHelpers<TeamMemberFormValues>
  ) => {
    setSubmitting(true);
    postEmail({
      data: {
        email: {
          customerAttributes: {
            ...values,
          },
          companyUuid,
          method: 'create-notification',
          itemType: 'customer_invitation',
        },
      },
      handleSuccess: (data) => {
        setTeamMembers((prev) => [...prev, data]);
        showSnackbar('Invite sent!', 'success');
        resetForm();
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  return (
    <TeamMemberFormik
      initialValues={initialValues}
      handleSubmit={handleSendCustomerInvite}
    >
      {({
        getFieldProps,
        submitForm,
        touched,
        errors,
        isSubmitting,
      }: FormikProps<TeamMemberFormValues>) => (
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.givenName)}
              error={touched.givenName && !!errors.givenName}
              helperText={touched.givenName && errors.givenName}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              label="First name"
              fullWidth
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.familyName)}
              error={touched.familyName && !!errors.familyName}
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              helperText={touched.familyName && errors.familyName}
              label="Last name"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.email)}
              error={touched.email && !!errors.email}
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              helperText={touched.email && errors.email}
              label="Work email"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              disabled={isSubmitting}
              fullWidth
              size="large"
              onClick={submitForm}
              variant="outlined"
              startIcon={
                isSubmitting ? (
                  <CircularProgress color="secondary" size={20} thickness={6} />
                ) : undefined
              }
            >
              Invite
            </Button>
          </Grid>
        </Grid>
      )}
    </TeamMemberFormik>
  );
};
