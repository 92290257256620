// Libraries
import { createContext } from 'react';

export const WizardContext = createContext<{
  pageNumber: number;
  incrementPage: () => void;
  decrementPage: () => void;
}>({
  pageNumber: 0,
  incrementPage: () => null,
  decrementPage: () => null,
});
