// Libraries
import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
// Utils
import {
  THEME_PALETTE,
  gTAmericaMonoRegular,
  interRegular,
} from 'styles/mui/themeV2';
import { parseCostFromPrice } from 'features/formula/formula-page/utils';
import { Ingredient } from 'features/formula/formula-page/types';
import { IngredientAttributes, IApiData } from 'api';
// Constants
import { UNITS } from 'features/formula/formula-page/constants';

ChartJS.register(
  BarElement,
  CategoryScale,
  ChartDataLabels,
  Legend,
  LinearScale,
  Title,
  Tooltip
);

const MAX_TICK_LENGTH = 20;
const TICK_LENGTH_SPLICE_POINT = 23;

export const calculatePricePerOunceAtCurrentWeight = (
  ingredient: Ingredient,
  selectedPricingUnit: string
) => {
  if (!ingredient?.price) return { ...ingredient, pricePerOunce: 0 };
  const pricePerOunce =
    parseCostFromPrice(ingredient.price, selectedPricingUnit) *
    (ingredient.amount / 100);
  return { ...ingredient, pricePerOunce };
};

interface IRawMaterialCostChart {
  ingredients: IApiData<IngredientAttributes>[];
}

interface IngredientWithPricePerOunce extends Ingredient {
  pricePerOunce: number;
}

export const RawMaterialCostChart: React.FC<IRawMaterialCostChart> = ({
  ingredients,
}) => {
  const totalCostOfFormula = ingredients.reduce((acc, ingredient) => {
    acc += ingredient?.attributes.highestPrice
      ? parseCostFromPrice(ingredient.attributes.highestPrice, UNITS.LB) *
        ((ingredient.attributes.amount as number) / 100)
      : 0;
    return acc;
  }, 0);

  const selectedIngredients: IngredientWithPricePerOunce[] = ingredients
    .map((ingredient) => {
      // TODO: update type in calculate function and places it is used
      return calculatePricePerOunceAtCurrentWeight(
        ({ ...ingredient.attributes } as any) as Ingredient,
        UNITS.LB
      );
    })
    .sort(
      (a, b) =>
        b.pricePerOunce / totalCostOfFormula -
        a.pricePerOunce / totalCostOfFormula
    )
    .slice(0, 5);

  const chartData = {
    labels: selectedIngredients.map((ingredient: Ingredient) => [
      ingredient.rawMaterialName.length > MAX_TICK_LENGTH
        ? ingredient.rawMaterialName
            .slice(0, TICK_LENGTH_SPLICE_POINT)
            .concat('...')
        : ingredient.rawMaterialName,
      `Cost: $${ingredient.pricePerOunce.toFixed(2)} / lb`,
    ]),
    datasets: [
      {
        label: '% OF TOTAL COST',
        data: selectedIngredients.map((ingredient: Ingredient) =>
          parseFloat(
            ((ingredient.pricePerOunce / totalCostOfFormula) * 100).toFixed(2)
          )
        ),
        backgroundColor: THEME_PALETTE.blue.dark,
      },
      {
        label: '% OF RAW MATERIAL IN FORMULA',
        data: selectedIngredients.map(
          (ingredient: Ingredient) => ingredient.amount
        ),
        backgroundColor: THEME_PALETTE.blue.main,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        grid: {
          display: false,
        },
        min: 0,
        suggestedMax: 0,
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: interRegular.fontFamily,
          },
          stepSize: 100,
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'start',
        offset: -20,
        color: THEME_PALETTE.gray.dark,
        font: {
          family: gTAmericaMonoRegular.fontFamily,
        },
        formatter: (value: string) => {
          return value + '%';
        },
      },
      legend: {
        labels: {
          font: {
            family: gTAmericaMonoRegular.fontFamily,
            weight: 'bold',
          },
        },
      },
    },
  } as ChartOptions<'bar'>;

  return (
    <div>
      <Bar
        data={chartData}
        options={chartOptions}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
};
