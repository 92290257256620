// Libraries
import React, { useState } from 'react';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
// Components
import { Menu } from 'design-system/select.component';

const dummySizes = [
  {
    size: '8',
    unit: 'oz',
  },
  {
    size: '10',
    unit: 'oz',
  },
];

export const FillSizeSelector = () => {
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  const [editSizeDropdown, setEditSizeDropdown] = useState<number | null>(null);

  const sizeMenuOptions = [
    { label: 'Edit fill size', value: 'edit' },
    {
      label: 'Delete fill size',
      leadingIcon: <TrashIcon className="w-5 h-5 text-red-50" />,
      value: 'delete',
    },
  ];

  const renderSizes = () => {
    return dummySizes.map((size, index) => {
      return (
        <div className="relative flex items-center">
          <div
            onClick={() => setSelectedSizeIndex(index)}
            key={index}
            className={`cursor-pointer  ${
              index === selectedSizeIndex && 'border-b-[3px] border-royal-50'
            }`}
          >
            {size.size} {size.unit}
          </div>

          <EllipsisVerticalIcon
            onClick={() => setEditSizeDropdown(index)}
            className="w-4 h-4 ml-1 text-grey-50 cursor-pointer"
          />

          {editSizeDropdown === index && (
            <div className="absolute -top-8 left-0">
              <Menu
                width="w-[200px]"
                onClose={() => setEditSizeDropdown(null)}
                options={sizeMenuOptions}
              />
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="mt-16 col-start-2 col-span-10 flex gap-16 text-lg text-grey-50">
      {renderSizes()}
      <span className="cursor-pointer">Add fill size +</span>
    </div>
  );
};
