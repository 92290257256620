// Libraries
import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/16/solid';
// Components
import { Button } from 'design-system';

interface SectionSummaryRowProps {
  data: any;
  innerContent?: React.ReactNode | React.ReactNode[];
  isActive: boolean;
  onCancel: () => void;
  setActiveSection: () => void;
}

export const SectionSummaryRow: React.FC<SectionSummaryRowProps> = ({
  data,
  innerContent,
  isActive,
  onCancel,
  setActiveSection,
}) => {
  return (
    <>
      {innerContent}
      <div
        className={`grid grid-cols-10 items-center px-3 py-4 border border-grey-90 bg-white ${
          data.isTopSection ? 'border-b-black' : ''
        }`}
      >
        <span className="col-span-2 text-grey-50">{data.title}</span>
        {data.values.map((value: string) => {
          return (
            <span
              className={`${data.isTopSection && 'font-semibold'} col-span-2`}
            >
              {value}
            </span>
          );
        })}
        <div className="col-start-10 flex items-center justify-end cursor-pointer">
          {isActive ? (
            <div className="flex gap-[5px]">
              <Button onClick={onCancel} type="secondary" text="Cancel" />
              <Button type="primary" text="Save" />
            </div>
          ) : (
            <PencilSquareIcon
              onClick={setActiveSection}
              className=" h-4 w-4 text-royal-50"
            />
          )}
        </div>
      </div>
    </>
  );
};
