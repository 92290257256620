// Libraries
import React from 'react';
import { Container, Grid, makeStyles, createStyles } from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';

interface IPage {
  children: React.ReactNode[] | React.ReactNode;
  [x: string]: any;
}
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(16),
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
      },
    },
  })
);

export const Page: React.FC<IPage> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <div className="reset">
      <Container fixed className={classes.container} {...rest}>
        <Grid container item lg={12} spacing={5}>
          {children}
        </Grid>
      </Container>
    </div>
  );
};
