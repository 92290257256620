// Libraries
import React from 'react';
import { format, parse } from 'date-fns';
import {
  makeStyles,
  createStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TableContainer,
} from '@material-ui/core';
import CreateOutlined from '@material-ui/icons/CreateOutlined';
import { FormikErrors } from 'formik';
// Utils
import { ITableColumn } from 'features/types';
import { Price } from 'features/types';
import { ITheme } from 'styles/mui/themeV2';
import { PricingFormType } from './types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    headerRow: {
      top: '65px',
      zIndex: 100,
    },
    receivedOn: {
      color: theme.palette.gray.main,
    },
    table: {
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.gray.light}`,
      borderRadius: '0.25rem',
    },
    tableCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '0',
    },
    tableHeader: {
      '&:first-child': {
        borderTopLeftRadius: '0.25rem',
      },
      '&:last-child': {
        borderTopRightRadius: '0.25rem',
      },
      backgroundColor: theme.palette.secondary.dark,
    },
    tableContainer: {
      maxHeight: 400,
    },
  })
);

const NOT_APPLICABLE = 'N/A';

interface PricingTableProps {
  prices: Price[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          create: PricingFormType;
          edit: PricingFormType;
        }>
      >;
  setPriceIndexToEdit: React.Dispatch<React.SetStateAction<Maybe<number>>>;
  setIsEditPriceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TABLE_HEADERS: ITableColumn[] = [
  {
    headerText: 'Supplier',
    columnWidth: '25%',
    align: 'left',
  },
  {
    headerText: 'Manufacturer',
    columnWidth: '25%',
    align: 'left',
  },
  {
    headerText: 'MOQ',
    columnWidth: '12%',
    align: 'left',
  },
  {
    headerText: 'Lead Time',
    columnWidth: '12%',
    align: 'left',
  },
  {
    headerText: 'Price',
    columnWidth: '22%',
    align: 'right',
  },
  {
    headerText: '',
    columnWidth: '0%',
    align: 'left',
  },
];

export const PricingTable: React.FC<PricingTableProps> = ({
  prices,
  setFieldValue,
  setPriceIndexToEdit,
  setIsEditPriceModalOpen,
}) => {
  const classes = useStyles();

  const initializeEditModal = (price: Price) => {
    setIsEditPriceModalOpen(true);

    setFieldValue('edit', {
      cost: price.cost,
      moq: price.moq || '',
      moqUnit: price.moqUnit || '',
      leadTimeNumber: price.leadTimeNumber || '',
      leadTimeUnits: price.leadTimeUnits || '',
      quantityPurchased: price.quantityPurchased || '',
      unit: price.unit || '',
      receivedOn: price.receivedAt
        ? parse(price.receivedAt, 'yyyy-MM-dd', new Date())
        : null,
      selectedManufacturer: price.manufacturer,
      selectedSupplier: price.supplier,
    } as PricingFormType);
  };

  const formatReceivedOnDate = (date: string) => {
    const [year, month, day] = date.split('-');
    const dateObject = new Date(
      (year as unknown) as number,
      ((month as unknown) as number) - 1,
      (day as unknown) as number
    );
    return format(new Date(dateObject), 'MM/dd/yy');
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} stickyHeader>
        <TableHead className={classes.headerRow}>
          <TableRow className={classes.headerRow}>
            {TABLE_HEADERS.map((header, index) => {
              return (
                <TableCell
                  className={classes.tableHeader}
                  key={`${header}-${index}`}
                  align={header.align ? header.align : 'left'}
                  style={{
                    width: header.columnWidth,
                    color: '#ffffff',
                  }}
                >
                  <Typography variant="h4">{header.headerText}</Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {prices.map((price, index) => {
            return (
              <TableRow key={index}>
                <Tooltip title={price?.supplier?.name || ''}>
                  <TableCell className={classes.tableCell}>
                    {price?.supplier?.name}
                  </TableCell>
                </Tooltip>
                <Tooltip title={price?.manufacturer?.name || ''}>
                  <TableCell className={classes.tableCell}>
                    {price?.manufacturer?.name}
                  </TableCell>
                </Tooltip>
                <Tooltip title={price?.moq + ' ' + (price.moqUnit ?? '') || ''}>
                  <TableCell className={classes.tableCell}>
                    {price?.moq} {price?.moqUnit}
                  </TableCell>
                </Tooltip>
                <Tooltip
                  title={
                    `${price?.leadTimeNumber} ${price?.leadTimeUnits}` || ''
                  }
                >
                  <TableCell className={classes.tableCell}>
                    {price?.leadTimeNumber} {price?.leadTimeUnits}
                  </TableCell>
                </Tooltip>
                <TableCell className={classes.tableCell}>
                  <Grid container alignItems="flex-end" direction="column">
                    <Grid item>
                      {price.cost
                        ? `$${price.cost.toFixed(2)}`
                        : 'Price not available'}
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.receivedOn}
                        variant="caption"
                      >
                        Received:{' '}
                        {price?.receivedAt
                          ? formatReceivedOnDate(price.receivedAt)
                          : NOT_APPLICABLE}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <CreateOutlined
                    onClick={() => {
                      initializeEditModal(price);
                      setPriceIndexToEdit(index);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
