// Libraries
import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  createStyles,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  IconButton,
  Tooltip,
  Switch,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab';
import Search from '@material-ui/icons/Search';
import { useFormikContext } from 'formik';
import { CreateOutlined } from '@material-ui/icons';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { useApi, useSnackbar } from 'hooks';
import { Inci } from '../types';
import { IRawMaterialFormValues } from './raw-material-form.component';
import { IApiResponse } from 'features/types';
// Constance
import * as Constants from 'features/constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    editInciBtn: {
      backgroundColor: theme.palette.blue.light,
      '&:hover': {
        backgroundColor: theme.palette.gray.dark,
        color: 'white',
      },
      '&:disabled': {
        backgroundColor: theme.palette.gray.light,
      },
    },
    alert: {
      marginBottom: theme.spacing(5),
    },
  })
);

interface CompositionalBreakdownInputsProps {
  handleEditSelectedInci: (inci: Maybe<Inci>) => void;
  inciInputValue: string;
  inciSearchResults: Inci[];
  selectedInci: Maybe<Inci>;
  setInciInputValue: React.Dispatch<React.SetStateAction<string>>;
  setInciSearchResults: React.Dispatch<React.SetStateAction<Inci[]>>;
  setSelectedInci: React.Dispatch<React.SetStateAction<Maybe<Inci>>>;
  clearVerifications: () => void;
}

const ALLERGEN = 'allergen';
const INCIDENTAL = 'incidental';

export const CompositionalBreakdownInputs: React.FC<CompositionalBreakdownInputsProps> = ({
  handleEditSelectedInci,
  inciInputValue,
  inciSearchResults,
  selectedInci,
  setInciInputValue,
  setInciSearchResults,
  setSelectedInci,
  clearVerifications,
}) => {
  const request = useApi();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const {
    values: { incis },
    setFieldValue,
  } = useFormikContext<IRawMaterialFormValues>();

  const [alertText, setAlertText] = useState<Maybe<string>>(undefined);
  const [showUsApiFields, setShowUsApiFields] = useState<boolean>(false);

  const handleInciSearch = async (searchTerm: string) => {
    try {
      const response: IApiResponse = await request({
        resource: `/api/v1/incis?query=${searchTerm}`,
        options: {
          settings: Constants.GET,
          scope: 'search:inci',
        },
      });
      if (response.data) {
        setInciSearchResults(
          response.data.map(({ id, attributes }) => ({ id, ...attributes }))
        );
      } else {
        throw new Error(
          response.errors
            ? response.errorMessage
            : 'There was a problem searching for Incis.'
        );
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error');
    }
  };

  const handleAutoCompleteInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // "reset" is triggered when the user selects an option from the dropdown
    if (reason === 'reset') return;

    setInciInputValue(value);
    handleInciSearch(value);
  };

  const handleInciSelection = (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => {
    if (typeof value === 'string') return;
    setSelectedInci(value);

    // Auto-set the US API Drug Name if this section is open
    showUsApiFields &&
      setUsApiInputs({
        ...usApiInputs,
        usApiDrugName: value?.usName || '',
      });
  };

  // Begin non-inci inputs
  const [numberInputs, setNumberInputs] = useState<{
    concentrationLevel: Maybe<number>;
    minPercentage: number;
    maxPercentage: number;
  }>({
    concentrationLevel: undefined,
    minPercentage: 0,
    maxPercentage: 0,
  });

  const [usApiInputs, setUsApiInputs] = useState<{
    usApiDrugName: Maybe<string>;
    usApiMin: Maybe<number>;
    usApiMax: Maybe<number>;
    usApiPurityFactor: Maybe<number>;
  }>({
    usApiDrugName: '',
    usApiMin: undefined,
    usApiMax: undefined,
    usApiPurityFactor: undefined,
  });

  const [checkboxInputs, setCheckboxInputs] = useState<{
    incidental: boolean;
    allergen: boolean;
    preservative: boolean;
  }>({ incidental: false, allergen: false, preservative: false });

  const handleUsApiInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUsApiInputs({
      ...usApiInputs,
      [event?.target.name]: Number(event?.target.value),
    });
  };

  const handleUsApiDrugNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUsApiInputs({ ...usApiInputs, usApiDrugName: event?.target.value });
  };

  const handleNumberInputsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNumberInputs({
      ...numberInputs,
      [event.target.name]: Number(event.target.value),
    });
  };

  const handleSwitchChange = () => {
    // Auto-populate the US API Drug Name field if there is an Inci name to use
    !showUsApiFields &&
      selectedInci?.usName &&
      setUsApiInputs({ ...usApiInputs, usApiDrugName: selectedInci.usName });

    // Clear US API values if user closes the US API fields
    showUsApiFields &&
      setUsApiInputs({
        usApiDrugName: undefined,
        usApiMin: undefined,
        usApiMax: undefined,
        usApiPurityFactor: undefined,
      });

    setShowUsApiFields(!showUsApiFields);
  };

  const isMinGreaterThanMax =
    numberInputs.minPercentage > numberInputs.maxPercentage;

  const isMaxLessThanMin =
    numberInputs.maxPercentage < numberInputs.minPercentage;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === INCIDENTAL && !event.target.checked) {
      setCheckboxInputs({
        ...checkboxInputs,
        incidental: false,
        allergen: false,
      });
    } else if (event.target.name === ALLERGEN) {
      setCheckboxInputs({
        ...checkboxInputs,
        incidental: true,
        allergen: event.target.checked,
      });
    } else {
      setCheckboxInputs({
        ...checkboxInputs,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const handleAddInci = () => {
    if (!selectedInci) {
      return;
    }

    // Prevent duplicate incis from being added to raw material
    if (
      incis.filter((inci) => inci.usName === selectedInci.usName).length > 0
    ) {
      setAlertText(`${selectedInci.usName} is already in this raw material!`);
      resetInputs();
      return;
    }

    const { minPercentage, maxPercentage } = numberInputs;
    const calculatedAmount = (minPercentage + maxPercentage) / 2;

    const isIncidental = checkboxInputs.incidental || checkboxInputs.allergen;

    const newInci: Inci = {
      ...selectedInci,
      ...numberInputs,
      ...checkboxInputs,
      ...usApiInputs,
      amount: calculatedAmount,
      usAmount: isIncidental ? 0 : calculatedAmount,
      euAmount: calculatedAmount,
      region: 'US',
    };
    setFieldValue('incis', [...incis, newInci]);
    resetInputs();
    clearVerifications();
  };

  const resetInputs = () => {
    setSelectedInci(undefined);
    setInciInputValue('');
    setCheckboxInputs({
      incidental: false,
      allergen: false,
      preservative: false,
    });
    setNumberInputs({
      concentrationLevel: undefined,
      minPercentage: 0,
      maxPercentage: 0,
    });
  };

  const isAddDisabled =
    !selectedInci ||
    !numberInputs.maxPercentage ||
    isMinGreaterThanMax ||
    isMaxLessThanMin ||
    // Disable the add button if we're showing US API fields, but any of them are not populated
    (showUsApiFields &&
      Object.values(usApiInputs).some((value) => !value && value !== 0));

  return (
    <>
      <Grid container item xs={12}>
        {alertText && (
          <Alert
            onClose={() => setAlertText(undefined)}
            className={classes.alert}
            color="error"
            severity="error"
          >
            {alertText}
          </Alert>
        )}
        <Grid item xs={11}>
          <Autocomplete
            id="inci"
            inputValue={selectedInci?.usName || inciInputValue}
            onInputChange={handleAutoCompleteInputChange}
            options={inciSearchResults}
            getOptionLabel={(inci) => {
              return inci?.usName || '';
            }}
            value={selectedInci?.usName || ''}
            onChange={handleInciSelection}
            freeSolo
            selectOnFocus
            renderInput={(params: any) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <Search />,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
                fullWidth
                variant="outlined"
                required
                label="INCI"
                placeholder="Search existing INCIs"
              />
            )}
          />
        </Grid>
        <Grid container alignItems="center" justifyContent="center" item xs={1}>
          <Tooltip title="Edit INCI">
            <IconButton
              color="primary"
              className={classes.editInciBtn}
              disabled={!selectedInci}
              onClick={() => handleEditSelectedInci(selectedInci)}
            >
              <CreateOutlined
                style={{
                  cursor: 'pointer',
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={6}
          alignItems="baseline"
          style={{ flexWrap: 'nowrap' }}
        >
          <Grid item xs={5}>
            <TextField
              name="minPercentage"
              label="Range Min (%)"
              variant="outlined"
              type="number"
              inputProps={{ min: 0 }}
              value={numberInputs.minPercentage}
              onFocus={(event) => event.target.select()} // lets the user easily overwrite the 0 as the default input value
              onChange={handleNumberInputsChange}
              required
              error={isMinGreaterThanMax}
              helperText={isMinGreaterThanMax ? 'Min cannot exceed max' : ''}
              FormHelperTextProps={{ style: { whiteSpace: 'nowrap' } }}
            />
          </Grid>
          <Grid
            container
            justifyContent="center"
            style={{ position: 'relative' }}
            item
            xs={1}
          >
            <span style={{ position: 'absolute', top: -4 }}>-</span>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Range Max (%)"
              variant="outlined"
              type="number"
              name="maxPercentage"
              value={numberInputs.maxPercentage}
              onFocus={(event) => event.target.select()}
              onChange={handleNumberInputsChange}
              required
              error={isMaxLessThanMin}
              helperText={isMaxLessThanMin ? 'Max cannot be less than min' : ''}
              inputProps={{ min: 0 }}
              FormHelperTextProps={{ style: { whiteSpace: 'nowrap' } }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Concentration"
            variant="outlined"
            type="number"
            name="concentrationLevel"
            value={numberInputs.concentrationLevel}
            onFocus={(event) => event.target.select()}
            onChange={handleNumberInputsChange}
            inputProps={{ min: 0 }}
            InputProps={{
              startAdornment: <div className="text-gray mr-2">X</div>,
            }}
          />
        </Grid>
        <Grid container item xs={12}>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                label="Incidental"
                control={
                  <Checkbox
                    name="incidental"
                    onChange={handleCheckboxChange}
                    checked={checkboxInputs.incidental}
                    color="default"
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                label="Allergen"
                control={
                  <Checkbox
                    name="allergen"
                    onChange={handleCheckboxChange}
                    checked={checkboxInputs.allergen}
                    color="default"
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                label="Preservative"
                control={
                  <Checkbox
                    name="preservative"
                    onChange={handleCheckboxChange}
                    checked={checkboxInputs.preservative}
                    color="default"
                  />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Grid item>
          <FormControlLabel
            label={'US API'}
            control={<Switch color="primary" onChange={handleSwitchChange} />}
          />
        </Grid>
      </Grid>
      {showUsApiFields && (
        <>
          <Grid xs={12} item>
            <TextField
              label="US API Drug Name"
              name="usApiDrugName"
              fullWidth
              variant="outlined"
              value={usApiInputs.usApiDrugName}
              onChange={handleUsApiDrugNameChange}
            />
          </Grid>
          <Grid xs={12} container item spacing={4}>
            <Grid item xs={3}>
              <TextField
                label="US API Min"
                type="number"
                name="usApiMin"
                variant="outlined"
                value={usApiInputs.usApiMin}
                onChange={handleUsApiInputChange}
                inputProps={{
                  step: '0.1',
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="US API Max"
                name="usApiMax"
                variant="outlined"
                value={usApiInputs.usApiMax}
                onChange={handleUsApiInputChange}
                type="number"
                inputProps={{
                  step: '0.1',
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="US API Purity Factor"
                name="usApiPurityFactor"
                variant="outlined"
                value={usApiInputs.usApiPurityFactor}
                onChange={handleUsApiInputChange}
                type="number"
                inputProps={{
                  step: '0.1',
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Button
            color="default"
            onClick={handleAddInci}
            disabled={isAddDisabled}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
