// Libraries
import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import {
  ChevronRight,
  DescriptionOutlined,
  FolderOutlined,
} from '@material-ui/icons';
import { BoltIcon } from '@heroicons/react/24/outline';
// Components
import { StartNewProjectButton } from './start-new-project-button.component';
import { ProjectTitleAndTags } from '../ui';
import { InviteTeamMemberButton } from './invite-team-member-button.component';
// Utils
import { IApiData, ProjectAttributes } from 'api';
import { useAnalytics } from 'hooks';
import { renderTargetCost } from '../utils';
// Constants
import { AnalyticsConstants } from 'features/analytics';
import {
  NOT_APPLICABLE,
  PROJECT_TYPES_TO_HIDE,
  ARCHIVED_GROUP_NAME,
  ON_HOLD_GROUP_NAME,
  ORDER_STATUS,
} from '../constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { ProjectType } from '../types';

interface IProjectsTable {
  companyName: string;
  isEmployee: boolean;
  isLoading: boolean;
  isMobile?: boolean;
  projects: Array<IApiData<ProjectAttributes>>;
}

export const ProjectsTable: React.FC<IProjectsTable> = ({
  companyName,
  isEmployee,
  isLoading,
  isMobile,
  projects,
}) => {
  const analytics = useAnalytics()!;
  const navigate = useNavigate();
  const [showOnHoldProjects, setShowOnHoldProjects] = useState(false);
  const [showArchivedProjects, setShowArchivedProjects] = useState(false);

  const OPEN_STATUSES = [
    'Not Started',
    'In Progress',
    'Pending',
    'Working on it',
  ];
  const DONE_STATUSES = ['Done', 'Waived'];
  const R_AND_D_TASKS = [
    'Ingredient Listing Creation',
    'Formulation',
    'Sample Shipped',
  ];
  const PRODUCTION_TASKS = [
    'Order Shipped',
    'Production',
    'Final Payment Received',
  ];

  type ProjectGroup = {
    action?: () => void;
    additionalInstructions?: string;
    isDisabled: boolean;
    isShowing: boolean;
    projects: IApiData<ProjectAttributes>[];
    title?: string;
  };

  const categorizeProjects = (projects: IApiData<ProjectAttributes>[]) => {
    const inProductionProjects: ProjectGroup = {
      isDisabled: false,
      isShowing: true,
      projects: [],
      title: 'In Production / Completed',
    };
    const rAndDFormulationProjects: ProjectGroup = {
      isDisabled: false,
      isShowing: true,
      projects: [],
    };
    const openProjects: ProjectGroup = {
      isDisabled: false,
      isShowing: true,
      projects: [],
      title: 'In progress',
    };
    const onHoldProjects: ProjectGroup = {
      action: () => setShowOnHoldProjects(!showOnHoldProjects),
      additionalInstructions:
        'To move projects out of the “On Hold” section, contact your customer representative.',
      isDisabled: true,
      isShowing: showOnHoldProjects,
      projects: [],
      title: 'On Hold',
    };
    const archivedProjects: ProjectGroup = {
      projects: [],
      title: 'Archived',
      additionalInstructions:
        'To unarchive a project, contact your customer representative.',
      isDisabled: true,
      action: () => setShowArchivedProjects(!showArchivedProjects),
      isShowing: showArchivedProjects,
    };

    projects.forEach((project) => {
      if (project.attributes.mondayProductDevGroup === ON_HOLD_GROUP_NAME) {
        onHoldProjects.projects.push(project);
        return;
      }
      if (project.attributes.mondayProductDevGroup === ARCHIVED_GROUP_NAME) {
        archivedProjects.projects.push(project);
        return;
      }
      if (
        project.parsedIncluded?.tasks?.some(
          (task: any) =>
            PRODUCTION_TASKS.includes(task.attributes.name) &&
            DONE_STATUSES.includes(task.attributes.status)
        )
      ) {
        inProductionProjects.projects.push(project);
      } else {
        openProjects.projects.push(project);
      }
      // The 'Testing' and 'Procurement' phases also count as R&D. When a project reaches 'In Production', this
      // conditional will not be reached.
      if (
        project.parsedIncluded?.tasks?.some(
          (task: any) =>
            R_AND_D_TASKS.includes(task.attributes.name) &&
            (OPEN_STATUSES.includes(task.attributes.status) ||
              DONE_STATUSES.includes(task.attributes.status))
        )
      ) {
        rAndDFormulationProjects.projects.push(project);
      }
    });
    return {
      inProductionProjects,
      rAndDFormulationProjects,
      openProjects,
      onHoldProjects,
      archivedProjects,
    };
  };

  const {
    openProjects,
    rAndDFormulationProjects,
    inProductionProjects,
    onHoldProjects,
    archivedProjects,
  } = categorizeProjects(projects);

  const OVERVIEW_SECTIONS = [
    {
      title: 'Open Projects',
      count: openProjects.projects.length,
    },
    {
      title: 'R&D Formulation',
      count: rAndDFormulationProjects.projects.length,
    },
    {
      title: 'In Production',
      count: inProductionProjects.projects.length,
    },
  ];

  const navigateToProject = (
    event: any,
    id: string,
    projectName: string,
    analyticsLabel: string,
    tabNumber: number = 0
  ) => {
    event.stopPropagation();
    const projectUrl = isEmployee
      ? `${ROUTES.EMPLOYEE_VIEW_CUSTOMER_PROJECT.route.replace(
          UUID_SHOW_ROUTE_STRING,
          id
        )}?tab=${tabNumber}`
      : `${ROUTES.SHOW_CUSTOMER_PROJECT_V2.route.replace(
          UUID_SHOW_ROUTE_STRING,
          id
        )}?tab=${tabNumber}`;
    analytics.trackEvent({
      eventCategory: AnalyticsConstants.EVENT_CATEGORIES.CBDashboard,
      eventAction: AnalyticsConstants.EVENT_ACTIONS.click,
      eventLabel: `${analyticsLabel} - ${companyName} - ${projectName}`,
    });

    navigate(projectUrl);
  };

  const renderTableOverviewRow = () => {
    return (
      <div className="grid grid-cols-12 sm:gap-3 mb-3 sm:mb-10">
        {OVERVIEW_SECTIONS.map((section, index) => {
          const isEdgeElement =
            index === 0 || index === OVERVIEW_SECTIONS.length - 1;
          return (
            <div
              key={`${section.title}-${index}`}
              className={`col-span-4 flex flex-col px-6 py-4 bg-lightPeach ${
                isEdgeElement ? (index === 0 ? 'rounded-l' : 'rounded-r') : ''
              } sm:rounded items-start`}
            >
              <div className="font-mono uppercase text-left text-xs font-medium">
                {section.title}
              </div>
              <div className="mt-1 font-inter text-xl text-darkGray">
                {section.count}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderProjectDetails = (
    project: IApiData<ProjectAttributes>,
    isDisabled: boolean
  ) => {
    const projectDetailHeaders = [
      {
        name: 'UNITS',
        value:
          project.parsedIncluded.brief.attributes.minimumOrderQuantity?.toLocaleString() ||
          NOT_APPLICABLE,
      },
      {
        name: 'TARGET COST',
        value: renderTargetCost(project.parsedIncluded.brief.attributes),
      },
      ...(!isMobile
        ? [
            {
              name: 'TARGET DATE',
              value: project.parsedIncluded.brief.attributes.targetDate
                ? new Date(
                    project.parsedIncluded.brief.attributes.targetDate
                  )?.toLocaleDateString('en-US', { timeZone: 'UTC' })
                : NOT_APPLICABLE,
            },
          ]
        : []),
    ];

    return (
      <div className="w-full grid grid-cols-2 sm:grid-cols-3 sm:items-center mb-5 sm:mb-0">
        {projectDetailHeaders.map((header, index) => {
          return (
            <div
              key={`${header.name}-${index}`}
              className={`flex flex-col items-start sm:px-6 py-1 ${
                index !== 0 && isMobile ? 'border-l px-6' : 'pr-6'
              } sm:border-l border-lighterGray`}
            >
              <div className="whitespace-nowrap uppercase font-mono font-medium text-xs text-lightGray">
                {header.name}
              </div>
              <div
                className={`font-inter text-lg ${
                  isDisabled ? 'text-lightGray' : 'text-darkGray'
                }`}
              >
                {header.value}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderButtonArray = (
    project: IApiData<ProjectAttributes>,
    hasTasks: boolean,
    isDisabled: boolean
  ) => {
    return (
      <div className="flex flex-col sm:ml-auto">
        {hasTasks && (
          <button
            disabled={isDisabled}
            onClick={(event) => {
              navigateToProject(
                event,
                project.id,
                project.attributes.name,
                'Track progress'
              );
            }}
            className={`${
              isDisabled
                ? 'bg-lighterGray text-lightGray'
                : 'bg-darkGray text-white hover:bg-blue-60'
            } flex mb-1 px-4 py-[14px] text-base font-display items-center rounded`}
          >
            <BoltIcon width="20" height="20" className="mr-2" />
            Track progress
          </button>
        )}
        <div className="flex">
          <button
            disabled={isDisabled}
            onClick={(event) =>
              navigateToProject(
                event,
                project.id,
                project.attributes.name,
                'View brief',
                hasTasks ? 2 : 1
              )
            }
            className={`${
              isDisabled
                ? 'text-lightGray bg-lighterGray'
                : 'text-darkGray bg-lightestGray'
            } flex w-1/2 mr-[2px] px-4 py-[14px] text-base font-display items-center rounded`}
          >
            <DescriptionOutlined
              className={`mr-2 ${isDisabled ? 'text-lightGray' : ''}`}
            />
            Brief
          </button>
          <button
            disabled={isDisabled}
            onClick={(event) =>
              navigateToProject(
                event,
                project.id,
                project.attributes.name,
                'View files',
                hasTasks ? 1 : 0
              )
            }
            className={`${
              isDisabled
                ? 'text-lightGray bg-lighterGray'
                : 'text-darkGray bg-lightestGray'
            } flex w-1/2 ml-[2px] px-4 py-[14px]  text-base font-display items-center rounded`}
          >
            <FolderOutlined
              className={`mr-2 ${isDisabled ? 'text-lightGray' : ''}`}
            />
            Files
          </button>
        </div>
      </div>
    );
  };

  const renderProjectRows = (projectsToRender: ProjectGroup) => {
    const {
      action,
      additionalInstructions,
      isDisabled,
      isShowing,
      projects,
      title,
    } = projectsToRender;
    if (projects.length === 0) return null;
    return (
      <div className="mb-6 text-left">
        <div className="text-lightGray text-sm mb-3">
          {title}{' '}
          {isDisabled ? (
            <>
              ({projects.length}){' '}
              <span onClick={action} className="text-blue-60 cursor-pointer">
                {isShowing ? 'Hide' : 'Show'}
              </span>
            </>
          ) : (
            ''
          )}
        </div>
        {isShowing && (
          <div className="text-black text-sm mb-3">
            {additionalInstructions}
          </div>
        )}
        {isShowing &&
          projects.map((project, index) => {
            const hasTasks = project?.parsedIncluded?.tasks?.length > 0;
            const hasPaidOrder =
              project?.parsedIncluded?.order?.attributes?.status ===
              ORDER_STATUS.paid;
            // Some project types are not to be displayed to customers (also exclude rtl projects without paid orders)
            if (
              PROJECT_TYPES_TO_HIDE.includes(
                project.attributes?.projectType || ''
              ) ||
              (project.attributes?.projectType === ProjectType.rtl &&
                !hasPaidOrder)
            )
              return null;
            return (
              <div
                key={`${project.id}-${index}`}
                className="flex flex-col sm:grid sm:grid-cols-12 sm:gap-6 gap-5 sm:items-center sm:justify-between mb-3 py-7 px-6 sm:pl-6 sm:pr-3 border border-lighterGray rounded"
              >
                <div className="sm: col-span-5">
                  <ProjectTitleAndTags
                    isDisabled={isDisabled}
                    project={project}
                  />
                </div>
                <div className="sm:col-span-7 flex flex-col sm:flex-row">
                  {renderProjectDetails(project, isDisabled)}
                  {renderButtonArray(project, hasTasks, isDisabled)}
                  {!isMobile && (
                    <div
                      className={`flex items-center ml-3 ${
                        !isDisabled && 'cursor-pointer'
                      }`}
                    >
                      <ChevronRight
                        onClick={(event) => {
                          !isDisabled &&
                            navigateToProject(
                              event,
                              project.id,
                              project.attributes.name,
                              'Track progress'
                            );
                        }}
                      />
                    </div>
                  )}
                </div>
                {/*TODO: Reinstate this when we have info and error logic*/}
                {/* {renderCtas(project)} */}
              </div>
            );
          })}
      </div>
    );
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const navigateToBrief = () => {
    analytics.trackEvent({
      eventCategory: AnalyticsConstants.EVENT_CATEGORIES.CBDashboard,
      eventAction: AnalyticsConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticsConstants.EVENT_LABEL.startNewProject,
    });
    navigate(ROUTES.CREATE_BRIEF.route);
  };

  return (
    <>
      {renderTableOverviewRow()}
      {isMobile && (
        <div className="mb-6">
          <div className="mb-2">
            <StartNewProjectButton fullWidth />
          </div>
          <div>
            <InviteTeamMemberButton fullWidth />
          </div>
        </div>
      )}
      {projects.length > 0 ? (
        <>
          {renderProjectRows(openProjects)}
          {renderProjectRows(inProductionProjects)}
          {renderProjectRows(onHoldProjects)}
          {renderProjectRows(archivedProjects)}
        </>
      ) : (
        <div className="flex bg-lightestGray sm:items-center sm:justify-between mb-3 py-14 px-6 sm:pl-6 sm:pr-3 border border-lighterGray rounded">
          <span
            onClick={() => navigateToBrief()}
            className="m-auto cursor-pointer underline text-sm"
          >
            Start a new project
          </span>
        </div>
      )}
    </>
  );
};
