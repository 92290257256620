// Libraries
import React, { useContext, useEffect, useState } from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
// Components
import { AccordionButton } from 'features/ui';
import { FormulaContext } from '../context';
import { MoreActionsMenu } from './more-actions-menu.component';
import { MoreActionsIconButton } from '../ui';
import { QuickAddRawMaterialModal } from './quick-add-raw-material-modal.component';
import { TotalCostSummary } from './total-cost-summary.component';
// Utils
import {
  ActionsMenuItem,
  ExtendedFormulaAttributes,
  Ingredient,
} from './types';
import { ITheme, THEME_PALETTE } from 'styles/mui/themeV2';
import { useSnackbar } from 'hooks';
import { useApi, IApiData, PricingQuoteAttributes } from 'api';
import { BatchQuantityContext } from '../context';

interface IngredientsTableSummaryRowProps {
  ingredients: Ingredient[];
  selectedPricingUnit: string;
  setSelectedPricingUnit: React.Dispatch<React.SetStateAction<string>>;
  handleExpandAll: () => void;
  isAllExpanded: boolean;
  isLocked: boolean;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    subHeaderRow: {
      backgroundColor: theme.palette.tan.light,
      position: 'sticky',
      top: 46,
      zIndex: 100,
    },
    tableCell: {
      padding: '0.5rem 1rem',
      borderBottom: 'none',
    },
    // styling specifically for right aligning weight input text
    tableInputCell: {
      padding: '0.5rem 1rem',
      paddingRight: '0',
      borderBottom: 'none',
    },
    textField: {
      backgroundColor: theme.palette.secondary.main,
    },
    // styling specifically for right aligning weight input text
    textFieldInput: {
      textAlign: 'right',
    },
  })
);
const WEIGHT_PERCENT_PRECISION = 4;

export const IngredientsTableSubHeaderRow: React.FC<IngredientsTableSummaryRowProps> = ({
  ingredients,
  selectedPricingUnit,
  setSelectedPricingUnit,
  handleExpandAll,
  isAllExpanded,
  isLocked,
}) => {
  const classes = useStyles();
  const { getFormulaPriceQuote, postPhase } = useApi();
  const { formula, setFormula, openAddIngredientModal } = useContext(
    FormulaContext
  );
  const { showSnackbar } = useSnackbar();
  const { batchQuantity, setBatchQuantity } = useContext(BatchQuantityContext);
  const [pricingQuote, setPricingQuote] = useState<
    Maybe<IApiData<PricingQuoteAttributes>>
  >(undefined);
  const [isQuickAddDialogOpen, setIsQuickAddDialogOpen] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (!formula) return;

    getFormulaPriceQuote({
      urlParams: `${formula.id}/pricing_quotes`,
      handleSuccess: setPricingQuote,
    });
  }, [getFormulaPriceQuote, formula]);

  const menuItems: ActionsMenuItem[] = [
    {
      label: 'Quick Add Raw Material',
      onClick: () => setIsQuickAddDialogOpen(true),
    },
    {
      label: 'Add Ingredient',
      onClick: () => {
        if (isLocked) return;

        openAddIngredientModal();
      },
    },
    {
      label: 'Add Phase',
      onClick: () => {
        postPhase({
          data: { phase: { formulaUuid: formula?.id } },
          handleSuccess: (data: any) => {
            showSnackbar(`Phase ${data.attributes.name} added`, 'success');
            setFormula({
              ...formula,
              phases: [
                ...(formula?.phases || []),
                {
                  id: data.id,
                  ...data.attributes,
                  ingredients: [],
                },
              ],
            } as ExtendedFormulaAttributes);
          },
        });
      },
    },
  ];

  const totalPercentage = ingredients
    ?.reduce(
      (accumulator, currentIngredient) =>
        accumulator + currentIngredient.amount,
      0
    )
    // this fixes issues with floating point addition 99.9999999999 becomes 100.00000
    .toFixed(WEIGHT_PERCENT_PRECISION);

  const getTotalColor = () => {
    // converts the toFixed string back to a number that can be properly compared
    // 99.999999 -> "100.00000" -> 100.00
    const preciseTotal = parseFloat(totalPercentage);

    if (preciseTotal === 100) return '#14b8a6';
    if (preciseTotal > 100) return '#dc2626';
    return THEME_PALETTE.gray.dark;
  };

  return (
    <TableRow className={classes.subHeaderRow}>
      <QuickAddRawMaterialModal
        isOpen={isQuickAddDialogOpen}
        handleClose={() => setIsQuickAddDialogOpen(false)}
      />
      <TableCell colSpan={1}>
        <MoreActionsMenu menuItems={menuItems}>
          {({ anchor, toggleMenu }) => (
            <MoreActionsIconButton
              disabled={formula?.locked}
              ref={anchor}
              onClick={toggleMenu}
            />
          )}
        </MoreActionsMenu>
      </TableCell>
      <TableCell
        className={classes.tableCell}
        colSpan={1}
        style={{ paddingLeft: 0 }}
      >
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          item
          xs={12}
        >
          <Grid item xs={12}>
            <AccordionButton
              onClick={handleExpandAll}
              expanded={isAllExpanded}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell colSpan={1} />
      <TableCell />
      <TableCell
        className={classes.tableCell}
        align="right"
        colSpan={4}
        style={{ paddingRight: 0 }}
      >
        <Grid container>
          <TotalCostSummary
            ingredients={ingredients}
            pricingQuote={pricingQuote}
            selectedPricingUnit={selectedPricingUnit}
            setSelectedPricingUnit={setSelectedPricingUnit}
          ></TotalCostSummary>
        </Grid>
      </TableCell>
      <TableCell
        align="right"
        className={classes.tableCell}
        style={{ color: getTotalColor() }}
      >
        <Typography variant="body1">{totalPercentage} %</Typography>
      </TableCell>
      <TableCell align="right" className={classes.tableInputCell} colSpan={1}>
        <TextField
          type="number"
          className={classes.textField}
          variant="outlined"
          inputProps={{ className: classes.textFieldInput, min: 0 }}
          size="medium"
          value={batchQuantity}
          onChange={(e) => setBatchQuantity(parseFloat(e.target.value))}
        />
      </TableCell>
      <TableCell colSpan={1} />
    </TableRow>
  );
};
