// Libraries
import { createContext } from 'react';
// Utils
import { ConfirmState } from './types';

export const ConfirmationContext = createContext<{
  confirmState: ConfirmState;
  setConfirmState: React.Dispatch<React.SetStateAction<ConfirmState>>;
}>({
  confirmState: {
    prompt: '',
    description: '',
    isOpen: false,
  },
  setConfirmState: () => {},
});
