// Libraries
import { useSearchParams } from 'react-router-dom';
// Constants
import { FORMULA_ID } from '../constants';

export const usePricingQuoteParams = () => {
  const [searchParams] = useSearchParams();
  const pricingQuoteSearchParams = Object.fromEntries(searchParams.entries());

  // If a formulaId is present in the URL on first render, this useEffect sets the dropdown to be that
  // formula
  const hasFormulaParam = Object.keys(pricingQuoteSearchParams).includes(
    FORMULA_ID
  );
  return {
    hasFormulaParam,
    pricingQuoteSearchParams,
  };
};
