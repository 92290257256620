import { SampleFeedbackStatus } from 'features/home/customer-portal/types';
import { SampleFeedbackFormValues } from './types';

export const determineIfAllApproved = (
  values: SampleFeedbackFormValues
): boolean => {
  for (const key in values) {
    if (
      key.includes('Feedback') &&
      values[key] !== SampleFeedbackStatus.approved
    ) {
      return false;
    }
  }

  return true;
};
