// Libraries
import React, { useState } from 'react';
import { createStyles, makeStyles, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
// Components
import { CompositionalBreakdownInputs } from './compositional-breakdown-inputs.component';
import { CompositionalBreakdownTable } from './compositional-breakdown-table.component';
import { AddOrEditInciModal } from './add-or-edit-inci-modal.component';
import { EditCompBreakdownModal } from './edit-comp-breakdown-modal.component';
import { FormSection } from 'features/ui';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { Inci } from '../types';
import { IRawMaterialFormValues } from './raw-material-form.component';
import { useFormikContext } from 'formik';

const COMPOSITIONAL_BREAKDOWN = 'Compositional Breakdown';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    callToAction: { paddingRight: theme.spacing(8) },
    createNewInciCTA: { cursor: 'pointer' },
    row: {
      marginBottom: theme.spacing(8),
    },
    inputsRow: {
      marginBottom: theme.spacing(4),
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);

interface CompositionalBreakdownSectionProps {
  rawMaterialUuid?: string;
}

export const CompositionalBreakdownSection: React.FC<CompositionalBreakdownSectionProps> = ({
  rawMaterialUuid,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<IRawMaterialFormValues>();

  // Search State
  const [inciInputValue, setInciInputValue] = useState<string>('');
  const [inciSearchResults, setInciSearchResults] = useState<Inci[]>([]);
  const [selectedInci, setSelectedInci] = useState<Maybe<Inci>>();

  // Edit modal state
  const [
    isEditCompBreakdownModalOpen,
    setIsEditCompBreakdownModalOpen,
  ] = useState<boolean>(false);
  const [nameOfInciToEdit, setNameOfInciToEdit] = useState<string>('');

  // Create/Edit Global Inci modal state
  const [
    isCreateOrEditInciModalOpen,
    setIsCreateOrEditInciModalOpen,
  ] = useState<boolean>(false);
  const [inciToEdit, setInciToEdit] = useState<Maybe<Inci>>();

  const handleEditSelectedInci = (inci: Maybe<Inci>) => {
    setInciToEdit(inci);
    setIsCreateOrEditInciModalOpen(true);
  };

  const clearVerifications = () => {
    if (!rawMaterialUuid) return;

    setFieldValue('verification', {
      ...values.verification,
      clearVerificationsUsIncis: true,
      clearVerificationsEuIncis: true,
      usIncisVerified: undefined,
      usInciFactorsVerified: undefined,
      euIncisVerified: undefined,
      euInciFactorsVerified: undefined,
      usIncisVerifiedBy: undefined,
      usInciFactorsVerifiedBy: undefined,
      euIncisVerifiedBy: undefined,
      euInciFactorsVerifiedBy: undefined,
    });
  };

  const clearRegionVerifications = (region: string) => {
    if (!rawMaterialUuid) return;

    const lowerCaseRegion = region.toLowerCase();
    const lowerCaseSecondLetterRegion =
      region.charAt(0) + region.charAt(1).toLowerCase();

    setFieldValue(
      `verification.clearVerifications${lowerCaseSecondLetterRegion}Incis`,
      true
    );
    setFieldValue(`verification.${lowerCaseRegion}IncisVerified`, undefined);
    setFieldValue(
      `verification.${lowerCaseRegion}InciFactorsVerified`,
      undefined
    );
    setFieldValue(`verification.${lowerCaseRegion}IncisVerifiedBy`, undefined);
    setFieldValue(
      `verification.${lowerCaseRegion}InciFactorsVerifiedBy`,
      undefined
    );
  };

  return (
    <FormSection
      callToAction={
        <Grid
          onClick={() => setIsCreateOrEditInciModalOpen(true)}
          container
          item
          xs={6}
          justify="flex-end"
          className={classes.callToAction}
        >
          <Grid
            container
            item
            xs={5}
            className={classes.createNewInciCTA}
            justify="flex-end"
          >
            <AddIcon />
            <Typography variant="body1" noWrap>
              Create new INCI
            </Typography>
          </Grid>
        </Grid>
      }
      sectionHeader={COMPOSITIONAL_BREAKDOWN}
      isRevealed={true}
      setIsRevealed={() => null}
    >
      {isCreateOrEditInciModalOpen && (
        <AddOrEditInciModal
          closeModal={() => setIsCreateOrEditInciModalOpen(false)}
          inciToEdit={inciToEdit}
          isOpen={isCreateOrEditInciModalOpen}
          setInciToEdit={setInciToEdit}
          setSelectedInci={setSelectedInci}
        />
      )}
      {isEditCompBreakdownModalOpen && (
        <EditCompBreakdownModal
          nameOfInciToEdit={nameOfInciToEdit}
          open={isEditCompBreakdownModalOpen}
          setIsEditCompBreakdownModalOpen={setIsEditCompBreakdownModalOpen}
          clearVerifications={clearVerifications}
        />
      )}
      <Grid container item spacing={5} xs={12} className={classes.inputsRow}>
        <CompositionalBreakdownInputs
          handleEditSelectedInci={handleEditSelectedInci}
          inciInputValue={inciInputValue}
          inciSearchResults={inciSearchResults}
          selectedInci={selectedInci}
          setInciInputValue={setInciInputValue}
          setInciSearchResults={setInciSearchResults}
          setSelectedInci={setSelectedInci}
          clearVerifications={clearVerifications}
        />
      </Grid>
      <Grid container item spacing={5} xs={12} className={classes.row}>
        <CompositionalBreakdownTable
          setIsEditCompBreakdownModalOpen={setIsEditCompBreakdownModalOpen}
          setNameOfInciToEdit={setNameOfInciToEdit}
          handleEditSelectedInci={handleEditSelectedInci}
          clearRegionVerifications={clearRegionVerifications}
        />
      </Grid>
    </FormSection>
  );
};
