// Libraries
import * as Yup from 'yup';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from './price-quote.constants';

export const priceQuoteValidationSchema = Yup.object().shape({
  [PRICE_QUOTE_FORM_KEYS.QUOTE_DATE]: Yup.date()
    .typeError('Please enter a valid quote date (mm/dd/yy)')
    .min(new Date(), 'Quote date must be in the future')
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
      'Quote date must be within the next 10 years'
    )
    .required('Quote date is required')
    .label('Quote Date'),
  [PRICE_QUOTE_FORM_KEYS.VALID_FOR]: Yup.number()
    .required('Please enter a valid number of days')
    .label('Valid for'),
  [PRICE_QUOTE_FORM_KEYS.PAYMENT_TERMS]: Yup.string()
    .required('Please enter valid payment terms')
    .label('Payment Terms'),
  [PRICE_QUOTE_FORM_KEYS.INCOTERMS]: Yup.string().required().label('Incoterms'),
  [PRICE_QUOTE_FORM_KEYS.DELIVERY_TERMS]: Yup.string()
    .required('Please enter valid incoterms')
    .label('Delivery Terms'),
  [PRICE_QUOTE_FORM_KEYS.FORMULA_UUID]: Yup.string()
    .required('Please enter a valid formula number')
    .label('Formula Number'),
  [PRICE_QUOTE_FORM_KEYS.MOQ_ONE]: Yup.number()
    .required('Please enter a valid MOQ')
    .label('Order Quantity 1 (MOQ)'),
  [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_QUANTITY]: Yup.number()
    .required('Please enter at least one size')
    .label('Size One'),
  [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_ONE_TOTAL_COST_PER_UNIT]: Yup.number()
    .required('Please enter a valid CPU')
    .label('Total'),
});
