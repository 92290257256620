/* These correspond to the list of available commands to the GA function
https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference */
export const COMMANDS = {
  create: 'create',
  set: 'set',
  send: 'send',
  require: 'require',
  provide: 'provide',
  remove: 'remove',
};

/* These correspond to the hit types for the send command
  https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference#send */
export const HIT_TYPES = {
  pageView: 'pageview',
  event: 'event',
  social: 'social',
  screenView: 'screenview',
  timing: 'timing',
  exception: 'exception',
};

/* Once these (category, action, & label) are set they should not be changed
to keep event tracking data historically uniform. See anatomy of an event for more information:
https://support.google.com/analytics/answer/1033068

  All three fields follow the following syntax *note capitalization:
    category - "Feature-Element-SubElement (if exists)"
    action - "Element-Action"
    label - "Element-ElementName"

    example: {
      category: "Brief-Form",
      action: "Input-Change",
      label: "Input-givenName"
   }
*/
export const EVENT_CATEGORIES: { [x: string]: any } = {
  briefForm: 'Brief-Form',
  briefFormCountrySelect: 'Brief-Form-CountrySelect',
  customerSignup: 'Customer-Signup',
  reportGeneration: 'Reports',
  formulas: 'Formulas',
  pricingCalculator: 'Pricing-Calculator',
  // CUSTOM BRIEF CATEGORIES
  CBSplash: 'CB Splash',
  CBDashboard: 'CB Dashboard',
  CBDividerProjectBasics: 'CB Divider - Project Basics',
  CBDividerMarketStrategy: 'CB Divider - Market strategy',
  CBDividerProjectRequirements: 'CB Divider - Project requirements',
  CBQuestion: 'CB Question',
  CBSubmissionSuccess: 'CB Submission Success',
  // CUSTOMER PORTAL CATEGORIES
  CPProjectTracker: 'CP Tracker',
  // MASS CUSTOMIZATION CATEGORIES
  MCTopNav: 'MC Top Nav',
  MCFooter: 'MC Footer',
  MCLandingPage: 'MC Landing Page',
  MCOutboundLink: 'MC Outbound Link',
  MCChooseBase: 'MC Choose Base',
  MCChooseCategory: 'MC Choose Category',
  MCSkinCareExtras: 'MC Skin Extras',
  MCSunCareExtras: 'MC Sun Extras',
  MCBodyCareExtras: 'MC Body Extras',
  MCHairCareExtras: 'MC Hair Extras',
  MCSkinCareGoals: 'MC Skin Goals',
  MCSunCareGoals: 'MC Sun Goals',
  MCBodyCareGoals: 'MC Body Goals',
  MCHairCareGoals: 'MC Hair Goals',
  MCNotes: 'MC Notes',
  MCName: 'MC Name',
  MCCongratsSummary: 'MC Congrats Summary',
  MCShoppingCart: 'MC Shopping Cart',
  MCConfirmation: 'MC Confirmation',
  MCNextButton: 'MC Next Button',
  MCBackButton: 'MC Back Button',
  // QUIZ CATEGORIES
  ProductCategory: 'Quiz-ProductCategory',
  'Skin Care': 'Quiz-Products-SkinCare',
  'Sun Care': 'Quiz-Products-SunCare',
  'Body Care': 'Quiz-Products-BodyCare',
  'Hair Care': 'Quiz-Products-HairCare',
  Fragrance: 'Quiz-Products-Fragrance',
  ProductSize: 'Quiz-ProductSize',
  Moq: 'Quiz-MOQ',
  RdInvestment: 'Quiz-RdInvestment',
  QuizRecommendation: 'Quiz-Recommendation',
  // SAMPLE FEEDBACK
  sampleFeedbackModal: 'SampleFeedback-Modal',
  sampleFeedbackCheckboxes: 'SampleFeedback-Checkboxes',
  sampleFeedbackInputs: 'SampleFeedback-Inputs',
  // FILE UPLOAD
  fileUploadBtn: 'File-Upload-Button',
  fileDeleteBtn: 'File-Delete-Button',
  fileDownloadBtn: 'File-Download-Button',
};
export const EVENT_ACTIONS = {
  inputChange: 'Input-Change',
  multiSelectChange: 'Multi-Select-Change',
  selectChange: 'Select-Change',
  formSubmit: 'Form-Submit',
  signup: 'Signup',
  generate: 'Generate',
  duplicate: 'Duplicate',
  click: 'click',
  checkboxCheck: 'Checkbox-Check',
  checkboxUncheck: 'Checkbox-Uncheck',
  hover: 'hover',
};
export const EVENT_LABEL = {
  // GENERIC INPUT LABELS
  input: (name: string) => `Input-${name}`,
  select: (name: string) => `Select-${name}`,
  checkbox: (name: string) => `Checkbox-${name}`,
  // CUSTOM BRIEF LABELS
  getStarted: 'Get Started',
  startNewProject: 'Start new project',
  cbNext: (question: string) => `Next from: ${question}`,
  cbBack: (question: string) => `Back from: ${question}`,
  cbSubmissionYes: 'Yes',
  cbSubmissionNo: 'No',
  // MASS CUSTOMIZATION LABELS
  primeMatterLabs: 'Prime Matter Labs',
  launchYourProduct: 'Launch Your Product',
  shoppingCart: 'Shopping Cart',
  termsOfUse: 'Terms of use',
  privacyPolicy: 'Privacy Policy',
  returnPolicy: 'Return Policy',
  buildYourProductOne: 'Build your product 1',
  buildYourProductTwo: 'Build your product 2',
  finishBuildingProduct: 'Finish building product',
  addToCart: 'Add to Cart',
  removeCircle: 'Remove circle',
  checkout: 'Checkout',
  createNewProduct: 'Create new product',
  buildYourSample: 'Build your sample',
  backToProductBuilder: 'Back to Product Builder',
  // CUSTOMER PORTAL LABELS
  collapsibleSection: 'Section',
  // SAMPLE FEEDBACK
  openFeedbackModal: 'Open Feedback Modal',
  // FILE UPLOAD
  employeeUploadFile: 'Employee Upload File',
  customerUploadFile: 'Customer Upload File',
  deleteFile: 'Delete File',
  employeeFileDownload: 'Employee File Download',
  customerFileDownload: 'Customer File Download',
};

export const GOOGLE_ADS_EVENT_IDS = {
  RTL_CHECKOUT_EVENT: 'AW-580262522/1_D4CPnJqaYDEPq02JQC',
  RTL_WAITLIST_SIGNUP_EVENT: 'AW-580262522/OPbZCJPBtdADEPq02JQC',
};
