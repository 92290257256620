import { TestGroup } from './types';

export const THIRD_PARTY_TESTS: TestGroup[] = [
  {
    sectionTitle: 'STANDARD TESTS',
    tests: [
      {
        label: 'HRIPT (50)',
        defaultCost: 1_200,
        name: 'hript50',
      },
      {
        label: 'HRIPT (100)',
        defaultCost: 2_400,
        name: 'hript100',
      },
      {
        label: 'PET',
        defaultCost: 500,
        name: 'pet',
      },
      {
        label: 'Stability (Third Party)',
        defaultCost: 12_000,
        name: 'stability',
      },
    ],
  },
  {
    sectionTitle: 'SPF',
    tests: [
      {
        label: 'FDA 2021 Static SPF 30 and Water Resistance',
        defaultCost: 7_850,
        name: 'fda2021StaticSpf30AndWaterResistance',
      },
      {
        label: 'FDA 2021 Static SPF 50 and Water Resistance',
        defaultCost: 9_000,
        name: 'fda2021StaticSpf50AndWaterResistance',
      },
      {
        label: 'FDA 2021 SPF 30 Static',
        defaultCost: 3_000,
        name: 'fda2021Spf30Static',
      },
      {
        label: 'FDA 2021 SPF 50 Static',
        defaultCost: 4_280,
        name: 'fda2021Spf50Static',
      },
      {
        label: 'FDA Critical Wavelength/Broad Spectrum',
        defaultCost: 600,
        name: 'fdaCriticalWavelengthBroadSpectrum',
      },
      {
        label: 'ISO 24442',
        defaultCost: 4_400,
        name: 'iso24442',
      },
      {
        label: 'ISO 24443',
        defaultCost: 700,
        name: 'iso24443',
      },
      {
        label: 'ISO 24444 SPF 30',
        defaultCost: 8_750,
        name: 'iso24444Spf30',
      },
      {
        label: 'ISO 24444 SPF 50',
        defaultCost: 9_750,
        name: 'iso24444Spf50',
      },
    ],
  },
  {
    sectionTitle: 'OTHER',
    tests: [
      {
        label: 'Comedogenicity Patch Test',
        defaultCost: 2_100,
        name: 'comedogenicityPatchTest',
      },
      {
        label: 'Comedogenicity In Real use',
        defaultCost: 3_000,
        name: 'comedogenicityInRealUse',
      },
      {
        label: 'Ocular Irritation (In Vitro)',
        defaultCost: 3_200,
        name: 'ocularIrritation',
      },
      {
        label: 'Safety-In-Use Pediatrician Sign-Off',
        defaultCost: 6_400,
        name: 'safetyInUsePediatricianSignOff',
      },
    ],
  },
];
