// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import {
  Divider,
  RadioButton,
  Select,
  TextField,
  Typography,
} from 'design-system';
import { useMobile } from 'hooks';
// Utils
import {
  InternalBriefFormValues,
  cogsType,
} from '../internal-brief/form.config';
// Constants
import { COGS_OPTIONS } from '../brief.constants';
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const TargetCogs = () => {
  const { values, setFieldValue, getFieldProps, errors } = useFormikContext<
    InternalBriefFormValues
  >();
  const { isMobile } = useMobile();

  return (
    <div className="mb-6">
      <div className="mb-6">
        <Typography variant="h3" font="inter" id="target-cogs">
          Target Cost of Goods Sold (COGS)
        </Typography>
        <Typography variant="h6" font="inter" color="error" size="xs">
          {errors.cogsType}
        </Typography>
      </div>
      <div className="flex flex-col justify-evenly lg:flex lg:flex-row lg:justify-between">
        <div
          className="mb-3 md:mb-0 lg:mb-0
                  md:flex md:flex-row md:justify-between md:items-center
                  lg:flex lg:flex-row lg:justify-between lg:items-center"
        >
          <div className="mb-2 md:mb-0 lg:mb-0 ">
            <RadioButton
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(BRIEF_FORM_KEYS.COGS_TYPE, e.target.value);
                setFieldValue(BRIEF_FORM_KEYS.COGS_RANGE, {
                  label: '',
                  value: '',
                });
              }}
              primaryLabel="Specific"
              name="cogsType"
              value="Specific"
              checked={values.cogsType === cogsType.Specific}
            />
          </div>
          <div>
            <TextField
              {...getFieldProps(BRIEF_FORM_KEYS.TARGET_COST)}
              disabled={values.cogsType !== cogsType.Specific}
              label="Cost per Unit"
              additionalClasses="md:w-[120px]"
              placeholder="0.00"
              leadingText="$"
              error={Boolean(errors.targetCost)}
              helperText={errors.targetCost}
            />
          </div>
        </div>

        {!isMobile && <Divider direction="vertical" className="h-auto" />}

        <div
          className="mb-3 md:mb-0 lg:mb-0
                  md:flex md:flex-row md:justify-between md:items-center
                  lg:flex lg:flex-row lg:justify-between lg:items-center"
        >
          <div className="mb-2 md:mb-0 lg:mb-0 ">
            <RadioButton
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(BRIEF_FORM_KEYS.COGS_TYPE, e.target.value);
                setFieldValue(BRIEF_FORM_KEYS.TARGET_COST, '');
              }}
              primaryLabel="Range"
              name="cogsType"
              value="Range"
              checked={values.cogsType === cogsType.Range}
            />
          </div>
          <div>
            <Select
              additionalClasses="md:w-[110px] lg:w-full"
              value={values.cogsRange}
              onChange={(option: Option) =>
                setFieldValue(BRIEF_FORM_KEYS.COGS_RANGE, option)
              }
              disabled={values.cogsType !== cogsType.Range}
              label="Cost per Unit"
              options={COGS_OPTIONS}
              placeholder="$0.00 - $0.00"
              error={Boolean(errors.cogsRange?.value)}
              helperText={errors.cogsRange?.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
