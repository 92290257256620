// Libraries
import React from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  createStyles,
  makeStyles,
} from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';
// Constants
import { SORT_OPTIONS } from 'features/constants';

interface IFileSort {
  sortedBy: string;
  handleSort: (e: React.ChangeEvent<any>) => void;
}

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(5),
    },
  })
);

export const FileSort: React.FC<IFileSort> = ({ sortedBy, handleSort }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <FormControl variant="outlined">
        <InputLabel>Sort</InputLabel>
        <Select
          aria-label="Sort"
          label="Sort"
          name="sortOptions"
          variant="outlined"
          value={sortedBy}
          onChange={handleSort}
        >
          <MenuItem value={SORT_OPTIONS.newest.value}>
            {SORT_OPTIONS.newest.label}
          </MenuItem>
          <MenuItem value={SORT_OPTIONS.alphabetical.value}>
            {SORT_OPTIONS.alphabetical.label}
          </MenuItem>
          <MenuItem value={SORT_OPTIONS.fileType.value}>
            {SORT_OPTIONS.fileType.label}
          </MenuItem>
          <MenuItem value={SORT_OPTIONS.oldest.value}>
            {SORT_OPTIONS.oldest.label}
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};
