// Libraries
import React from 'react';
import { createStyles, makeStyles, Grid, TextField } from '@material-ui/core';
import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
// Components
import { DialogModal } from 'features/ui';
// Utils
import { Inci } from '../types';
import { IRawMaterialFormValues } from './raw-material-form.component';
import { ITheme } from 'styles/mui/themeV2';
import * as Constants from 'features/constants';
import { useApi, useSnackbar } from 'hooks';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    row: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

interface IInciFormValues {
  casNumber: string;
  euName: string;
  usName: string;
}

interface CreateInciModalProps {
  closeModal: () => void;
  inciToEdit?: Inci;
  isOpen: boolean;
  setInciToEdit: React.Dispatch<React.SetStateAction<Maybe<Inci>>>;
  setSelectedInci: React.Dispatch<React.SetStateAction<Maybe<Inci>>>;
}

export const AddOrEditInciModal: React.FC<CreateInciModalProps> = ({
  closeModal,
  inciToEdit,
  isOpen,
  setInciToEdit,
  setSelectedInci,
}) => {
  const isEditMode = !!inciToEdit;

  const classes = useStyles();
  const request = useApi();
  const { values, setFieldValue } = useFormikContext<IRawMaterialFormValues>();
  const { showSnackbar } = useSnackbar();

  const handleClose = () => {
    resetForm();
    isEditMode && setInciToEdit(undefined);
    closeModal();
  };

  const handleInciUpdateOrCreation = async (inciValues: IInciFormValues) => {
    if (isEditMode) {
      if (
        !window.confirm(
          'This change affects all INCIs with this name. Would you like to continue?'
        )
      )
        return;
    }

    try {
      const response = await request({
        resource: `/api/v1/incis${isEditMode ? `/${inciToEdit?.id}` : ''}`,
        options: {
          settings: isEditMode ? Constants.PATCH : Constants.POST,
          scope: `${isEditMode ? 'update' : 'create'}:inci`,
          body: {
            inci: inciValues,
          },
        },
      });

      if (response.data) {
        showSnackbar(
          `INCI ${isEditMode ? 'updated' : 'created'} successfully`,
          'success'
        );

        if (isEditMode) {
          // Try to find if the current Global INCI we just edited
          // is also in the formik state
          const foundInciToUpdateIndex = values.incis.findIndex(
            (inci) => inci.id === inciToEdit?.id
          );
          const foundInciToUpdate = values.incis[foundInciToUpdateIndex];

          if (foundInciToUpdate) {
            // If it is update the fields it means the formik INCI state
            // needs to update as well.
            values.incis[foundInciToUpdateIndex] = {
              ...foundInciToUpdate,
              // only replace the values available in the edit form
              ...inciValues,
            };

            // reset this field so it has the updated values.
            setFieldValue('incis', [...values.incis]);
          }

          inciToEdit &&
            setSelectedInci({
              ...inciToEdit,
              ...inciValues,
            });
        }

        handleClose();
      } else {
        throw new Error(
          response.errors
            ? response.errorMessage
            : `There was an error trying to ${
                isEditMode ? 'update' : 'create'
              } the INCI`
        );
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error');
    }
  };

  const formik = useFormik<IInciFormValues>({
    initialValues: {
      usName: inciToEdit?.usName || '',
      euName: inciToEdit?.euName || '',
      casNumber: inciToEdit?.casNumber || '',
    },
    validationSchema: Yup.object({
      usName: Yup.string().required('A US name is required'),
      euName: Yup.string().required('An EU name is required'),
    }),
    onSubmit: handleInciUpdateOrCreation,
  });

  // getFieldProps automatically gives us => value, onBlur, onChange and Name attributes :)
  const {
    errors,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    resetForm,
    touched,
  } = formik;

  const renderModalContent = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.row}>
          <TextField
            error={Boolean(errors.usName && touched.usName)}
            fullWidth
            helperText={touched.usName ? errors.usName : ''}
            label="US Name"
            {...getFieldProps('usName')}
            required
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.row} item xs={12}>
          <TextField
            error={Boolean(errors.euName && touched.euName)}
            fullWidth
            helperText={touched.euName ? errors.euName : ''}
            label="EU Name"
            {...getFieldProps('euName')}
            required
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.row} item xs={12}>
          <TextField
            fullWidth
            label="CAS #"
            {...getFieldProps('casNumber')}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DialogModal
      handleClose={handleClose}
      onConfirm={handleSubmit}
      open={isOpen}
      submitDisabled={isSubmitting}
      title={`${isEditMode ? 'Edit' : 'Create new'} INCI`}
    >
      {renderModalContent()}
    </DialogModal>
  );
};
