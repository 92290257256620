//Libraries
import React from 'react';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { useNavigate } from 'react-router-dom';
// Utils
import { useAnalytics } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { ROUTES } from 'features/navigation';
import { SEARCH_PARAM_KEYS } from 'features/constants';

interface InviteTeamMemberButtonProps {
  fullWidth?: boolean;
}

export const InviteTeamMemberButton: React.FC<InviteTeamMemberButtonProps> = ({
  fullWidth,
}) => {
  const navigate = useNavigate();
  const analytics = useAnalytics()!;

  const goToTeamInvite = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBSubmissionSuccess,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbSubmissionYes,
    });

    navigate({
      pathname: ROUTES.PROFILE.route,
      search: `?${SEARCH_PARAM_KEYS.TAB}=company`,
    });
  };

  return (
    <button
      onClick={goToTeamInvite}
      className={`
      ${
        fullWidth ? 'w-full' : ''
      } flex px-5 py-4 text-black border text-base font-display items-center rounded`}
    >
      <PersonAddOutlinedIcon />
      <h5 className="ml-2">Invite</h5>
    </button>
  );
};
