// Libraries
import React from 'react';
import _ from 'lodash';

interface HorizontalCardProps {
  title: string | React.ReactNode;
  content?: string | string[];
  icon?: React.ReactComponentElement<any> | null;
  iconColor?: string;
  image?: string;
  isDisabled?: boolean;
  selected?: boolean;
  action?: () => void;
  [key: string]: any;
}

export const HorizontalCard: React.FC<HorizontalCardProps> = ({
  title,
  content,
  icon: Icon,
  iconColor,
  isDisabled,
  image,
  selected,
  action,
  ...rest
}) => {
  const renderContent = () => {
    if (Array.isArray(content)) {
      return (
        <div className="flex flex-col">
          {content.map((item, index) => {
            return (
              <p
                key={index}
                className="ml-4 mb-1 mr-4 font-inter text-xs text-grey-50"
              >
                {item}
              </p>
            );
          })}
        </div>
      );
    }
    return (
      <p className="ml-4 mb-4 mr-4 font-inter text-xs text-grey-50">
        {content}
      </p>
    );
  };

  return (
    <div
      className={`flex flex-row border ${
        selected ? 'border-royal-50 bg-blue-95' : 'border-grey-90'
      } rounded cursor-pointer max-w-[600px]
        ${!isDisabled && 'hover:bg-blue-95 active:border-royal-50'}
        ${!isDisabled && action && 'cursor-pointer'}
        ${isDisabled && 'bg-grey-95'} ${rest.className || ''}`}
      onClick={action || undefined}
      {..._.omit(rest, ['className'])}
    >
      <div className="flex flex-row justify-start items-center">
        <div className="flex flex-col w-[144px] max-w-xs">
          <div className="flex flex-row items-center m-4">
            {Icon &&
              React.cloneElement(Icon, {
                className: `${iconColor} mr-2`,
                height: 14,
                width: 14,
              })}
            <div className="flex-1">
              <p className="font-inter text-base text-grey-20">{title}</p>
            </div>
          </div>
          {content && renderContent()}
        </div>
      </div>
      {image && (
        <div className="relative overflow-hidden bg-cover bg-no-repeat">
          <img
            src={image}
            width={300}
            alt=""
            className="h-full md:h-auto object-cover md:object-fill"
          />
        </div>
      )}
    </div>
  );
};
