// Libraries
import React, { useEffect, useState } from 'react';
// Components
import { DialogModal } from 'features/ui';
import { PurposesModule } from './purposes-module.component';
// Constants
import { US_API_DRUG_PURPOSES } from './constants';

interface IAPIIngredientModal {
  handleClose: () => void;
  handleUpdate: (name: string, value: any) => void;
  isLocked: boolean;
  isOpen: boolean;
  usApiDrugPurposes: string[];
}

export const APIIngredientModal: React.FC<IAPIIngredientModal> = ({
  handleClose,
  handleUpdate,
  isLocked,
  isOpen,
  usApiDrugPurposes,
}) => {
  const [selectedUsApiDrugPurposes, setSelectedUsApiDrugPurposes] = useState<
    string[]
  >(usApiDrugPurposes);

  useEffect(() => {
    setSelectedUsApiDrugPurposes(usApiDrugPurposes);
  }, [usApiDrugPurposes]);

  const handlePurposesChange = (purposes: string[]) => {
    setSelectedUsApiDrugPurposes(purposes);
  };

  const handleSubmit = async () => {
    try {
      await handleUpdate(US_API_DRUG_PURPOSES, selectedUsApiDrugPurposes);
    } finally {
      handleClose();
    }
  };

  return (
    <DialogModal
      handleClose={handleClose}
      onConfirm={handleSubmit}
      open={isOpen}
      title="Active Pharmaceutical Ingredient"
    >
      <PurposesModule
        isLocked={isLocked}
        handlePurposesChange={handlePurposesChange}
        usApiDrugPurposes={selectedUsApiDrugPurposes}
      />
    </DialogModal>
  );
};
