// Libraries
import React from 'react';
// Components
import { PricingQuoteContainer } from './pricing-quote-container.component';
import { PricingQuoteFormikWrapper } from './pricing-quote-formik-wrapper.component';
// Utils
import { IPricingQuote } from './types';
import { IApiData, PricingQuoteAttributes } from 'api';

export const PricingQuote: React.FC<IPricingQuote> = ({
  brief,
  projectId,
  setTabValue,
}) => {
  return (
    <PricingQuoteFormikWrapper>
      {({
        pricingQuote,
      }: {
        pricingQuote: Maybe<IApiData<PricingQuoteAttributes>>;
      }) => (
        <PricingQuoteContainer
          brief={brief}
          pricingQuote={pricingQuote}
          projectId={projectId}
          setTabValue={setTabValue}
        />
      )}
    </PricingQuoteFormikWrapper>
  );
};
