// Libraries
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
// Components
import { Button, Select, TextArea, TextField, Typography } from 'design-system';
import {
  PRICE_QUOTE_FORM_KEYS,
  PRICE_SIZE_UNITS,
  INITIAL_PRICE_QUOTE_FORM_VALUES,
} from 'features/price-quote/price-quote.constants';
// Assets
import { PlusIcon } from '@heroicons/react/16/solid';
import { PriceQuoteFormValues } from '../form.config';
// Constants

const SIZE_LIMIT = 3;
const SIZE_KEYS = [
  {
    sizeQuantityKey: PRICE_QUOTE_FORM_KEYS.SIZE_ONE_QUANTITY,
    sizeUnitKey: PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT,
    sizeDescriptionKey: PRICE_QUOTE_FORM_KEYS.SIZE_ONE_DESCRIPTION,
    sizeMoqOneTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_ONE_TOTAL_COST_PER_UNIT,
    sizeMoqTwoTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_TWO_TOTAL_COST_PER_UNIT,
    sizeMoqThreeTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_THREE_TOTAL_COST_PER_UNIT,
  },
  {
    sizeQuantityKey: PRICE_QUOTE_FORM_KEYS.SIZE_TWO_QUANTITY,
    sizeUnitKey: PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT,
    sizeDescriptionKey: PRICE_QUOTE_FORM_KEYS.SIZE_TWO_DESCRIPTION,
    sizeMoqOneTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_ONE_TOTAL_COST_PER_UNIT,
    sizeMoqTwoTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_TWO_TOTAL_COST_PER_UNIT,
    sizeMoqThreeTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_THREE_TOTAL_COST_PER_UNIT,
  },
  {
    sizeQuantityKey: PRICE_QUOTE_FORM_KEYS.SIZE_THREE_QUANTITY,
    sizeUnitKey: PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT,
    sizeDescriptionKey: PRICE_QUOTE_FORM_KEYS.SIZE_THREE_DESCRIPTION,
    sizeMoqOneTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_ONE_TOTAL_COST_PER_UNIT,
    sizeMoqTwoTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_TWO_TOTAL_COST_PER_UNIT,
    sizeMoqThreeTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_THREE_TOTAL_COST_PER_UNIT,
  },
];

interface SizeTableProps {
  quoteIsEditable: Maybe<boolean>;
}

export const SizeTable: React.FC<SizeTableProps> = ({ quoteIsEditable }) => {
  const [sizeCount, setSizeCount] = React.useState(1);

  const handleAddSize = () => {
    if (sizeCount === SIZE_LIMIT) return;
    setSizeCount(sizeCount + 1);
  };

  const {
    getFieldProps,
    setFieldValue,
    values,
    setValues,
    errors,
  } = useFormikContext<PriceQuoteFormValues>();

  useEffect(() => {
    // this determines the number of rows to display in the size table base on the acc being set
    setSizeCount(
      SIZE_KEYS.reduce((acc, size) => {
        return (
          // increment acc if the values picked from the formik object (value) at the specific keys from the size object are truthy
          acc + (Object.values(_.pick(values, Object.values(size)))[0] ? 1 : 0)
        );
        // if the reducer returns 0, then set the size count to 1 because we want to render at least one row
      }, 0) || 1
    );
  }, [values]);

  const handleRemoveSize = (index: number) => {
    if (sizeCount === 1) return;

    const sizeKeys = SIZE_KEYS[index];
    const sizePosition = index + 1;

    if (sizePosition === SIZE_LIMIT) {
      // if the row being removed is the last row, just reset the values
      setValues({
        ...values,
        ..._.pick(INITIAL_PRICE_QUOTE_FORM_VALUES, Object.values(sizeKeys)),
      });

      setSizeCount(sizeCount - 1);
    } else {
      // This is all the updated values in the size table, if any
      const newValues = _.cloneDeep(values);
      // if the row being removed is not the last row, then shift the values from the next row down up to the current row
      // repeat this for all rows until the last row
      for (let i = index; i < SIZE_LIMIT - 1; i++) {
        const currentSize = SIZE_KEYS[i];
        const nextSize = SIZE_KEYS[i + 1];

        newValues[currentSize.sizeQuantityKey] =
          newValues[nextSize.sizeQuantityKey];
        newValues[currentSize.sizeUnitKey] = newValues[nextSize.sizeUnitKey];
        newValues[currentSize.sizeDescriptionKey] =
          newValues[nextSize.sizeDescriptionKey];
        newValues[currentSize.sizeMoqOneTotalCostPerUnitKey] =
          newValues[nextSize.sizeMoqOneTotalCostPerUnitKey];
        newValues[currentSize.sizeMoqTwoTotalCostPerUnitKey] =
          newValues[nextSize.sizeMoqTwoTotalCostPerUnitKey];
        newValues[currentSize.sizeMoqThreeTotalCostPerUnitKey] =
          newValues[nextSize.sizeMoqThreeTotalCostPerUnitKey];

        // values will be whatever the new values have been set to, but overwrite the last row with the initial form values
        setValues({
          ...newValues,
          ..._.pick(INITIAL_PRICE_QUOTE_FORM_VALUES, Object.values(nextSize)),
        });
      }

      setSizeCount(sizeCount - 1);
    }
  };

  return (
    <div className="border rounded border-grey-90">
      <div className="grid grid-cols-4 rounded">
        <div className="col-span-1 bg-grey-20 flex items-center pl-6 border border-t-0 border-b-0 border-l-0 border-r-grey-90 rounded-tl">
          <Typography variant="p" font="inter" weight="semibold" color="onDark">
            Size
          </Typography>
        </div>
        <div className="col-span-1 bg-grey-20 px-16 py-3 border border-t-0 border-b-0 border-l-0 border-r-grey-90 flex flex-col items-center">
          <Typography
            variant="h6"
            font="inter"
            weight="semibold"
            color="onDark"
            additionalStyles="whitespace-nowrap mb-2"
          >
            Order Quantity 1 (MOQ)
          </Typography>
          <TextField
            placeholder="0000"
            height="auto"
            type="number"
            {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MOQ_ONE)}
            error={errors.moqOne}
            helperText={errors.moqOne}
            disabled={!quoteIsEditable}
          />
        </div>
        <div className="col-span-1 bg-grey-20 px-16 py-3 border border-t-0 border-b-0 border-l-0 border-r-grey-90 flex flex-col items-center">
          <Typography
            variant="h6"
            font="inter"
            weight="semibold"
            color="onDark"
            additionalStyles="whitespace-nowrap mb-2"
          >
            Order Quantity 2
          </Typography>
          <TextField
            placeholder="0000"
            height="auto"
            type="number"
            {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MOQ_TWO)}
            disabled={!quoteIsEditable}
          />
        </div>
        <div className="col-span-1 bg-grey-20 px-16 py-3 flex flex-col items-center rounded-tr">
          <Typography
            variant="h6"
            font="inter"
            weight="semibold"
            color="onDark"
            additionalStyles="whitespace-nowrap mb-2"
          >
            Order Quantity 3
          </Typography>
          <TextField
            placeholder="0000"
            height="auto"
            type="number"
            {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MOQ_THREE)}
            disabled={!quoteIsEditable}
          />
        </div>
      </div>

      {Array.from({ length: sizeCount }).map((_, index) => {
        const {
          sizeQuantityKey,
          sizeUnitKey,
          sizeDescriptionKey,
          sizeMoqOneTotalCostPerUnitKey,
          sizeMoqTwoTotalCostPerUnitKey,
          sizeMoqThreeTotalCostPerUnitKey,
        } = SIZE_KEYS[index];

        return (
          <div className="grid grid-cols-4" key={`size-row-${index + 1}`}>
            <div className="col-span-1 flex flex-col p-6 border border-t-0 border-l-0 border-r-grey-90 border-b-grey-90">
              <div className="flex flex-row">
                <TextField
                  placeholder="00"
                  height="auto"
                  label={`Size ${index + 1}`}
                  additionalClasses="mr-3"
                  type="number"
                  {...getFieldProps(sizeQuantityKey)}
                  error={Boolean(errors[sizeQuantityKey])}
                  helperText={errors[sizeQuantityKey]}
                  disabled={!quoteIsEditable}
                />
                <Select
                  label="Unit"
                  options={PRICE_SIZE_UNITS}
                  onChange={(option: Option) => {
                    setFieldValue(sizeUnitKey, option);
                  }}
                  value={values[sizeUnitKey]}
                  disabled={!quoteIsEditable}
                />
              </div>
              <div>
                <TextArea
                  placeholder="Description"
                  height="auto"
                  label="Description (Optional)"
                  rows={2}
                  {...getFieldProps(sizeDescriptionKey)}
                  disabled={!quoteIsEditable}
                />
              </div>
              <Typography
                font="inter"
                color="secondary"
                variant="h6"
                additionalStyles={
                  quoteIsEditable ? 'underline cursor-pointer' : 'underline'
                }
                onClick={() =>
                  quoteIsEditable ? null : handleRemoveSize(index)
                }
              >
                Remove Size
              </Typography>
            </div>
            <div className="col-span-1 p-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
              <TextField
                label="Total"
                leadingText="$"
                trailingText="per unit"
                placeholder="00.00"
                height="auto"
                additionalClasses="white-space-nowrap"
                type="number"
                {...getFieldProps(sizeMoqOneTotalCostPerUnitKey)}
                error={Boolean(errors[sizeMoqOneTotalCostPerUnitKey])}
                helperText={errors[sizeMoqOneTotalCostPerUnitKey]}
                disabled={!quoteIsEditable}
              />
            </div>
            <div className="col-span-1 p-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
              <TextField
                label="Total"
                leadingText="$"
                trailingText="per unit"
                placeholder="00.00"
                height="auto"
                additionalClasses="white-space-nowrap"
                type="number"
                {...getFieldProps(sizeMoqTwoTotalCostPerUnitKey)}
                disabled={!quoteIsEditable}
              />
            </div>
            <div className="col-span-1 p-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
              <TextField
                label="Total"
                leadingText="$"
                trailingText="per unit"
                placeholder="00.00"
                height="auto"
                additionalClasses="white-space-nowrap"
                type="number"
                {...getFieldProps(sizeMoqThreeTotalCostPerUnitKey)}
                disabled={!quoteIsEditable}
              />
            </div>
          </div>
        );
      })}

      <div className="grid grid-cols-4">
        <div className="col-span-1 p-3 border border-t-0 border-b-0 border-l-0 border-r-grey-90">
          <Button
            text="Add Size"
            leadingIcon={<PlusIcon />}
            size="large"
            type="soft"
            width="w-[225px]"
            action={handleAddSize}
            disabled={!quoteIsEditable}
          />
        </div>
        <div className="col-span-3"></div>
      </div>
    </div>
  );
};
