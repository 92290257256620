// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import {
  createStyles,
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
// Components
import { SecondaryFiltersAndSort } from './secondary-filters-and-sort.component';
// Utils
import { ITableColumnCollection } from 'features/types';
import { ITheme } from 'styles/mui/themeV2';
import { SearchResult, SearchResultFilters } from './types';
// Constants
import {
  CREATED_AT,
  DATE_FORMAT,
  FORMULAS_TITLE,
  NOT_APPLICABLE,
} from './constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';

interface SearchResultsTableProps {
  currentPage: number;
  filters: SearchResultFilters;
  isLoading: boolean;
  results: SearchResult[];
  rowsPerPage: number;
  selectedSort: string;
  setFilters: React.Dispatch<React.SetStateAction<SearchResultFilters>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedSort: React.Dispatch<React.SetStateAction<string>>;
  sortOptions: { [key: string]: { name: string; value: string } };
  tableColumns: ITableColumnCollection;
  title: string;
  totalResultsCount: number;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    headerRow: {
      backgroundColor: theme.palette.secondary.dark,
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
    },
    headerCell: {
      color: '#ffffff',
      borderBottom: 'none',
      '&:first-child': {
        borderTopLeftRadius: '0.25rem',
      },
      '&:last-child': {
        borderTopRightRadius: '0.25rem',
      },
    },
    link: {
      textDecoration: 'underline',
      maxWidth: 'fit-content',
      display: 'block',
    },
    table: {
      border: `1px solid ${theme.palette.gray.light}`,
    },
    tableRow: {
      '& *': {
        fontSize: '16px',
      },
    },
    highlighted: {
      backgroundColor: theme.palette.tan.main,
    },
  })
);

export const SearchResultsTable: React.FC<SearchResultsTableProps> = ({
  currentPage,
  filters,
  isLoading,
  results,
  rowsPerPage,
  selectedSort,
  setFilters,
  setPage,
  setRowsPerPage,
  setSelectedSort,
  sortOptions,
  tableColumns,
  title,
  totalResultsCount,
}) => {
  const classes = useStyles();

  const highlightContent = (htmlContent: string) => {
    return htmlContent.replace(
      /<em>(.*?)<\/em>/g,
      `<em class="${classes.highlighted}">$1</em>`
    );
  };

  const renderExternalId = (result: any) => {
    // result may have either an external_id (formula) or a monday_pulse_id (project)

    if (result._source.external_id) {
      return result.highlight?.external_id
        ? highlightContent(result.highlight.external_id[0])
        : result._source.external_id;
    }

    if (result._source.monday_pulse_id) {
      return result.highlight?.monday_pulse_id
        ? highlightContent(result.highlight.monday_pulse_id[0])
        : result._source.monday_pulse_id;
    }

    return NOT_APPLICABLE;
  };

  const renderLink = (
    name: string,
    uuid: string,
    route: string,
    highlight: string = ''
  ) => {
    const modifiedContent = highlightContent(highlight);
    return (
      <Link
        to={route.replace(UUID_SHOW_ROUTE_STRING, uuid)}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
        dangerouslySetInnerHTML={
          modifiedContent
            ? { __html: modifiedContent }
            : { __html: `<div>${name}</div>` }
        }
      ></Link>
    );
  };

  const renderCompanyAndCustomerForProjectTable = (result: any) => {
    return result._source.company?.customers?.length > 0 ? (
      <Tooltip
        title={`${result._source.company.customers[0].given_name}
                ${result._source.company.customers[0].family_name}:
                ${result._source.company.customers[0].email}`}
      >
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          to={ROUTES.EMPLOYEE_VIEW_CUSTOMER_DASHBOARD.route.replace(
            UUID_SHOW_ROUTE_STRING,
            result._source.company.uuid
          )}
          dangerouslySetInnerHTML={{
            __html: result?.highlight?.['company.name']
              ? highlightContent(result.highlight['company.name'][0])
              : result._source.company?.name || NOT_APPLICABLE,
          }}
        />
      </Tooltip>
    ) : (
      <div>{result._source.company?.name || NOT_APPLICABLE}</div>
    );
  };

  const renderCompanyAndCustomerForFormulaTable = (result: any) => {
    return result._source.project.company?.customers?.length > 0 ? (
      <Tooltip
        title={`${result._source.project.company.customers[0].given_name}
                ${result._source.project.company.customers[0].family_name}:
                ${result._source.project.company.customers[0].email}`}
      >
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          to={ROUTES.EMPLOYEE_VIEW_CUSTOMER_DASHBOARD.route.replace(
            UUID_SHOW_ROUTE_STRING,
            result._source.project.company.uuid
          )}
          dangerouslySetInnerHTML={{
            __html: result?.highlight?.['company.name']
              ? highlightContent(result.highlight['company.name'][0])
              : result._source.project.company?.name || NOT_APPLICABLE,
          }}
        />
      </Tooltip>
    ) : (
      <div>{result._source.project.company?.name || NOT_APPLICABLE}</div>
    );
  };

  return (
    <Grid container direction="column" style={{ marginTop: '32px' }}>
      <Typography variant="h3">{title}</Typography>
      <SecondaryFiltersAndSort
        filters={filters}
        selectedSort={selectedSort}
        setFilters={setFilters}
        setSelectedSort={setSelectedSort}
        sortOptions={sortOptions}
        title={title}
      />
      <Table className={classes.table}>
        <TableHead className={classes.headerRow}>
          <TableRow className={classes.headerRow}>
            {Object.keys(tableColumns).map((tableColumnKey: string) => {
              const tableColumn = tableColumns[tableColumnKey];
              return (
                <TableCell
                  key={`${tableColumnKey}`}
                  align={tableColumn.align ? tableColumn.align : 'left'}
                  className={classes.headerCell}
                  style={{
                    width: tableColumn.columnWidth,
                  }}
                >
                  <Typography variant="h4">{tableColumn.headerText}</Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {results.length > 0 && !isLoading ? (
          <TableBody>
            {results.map((result: any, idx: number) => {
              return title === FORMULAS_TITLE ? (
                <TableRow className={classes.tableRow} key={`${title}-${idx}`}>
                  <TableCell>
                    {renderLink(
                      result._source.name,
                      result._source.uuid,
                      ROUTES.SHOW_FORMULA.route,
                      result?.highlight?.name?.[0]
                    )}
                  </TableCell>
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: result.highlight?.external_id
                        ? highlightContent(result.highlight.external_id[0])
                        : result._source.external_id || NOT_APPLICABLE,
                    }}
                  />
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: result.highlight?.production_recipe_id
                        ? highlightContent(
                            result.highlight.production_recipe_id[0]
                          )
                        : result._source.production_recipe_id || NOT_APPLICABLE,
                    }}
                  />
                  <TableCell>
                    {renderCompanyAndCustomerForFormulaTable(result)}
                  </TableCell>
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: result.highlight?.['project.name']
                        ? highlightContent(result.highlight['project.name'][0])
                        : result._source.project.name || NOT_APPLICABLE,
                    }}
                  />
                  <TableCell>
                    <Tooltip
                      title={`${CREATED_AT} ${format(
                        new Date(result._source.created_at),
                        DATE_FORMAT
                      )}`}
                    >
                      <div>
                        {format(
                          new Date(result._source.updated_at),
                          DATE_FORMAT
                        )}
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow className={classes.tableRow} key={`${title}-${idx}`}>
                  <TableCell>
                    {renderLink(
                      result._source.name,
                      result._source.uuid,
                      ROUTES.SHOW_PROJECT.route,
                      result?.highlight?.name?.[0]
                    )}
                  </TableCell>
                  <TableCell
                    dangerouslySetInnerHTML={{
                      __html: renderExternalId(result),
                    }}
                  ></TableCell>
                  <TableCell>
                    {renderCompanyAndCustomerForProjectTable(result)}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={`${CREATED_AT} ${format(
                        new Date(result._source.created_at),
                        DATE_FORMAT
                      )}`}
                    >
                      <div>
                        {format(
                          new Date(result._source.updated_at),
                          DATE_FORMAT
                        )}
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : isLoading ? (
          <TableRow>
            <TableCell colSpan={100}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress size={100} thickness={3} />
              </div>
            </TableCell>
          </TableRow>
        ) : null}
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={totalResultsCount}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={(_e, page) => {
          setPage(page);
        }}
        onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value))}
      />
    </Grid>
  );
};
