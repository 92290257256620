import { ConfirmState } from 'context';

export const BRIEF_FORM_KEYS = {
  ADDITIONAL_COMMENTS: 'additionalComments',
  ADDITIONAL_TESTING_BULK: 'additionalTestingBulk',
  ADDITIONAL_TESTING_BULK_NOTE: 'additionalTestingBulkNote',
  ANTI_FRIZZ: 'antiFrizz',
  ANTI_REDNESS: 'antiRedness',
  APPROVAL_DATE: 'approvalDate',
  BENCHMARKS: 'benchmarks',
  BLUE_LIGHT_PROTECTION: 'blueLightProtection',
  BRIGHTENING: 'brightening',
  BROAD_SPECTRUM_TEST: 'broadSpectrumTest',
  CLOSURE: 'closure',
  COA: 'coa',
  COGS_RANGE: 'cogsRange',
  COGS_TYPE: 'cogsType',
  COLOR_NOTE: 'colorNote',
  COMPANY: 'company',
  COMPATIBILITY_TEST: 'compatibilityTest',
  CONDITIONING: 'conditioning',
  CONTACT: 'contact',
  CONTAINER: 'container',
  COUNTRIES: 'countries',
  COUNTRY_COMPLIANCE_NOTE: 'countryComplianceNote',
  CURL_DEFINING: 'curlDefining',
  DEEP_CONDITIONING: 'deepConditioning',
  DETANGLE: 'detangle',
  DIRECTIONS_FOR_USE: 'directionsForUse',
  ESTIMATED_QUOTE_STANDARD: 'estimatedQuoteStandard',
  EYES_REDUCE_APPEARANCE_OF_DARK_CIRCLES: 'eyesReduceAppearanceOfDarkCircles',
  EYES_REDUCE_APPEARANCE_OF_LINE_CROWS_FEET:
    'eyesReduceAppearanceOfLineCrowsFeet',
  EYES_REDUCE_APPEARANCE_OF_PUFFINESS: 'eyesReduceAppearanceOfPuffiness',
  FIRMING: 'firming',
  FRAGRANCE_COMMENT: 'fragranceComment',
  FRAGRANCE_TYPE: 'fragranceType',
  GLUTEN_FREE: 'glutenFree',
  GLUTEN_FREE_STATEMENT: 'glutenFreeStatement',
  HAIR_SMOOTHING: 'hairSmoothing',
  HARD_HOLD: 'hardHold',
  HRIPT_FOR_HYPOALLERGENIC_TEST: 'hriptForHypoallergenicTest',
  HRIPT_TEST: 'hriptTest',
  HRIPT_WITH_DERMATOLOGIST_TEST: 'hriptWithDermatologistTest',
  HYDRATION: 'hydration',
  HYDRATION_LONG_TERM: 'hydrationLongTerm',
  HYPOALLERGENIC: 'hypoallergenic',
  IFRA_COMPLIANCE_STATEMENT: 'ifraComplianceStatement',
  INITIAL_DATE_OF_BRIEF: 'initialDateOfBrief',
  LIGHT_HOLD: 'lightHold',
  MASTER_BOX: 'masterBox',
  MATERIAL: 'material',
  MATTIFYING: 'mattifying',
  MINIMUM_ORDER_QUANTITY: 'minimumOrderQuantity',
  MOISTURE: 'moisture',
  MOISTURIZATION: 'moisturization',
  MUST_EXCLUDE_INGREDIENTS: 'mustExcludeIngredients',
  MUST_HAVE_INGREDIENTS: 'mustHaveIngredients',
  NON_OTC_CLAIM_COMMENTS: 'nonOtcClaimComments',
  NO_ANIMAL_DERIVED_INGREDIENTS: 'noAnimalDerivedIngredients',
  NO_ANIMAL_DERIVED_INGREDIENTS_STATEMENT:
    'noAnimalDerivedIngredientsStatement',
  NO_ANIMAL_TESTED_INGREDIENTS_STATEMENT: 'noAnimalTestedIngredientsStatement',
  NO_ANIMAL_TESTING: 'noAnimalTesting',
  OCCULAR_TEST: 'occularTest',
  OPACITY: 'opacity',
  OTC: 'otc',
  OTC_NOTE: 'otcNote',
  OTHER_R_AND_D_DOCUMENTS: 'otherRAndDDocuments',
  PET_TEST: 'petTest',
  PRELIMINARY_IL_PER_SUBMISSION_STANDARD: 'preliminaryIlPerSubmissionStandard',
  PREVIOUSLY_BROUGHT_TO_MARKET: 'previouslyBroughtToMarket',
  PREVIOUSLY_BROUGHT_TO_MARKET_NOTE: 'previouslyBroughtToMarketNote',
  PRODUCTION_LOCATION: 'productionLocation',
  PRODUCT_CATEGORY: 'productCategory',
  PRODUCT_DESCRIPTION: 'productDescription',
  PRODUCT_FUNCTION: 'productFunction',
  PRODUCT_OBJECTIVE: 'productObjective',
  PRODUCT_PURPOSE: 'productPurpose',
  PRODUCT_TYPE: 'productType',
  PROJECT_NAME: 'projectName',
  RADIANCE_GLOW: 'radianceGlow',
  RETAILER_STANDARDS: 'retailerStandards',
  SAMPLE_RECIPIENTS: 'sampleRecipients',
  SAMPLE_QUANTITY: 'sampleQuantity',
  SELF_TANNER: 'selfTanner',
  SHINE: 'shine',
  SILICONE_FREE: 'siliconeFree',
  SIZE: 'size',
  SKIN_SMOOTHING: 'skinSmoothing',
  SOOTHING: 'soothing',
  SPF: 'spf',
  SPF_DETERMINATION_FDA_TEST: 'spfDeterminationFdaTest',
  SPF_DETERMINATION_ISO_TEST: 'spfDeterminationIsoTest',
  STABILITY_TEST: 'stabilityTest',
  STATEMENTS_NOTE: 'statementsNote',
  STRENGTHENING: 'strengthening',
  STRUCTURE: 'structure',
  TARGET_COST: 'targetCost',
  TARGET_CUSTOMER: 'targetCustomer',
  TARGET_DATE: 'targetDate',
  TEST_HANDLING_RESPONSIBILITY: 'testHandlingResponsibility',
  TIGHTENING: 'tightening',
  TINT: 'tint',
  UNIT: 'unit',
  VEGAN: 'vegan',
  VEGAN_INGREDIENTS_STATEMENT: 'veganIngredientsStatement',
  WATER_ACTIVITY_TEST: 'waterActivityTest',
} as const;

export const PRODUCT_CATEGORIES = {
  SUN_CARE: 'Sun Care',
  SKIN_CARE: 'Skin Care',
  HAIR_CARE: 'Hair Care',
  BODY_CARE: 'Body Care',
};

export const PRODUCT_TYPES = {
  AFTER_SUN: 'After-sun',
  BODY_SCRUB: 'Body scrub',
  BODY_WASH: 'Body wash',
  CHEMICAL_SUNSCREEN: 'Chemical Sunscreen',
  CLEANSER: 'Cleanser',
  CONDITIONER: 'Conditioner',
  DEODORANT: 'Deodorant',
  FOOT_CREAM: 'Foot cream',
  HAND_CREAM: 'Hand cream',
  HYBRID_SUNSCREEN: 'Hybrid Sunscreen',
  LIP_BALM: 'Lip balm',
  MINERAL: 'Mineral',
  MINERAL_SUNSCREEN: 'Mineral Sunscreen',
  MOISTURIZER: 'Moisturizer',
  POMADE: 'Pomade',
  SCRUB: 'Scrub',
  SELF_TANNING: 'Self-tanning',
  SERUM: 'Serum',
  SHAMPOO: 'Shampoo',
  SPRAY: 'Spray',
  STYLING_CREAM: 'Styling cream',
  TONER: 'Toner',
};

export const STRUCTURES = {
  AEROSOL: 'Aerosol',
  BALM: 'Balm',
  BUTTER: 'Butter',
  CONTINUOUS_SPRAY: 'Continuous Spray',
  CONTINUOUS_SPRAY_BOV: 'Continuous Spray(BOV)',
  CREAM: 'Cream',
  GEL: 'Gel',
  GEL_CREAM: 'Gel Cream',
  LIQUID: 'Liquid (ie Toner)',
  LOTION: 'Lotion',
  MOUSSE_FOAM: 'Mousse/Foam',
  OIL: 'Oil',
  OTHER: 'Other',
  PUMP_SPRAY: 'Pump Spray',
  SERUM: 'Serum',
  SPRAY: 'Spray',
  STICK: 'Stick',
  WAX: 'Wax',
};

export const OPACITIES = {
  CLEAR_TRANSPARENT: 'Clear / Transparent',
  TRANSLUCENT: 'Translucent',
  OPAQUE: 'Opaque',
};

export const FILL_SIZE_UNITS = {
  G: 'g',
  ML: 'ml',
  OZ: 'oz',
};

export const FRAGRANCE_OPTIONS = {
  FREE: 'free',
  OWN: 'own',
  BMARK: 'bmark',
  CUSTOM: 'custom',
};

export const SPF_VALUES = {
  SPF_15: '15',
  SPF_30: '30',
  SPF_45: '45',
  SPF_50: '50',
  SPF_60: '60',
  SPF_85: '85',
};

export const COGS_OPTIONS = [
  { label: '$0.01 - $0.50', value: '$0.01-$0.50' },
  { label: '$0.51 - $1.00', value: '$0.51-$1.00' },
  { label: '$1.01 - $1.50', value: '$1.01-$1.50' },
  { label: '$1.51 - $2.00', value: '$1.51-$2.00' },
  { label: '$2.01 - $2.50', value: '$2.01-$2.50' },
  { label: '$2.51 - $3.00', value: '$2.51-$3.00' },
  { label: '$3.01 - $3.50', value: '$3.01-$3.50' },
  { label: '$3.51 - $4.00', value: '$3.51-$4.00' },
  { label: '$4.01 - $4.50', value: '$4.01-$4.50' },
  { label: '$4.51 - $5.00', value: '$4.51-$5.00' },
  { label: '$5.01 - $5.50', value: '$5.01-$5.50' },
  { label: '$5.51 - $6.00', value: '$5.51-$6.00' },
  { label: '$6.01 - $6.50', value: '$6.01-$6.50' },
  { label: '$6.51 - $7.00', value: '$6.51-$7.00' },
  { label: '$7.01 - $7.50', value: '$7.01-$7.50' },
  { label: '$7.51 - $8.00', value: '$7.51-$8.00' },
  { label: '$8.01 - $8.50', value: '$8.01-$8.50' },
  { label: '$8.51 - $9.00', value: '$8.51-$9.00' },
  { label: '$9.01 - $9.50', value: '$9.01-$9.50' },
  { label: '$9.51 - $10.00', value: '$9.51-$10.00' },
];

const primaryComponentOptions = [
  { label: 'Aerosol', value: 'aerosol' },
  { label: 'Bag-on-Valve', value: 'bagOnValve' },
  { label: 'Bottle', value: 'bottle' },
  { label: 'Foil Sachet', value: 'foilSachet' },
  { label: 'Jar', value: 'jar' },
  { label: 'Tube', value: 'tube' },
  { label: 'Lip Balm/Deodorant', value: 'lipBalmDeodorant' },
  { label: 'Tottle', value: 'tottle' },
];

export const PACKAGING_OPTION_TYPES = {
  PRIMARY_COMPONENT: {
    name: BRIEF_FORM_KEYS.CONTAINER,
    options: primaryComponentOptions,
  },
  MASTER_BOX: {
    name: BRIEF_FORM_KEYS.MASTER_BOX,
    options: [],
  },
};

export const YES_NO_CHOICE = {
  YES: 'Yes',
  NO: 'No',
};

export const RTL_CONFIRM_STATE: Omit<ConfirmState, 'isOpen'> = {
  prompt: 'Are you sure?',
  description:
    'This selection requires your formula to be custom developed, which increases production timelines and costs.',
};

export const RESTART_CONFIRM_STATE: Omit<ConfirmState, 'isOpen'> = {
  prompt: 'Are you sure?',
  description:
    'By selecting this option, your subsequent choices will be lost.',
};
