// Libraries
import React from 'react';
import {
  makeStyles,
  createStyles,
  AppBar,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
// Assets
import Error from '@material-ui/icons/Error';
// Utils
import { ITheme } from 'styles/mui/themeV2';

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    button: {
      padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
      height: theme.spacing(12),
    },
    buttonBar: {
      display: 'flex',
      justifyContent: 'center',
      height: theme.spacing(16),
      top: 'auto',
      bottom: 0,
      backgroundColor: theme.palette.gray.lighter,
      color: theme.palette.secondary.dark,
    },
    submitButton: {
      marginLeft: theme.spacing(2),
    },
    warningIcon: {
      marginRight: theme.spacing(2),
    },
  })
);

interface IFixedButtonBar {
  disabled?: boolean;
  onCancel: () => void;
}

export const FixedSubmitButtonBar: React.FC<IFixedButtonBar> = ({
  disabled,
  onCancel,
}) => {
  const classes = useStyles();
  const { submitForm, dirty } = useFormikContext();

  const renderDirtyState = () => {
    if (dirty && !disabled) {
      return (
        <Grid container item xs={12}>
          <Error className={classes.warningIcon} />
          <Typography style={{ fontSize: '14px' }} variant="caption">
            You have unsaved changes
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={classes.buttonBar}
      style={{
        background: dirty ? '#dc2626' : '',
        color: dirty ? '#ffffff' : '',
      }}
    >
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={false} sm={1}></Grid>
        <Grid container item xs={6} sm={6} alignItems="center">
          {renderDirtyState()}
        </Grid>
        <Grid
          container
          item
          xs={5}
          sm={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            disabled={disabled}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={onCancel}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            size="small"
            disabled={disabled}
            onClick={submitForm}
            className={`${classes.button} ${classes.submitButton}`}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={false} sm={1}></Grid>
      </Grid>
    </AppBar>
  );
};
