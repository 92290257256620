// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton, TextArea, Typography } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS, FRAGRANCE_OPTIONS } from '../brief.constants';

export const Fragrance: React.FC = () => {
  const { getFieldProps, values } = useFormikContext<InternalBriefFormValues>();

  return (
    <div className="flex flex-col gap-6">
      <Typography id="fragrance" variant="h3" font="inter">
        Fragrance
      </Typography>
      <div className="flex flex-col gap-3">
        <Typography variant="h6" font="inter" weight="semibold">
          Do you need a fragrance?
        </Typography>
        <RadioButton
          {...getFieldProps(BRIEF_FORM_KEYS.FRAGRANCE_TYPE)}
          label="fragrance"
          value={FRAGRANCE_OPTIONS.FREE}
          primaryLabel="Fragrance-free"
          direction="col"
          checked={values.fragranceType === FRAGRANCE_OPTIONS.FREE}
        />
        <RadioButton
          {...getFieldProps(BRIEF_FORM_KEYS.FRAGRANCE_TYPE)}
          label="fragrance"
          value={FRAGRANCE_OPTIONS.OWN}
          primaryLabel="I have my own"
          direction="col"
          checked={values.fragranceType === FRAGRANCE_OPTIONS.OWN}
        />
        <RadioButton
          {...getFieldProps(BRIEF_FORM_KEYS.FRAGRANCE_TYPE)}
          label="fragrance"
          value={FRAGRANCE_OPTIONS.BMARK}
          primaryLabel="I have a benchmark fragrance"
          direction="col"
          checked={values.fragranceType === FRAGRANCE_OPTIONS.BMARK}
        />
        <RadioButton
          {...getFieldProps(BRIEF_FORM_KEYS.FRAGRANCE_TYPE)}
          label="fragrance"
          value={FRAGRANCE_OPTIONS.CUSTOM}
          primaryLabel="I want to develop a fragrance"
          direction="col"
          checked={values.fragranceType === FRAGRANCE_OPTIONS.CUSTOM}
        />
      </div>
      <TextArea
        {...getFieldProps(BRIEF_FORM_KEYS.FRAGRANCE_COMMENT)}
        label="Fragrance notes"
        placeholder="Additional information about fragrance"
      />
    </div>
  );
};
