// Libraries
import React, { useContext, useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import { Lock, LockOpenOutlined, ArrowDropDown } from '@material-ui/icons';
// Components
import { ArchiveDialog } from './archive-formula-modal.component';
import { EditAndDuplicateFormulaDialog } from './edit-and-duplicate-formula-dialog.component';
import { FormulaActions } from './formula-actions.component';
import { FormulaContext } from '../context';
import { FormulasDropdown } from './formulas-dropdown.component';
import { FormulaNote } from './formula-note.component';
import { MarketingInfoDialog } from './marketing-info.component';
import { MoreActionsMenu } from './more-actions-menu.component';
import { UserContext } from 'context';
import { StatusDialog } from './status-dialog.component';
// Utils
import { useSnackbar } from 'hooks';
import { interRegular, ITheme } from 'styles/mui/themeV2';
import { ActionsMenuItem, Dialog } from './types';
import { useApi, IApiData, FormulaAttributes } from 'api';
// Constant
import { DIALOG_MODES } from './constants';
import { THEME_PALETTE } from 'styles/mui/themeV2';
import { PERMISSIONS } from 'features/rbac';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
const { archive, duplicate, hidden, marketingInfo, status } = DIALOG_MODES;

// Types
interface IStyleProps {
  note: Maybe<string>;
  isLocked: Maybe<boolean>;
}

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    formulaName: {
      color: theme.palette.secondary.dark,
    },
    infoRow: {
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.gray.light}`,
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
      borderBottomRightRadius: (props: IStyleProps) =>
        props.note ? 0 : '0.25rem',
      borderBottomLeftRadius: (props: IStyleProps) =>
        props.note ? 0 : '0.25rem',
      marginTop: '1.25rem',
    },
    box: {
      borderRight: `1px solid ${theme.palette.gray.light}`,
      padding: '18px 20px',
    },
    button: { marginLeft: '0.75rem' },
    lockButtonContainer: {
      [theme.breakpoints.up('md')]: {
        marginRight: '10px',
      },
    },
    lockButton: {
      marginLeft: theme.spacing(4),
      '&:hover': {
        background: ({ isLocked }: IStyleProps) =>
          isLocked ? theme.palette.gray.main : theme.palette.gray.light,
      },
    },
    noteContainer: {
      fontFamily: interRegular.fontFamily,
      color: theme.palette.gray.dark,
      backgroundColor: theme.palette.gray.lighter,
      border: `1px solid ${theme.palette.gray.light}`,
      borderTop: 'none',
      borderBottomLeftRadius: '0.25rem',
      borderBottomRightRadius: '0.25rem',
    },
    projectNameWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    projectName: {
      display: 'inline-block',
      maxWidth: '8rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: theme.spacing(1),
    },
    actionMenu: {
      '&:hover': {
        background: theme.palette.gray.lighter,
        color: '#000000',
        borderColor: '#000000',
      },
    },
  })
);

export const FormulaDetails: React.FC = () => {
  const navigate = useNavigate();
  const { patchFormula } = useApi();
  const { formula, openAddIngredientModal, setFormula } = useContext(
    FormulaContext
  );
  const [dialogMode, setDialogMode] = useState<Dialog>(hidden);
  const { permissions } = useContext(UserContext)!;
  const { showSnackbar } = useSnackbar();
  const canLockFormula = permissions.includes(PERMISSIONS.LOCK_FORMULA);
  const canUnlockFormula = permissions.includes(PERMISSIONS.UNLOCK_FORMULA);
  const canArchiveFormula = permissions.includes(PERMISSIONS.ARCHIVE_FORMULA);
  const classes = useStyles({
    note: formula?.note,
    isLocked: formula?.locked,
  });

  if (!formula) {
    return null;
  }

  /* Handlers/state for more actions menu */
  const closeDialog = () => setDialogMode(hidden);

  const isDuplicationDialogOpen = dialogMode === duplicate;
  const isMarketingInfoDialogOpen = dialogMode === marketingInfo;
  const isStatusDialogOpen = dialogMode === status;
  const isArchiveDialogOpen = dialogMode === archive;
  const openDuplicationDialog = () => setDialogMode(duplicate);
  const openStatusDialog = () => setDialogMode(status);
  const openMarketingInfoDialog = () => setDialogMode(marketingInfo);
  const openArchiveDialog = () => setDialogMode(archive);

  const menuItems: ActionsMenuItem[] = [
    {
      label: 'Duplicate Formula',
      onClick: openDuplicationDialog,
    },
    {
      label: 'Update Status',
      onClick: openStatusDialog,
    },
    {
      label: 'Marketing Information',
      onClick: openMarketingInfoDialog,
    },
    {
      label: 'Archive Formula',
      canAccess: canArchiveFormula,
      onClick: openArchiveDialog,
    },
  ];

  const determineTooltip = () => {
    if (!formula.locked) {
      return 'Lock formula';
    } else if (formula.locked && canUnlockFormula) {
      return 'Unlock formula';
    } else {
      return 'Formula is locked from changes';
    }
  };

  const handleLockClick = async () => {
    if (formula?.locked && !canUnlockFormula) {
      alert('You do not have the necessary permissions to unlock a formula');
      return;
    }
    if (!formula?.locked && !canLockFormula) {
      alert('You do not have the necessary permissions to lock a formula');
      return;
    }
    if (!formula?.locked) {
      if (
        !window.confirm(
          'Locking the formula makes it un-editable. Would you like to continue?'
        )
      )
        return;
    }

    patchFormula({
      urlParams: formula.id,
      data: {
        formula: { locked: !formula.locked },
      },
      handleSuccess: (data: IApiData<FormulaAttributes>) => {
        formula &&
          setFormula({
            ...formula,
            locked: data.attributes.locked,
          });
        showSnackbar(
          `Formula successfully ${
            data.attributes.locked ? 'locked' : 'unlocked'
          }`,
          'success'
        );
      },
    });
  };

  const navigateToFormula = (formula: IApiData<FormulaAttributes>) => {
    navigate(
      ROUTES.SHOW_FORMULA.route.replace(UUID_SHOW_ROUTE_STRING, formula.id)
    );
  };

  return (
    <Grid container>
      <Grid container xs={12}>
        <FormulasDropdown
          formula={
            {
              attributes: ({ ...formula } as unknown) as FormulaAttributes,
              id: formula.id,
            } as IApiData<FormulaAttributes>
          }
          projectUuid={formula.projectUuid}
          handleClick={navigateToFormula}
        />
        <Grid alignItems="center" container xs={12} md={8} spacing={0}>
          <Grid
            container
            alignItems="center"
            item
            xs={12}
            className={classes.formulaName}
            style={{ flexGrow: 0 }}
          >
            <Grid item xs={10} sm={10} style={{ maxWidth: '90%' }}>
              <Tooltip title={formula.name}>
                <Typography variant="h1" noWrap>
                  {formula.name}
                </Typography>
              </Tooltip>
            </Grid>
            {(formula.locked || canLockFormula) && (
              <Grid item xs={1} className={classes.lockButtonContainer}>
                <Tooltip title={determineTooltip()}>
                  <IconButton
                    onClick={handleLockClick}
                    className={classes.lockButton}
                    style={{
                      color: formula.locked
                        ? THEME_PALETTE.secondary.main
                        : THEME_PALETTE.secondary.dark,
                      backgroundColor: formula.locked
                        ? THEME_PALETTE.secondary.dark
                        : THEME_PALETTE.gray.light,
                    }}
                  >
                    {formula.locked ? <Lock /> : <LockOpenOutlined />}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container xs={12} md={4} justifyContent="flex-end">
          <MoreActionsMenu menuItems={menuItems}>
            {({ anchor, toggleMenu }) => (
              <Button
                ref={anchor}
                onClick={toggleMenu}
                variant="outlined"
                color="secondary"
                className={classes.actionMenu}
              >
                More actions <ArrowDropDown style={{ width: '0.75em' }} />
              </Button>
            )}
          </MoreActionsMenu>
          {!formula.locked && (
            <Button
              size="large"
              className={classes.button}
              onClick={openAddIngredientModal}
            >
              Add ingredient
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.infoRow} container item xs={12}>
        <Grid className={classes.box} item xs={12} sm={6} md={4} lg={2}>
          <Typography variant="h4" className={classes.projectNameWrapper}>
            PROJECT:{' '}
            <Tooltip title={formula.projectName}>
              <Link
                className={classes.projectName + ' underline'}
                to={ROUTES.SHOW_PROJECT.route.replace(
                  UUID_SHOW_ROUTE_STRING,
                  formula.projectUuid
                )}
              >
                {formula.projectName}
              </Link>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={12} sm={6} md={4} lg={2}>
          <Tooltip title={formula.name}>
            <Typography variant="h4">
              RECIPE #:{' '}
              {formula.productionRecipeId ? formula.productionRecipeId : 'N/A'}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid className={classes.box} item xs={12} sm={6} md={4} lg={2}>
          <Typography variant="h4">
            COMPANY:{' '}
            <Link
              target="_blank"
              className="underline"
              to={ROUTES.EMPLOYEE_VIEW_CUSTOMER_DASHBOARD.route.replace(
                UUID_SHOW_ROUTE_STRING,
                formula.companyUuid
              )}
            >
              {formula.companyName}
            </Link>
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={12} sm={6} md={4} lg={2}>
          <Typography variant="h4">
            USAGE: {formula?.usage ? formula.usage : 'N/A'}
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={12} sm={6} md={4} lg={2}>
          <Typography variant="h4">
            STATUS: {formula?.status ? formula.status : 'N/A'}
          </Typography>
        </Grid>
        <FormulaActions />
      </Grid>
      {formula.note && (
        <FormulaNote
          note={formula.note}
          noteCreatedBy={formula.noteCreatedBy}
          noteCreatedOn={formula.noteCreatedOn}
          noteLastModifiedBy={formula.noteLastModifiedBy}
          noteLastModifiedOn={formula.noteLastModifiedOn}
        />
      )}
      <EditAndDuplicateFormulaDialog
        duplicateMode={true}
        handleClose={closeDialog}
        open={isDuplicationDialogOpen}
      />
      <StatusDialog handleClose={closeDialog} open={isStatusDialogOpen} />
      <MarketingInfoDialog
        handleClose={closeDialog}
        open={isMarketingInfoDialogOpen}
      />
      <ArchiveDialog
        formula={formula}
        open={isArchiveDialogOpen}
        handleClose={closeDialog}
      />
    </Grid>
  );
};
