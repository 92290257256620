// Libraries
import React, { useCallback, useState } from 'react';
// Components
import { Snackbar } from 'features/ui';
import { SnackbarContext } from './snackbar.context';
// Utils
import { SnackbarProps } from 'features/ui';
import { Color } from '@material-ui/lab';

interface ISnackbarContextProvider {
  children: React.ReactNode[] | React.ReactNode;
}

interface ISnackbarState extends Omit<SnackbarProps, 'handleClose'> {
  action?: Maybe<React.ReactNode>;
}

export const SnackbarContextProvider: React.FC<ISnackbarContextProvider> = ({
  children,
}) => {
  const INITIAL_SNACKBAR_STATE: ISnackbarState = {
    action: undefined,
    open: false,
    severity: 'error' as Color,
    message: undefined,
    duration: 4000,
  };

  const [snackbar, setSnackbar] = useState<ISnackbarState>({
    ...INITIAL_SNACKBAR_STATE,
  });

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = useCallback(
    (message?: string, severity?: Color, action?: React.ReactNode) => {
      setSnackbar({
        action: action,
        message: message,
        open: true,
        severity: severity || 'error',
        duration: 4000,
      });
    },
    [setSnackbar]
  );

  return (
    <SnackbarContext.Provider value={{ setSnackbar, showSnackbar }}>
      <Snackbar
        action={snackbar.action}
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        duration={snackbar.duration}
        handleClose={handleClose}
      />
      {children}
    </SnackbarContext.Provider>
  );
};
