// Libraries
import React from 'react';
// Components
import { Divider } from 'design-system';
import { Benchmarks } from './benchmarks.component';
import { ProductVision } from '../form-modules';

export const ConceptProductSpecific: React.FC = () => {
  return (
    <>
      <ProductVision />
      <Divider />
      <div id="benchmarks">
        <Benchmarks />
      </div>
    </>
  );
};
