// Libraries
import React, { useContext, useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Select,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
} from '@material-ui/core';

// Components
import { DialogModal } from 'features/ui';
import { FormulaContext } from '../context';
import { PurposesModule } from './purposes-module.component';
import { RawMaterialSearch } from '../formula-search';

// Utils
import {
  ExtendedFormulaAttributes,
  Ingredient,
  IRawMaterialFormValues,
} from './types';
import { useApi } from 'api';
import { useSnackbar } from 'hooks';

// Constants
import { INITIAL_RAW_MATERIAL_FORM_VALUES } from './constants';
import { ITheme } from 'styles/mui/themeV2';

export const PHASE_ALPHABET = Array.from(Array(26)).map((_, i) =>
  String.fromCharCode(i + 65)
);

const INITIAL_INGREDIENT_FORM_VALUES: IIngredientInputs = {
  phase: 'A',
  lotNumber: '',
  amount: 0.0,
};

// Types
interface IIngredientInputs {
  phase: string;
  lotNumber: string;
  amount: number;
}

interface IAddIngredientModal {
  closeModal: () => void;
  ingredient?: Ingredient;
  ingredientIndex?: number;
  isOpen: boolean;
  phaseIndex?: number;
}

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    divider: { color: theme.palette.gray.main },
    usApiLabel: {
      marginBottom: theme.spacing(3),
      color: theme.palette.secondary.dark,
    },
  })
);

export const AddOrEditIngredientModal: React.FC<IAddIngredientModal> = ({
  closeModal,
  ingredient,
  ingredientIndex,
  isOpen,
  phaseIndex,
}) => {
  const classes = useStyles();
  const isEditMode = !!ingredient;
  const { formula, setFormula } = useContext(FormulaContext);
  const { showSnackbar } = useSnackbar();
  const { patchIngredient, postFormulaIngredients } = useApi();
  const lastPhaseName =
    formula?.phases.at(-1)?.name || INITIAL_INGREDIENT_FORM_VALUES.phase;

  //  SEARCH STATE
  const [selectedRawMaterial, setSelectedRawMaterial] = useState<
    IRawMaterialFormValues
  >(
    isEditMode
      ? {
          id: ingredient.rmUuid,
          name: ingredient.name,
          rmId: ingredient.rmId,
          sampleCode: ingredient.sampleCode,
        }
      : { ...INITIAL_RAW_MATERIAL_FORM_VALUES }
  );
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(false);

  // INPUT STATE
  const [ingredientInputs, setIngredientInputs] = useState<IIngredientInputs>(
    isEditMode
      ? {
          lotNumber: ingredient.lotNumber,
          amount: ingredient.amount,
          phase: formula!.phases[phaseIndex as number].id,
        }
      : {
          ...INITIAL_INGREDIENT_FORM_VALUES,
          phase: lastPhaseName,
        }
  );

  const { phase, lotNumber, amount } = ingredientInputs;

  // ACTIVE INGREDIENT INPUT STATE
  const [usApiDrugPurposes, setUsApiDrugPurposes] = useState<string[]>([]);

  useEffect(() => {
    if (!isOpen && !isEditMode) {
      // Resetting inputs when we close the modal
      setIngredientInputs({
        ...INITIAL_INGREDIENT_FORM_VALUES,
        phase: lastPhaseName,
      });
      setSelectedRawMaterial({
        ...INITIAL_RAW_MATERIAL_FORM_VALUES,
      });
      setUsApiDrugPurposes([]);
      setIsSearchDisabled(false);
    }
  }, [isOpen, isEditMode, lastPhaseName]);

  if (!formula) return null;

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setIngredientInputs({
      ...ingredientInputs,
      [e.target.name as any]: e.target.value,
    });
  };

  const handleUpdateIngredient = () => {
    patchIngredient({
      urlParams: ingredient!.id,
      data: {
        ingredient: {
          ...ingredientInputs,
          active: false,
          key: false,
          mayContain: null,
          solvent: null,
          rawMaterialUuid: selectedRawMaterial.id,
          phaseUuid: formula.phases[phaseIndex as number].id,
          usApiDrugPurposes: [],
        },
      },
      handleSuccess: (data) => {
        // Destructure from response
        const {
          active,
          amount,
          highestPrice,
          lotNumber,
          manufacturer,
          position,
          rawMaterialEuIncis,
          rawMaterialName,
          rawMaterialRmId,
          rawMaterialUsIncis,
          rawMaterialUuid,
          rawMaterialSampleCode,
          supplier,
          usApiDrugPurposes,
        } = data.attributes;

        // Grab phase ID from response
        const phaseId = data.relationships.phase.data.id;

        // Build the new ingredient
        const newIngredient: Ingredient = {
          active,
          euIncis: rawMaterialEuIncis,
          id: data.id,
          key: false,
          lotNumber,
          manufacturer,
          name: rawMaterialName,
          amount: parseFloat(amount),
          phaseUuid: phaseId,
          position: position,
          price: highestPrice,
          rmId: rawMaterialRmId,
          rmUuid: rawMaterialUuid,
          sampleCode: rawMaterialSampleCode,
          supplier,
          usApiDrugPurposes,
          usIncis: rawMaterialUsIncis,
        } as Ingredient;
        const formulaCopy = { ...formula };
        formulaCopy.phases[phaseIndex as number].ingredients[
          ingredientIndex as number
        ] = newIngredient;
        setFormula(formulaCopy);
        showSnackbar(`${newIngredient.name} added to formula`, 'success');
      },
    });
  };

  const handleCreateIngredient = () => {
    postFormulaIngredients({
      urlParams: `${formula?.id}/ingredients`,
      data: {
        ingredient: {
          ...ingredientInputs,
          rawMaterialUuid: selectedRawMaterial.id,
          usApiDrugPurposes,
          active: usApiDrugPurposes.length > 0,
        },
      },
      handleSuccess: (data) => {
        // Destructure from response
        const {
          active,
          amount,
          highestPrice,
          lotNumber,
          manufacturer,
          position,
          rawMaterialEuIncis,
          rawMaterialName,
          rawMaterialRmId,
          rawMaterialUsIncis,
          rawMaterialUuid,
          rawMaterialSampleCode,
          supplier,
          usApiDrugPurposes,
        } = data.attributes;

        // Grab phase ID from response
        const phaseId = data.relationships.phase.data.id;

        // Build the new ingredient
        const newIngredient: Ingredient = {
          active,
          euIncis: rawMaterialEuIncis,
          id: data.id,
          key: undefined,
          lotNumber,
          manufacturer,
          name: rawMaterialName,
          amount: parseFloat(amount),
          phaseUuid: phaseId,
          position: position,
          price: highestPrice,
          rmId: rawMaterialRmId,
          rmUuid: rawMaterialUuid,
          sampleCode: rawMaterialSampleCode,
          supplier,
          usApiDrugPurposes,
          usIncis: rawMaterialUsIncis,
        } as Ingredient;

        // from the existing phases in state try to find the phase
        const foundPhase = formula.phases.find((phase) => phase.id === phaseId);

        if (foundPhase) {
          // Just add a new ingredient to the existing phase
          foundPhase.ingredients.push(newIngredient);
        } else {
          // Add both the new phase and the new ingredient in that phase's ingredients
          formula.phases.push({
            id: phaseId,
            name: phase,
            ingredients: [newIngredient],
          });
        }

        // reassign positions on the frontend
        formula.phases
          .sort(({ name: aName }, { name: bName }) => {
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
          })
          .flatMap((ph) => ph.ingredients)
          .forEach((ing, idx) => (ing.position = idx + 1));

        // Finally add the new ing/phase to the formula state
        setFormula({
          ...formula,
          phases: [...formula.phases],
        } as ExtendedFormulaAttributes);
        showSnackbar(`${newIngredient.name} added to formula`, 'success');
      },
      handleFinally: closeModal,
    });
  };

  // Submit is disabled until phase, weight, and raw material is selected
  const isSubmitDisabled = !phase || !amount || !selectedRawMaterial.id;

  return (
    <DialogModal
      title={isEditMode ? 'Change ingredient' : 'Add ingredient'}
      submitDisabled={isSubmitDisabled}
      onConfirm={isEditMode ? handleUpdateIngredient : handleCreateIngredient}
      handleClose={closeModal}
      open={isOpen}
    >
      <Grid container spacing={4}>
        {!isEditMode && (
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="phase-select-label">Phase</InputLabel>
              <Select
                variant="outlined"
                fullWidth
                labelId="phase-select-label"
                label="Phase"
                id="phase-select"
                name="phase"
                value={phase}
                onChange={handleChange}
              >
                {PHASE_ALPHABET.map((phaseLetter, index) => (
                  <MenuItem key={phaseLetter + index} value={phaseLetter}>
                    Phase {phaseLetter}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RawMaterialSearch
              isSearchDisabled={isSearchDisabled}
              rawMaterialFormValues={selectedRawMaterial}
              setIsSearchDisabled={setIsSearchDisabled}
              setRawMaterialFormValues={setSelectedRawMaterial}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              label="Lot #"
              name="lotNumber"
              value={lotNumber}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              type="number"
              inputProps={{
                min: 0.0,
                max: 100.0,
              }}
              variant="outlined"
              label="Weight %"
              name="amount"
              required
              value={amount}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        {!isEditMode && (
          <>
            <Grid item xs={12}>
              <hr className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                id="us-api-select-label"
                className={classes.usApiLabel}
              >
                Mark Active Pharmaceutical Ingredient (Optional)
              </InputLabel>
              <PurposesModule
                isLocked={false}
                handlePurposesChange={setUsApiDrugPurposes}
                usApiDrugPurposes={usApiDrugPurposes}
              />
            </Grid>
          </>
        )}
      </Grid>
    </DialogModal>
  );
};
