// Libraries
import React, { createContext } from 'react';

export const LeftNavContext = createContext<{
  isLeftNavOpen: boolean;
  setIsLeftNavOpen: React.Dispatch<React.SetStateAction<any>>;
}>({
  isLeftNavOpen: false,
  setIsLeftNavOpen: () => {},
});
