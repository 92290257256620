// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
import * as amplitude from '@amplitude/analytics-browser';
// Components
import { Button } from 'design-system';
import { ScrollIntoView } from './scroll-into-view.component';
// Utils
import { CustomerBriefFormValues } from '../internal-brief/form.config';
import { useRtlIconMap } from '../rtl-logic';
import { useConfirmation } from 'hooks';
import {
  BRIEF_FORM_KEYS,
  RESTART_CONFIRM_STATE,
  RTL_CONFIRM_STATE,
  YES_NO_CHOICE,
} from '../brief.constants';
// Assets
import { ReactComponent as ReadyToLaunchIcon } from '../../../img/ready-to-launch.svg';

interface SiliconeFreeProps {
  projectIsRtl?: boolean;
}

export const SiliconeFree: React.FC<SiliconeFreeProps> = ({ projectIsRtl }) => {
  const { getFieldProps, setFieldValue, values } = useFormikContext<
    CustomerBriefFormValues
  >();

  const rtlIconMap = useRtlIconMap({
    values,
    moduleKey: BRIEF_FORM_KEYS.SILICONE_FREE,
  });
  const { confirm } = useConfirmation();

  const handleNonRtlClick = async (value: string) => {
    const shouldProceed = await confirm(RTL_CONFIRM_STATE);

    if (shouldProceed) {
      amplitude.track('Confirmed Leaving RTL', {
        field: 'Silicone Free',
        value,
      });
      setFieldValue(BRIEF_FORM_KEYS.SILICONE_FREE, value);
    } else {
      amplitude.track('Cancelled Leaving RTL', {
        field: 'Silicone Free',
        value,
      });
      return;
    }
  };

  const siliconeFreeIsRtl = rtlIconMap[BRIEF_FORM_KEYS.SILICONE_FREE]
    ? rtlIconMap[BRIEF_FORM_KEYS.SILICONE_FREE][YES_NO_CHOICE.YES]
    : false;
  const nonSiliconeFreeIsRtl = rtlIconMap[BRIEF_FORM_KEYS.SILICONE_FREE]
    ? rtlIconMap[BRIEF_FORM_KEYS.SILICONE_FREE][YES_NO_CHOICE.NO]
    : false;

  return (
    <ScrollIntoView>
      {({ ref }) => (
        <div ref={ref} className="flex flex-col gap-3">
          <p className="font-inter text-base font-bold text-grey-20">
            Silicone Free
          </p>
          <div className="flex items-center space-x-3">
            <Button
              additionalStyles={siliconeFreeIsRtl ? 'py-3 px-7' : 'py-3 px-10'}
              text="Yes"
              size="large"
              type="secondary"
              data-testid={`silicone-free-${YES_NO_CHOICE.YES}`}
              {...getFieldProps(BRIEF_FORM_KEYS.SILICONE_FREE)}
              leadingIcon={
                siliconeFreeIsRtl ? (
                  <ReadyToLaunchIcon
                    height="4"
                    width="4"
                    className="bg-peach-50"
                  />
                ) : undefined
              }
              selected={
                values[BRIEF_FORM_KEYS.SILICONE_FREE] === YES_NO_CHOICE.YES
              }
              action={
                siliconeFreeIsRtl || !projectIsRtl
                  ? async () => {
                      if (values.siliconeFree && values.countries.length > 0) {
                        const shouldProceed = await confirm(
                          RESTART_CONFIRM_STATE
                        );

                        if (!shouldProceed) return;
                      }
                      setFieldValue(
                        BRIEF_FORM_KEYS.SILICONE_FREE,
                        YES_NO_CHOICE.YES
                      );
                    }
                  : () => handleNonRtlClick(YES_NO_CHOICE.YES)
              }
              checked={
                values[BRIEF_FORM_KEYS.SILICONE_FREE] === YES_NO_CHOICE.YES
              }
            />
            <Button
              additionalStyles={
                nonSiliconeFreeIsRtl ? 'py-3 px-7' : 'py-3 px-10'
              }
              text="No"
              size="large"
              type="secondary"
              data-testid={`silicone-free-${YES_NO_CHOICE.NO}`}
              {...getFieldProps(BRIEF_FORM_KEYS.SILICONE_FREE)}
              leadingIcon={
                nonSiliconeFreeIsRtl ? (
                  <ReadyToLaunchIcon className="bg-peach-50 h-4 w-4" />
                ) : undefined
              }
              selected={
                values[BRIEF_FORM_KEYS.SILICONE_FREE] === YES_NO_CHOICE.NO
              }
              action={
                nonSiliconeFreeIsRtl || !projectIsRtl
                  ? async () => {
                      if (values.siliconeFree && values.countries.length > 0) {
                        const shouldProceed = await confirm(
                          RESTART_CONFIRM_STATE
                        );

                        if (!shouldProceed) return;
                      }

                      setFieldValue(
                        BRIEF_FORM_KEYS.SILICONE_FREE,
                        YES_NO_CHOICE.NO
                      );
                    }
                  : () => handleNonRtlClick(YES_NO_CHOICE.NO)
              }
              checked={
                values[BRIEF_FORM_KEYS.SILICONE_FREE] === YES_NO_CHOICE.NO
              }
            />
          </div>
        </div>
      )}
    </ScrollIntoView>
  );
};
