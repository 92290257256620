// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton, TextArea } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const Otc: React.FC = () => {
  const { getFieldProps, values } = useFormikContext<InternalBriefFormValues>();
  return (
    <div className="flex flex-col gap-6">
      <p id="otc-claims" className="font-inter text-lg text-grey-20">
        Does your product qualify as over-the-counter (OTC)?
      </p>

      <p className="font-inter text-base font-normal text-grey-50">
        Examples of over-the-counter (OTC) claims include SPF / sunscreen, acne
        treatment, and antiperspirant. Read more on OTC designations{' '}
        <a
          href="https://www.primematterlabs.com/resources/difference-between-cosmetic-and-OTC/"
          target="_blank"
          rel="noreferrer"
          className="text-royal-50"
        >
          here
        </a>
        .
      </p>
      <div className="flex flex-col gap-3">
        <div>
          <RadioButton
            {...getFieldProps(BRIEF_FORM_KEYS.OTC)}
            value="Yes"
            primaryLabel="Yes"
            name="otc"
            checked={values.otc === 'Yes'}
          />
        </div>
        <div>
          <RadioButton
            {...getFieldProps(BRIEF_FORM_KEYS.OTC)}
            value="No"
            primaryLabel="No"
            name="otc"
            checked={values.otc === 'No'}
          />
        </div>
        <div>
          <RadioButton
            {...getFieldProps(BRIEF_FORM_KEYS.OTC)}
            value="Unsure"
            primaryLabel="Unsure"
            name="otc"
            checked={values.otc === 'Unsure'}
          />
        </div>
        <TextArea
          {...getFieldProps(BRIEF_FORM_KEYS.OTC_NOTE)}
          placeholder="Additional comments"
        />
      </div>
    </div>
  );
};
