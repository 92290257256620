// Libraries
import React, { useState } from 'react';
import {
  Button,
  createStyles,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
// Utils
import { useApi } from 'hooks';
import { ITheme } from 'styles/mui/themeV2';
import { IRawMaterialFormValues } from './raw-material-form.component';
// Constants
import * as Constants from 'features/constants';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    button: { width: '50%', whiteSpace: 'nowrap' },
    sampleCodeInput: {
      paddingRight: theme.spacing(2),
    },
  })
);

export const IdModule: React.FC = () => {
  const request = useApi();
  const classes = useStyles();
  const {
    values: { general },
    setFieldValue,
    errors,
  } = useFormikContext<IRawMaterialFormValues>();

  const [isGeneratingSampleCode, setIsGeneratingSampleCode] = useState<boolean>(
    false
  );

  const handleGenerateSampleCode = async () => {
    (async () => {
      try {
        setIsGeneratingSampleCode(true);
        const apiResponse = await request({
          resource: '/api/v2/raw_materials?total_count="true"',
          options: {
            settings: Constants.GET,
            scope: 'read:raw_materials',
          },
        });

        if (apiResponse.totalCount) {
          setFieldValue('general.sampleCode', apiResponse.totalCount + 1);
        } else {
          throw new Error('There was an unexpected problem');
        }
      } catch (error) {
        Rollbar.error(error);
      } finally {
        setIsGeneratingSampleCode(false);
      }
    })();
  };

  return (
    <>
      <Grid item xs={6}>
        <TextField
          name="rmId"
          id="rmId"
          label="RM code"
          variant="outlined"
          helperText={
            Boolean(errors?.general?.sampleCode)
              ? 'RM Code OR Sample Code is required'
              : ''
          }
          error={Boolean(errors?.general?.sampleCode)}
          fullWidth
          required={!Boolean(general.sampleCode)} // only require one or the other
          value={general.rmId}
          onChange={(event) =>
            setFieldValue('general.rmId', event.target.value)
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="sampleCode"
          label="Sample code"
          name="sampleCode"
          onChange={(event) =>
            setFieldValue('general.sampleCode', event.target.value)
          }
          required={!Boolean(general.rmId)} // only require one or the other
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={general.sampleCode}
          helperText={
            Boolean(errors?.general?.sampleCode)
              ? 'RM Code OR Sample Code is required'
              : 'Numbers only'
          }
          error={Boolean(errors?.general?.sampleCode)}
          InputProps={{
            className: classes.sampleCodeInput,
            startAdornment: 'SAMP-',
            endAdornment: isGeneratingSampleCode ? (
              <InputAdornment position="start">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : (
              <Button
                disableElevation
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={isGeneratingSampleCode || Boolean(general.sampleCode)}
                onClick={handleGenerateSampleCode}
                size="small"
                fullWidth
              >
                Generate code
              </Button>
            ),
          }}
          variant="outlined"
        />
      </Grid>
    </>
  );
};
