// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Checkbox } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

type Claim = {
  label: string;
  formikKey: string;
  column?: string;
};

const skinClaims = [
  { label: 'Brightening', formikKey: BRIEF_FORM_KEYS.BRIGHTENING },
  { label: 'Firming', formikKey: BRIEF_FORM_KEYS.FIRMING },
  { label: 'Hydration', formikKey: BRIEF_FORM_KEYS.HYDRATION },
  { label: 'Mattifying', formikKey: BRIEF_FORM_KEYS.MATTIFYING },
  { label: 'Moisturization', formikKey: BRIEF_FORM_KEYS.MOISTURIZATION },
  { label: 'Radiance/glow', formikKey: BRIEF_FORM_KEYS.RADIANCE_GLOW },
  { label: 'Self Tanner', formikKey: BRIEF_FORM_KEYS.SELF_TANNER },
  { label: 'Smoothing', formikKey: BRIEF_FORM_KEYS.SKIN_SMOOTHING },
  { label: 'Tightening', formikKey: BRIEF_FORM_KEYS.TIGHTENING },
  {
    label: 'Eyes: Reduce appearance of dark circles',
    formikKey: BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_DARK_CIRCLES,
    column: '2',
  },
  {
    label: 'Eyes: Reduce appearance of Fine line/crows feet',
    formikKey: BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_LINE_CROWS_FEET,
    column: '2',
  },
  {
    label: 'Eyes: Reduce appearance of puffiness',
    formikKey: BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_PUFFINESS,
    column: '2',
  },
];

const hairClaims = [
  { label: 'Anti-frizz', formikKey: BRIEF_FORM_KEYS.ANTI_FRIZZ },
  { label: 'Conditioning', formikKey: BRIEF_FORM_KEYS.CONDITIONING },
  { label: 'Curl defining', formikKey: BRIEF_FORM_KEYS.CURL_DEFINING },
  { label: 'Deep conditioning', formikKey: BRIEF_FORM_KEYS.DEEP_CONDITIONING },
  { label: 'Detangle', formikKey: BRIEF_FORM_KEYS.DETANGLE },
  { label: 'Hard hold', formikKey: BRIEF_FORM_KEYS.HARD_HOLD },
  { label: 'Light hold', formikKey: BRIEF_FORM_KEYS.LIGHT_HOLD },
  { label: 'Moisture', formikKey: BRIEF_FORM_KEYS.MOISTURE },
  { label: 'Shine', formikKey: BRIEF_FORM_KEYS.SHINE },
  { label: 'Smoothing', formikKey: BRIEF_FORM_KEYS.HAIR_SMOOTHING },
  { label: 'Strengthening', formikKey: BRIEF_FORM_KEYS.STRENGTHENING },
];

const CLAIM_TYPE = {
  SKIN: {
    label: 'skin',
    claims: skinClaims,
  },
  HAIR: {
    label: 'hair',
    claims: hairClaims,
  },
};

export const Benefits = () => {
  const { values, getFieldProps } = useFormikContext<InternalBriefFormValues>();

  const renderClaims = (type: string) => {
    let claims =
      Object.values(CLAIM_TYPE).find((claim) => claim.label === type)?.claims ||
      [];

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 justify-between">
        {claims?.map((claim: Claim) => (
          <div
            className={`${
              claim.column ? `md:col-start-${claim.column} md:col-span-1` : ''
            }`}
            key={claim.formikKey}
          >
            <Checkbox
              {...getFieldProps(claim.formikKey)}
              primaryLabel={claim.label}
              checked={values[claim.formikKey as keyof InternalBriefFormValues]}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-6">
      <p id="benefits" className="font-inter text-lg text-grey-20 ">
        Benefits
      </p>

      <p className="text-grey-50 font-inter text-base font-normal ">
        Attributes supported by ingredients in the formula.
      </p>

      <div>
        <div className="mb-6">
          <p className="font-inter text-base font-bold mb-3">Skin</p>
          {renderClaims(CLAIM_TYPE.SKIN.label)}
        </div>

        <div>
          <p className="font-inter text-base font-bold mb-3">Hair</p>
          {renderClaims(CLAIM_TYPE.HAIR.label)}
        </div>
      </div>
    </div>
  );
};
