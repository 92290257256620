// Libraries
import React, { useState } from 'react';
import { XCircleIcon } from '@heroicons/react/16/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import _ from 'lodash';
// Components
import { TextField } from 'design-system';
import { Menu } from 'design-system/select.component';
// Utils

interface LocalSearchProps {
  clearField?: () => void;
  label?: string;
  required?: boolean;
  options: Option[];
  selectedOptions?: Option[];
  [x: string]: any;
}

export const LocalSearch: React.FC<LocalSearchProps> = ({
  clearField,
  label,
  required,
  options,
  selectedOptions,
  ...rest
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  // Handles for typing into the input
  const handleAutocompleteChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValue(e.target.value);
    setIsMenuOpen(true);
  };

  // Handles clearing the input when the user presses backspace
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (rest.value && e.key === 'Backspace') {
      clearField && clearField();
      setInputValue('');
    }
  };

  // Handles selecting an option from the menu
  const handleOptionSelection = (option: Option) => {
    rest.onChange(option);
    setInputValue(option.label);
    setIsMenuOpen(false);
  };

  const handleClear = () => {
    clearField && clearField();
    setInputValue('');
  };

  return (
    <div className="relative">
      <TextField
        required={required}
        label={label}
        value={
          // The value prop is passed down by Formik as an object with a shape of { label: string, value: string }
          // So in order to find the display value, we need to find the option with the same value
          // Hence value.value
          options.find(
            (option) =>
              option.value === rest.value?.value || option.value === rest.value
          )?.label ||
          rest.value?.label ||
          inputValue
        }
        onChange={handleAutocompleteChange}
        onKeyDown={handleOnKeyDown}
        trailingIcon={
          rest.diabled ? (
            rest.value ? (
              <XCircleIcon
                className="cursor-pointer"
                onClick={() => handleClear()}
                height="20"
                width="20"
              />
            ) : (
              <MagnifyingGlassIcon
                className="text-grey-50"
                height="20"
                width="20"
              />
            )
          ) : null
        }
        {..._.omit(rest, ['onChange', 'value'])}
        height={null}
        disabled={rest.disabled}
      />
      {isMenuOpen && (
        <Menu
          isLoading={isLoading}
          name={rest.name}
          onChange={handleOptionSelection}
          options={options}
          onClose={() => setIsMenuOpen(false)}
        />
      )}
    </div>
  );
};
