import React from 'react';

interface ProgressTrackerProps {
  percentage: number;
}

export const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  percentage,
}) => {
  return (
    <div
      className={`absolute left-0 bg-royal-60 h-1 transition-[width] duration-1000 rounded-r`}
      style={{
        width: `${percentage}%`,
      }}
    />
  );
};
