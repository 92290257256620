export const SECTIONS = {
  ORDER_QTY: 'orderQty',
  FORMULA: 'formula',
  LABOR: 'labor',
  COMPONENTS: 'components',
  MARGIN: 'margin',
};

export const dummySummaryData = [
  {
    title: 'Tank Size (Gallons)',
    values: ['1000', '1500', '2000'],
  },
  {
    title: 'Unit Output',
    values: ['16,000', '24,000', '32,000'],
  },
  {
    title: 'Contribution per Run',
    values: ['$38,880', '$58,080', '$71,040'],
  },
  {
    title: 'Total Excess Raws',
    values: ['$2,808.12', '$2,574.80', '$2,824.56'],
  },
  {
    title: '% Excess Raws',
    values: ['0.72%', '0.44%', '0.36%'],
  },
];

export const WORKSHEET_FORM_KEYS = {
  ADDITIONAL_COMPONENT_ONE_A: 'additionalComponentOneA',
  ADDITIONAL_COMPONENT_ONE_B: 'additionalComponentOneB',
  ADDITIONAL_COMPONENT_ONE_C: 'additionalComponentOneC',
  ADDITIONAL_COMPONENT_TWO_A: 'additionalComponentTwoA',
  ADDITIONAL_COMPONENT_TWO_B: 'additionalComponentTwoB',
  ADDITIONAL_COMPONENT_TWO_C: 'additionalComponentTwoC',
  ADDITIONAL_COMPONENT_THREE_A: 'additionalComponentThreeA',
  ADDITIONAL_COMPONENT_THREE_B: 'additionalComponentThreeB',
  ADDITIONAL_COMPONENT_THREE_C: 'additionalComponentThreeC',
  CAPPING_HEAD_COUNT_ONE: 'cappingHeadCountOne',
  CAPPING_HEAD_COUNT_THREE: 'cappingHeadCountThree',
  CAPPING_HEAD_COUNT_TWO: 'cappingHeadCountTwo',
  COMPOUNDING_HEAD_COUNT_ONE: 'compoundingHeadCountOne',
  COMPOUNDING_HEAD_COUNT_THREE: 'compoundingHeadCountThree',
  COMPOUNDING_HEAD_COUNT_TWO: 'compoundingHeadCountTwo',
  EFFICIENCY_PERCENTAGE_ONE: 'efficiencyPercentageOne',
  EFFICIENCY_PERCENTAGE_THREE: 'efficiencyPercentageThree',
  EFFICIENCY_PERCENTAGE_TWO: 'efficiencyPercentageTwo',
  FILL_SIZE_ONE: 'fillSizeOne',
  FILL_SIZE_THREE: 'fillSizeThree',
  FILL_SIZE_TWO: 'fillSizeTwo',
  MASTER_BOX_ONE: 'masterBoxOne',
  MASTER_BOX_THREE: 'masterBoxThree',
  MASTER_BOX_TWO: 'masterBoxTwo',
  OPERATOR_HEAD_COUNT_ONE: 'operatorHeadCountOne',
  OPERATOR_HEAD_COUNT_THREE: 'operatorHeadCountThree',
  OPERATOR_HEAD_COUNT_TWO: 'operatorHeadCountTwo',
  ORDER_QUANTITY_ONE: 'orderQuantityOne',
  ORDER_QUANTITY_THREE: 'orderQuantityThree',
  ORDER_QUANTITY_TWO: 'orderQuantityTwo',
  PACKING_PALLETIZER_HEAD_COUNT_ONE: 'packingPalletizerHeadCountOne',
  PACKING_PALLETIZER_HEAD_COUNT_THREE: 'packingPalletizerHeadCountThree',
  PACKING_PALLETIZER_HEAD_COUNT_TWO: 'packingPalletizerHeadCountTwo',
  PREWORK_HEAD_COUNT_ONE: 'preworkHeadCountOne',
  PREWORK_HEAD_COUNT_THREE: 'preworkHeadCountThree',
  PREWORK_HEAD_COUNT_TWO: 'preworkHeadCountTwo',
  PROFIT_ONE: 'profitOne',
  PROFIT_THREE: 'profitThree',
  PROFIT_TWO: 'profitTwo',
  RUN_RATE_ONE: 'runRateOne',
  RUN_RATE_THREE: 'runRateThree',
  RUN_RATE_TWO: 'runRateTwo',
  TOTAL_HEAD_COUNT_ONE: 'totalHeadCountOne',
  TOTAL_HEAD_COUNT_THREE: 'totalHeadCountThree',
  TOTAL_HEAD_COUNT_TWO: 'totalHeadCountTwo',
  UNIT_CARTON_HEAD_COUNT_ONE: 'unitCartonHeadCountOne',
  UNIT_CARTON_HEAD_COUNT_THREE: 'unitCartonHeadCountThree',
  UNIT_CARTON_HEAD_COUNT_TWO: 'unitCartonHeadCountTwo',
  INGREDIENTS: 'ingredients',
};

export const dummyIngredients = [
  {
    rmCode: 'RMN24',
    tradeName: 'Water',
    weight: '70.815',
    pricePerLb: '1.20',
    pricePerLbOfFormula: '2.94534',
    supplier: 'Ampak Company, Inc.',
  },
  {
    rmCode: 'RME48',
    tradeName: 'Cellosize Texture E4M PRM',
    weight: '2.1',
    pricePerLb: '0.40',
    pricePerLbOfFormula: '7.23000',
    supplier: 'Jeen International Corp.',
  },
  {
    rmCode: 'RMA06',
    tradeName: 'Pemulen TR 1 Polymer',
    weight: '0.3',
    pricePerLb: '0.25',
    pricePerLbOfFormula: '0.01145',
    supplier: 'Ashland Specialty Ingredients',
  },
  {
    rmCode: 'RMA06',
    tradeName: 'Pemulen TR 1 Polymer',
    weight: '0.3',
    pricePerLb: '0.25',
    pricePerLbOfFormula: '0.01145',
    supplier: 'Ashland Specialty Ingredients',
  },
  {
    rmCode: 'RMA06',
    tradeName: 'Pemulen TR 1 Polymer',
    weight: '0.3',
    pricePerLb: '0.25',
    pricePerLbOfFormula: '0.01145',
    supplier: 'Ashland Specialty Ingredients',
  },
  {
    rmCode: 'RMA06',
    tradeName: 'Pemulen TR 1 Polymer',
    weight: '0.3',
    pricePerLb: '0.25',
    pricePerLbOfFormula: '0.01145',
    supplier: 'Ashland Specialty Ingredients',
  },
  {
    rmCode: 'RMA06',
    tradeName: 'Pemulen TR 1 Polymer',
    weight: '0.3',
    pricePerLb: '0.25',
    pricePerLbOfFormula: '0.01145',
    supplier: 'Ashland Specialty Ingredients',
  },
  {
    rmCode: 'RMA06',
    tradeName: 'Pemulen TR 1 Polymer',
    weight: '0.3',
    pricePerLb: '0.25',
    pricePerLbOfFormula: '0.01145',
    supplier: 'Ashland Specialty Ingredients',
  },
  {
    rmCode: 'RMA06',
    tradeName: 'Pemulen TR 1 Polymer',
    weight: '0.3',
    pricePerLb: '0.25',
    pricePerLbOfFormula: '0.01145',
    supplier: 'Ashland Specialty Ingredients',
  },
];

export const orderQuantityData = {
  title: 'Order Qty',
  formikKeys: [
    WORKSHEET_FORM_KEYS.ORDER_QUANTITY_ONE,
    WORKSHEET_FORM_KEYS.ORDER_QUANTITY_TWO,
    WORKSHEET_FORM_KEYS.ORDER_QUANTITY_THREE,
  ],
};

export const componentCosts = [
  {
    title: 'Master Box',
    formikKeys: [
      WORKSHEET_FORM_KEYS.MASTER_BOX_ONE,
      WORKSHEET_FORM_KEYS.MASTER_BOX_TWO,
      WORKSHEET_FORM_KEYS.MASTER_BOX_THREE,
    ],
    leadingText: '$',
  },
  {
    title: "Add'l Component 1",
    formikKeys: [
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_A,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_B,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_C,
    ],
    leadingText: '$',
  },
  {
    title: "Add'l Component 2",
    formikKeys: [
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_A,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_B,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_C,
    ],
    leadingText: '$',
  },
  {
    title: "Add'l Component 3",
    formikKeys: [
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_A,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_B,
      WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_C,
    ],
    leadingText: '$',
  },
];

export const laborCostInputs = [
  {
    title: 'Efficiency %',
    formikKeys: [
      WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_ONE,
      WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_TWO,
      WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_THREE,
    ],
    trailingText: '%',
  },
  {
    title: 'Run rate per minute',
    formikKeys: [
      WORKSHEET_FORM_KEYS.RUN_RATE_ONE,
      WORKSHEET_FORM_KEYS.RUN_RATE_TWO,
      WORKSHEET_FORM_KEYS.RUN_RATE_THREE,
    ],
  },
  {
    title: 'Head Count',
  },
  {
    title: 'Compounding',
    formikKeys: [
      WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_ONE,
      WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_TWO,
      WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_THREE,
    ],
  },
  {
    title: 'Prework',
    formikKeys: [
      WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_ONE,
      WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_TWO,
      WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_THREE,
    ],
  },
  {
    title: 'Operator',
    formikKeys: [
      WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_ONE,
      WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_TWO,
      WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_THREE,
    ],
  },
  {
    title: 'Capping',
    formikKeys: [
      WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_ONE,
      WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_TWO,
      WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_THREE,
    ],
  },
  {
    title: 'Unit Carton',
    formikKeys: [
      WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_ONE,
      WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_TWO,
      WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_THREE,
    ],
  },
  {
    title: 'Packing/Palletizer',
    formikKeys: [
      WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_ONE,
      WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_TWO,
      WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_THREE,
    ],
  },
];
