//Libraries
import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
// Utils
import { IApiData } from 'features/types';

interface InciVerificationProps {
  ingredientOrRawMaterial: IApiData;
  inciType: string;
}

const useStyles = makeStyles({
  doneIcon: {
    color: '#00b300',
  },
});

export const InciVerification: React.FC<InciVerificationProps> = (props) => {
  const classes = useStyles();

  const getVerification = (ingredientOrRawMaterial: IApiData) => {
    const areIncisVerified =
      ingredientOrRawMaterial?.attributes?.[`${props.inciType}IncisVerified`];
    const areInciFactorsVerified =
      ingredientOrRawMaterial?.attributes?.[
        `${props.inciType}InciFactorsVerified`
      ];

    if (areIncisVerified && areInciFactorsVerified) {
      return (
        <Tooltip title="INCIS verified">
          <DoneAllIcon className={classes.doneIcon} />
        </Tooltip>
      );
    } else if (areInciFactorsVerified) {
      return (
        <Tooltip title="INCI factors verified">
          <DoneIcon className={classes.doneIcon} />
        </Tooltip>
      );
    } else if (areIncisVerified) {
      return (
        <Tooltip title="INCI names verified">
          <DoneIcon className={classes.doneIcon} />
        </Tooltip>
      );
    } else return '';
  };

  return (
    <>
      <br />
      {getVerification(props.ingredientOrRawMaterial)}
    </>
  );
};
