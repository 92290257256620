// Utils
import {
  AdditionalComponentsValues,
  PricingQuoteContainerValues,
  PricingQuoteFieldValue,
  PricingQuoteFieldValues,
  Tank,
} from './types';

export const FORMULA_ID = 'formulaId';

export const STOCK_RAW_MATERIALS_EAST = [
  'RM-2301',
  'RMA03',
  'RMA06-B',
  'RMA06-C',
  'RMA06',
  'RMA10',
  'RMA109',
  'RMA111',
  'RMA114',
  'RMA22',
  'RMA23',
  'RMA41',
  'RMA60',
  'RMA60',
  'RMA68',
  'RMA81',
  'RMA81',
  'RMA91-B',
  'RMA91',
  'RMA91',
  'RMA98',
  'RMB04',
  'RMB06-B',
  'RMB46',
  'RMB76',
  'RMC02-B',
  'RMC02',
  'RMC03',
  'RMC04',
  'RMC05',
  'RMC07',
  'RMC122',
  'RMC160',
  'RMC162',
  'RMC167',
  'RMC179',
  'RMC190',
  'RMC191',
  'RMC192',
  'RMC33',
  'RMC48',
  'RMC81',
  'RMC86',
  'RMD07',
  'RMD09',
  'RMD13-B',
  'RMD13',
  'RMD14',
  'RMD20',
  'RMD23',
  'RMD24',
  'RMD28',
  'RMD31',
  'RMD37',
  'RMD41',
  'RMD54-B',
  'RMD54',
  'RMD58',
  'RMD62',
  'RMD65',
  'RMD67',
  'RMD77',
  'RME01',
  'RME07',
  'RME15-B',
  'RME15-C',
  'RME15',
  'RME48',
  'RMF05-B',
  'RMF05',
  'RMG01',
  'RMG03-B',
  'RMG03-C',
  'RMG03-D',
  'RMG03-E',
  'RMG03',
  'RMG10',
  'RMG20',
  'RMG25-B',
  'RMG62',
  'RMG63',
  'RMH18-B',
  'RMH18-C',
  'RMH18',
  'RMH55',
  'RMI06',
  'RMI30',
  'RMI31',
  'RMJ12',
  'RMK01',
  'RMK02',
  'RMK05',
  'RML10',
  'RML26',
  'RML87',
  'RMM12',
  'RMM18',
  'RMM48',
  'RMM58',
  'RMM68',
  'RMM75',
  'RMM76-B',
  'RMM76',
  'RMN25',
  'RMO03-B',
  'RMO03-C',
  'RMO03',
  'RMO06-B',
  'RMO06',
  'RMO63',
  'RMP07-B',
  'RMP07',
  'RMP08-B',
  'RMP08-C',
  'RMP08',
  'RMP09-B',
  'RMP09',
  'RMP33',
  'RMP35',
  'RMP47',
  'RMP48-B',
  'RMP72',
  'RMP73-B',
  'RMP73-C',
  'RMP73',
  'RMR14',
  'RMS01',
  'RMS02-B',
  'RMS02',
  'RMS04',
  'RMS06',
  'RMS08',
  'RMS108',
  'RMS12',
  'RMS134',
  'RMS151',
  'RMS153',
  'RMS167',
  'RMS24',
  'RMS27',
  'RMS28',
  'RMS33-B',
  'RMS33',
  'RMS45',
  'RMS67',
  'RMS68-B',
  'RMS86',
  'RMS89',
  'RMT01',
  'RMT66',
  'RMT70',
  'RMU04',
  'RMU06',
  'RMU15',
  'RMU16',
  'RMV01',
  'RMV02',
  'RMW01',
  'RMW02-950',
  'RMW10',
  'RMX151',
  'RMZ07',
];

export const STOCK_RAW_MATERIALS_WEST = [
  'RM0107',
  'RM0237',
  'RM0302',
  'RM0306',
  'RM0307',
  'RM0309',
  'RM0313',
  'RM0314',
  'RM0316',
  'RM0318',
  'RM0368',
  'RM0593-B',
  'RM0593',
  'RM0594-B',
  'RM0594',
  'RM0595-B',
  'RM0595',
  'RM0596-B',
  'RM0596',
  'RM0601',
  'RM0704',
  'RM0706',
  'RM0768',
  'RM0858',
  'RM0870',
  'RM1006',
  'RM1206',
  'RM1242',
  'RM1502',
  'RM1602',
  'RM1615',
  'RM1630',
  'RM1701',
  'RM1728',
  'RM1902',
  'RM1904',
  'RM1907',
  'RM1912',
  'RM1923',
  'RM1934',
  'RM1980',
  'RM2025',
  'RM2301',
  'RM2603',
  'RM2605',
  'RM2811',
  'RM2894',
  'RM2901',
  'RM2927',
  'RM3153',
  'RM3155',
  'RM3156',
  'RM3157',
  'RM3160',
  'RM3161',
];

// INPUT FIELD CONSTANTS
export const INITIAL_RUN_RATE: PricingQuoteFieldValue = {
  label: 'Run Rate',
  name: 'runRate',
  value: 10,
};

export const MARGIN_TARGET = {
  // LOW: 65,
  // MEDIUM: 67,
  // HIGH: 69,
  // VERY_HIGH: 70,
  // TODO Requested temp change to values. Restore originals later
  LOW: 50,
  MEDIUM: 50,
  HIGH: 50,
  VERY_HIGH: 50,
};

export const NA = 'N/A';

export enum UNIT_OUTPUT_THRESHOLDS {
  LOW = 25_001,
  MEDIUM = 50_001,
  HIGH = 250_001,
}

export const INITIAL_MARGIN_TARGET_PERCENTAGE: PricingQuoteFieldValue = {
  label: 'Margin Target %',
  name: 'marginTargetPercentage',
  value: 0,
};

export const INITIAL_EFFICIENCY_PERCENTAGE: PricingQuoteFieldValue = {
  label: 'Efficiency Target %',
  name: 'efficiencyPercentage',
  value: 85,
};

export const ADDITIONAL_COMPONENT_COST_PER_UNIT: AdditionalComponentsValues = {
  LOW: {
    innerBox: 0.15,
    tamperSleeve: 0.15,
    overCap: 0.15,
    masterBox: {
      less_than_six: 0.08,
      six_or_greater: 0.05,
      none: 0,
    },
  },
  MEDIUM: {
    innerBox: 0.1,
    tamperSleeve: 0.1,
    overCap: 0.1,
    masterBox: {
      less_than_six: 0.08,
      six_or_greater: 0.03,
      none: 0,
    },
  },
  HIGH: {
    innerBox: 0.05,
    tamperSleeve: 0.05,
    overCap: 0.05,
    masterBox: {
      less_than_six: 0.08,
      six_or_greater: 0.03,
      none: 0,
    },
  },
};

export const INITIAL_ADDITIONAL_COMPONENTS_VALUES = {
  innerBox: {
    label: 'Inner Box',
    name: 'innerBox',
    checked: false,
  },
  masterBox: {
    label: 'Master Box',
    name: 'masterBox',
    value: 'sixOrLess',
  },
  overCap: {
    label: 'Over Cap',
    name: 'overCap',
    checked: false,
  },
  tamperSleeve: {
    label: 'Tamper Sleeve',
    name: 'tamperSleeve',
    checked: false,
  },
  unitCarton: {
    label: 'Unit Carton',
    name: 'unitCarton',
    checked: false,
  },
};

export const INITIAL_LABOR_HEAD_COUNT_VALUES = {
  compoundingHeadCount: {
    label: 'Compounding',
    name: 'compoundingHeadCount',
    required: true,
    value: 0,
  },
  preworkHeadCount: {
    label: 'Prework',
    name: 'preworkHeadCount',
    required: true,
    value: 0,
  },
  operatorHeadCount: {
    label: 'Operator',
    name: 'operatorHeadCount',
    required: true,
    value: 0,
  },
  cappingHeadCount: {
    label: 'Capping',
    name: 'cappingHeadCount',
    required: true,
    value: 0,
  },
  unitCartonHeadCount: {
    label: 'Unit Carton',
    name: 'unitCartonHeadCount',
    required: false,
    value: 0,
  },
  packingOrPalletizerHeadCount: {
    label: 'Packing/Palletizer',
    name: 'packingOrPalletizerHeadCount',
    required: true,
    value: 0,
  },
  otherHeadCount: {
    label: 'Other',
    name: 'otherHeadCount',
    required: false,
    value: 0,
  },
};

export const INITIAL_COMPONENT_VALUES = {
  container: {
    label: 'Container',
    name: 'container',
    value: '',
  },
  material: {
    label: 'Material',
    name: 'material',
    value: '',
  },
  closure: {
    label: 'Closure',
    name: 'closure',
    value: '',
  },
};

export enum CONTAINER_TYPE {
  BOTTLE = 'bottle',
  TOTTLE = 'tottle',
  JAR = 'jar',
  TUBE = 'tube',
  LIP_BALM_DEODORANT = 'lipBalmDeodorant',
  FOIL_SACHET = 'foilSachet',
  AEROSOL = 'aerosol',
  BAG_ON_VALVE = 'bagOnValve',
}

export const CONTAINER_MENU_ITEMS: PricingQuoteContainerValues = {
  bottle: {
    label: 'Bottle',
    value: CONTAINER_TYPE.BOTTLE,
    materials: ['glass', 'plastic'],
    closures: ['flipTop', 'discCap', 'snapCap', 'pump', 'sprayer', 'dropper'],
  },
  tottle: {
    label: 'Tottle',
    value: CONTAINER_TYPE.TOTTLE,
    materials: ['plastic'],
    closures: ['cap'],
  },
  jar: {
    label: 'Jar',
    value: CONTAINER_TYPE.JAR,
    materials: ['glass', 'plastic'],
    closures: ['threadedCap'],
  },
  tube: {
    label: 'Tube',
    value: CONTAINER_TYPE.TUBE,
    materials: ['metal', 'plastic'],
    closures: ['cap'],
  },
  lipBalmDeodorant: {
    label: 'Lip Balm/Deodorant',
    value: CONTAINER_TYPE.LIP_BALM_DEODORANT,
    materials: ['plastic'],
    closures: ['cap'],
  },
  foilSachet: {
    label: 'Foil Sachet',
    value: CONTAINER_TYPE.FOIL_SACHET,
    materials: ['foil'],
    closures: ['notApplicable'],
  },
  aerosol: {
    label: 'Aerosol',
    value: CONTAINER_TYPE.AEROSOL,
    materials: ['notApplicable'],
    closures: ['notApplicable'],
  },
  bagOnValve: {
    label: 'Bag-on-Valve',
    value: CONTAINER_TYPE.BAG_ON_VALVE,
    materials: ['notApplicable'],
    closures: ['notApplicable'],
  },
};

export const MATERIAL_MENU_ITEMS: PricingQuoteFieldValues = {
  glass: {
    label: 'Glass',
    value: 'glass',
  },
  plastic: {
    label: 'Plastic',
    value: 'plastic',
  },
  metal: {
    label: 'Metal',
    value: 'metal',
  },
  foil: {
    label: 'Foil',
    value: 'foil',
  },
  notApplicable: {
    label: 'N/A',
    value: 'notApplicable',
  },
};

export const CLOSURE_MENU_ITEMS: PricingQuoteFieldValues = {
  flipTop: {
    label: 'Flip Top',
    value: 'flipTop',
  },
  cap: {
    label: 'Cap',
    value: 'cap',
  },
  discCap: {
    label: 'Disc Cap',
    value: 'discCap',
  },
  snapCap: {
    label: 'Snap Cap',
    value: 'snapCap',
  },
  threadedCap: {
    label: 'Threaded Cap',
    value: 'threadedCap',
  },
  pump: {
    label: 'Pump',
    value: 'pump',
  },
  sprayer: {
    label: 'Sprayer',
    value: 'sprayer',
  },
  dropper: {
    label: 'Dropper',
    value: 'dropper',
  },
  notApplicable: {
    label: 'N/A',
    value: 'notApplicable',
  },
};

// DOUGHNUT CHART CONSTANTS
export const DOUGHNUT_CHART_LABELS = [
  'FORMULA',
  'LABOR',
  'COMPONENT',
  'CONTRIBUTION',
];

export const DOUGHNUT_CHART_NAME = 'Cost Breakdown';

// EXCESS RAWS CHART CONSTANTS
export const EXCESS_RAWS_TABLE_HEADERS = [
  'Contribution Margin',
  'Tank Size',
  'Unit Output',
  'Unit Cost',
  'Unit Contribution',
  'Unit Price',
  'Run Contribution',
  '% Excess Raws',
  'Total Excess Raws',
];

export const RAW_MATERIALS_BREAKDOWN_TABLE_HEADERS = [
  'RM Code',
  'Trade Name',
  'Weight %',
  'Price / lb',
  'Supplier',
  'MOQ',
  'MOQ Unit',
  'Est. Usage (lb)',
  'Excess Value',
];

// NAME CONSTANTS
export enum PRODUCTION_LOCATIONS {
  MIAMI = 'Miami',
  LOS_ANGELES = 'Los Angeles',
}

export const PRODUCTION_TANKS: {
  [x: string]: (Pick<Tank, 'gallons'> & { quantity: number })[];
} = {
  [PRODUCTION_LOCATIONS.MIAMI]: [
    {
      gallons: 100,
      quantity: 1,
    },
    {
      gallons: 200,
      quantity: 1,
    },
    {
      gallons: 500,
      quantity: 2,
    },
    {
      gallons: 1000,
      quantity: 5,
    },
    {
      gallons: 1500,
      quantity: 4,
    },
    {
      gallons: 2000,
      quantity: 2,
    },
    {
      gallons: 2600,
      quantity: 1,
    },
    {
      gallons: 3000,
      quantity: 2,
    },
  ],
  [PRODUCTION_LOCATIONS.LOS_ANGELES]: [
    {
      gallons: 40,
      quantity: 1,
    },
    {
      gallons: 100,
      quantity: 3,
    },
    {
      gallons: 125,
      quantity: 2,
    },
    {
      gallons: 305,
      quantity: 1,
    },
    {
      gallons: 350,
      quantity: 1,
    },
    {
      gallons: 500,
      quantity: 2,
    },
    {
      gallons: 1000,
      quantity: 1,
    },
    {
      gallons: 3200,
      quantity: 1,
    },
  ],
};

export const FLUID_OUNCES_PER_GALLON = 128;

// WARNINGS & ERRORS CONSTANTS
export const FORMULA_ISSUE_SUMMARY =
  'Formula needs attention. Update the following to generate accurate costs:';
export const EXCESS_RAW_MATERIAL_ISSUE_SUMMARY =
  'Missing information. Update the following to generate accurate calculation of excess raw materials:';
export const MISSING_PRICE_WARNING = {
  actionItem: 'Add price(s) to raw material(s):',
  issue: 'One or more raw materials are missing pricing.',
  warningName: 'MISSING_PRICE_WARNING',
};
export const MIXED_UP_LOCATIONS_WARNING = {
  actionItem: 'Use RM codes from one location in',
  issue: 'RM codes from multiple locations are present.',
  warningName: 'MIXED_UP_LOCATIONS_WARNING',
};
export const INCORRECT_FORMULA_AMOUNT_WARNING = {
  actionItem: 'Adjust raw materials total to 100% in',
  issue: 'Raw materials percentages do not add up to 100%.',
  warningName: 'INCORRECT_FORMULA_AMOUNT_WARNING',
};
export const FORMULA_MISSING_INGREDIENTS_WARNING = {
  actionItem: 'Add ingredients to',
  issue: 'Formula has no ingredients',
  warningName: 'FORMULA_MISSING_INGREDIENTS_WARNING',
};
export const FORMULA_MISSING_MOQS_WARNING = {
  actionItem: 'Add MOQ(s) to raw material(s):',
  issue: 'One or more raw materials are missing MOQs.',
  warningName: 'FORMULA_MISSING_INGREDIENTS_MOQ_WARNING',
};

export const FORMULA_MISSING_MOQ_UNIT_WARNING = {
  actionItem: 'Add unit to MOQ(s) for raw material(s):',
  issue: 'One or more raw materials are missing a unit for its MOQ.',
  warningName: 'FORMULA_MISSING_MOQ_UNIT_WARNING',
};
