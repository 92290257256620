// Libraries
import React from 'react';

interface RtlWrapperProps {
  children: React.ReactNode;
  shouldRender: boolean;
}

export const RtlWrapper: React.FC<RtlWrapperProps> = ({
  children,
  shouldRender,
}) => {
  return shouldRender ? <>{children}</> : null;
};
