// Libraries
import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// Constants
import { THEME_PALETTE } from 'styles/mui/themeV2';

const US_API_DRUG_PURPOSES: string[] = [
  'Acne treatment',
  'Antibacterial',
  'Anti-dandruff',
  'Antifungal',
  'Anti-itch',
  'Antimicrobial',
  'Antiperspirant',
  'Antipruritic',
  'Antiseptic',
  'Counterirritant',
  'External Analgesic',
  'Pain Relieving',
  'Skin Protectant',
  'Sunscreen',
  'Topical Analgesic',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listbox: {
      height: 200,
      '& > .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    popupIndicator: {
      color: THEME_PALETTE.gray.dark,
      backgroundColor: 'transparent',
    },
    clearIndicator: {
      color: THEME_PALETTE.gray.dark,
      backgroundColor: 'transparent',
    },
  })
);

interface PurposesModuleProps {
  handlePurposesChange: (purposes: Array<string>) => void;
  isLocked?: boolean;
  usApiDrugPurposes: Array<string>;
}

export const PurposesModule: React.FC<PurposesModuleProps> = ({
  handlePurposesChange,
  isLocked,
  usApiDrugPurposes,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      disabled={isLocked}
      id="origin-multi-select"
      options={US_API_DRUG_PURPOSES}
      value={usApiDrugPurposes}
      onChange={(event, purposes) => handlePurposesChange(purposes)}
      multiple
      selectOnFocus
      disableCloseOnSelect
      disablePortal
      classes={{
        listbox: classes.listbox,
        popupIndicator: classes.popupIndicator,
        clearIndicator: classes.clearIndicator,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="US API Drug Purpose"
          placeholder="Select as many purposes as needed"
        />
      )}
    />
  );
};
