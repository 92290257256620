// Libraries
import React, { useState } from 'react';
import {
  BarsArrowDownIcon,
  ChevronDownIcon,
  ClockIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  ShoppingCartIcon,
  WrenchIcon,
} from '@heroicons/react/16/solid';
import { Bars4Icon, Squares2X2Icon } from '@heroicons/react/24/outline';
// Components
import {
  Button,
  Chip,
  Page,
  Pagination,
  Select,
  TextField,
} from 'design-system';
import { CreateNewCatalogueEntryModal } from './create-new-catalogue-entry-modal.component';
// Utils
import { USDollar } from 'features/utils';
// Assets
import ReadyToLaunchFullLogo from 'img/ready-to-launch-full-logo.svg';

const sortDropdownOptions = [
  {
    label: 'Alphabetical',
    value: 'alphabetical',
    leadingIcon: <BarsArrowDownIcon className="h-5 w-5 text-grey-50" />,
  },
  {
    label: 'Newest',
    value: 'newest',
    leadingIcon: <ClockIcon className="h-5 w-5 text-grey-50" />,
  },
  {
    label: 'Last Modified',
    value: 'lastModified',
    leadingIcon: <WrenchIcon className="h-5 w-5 text-grey-50" />,
  },
];

const filterChips = [
  { label: 'Sun Care', value: 'sunCare' },
  { label: 'Skin Care', value: 'skinCare' },
  { label: 'Hair Care', value: 'hairCare' },
  { label: 'Body Care', value: 'bodyCare' },
];

const dummyFormulas = [
  {
    category: 'sunCare',
    name: 'Sun Care Formula 1',
    format: 'Lotion',
    component: 'BOV',
    moq: 10000,
    sampleSize: '0.5 oz',
    price: 0.9,
  },
  {
    category: 'sunCare',
    name: 'Sun Care Formula 2',
    format: 'Spray',
    moq: 20000,
    component: 'Tube',
    sampleSize: '1 oz',
    price: 1.2,
  },
  {
    moq: 30000,
    name: 'Sun Care Formula 3',
    format: 'Spray',
    component: 'Bottle',
    sampleSize: '0.75 oz',
    price: 1.5,
  },
  {
    category: 'skinCare',
    name: 'Skin Care Formula 1',
    format: 'Lotion',
    component: 'BOV',
    sampleSize: '0.6 oz',
    price: 0.9,
    moq: 20000,
  },
  {
    category: 'skinCare',
    name: 'Skin Care Formula 2',
    format: 'Cream',
    component: 'Tube',
    sampleSize: '0.6 oz',
    price: 1.2,
    moq: 20000,
  },
  {
    category: 'skinCare',
    name: 'Skin Care Formula 3',
    format: 'Spray',
    component: 'Bottle',
    sampleSize: '0.6 oz',
    price: 1.5,
    moq: 20000,
  },
  {
    category: 'hairCare',
    name: 'Hair Care Formula 1',
    format: 'Lotion',
    component: 'BOV',
    sampleSize: '0.6 oz',
    price: 0.9,
    moq: 2000,
  },
  {
    category: 'hairCare',
    name: 'Hair Care Formula 2',
    format: 'Cream',
    component: 'Tube',
    sampleSize: '0.6 oz',
    price: 1.2,
    moq: 20000,
  },
  {
    category: 'hairCare',
    name: 'Hair Care Formula 3',
    format: 'Spray',
    component: 'Bottle',
    sampleSize: '0.6 oz',
    price: 1.5,
    moq: 20000,
  },
  {
    category: 'bodyCare',
    name: 'Body Care Formula 1',
    format: 'Lotion',
    component: 'BOV',
    sampleSize: '0.6 oz',
    moq: 20000,
    price: 0.9,
  },
  {
    category: 'bodyCare',
    name: 'Body Care Formula 2',
    format: 'Cream',
    component: 'Tube',
    sampleSize: '0.6 oz',
    price: 1.2,
    moq: 200,
  },
  {
    category: 'bodyCare',
    name: 'Body Care Formula 3',
    format: 'Spray',
    component: 'Bottle',
    sampleSize: '0.6 oz',
    price: 1.5,
    moq: 200,
  },
];

const formulaTableHeaders = [
  'MOQ',
  'Format',
  'Component',
  'Sample Size',
  'CPU (per oz)',
];

export const RtlFormulaCatalogue = () => {
  const [selectedFilterChips, setSelectedFilterChips] = useState(['']);
  const [numberOfItemsInCart] = useState<number>(0);
  const [primarySortOption, setPrimarySortOption] = useState(
    sortDropdownOptions[0]
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [
    createNewCatalogueEntryModalOpen,
    setCreateNewCatalogueEntryModalOpen,
  ] = useState<boolean>(false);

  const renderTableHeaders = () => {
    return formulaTableHeaders.map((headerName) => (
      <div className="flex items-center gap-1 cursor-pointer">
        <span>{headerName}</span>
        <ChevronDownIcon className="h-4 w-4 text-royal-50" />
      </div>
    ));
  };

  const renderFormulaRows = () => {
    return dummyFormulas.map((formula) => {
      return (
        <div className="grid items-center grid-cols-rtlFormulaCatalogueListViewTableHeader py-3 gap-x-[56px] border-b border-grey-90 text-grey-50">
          <div className="flex items-center">
            <div className="w-4 h-4 bg-grey-50 rounded"></div>
          </div>
          <span className="-ml-10 text-black font-semibold">
            {formula.name}
          </span>
          <span>{new Intl.NumberFormat('en-US').format(formula.moq)}</span>
          <span>{formula.format}</span>
          <span>{formula.component}</span>
          <span>{formula.sampleSize}</span>
          <span>{USDollar.format(formula.price)}</span>
          <div>
            <Button
              text="Add to Cart"
              type="primary"
              size="small"
              leadingIcon={<PlusIcon className="h-4 w-4" />}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <Page>
      {createNewCatalogueEntryModalOpen && (
        <CreateNewCatalogueEntryModal
          filterChips={filterChips}
          handleClose={() => setCreateNewCatalogueEntryModalOpen(false)}
        />
      )}
      <div className="col-span-12 sticky top-0 bg-white">
        <div className=" py-6 flex w-full justify-between items-center">
          <div className="flex gap-2 items-center">
            <img
              width="20"
              height="20"
              src={ReadyToLaunchFullLogo}
              alt="Ready to Launch logo"
            />
            <span className="uppercase font-agipo">Ready to launch</span>
          </div>
          <div className="flex items-center gap-2 cursor-pointer">
            <ShoppingCartIcon className="h-4 w-4" />
            <div className="font-mono uppercase text-xs">
              <span>Samples cart</span>
              <span className={`${numberOfItemsInCart > 0 && 'text-royal-50'}`}>
                {' '}
                {`(${numberOfItemsInCart})`}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 flex justify-between pt-52">
        <div className="w-1/2 flex flex-col">
          <span className="font-agipo text-[48px] font-bold">
            RTL Formula Library
          </span>
          <span className="font-inter text-grey-50">
            This is PML’s library of off-the-shelf formulas. These formulas have
            a faster production time and lower costs to our clients because
            they’ve been pre-tested. Clients can make minor adjustments to
            fragrance and ingredients. Browse formulas, then add them to your
            cart to send samples to clients.
          </span>
        </div>
        <div className="self-start">
          <Button
            leadingIcon={<PlusIcon />}
            onClick={() => setCreateNewCatalogueEntryModalOpen(true)}
            size="large"
            text="New RTL Formula"
            type="primary"
          />
        </div>
      </div>
      <div className="sticky top-[72px] col-span-12 py-16 bg-white">
        <div className="bg-white flex justify-between items-center">
          <div className="w-1/2">
            <TextField
              placeholder="Search"
              trailingIcon={
                <MagnifyingGlassIcon className="text-grey-50 h-5 w-5" />
              }
              value={searchQuery}
              onChange={(e: any) => setSearchQuery(e.target.value)}
              height="h-11"
            />
          </div>
          <div className="flex items-center gap-6">
            <div className="flex gap-3 text-grey-50">
              <Squares2X2Icon
                className={`h-6 w-6 cursor-pointer ${
                  viewMode === 'grid' && 'text-royal-50'
                }`}
                onClick={() => setViewMode('grid')}
              />
              <Bars4Icon
                className={`h-6 w-6 cursor-pointer ${
                  viewMode === 'list' && 'text-royal-50'
                }`}
                onClick={() => setViewMode('list')}
              />
            </div>
            <Select
              height="h-11"
              leadingIcon={primarySortOption.leadingIcon}
              options={sortDropdownOptions}
              value={primarySortOption}
              onChange={setPrimarySortOption}
            />
          </div>
        </div>
      </div>
      <div className="flex sticky top-[244px] col-span-12 justify-center gap-4 bg-white">
        {filterChips.map((chip) => {
          return (
            <Chip
              key={chip.value}
              bgColor="bg-grey-95"
              closeIconColor="text-royal-80"
              label={chip.label}
              onClick={() => {
                setSelectedFilterChips((prev) => {
                  if (prev.includes(chip.value)) {
                    return [...prev];
                  }
                  return [...prev, chip.value];
                });
              }}
              onCloseIconClick={(event) => {
                event.stopPropagation();
                setSelectedFilterChips((prev) => {
                  return prev.filter((value) => value !== chip.value);
                });
              }}
              textColor="text-grey-40"
              selected={selectedFilterChips.includes(chip.value)}
              selectedBgColor="bg-royal-95"
              selectedTextColor="text-royal-40"
              value={chip.value}
            />
          );
        })}
      </div>
      {viewMode === 'grid' ? (
        <div className="flex col-span-12 flex-wrap gap-x-6 gap-y-9 my-6">
          {dummyFormulas.map((formula) => {
            return (
              <div
                key={formula.name}
                className="flex flex-col font-inter text-sm"
              >
                <div className="h-[191px] w-[191px] bg-grey-80 flex items-center justify-center rounded">
                  <span className="mono">Placeholder Image</span>
                </div>
                <div className="flex justify-between my-[6px]">
                  <span className="font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis w-2/3">
                    {formula.name}
                  </span>
                  <span className="">{USDollar.format(formula.price)}</span>
                </div>
                <span className="text-grey-50">{formula.format}</span>
                <span className="text-grey-50">{formula.component}</span>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="col-span-12 font-inter text-sm">
          <div className="grid grid-cols-rtlFormulaCatalogueListViewTableHeader gap-x-[56px] py-3 border-b border-grey-90">
            <div id="imageColumnPlaceholder" className="py-3"></div>
            <div className="flex items-center gap-1 cursor-pointer -ml-10">
              <span>Formula Name</span>
              <ChevronDownIcon className="h-4 w-4 text-royal-50" />
            </div>
            {renderTableHeaders()}
          </div>
          <div id="addToCartButtonPlaceholder"></div>
          {renderFormulaRows()}
        </div>
      )}
      <div className="col-span-12">
        <Pagination
          numResultsStart={'1'}
          numResultsEnd={'10'}
          totalResults={'1000'}
          totalPages={20}
        />
      </div>
    </Page>
  );
};
