// Libraries
import React from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { useFormikContext } from 'formik';
// Components
import { Can, PERMISSIONS } from 'features/rbac';
// Utils
import { PricingQuoteFormikValues, Tank } from './types';
import {
  determineHeadCountValues,
  determineMarginTarget,
} from './pricing-calculator.component';

interface ActionsPanelProps {
  briefWithSupplement: any;
  openSharePricingQuoteModal: () => void;
  productionTank: Maybe<Tank & { tanks: Tank[] }>;
}

export const ActionsPanel: React.FC<ActionsPanelProps> = ({
  briefWithSupplement,
  openSharePricingQuoteModal,
  productionTank,
}) => {
  const {
    setValues,
    submitForm,
    dirty,
    isSubmitting,
    values,
  } = useFormikContext<PricingQuoteFormikValues>();

  const resetInputs = () => {
    if (!productionTank) return;

    const newLaborHeadCountValues = determineHeadCountValues(
      briefWithSupplement,
      productionTank.unitOutput
    );

    setValues({
      ...values,
      ...newLaborHeadCountValues,
      marginTargetPercentage: determineMarginTarget(productionTank),
      compoundingHeadCount:
        newLaborHeadCountValues?.compoundingHeadCount *
        productionTank.tanks.length,
    });
  };

  return (
    <Grid container item xs={12}>
      <Grid container item xs={10} spacing={2}>
        <Grid item>
          <Can
            perform={PERMISSIONS.OVERRIDE_PRICING_QUOTE}
            yes={() => (
              <Button
                disabled={!dirty || isSubmitting}
                onClick={submitForm}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress
                      size={12}
                      thickness={10}
                      color="secondary"
                    />
                  ) : undefined
                }
              >
                Save
              </Button>
            )}
          />
        </Grid>
        <Grid item>
          <Can
            perform={PERMISSIONS.OVERRIDE_PRICING_QUOTE}
            yes={() => (
              <Button
                variant="outlined"
                color="secondary"
                disabled={isSubmitting}
                onClick={resetInputs}
              >
                Reset
              </Button>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Button
          endIcon={<ShareIcon />}
          disabled={isSubmitting}
          onClick={openSharePricingQuoteModal}
        >
          Share
        </Button>
      </Grid>
    </Grid>
  );
};
