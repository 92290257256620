// Libraries
import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik } from 'formik';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import _ from 'lodash';
// Components
import { UserContext } from 'context';
// Utils
import { useApi } from 'api';
import { useSnackbar } from 'hooks';
import { Can, PERMISSIONS } from 'features/rbac';

const PROFILE_FIELD_NAMES = {
  givenName: 'givenName',
  familyName: 'familyName',
  companyName: 'companyName',
  location: 'location',
  searchLocation: 'searchLocation',
};

const sharedValidation = {
  [PROFILE_FIELD_NAMES.givenName]: Yup.string().required(
    'First name is required'
  ),
  [PROFILE_FIELD_NAMES.familyName]: Yup.string().required(
    'Last name is required'
  ),
};

export const ProfileForm: React.FC = () => {
  const { user } = useAuth0();
  const { patchCustomer, patchEmployee } = useApi();
  const { showSnackbar } = useSnackbar();

  const {
    userSession,
    userSession: {
      email,
      givenName,
      familyName,
      employeeMetadata: { location, searchLocation },
    },
    setUserSession,
    permissions,
  } = useContext(UserContext)!;

  const isEmployee = permissions.includes(PERMISSIONS.VISIT_EMPLOYEE_PAGE);

  const profileValidationSchema = Yup.object().shape(
    isEmployee
      ? {
          ...sharedValidation,
          [PROFILE_FIELD_NAMES.location]: Yup.string()
            .required('Location is required')
            .oneOf(['Canada', 'Los Angeles', 'Miami', 'New York City']),
          [PROFILE_FIELD_NAMES.searchLocation]: Yup.string()
            .required('Search location is required')
            .oneOf(['All Locations', 'Los Angeles', 'Miami']),
        }
      : {
          ...sharedValidation,
        }
  );

  const sharedFormValues = {
    [PROFILE_FIELD_NAMES.givenName]: givenName,
    [PROFILE_FIELD_NAMES.familyName]: familyName,
  };

  const initialFormValues = isEmployee
    ? {
        ...sharedFormValues,
        [PROFILE_FIELD_NAMES.location]: location,
        [PROFILE_FIELD_NAMES.searchLocation]: searchLocation,
      }
    : {
        ...sharedFormValues,
      };

  const handleProfileUpdate = (values: any) => {
    if (_.isEqual(values, initialFormValues)) return;

    const patchFunction = isEmployee ? patchEmployee : patchCustomer;

    patchFunction({
      urlParams: user?.sub,
      data: {
        [isEmployee ? 'employee' : 'customer']: {
          givenName: values.givenName.trim(),
          familyName: values.familyName.trim(),
          ...values,
        },
      },
      handleSuccess: (data) => {
        showSnackbar('Profile updated successfully', 'success');
        setUserSession({
          ...userSession,
          ...values,
        });
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      validationSchema={profileValidationSchema}
      onSubmit={handleProfileUpdate}
    >
      {({ getFieldProps, errors, touched, handleSubmit, handleChange }) => (
        <Grid container item xs={12} spacing={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...getFieldProps(PROFILE_FIELD_NAMES.givenName)}
              error={!!(errors.givenName && touched.givenName)}
              fullWidth
              helperText={
                errors.givenName && touched.givenName && errors.givenName
              }
              label="First"
              required
              variant="outlined"
              onBlur={() => handleSubmit()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...getFieldProps(PROFILE_FIELD_NAMES.familyName)}
              fullWidth
              error={!!(errors.familyName && touched.familyName)}
              helperText={
                errors.familyName && touched.familyName && errors.familyName
              }
              label="Last"
              required
              variant="outlined"
              onBlur={() => handleSubmit()}
            />
          </Grid>
          <Can
            perform={PERMISSIONS.VISIT_EMPLOYEE_PAGE}
            yes={() => (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...getFieldProps(PROFILE_FIELD_NAMES.location)}
                    fullWidth
                    label="Location"
                    required
                    select
                    variant="outlined"
                    onChange={(e) => {
                      handleChange(e);
                      // Select component does not trigger onBlur
                      // so handleSubmit has to be called manually onChange instead
                      handleSubmit();
                    }}
                  >
                    <MenuItem value="Canada">Canada</MenuItem>
                    <MenuItem value="Los Angeles">Los Angeles</MenuItem>
                    <MenuItem value="Miami">Miami</MenuItem>
                    <MenuItem value="New York City">New York City</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...getFieldProps(PROFILE_FIELD_NAMES.searchLocation)}
                    fullWidth
                    id="searchLocation"
                    label="Search Location"
                    name="searchLocation"
                    required
                    select
                    variant="outlined"
                    onChange={(e) => {
                      handleChange(e);
                      // Select component does not trigger onBlur
                      // so handleSubmit has to be called manually onChange instead
                      handleSubmit();
                    }}
                  >
                    <MenuItem value="All Locations">All Locations</MenuItem>
                    <MenuItem value="Los Angeles">Los Angeles</MenuItem>
                    <MenuItem value="Miami">Miami</MenuItem>
                  </TextField>
                </Grid>
              </>
            )}
          />
          <Grid item xs={12} sm={6}>
            <TextField
              value={email || user?.email}
              disabled
              fullWidth
              helperText={
                <>
                  For assistance with your email send a message to{' '}
                  <a
                    className="underline"
                    href="mailto:support@primematterlabs.com"
                  >
                    support@primematterlabs.com
                  </a>
                </>
              }
              label="Email"
              required
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};
