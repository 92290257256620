// Libraries
import React from 'react';
// Components
import { TextArea, TextField } from 'design-system';
import { PriceQuoteFormValues } from '../form.config';
import { useFormikContext } from 'formik';
import { PRICE_QUOTE_FORM_KEYS } from 'features/price-quote/price-quote.constants';

export const BasicInfo: React.FC = () => {
  const { getFieldProps } = useFormikContext<PriceQuoteFormValues>();

  return (
    <div className="flex flex-row justify-between align-middle">
      <div className="w-[580px]">
        <TextArea
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.PERSONAL_NOTE)}
          label="Personal Note"
          placeholder="A note to the recipient (optional)"
        />
      </div>
      <div>
        <TextField
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.QUOTE_VERSION_NUMBER)}
          label="Quote Version Number"
          helperText="Quote Version Numbers are autogenerated and cannot be edited"
          width="w-[435px]"
          disabled
          additionalClasses="text-grey-50"
        />
      </div>
    </div>
  );
};
