// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton, TextArea, Typography } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

const TEST_RESPONSIBILITY = [
  {
    label: 'PML',
    value: 'PML',
    formikKey: BRIEF_FORM_KEYS.TEST_HANDLING_RESPONSIBILITY,
  },
  {
    label: 'Customer',
    value: 'Customer',
    formikKey: BRIEF_FORM_KEYS.TEST_HANDLING_RESPONSIBILITY,
  },
];

const ADDITIONAL_TESTING_BULK = [
  {
    label: 'No',
    value: 'No',
    formikKey: BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK,
  },
  {
    label: 'Yes',
    value: 'Yes',
    formikKey: BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK,
  },
];

const TEST_HANDLING = {
  responsibility: {
    label: 'responsibility',
    options: TEST_RESPONSIBILITY,
  },
  additionalTestingBulk: {
    label: 'additionalTestingBulk',
    options: ADDITIONAL_TESTING_BULK,
  },
};

export const TestHandling: React.FC = () => {
  const { getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();

  const renderRadioButtons = (type: string) => {
    const sectionName =
      Object.values(TEST_HANDLING).find((section) => section.label === type)
        ?.label || '';

    const options =
      Object.values(TEST_HANDLING).find((section) => section.label === type)
        ?.options || [];

    return options.map((option) => (
      <RadioButton
        key={option.label}
        primaryLabel={option.label}
        direction="col"
        {...(getFieldProps(option.formikKey),
        { name: sectionName, value: option.value })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue(
            option.formikKey,
            // If the section is additionalTestingBulk, set the value to true if the selected option is 'Yes'
            sectionName === TEST_HANDLING.additionalTestingBulk.label
              ? e.target.value === ADDITIONAL_TESTING_BULK[1].value
                ? true
                : false
              : e.target.value
          )
        }
        checked={
          values[option.formikKey] === option.value ||
          (values[option.formikKey] ? 'Yes' : 'No') === option.value
        }
      />
    ));
  };

  return (
    <div className="flex flex-col gap-6">
      <Typography id="test-handling" variant="h3" font="inter">
        Test Handling
      </Typography>
      <div className="flex flex-col gap-3">
        <Typography variant="h4" font="inter" weight="semibold">
          Responsibility
        </Typography>
        <Typography variant="h4" font="inter">
          Who will take care of testing?
        </Typography>

        {renderRadioButtons(TEST_HANDLING.responsibility.label)}
      </div>
      <div className="flex flex-col gap-3">
        <Typography variant="h4" font="inter" weight="semibold">
          Is additional bulk testing needed?
        </Typography>

        {renderRadioButtons(TEST_HANDLING.additionalTestingBulk.label)}

        <TextArea
          {...getFieldProps(BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK_NOTE)}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setFieldValue(
              BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK_NOTE,
              e.target.value
            )
          }
        />
      </div>
    </div>
  );
};
