// Libraries
import React from 'react';
// Utils
import { IApiData, ProjectAttributes, useApi } from 'api';
import { FeedbackStatus } from 'features/home/customer-portal/types';
import { useAnalytics } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

interface IngredientListDoneActionsProps {
  project: IApiData<ProjectAttributes>;
  files: any;
}

export const IngredientListDoneActions: React.FC<IngredientListDoneActionsProps> = ({
  project,
  files,
}) => {
  const { getProjectFileUrl } = useApi();
  const analytics = useAnalytics()!;

  const isEmployee = window.location.pathname.includes(
    'employee-view-project-tracker'
  );

  // There should only be one file with an approved status feedback so the array should only return one element
  const approvedIL = files.find(
    (file: any) =>
      file.parsedIncluded.feedback?.attributes.status ===
      FeedbackStatus.approved
  );

  const handleFileDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.fileDownloadBtn,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: isEmployee
        ? AnalyticConstants.EVENT_LABEL.employeeFileDownload
        : AnalyticConstants.EVENT_LABEL.customerFileDownload,
    });

    const uriEncodedUuid = approvedIL.id;

    getProjectFileUrl({
      // files are called 'documents' in the API
      urlParams: `${project.id}/documents/${uriEncodedUuid}?format=url`,
      handleSuccess: ({ data }) => {
        // this initiates an auto download to the browser
        let a = document.createElement('a');
        a.href = data.presigned_url;
        a.download = data.name;
        a.click();
        a.remove();
      },
    });
  };

  return (
    <>
      {approvedIL && (
        <div className="sm:mb-0 sm:mt-0 mb-6">
          <button
            className="flex items-center p-2 cursor-pointer rounded-md font-inter text-sm border border-darkGray text-black mr-2 hover:bg-darkGray hover:text-white"
            onClick={handleFileDownload}
          >
            Download IL
          </button>
        </div>
      )}
    </>
  );
};
