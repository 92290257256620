//Libraries
import React, { useEffect, FC } from 'react';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
//Components
import { AUTH0_ERROR_CODES, Can } from 'features/rbac';
import { Loader } from 'features/ui';
//Utils
import { ROUTES } from 'features/navigation/navigation.constants';

/**
 * @ignore
 */
const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

/**
 * Options for the withAuthorizationRequired Higher Order Component
 */
export interface WithAuthorizationRequiredOptions {
  /**
   * a optional route for the can component to redirect to
   */
  redirectTo?: string;
  /**
   * ```js
   * withAuthorizationRequired(Profile, {
   *   returnTo: '/profile'
   * })
   * ```
   *
   * or
   *
   * ```js
   * withAuthorizationRequired(Profile, {
   *   returnTo: () => window.location.hash.substr(1)
   * })
   * ```
   *
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string | (() => string);
  /**
   * ```js
   * withAuthorizationRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * })
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element;
  /**
   * ```js
   * withAuthorizationRequired(Profile, {
   *   loginOptions: {
   *     appState: {
   *       customProp: 'foo'
   *     }
   *   }
   * })
   * ```
   *
   * Pass additional login options, like extra `appState` to the login page.
   * This will be merged with the `returnTo` option used by the `onRedirectCallback` handler.
   */
  loginOptions?: RedirectLoginOptions;
}

/**
 * ```js
 * const MyProtectedComponent = withAuthorizationRequired(MyComponent);
 * ```
 *
 * When you wrap your components in this Higher Order Component and an anonymous user visits your component
 * they will be redirected to the login page and returned to the page they we're redirected from after login.
 */
export const withAuthorizationRequired = <P extends object>(
  children: JSX.Element,
  options: WithAuthorizationRequiredOptions = {},
  permission: string,
  redirectTo?: string
): FC<P> => {
  return function WithAuthorizationRequired(props: P): JSX.Element {
    const {
      error,
      isAuthenticated,
      isLoading,
      loginWithRedirect,
      logout,
    } = useAuth0();
    const { returnTo = defaultReturnTo, loginOptions = {} } = options;

    useEffect(() => {
      if (isLoading || isAuthenticated) {
        return;
      }

      // This prevents an infinite loop that is triggered when loginWithRedirect
      // is called without logging the user out first.
      if (
        error &&
        (error as any).error_description ===
          AUTH0_ERROR_CODES.ERROR_UNVERIFIED_EMAIL
      ) {
        logout({
          returnTo: `${window.location.origin}${redirectTo ? redirectTo : ''}/${
            window.location.search
          }`,
        });
        return;
      }

      const opts = {
        ...loginOptions,
        appState: {
          ...loginOptions.appState,
          returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
        },
      };

      (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
    }, [
      error,
      isAuthenticated,
      isLoading,
      loginOptions,
      loginWithRedirect,
      logout,
      returnTo,
    ]);

    return isAuthenticated ? (
      <Can
        perform={permission}
        yes={() => children}
        no={() => <Navigate to={redirectTo ? redirectTo : ROUTES.HOME.route} />}
      />
    ) : (
      <Loader />
    );
  };
};
