// Libraries
import React, { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Plugin,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import { useFormikContext } from 'formik';
// Utils
import { useSnackbar } from 'hooks';
import { IApiData, FormulaAttributes, IngredientAttributes } from 'api';
import { calculateUnitPricing } from './pricing-calculator.component';
import { IPricingData, PricingQuoteFormikValues, Tank } from './types';
import { THEME_PALETTE, gTAmericaMonoRegular } from 'styles/mui/themeV2';
// Constants
import {
  DOUGHNUT_CHART_LABELS,
  DOUGHNUT_CHART_NAME,
  PRODUCTION_LOCATIONS,
  NA,
} from './constants';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface IPricingChartProps {
  briefWithSupplement: any;
  ingredients: Maybe<IApiData<IngredientAttributes>[]>;
  pricingData: Maybe<IPricingData>;
  productionLocation: Maybe<valueof<typeof PRODUCTION_LOCATIONS>>;
  productionTank: Maybe<
    Tank & {
      tanks: Tank[];
    }
  >;
  selectedFormula: Maybe<IApiData<FormulaAttributes>>;
  setPricingData: React.Dispatch<React.SetStateAction<Maybe<IPricingData>>>;
  targetCost: Maybe<number>;
  targetMaxCost: Maybe<number>;
}

const determineDisplayValue = (value: Maybe<string>, display: string) => {
  if (!value) return NA;

  if (isNaN(parseInt(value))) return NA;

  return display;
};

export const PricingChart: React.FC<IPricingChartProps> = ({
  briefWithSupplement,
  ingredients,
  pricingData,
  productionLocation,
  productionTank,
  selectedFormula,
  setPricingData,
  targetCost,
  targetMaxCost,
}) => {
  // If pricing data is present, set the title of the chart to the total with profit
  // update must be called to reflect the changes to the chart
  const chartRef = useRef();
  const { showSnackbar } = useSnackbar();
  const { values } = useFormikContext<PricingQuoteFormikValues>();

  useEffect(() => {
    if (!chartRef.current) return;

    (chartRef.current as any).costPerUnit = pricingData?.roundedTotalCostWithProfit.toFixed(
      2
    );

    const selectedTargetCost = targetCost || targetMaxCost;

    if (selectedTargetCost && pricingData?.roundedTotalCostWithProfit) {
      (chartRef.current as any).targetCostMessage =
        pricingData?.roundedTotalCostWithProfit <= selectedTargetCost
          ? 'Meets Target Cost'
          : 'Exceeds Target Cost';
    }

    (chartRef?.current as any)?.update();
  }, [pricingData, targetCost, targetMaxCost]);

  useEffect(() => {
    if (
      !selectedFormula ||
      !ingredients ||
      !briefWithSupplement ||
      !productionLocation ||
      !productionTank
    ) {
      return;
    }

    try {
      const calculatedPrice = calculateUnitPricing({
        formula: selectedFormula,
        ingredients,
        brief: briefWithSupplement,
        laborFields: values,
        productionTank,
        productionLocation,
      });
      setPricingData({
        componentCostPerContainer: _.round(
          calculatedPrice?.componentCostPerContainer!,
          2
        ),
        roundedTotalCostWithProfit: _.round(
          calculatedPrice?.roundedTotalCostWithProfit!,
          2
        ),
        formulaCostPerContainer: _.round(
          calculatedPrice?.formulaCostPerContainer!,
          2
        ),
        laborCostPerContainer: _.round(
          calculatedPrice?.laborCostPerContainer!,
          2
        ),
        // Appears as "Contribution" on the chart
        profit: _.round(calculatedPrice?.profit!, 2),
      });
    } catch (e) {
      showSnackbar('There was an error calculating cost', 'error');
      Rollbar.warning(e);
    }
  }, [
    selectedFormula,
    ingredients,
    briefWithSupplement,
    productionTank,
    productionLocation,
    setPricingData,
    showSnackbar,
    values,
  ]);

  const costs = [
    pricingData?.formulaCostPerContainer.toFixed(2),
    pricingData?.laborCostPerContainer.toFixed(2),
    pricingData?.componentCostPerContainer.toFixed(2),
    pricingData?.profit.toFixed(2),
  ];
  const chartData = {
    labels: DOUGHNUT_CHART_LABELS.map(
      (label, index) =>
        `${label}: ${determineDisplayValue(costs[index], `$${costs[index]}`)}`
    ),
    datasets: [
      {
        label: DOUGHNUT_CHART_NAME,
        data: costs,
        backgroundColor: [
          THEME_PALETTE.gray.dark,
          THEME_PALETTE.blue.dark,
          THEME_PALETTE.green.dark,
          THEME_PALETTE.blue.main,
        ],
        borderColor: [
          THEME_PALETTE.secondary.main,
          THEME_PALETTE.secondary.main,
          THEME_PALETTE.secondary.main,
          THEME_PALETTE.secondary.main,
        ],
        borderWidth: 2,
        cutout: '56%',
      },
    ],
  };

  const centerText: Plugin<'doughnut'> = {
    id: 'centerText',
    afterDatasetsDraw: (chart, _args, _options) => {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;
      ctx.save();

      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.fillText('COST PER UNIT', width / 2, height / 2 - 25);
      ctx.restore();

      ctx.font = '30px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(
        `${
          isNaN(parseInt((chart as any).costPerUnit))
            ? NA
            : '$' + (chart as any).costPerUnit
        }`,
        width / 2,
        height - 135
      );
      ctx.restore();

      if ((chart as any).targetCostMessage) {
        ctx.font = '12px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(
          `${(chart as any).targetCostMessage}`,
          width / 2,
          height - 100
        );
        ctx.restore();
      }
    },
  };

  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: THEME_PALETTE.gray.dark,
          font: {
            family: gTAmericaMonoRegular.fontFamily,
          },
          pointStyle: 'circle',
          usePointStyle: true,
        },
      },
      datalabels: {
        color: ['white', 'white', 'black', 'black'],
        font: {
          family: gTAmericaMonoRegular.fontFamily,
        },
        formatter: (value: string, ctx: any) => {
          let sum = 0;
          const dataArr = ctx.chart.data.datasets[0].data;

          dataArr.forEach((data: string) => {
            sum += parseFloat(data);
          });
          const percentage = (parseFloat(value) * 100) / sum;

          if (isNaN(percentage)) return NA;

          return percentage.toFixed(2) + '%';
        },
      },
    },
  };

  return (
    <div role={'figure'} className="mb-5">
      <Doughnut
        data={chartData}
        options={chartOptions}
        plugins={[centerText, ChartDataLabels]}
        ref={chartRef}
      />
    </div>
  );
};
