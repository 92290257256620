// Libraries
import React, { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
// Components
import { FormSection } from 'features/ui';
import { GeneralSectionInputs } from './general-section-inputs.component';
// Utils
import { IRawMaterialFormValues } from './raw-material-form.component';

const GENERAL = 'General';

export const GeneralSection: React.FC = () => {
  const section = useRef<any>();
  const { errors } = useFormikContext<IRawMaterialFormValues>();

  useEffect(() => {
    section?.current?.scrollIntoView(true);
  }, [errors.general]);

  return (
    <FormSection
      sectionHeader={GENERAL}
      isRevealed={true}
      setIsRevealed={() => null}
    >
      <GeneralSectionInputs ref={section} />
    </FormSection>
  );
};
