// Libraries
import React, { useEffect, useState, useContext } from 'react';
// Components
import { NotificationsContext } from './notifications.context';
import { UserContext } from 'context';
// Utils
import { useApi, NotificationAttributes, IApiData } from 'api';
// Constants
import { ROLES } from 'features/rbac';

interface INotificationsContextProvider {
  children: React.ReactNode[] | React.ReactNode;
}

export const NotificationsContextProvider: React.FC<INotificationsContextProvider> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<
    IApiData<NotificationAttributes>[]
  >([]);
  const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(
    false
  );
  const [isNotificationsLoading, setIsNotificationsLoading] = useState<boolean>(
    false
  );

  const { roles, userSession } = useContext(UserContext)!;
  const isEmployee = roles && roles.includes(ROLES.EMPLOYEE);
  const { getCustomerNotifications } = useApi();

  useEffect(() => {
    // Employees don't have notifications and if the customer signs up they may not
    //  have a thirdPartyId yet
    if (isEmployee || !userSession.thirdPartyId) return;

    setIsNotificationsLoading(true);
    getCustomerNotifications({
      urlParams: `${encodeURIComponent(
        userSession.thirdPartyId!
      )}/notifications`,
      handleSuccess: ({ data }) => {
        setNotifications(data);
        const hasNewNotifications = data.some(
          (notification: IApiData<NotificationAttributes>) =>
            notification.attributes.viewedAt === null
        );

        if (hasNewNotifications) {
          setHasNewNotifications(true);
        }
      },
      handleFinally: () => {
        setIsNotificationsLoading(false);
      },
    });
  }, [userSession.thirdPartyId, getCustomerNotifications, isEmployee]);

  return (
    <NotificationsContext.Provider
      value={{
        hasNewNotifications,
        setHasNewNotifications,
        notifications,
        setNotifications,
        isNotificationsLoading,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
