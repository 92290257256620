// Libraries
import React from 'react';
import { BeakerIcon } from '@heroicons/react/16/solid';
import { useFormikContext } from 'formik';
// Utils
import { CustomerBriefFormValues } from '../internal-brief/form.config';
// Components
import { Chip, Typography } from 'design-system';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';
// Assets
import ReadyToLaunchIcon from 'img/ready-to-launch.svg';

interface ProjectInfoPaneProps {
  isRtl: boolean;
}

export const ProjectInfoPane: React.FC<ProjectInfoPaneProps> = ({ isRtl }) => {
  const { values } = useFormikContext<CustomerBriefFormValues>();

  if (!values[BRIEF_FORM_KEYS.PRODUCT_TYPE].value) {
    return null;
  }

  return (
    <div className="sticky top-10 flex flex-col gap-9 px-9 pt-13 pb-12">
      {values[BRIEF_FORM_KEYS.PRODUCT_CATEGORY].value && (
        <div className="flex flex-col gap-9">
          <div className="flex flex-col gap-1">
            <Typography
              variant="h6"
              font="mono"
              color="secondary"
              weight="bold"
            >
              DEVELOPMENT APPROACH:
            </Typography>
            {isRtl ? (
              <Chip
                additionalClasses="w-fit-content"
                bgColor="bg-peach-95"
                borderColor="border-peach-80"
                label="Ready To Launch"
                leadingIcon={
                  <img src={ReadyToLaunchIcon} alt="ready to launch icon" />
                }
                noHover
                textColor="text-peach-40"
                value="rtl"
              />
            ) : (
              <Chip
                additionalClasses="w-fit-content"
                bgColor="bg-blue-95"
                borderColor="border-blue-80"
                label="Custom Development"
                leadingIcon={<BeakerIcon className="h-4 w-4 text-blue-40" />}
                noHover
                textColor="text-blue-40"
                value="customDevelopment"
              />
            )}
          </div>
          <div className="flex flex-col gap-1">
            <Typography
              variant="h6"
              font="mono"
              color="secondary"
              weight="bold"
            >
              DEVELOPMENT COST:
            </Typography>
            <Typography variant="h4" font="inter" color="primary">
              {isRtl ? '$0' : '$5000 - $10,000'}
            </Typography>
          </div>
          <div className="flex flex-col gap-1">
            <Typography
              variant="h6"
              font="mono"
              color="secondary"
              weight="bold"
            >
              ESTIMATED TIMELINE:
            </Typography>
            {/* Hard-coding temporarily */}
            <Typography variant="h4" font="inter" color="primary">
              {isRtl ? '6 - 12 ' : '12 - 18 '} months
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
