// Libraries
import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { FieldInputProps, FormikErrors } from 'formik';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { BatchAttributes, IApiData } from 'api';
// Constants

const LOT_NUMBER = 'lotNumber';
const QUANTITY = 'quantity';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    batchInfoContainer: {
      backgroundColor: theme.palette.gray.lighter,
    },
    batchInfo: {
      padding: theme.spacing(2),
    },
    borderRight: {
      borderRight: `1px solid ${theme.palette.gray.light}`,
    },
    lotNumberInput: {
      width: theme.typography.pxToRem(180),
    },
  })
);

interface BatchInfoContainerProps {
  batch: IApiData<BatchAttributes>;
  errors: FormikErrors<
    BatchAttributes & {
      [x: string]: Maybe<string | number>;
    }
  >;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  submitForm: () => Promise<any>;
}

export const BatchInfoContainer: React.FC<BatchInfoContainerProps> = ({
  batch,
  errors,
  getFieldProps,
  submitForm,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.batchInfoContainer}>
      <Grid
        className={classes.batchInfo + ' ' + classes.borderRight}
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        sm={3}
      >
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" align="center">
            Lot #*:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            {...getFieldProps(LOT_NUMBER)}
            inputProps={{ 'aria-label': 'lot number' }}
            className={classes.lotNumberInput}
            onBlur={submitForm}
            error={LOT_NUMBER in errors}
            helperText={errors[LOT_NUMBER]}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid
        className={classes.batchInfo + ' ' + classes.borderRight}
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        sm={2}
      >
        <Grid item xs={7}>
          <Typography variant="h4">Batch Qty (g)*:</Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            {...getFieldProps(QUANTITY)}
            error={QUANTITY in errors}
            fullWidth
            helperText={errors[QUANTITY]}
            inputProps={{
              'aria-label': 'batch quantity',
              type: 'number',
              min: 0,
            }}
            onBlur={submitForm}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid
        className={classes.batchInfo + ' ' + classes.borderRight}
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        sm={2}
      >
        <Typography variant="h4">
          Date Created:{' '}
          {new Date(batch.attributes.createdAt).toLocaleDateString()}
        </Typography>
      </Grid>
      <Grid
        className={classes.batchInfo + ' ' + classes.borderRight}
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        sm={2}
      >
        <Typography variant="h4">
          Created by: {/* parsedIncluded comes from the indexTransformer */}
          {(batch as any).parsedIncluded?.createdBy?.attributes.fullName ||
            // calling createdBy directly comes form the showTransformer
            (batch as any).createdBy?.attributes.fullName ||
            'N/A'}
        </Typography>
      </Grid>
      <Grid
        className={classes.batchInfo}
        item
        container
        alignItems="center"
        justifyContent="flex-start"
        xs={12}
        sm={3}
      >
        <Typography variant="h4">
          Last Updated by:{' '}
          {(batch as any).parsedIncluded?.lastModifiedBy?.attributes.fullName ||
            'N/A'}
        </Typography>
      </Grid>
    </Grid>
  );
};
