// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { Select } from 'design-system';
import { UserContext } from 'context';
// Constants
import {
  CONTAINER_MENU_ITEMS,
  CLOSURE_MENU_ITEMS,
} from 'features/pricing-quote/constants';
import { BRIEF_FORM_KEYS } from '../brief.constants';
import { ROLES } from 'features/rbac';

export const Closure: React.FC = () => {
  const { roles } = useContext(UserContext)!;

  const isEmployee = roles.includes(ROLES.EMPLOYEE);

  const { getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();

  const renderOptions = () => {
    return CONTAINER_MENU_ITEMS[values.container]?.closures.map(
      (closureName) => {
        const closure = CLOSURE_MENU_ITEMS[closureName];
        return { ...closure };
      }
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <p className="font-inter text-base font-bold text-grey-20">Closure</p>
      <Select
        {...getFieldProps(BRIEF_FORM_KEYS.CLOSURE)}
        onChange={(option: Option) =>
          setFieldValue(BRIEF_FORM_KEYS.CLOSURE, option)
        }
        options={(renderOptions() as Option[]) || []}
        helperText={isEmployee ? 'Required for Pricing Quote' : ''}
      />
    </div>
  );
};
