// Libraries
import React, { useContext, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Chip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// Components
import { EditTeamMemberInline } from './edit-team-member-inline.component';
import { EditTeamMemberDialog } from './edit-team-member-dialog';
// Utils
import { CustomerAttributes, IApiData, InviteStatus } from 'api';
import { ITheme, gTAmericaMonoRegular } from 'styles/mui/themeV2';
import { Action, ActionType } from './types';
import { Can, PERMISSIONS } from 'features/rbac';
import { useMobile } from 'hooks';
import { UserContext } from 'context';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    buttonContainer: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
      },
    },
    memberRow: {
      borderTop: `1px solid ${theme.palette.gray.light}`,
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    nameContainer: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },
    },
    youChip: {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.gray.dark,
      fontFamily: gTAmericaMonoRegular.fontFamily,
    },
    pendingChip: {
      backgroundColor: theme.palette.tan.main,
      color: theme.palette.gray.dark,
      fontFamily: gTAmericaMonoRegular.fontFamily,
    },
    noWrapButton: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        '&:hover': {
          backgroundColor: theme.palette.gray.dark,
        },
      },
    },
  })
);

interface TeamMemberRowProps {
  isCurrentlyLoggedInCustomer?: boolean;
  action: Action;
  setAction: React.Dispatch<React.SetStateAction<Action>>;
  customer: IApiData<CustomerAttributes>;
  setCustomers: React.Dispatch<
    React.SetStateAction<IApiData<CustomerAttributes>[]>
  >;
  handleSendCustomerReinvite: (id: string) => void;
  handleRemoveTeamMember: (id: string) => void;
}

export const TeamMemberRow: React.FC<TeamMemberRowProps> = ({
  action,
  customer,
  setCustomers,
  setAction,
  handleSendCustomerReinvite,
  handleRemoveTeamMember,
  isCurrentlyLoggedInCustomer,
}) => {
  const { isMobile } = useMobile();
  const { attributes, id } = customer;
  const classes = useStyles();
  const { permissions } = useContext(UserContext)!;
  const isEmployee = permissions.includes(PERMISSIONS.VISIT_EMPLOYEE_PAGE);

  // This dialog is different than the inline edit
  // This dialog would appear for customers only.
  // The state is changed when the send invitation is clicked
  // and no thirdPartyId, given name, family name or email is present.
  //  meaning they have no auth0 account.
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);

  const isUnableToSendInvite =
    !attributes.email || !attributes.givenName || !attributes.familyName;

  const isInvitePending = attributes.inviteStatus === InviteStatus.pending;

  const isEditingRow =
    action.type === ActionType.edit && action.currentResourceId === id;

  const isFullNameMissing = !attributes.givenName || !attributes.familyName;
  const isEmailMissing = !attributes.email;

  return (
    <Grid
      container
      item
      xs={12}
      key={id}
      className={classes.memberRow}
      style={{ padding: '20px 0' }}
      spacing={2}
    >
      <EditTeamMemberDialog
        isOpen={isEditDialogOpen}
        handleClose={() => setIsEditDialogOpen(false)}
        teamMember={customer}
        setTeamMembers={setCustomers}
      />
      <Grid
        container
        item
        xs={12}
        spacing={2}
        justifyContent="space-between"
        wrap={isMobile ? 'wrap' : 'nowrap'}
      >
        {isEditingRow ? (
          <EditTeamMemberInline
            setAction={setAction}
            setTeamMembers={setCustomers}
            teamMember={customer}
          />
        ) : (
          <>
            <Grid
              item
              xs={9}
              sm={5}
              className={classes.nameContainer}
              style={{
                filter: isInvitePending ? 'opacity(0.5)' : 'none',
              }}
            >
              <Typography
                variant="body1"
                color={isFullNameMissing ? 'textSecondary' : 'textPrimary'}
                style={{
                  fontStyle: isFullNameMissing ? 'italic' : 'normal',
                }}
              >
                {isFullNameMissing ? 'Full name missing' : attributes.fullName}
              </Typography>
              <Typography
                variant="body2"
                color={isEmailMissing ? 'textSecondary' : 'textPrimary'}
                style={{
                  fontStyle: isEmailMissing ? 'italic' : 'normal',
                }}
              >
                {isEmailMissing ? 'Email missing' : attributes.email}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={3}
              sm={isCurrentlyLoggedInCustomer ? 7 : 4}
              justifyContent="flex-end"
              alignItems="center"
            >
              {isCurrentlyLoggedInCustomer ? (
                <Chip size="medium" label="YOU" className={classes.youChip} />
              ) : (
                <>
                  {isInvitePending && (
                    <Grid container item xs={12} justifyContent="flex-end">
                      <Chip
                        size="medium"
                        label={attributes.inviteStatus!.toLocaleUpperCase()}
                        className={classes.pendingChip}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {!isCurrentlyLoggedInCustomer && (
              <Grid
                container
                item
                xs={12}
                sm={isEmployee ? 5 : 4}
                className={classes.buttonContainer}
                style={{
                  flexWrap: action.isLoading ? 'nowrap' : 'wrap',
                }}
                spacing={2}
              >
                <Grid item>
                  {attributes.inviteStatus !== InviteStatus.accepted && (
                    <Button
                      className={classes.noWrapButton}
                      disabled={action.isLoading}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        isUnableToSendInvite
                          ? setIsEditDialogOpen(true)
                          : handleSendCustomerReinvite(id);
                      }}
                      startIcon={
                        action.isLoading &&
                        action.type === ActionType.reinvite &&
                        action.currentResourceId === id && (
                          <CircularProgress
                            color="secondary"
                            size={20}
                            thickness={6}
                          />
                        )
                      }
                    >
                      {attributes.thirdPartyId ? 'Resend' : 'Send'} Invite
                    </Button>
                  )}
                </Grid>
                <Can
                  perform={PERMISSIONS.VISIT_EMPLOYEE_PAGE}
                  yes={() =>
                    attributes.thirdPartyId && (
                      <Grid item>
                        <Button
                          /** We have to disable editing because we use the thirdPartyId
                           * if the thirdPartyId is not present, we won't be able
                           * to find the customer in the database without it */
                          disabled={action.isLoading}
                          variant="outlined"
                          color="secondary"
                          onClick={() =>
                            setAction({
                              ...action,
                              type: ActionType.edit,
                              currentResourceId: id,
                            })
                          }
                        >
                          Edit
                        </Button>
                      </Grid>
                    )
                  }
                />
                <Grid item>
                  <Button
                    className={classes.noWrapButton}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveTeamMember(id)}
                    disabled={action.isLoading}
                    startIcon={
                      action.isLoading &&
                      action.type === ActionType.remove &&
                      action.currentResourceId === id && (
                        <CircularProgress
                          color="secondary"
                          size={20}
                          thickness={6}
                        />
                      )
                    }
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {!attributes.thirdPartyId && (
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Alert severity="warning">
            Account setup is incomplete. Invite team member to complete the
            process.
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};
