// Libraries
import React, { useEffect, useRef, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
// Utils
import { useApi, IApiData, FormulaAttributes } from 'api';
import { ITheme } from 'styles/mui/themeV2';
// Constants
const RESOURCES_PER_PAGE = 10;

const generateMenuObjects = (data: IApiData<FormulaAttributes>[]) => {
  return data.sort((a, b) =>
    new Date(a.attributes.createdAt as string).getTime() >
    new Date(b.attributes.createdAt as string).getTime()
      ? 1
      : -1
  );
};

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    loadMoreButton: {
      justifyContent: 'center',
    },
    loadingIcon: {
      minWidth: 'fit-content',
      marginLeft: theme.spacing(1),
    },
  })
);

interface FormulasDropdownProps {
  formula: Maybe<IApiData<FormulaAttributes>>;
  projectUuid: string;
  handleClick: (formula: IApiData<FormulaAttributes>) => void;
}

export const FormulasDropdown: React.FC<FormulasDropdownProps> = ({
  formula,
  projectUuid,
  handleClick,
}) => {
  const { getProjectFormulas } = useApi();
  const anchorEl = useRef();
  const classes = useStyles();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const [page, setPage] = useState<number>(1);
  const [isLoadingFormulas, setIsLoadingFormulas] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [formulaMenuItems, setFormulaMenuItems] = useState<
    IApiData<FormulaAttributes>[]
  >([]);

  const getFormulas = React.useCallback(() => {
    if (!projectUuid) return;

    setIsLoadingFormulas(true);

    getProjectFormulas({
      urlParams: `${projectUuid}/formulas?page=${
        page + 1
      }&per_page=${RESOURCES_PER_PAGE}`,
      handleSuccess: ({ data }: { data: IApiData<FormulaAttributes>[] }) => {
        setPage(page + 1);
        setFormulaMenuItems((prevItems) => [
          ...prevItems,
          ...generateMenuObjects(data),
        ]);
      },
      handleFinally: () => setIsLoadingFormulas(false),
    });
  }, [projectUuid, getProjectFormulas, page]);

  useEffect(() => {
    getProjectFormulas({
      urlParams: `${projectUuid}/formulas?page=1&per_page=${RESOURCES_PER_PAGE}`,
      handleSuccess: ({
        data,
        meta,
      }: {
        data: IApiData<FormulaAttributes>[];
        meta: Maybe<any>;
      }) => {
        setTotal(meta?.total || 0);

        setFormulaMenuItems(generateMenuObjects(data));
      },
    });
  }, [projectUuid, getProjectFormulas]);

  return (
    <Grid container item xs={12} direction="row" alignItems="center">
      <Typography className="cursor-pointer" onClick={toggleMenu}>
        {formula
          ? `Formula #: ${formula.attributes.externalId}`
          : 'Select a formula'}
      </Typography>
      <ArrowDropDown
        className="cursor-pointer"
        innerRef={anchorEl}
        onClick={toggleMenu}
        data-testid="menu-toggle"
      />
      <Menu
        id="formulas-menu"
        anchorEl={anchorEl.current}
        open={isMenuOpen}
        onClose={toggleMenu}
      >
        {formulaMenuItems.map((formula, index) => (
          <MenuItem onClick={() => handleClick(formula)} key={index}>
            Formula #: {formula.attributes.externalId}
          </MenuItem>
        ))}
        {formulaMenuItems.length < total && (
          <MenuItem
            className={classes.loadMoreButton}
            disabled={isLoadingFormulas}
            onClick={getFormulas}
          >
            <Typography variant="h4">Load More</Typography>
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
};
