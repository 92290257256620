// Libraries
import { useContext } from 'react';
// Utils
import { UserContext } from 'context';
// Constants
import { ROLES } from 'features/rbac';

export const useIsEmployee = () => {
  const { roles } = useContext(UserContext)!;
  const isEmployee = roles && roles.includes(ROLES.EMPLOYEE);

  return isEmployee;
};
