// Libraries
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
// Components
import {
  ComponentsSection,
  FormulaSection,
  LaborSection,
  MarginSection,
  OrderQuantitySection,
} from './worksheet-sections';
import { CostSummary } from './cost-summary.component';
import { FillSizeSelector } from './fill-size-selector.component';
import { WorksheetInfo } from './worksheet-info.component';
import { SectionSummaryRow } from './section-summary-row.component';
// Utils
import { QuoteCalculator } from '../quote-calculator/quote-calculator.component';
// Constants
import { SECTIONS, WORKSHEET_FORM_KEYS, orderQuantityData } from './constants';
import { UNITS } from 'features/types';
import { USDollar } from 'features/utils';

interface WorksheetProps {
  closeWorksheet: () => void;
}

export const Worksheet: React.FC<WorksheetProps> = ({ closeWorksheet }) => {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const { values } = useFormikContext<any>();

  const quoteCalculatorOne = new QuoteCalculator({
    orderQuantity: values[WORKSHEET_FORM_KEYS.ORDER_QUANTITY_ONE] as number,
    productionLocation: 'Miami',
    profit: values[WORKSHEET_FORM_KEYS.PROFIT_ONE],
    size: 8,
    unit: UNITS.oz,
  });

  const quoteCalculatorTwo = new QuoteCalculator({
    orderQuantity: values[WORKSHEET_FORM_KEYS.ORDER_QUANTITY_TWO] as number,
    productionLocation: 'Miami',
    profit: values[WORKSHEET_FORM_KEYS.PROFIT_TWO],
    size: 8,
    unit: UNITS.oz,
  });

  const quoteCalculatorThree = new QuoteCalculator({
    orderQuantity: values[WORKSHEET_FORM_KEYS.ORDER_QUANTITY_THREE] as number,
    productionLocation: 'Miami',
    profit: values[WORKSHEET_FORM_KEYS.PROFIT_THREE],
    size: 8,
    unit: UNITS.oz,
  });

  const formulaCosts = quoteCalculatorOne.calculateFormulaCost(
    values[WORKSHEET_FORM_KEYS.INGREDIENTS]
  );

  // Calling these so that the margin calculation will work properly on all three columns.
  quoteCalculatorTwo.calculateFormulaCost(
    values[WORKSHEET_FORM_KEYS.INGREDIENTS]
  );
  quoteCalculatorThree.calculateFormulaCost(
    values[WORKSHEET_FORM_KEYS.INGREDIENTS]
  );

  const laborCosts = [
    quoteCalculatorOne.calculateLaborCost({
      cappingHeadCount: values[WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_ONE],
      preworkHeadCount: values[WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_ONE],
      operatorHeadCount: values[WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_ONE],
      unitCartonHeadCount:
        values[WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_ONE],
      packingPalletizerHeadCount:
        values[WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_ONE],
      compoundingHeadCount:
        values[WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_ONE],
      efficiencyPercentage:
        values[WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_ONE],
      runRate: values[WORKSHEET_FORM_KEYS.RUN_RATE_ONE],
    }),
    quoteCalculatorTwo.calculateLaborCost({
      cappingHeadCount: values[WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_TWO],
      preworkHeadCount: values[WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_TWO],
      operatorHeadCount: values[WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_TWO],
      unitCartonHeadCount:
        values[WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_TWO],
      packingPalletizerHeadCount:
        values[WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_TWO],
      compoundingHeadCount:
        values[WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_TWO],
      efficiencyPercentage:
        values[WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_TWO],
      runRate: values[WORKSHEET_FORM_KEYS.RUN_RATE_TWO],
    }),
    quoteCalculatorThree.calculateLaborCost({
      cappingHeadCount: values[WORKSHEET_FORM_KEYS.CAPPING_HEAD_COUNT_THREE],
      preworkHeadCount: values[WORKSHEET_FORM_KEYS.PREWORK_HEAD_COUNT_THREE],
      operatorHeadCount: values[WORKSHEET_FORM_KEYS.OPERATOR_HEAD_COUNT_THREE],
      unitCartonHeadCount:
        values[WORKSHEET_FORM_KEYS.UNIT_CARTON_HEAD_COUNT_THREE],
      packingPalletizerHeadCount:
        values[WORKSHEET_FORM_KEYS.PACKING_PALLETIZER_HEAD_COUNT_THREE],
      compoundingHeadCount:
        values[WORKSHEET_FORM_KEYS.COMPOUNDING_HEAD_COUNT_THREE],
      efficiencyPercentage:
        values[WORKSHEET_FORM_KEYS.EFFICIENCY_PERCENTAGE_THREE],
      runRate: values[WORKSHEET_FORM_KEYS.RUN_RATE_THREE],
    }),
  ];

  const componentsCosts = [
    quoteCalculatorOne.calculateComponentsCost({
      masterBox: values[WORKSHEET_FORM_KEYS.MASTER_BOX_ONE],
      additionalComponentOne:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_A],
      additionalComponentTwo:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_B],
      additionalComponentThree:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_ONE_C],
    }),
    quoteCalculatorTwo.calculateComponentsCost({
      masterBox: values[WORKSHEET_FORM_KEYS.MASTER_BOX_TWO],
      additionalComponentOne:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_A],
      additionalComponentTwo:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_B],
      additionalComponentThree:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_TWO_C],
    }),
    quoteCalculatorThree.calculateComponentsCost({
      masterBox: values[WORKSHEET_FORM_KEYS.MASTER_BOX_THREE],
      additionalComponentOne:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_A],
      additionalComponentTwo:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_B],
      additionalComponentThree:
        values[WORKSHEET_FORM_KEYS.ADDITIONAL_COMPONENT_THREE_C],
    }),
  ];

  const margins = [
    quoteCalculatorOne.calculateMargin(),
    quoteCalculatorTwo.calculateMargin(),
    quoteCalculatorThree.calculateMargin(),
  ];

  const contributionsPerRun = [
    quoteCalculatorOne.getContributionPerRun(),
    quoteCalculatorTwo.getContributionPerRun(),
    quoteCalculatorThree.getContributionPerRun(),
  ];

  const onCancel = () => setActiveSection(null);

  return (
    <>
      <div className="relative w-full bg-white max-w-[1440px] mx-auto">
        <div className="grid grid-cols-12 flex-col font-inter ml-16 mr-16 gap-x-6">
          <WorksheetInfo />
          <FillSizeSelector />
        </div>
      </div>
      <div className="bg-grey-95 h-full">
        <div className="relative w-full max-w-[1440px] mx-auto">
          <div className="grid grid-cols-12 flex-col font-inter ml-16 mr-16 gap-x-6">
            <div className=" col-start-2 col-span-10 mt-11">
              <OrderQuantitySection
                data={orderQuantityData}
                isActive={activeSection === SECTIONS.ORDER_QTY}
                setActiveSection={setActiveSection}
              />
              <SectionSummaryRow
                data={{
                  title: 'Formula',
                  values: [
                    USDollar.format(formulaCosts.totalCostPerSizeAndUnit),
                    USDollar.format(formulaCosts.totalCostPerSizeAndUnit),
                    USDollar.format(formulaCosts.totalCostPerSizeAndUnit),
                  ],
                }}
                innerContent={
                  <FormulaSection
                    costs={formulaCosts}
                    isActive={activeSection === SECTIONS.FORMULA}
                  />
                }
                isActive={activeSection === SECTIONS.FORMULA}
                onCancel={onCancel}
                setActiveSection={() => setActiveSection(SECTIONS.FORMULA)}
              />
              <SectionSummaryRow
                data={{
                  title: 'Labor Cost',
                  values: laborCosts.map(({ headCountCost }) =>
                    USDollar.format(headCountCost)
                  ),
                }}
                innerContent={
                  <LaborSection
                    costs={laborCosts}
                    isActive={activeSection === SECTIONS.LABOR}
                  />
                }
                isActive={activeSection === SECTIONS.LABOR}
                onCancel={onCancel}
                setActiveSection={() => setActiveSection(SECTIONS.LABOR)}
              />
              <SectionSummaryRow
                data={{
                  title: 'Components Cost',
                  values: componentsCosts.map(({ componentTotalCost }) =>
                    USDollar.format(componentTotalCost)
                  ),
                }}
                innerContent={
                  <ComponentsSection
                    costs={componentsCosts}
                    isActive={activeSection === SECTIONS.COMPONENTS}
                  />
                }
                isActive={activeSection === SECTIONS.COMPONENTS}
                onCancel={onCancel}
                setActiveSection={() => setActiveSection(SECTIONS.COMPONENTS)}
              />
              <SectionSummaryRow
                data={{
                  title: 'Margin',
                  values: margins.map(({ margin }) => `${Math.round(margin)}%`),
                }}
                innerContent={
                  <MarginSection
                    costs={margins}
                    isActive={activeSection === SECTIONS.MARGIN}
                  />
                }
                isActive={activeSection === SECTIONS.MARGIN}
                onCancel={onCancel}
                setActiveSection={() => setActiveSection(SECTIONS.MARGIN)}
              />
            </div>
          </div>
        </div>
        <CostSummary
          contributionsPerRun={contributionsPerRun}
          laborCosts={laborCosts}
          closeWorksheet={closeWorksheet}
        />
      </div>
    </>
  );
};
