// Libraries
import React from 'react';
import MUISnackbar from '@material-ui/core/Snackbar';
import { Alert, Color } from '@material-ui/lab';

export interface SnackbarProps {
  action?: Maybe<React.ReactNode>;
  open: boolean;
  severity: Color;
  message?: string;
  duration?: number;
  handleClose: () => void;
}

const SB_SEVERITY = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

const getGenericMessage = (severity: Color): string => {
  switch (severity) {
    case SB_SEVERITY.error:
      return 'There was an error, please try again!';
    case SB_SEVERITY.success:
      return 'Action completed successfully!';
    default:
      return '';
  }
};

export const Snackbar: React.FC<SnackbarProps> = ({
  action,
  open,
  severity,
  message,
  duration,
  handleClose,
}) => {
  const alertMessage = message ? message : getGenericMessage(severity);

  return (
    <MUISnackbar
      action={action}
      open={open}
      autoHideDuration={duration || 4000}
      onClose={handleClose}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
    >
      <Alert severity={severity}>
        {alertMessage} {action && action}
      </Alert>
    </MUISnackbar>
  );
};
