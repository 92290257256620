// Utils
import { MileStone, TaskOwner } from './types';

export const HIDE_TEAM_INVITATION_BANNER_KEY = 'hideTeamInvitationBanner';

export const NOT_APPLICABLE = 'N/A';

export const MILESTONE_TITLES = {
  PROJECT_BRIEF: 'Project Brief',
  R_AND_D_FORMULATION: 'R&D Formulation',
  TESTING: 'Testing',
  PROCUREMENT: 'Procurement',
  PRODUCTION: 'Production',
};

export const MILESTONE_STATUSES = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN PROGRESS',
  NOT_STARTED: 'NOT STARTED',
};

export const PROJECT_TYPES_TO_HIDE = ['Innovation', 'TT (Internal)', 'Pricing'];
export const ON_HOLD_GROUP_NAME = 'On Hold/Stuck';
export const ARCHIVED_GROUP_NAME = 'Archive';

export const FILE_CATEGORIES = {
  approveIngredientList: 'approve-ingredient-list',
  sendArtwork: 'send-artwork',
  billsOfMaterials: 'bills-of-materials',
};

export const TASK_TITLES = {
  SUBMIT_BRIEF: 'Submit brief',
  PROJECT_REVIEW: 'Project review',
  PAY_DEPOSIT: 'Pay deposit',
  PRELIMINARY_INGREDIENT_LIST: 'Preliminary ingredient list',
  APPROVE_INGREDIENT_LIST: 'Approve preliminary ingredient list',
  FORMULATION: 'Formulation',
  SAMPLE_SHIPPED: 'Sample shipped',
  GIVE_FEEDBACK: 'Give feedback',
  FEEDBACK_REVIEW: 'Feedback review',
  COMPLETE_3RD_PARTY_TESTING_ACKNOWLEDGEMENT:
    'Complete 3rd party testing acknowledgement',
  SUBMIT_PAYMENT_FOR_TESTING: 'Submit payment for testing',
  TESTING: 'Testing',
  SUBMIT_PAYMENT_FOR_PRODUCTION_ORDER: 'Submit payment for production order',
  SEND_ARTWORK: 'Send artwork',
  SEND_BILL_OF_MATERIALS: 'Send bill of materials',
  CONFIRM_ETA_FOR_COMPONENTS: 'Confirm ETA for components',
  PRODUCTION: 'Production',
  SEND_FINAL_PAYMENT: 'Send final payment',
  ORDER_SHIPPED: 'Order shipped',
  PRELIM_QUOTE_APPROVED: 'Prelim Quote Approved',
  FINAL_QUOTE_APPROVED: 'Final Quote Approved',
};
export const RTL_SAMPLE_FEEDBACK_TASK_TITLE = 'Approve sample';

export const TASK_COLUMN_IDS = {
  SUBMIT_BRIEF: 'status_105',
  PROJECT_REVIEW: 'status_12',
  PAY_DEPOSIT: 'color2',
  PRELIMINARY_INGREDIENT_LIST: 'color7',
  APPROVE_INGREDIENT_LIST: 'color6',
  FORMULATION: 'color4',
  SAMPLE_SHIPPED: 'dup__of_formulation',
  GIVE_FEEDBACK: 'color40',
  FEEDBACK_REVIEW: 'color48',
  SUBMIT_PAYMENT_FOR_TESTING: 'color3',
  TESTING: 'color64',
  SUBMIT_PAYMENT_FOR_PRODUCTION_ORDER: 'color9',
  SEND_ARTWORK: 'color5',
  SEND_BILL_OF_MATERIALS: 'color95',
  CONFIRM_ETA_FOR_COMPONENTS: 'color79',
  PRODUCTION: 'color1',
  SEND_FINAL_PAYMENT: 'color63',
  ORDER_SHIPPED: 'dup__of_production',
  // TODO: these are staging column ids, we have to update them when we move to production
  PRELIM_QUOTE_APPROVED: 'status7__1',
  FINAL_QUOTE_APPROVED: 'status9__1',
} as const;

export const MILESTONES: Array<MileStone> = [
  {
    title: MILESTONE_TITLES.PROJECT_BRIEF,
    tagColor: '#eaeaea',
    tasks: [
      {
        title: TASK_TITLES.SUBMIT_BRIEF,
        columnId: TASK_COLUMN_IDS.SUBMIT_BRIEF,
        owner: TaskOwner.customer,
        status: undefined,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.PROJECT_REVIEW,
        columnId: TASK_COLUMN_IDS.PROJECT_REVIEW,
        owner: TaskOwner.prime,
        status: undefined,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.PAY_DEPOSIT,
        columnId: TASK_COLUMN_IDS.PAY_DEPOSIT,
        owner: TaskOwner.customer,
        status: undefined,
        waivedTitle: 'R&D deposit is waived',
        updatedAt: undefined,
      },
    ],
  },
  {
    title: MILESTONE_TITLES.R_AND_D_FORMULATION,
    tagColor: '#F2F8FF',
    tasks: [
      {
        title: TASK_TITLES.PRELIMINARY_INGREDIENT_LIST,
        columnId: TASK_COLUMN_IDS.PRELIMINARY_INGREDIENT_LIST,
        owner: TaskOwner.prime,
        status: undefined,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.APPROVE_INGREDIENT_LIST,
        columnId: TASK_COLUMN_IDS.APPROVE_INGREDIENT_LIST,
        isActionable: true,
        isLast: true,
        owner: TaskOwner.customer,
        status: undefined,
        tag: FILE_CATEGORIES.approveIngredientList,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.FORMULATION,
        columnId: TASK_COLUMN_IDS.FORMULATION,
        owner: TaskOwner.prime,
        status: undefined,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.SAMPLE_SHIPPED,
        columnId: TASK_COLUMN_IDS.SAMPLE_SHIPPED,
        owner: TaskOwner.prime,
        status: undefined,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.GIVE_FEEDBACK,
        columnId: TASK_COLUMN_IDS.GIVE_FEEDBACK,
        owner: TaskOwner.customer,
        isActionable: true,
        status: undefined,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.FEEDBACK_REVIEW,
        columnId: TASK_COLUMN_IDS.FEEDBACK_REVIEW,
        owner: TaskOwner.prime,
        status: undefined,
        updatedAt: undefined,
      },
    ],
  },
  {
    title: MILESTONE_TITLES.TESTING,
    tagColor: '#f2f8ff',
    tasks: [
      {
        title: TASK_TITLES.COMPLETE_3RD_PARTY_TESTING_ACKNOWLEDGEMENT,
        columnId: 'status__1',
        owner: TaskOwner.customer,
        isActionable: true,
        status: undefined,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.SUBMIT_PAYMENT_FOR_TESTING,
        columnId: TASK_COLUMN_IDS.SUBMIT_PAYMENT_FOR_TESTING,
        owner: TaskOwner.customer,
        status: undefined,
        updatedAt: undefined,
        waivedTitle: 'Testing payment is waived',
      },
      {
        title: TASK_TITLES.TESTING,
        columnId: TASK_COLUMN_IDS.TESTING,
        owner: TaskOwner.prime,
        status: undefined,
        updatedAt: undefined,
      },
    ],
  },
  {
    title: MILESTONE_TITLES.PROCUREMENT,
    tagColor: '#f2f8ff',
    tasks: [
      {
        title: TASK_TITLES.SUBMIT_PAYMENT_FOR_PRODUCTION_ORDER,
        columnId: TASK_COLUMN_IDS.SUBMIT_PAYMENT_FOR_PRODUCTION_ORDER,
        owner: TaskOwner.customer,
        status: undefined,
        updatedAt: undefined,
        waivedTitle: 'Production order payment is waived',
      },
      {
        title: TASK_TITLES.SEND_ARTWORK,
        isActionable: true,
        columnId: TASK_COLUMN_IDS.SEND_ARTWORK,
        owner: TaskOwner.customer,
        status: undefined,
        updatedAt: undefined,
        canUploadFiles: true,
        tag: FILE_CATEGORIES.sendArtwork,
      },
      {
        title: TASK_TITLES.SEND_BILL_OF_MATERIALS,
        isActionable: true,
        columnId: TASK_COLUMN_IDS.SEND_BILL_OF_MATERIALS,
        owner: TaskOwner.customer,
        status: undefined,
        updatedAt: undefined,
        canUploadFiles: true,
        tag: FILE_CATEGORIES.billsOfMaterials,
      },
      {
        title: TASK_TITLES.CONFIRM_ETA_FOR_COMPONENTS,
        columnId: TASK_COLUMN_IDS.CONFIRM_ETA_FOR_COMPONENTS,
        owner: TaskOwner.customer,
        status: undefined,
        updatedAt: undefined,
      },
    ],
  },
  {
    title: MILESTONE_TITLES.PRODUCTION,
    tagColor: '#e5f8f3',
    tasks: [
      {
        title: TASK_TITLES.PRODUCTION,
        columnId: TASK_COLUMN_IDS.PRODUCTION,
        owner: TaskOwner.prime,
        status: undefined,
        updatedAt: undefined,
      },
      {
        title: TASK_TITLES.SEND_FINAL_PAYMENT,
        columnId: TASK_COLUMN_IDS.SEND_FINAL_PAYMENT,
        owner: TaskOwner.customer,
        status: undefined,
        updatedAt: undefined,
        waivedTitle: 'Final payment is waived',
      },
      {
        title: TASK_TITLES.ORDER_SHIPPED,
        columnId: TASK_COLUMN_IDS.ORDER_SHIPPED,
        owner: TaskOwner.prime,
        status: undefined,
        updatedAt: undefined,
      },
    ],
  },
];

// FILE UPLOAD SECTION

// File extensions
export const ACCEPTED_FILE_EXTENSIONS = [
  // images
  'png',
  'jpg',
  'jpeg',
  'gif',
  'webp',
  'heic',
  'heif',
  'svg',
  'tiff',
  'eps',
  // documents
  'pdf',
  'doc',
  'xls',
  'key',
  'ppt',
  'csv',
  'docx',
  'pptx',
  'xlsx',
  // videos
  'mp4',
  'm4v',
  'mpg',
  'mov',
  'wmv',
  'avi',
];

// File upload warnings
export const UPLOAD_WARNINGS = {
  duplicateFileWarning: {
    title: 'This file name already exists.',
    content:
      'A file with this name already exists so we couldn’t upload it. Would you like to replace it or keep both?',
  },
  fileSizeWarning: {
    title: 'File size too large',
    content:
      'The file you are trying to upload is larger than 25MB. Please try again with a smaller file.',
  },
  fileExtensionWarning: {
    title: 'File type not supported',
    content:
      'The file you are trying to upload is not supported. Please try again with a supported file type.',
  },
};

// Duplicate file modal selection values
export const DUPE_VALUES = {
  duplicate: 'duplicate',
  replace: 'replace',
};

export const FILE_UPLOAD_FOOTER = {
  title: 'File size limit 25k. Supported filetypes:',
  content:
    '.avi, .csv, .doc, .docx, .eps, .gif, .heic, .heif, .jpg, .jpeg, .key, .m4v, .mpg, .mp4, .mov, .pdf, .png, .ppt, .pptx, .svg, .tiff, .webp, .wmv, .xls, .xlsx',
};

export const IL_MODAL_TYPES = {
  submitFeedback: 'submitFeedback',
  submitApproval: 'submitApproval',
};

export const ORDER_STATUS = {
  open: 'open',
  paid: 'paid',
  cancelled: 'cancelled',
};
