// Utils
import { BriefAttributes, FormulaAttributes, IApiData } from 'api';
// Constants
import { CONTAINER_TYPE, PRODUCTION_LOCATIONS } from './constants';

export const generateProjectErrors = ({
  brief,
  formulas,
  productionLocation,
}: {
  brief: IApiData<BriefAttributes>;
  formulas: Maybe<IApiData<FormulaAttributes>[]>;
  productionLocation: Maybe<valueof<typeof PRODUCTION_LOCATIONS>>;
}): string[] => {
  const FOIL_SACHET_MOQ_MINIMUM = 25_000;
  const incompatibleContainerAndMoq =
    brief.attributes?.briefSupplement?.data.attributes?.container ===
      CONTAINER_TYPE.FOIL_SACHET && brief.attributes?.minimumOrderQuantity
      ? brief.attributes?.minimumOrderQuantity < FOIL_SACHET_MOQ_MINIMUM
      : false;

  const isMissingRequiredBriefInformation = [
    brief.attributes.size,
    brief.attributes.minimumOrderQuantity,
  ].some((value) => !value);

  const isMissingRequiredComponentInformation = [
    brief.attributes?.briefSupplement?.data.attributes.container,
    brief.attributes?.briefSupplement?.data.attributes.material,
    brief.attributes?.briefSupplement?.data.attributes.closure,
  ].some((value) => !value);

  const errorMessages: string[] = [];

  if (!productionLocation)
    errorMessages.push('Choose Production Location in project brief.');

  if (isMissingRequiredBriefInformation)
    errorMessages.push('Add unit size and/or order quantity to project brief.');

  if (isMissingRequiredComponentInformation)
    errorMessages.push('Choose Components in project brief');

  if (!formulas || formulas.length <= 0)
    errorMessages.push('Assign one or more formulas to project.');

  if (incompatibleContainerAndMoq)
    errorMessages.push(
      'Foil Sachet MOQ is 25,000. Increase Annual Order Quantity on Project Brief.'
    );

  return errorMessages;
};
