// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Utils
import { SectionProps } from './types';
// Components
import { SectionHeader } from '../section-header.component';
import { TextField } from 'design-system';
import { USDollar } from 'features/utils';
// Constants
import { WORKSHEET_FORM_KEYS } from '../constants';

export const MarginSection: React.FC<SectionProps> = ({ costs, isActive }) => {
  const { getFieldProps } = useFormikContext();

  return (
    <div
      className={`${isActive ? 'h-auto' : 'h-0 overflow-hidden'} flex flex-col`}
    >
      <SectionHeader title="Margin" />
      <div className="px-3 bg-white">
        <div className="grid grid-cols-10 py-[6px] items-center min-h-[44px]">
          <div className="flex flex-col col-span-2 text-grey-50">
            <span>Cost</span>
            <span className="text-xs">Formula + Labor + Component</span>
          </div>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[0].totalCost)}
          </span>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[1].totalCost)}
          </span>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[2].totalCost)}
          </span>
        </div>
        <div className="grid grid-cols-10 py-[6px] items-center min-h-[44px]">
          <span className="col-span-2 text-grey-50">Profit</span>
          <div className="col-span-2 mr-6">
            <TextField
              {...getFieldProps(WORKSHEET_FORM_KEYS.PROFIT_ONE)}
              height="h-11"
              leadingText={'$'}
              type="number"
            />
          </div>
          <div className="col-span-2 mr-6">
            <TextField
              {...getFieldProps(WORKSHEET_FORM_KEYS.PROFIT_TWO)}
              height="h-11"
              leadingText={'$'}
              type="number"
            />
          </div>
          <div className="col-span-2 mr-6">
            <TextField
              {...getFieldProps(WORKSHEET_FORM_KEYS.PROFIT_THREE)}
              height="h-11"
              leadingText={'$'}
              type="number"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
