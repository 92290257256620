// Libraries
import React from 'react';
import { Button } from '@material-ui/core';

interface GiveSampleFeedbackProps {
  isSampleFeedbackTaskCompleted: boolean;
  handleOpenSampleFeedbackModal: () => void;
}

export const GiveSampleFeedback: React.FC<GiveSampleFeedbackProps> = ({
  isSampleFeedbackTaskCompleted,
  handleOpenSampleFeedbackModal,
}) => {
  return (
    <>
      <Button
        onClick={handleOpenSampleFeedbackModal}
        variant="outlined"
        color="secondary"
        style={{ marginRight: '0.5rem' }}
        className="w-1/2 sm:w-auto"
      >
        {isSampleFeedbackTaskCompleted ? 'View' : 'Sample'} Feedback
      </Button>
    </>
  );
};
