export const LABOR_HEAD_COUNT_AND_RUN_RATE_MAP = {
  DEFAULT: {
    cappingHeadCount: 0,
    compoundingHeadCount: 0,
    operatorHeadCount: 0,
    otherHeadCount: 0,
    packingOrPalletizerHeadCount: 0,
    preworkHeadCount: 0,
    runRate: 0,
    unitCartonHeadCount: 0,
  },
  BOTTLE_WITH_CAP: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 3,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 0,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 4,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 0,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 34,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 30,
        unitCartonHeadCount: 4,
      },
    },
  },
  BOTTLE_WITH_SPRAYER: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 2,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 2,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 34,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 30,
        unitCartonHeadCount: 6,
      },
    },
  },
  TOTTLE: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 2,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 4,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 34,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 30,
        unitCartonHeadCount: 6,
      },
    },
  },
  JAR: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 3,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 4,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 35,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 3,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 30,
        unitCartonHeadCount: 6,
      },
    },
  },
  TUBE_WITH_METAL: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 0,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 0,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 0,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 0,
        runRate: 10,
        unitCartonHeadCount: 3,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 0,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 0,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 0,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 0,
        runRate: 10,
        unitCartonHeadCount: 3,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 0,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 0,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 0,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 0,
        runRate: 10,
        unitCartonHeadCount: 3,
      },
    },
  },
  TUBE_WITH_PLASTIC: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 1,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 1,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 4,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 1,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 40,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 1,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 30,
        unitCartonHeadCount: 6,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 1,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 5,
        preworkHeadCount: 1,
        runRate: 60,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 1,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 40,
        unitCartonHeadCount: 8,
      },
    },
  },
  LIP_BALM_DEODORANT: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 3,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 17,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 17,
        unitCartonHeadCount: 4,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 17,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 3,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 4,
        preworkHeadCount: 1,
        runRate: 17,
        unitCartonHeadCount: 4,
      },
    },
  },
  FOIL_SACHET: {
    LOW: {
      cappingHeadCount: 0,
      compoundingHeadCount: 0,
      operatorHeadCount: 0,
      otherHeadCount: 0,
      packingOrPalletizerHeadCount: 0,
      preworkHeadCount: 0,
      runRate: 0,
      unitCartonHeadCount: 0,
    },
    MEDIUM: {
      cappingHeadCount: 0,
      compoundingHeadCount: 2,
      operatorHeadCount: 1,
      otherHeadCount: 0,
      packingOrPalletizerHeadCount: 0,
      preworkHeadCount: 0,
      runRate: 53,
      unitCartonHeadCount: 0,
    },
    HIGH: {
      cappingHeadCount: 0,
      compoundingHeadCount: 2,
      operatorHeadCount: 1,
      otherHeadCount: 0,
      packingOrPalletizerHeadCount: 0,
      preworkHeadCount: 0,
      runRate: 53,
      unitCartonHeadCount: 0,
    },
  },
  AEROSOL: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 3,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 3,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 2,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 3,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 3,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 2,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 3,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 3,
        packingOrPalletizerHeadCount: 2,
        preworkHeadCount: 1,
        runRate: 10,
        unitCartonHeadCount: 2,
      },
    },
  },
  BAG_ON_VALVE: {
    LOW: {
      withoutUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 20,
        unitCartonHeadCount: 4,
      },
    },
    MEDIUM: {
      withoutUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 25,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 25,
        unitCartonHeadCount: 4,
      },
    },
    HIGH: {
      withoutUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 25,
        unitCartonHeadCount: 0,
      },
      withUnitCarton: {
        cappingHeadCount: 2,
        compoundingHeadCount: 2,
        operatorHeadCount: 1,
        otherHeadCount: 0,
        packingOrPalletizerHeadCount: 3,
        preworkHeadCount: 1,
        runRate: 25,
        unitCartonHeadCount: 4,
      },
    },
  },
};
