//Libraries
import React from 'react';

export type UserSession = {
  uuid: Maybe<string>;
  givenName: Maybe<string>;
  familyName: Maybe<string>;
  email: Maybe<string>;
  avatarColor: Maybe<string>;
  thirdPartyId: Maybe<string>;
  customerMetadata: CustomerMetadata;
  employeeMetadata: EmployeeMetadata;
  [index: string]: any;
};

export type CustomerMetadata = {
  companyName: Maybe<string>;
  companyUuid: Maybe<string>;
  notificationPreference: Maybe<string>;
  isNewCustomer: Maybe<boolean>;
  appState?: any;
};

export type EmployeeMetadata = {
  location: Maybe<string>;
  searchLocation: Maybe<string>;
};

export type UserContextType = {
  roles: Array<string>;
  setRoles: (value: Array<string>) => void;
  permissions: Array<string>;
  setPermissions: (value: Array<string>) => void;
  userSession: UserSession;
  setUserSession: (value: UserSession) => void;
};

export const UserContext = React.createContext<UserContextType | undefined>(
  undefined
);
