// Libraries
import React from 'react';
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { FormikHelpers, FormikProps } from 'formik';
// Utils
import { IApiData, CustomerAttributes, useApi } from 'api';
import { useSnackbar } from 'hooks';
import { Action, TeamMemberFormValues } from './types';
// Components
import { TeamMemberFormik } from './team-member-formik.component';
// Constants
import { TEAM_MEMBER_FIELD_NAMES } from './team-management.constants';

interface EditTeamMemberInlineProps {
  setTeamMembers: React.Dispatch<
    React.SetStateAction<IApiData<CustomerAttributes, any>[]>
  >;
  setAction: React.Dispatch<React.SetStateAction<Action>>;
  teamMember: IApiData<CustomerAttributes>;
}

export const EditTeamMemberInline: React.FC<EditTeamMemberInlineProps> = ({
  setAction,
  setTeamMembers,
  teamMember,
}): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const { patchCustomer } = useApi();
  const { id, attributes } = teamMember;

  const initialValues: TeamMemberFormValues = {
    givenName: attributes.givenName,
    familyName: attributes.familyName,
    email: attributes.email,
  };

  const handleTeamMemberUpdate = (
    values: TeamMemberFormValues,
    { setSubmitting }: FormikHelpers<TeamMemberFormValues>
  ) => {
    patchCustomer({
      urlParams: attributes.thirdPartyId,
      data: {
        customer: {
          givenName: values.givenName,
          familyName: values.familyName,
          email: values.email,
        },
      },
      handleSuccess: (data: IApiData<CustomerAttributes>) => {
        setTeamMembers((prev) =>
          prev.map((teamMember) => (teamMember.id === id ? data : teamMember))
        );

        showSnackbar('Team member updated successfully', 'success');

        setAction({
          currentResourceId: undefined,
          isLoading: false,
          type: undefined,
        });
      },
      handleFinally: () => setSubmitting(false),
    });
  };

  return (
    <TeamMemberFormik
      initialValues={initialValues}
      handleSubmit={handleTeamMemberUpdate}
    >
      {({
        submitForm,
        isSubmitting,
        getFieldProps,
        errors,
        touched,
        resetForm,
        dirty,
      }: FormikProps<TeamMemberFormValues>) => (
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12} sm={10} spacing={4}>
            <Grid item xs={6}>
              <TextField
                {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.givenName)}
                disabled={isSubmitting}
                error={!!(errors.givenName && touched.givenName)}
                fullWidth
                helperText={touched.givenName && errors.givenName}
                label="First Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.familyName)}
                disabled={isSubmitting}
                error={!!(errors.familyName && touched.familyName)}
                fullWidth
                helperText={touched.familyName && errors.familyName}
                label="Last Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...getFieldProps(TEAM_MEMBER_FIELD_NAMES.email)}
                disabled={!!attributes.thirdPartyId}
                fullWidth
                helperText={
                  // When a team member has a thirdPartyId,
                  // it means they have an Auth0 account.
                  // In this case, the email can't be changed.
                  attributes.thirdPartyId
                    ? 'Email can’t be changed.'
                    : touched.email && errors.email
                }
                error={!!(errors.email && touched.email)}
                label="Email"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={2}
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item xs={12} sm={8}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={submitForm}
                disabled={!dirty || isSubmitting}
                startIcon={
                  isSubmitting && (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      thickness={6}
                    />
                  )
                }
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                disabled={isSubmitting}
                onClick={() => {
                  dirty &&
                    resetForm({
                      values: {
                        givenName: attributes.givenName,
                        familyName: attributes.familyName,
                        email: attributes.email,
                      },
                    });

                  setAction({
                    currentResourceId: undefined,
                    isLoading: false,
                    type: undefined,
                  });
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </TeamMemberFormik>
  );
};
