//Libraries
import React from 'react';
//Components
import { withAuthorizationRequired } from './with-authorization-required.component';

interface IProtectedComponentProps {
  children: any;
  permission: string;
  redirectTo?: string;
  path: string;
  [x: string]: any;
}

const ProtectedComponent = ({
  children,
  permission,
  redirectTo,
  ...rest
}: IProtectedComponentProps) => {
  const WrappedComponent = withAuthorizationRequired(
    children,
    {},
    permission,
    redirectTo
  );

  return <WrappedComponent />;
};

/**
 * Use react memo to prevent the protected route from rendering unnecessarily.
 * IF the callback returns false the component re-renders
 * ELSE the component stays mounted
 *
 * For this use case we only want the component to re-render if the paths are different OR
 * If the paths are the same, we also need to check if params from the URL are different in cases
 * when a component is the same but different params are being injected.
 */
export default React.memo(
  ProtectedComponent,
  (prevProps: any, nextProps: any) => {
    if (prevProps.path !== nextProps.path) {
      return false;
    }

    // If they are equal DO NOT re-render
    return prevProps.path === nextProps.path;
  }
);
