// Libraries
import React from 'react';
import { Button, Checkbox, TextField } from '@material-ui/core';
import { Formik, Form, useFormikContext, FormikHelpers } from 'formik';
import * as Yup from 'yup';
// Utils
import { IApiData, SampleFeedbackAttributes } from 'api';
import { SampleFeedbackFormValues } from './types';
import { SampleFeedbackModalState } from '../types';
import { SampleFeedbackStatus } from 'features/home/customer-portal/types';
import { useAnalytics } from 'hooks';
import { determineIfAllApproved } from './utils';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const rows = [
  { title: 'COLOR', name: 'color' },
  { title: 'FRAGRANCE', name: 'fragrance' },
  { title: 'TEXTURE', name: 'texture' },
  { title: 'APPLICATION / AFTER FEEL', name: 'application' },
  { title: 'EMOLLIENCE / MOISTURIZATION', name: 'emolliency' },
  { title: 'VISCOSITY / THICKNESS', name: 'viscosity' },
  { title: 'FUNCTION PERFORMANCE', name: 'performance' },
  { title: 'OTHER', name: 'other', noteOnly: true },
];

const validationSchema = Yup.object().shape({
  colorFeedback: Yup.string().required().label('Color'),
  colorNote: Yup.string().label('Color Note'),
  fragranceFeedback: Yup.string().required().label('Fragrance'),
  fragranceNote: Yup.string().label('Fragrance Note'),
  textureFeedback: Yup.string().required().label('Texture'),
  textureNote: Yup.string().label('Texture Note'),
  applicationFeedback: Yup.string().required().label('Application'),
  applicationNote: Yup.string().label('Application Note'),
  emolliencyFeedback: Yup.string().required().label('Emolliency'),
  emolliencyNote: Yup.string().label('Emolliency Note'),
  viscosityFeedback: Yup.string().required().label('Viscosity'),
  viscosityNote: Yup.string().label('Viscosity Note'),
  performanceFeedback: Yup.string().required().label('Performance'),
  performanceNote: Yup.string().label('Performance Note'),
  otherNote: Yup.string().label('Other Note'),
});

interface FeedbackInputRowProps {
  title: string;
  name: string;
  noteOnly?: boolean;
  disabled?: boolean;
}

const FeedbackInputRow: React.FC<FeedbackInputRowProps> = ({
  title,
  name,
  noteOnly,
  disabled,
}) => {
  const analytics = useAnalytics()!;
  const { getFieldProps, values, setFieldValue, errors } = useFormikContext<
    SampleFeedbackFormValues
  >();

  const inputName = `${name}Feedback`;

  return (
    <div className="flex items-stretch text-xs border-b border-lighterGray m-h-20">
      <div
        className={`${
          noteOnly ? 'w-5/12' : 'w-2/12'
        } bg-lightestGray px-5 py-8 h-auto font-mono border-r border-r-lighterGray`}
      >
        <h6>{title}</h6>
      </div>
      {!noteOnly && (
        <>
          <div
            className={`flex items-center justify-center w-1/12 border-r border-r-lighterGray h-auto ${
              inputName in errors && 'bg-[#faede9]'
            }`}
          >
            <Checkbox
              {...getFieldProps(inputName)}
              disabled={disabled}
              name={inputName}
              onChange={({ target: { checked } }) => {
                analytics.trackEvent({
                  eventCategory:
                    AnalyticConstants.EVENT_CATEGORIES.sampleFeedbackCheckboxes,
                  eventAction:
                    AnalyticConstants.EVENT_ACTIONS[
                      checked ? 'checkboxCheck' : 'checkboxUncheck'
                    ],
                  eventLabel: AnalyticConstants.EVENT_LABEL.checkbox(
                    `${inputName} - ${SampleFeedbackStatus.approved}`
                  ),
                });

                setFieldValue(
                  inputName,
                  checked ? SampleFeedbackStatus.approved : ''
                );
              }}
              value={SampleFeedbackStatus.approved}
              checked={
                values[inputName as keyof SampleFeedbackFormValues] ===
                SampleFeedbackStatus.approved
              }
            />
          </div>
          <div
            className={`flex items-center justify-center w-1/12 border-r border-r-lighterGray h-auto ${
              inputName in errors && 'bg-[#faede9]'
            }`}
          >
            <Checkbox
              {...getFieldProps(inputName)}
              disabled={disabled}
              name={inputName}
              onChange={({ target: { checked } }) => {
                analytics.trackEvent({
                  eventCategory:
                    AnalyticConstants.EVENT_CATEGORIES.sampleFeedbackCheckboxes,
                  eventAction:
                    AnalyticConstants.EVENT_ACTIONS[
                      checked ? 'checkboxCheck' : 'checkboxUncheck'
                    ],
                  eventLabel: AnalyticConstants.EVENT_LABEL.checkbox(
                    `${inputName} - ${SampleFeedbackStatus.notApproved}`
                  ),
                });

                setFieldValue(
                  inputName,
                  checked ? SampleFeedbackStatus.notApproved : ''
                );
              }}
              value={SampleFeedbackStatus.notApproved}
              checked={
                values[inputName as keyof SampleFeedbackFormValues] ===
                SampleFeedbackStatus.notApproved
              }
            />
          </div>
          <div
            className={`flex items-center justify-center w-1/12 border-r border-r-lighterGray h-auto ${
              inputName in errors && 'bg-[#faede9]'
            }`}
          >
            <Checkbox
              {...getFieldProps(inputName)}
              disabled={disabled}
              name={`${name}Approved`}
              onChange={({ target: { checked } }) => {
                analytics.trackEvent({
                  eventCategory:
                    AnalyticConstants.EVENT_CATEGORIES.sampleFeedbackCheckboxes,
                  eventAction:
                    AnalyticConstants.EVENT_ACTIONS[
                      checked ? 'checkboxCheck' : 'checkboxUncheck'
                    ],
                  eventLabel: AnalyticConstants.EVENT_LABEL.checkbox(
                    `${inputName} - ${SampleFeedbackStatus.notApplicable}`
                  ),
                });

                setFieldValue(
                  inputName,
                  checked ? SampleFeedbackStatus.notApplicable : ''
                );
              }}
              value={SampleFeedbackStatus.notApplicable}
              checked={
                values[inputName as keyof SampleFeedbackFormValues] ===
                SampleFeedbackStatus.notApplicable
              }
            />
          </div>
        </>
      )}
      <div className="flex items-center w-7/12 px-5 py-3 border-r border-r-lighterGray h-auto">
        <TextField
          {...getFieldProps(`${name}Note`)}
          onChange={(e) => {
            analytics.trackEvent({
              eventCategory:
                AnalyticConstants.EVENT_CATEGORIES.sampleFeedbackInputs,
              eventAction: AnalyticConstants.EVENT_ACTIONS.inputChange,
              eventLabel: AnalyticConstants.EVENT_LABEL.input(`${name}Note`),
            });

            setFieldValue(`${name}Note`, e.target.value);
          }}
          disabled={disabled}
          fullWidth
          variant="outlined"
          multiline
        />
      </div>
    </div>
  );
};

interface SampleFeedbackFormProps {
  formulaId?: Maybe<string>;
  setSampleFeedbackModalState?: React.Dispatch<
    React.SetStateAction<SampleFeedbackModalState>
  >;
  sampleFeedback?: Maybe<IApiData<SampleFeedbackAttributes>>;
  handleFeedbackSubmit?: (
    values: SampleFeedbackFormValues,
    helpers: FormikHelpers<SampleFeedbackFormValues>
  ) => void;
  isReadOnly?: boolean;
  isLegacyView?: boolean;
  hasApprovedFeedback?: boolean;
}

export const SampleFeedbackForm: React.FC<SampleFeedbackFormProps> = ({
  formulaId,
  setSampleFeedbackModalState,
  sampleFeedback,
  handleFeedbackSubmit,
  isReadOnly,
  isLegacyView,
  hasApprovedFeedback,
}): JSX.Element => {
  const attributes = sampleFeedback?.attributes;

  const INITIAL_VALUES: SampleFeedbackFormValues = {
    formulaId: formulaId || '',
    colorFeedback: attributes?.colorFeedback || '',
    colorNote: attributes?.colorNote || '',
    fragranceFeedback: attributes?.fragranceFeedback || '',
    fragranceNote: attributes?.fragranceNote || '',
    textureFeedback: attributes?.textureFeedback || '',
    textureNote: attributes?.textureNote || '',
    applicationFeedback: attributes?.applicationFeedback || '',
    applicationNote: attributes?.applicationNote || '',
    emolliencyFeedback: attributes?.emolliencyFeedback || '',
    emolliencyNote: attributes?.emolliencyNote || '',
    viscosityFeedback: attributes?.viscosityFeedback || '',
    viscosityNote: attributes?.viscosityNote || '',
    performanceFeedback: attributes?.performanceFeedback || '',
    performanceNote: attributes?.performanceNote || '',
    otherNote: attributes?.otherNote || '',
  };

  return (
    <div className="flex flex-col mb-4">
      <Formik
        initialValues={{
          ...INITIAL_VALUES,
        }}
        onSubmit={handleFeedbackSubmit as any}
        validationSchema={validationSchema}
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ isSubmitting, isValid, values, setValues, submitForm }) => {
          const allRowsApproved = determineIfAllApproved(values);

          return (
            <Form>
              {rows.map(({ title, name, noteOnly }) => (
                <FeedbackInputRow
                  key={name}
                  title={title}
                  name={name}
                  disabled={isReadOnly}
                  noteOnly={noteOnly}
                />
              ))}
              {isReadOnly && attributes?.createdAt && (
                <div className="bg-lighterGray text-lightGray font-mono text-xs px-5 py-3">
                  FEEDBACK GIVEN ON{' '}
                  {new Date(attributes?.createdAt).toLocaleDateString('en-US')}
                </div>
              )}
              <div className="flex flex-col md:flex-row lg:justify-between w-full mt-6 relative">
                {!isValid && (
                  <div className="flex text-red-40 justify-start">
                    <p>
                      Please complete all rows. If a characteristic is not
                      relevant to your product, check N/A.
                    </p>
                  </div>
                )}
                <div className="flex flex-col lg:flex-row justify-between sticky lg:static lg:ml-auto ml-0 left-0 w-fit-content lg:w-full mt-4 md:mt-0">
                  {hasApprovedFeedback || isLegacyView ? (
                    // Empty div for spacing
                    <div></div>
                  ) : (
                    <Button
                      onClick={() => {
                        setValues({
                          ...values,
                          colorFeedback: SampleFeedbackStatus.approved,
                          fragranceFeedback: SampleFeedbackStatus.approved,
                          textureFeedback: SampleFeedbackStatus.approved,
                          applicationFeedback: SampleFeedbackStatus.approved,
                          emolliencyFeedback: SampleFeedbackStatus.approved,
                          viscosityFeedback: SampleFeedbackStatus.approved,
                          performanceFeedback: SampleFeedbackStatus.approved,
                        });
                        setTimeout(() => {
                          submitForm();
                        }, 0);
                      }}
                      variant="outlined"
                      className="!bg-green-50 !mb-2"
                      disabled={isSubmitting}
                    >
                      Approve Formula
                    </Button>
                  )}
                  <section>
                    <Button
                      onClick={() =>
                        setSampleFeedbackModalState!({
                          isOpen: false,
                          isViewOnly: false,
                        })
                      }
                      variant="outlined"
                      color="secondary"
                      disabled={isSubmitting}
                    >
                      {isReadOnly ? 'Close' : 'Cancel'}
                    </Button>
                    {!isReadOnly && (
                      <Button
                        style={{ marginLeft: '8px' }}
                        type="submit"
                        disabled={isSubmitting}
                        color={allRowsApproved ? 'secondary' : 'primary'}
                        variant="contained"
                        disableElevation
                      >
                        {allRowsApproved ? 'Approve' : 'Submit'}
                      </Button>
                    )}
                  </section>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
