// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import { Select, Typography } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { UserContext } from 'context';
// Constants
import {
  CONTAINER_MENU_ITEMS,
  MATERIAL_MENU_ITEMS,
} from 'features/pricing-quote/constants';
import { BRIEF_FORM_KEYS } from '../brief.constants';
import { ROLES } from 'features/rbac';

export const Material = () => {
  const { roles } = useContext(UserContext)!;

  const isEmployee = roles.includes(ROLES.EMPLOYEE);

  const { getFieldProps, setFieldValue, values } = useFormikContext<
    InternalBriefFormValues
  >();

  const renderOptions = () => {
    return CONTAINER_MENU_ITEMS[values.container]?.materials.map(
      (materialName) => {
        const material = MATERIAL_MENU_ITEMS[materialName];
        return { ...material };
      }
    );
  };
  return (
    <div className="flex flex-col gap-3">
      <Typography variant="h4" font="inter" weight="bold">
        Material
      </Typography>
      <Select
        {...getFieldProps(BRIEF_FORM_KEYS.MATERIAL)}
        onChange={(option: Option) =>
          setFieldValue(BRIEF_FORM_KEYS.MATERIAL, option)
        }
        options={(renderOptions() as Option[]) || []}
        helperText={isEmployee ? 'Required for Pricing Quote' : ''}
      />
    </div>
  );
};
