// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Component
import { WizardContext } from '../../wizard/wizard.context';
// Utils
import { BriefFormValues } from 'features/brief/customer-brief/types';
import { useAnalytics, useMobile } from 'hooks';
// Assets
import backArrow from '../../../assets/backArrow.svg';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const BACK = 'BACK';

interface IFormLayout {
  buttonText?: string;
  children: any;
  isNextButtonDisabled?: boolean;
  isBackButtonDisabled?: boolean;
  isBackButtonHidden?: boolean;
  onClick?: () => void;
  subTitle?: string | JSX.Element;
  tip?: JSX.Element;
  title: string;
}

export const FormLayout: React.FC<IFormLayout> = ({
  buttonText = 'Next',
  children,
  isNextButtonDisabled,
  isBackButtonDisabled,
  isBackButtonHidden,
  onClick,
  subTitle,
  tip,
  title,
}) => {
  const analytics = useAnalytics()!;
  const { pageNumber, decrementPage, incrementPage } = useContext(
    WizardContext
  );
  const { isMobile } = useMobile();
  const { values } = useFormikContext<BriefFormValues>();

  const handleNextClick = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBQuestion,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbNext(
        `${String(pageNumber).padStart(2, '0')}. ${title}`
      ),
    });
    onClick ? onClick() : incrementPage();
  };

  const handleBackClick = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBQuestion,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbBack(
        `${String(pageNumber).padStart(2, '0')}. ${title}`
      ),
    });
    decrementPage();
  };

  const badgeText =
    values.productCategory && values.productType
      ? `${values.productCategory}: ${values.productType}`
      : values.productCategory
      ? values.productCategory
      : '';

  return isMobile ? (
    <div className="h-full">
      {badgeText && (
        <div className="mx-auto my-5 px-4 py-2 bg-darkPeach font-mono rounded-[6.25rem] text-[0.813rem] max-w-fit whitespace-nowrap">
          {badgeText.toLocaleUpperCase()}
        </div>
      )}
      <div
        className={`grid grid-cols-2 gap-x-6 mx-5 ${
          badgeText ? 'mt-6' : 'mt-8'
        }`}
      >
        <div className="col-start-1">
          {!isBackButtonHidden && (
            <button
              disabled={isBackButtonDisabled}
              onClick={handleBackClick}
              className="flex items-center cursor-pointer"
            >
              <img className="h-4 w-4" src={backArrow} alt="back" />
              <div className="font-mono font-semibold text-sm text-gray">
                {BACK}
              </div>
            </button>
          )}
        </div>
        <div className="mt-3 col-start-1 col-span-2 flex flex-col">
          <p className="font-headline font-extrabold text-[32px] leading-9 mb-2">
            {title}
          </p>
          <p className="font-display text-base text-gray">{subTitle}</p>
        </div>
      </div>
      <div
        className={`bg-lightestBlue mt-10 pb-[150px] min-h-screen ${
          !tip && 'pt-10'
        }`}
      >
        {tip && <div className="bg-legacyPeach mb-10">{tip}</div>}
        {children}
      </div>
      <div className="bg-black fixed bottom-0 w-full p-4 z-[9999]">
        <button
          disabled={isNextButtonDisabled}
          onClick={handleNextClick}
          className={`${
            isNextButtonDisabled
              ? 'text-lightGray bg-gray'
              : 'text-white bg-blue-60'
          } w-full whitespace-nowrap px-5 py-4 text-base font-display`}
        >
          {buttonText}
        </button>
      </div>
    </div>
  ) : (
    <>
      <div className="mx-20 max-w-[1440px] sm:mx-auto">
        <div className="grid grid-cols-12 gap-x-6 sm:mt-4">
          <div className="col-start-2 flex items-end">
            {!isBackButtonHidden && (
              <button
                disabled={isBackButtonDisabled}
                onClick={handleBackClick}
                className="flex items-center cursor-pointer"
              >
                <img className="h-4 w-4" src={backArrow} alt="back" />
                <div className="font-mono font-semibold text-sm text-gray">
                  {BACK}
                </div>
              </button>
            )}
          </div>
          {badgeText && (
            <div className="col-start-5 col-span-4 flex justify-center items-end">
              <div className="px-4 py-2 bg-darkPeach font-mono rounded-[6.25rem] text-[0.813rem]">
                {badgeText.toLocaleUpperCase()}
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-12 gap-x-6 mt-[3rem]">
          <div className="col-start-2 col-span-8 flex flex-col">
            <p className="font-headline font-extrabold text-[3rem] mb-2">
              {title}
            </p>
            <p className="font-display text-lg text-gray">{subTitle}</p>
          </div>
          <div className="col-start-11 col-span-1">
            <button
              disabled={isNextButtonDisabled}
              onClick={handleNextClick}
              className={`${
                isNextButtonDisabled ? 'bg-gray' : 'bg-blue-60'
              } whitespace-nowrap px-5 py-4 text-white text-lg font-display`}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
      <div className="bg-lightestBlue mt-16">
        <div className="flex max-w-[1440px] sm:mx-auto">
          <div className={`h-screen pt-10 ${tip ? 'w-3/4' : 'w-full'}`}>
            {children}
          </div>
          {tip && <div className="w-1/4 bg-legacyPeach">{tip}</div>}
        </div>
      </div>
    </>
  );
};
