import { ITableColumnCollection } from 'features/types';

export const INITIAL_NUM_SEARCH_RESULTS_TO_DISPLAY = 10;
export const NUM_RECENT_SEARCHES_TO_DISPLAY = 5;
export const DATE_FORMAT = 'MM/dd/yyyy';

export const FORMULAS = 'formulas';
export const FORMULAS_TITLE = 'Formulas';
export const PROJECTS = 'projects';

export const CLEAR_SELECTIONS = 'clear selections';
export const CREATED_AT = 'Created at: ';
export const FILTER_BY = 'filter by:';
export const INITIAL_TABLES_TO_DISPLAY = [FORMULAS, PROJECTS];
export const NONE = 'none';
export const NOT_APPLICABLE = 'N/A';
export const RECENT_SEARCHES = 'recentSearches';
export const RECENT_SEARCHES_SECTION_TITLE = 'RECENT SEARCHES';
export const SORT_BY = 'sort by:';
export const TABLES_FILTER_DELIMITER = 1;

export const FORMULA_SEARCH_RESULTS_TABLE_COLUMNS: ITableColumnCollection = {
  formulaName: {
    align: 'left',
    columnWidth: '25%',
    headerText: 'formula name',
    padding: '1rem 10px',
    includeAsFilter: true,
  },
  formulaNumber: {
    align: 'left',
    columnWidth: '15%',
    headerText: 'formula #',
    padding: '1rem 0',
    includeAsFilter: true,
  },
  recipeNumber: {
    align: 'left',
    columnWidth: '15%',
    headerText: 'recipe #',
    padding: '1rem 0',
    includeAsFilter: true,
  },
  companyName: {
    align: 'left',
    columnWidth: '15%',
    headerText: 'company',
    padding: '1rem 0',
    includeAsFilter: true,
  },
  projectName: {
    align: 'left',
    columnWidth: '15%',
    headerText: 'project',
    padding: '16px 10px',
    includeAsFilter: true,
  },
  lastModified: {
    align: 'left',
    columnWidth: '15%',
    headerText: 'last modified',
    padding: '16px 10px',
    includeAsFilter: false,
  },
};

export const PROJECT_SEARCH_RESULTS_TABLE_COLUMNS: ITableColumnCollection = {
  projectName: {
    align: 'left',
    columnWidth: '25%',
    headerText: 'project',
    padding: '1rem 10px',
    includeAsFilter: true,
  },
  mondayId: {
    align: 'left',
    columnWidth: '15%',
    headerText: 'monday id',
    padding: '1rem 0',
    includeAsFilter: true,
  },
  companyName: {
    align: 'left',
    columnWidth: '15%',
    headerText: 'company',
    padding: '1rem 0',
    includeAsFilter: true,
  },
  lastModified: {
    align: 'left',
    columnWidth: '15%',
    headerText: 'last modified',
    padding: '1rem 0',
    includeAsFilter: false,
  },
};

export const FORMULA_SEARCH_RESULTS_FILTERS = {
  name: {
    name: 'Formula Name',
    value: 'name',
    isFiltering: false,
  },
  externalId: {
    name: 'Formula #',
    value: 'externalId',
    isFiltering: false,
  },
  productionRecipeId: {
    name: 'Recipe #',
    value: 'productionRecipeId',
    isFiltering: false,
  },
  company: {
    name: 'Company',
    value: 'company',
    isFiltering: false,
  },
  project: {
    name: 'Project',
    value: 'project',
    isFiltering: false,
  },
  rmName: {
    name: 'RM Trade Name',
    value: 'rmName',
    isFiltering: false,
  },
};

export const PROJECT_SEARCH_RESULTS_FILTERS = {
  name: {
    name: 'Project',
    value: 'name',
    isFiltering: false,
  },
  externalId: {
    name: 'Monday ID',
    value: 'externalId',
    isFiltering: false,
  },
  company: {
    name: 'Company',
    value: 'company',
    isFiltering: false,
  },
};

export const FORMULA_SEARCH_RESULTS_SORT_OPTIONS = {
  none: {
    name: 'None',
    value: 'none',
  },
  project_name: {
    name: 'Project (Alphabetically)',
    value: 'project_name',
  },
  last_modified: {
    name: 'Last Modified',
    value: 'last_modified',
  },
};

export const PROJECT_SEARCH_RESULTS_SORT_OPTIONS = {
  none: {
    name: 'None',
    value: 'none',
  },
  last_modified: {
    name: 'Last Modified',
    value: 'last_modified',
  },
};
