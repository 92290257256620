//Libraries
import { useState, useCallback } from 'react';

export const useError = () => {
  // we only need the second property from useState
  const [, setError] = useState<any>('');

  return useCallback((error?: Error) => {
    /*
      setting state with an error triggers the
       error boundary. This provides a way for
       async requests to use the error boundary
    */
    setError(() => {
      throw error;
    });
  }, []);
};
