// Libraries
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import { UserContext } from 'context';
// Utils
import { CustomerAttributes, IApiData, useApi } from 'api';
import { useSnackbar } from 'hooks';
// Constants
import { NOTIFICATION_PREFERENCE_VALUES } from './constants';

const RADIO_OPTIONS = [
  {
    label: 'Yes, send me notification emails',
    description:
      'Send me emails from Element when my action is needed (reviewing ILs, approving samples, etc.)',
    value: NOTIFICATION_PREFERENCE_VALUES.EMAIL_ALL,
  },
  {
    label: 'Unsubscribe',
    description: 'Do not send me any emails.',
    value: NOTIFICATION_PREFERENCE_VALUES.EMAIL_NONE,
  },
];

export const NotificationSettings = () => {
  const {
    userSession,
    userSession: {
      customerMetadata: { notificationPreference },
      thirdPartyId,
    },
    setUserSession,
  } = useContext(UserContext)!;
  const { patchCustomer } = useApi();
  const { showSnackbar } = useSnackbar();
  const {
    handleSubmit,
    getFieldProps,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: {
      notificationPreference,
    },
    validationSchema: Yup.object().shape({
      notificationPreference: Yup.string().required(
        'An email preference is required'
      ),
    }),
    onSubmit: (values): any => {
      setSubmitting(true);
      patchCustomer({
        urlParams: thirdPartyId,
        data: {
          customer: {
            notificationPreference: values.notificationPreference,
          },
        },
        handleSuccess: (data: IApiData<CustomerAttributes>) => {
          showSnackbar(
            'Notification preferences updated successfully',
            'success'
          );
          setUserSession({
            ...userSession,
            customerMetadata: {
              ...userSession.customerMetadata,
              companyName: data.attributes.name,
            },
          });
        },
        handleFinally: () => {
          setSubmitting(false);
        },
      });
    },
  });

  const renderRadioOptions = () => {
    return RADIO_OPTIONS.map((option, index) => {
      const fieldProps = getFieldProps('notificationPreference');
      return (
        <>
          {index === RADIO_OPTIONS.length - 1 && (
            <hr className="text-grey-90" />
          )}
          <label className="flex items-center gap-4 cursor-pointer" key={index}>
            <div>
              <input
                type="radio"
                {...fieldProps}
                value={option.value}
                checked={fieldProps.value === option.value}
              />
            </div>
            <div className="flex flex-col">
              <span>{option.label}</span>
              <span className="text-grey-50">{option.description}</span>
            </div>
          </label>
        </>
      );
    });
  };

  return (
    <div className="flex flex-col font-inter gap-12 max-w-[550px]">
      <div className="flex flex-col gap-3 justify-start">
        <span className="font-bold">Email Notification Settings</span>
      </div>
      {renderRadioOptions()}
      <button
        type="submit"
        disabled={isSubmitting}
        onClick={() => handleSubmit()}
        className="flex items-center w-[70px] h-10 rounded p-4 bg-royal-50 text-grey-90 hover:bg-royal-40 active:bg-royal-30"
      >
        Save
      </button>
    </div>
  );
};
