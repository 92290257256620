import * as Constants from '../../constants';

export const isFileSizeTooBig = (file: Maybe<File>) => {
  if (!file) return false;

  return file.size > 25_000_000;
};

export const isFileExtensionAccepted = (file: Maybe<File>) => {
  if (!file) return false;

  const extension = file.name.split('.').pop();
  return Constants.ACCEPTED_FILE_EXTENSIONS.includes(extension!);
};

export const isFileADuplicate = (selectedFile: Maybe<File>, files: any) => {
  if (!selectedFile) return false;

  return files.some((file: any) => file.attributes.name === selectedFile.name);
};
