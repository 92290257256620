// Libraries
import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Create, InfoOutlined } from '@material-ui/icons';
// Utils
import { IApiData, ProjectAttributes } from 'api';
import { useAnalytics, useMobile } from 'hooks';
import { TaskStatus } from '../types';
import { useApi } from 'api';
// Constants
import { MILESTONES, MILESTONE_TITLES } from '../constants';
import { AnalyticsConstants } from 'features/analytics';

interface IProjectTitleAndTags {
  isDisabled?: boolean;
  large?: boolean;
  project: IApiData<ProjectAttributes>;
}

export const ProjectTitleAndTags: React.FC<IProjectTitleAndTags> = ({
  isDisabled,
  large,
  project,
}) => {
  const analytics = useAnalytics!();
  const { isMobile } = useMobile();
  const { patchProject } = useApi();

  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState(project.attributes.name);

  const handleEditNameIconClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    setIsEditingProjectName(true);
  };

  const handleProjectNameSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    updateProjectName();
  };

  const updateProjectName = () => {
    if (!isEditingProjectName) return;

    if (projectName !== project.attributes.name) {
      setIsLoading(true);
      patchProject({
        urlParams: project.id,
        data: {
          project: {
            name: projectName,
          },
        },
        handleSuccess: (data) => {
          setProjectName(data.attributes.name);
        },
        handleFinally: () => {
          setIsEditingProjectName(false);
          setIsLoading(false);
        },
      });
    } else {
      setIsEditingProjectName(false);
    }
  };

  const renderTags = (project: IApiData<ProjectAttributes>) => {
    const tasks = project?.parsedIncluded?.tasks;
    if (!tasks) return null;
    let tagToRender = null;

    if (tasks) {
      for (const milestone of MILESTONES) {
        const isDoneOrInProgress = milestone.tasks.some((task) => {
          const correspondingTask = tasks.find(
            (t: IApiData<any>) => t.attributes.mondayColumnId === task.columnId
          );
          if (!correspondingTask) return false;
          return (
            (correspondingTask &&
              correspondingTask.attributes.status === TaskStatus.done) ||
            correspondingTask.attributes.status === TaskStatus.inProgress
          );
        });
        if (isDoneOrInProgress) {
          tagToRender = milestone;
        }
      }
    }

    return (
      <div className="flex">
        {isDisabled && (
          <div className="font-medium bg-lightestGray mr-2 uppercase px-3 py-[6px] rounded-full font-mono text-xs">
            ON HOLD
          </div>
        )}
        {!isDisabled && (
          <div
            style={{ backgroundColor: tagToRender?.tagColor }}
            className={`font-medium mr-2 uppercase px-3 py-[6px] rounded-full font-mono text-xs ${
              tagToRender?.title === MILESTONE_TITLES.PRODUCTION
                ? 'text-legacyGreen'
                : 'text-darkGray'
            }`}
          >
            {tagToRender?.title}
          </div>
        )}
        {project.parsedIncluded.brief.attributes.size &&
          project.parsedIncluded.brief.attributes.unit && (
            <div
              id="size"
              className={`font-medium uppercase px-3 py-[6px] rounded-full font-mono text-xs ${
                isDisabled
                  ? 'bg-lighterGray text-lightGray'
                  : 'bg-legacyPeach text-darkGray'
              }`}
            >
              {project.parsedIncluded.brief.attributes.size}{' '}
              {project.parsedIncluded.brief.attributes.unit}
            </div>
          )}
      </div>
    );
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex flex-col items-start mr-6 cursor-default"
    >
      <div
        className={`font-inter text-lightGray ${large ? 'text-sm' : 'text-xs'}`}
      >
        {`Formula #:  ${project.attributes.latestFormulaNumber || 'N/A'} `}
      </div>
      <div className="my-2 flex items-center w-full">
        {isEditingProjectName ? (
          <TextField
            disabled={isLoading}
            onChange={(e) => setProjectName(e.target.value)}
            style={{ marginRight: '6px' }}
            size="small"
            variant="outlined"
            value={projectName}
          />
        ) : (
          <h3
            className={`mr-2 font-inter text-left sm:whitespace-nowrap sm:overflow-hidden sm:text-ellipsis ${
              large ? 'text-[28px]' : 'text-lg'
            } ${isDisabled ? 'text-lightGray' : 'text-darkGray'}`}
          >
            {projectName}
          </h3>
        )}
        {isEditingProjectName ? (
          <Button
            disabled={isLoading}
            onClick={handleProjectNameSave}
            style={{
              border: '1px solid #E2E6EA',
              backgroundColor: '#EBF1F9',
              color: '#2B323B',
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Save'}
          </Button>
        ) : (
          <Create
            onClick={(e) => handleEditNameIconClick(e)}
            className="mr-2 cursor-pointer"
          />
        )}
        {!isMobile && !isEditingProjectName && (
          <Tooltip
            onMouseEnter={() => {
              analytics?.trackEvent({
                eventCategory: AnalyticsConstants.EVENT_CATEGORIES.CBDashboard,
                eventAction: AnalyticsConstants.EVENT_ACTIONS.hover,
                eventLabel: `Project Title Tooltip`,
              });
            }}
            title={
              <div>
                <p>
                  Target date:{' '}
                  {new Date(
                    project.parsedIncluded.brief.attributes.targetDate
                  ).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                    timeZone: 'UTC',
                  })}
                </p>
                <p>
                  Created:{' '}
                  {new Date(
                    project.attributes.createdAt as string
                  ).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </p>
              </div>
            }
          >
            <InfoOutlined />
          </Tooltip>
        )}
      </div>
      {renderTags(project)}
    </div>
  );
};
