//Libraries
import React from 'react';
import Button from '@material-ui/core/Button';
import { useAuth0 } from '@auth0/auth0-react';

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button fullWidth onClick={loginWithRedirect}>
      Sign In
    </Button>
  );
};
