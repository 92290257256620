// Libraries
import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  // useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  ExpandMore as ExpandMoreIcon,
  // CheckCircle,
  Warning as WarningIcon,
} from '@material-ui/icons';
// Utils
import { resourceFunctionType } from 'api';

interface NotificationLevel {
  0: 'info';
  1: 'warning';
  2: 'error';
  [x: string]: 'info' | 'warning' | 'error';
}

/**
 * These are sent from the API as a number from 0 -> 2
 */
const NOTIFICATION_LEVELS: NotificationLevel = {
  0: 'info',
  1: 'warning',
  2: 'error',
};

interface Warning {
  message: string;
  level: number;
}
interface IWarningsProps {
  resource: string;
  warningsEndPoint: string;
  getWarnings: resourceFunctionType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    validationContainer: {
      flexBasis: '25%',
    },
    accordionSummaryRoot: {
      padding: theme.spacing(1, 2, 0, 2),
    },
    accordion: {
      backgroundColor: theme.palette.warning.main,
      color: '#fff',
      maxHeight: 225,
      overflow: 'scroll',
      borderRadius: 5,
    },
    accordionDetails: {
      padding: theme.spacing(1, 2, 0.5),
    },
    warningAlert: {
      width: '100%',
      color: '#000',
      backgroundColor: '#fff',
    },
  })
);

export const Warnings: React.FC<IWarningsProps> = ({
  getWarnings,
  warningsEndPoint,
  resource,
}) => {
  const classes = useStyles();

  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<Array<Maybe<Warning>>>([]);

  useEffect(() => {
    setIsValidating(true);
    getWarnings({
      urlParams: warningsEndPoint,
      handleSuccess: (response: { data: Array<Warning> }) =>
        setWarnings(response.data),
      handleError: (error) => {
        setHasError(true);
        Rollbar.error(error);
      },
      handleFinally: () => setIsValidating(false),
    });
  }, [getWarnings, warningsEndPoint]);

  const isValidated: boolean = !isValidating && warnings.length === 0;

  if (hasError) {
    return (
      <Grid item container xs={10} style={{ alignSelf: 'center' }}>
        <Grid item xs={12}>
          <Alert variant="filled" severity="error">
            <Typography style={{ paddingLeft: 5 }}>
              Error validating {resource}
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    );
  }

  if (isValidating) {
    return (
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        className={classes.validationContainer}
      >
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <CircularProgress size={30} />
        </Grid>
        <Grid item xs={6}>
          <Typography style={{ paddingLeft: 5 }}>
            Validating the {resource}...
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (isValidated) {
    return null;
    /* Commenting out for now as QC wants to sync on
       what it means for something to be validated */
    // return (
    //   <Grid item container xs={10} alignItems="center">
    //     <Grid item xs={1}></Grid>
    //     <Grid item xs={1}>
    //       <CheckCircle
    //         fontSize="large"
    //         style={{ color: theme.palette.success.main }}
    //       />
    //     </Grid>
    //     <Grid item xs={6}>
    //       <Typography style={{ paddingLeft: 5 }}>
    //         {resource.charAt(0).toUpperCase() + resource.slice(1)} is validated!
    //       </Typography>
    //     </Grid>
    //   </Grid>
    // );
  }

  const renderWarnings = (): Array<React.ReactNode> => {
    return warnings.map((warning: Maybe<Warning>) => (
      <AccordionDetails className={classes.accordionDetails}>
        <Alert
          elevation={1}
          classes={{ root: classes.warningAlert }}
          severity={
            warning?.level ? NOTIFICATION_LEVELS[warning?.level] : 'warning'
          }
        >
          {warning?.message}
        </Alert>
      </AccordionDetails>
    ));
  };

  return (
    <Accordion className={classes.accordion} style={{ margin: 24 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{ root: classes.accordionSummaryRoot }}
        IconButtonProps={{
          style: { background: 'transparent', marginRight: '4px' },
        }}
      >
        <Grid container item>
          <Grid item xs={1}>
            <Badge badgeContent={warnings.length} color="error">
              <WarningIcon />
            </Badge>
          </Grid>
          <Grid item xs={3}>
            <Typography style={{ padding: '0 0 0 2px' }}>Warnings!</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      {renderWarnings()}
    </Accordion>
  );
};
