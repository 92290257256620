// Libraries
import React, { useEffect, useState, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
// Components
import { TableHeaderRow } from './table-header-row.component';
// Assets
import { ReactComponent as Cancel } from 'features/formula/images/cancel.svg';
import spinner from 'features/formula/images/spinner.svg';
// Utils
import { IApiData, PriceAttributes } from 'api';
// Constants
import {
  DEFAULT_NUM_SEARCH_RESULTS,
  INCREMENT_NUM_SEARCH_RESULTS,
  SEARCH_TABLE_HEADERS,
} from 'features/formula/formula-page/constants';

interface IRawMaterialSearchDropdown {
  closeDropdown: () => void;
  handleSearchResultsSelection: (resultRow: any) => void;
  searchResults: Array<any>;
  isOpen: boolean;
  isSearching: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RawMaterialSearchDropdown: React.FC<IRawMaterialSearchDropdown> = ({
  closeDropdown,
  handleSearchResultsSelection,
  isOpen,
  isSearching,
  searchResults,
  setIsOpen,
}) => {
  const [numResultsToShow, setNumResultsToShow] = useState<number>(
    DEFAULT_NUM_SEARCH_RESULTS
  );

  const dropdownContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      setNumResultsToShow(DEFAULT_NUM_SEARCH_RESULTS);
    }
  }, [isOpen]);

  useEffect(() => {
    dropdownContainer?.current?.scrollTo({
      top: dropdownContainer.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [numResultsToShow]);

  const handleSearchResultsRowClick = (resultRow: any) => {
    handleSearchResultsSelection(resultRow);
    setIsOpen(false);
  };

  const handleShowMoreResultsClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setNumResultsToShow(numResultsToShow + INCREMENT_NUM_SEARCH_RESULTS);
  };

  const renderSearchResults = () => {
    if (searchResults.length <= 0) {
      return (
        <div className="flex h-40 items-center justify-center text-xl text-formulaGray">
          No results found
        </div>
      );
    }
    return searchResults.map((result: any, index: number) => {
      if (index <= numResultsToShow - 1) {
        const highestPrice = (result.prices as Array<
          IApiData<PriceAttributes>
        >).sort((a, b) => {
          return a.attributes.cost - b.attributes.cost;
        })[0] as Maybe<IApiData<PriceAttributes>>;

        return (
          <div
            key={`${result?.name}-${index}`}
            onClick={() => handleSearchResultsRowClick(result)}
            className="transition-colors ease-in-out duration-500 grid grid-cols-formulaSearchResultsColumn col-span-11 cursor-pointer hover:bg-formulaLightBlue"
          >
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {result?.name ? result.name : result.tradeName}
                </span>
              }
            >
              <span className="py-5 px-5 shadow-formulaTableRow truncate">
                {result?.name ? result.name : result.tradeName}
              </span>
            </Tooltip>
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {result?.rmId ? result.rmId : `SAMP-${result?.sampleCode}`}
                </span>
              }
            >
              <span className="py-5 px-5 shadow-formulaTableRow truncate">
                {result?.rmId ? result.rmId : `SAMP-${result?.sampleCode}`}
              </span>
            </Tooltip>
            <span className="py-5 px-5 shadow-formulaTableRow">
              {result?.location}
            </span>
            <span className="flex flex-col justify-end py-5 col-span-2 shadow-formulaTableRow">
              <div>
                ${(highestPrice?.attributes.cost || 0).toFixed(2)}/
                {highestPrice?.attributes.unit || 'unit'}
              </div>
              <div className="text-xs">
                <p className="inline text-lightGray">from</p>{' '}
                {(highestPrice as any)?.supplier?.attributes.name || 'N/A'}
              </div>
            </span>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div
      style={{ transform: 'translate(-50%, 0)' }}
      className="absolute top-[3.125rem] left-1/2  z-50 w-[658px] text-formulaDarkerGray rounded-md rounded-t-none bg-white shadow-formulaTable"
    >
      <div
        className="
        bg-darkGray text-white shadow-formulaTable grid grid-cols-formulaSearchResultsColumn"
      >
        <TableHeaderRow
          tableHeaders={SEARCH_TABLE_HEADERS.rawMaterialHeaders}
        />
        <div
          onClick={closeDropdown}
          className="justify-self-end self-center pr-5 cursor-pointer"
        >
          <Cancel width="20" height="20" />
        </div>
      </div>
      {/* Commenting this link out until we are ready to implement this functionality */}
      {/* <div className="p-5 shadow-formulaTableRow text-blue-60">
        <button>Create New Raw Material</button>
      </div> */}
      <div ref={dropdownContainer} className="h-60 overflow-y-auto">
        {isSearching ? (
          <div className="flex h-40 items-center justify-center">
            <img
              className="h-10 w-10 animate-spin"
              src={spinner}
              alt="spinner"
            />
          </div>
        ) : (
          renderSearchResults()
        )}
      </div>
      <div className="p-5 text-blue-60 rounded-b-md shadow-formulaTableFooter">
        {numResultsToShow < searchResults.length ? (
          <button onClick={handleShowMoreResultsClick}>
            Show More Results...
          </button>
        ) : (
          <span className="text-formulaDarkerGray">{`Showing all ${searchResults.length} results`}</span>
        )}
      </div>
    </div>
  );
};
