// Libraries
import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// Utils
import { ITheme, THEME_PALETTE } from 'styles/mui/themeV2';
import { WarningOrError } from './types';
// Constants
import { EXCESS_RAW_MATERIAL_ISSUE_SUMMARY } from './constants';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
const ZERO_WARNINGS = 0;

interface ExcessRawMaterialWarningsProps {
  warnings: WarningOrError[];
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    action: {
      alignSelf: 'flex-start',
      cursor: 'pointer',
      marginTop: '0.25rem',
    },
  })
);

export const ExcessRawMaterialWarnings: React.FC<ExcessRawMaterialWarningsProps> = ({
  warnings,
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  if (warnings.length <= ZERO_WARNINGS) return null;

  return (
    <Alert
      severity="warning"
      className="mb-2 cursor-pointer"
      onClick={() => setIsExpanded(!isExpanded)}
      style={{
        overflow: 'hidden',
        height: isExpanded ? '' : 48,
      }}
      action={isExpanded ? <ExpandLess /> : <ExpandMore />}
      classes={{
        action: classes.action,
      }}
    >
      <Typography variant="body2" style={{ color: THEME_PALETTE.gray.dark }}>
        {EXCESS_RAW_MATERIAL_ISSUE_SUMMARY}
      </Typography>
      <List
        dense
        style={{
          listStyleType: 'disc',
          marginLeft: '1rem',
          color: THEME_PALETTE.gray.dark,
        }}
      >
        {warnings.map((warning, index) => (
          <ListItem key={index} style={{ display: 'list-item' }} disableGutters>
            <ListItemText
              primary={warning.issue}
              secondary={warning.actionItem}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        ))}
      </List>
    </Alert>
  );
};
