// Libraries
import React from 'react';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';

// Styles
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    emptyFileContainer: {
      textAlign: 'center',
      color: theme.palette.gray.main,
      backgroundColor: theme.palette.gray.light,
      paddingTop: theme.spacing(6.5),
      paddingBottom: theme.spacing(6.5),
      borderRadius: theme.spacing(1),
      height: 200,
    },
  })
);

export const NoFiles: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      xs={12}
      className={classes.emptyFileContainer}
      container
      alignContent="center"
      justifyContent="center"
    >
      <Grid item>
        <Typography>
          No files yet. Files added to steps in the tracker will be shown here.
        </Typography>
      </Grid>
    </Grid>
  );
};
