// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { TextField, Typography } from 'design-system';
// Utils
import { PriceQuoteFormValues } from '../form.config';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from '../../price-quote.constants';

interface OverviewProps {
  companyName?: string;
  quoteIsEditable: Maybe<boolean>;
}

export const Overview: React.FC<OverviewProps> = ({
  companyName,
  quoteIsEditable,
}) => {
  const { getFieldProps, errors } = useFormikContext<PriceQuoteFormValues>();

  return (
    <div className="flex flex-col gap-6 w-[412px]">
      <Typography font="inter" variant="h3" color="primary" weight="semibold">
        Overview
      </Typography>
      <div>
        <TextField
          label="Customer Name"
          value={companyName}
          disabled
          height={null}
          additionalClasses="text-grey-50"
        />
      </div>
      <div>
        <TextField
          label="Quote Date"
          placeholder="00/00/00"
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.QUOTE_DATE)}
          height={null}
          error={Boolean(errors.quoteDate)}
          helperText={errors.quoteDate}
          disabled={!quoteIsEditable}
        />
      </div>
      <div>
        <TextField
          label="Valid for"
          placeholder="00"
          trailingText="days"
          type="number"
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.VALID_FOR)}
          height={null}
          error={Boolean(errors.validFor)}
          helperText={errors.validFor}
          disabled={!quoteIsEditable}
        />
      </div>
    </div>
  );
};
