// Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DescriptionOutlined } from '@material-ui/icons';
import * as amplitude from '@amplitude/analytics-browser';
// Utils
import { useAnalytics } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { ROUTES } from 'features/navigation';

interface IStartNewProjectButton {
  fullWidth?: boolean;
}

export const StartNewProjectButton: React.FC<IStartNewProjectButton> = ({
  fullWidth,
}) => {
  const navigate = useNavigate();
  const analytics = useAnalytics()!;
  const navigateToBrief = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBDashboard,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.startNewProject,
    });
    amplitude.track('Customer Brief Started');
    navigate(ROUTES.CREATE_BRIEF.route);
  };

  return (
    <div>
      <button
        onClick={navigateToBrief}
        className={`${
          fullWidth ? 'w-full' : ''
        } flex px-5 py-4 bg-blue-60 text-white text-base font-display items-center rounded`}
      >
        <DescriptionOutlined />
        <h5 className="ml-2">Start new project</h5>
      </button>
    </div>
  );
};
