// Libraries
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
// Components
import { Button, Typography } from 'design-system';
import cleanserSample from 'img/cleanser-sample.png';
import { RtlOrderSuccessListener } from 'features/brief/rtl-logic/rtl-order-success-listener.component';

export const PaymentConfirmationInterstitial = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const projectUuid = queryParams.get('project_id');
  const formulaName = queryParams.get('formula_name');

  const handleClick = () => {
    navigate(`/your-projects/${projectUuid}`);
  };

  useEffect(() => {
    if (!formulaName) return;
    amplitude.track('RTL Sample Purchased', {
      formula: formulaName,
    });
  }, [formulaName]);

  return (
    <>
      {projectUuid && <RtlOrderSuccessListener projectUuid={projectUuid} />}
      <div className="flex flex-col sm:flex-row sm:mx-auto mt-8 sm:mt-0 gap-16 sm:gap-0 max-w-[1440px]">
        <div className="flex flex-col sm:justify-center gap-6 mx-6 sm:mx-[124px] sm:w-1/2">
          <Typography variant="h2">
            Thank you for purchasing your
            <span className="text-peach-50"> {formulaName} </span>sample.
          </Typography>
          <Typography variant="h3" font="inter">
            You will receive an email confirmation when it ships.
          </Typography>
          <Typography variant="h3" font="inter">
            When your sample arrives, you'll be asked to approve it in your
            Element Project tracker.
          </Typography>
          <Button onClick={handleClick} text="View your Project Tracker" />
        </div>
        <img className="sm:w-1/2" src={cleanserSample} alt="cleanser sample" />
      </div>
    </>
  );
};
