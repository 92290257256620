// Libraries
import React from 'react';
// Components
import { ErrorBoundary } from 'features/error';
import { InviteTeamMemberButton } from './invite-team-member-button.component';
import { ProjectsTable } from './projects-table.component';
import { StartNewProjectButton } from './start-new-project-button.component';
import { TeamInvitationBanner } from './team-invitation-banner.component';
import { CustomerViewWarning } from '../ui/customer-view-warning.component';
// Utils
import { IApiData, ProjectAttributes } from 'api';
import { useMobile } from 'hooks';

interface ICustomerDashboard {
  companyName: string;
  isEmployee: boolean;
  isLoading: boolean;
  projects: Array<IApiData<ProjectAttributes>>;
}

export const CustomerDashboardV3: React.FC<ICustomerDashboard> = ({
  companyName,
  isEmployee,
  isLoading,
  projects,
}) => {
  const YOUR_PROJECTS = 'Your Projects';
  const { isMobile } = useMobile();

  const filterProjectsThatAreOnMondayCrm = (
    projects: Array<IApiData<ProjectAttributes>>
  ) => {
    return projects.filter((project) => project.attributes.mondayPulseId);
  };

  return (
    <main className="flex flex-col max-w-7xl mx-5 sm:mx-auto pb-[130px]">
      <section className="w-full flex justify-between mt-12 sm:mt-16 mb-7 sm:mb-10">
        <div className="flex items-center">
          <h1 className="text-3xl text-darkGray mr-3">{YOUR_PROJECTS}</h1>
          <div className="mt-1 px-3 py-[6px] bg-lightBlue text-darkGray text-center font-mono text-xs uppercase rounded-full">
            {companyName}
          </div>
        </div>
        <div className="flex justify-between w-3/12">
          {!isMobile && (
            <>
              <StartNewProjectButton />
              <InviteTeamMemberButton />
            </>
          )}
        </div>
      </section>
      <TeamInvitationBanner />
      <section className="w-full sm:mb-0 mb-16">
        <ErrorBoundary>
          <ProjectsTable
            companyName={companyName}
            projects={filterProjectsThatAreOnMondayCrm(projects)}
            isLoading={isLoading}
            isEmployee={isEmployee}
            isMobile={isMobile}
          />
        </ErrorBoundary>
      </section>
      {isEmployee && <CustomerViewWarning companyName={companyName} />}
    </main>
  );
};
