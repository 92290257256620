// Libraries
import React from 'react';
import {
  createStyles,
  makeStyles,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
// Components
import { IdModule } from './id-module.component';
import { InfoBox } from './info-box.component';
// Utils
import { ITheme } from 'styles/mui/themeV2';
import { IRawMaterialFormValues } from './raw-material-form.component';

const INFO_BOX_CONTENT = 'RM Code or Sample code must be entered';
const LOCATIONS = [
  { label: 'Miami', value: 'Miami' },
  { label: 'New York', value: 'New York' },
  { label: 'Los Angeles', value: 'Los Angeles' },
];

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(2),
    },
    select: {
      height: '23px',
      marginTop: theme.spacing(1),
    },
  })
);

export const GeneralSectionInputs = React.forwardRef(
  (_props: any, ref: any) => {
    const classes = useStyles();
    const {
      values: { general },
      setFieldValue,
      errors,
    } = useFormikContext<IRawMaterialFormValues>();

    return (
      <>
        <Grid
          ref={ref}
          container
          item
          spacing={5}
          xs={12}
          className={classes.row}
        >
          <Grid xs={6} item>
            <TextField
              required
              variant="outlined"
              label="Trade name"
              onChange={(event) =>
                setFieldValue('general.name', event.target.value)
              }
              value={general.name}
              fullWidth
              helperText={
                Boolean(errors?.general?.name) ? 'Name is required' : ''
              }
              error={Boolean(errors?.general?.name)}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              label="Location"
              select
              required
              variant="outlined"
              onChange={(event) =>
                setFieldValue('general.location', event.target.value)
              }
              value={general.location}
              inputProps={{ className: classes.select }}
              fullWidth
              helperText={
                Boolean(errors?.general?.location) ? 'Location is required' : ''
              }
              error={Boolean(errors?.general?.location)}
            >
              {LOCATIONS.map((location) => {
                return (
                  <MenuItem key={location.value} value={location.value}>
                    {location.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
        <Grid container item spacing={5} xs={12} className={classes.row}>
          <IdModule />
        </Grid>
        <Grid container item xs={12}>
          <InfoBox content={INFO_BOX_CONTENT}></InfoBox>
        </Grid>{' '}
      </>
    );
  }
);
