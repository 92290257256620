// Libraries
import React from 'react';
import {
  CircularProgress,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
// Utils
import { ITheme } from 'styles/mui/themeV2';

interface LoaderProps {
  isLoading: boolean;
  children: React.ReactElement<any, any> | null;
}

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    loadingPanel: {
      height: '62vh',
      backgroundColor: theme.palette.gray.light,
    },
  })
);

export const Loader: React.FC<LoaderProps> = ({ isLoading, children }) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.loadingPanel}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <CircularProgress size={100} thickness={3} />
          <Typography variant="h4" style={{ marginTop: 12, fontWeight: 600 }}>
            Loading...
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return children;
};
