// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { SectionHeader } from '../section-header.component';
import { TextField } from 'design-system';
// Utils
import { SectionProps } from './types';
import { USDollar } from 'features/utils';
// Constants
import { componentCosts } from '../constants';

export const ComponentsSection: React.FC<SectionProps> = ({
  costs,
  isActive,
}) => {
  const { getFieldProps } = useFormikContext();
  return (
    <div
      className={`${isActive ? 'h-auto' : 'h-0 overflow-hidden'} flex flex-col`}
    >
      <SectionHeader title="Components" />
      <div className="px-3 bg-white">
        {componentCosts.map((componentCost: any, index: number) => {
          return (
            <div
              key={`${index}-${componentCost.title}`}
              className="grid grid-cols-10 py-[6px] items-center min-h-[44px]"
            >
              <span className="col-span-2 text-grey-50">
                {componentCost.title}
              </span>
              {componentCost.formikKeys &&
                componentCost.formikKeys.map(
                  (formikKey: string, index: number) => {
                    return (
                      <div
                        key={`${index}-${formikKey}`}
                        className="col-span-2 mr-6"
                      >
                        <TextField
                          {...getFieldProps(formikKey)}
                          height="h-11"
                          type="number"
                          leadingText={componentCost?.leadingText}
                        />
                      </div>
                    );
                  }
                )}
            </div>
          );
        })}
        <div className="grid grid-cols-10 py-[6px] items-center min-h-[44px]">
          <span className="col-span-2 text-grey-50">Component Subtotal</span>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[0].componentSubtotal)}
          </span>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[1].componentSubtotal)}
          </span>
          <span className="col-span-2 mr-6">
            {USDollar.format(costs[2].componentSubtotal)}
          </span>
        </div>
        <div className="grid grid-cols-10 py-[6px] items-center min-h-[44px]">
          <span className="col-span-2 text-grey-50">Buy Fee (20%)</span>
          <span className="col-span-2 mr-6">${costs[0].buyFee.toFixed(3)}</span>
          <span className="col-span-2 mr-6">${costs[1].buyFee.toFixed(3)}</span>
          <span className="col-span-2 mr-6">${costs[2].buyFee.toFixed(3)}</span>
        </div>
      </div>
    </div>
  );
};
