// Libraries
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { parseISO, format } from 'date-fns';
import _ from 'lodash';
// Components
import { Page } from 'design-system';

import { ProjectOverview } from './project-overview.component';
import { Claims } from './claims.component';
import { ConceptProductSpecific } from './concept-product-specfic.component';
import { IngredientsAndFormula } from './ingredients-and-formula.component';
import { FormulaSamples } from './formula-sample.component';
import { InternalBriefBottomBar } from './internal-brief-bottom-bar.component';
import { InternalBriefNav } from './internal-brief-nav.component';
import { InternalBriefPage } from './internal-brief-page.component';
import { Packaging } from './packaging.component';
import { Regulatory } from './regulatory.component';
import { Testing } from './testing.component';
import { LeftNavContext } from 'context';
// Utils
import {
  useApi,
  IApiData,
  ProjectAttributes,
  BriefAttributes,
  CompanyAttributes,
  CustomerAttributes,
  SampleRecipientAttributes,
} from 'api';
import {
  InternalBriefFormValues,
  initialInternalBriefFormValues,
  internalBriefValidationSchema,
} from './form.config';
// Constants
import { BRIEF_FORM_KEYS, COGS_OPTIONS } from '../brief.constants';
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import {
  CLOSURE_MENU_ITEMS,
  MATERIAL_MENU_ITEMS,
} from 'features/pricing-quote/constants';

const PAGES = [
  { component: <ProjectOverview />, title: 'Project Overview' },
  { component: <ConceptProductSpecific />, title: 'Concept & Product' },
  { component: <IngredientsAndFormula />, title: 'Ingredients & Formula' },
  { component: <Claims />, title: 'Claims' },
  { component: <Packaging />, title: 'Packaging' },
  { component: <Testing />, title: 'Testing' },
  { component: <Regulatory />, title: 'Regulatory' },
  { component: <FormulaSamples />, title: 'Formula Samples' },
];

interface ProjectWithAssociations extends IApiData<ProjectAttributes> {
  brief: IApiData<BriefAttributes>;
  company: IApiData<CompanyAttributes>;
  contact: IApiData<CustomerAttributes>;
  sampleRecipients: IApiData<SampleRecipientAttributes>[];
}

const initialFormValues = initialInternalBriefFormValues;

export const InternalBrief: React.FC = () => {
  const { getProject, postProjectV2, patchProjectV2 } = useApi();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { isLeftNavOpen } = useContext(LeftNavContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [project, setProject] = useState<Maybe<ProjectWithAssociations>>(
    undefined
  );
  const [fixedDivWidth, setFixedDivWidth] = useState('auto');

  const fixedDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (uuid) {
      getProject({
        urlParams: uuid,
        handleSuccess: setProject,
      });
    }
  }, [uuid, getProject]);

  // This useEffect sets up a ref that communicates the width of the container
  // div down to the BottomBar component, so that the BottomBar, which is fixed,
  // can have the same width as the container div.
  useEffect(() => {
    const updateFixedDivWidth = () => {
      if (fixedDivRef.current) {
        const width = `${fixedDivRef.current.offsetWidth}px`;
        setFixedDivWidth(width);
      }
    };

    window.addEventListener('resize', updateFixedDivWidth);
    updateFixedDivWidth();

    return () => window.removeEventListener('resize', updateFixedDivWidth);
  }, [isLeftNavOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const parseMaxRangeValue = (value: string) => {
    return value.split('-$')[1];
  };

  const parseMinRangeValue = (value: string) => {
    return value.split('-$')[0].split('$')[1];
  };

  const parseValueFromMinAndMax = (
    minValue: Maybe<string | number>,
    maxValue: Maybe<string | number>
  ) => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if (!minValue) return;
    return `${currencyFormatter.format(
      minValue as number
    )}-${currencyFormatter.format(maxValue as number)}`;
  };

  const submitForm = (
    values: InternalBriefFormValues,
    { setSubmitting }: FormikHelpers<InternalBriefFormValues>
  ) => {
    const requestFunction = uuid ? patchProjectV2 : postProjectV2;
    const {
      additionalTestingBulk,
      additionalTestingBulkNote,
      antiFrizz,
      antiRedness,
      approvalDate,
      benchmarks,
      blueLightProtection,
      brightening,
      broadSpectrumTest,
      closure,
      coa,
      cogsRange,
      colorNote,
      company,
      compatibilityTest,
      conditioning,
      contact,
      container,
      countries,
      countryComplianceNote,
      curlDefining,
      deepConditioning,
      detangle,
      directionsForUse,
      estimatedQuoteStandard,
      eyesReduceAppearanceOfDarkCircles,
      eyesReduceAppearanceOfLineCrowsFeet,
      eyesReduceAppearanceOfPuffiness,
      firming,
      fragranceComment,
      fragranceType,
      glutenFree,
      glutenFreeStatement,
      hairSmoothing,
      hardHold,
      hriptForHypoallergenicTest,
      hriptTest,
      hriptWithDermatologistTest,
      hydration,
      hydrationLongTerm,
      hypoallergenic,
      ifraComplianceStatement,
      initialDateOfBrief,
      lightHold,
      masterBox,
      material,
      mattifying,
      minimumOrderQuantity,
      moisture,
      moisturization,
      mustExcludeIngredients,
      mustHaveIngredients,
      noAnimalDerivedIngredients,
      noAnimalDerivedIngredientsStatement,
      noAnimalTestedIngredientsStatement,
      noAnimalTesting,
      nonOtcClaimComments,
      occularTest,
      opacity,
      otc,
      otcNote,
      otherRAndDDocuments,
      petTest,
      preliminaryIlPerSubmissionStandard,
      previouslyBroughtToMarket,
      previouslyBroughtToMarketNote,
      productCategory,
      productDescription,
      productFunction,
      productObjective,
      productPurpose,
      productType,
      productionLocation,
      projectName,
      radianceGlow,
      retailerStandards,
      sampleQuantity,
      sampleRecipients,
      selfTanner,
      shine,
      size,
      skinSmoothing,
      soothing,
      spfDeterminationFdaTest,
      spfDeterminationIsoTest,
      stabilityTest,
      statementsNote,
      strengthening,
      structure,
      targetCost,
      targetCustomer,
      targetDate,
      testHandlingResponsibility,
      tightening,
      unit,
      vegan,
      veganIngredientsStatement,
      waterActivityTest,
    } = values;

    const parsedMinimumOrderQuantity =
      typeof minimumOrderQuantity === 'number'
        ? minimumOrderQuantity
        : parseInt(minimumOrderQuantity.replace(/,/g, ''));

    requestFunction({
      urlParams: uuid ? uuid : '',
      data: {
        project: {
          additionalTestingBulk,
          additionalTestingBulkNote,
          antiFrizz,
          antiRedness,
          approvalDate: new Date(approvalDate),
          blueLightProtection,
          brightening,
          broadSpectrumTest,
          category: productCategory.value,
          coa,
          colorNote,
          companyUuid: company.value,
          compatibilityTest,
          conditioning,
          contactUuid: contact.value,
          countryComplianceNote,
          curlDefining,
          deepConditioning,
          description: productDescription,
          detangle,
          directionsForUse,
          estimatedQuoteStandard,
          eyesReduceAppearanceOfDarkCircles,
          eyesReduceAppearanceOfLineCrowsFeet,
          eyesReduceAppearanceOfPuffiness,
          firming,
          function: productFunction,
          glutenFree,
          glutenFreeStatement,
          hairSmoothing,
          hardHold,
          hriptForHypoallergenicTest,
          hriptTest,
          hriptWithDermatologistTest,
          hydration,
          hydrationLongTerm,
          hypoallergenic,
          ifraComplianceStatement,
          initialDateOfBrief: new Date(initialDateOfBrief),
          lightHold,
          mattifying,
          moisture,
          moisturization,
          name: projectName,
          noAnimalDerivedIngredients,
          noAnimalDerivedIngredientsStatement,
          noAnimalTestedIngredientsStatement,
          noAnimalTesting,
          nonOtcClaimComments,
          objective: productObjective,
          occularTest,
          opacity,
          otc,
          otcNote,
          otherRAndDDocuments,
          petTest,
          preliminaryIlPerSubmissionStandard,
          previouslyBroughtToMarket,
          previouslyBroughtToMarketNote,
          productType: productType.value,
          purpose: productPurpose,
          radianceGlow,
          retailerStandards,
          sampleQuantity: sampleQuantity.value,
          selfTanner,
          shine,
          skinSmoothing,
          soothing,
          spfDeterminationFdaTest,
          spfDeterminationIsoTest,
          stabilityTest,
          statementsNote,
          strengthening,
          testHandlingResponsibility,
          tightening,
          vegan,
          veganIngredientsStatement,
          waterActivityTest,
          briefAttributes: {
            bMarksAttributes: benchmarks,
            briefSupplementAttributes: {
              closure: closure.value,
              container,
              material: material.value,
              masterBox,
              productionLocation,
            },
            countries,
            fragranceComment,
            fragranceType,
            minimumOrderQuantity: parsedMinimumOrderQuantity,
            mustExcludeIngredients,
            mustHaveIngredients,
            size,
            structure,
            targetCost,
            targetCustomer,
            targetDate: new Date(targetDate),
            targetMaxCost: parseMaxRangeValue(cogsRange.value),
            targetMinCost: parseMinRangeValue(cogsRange.value),
            unit,
          },
          sampleRecipientsAttributes: sampleRecipients.map((recipient) => ({
            address_1: recipient.addressLine1,
            address_2: recipient.addressLine2,
            state: recipient.state.value,
            ..._.omit(recipient, ['addressLine1', 'addressLine2', 'state']),
          })),
        },
      },
      handleSuccess: (data) => {
        uuid
          ? alert('updated!')
          : navigate(
              ROUTES.SHOW_PROJECT.route.replace(UUID_SHOW_ROUTE_STRING, data.id)
            );
      },
      handleFinally: () => {
        // isSubmitting is provided by formik as a generic loader boolean
        // when submitForm is called the isSubmitting boolean is automatically
        // set to true, here we can set it back so loaders will disappear.
        setSubmitting(false);
      },
    });
  };

  const determineInitialValues = (): InternalBriefFormValues => {
    if (project) {
      const { attributes, brief, company, contact, sampleRecipients } = project;

      return {
        [BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET]:
          attributes.previouslyBroughtToMarket ||
          initialFormValues.previouslyBroughtToMarket,
        [BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET_NOTE]:
          attributes.previouslyBroughtToMarketNote ||
          initialFormValues.previouslyBroughtToMarketNote,
        [BRIEF_FORM_KEYS.TINT]: initialFormValues.tint,
        [BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK]:
          attributes.additionalTestingBulk,
        [BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK_NOTE]:
          attributes.additionalTestingBulkNote,
        [BRIEF_FORM_KEYS.COGS_TYPE]:
          brief.attributes.targetMinCost || brief.attributes.targetMaxCost
            ? 'Range'
            : 'Specific',
        [BRIEF_FORM_KEYS.ANTI_FRIZZ]: attributes.antiFrizz,
        [BRIEF_FORM_KEYS.ANTI_REDNESS]: attributes.antiRedness,
        [BRIEF_FORM_KEYS.APPROVAL_DATE]: attributes.approvalDate
          ? format(
              parseISO((brief.attributes.approvalDate as unknown) as string),
              'MM/dd/yyyy'
            )
          : initialFormValues.approvalDate,
        [BRIEF_FORM_KEYS.MUST_EXCLUDE_INGREDIENTS]:
          brief.attributes.mustExcludeIngredients || '',
        [BRIEF_FORM_KEYS.BLUE_LIGHT_PROTECTION]: attributes.blueLightProtection,
        [BRIEF_FORM_KEYS.BRIGHTENING]: attributes.brightening,
        [BRIEF_FORM_KEYS.BROAD_SPECTRUM_TEST]: attributes.broadSpectrumTest,
        [BRIEF_FORM_KEYS.PRODUCT_CATEGORY]: {
          label: attributes.category || '',
          value: attributes.category || '',
        },
        [BRIEF_FORM_KEYS.COA]: attributes.coa,
        [BRIEF_FORM_KEYS.COLOR_NOTE]: attributes.colorNote,
        [BRIEF_FORM_KEYS.COMPANY]: {
          label: company.attributes.name,
          value: company.id,
        },
        [BRIEF_FORM_KEYS.COMPATIBILITY_TEST]: attributes.compatibilityTest,
        [BRIEF_FORM_KEYS.COUNTRY_COMPLIANCE_NOTE]:
          attributes.countryComplianceNote,
        [BRIEF_FORM_KEYS.CONDITIONING]: attributes.conditioning,
        [BRIEF_FORM_KEYS.CONTACT]: contact
          ? { label: contact.attributes.fullName, value: contact.id }
          : initialFormValues.contact,
        [BRIEF_FORM_KEYS.CURL_DEFINING]: attributes.curlDefining,
        [BRIEF_FORM_KEYS.DEEP_CONDITIONING]: attributes.deepConditioning,
        [BRIEF_FORM_KEYS.PRODUCT_DESCRIPTION]:
          attributes.description || initialFormValues.productDescription,
        [BRIEF_FORM_KEYS.DETANGLE]: attributes.detangle,
        [BRIEF_FORM_KEYS.DIRECTIONS_FOR_USE]: attributes.directionsForUse,
        [BRIEF_FORM_KEYS.ESTIMATED_QUOTE_STANDARD]:
          attributes.estimatedQuoteStandard,
        [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_DARK_CIRCLES]:
          attributes.eyesReduceAppearanceOfDarkCircles,
        [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_LINE_CROWS_FEET]:
          attributes.eyesReduceAppearanceOfLineCrowsFeet,
        [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_PUFFINESS]:
          attributes.eyesReduceAppearanceOfPuffiness,
        [BRIEF_FORM_KEYS.FIRMING]: attributes.firming,
        [BRIEF_FORM_KEYS.PRODUCT_FUNCTION]: attributes.function,
        [BRIEF_FORM_KEYS.GLUTEN_FREE]: attributes.glutenFree,
        [BRIEF_FORM_KEYS.GLUTEN_FREE_STATEMENT]: attributes.glutenFreeStatement,
        [BRIEF_FORM_KEYS.NO_ANIMAL_DERIVED_INGREDIENTS_STATEMENT]:
          attributes.noAnimalDerivedIngredientsStatement,
        [BRIEF_FORM_KEYS.NO_ANIMAL_TESTING]: attributes.noAnimalTesting,
        [BRIEF_FORM_KEYS.NO_ANIMAL_TESTED_INGREDIENTS_STATEMENT]:
          attributes.noAnimalTestedIngredientsStatement,
        [BRIEF_FORM_KEYS.HAIR_SMOOTHING]: attributes.hairSmoothing,
        [BRIEF_FORM_KEYS.HARD_HOLD]: attributes.hardHold,
        [BRIEF_FORM_KEYS.HRIPT_FOR_HYPOALLERGENIC_TEST]:
          attributes.hriptForHypoallergenicTest,
        [BRIEF_FORM_KEYS.HRIPT_TEST]: attributes.hriptTest,
        [BRIEF_FORM_KEYS.HRIPT_WITH_DERMATOLOGIST_TEST]:
          attributes.hriptWithDermatologistTest,
        [BRIEF_FORM_KEYS.HYDRATION]: attributes.hydration,
        [BRIEF_FORM_KEYS.HYDRATION_LONG_TERM]: attributes.hydrationLongTerm,
        [BRIEF_FORM_KEYS.HYPOALLERGENIC]: attributes.hypoallergenic,
        [BRIEF_FORM_KEYS.IFRA_COMPLIANCE_STATEMENT]:
          attributes.ifraComplianceStatement,
        [BRIEF_FORM_KEYS.INITIAL_DATE_OF_BRIEF]: attributes.initialDateOfBrief
          ? format(
              parseISO(
                ((attributes.initialDateOfBrief as unknown) || '') as string
              ),
              'MM/dd/yyyy'
            )
          : (initialFormValues.initialDateOfBrief as string),
        [BRIEF_FORM_KEYS.LIGHT_HOLD]: attributes.lightHold,
        [BRIEF_FORM_KEYS.MATTIFYING]: attributes.mattifying,
        [BRIEF_FORM_KEYS.MOISTURE]: attributes.moisture,
        [BRIEF_FORM_KEYS.MOISTURIZATION]: attributes.moisturization,
        [BRIEF_FORM_KEYS.PROJECT_NAME]: attributes.name,
        [BRIEF_FORM_KEYS.NO_ANIMAL_DERIVED_INGREDIENTS]:
          attributes.noAnimalDerivedIngredients,
        [BRIEF_FORM_KEYS.NON_OTC_CLAIM_COMMENTS]:
          attributes.nonOtcClaimComments,
        [BRIEF_FORM_KEYS.PRODUCT_OBJECTIVE]: attributes.objective,
        [BRIEF_FORM_KEYS.OCCULAR_TEST]: attributes.occularTest,
        [BRIEF_FORM_KEYS.OPACITY]: attributes.opacity,
        [BRIEF_FORM_KEYS.OTC]: attributes.otc || initialFormValues.otc,
        [BRIEF_FORM_KEYS.OTC_NOTE]:
          attributes.otcNote || initialFormValues.otcNote,
        [BRIEF_FORM_KEYS.OTHER_R_AND_D_DOCUMENTS]:
          attributes.otherRAndDDocuments,
        [BRIEF_FORM_KEYS.PET_TEST]: attributes.petTest,
        [BRIEF_FORM_KEYS.PRELIMINARY_IL_PER_SUBMISSION_STANDARD]:
          attributes.preliminaryIlPerSubmissionStandard,
        [BRIEF_FORM_KEYS.PRODUCT_TYPE]: {
          label: attributes.productType || '',
          value: attributes.productType || '',
        },
        [BRIEF_FORM_KEYS.PRODUCT_PURPOSE]: attributes.purpose,
        [BRIEF_FORM_KEYS.RADIANCE_GLOW]: attributes.radianceGlow,
        [BRIEF_FORM_KEYS.RETAILER_STANDARDS]: brief.relationships.standards.data.map(
          ({ id }) => id
        ),
        [BRIEF_FORM_KEYS.SAMPLE_QUANTITY]: attributes.sampleQuantity
          ? {
              label: attributes.sampleQuantity.toString(),
              value: attributes.sampleQuantity.toString(),
            }
          : initialFormValues.sampleQuantity,
        [BRIEF_FORM_KEYS.SELF_TANNER]: attributes.selfTanner,
        [BRIEF_FORM_KEYS.SHINE]: attributes.shine,
        [BRIEF_FORM_KEYS.SKIN_SMOOTHING]: attributes.skinSmoothing,
        [BRIEF_FORM_KEYS.SOOTHING]: attributes.soothing,
        [BRIEF_FORM_KEYS.SPF_DETERMINATION_FDA_TEST]:
          attributes.spfDeterminationFdaTest,
        [BRIEF_FORM_KEYS.SPF_DETERMINATION_ISO_TEST]:
          attributes.spfDeterminationIsoTest,
        [BRIEF_FORM_KEYS.STABILITY_TEST]: attributes.stabilityTest,
        [BRIEF_FORM_KEYS.STATEMENTS_NOTE]: attributes.statementsNote,
        [BRIEF_FORM_KEYS.STRENGTHENING]: attributes.strengthening,
        [BRIEF_FORM_KEYS.TEST_HANDLING_RESPONSIBILITY]:
          attributes.testHandlingResponsibility,
        [BRIEF_FORM_KEYS.TIGHTENING]: attributes.tightening,
        [BRIEF_FORM_KEYS.VEGAN]: attributes.vegan,
        [BRIEF_FORM_KEYS.VEGAN_INGREDIENTS_STATEMENT]:
          attributes.veganIngredientsStatement,
        [BRIEF_FORM_KEYS.WATER_ACTIVITY_TEST]: attributes.waterActivityTest,
        [BRIEF_FORM_KEYS.BENCHMARKS]:
          (brief.attributes.bMarks?.map((bMark) => bMark.attributes) as any) ||
          initialFormValues.benchmarks,
        [BRIEF_FORM_KEYS.CLOSURE]:
          (CLOSURE_MENU_ITEMS[
            brief.attributes.briefSupplement?.data.attributes.closure || ''
          ] as Option) || initialFormValues.closure,
        [BRIEF_FORM_KEYS.CONTAINER]:
          brief.attributes.briefSupplement?.data.attributes.container ||
          initialFormValues.container,
        [BRIEF_FORM_KEYS.MATERIAL]:
          (MATERIAL_MENU_ITEMS[
            brief.attributes.briefSupplement?.data.attributes.material || ''
          ] as Option) || initialFormValues.material,
        [BRIEF_FORM_KEYS.MASTER_BOX]:
          brief.attributes.briefSupplement?.data.attributes.masterBox ||
          initialFormValues.masterBox,
        [BRIEF_FORM_KEYS.PRODUCTION_LOCATION]:
          brief.attributes.briefSupplement?.data.attributes
            .productionLocation || initialFormValues.productionLocation,
        [BRIEF_FORM_KEYS.COUNTRIES]:
          brief.attributes.countries || initialFormValues.countries,
        [BRIEF_FORM_KEYS.FRAGRANCE_COMMENT]:
          brief.attributes.fragranceComment ||
          initialFormValues.fragranceComment,
        [BRIEF_FORM_KEYS.FRAGRANCE_TYPE]:
          brief.attributes.fragranceType || initialFormValues.fragranceType,
        [BRIEF_FORM_KEYS.MINIMUM_ORDER_QUANTITY]:
          (brief.attributes.minimumOrderQuantity as any) ||
          initialFormValues.minimumOrderQuantity,
        [BRIEF_FORM_KEYS.MUST_HAVE_INGREDIENTS]:
          brief.attributes.mustHaveIngredients ||
          initialFormValues.mustHaveIngredients,
        [BRIEF_FORM_KEYS.SIZE]:
          (brief.attributes.size as any) || initialFormValues.size,
        [BRIEF_FORM_KEYS.STRUCTURE]:
          brief.attributes.structure || initialFormValues.structure,
        [BRIEF_FORM_KEYS.TARGET_COST]:
          (brief.attributes.targetCost as any) || initialFormValues.targetCost,
        [BRIEF_FORM_KEYS.TARGET_CUSTOMER]:
          brief.attributes.targetCustomer || initialFormValues.targetCustomer,
        [BRIEF_FORM_KEYS.TARGET_DATE]: brief.attributes.targetDate
          ? format(
              parseISO((brief.attributes.targetDate as unknown) as string),
              'MM/dd/yyyy'
            )
          : initialFormValues.targetDate,
        [BRIEF_FORM_KEYS.COGS_RANGE]:
          COGS_OPTIONS.find(
            (option) =>
              option.value ===
              parseValueFromMinAndMax(
                brief.attributes.targetMinCost,
                brief.attributes.targetMaxCost
              )
          ) || initialFormValues.cogsRange,
        [BRIEF_FORM_KEYS.UNIT]: brief.attributes.unit || initialFormValues.unit,
        [BRIEF_FORM_KEYS.SAMPLE_RECIPIENTS]: sampleRecipients
          ? sampleRecipients.map(
              (recipient) =>
                ({
                  ..._.omit(recipient.attributes, ['address1', 'address2']),
                  addressLine1: recipient.attributes.address1,
                  addressLine2: recipient.attributes.address2,
                  state:
                    recipient.attributes.state ||
                    initialFormValues.sampleRecipients[0].state,
                } as SampleRecipient)
            )
          : initialFormValues.sampleRecipients,
      };
    }
    return initialFormValues;
  };

  return !uuid ? (
    <Page>
      <Formik
        enableReinitialize
        initialValues={determineInitialValues()}
        onSubmit={submitForm}
        validationSchema={internalBriefValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <div
          ref={fixedDivRef}
          className={`max-w-[1256px] col-start-1 col-span-2 md:col-span-6 ${
            uuid
              ? 'lg:col-start-1 lg:col-span-12'
              : 'lg:col-start-2 lg:col-span-10'
          }`}
        >
          <div
            className="flex flex-col md:flex-row lg:flex-row items-start md:items-center lg:items-center md:justify-between lg:justify-between
                         mt-16 mb-12 md:mb-6"
          >
            {!uuid && <p className="font-agipo text-[40px]">Add New Brief</p>}
            {/* // TODO: Uncomment once the save as draft button is implemented */}
            {/* <Button
              icon={<DocumentIcon />}
              size="medium"
              text="Save as Draft"
              type="secondary"
            /> */}
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="mb-3 md:mb-0">
              <InternalBriefNav
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <InternalBriefPage page={PAGES[currentPage]} />
          </div>
          <InternalBriefBottomBar
            currentPage={currentPage}
            fixedDivWidth={fixedDivWidth}
            pages={PAGES}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </Formik>
    </Page>
  ) : (
    <Formik
      enableReinitialize
      initialValues={determineInitialValues()}
      onSubmit={submitForm}
      validationSchema={internalBriefValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <div
        ref={fixedDivRef}
        className="max-w-[1256px] col-start-1 col-span-2 md:col-span-6 h-auto -mb-20 md:mb-0 font-inter"
      >
        <div
          className="flex flex-col md:flex-row lg:flex-row items-start md:items-center lg:items-center md:justify-between lg:justify-between
                          mb-12 md:mb-0"
        >
          {!uuid && <p className="font-agipo text-[40px]">Add New Brief</p>}
          {/* // TODO: Uncomment once the save as draft button is implemented */}
          {/* <Button
              icon={<DocumentIcon />}
              size="medium"
              text="Save as Draft"
              type="secondary"
            /> */}
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="mb-3 md:mb-0">
            <InternalBriefNav
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <InternalBriefPage page={PAGES[currentPage]} />
        </div>
        <InternalBriefBottomBar
          currentPage={currentPage}
          fixedDivWidth={fixedDivWidth}
          pages={PAGES}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Formik>
  );
};
