//Libraries
import React, { createContext } from 'react';
// Utils
import { ExtendedFormulaAttributes } from 'features/formula/formula-page/types';

export const FormulaContext = createContext<{
  formula: Maybe<ExtendedFormulaAttributes>;
  setFormula:
    | React.Dispatch<React.SetStateAction<Maybe<ExtendedFormulaAttributes>>>
    | (() => void);
  openAddIngredientModal: () => void;
}>({
  formula: undefined,
  setFormula: () => {},
  openAddIngredientModal: () => {},
});
