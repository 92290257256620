//Libraries
import React, { useContext, useEffect, useState } from 'react';
import {
  Collapse,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useFormikContext } from 'formik';
// Components
import { AccordionButton } from 'features/ui';
//Utils
import {
  determineHeadCountValues,
  determineMarginTarget,
} from './pricing-calculator.component';
import { PricingQuoteFormikValues, Tank } from './types';
import { PERMISSIONS, usePermissionCheck } from 'features/rbac';
import { UserContext, UserSession } from 'context';
import { IApiData, PricingQuoteAttributes } from 'api';
// Constants
import {
  INITIAL_EFFICIENCY_PERCENTAGE,
  INITIAL_LABOR_HEAD_COUNT_VALUES,
  INITIAL_MARGIN_TARGET_PERCENTAGE,
  INITIAL_RUN_RATE,
  PRODUCTION_LOCATIONS,
} from './constants';

interface IPricingQuoteInputs {
  briefWithSupplement: any;
  pricingQuote: Maybe<IApiData<PricingQuoteAttributes>>;
  productionLocation: Maybe<valueof<typeof PRODUCTION_LOCATIONS>>;
  productionTank: Maybe<
    Tank & {
      tanks: Tank[];
    }
  >;
  setChangedInputUser: React.Dispatch<React.SetStateAction<string | undefined>>;
  setProductionTank: React.Dispatch<
    React.SetStateAction<
      Maybe<
        Tank & {
          tanks: Tank[];
        }
      >
    >
  >;
  urlEncodeUserName: ({ givenName, familyName }: UserSession) => string;
}

export const PricingQuoteInputs: React.FC<IPricingQuoteInputs> = ({
  briefWithSupplement,
  pricingQuote,
  productionLocation,
  productionTank,
  setChangedInputUser,
  setProductionTank,
  urlEncodeUserName,
}) => {
  const { dirty, getFieldProps, resetForm, values } = useFormikContext<
    PricingQuoteFormikValues
  >();
  const isAbleToOverridePricingQuote = usePermissionCheck({
    actions: [PERMISSIONS.OVERRIDE_PRICING_QUOTE],
  });

  /**
   If the pricingQuote is present, we expand the labor fields to display the
   persisted values
  */
  const [expanded, setExpanded] = useState<boolean>(Boolean(pricingQuote));

  const { userSession } = useContext(UserContext)!;

  useEffect(() => {
    // If pricingQuote is present we do not need to determine the margin target
    if (pricingQuote) return;

    if (
      briefWithSupplement?.container &&
      briefWithSupplement?.material &&
      briefWithSupplement?.closure &&
      productionTank?.unitOutput
    ) {
      const newLaborHeadCountValues = determineHeadCountValues(
        briefWithSupplement,
        productionTank.unitOutput
      );

      resetForm({
        values: {
          ...values,
          ...newLaborHeadCountValues,
          marginTargetPercentage: determineMarginTarget(productionTank),
          compoundingHeadCount:
            newLaborHeadCountValues?.compoundingHeadCount *
            productionTank.tanks.length,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    briefWithSupplement,
    productionLocation,
    setProductionTank,
    productionTank,
    pricingQuote,
    resetForm,
  ]);

  useEffect(() => {
    if (dirty) {
      setChangedInputUser(urlEncodeUserName(userSession));
    }
  }, [dirty, setChangedInputUser, urlEncodeUserName, userSession]);

  const renderHeadCountInputs = () => {
    const headCounts = Object.values(INITIAL_LABOR_HEAD_COUNT_VALUES);
    return headCounts.map((headCount, idx) => {
      return (
        <TextField
          {...getFieldProps(headCount.name)}
          required={headCount.required}
          disabled={!isAbleToOverridePricingQuote}
          key={`${headCount.label}-${idx}`}
          label={headCount.label}
          type="number"
          variant="outlined"
          InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
        />
      );
    });
  };

  const recommendedMarginTarget = determineMarginTarget(productionTank);

  return (
    <>
      <div className="flex justify-between pt-3 border-t border-formulaLighterGray">
        <div className="flex flex-col w-full">
          <div className="flex">
            <div className="flex w-1/2 flex-col pt-3 mr-5 mb-6">
              <div className="flex items-center mb-4">
                <Typography variant="h4">Margin Target</Typography>
              </div>
              <TextField
                {...getFieldProps(INITIAL_MARGIN_TARGET_PERCENTAGE.name)}
                className="w-full mb-12"
                disabled={!isAbleToOverridePricingQuote}
                label={INITIAL_MARGIN_TARGET_PERCENTAGE.label}
                variant="outlined"
                helperText={
                  values.marginTargetPercentage !== recommendedMarginTarget
                    ? `Recommended margin is ${recommendedMarginTarget}%`
                    : ''
                }
                inputProps={{
                  type: 'number',
                  placeholder: '0',
                  step: '1',
                  min: '1',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                aria-label="Margin Target"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-y border-formulaLighterGray">
        <div className="flex my-8 items-center justify-between">
          <div className="flex items-center">
            <Typography variant="h4">Labor</Typography>
            <Tooltip title="Labor head count is recommended based on chosen components">
              <InfoOutlinedIcon className="ml-2" />
            </Tooltip>
          </div>
          <AccordionButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        </div>
        <Collapse in={expanded}>
          <div className="grid grid-cols-2 gap-4">
            <TextField
              {...getFieldProps(INITIAL_EFFICIENCY_PERCENTAGE.name)}
              disabled={!isAbleToOverridePricingQuote}
              required
              variant="outlined"
              label={INITIAL_EFFICIENCY_PERCENTAGE.label}
              inputProps={{
                type: 'number',
                step: '1',
                max: '100',
                min: '1',
                placeholder: '0',
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
            <TextField
              {...getFieldProps(INITIAL_RUN_RATE.name)}
              required
              disabled={!isAbleToOverridePricingQuote}
              label={INITIAL_RUN_RATE.label}
              type="number"
              variant="outlined"
            />
          </div>
          <div className={`my-8 items-center text-formulaGray`}>
            <Typography variant="h4">Head Count:</Typography>
          </div>
          <div className="grid grid-cols-3 gap-4 mb-6">
            {renderHeadCountInputs()}
          </div>
        </Collapse>
      </div>
    </>
  );
};
