// Utils
import { IApiData } from 'features/types';
import { PURCHASE_QUANTITY_UNITS, Price } from 'features/types';
import { Ingredient } from './types';
// Constants
import { API_TYPES } from 'features/constants';
import { UNITS } from './constants';

const OUNCES_PER_POUND = 16;
const OUNCES_PER_KILO = 35.274;
const POUNDS_PER_KILO = 2.2046;
const GRAMS_PER_OUNCE = 28.35;

export const getPriceFromIngredient = (
  price: Maybe<IApiData>,
  included?: IApiData[]
) => {
  if (!price) return;

  const {
    id,
    attributes: { cost, unit, location, receivedAt },
    relationships,
  }: IApiData = price;

  const manufacturer = included?.find((inc: IApiData) => {
    return (
      inc.type === API_TYPES.MANUFACTURER &&
      relationships?.manufacturer?.data?.id === inc.id
    );
  });
  const supplier = included?.find((inc: IApiData) => {
    return (
      inc.type === API_TYPES.SUPPLIER &&
      relationships?.supplier?.data?.id === inc.id
    );
  });

  return {
    id,
    cost,
    unit,
    location,
    receivedAt,
    manufacturer: manufacturer
      ? {
          id: manufacturer.id,
          ...manufacturer.attributes,
        }
      : undefined,
    supplier: supplier
      ? {
          id: supplier.id,
          ...supplier.attributes,
        }
      : undefined,
  };
};

const convertCostFromPoundsToOunces = (costPerPound: number) =>
  costPerPound / OUNCES_PER_POUND;

const convertCostFromGramsToOunces = (costPerGram: number) =>
  costPerGram / GRAMS_PER_OUNCE;

export const parseCostFromPrice = (
  price: Price, // {cost: number, unit: 'lb' or 'grams'}
  selectedPricingUnit?: string // pounds or ounces
) => {
  // Price that is passed could be in pounds or very rarely grams, so
  // IF the selectedPricingUnit is pounds
  if (selectedPricingUnit === UNITS.LB) {
    // IF the price is in pounds just return the cost
    if (price.unit === PURCHASE_QUANTITY_UNITS.lb) return price.cost;

    if (price.unit === PURCHASE_QUANTITY_UNITS.g)
      // convert grams to ounces and multiply by 16 to convert again to pounds
      return convertCostFromGramsToOunces(price.cost) * OUNCES_PER_POUND;

    if (price.unit === PURCHASE_QUANTITY_UNITS.kg)
      return price.cost / POUNDS_PER_KILO;
  }

  // User has selected price per OZ
  if (price.unit === PURCHASE_QUANTITY_UNITS.lb)
    return convertCostFromPoundsToOunces(price.cost);

  if (price.unit === PURCHASE_QUANTITY_UNITS.g)
    return convertCostFromGramsToOunces(price.cost);

  if (price.unit === PURCHASE_QUANTITY_UNITS.kg)
    return price.cost / OUNCES_PER_KILO;

  return convertCostFromPoundsToOunces(price.cost);
};

export const calculatePricePerOunceAtCurrentWeight = (
  ingredient: Ingredient,
  selectedPricingUnit: string
) => {
  if (!ingredient?.price) return 0;
  return (
    parseCostFromPrice(ingredient.price, selectedPricingUnit) *
    (ingredient.amount / 100)
  );
};

export const calculatePricePercentageOfTotal = (
  ingredient: Ingredient,
  selectedPricingUnit: string,
  totalCost: number
) => {
  return ingredient?.price
    ? (parseCostFromPrice(ingredient.price, selectedPricingUnit) *
        ingredient.amount) /
        totalCost
    : 0.0;
};

// TODO: use API version instead and delete
export const getNoteCreatedOrModifiedBy = (
  relationId?: string,
  included?: IApiData[]
) => {
  if (!included || !relationId) return;

  const employee = included.find(
    (inc) => inc.type === API_TYPES.EMPLOYEE && inc.id === relationId
  );

  return employee?.attributes?.fullName;
};
